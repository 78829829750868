import { AnalysisDetails } from '../../../store';
import { Analysis } from '../modules/analyses/store';

export const getAnalysisToolUrl = (destination: string, analysis: Analysis | AnalysisDetails) => {
  const analysisId = analysis.id;
  const analysisType = analysis.assay?.analysis_type;
  const reportType = analysis.report_configuration?.type;
  const reportId = analysis.last_report_uuid;
  const hideSnp = !!analysis.assay?.interpretation_config?.short?.hide_in_analysis;
  const variantTypeDestination =
    analysisType === 'SOMATIC' ? '/all' : analysis.snp_annotation_done && !hideSnp ? '/snp' : '/sv';

  switch (destination) {
    case 'analyses': {
      return '/analysis-tool/analyses';
    }
    case 'germline-dashboard': {
      return '/analysis-tool/germline-dashboard';
    }
    case 'somatic-dashboard': {
      return '/analysis-tool/somatic-dashboard';
    }
    case 'variants': {
      return '/analysis-tool/analysis/variants/' + analysisId + variantTypeDestination;
    }

    case 'workbench': {
      return '/analysis-tool/analysis/workbench/' + analysisId;
    }

    case 'coverage': {
      return '/analysis-tool/analysis/coverage/' + analysisId;
    }

    case 'report': {
      return '/analysis-tool/analysis/report/' + reportType + '/' + analysisId;
    }

    case 'report-v2': {
      return '/analysis-tool/analysis/report-v2/' + analysisId;
    }

    case 'viewer': {
      return '/analysis-tool/analysis/viewer/' + reportType + '/' + analysisId + '/' + reportId;
    }

    case 'history': {
      return '/analysis-tool/analysis/history/' + analysisId;
    }
  }
};
