import { createReducer, on } from '@ngrx/store';

import * as fromAction from './../actions/edit-variant.action';

export interface EditVariantSubmitState {
  submitting: boolean;
  submitSuccess: boolean;
  submitFail: boolean;
  editPosition?: {
    start: number;
    end: number;
  };
}

const initialState: EditVariantSubmitState = {
  submitting: false,
  submitSuccess: false,
  submitFail: false,
};

export const reducer = createReducer(
  initialState,
  on(fromAction.editVariantSubmit, (state) => ({
    ...state,
    submitting: true,
    submitFail: false,
    submitSuccess: false,
  })),
  on(fromAction.editVariantSubmitSuccess, (state) => ({
    ...state,
    submitting: false,
    submitSuccess: true,
  })),
  on(fromAction.editVariantSubmitFail, (state) => ({
    ...state,
    submitting: false,
    submitFail: true,
  })),
  on(fromAction.editVariantUpdatePositions, (state, { start, end }) => ({
    ...state,
    editPosition: {
      start: start ?? state.editPosition.start,
      end: end ?? state.editPosition.end,
    },
  })),
  on(fromAction.editVariantClearPositions, (state) => ({
    ...state,
    editPosition: undefined,
  })),
);
