import { Action } from '@ngrx/store';
import { CaseSummary } from '../models';

export const LOAD_CASE_SUMMARY = '[Analysis] Load CaseSummary';
export const LOAD_CASE_SUMMARY_SUCCESS = '[Analysis] Load CaseSummary success';
export const LOAD_CASE_SUMMARY_FAIL = '[Analysis] Load CaseSummary fail';

export class LoadCaseSummary implements Action {
  readonly type = LOAD_CASE_SUMMARY;

  constructor(public analysisId: number) {}
}

export class LoadCaseSummarySuccess implements Action {
  readonly type = LOAD_CASE_SUMMARY_SUCCESS;

  constructor(
    public analysisId: number,
    public caseSummary: CaseSummary,
  ) {}
}

export class LoadCaseSummaryFail implements Action {
  readonly type = LOAD_CASE_SUMMARY_FAIL;

  constructor(
    public analysisId: number,
    public err: any,
  ) {}
}

export const SET_TREATMENT_INCLUDE_IN_REPORT = '[Analysis] Set treatment include in report';
export const SET_TREATMENT_INCLUDE_IN_REPORT_SUCCESS = '[Analysis] Set treatment include in report success';
export const SET_TREATMENT_INCLUDE_IN_REPORT_FAIL = '[Analysis] Set treatment include in report fail';

export class SetTreatmentIncludeInReport implements Action {
  readonly type = SET_TREATMENT_INCLUDE_IN_REPORT;

  constructor(
    public analysisId: number,
    public treatmentId: string,
    public inReport: boolean,
  ) {}
}

export class SetTreatmentIncludeInReportSuccess implements Action {
  readonly type = SET_TREATMENT_INCLUDE_IN_REPORT_SUCCESS;

  constructor(
    public analysisId: number,
    public treatmentId: string,
    public inReport: boolean,
  ) {}
}

export class SetTreatmentIncludeInReportFail implements Action {
  readonly type = SET_TREATMENT_INCLUDE_IN_REPORT_FAIL;

  constructor(
    public analysisId: number,
    public treatmentId: string,
    public error: any,
  ) {}
}

export type CaseSummaryAction =
  | LoadCaseSummary
  | LoadCaseSummarySuccess
  | LoadCaseSummaryFail
  | SetTreatmentIncludeInReport
  | SetTreatmentIncludeInReportSuccess
  | SetTreatmentIncludeInReportFail;
