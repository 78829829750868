import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import * as fromAction from '../actions/follow-variant.action';
import { of } from 'rxjs';
import { getIsVariantFollowed } from '../selectors';
import { VariantPageService } from '../services';
import { VariantPageModuleState } from '../reducers';

@Injectable()
export class FollowVariantEffect {
  constructor(
    private actions$: Actions,
    private service: VariantPageService,
    private store$: Store<VariantPageModuleState>,
  ) {}

  getFollowedVariantState = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GET_VARIANT_FOLLOWED_STATE),
      switchMap((action: fromAction.GetVariantFollowedState) =>
        this.service.getFollowVariantState(action.varId).pipe(
          map((result) => new fromAction.GetVariantFollowedStateSuccess(result.variant, result.is_followed)),
          catchError((err) => of(new fromAction.GetVariantFollowedStateFail(err))),
        ),
      ),
    ),
  );

  setFollowVariant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.FOLLOW_VARIANT),
      withLatestFrom(this.store$.pipe(select(getIsVariantFollowed))),
      switchMap(([action, followed]: [fromAction.FollowVariant, boolean]) =>
        this.service.followVariant(action.varId, !followed).pipe(
          map(() => new fromAction.FollowVariantSuccess(action.varId, !followed)),
          catchError((error) => of(new fromAction.FollowVariantFail(error))),
        ),
      ),
    ),
  );
}
