import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { GenePhenotypesResponse, Phenotype } from '../models';
import { environment } from '../../../environments/environment';

@Injectable()
export class PhenotypesService {
  constructor(private http: HttpClient) {}

  getPhenotypes(
    input: string,
    queryType: ('phenotypes' | 'diseases')[] = [],
    diseaseMondoId?: string,
  ): Observable<Phenotype[]> {
    if (input) {
      const params = new HttpParams({
        fromObject: {
          str: input,
          query_type: queryType,
          disease_mondo_id_context: diseaseMondoId,
        },
      });
      return this.http.get<Phenotype[]>(`${environment.beBaseURL}/api/phenotypes/auto_complete`, { params });
    }
    return of([]);
  }

  getMatchingPhenotypes(input: string[]): Observable<{ invalid_phenotypes: string[]; valid_phenotypes: string[] }> {
    const params = new HttpParams({ fromObject: { phenotype: input } });
    return this.http.get<{ invalid_phenotypes: string[]; valid_phenotypes: string[] }>(
      `${environment.beBaseURL}/api/analysis/phenotypes/validate`,
      { params },
    );
  }

  getMatchingPhenotypeModels(
    input: string[],
  ): Observable<{ invalid_phenotypes: Phenotype[]; valid_phenotypes: Phenotype[] }> {
    const params = new HttpParams({ fromObject: { phenotype: input } });
    return this.http.get<{ invalid_phenotypes: Phenotype[]; valid_phenotypes: Phenotype[] }>(
      `${environment.beBaseURL}/api/analysis/phenotypes/validate_detailed`,
      { params },
    );
  }

  getGenePhenotypes(
    input: string,
    gene: string,
    queryType: ('phenotypes' | 'diseases')[] = [],
  ): Observable<GenePhenotypesResponse> {
    const params = new HttpParams({ fromObject: { str: input, gene, query_type: queryType } });

    return this.http.get<any>(`${environment.beBaseURL}/api/phenotypes/classification_auto_complete`, { params });
  }
}
