import { Injectable } from '@angular/core';
import { VariantAssessment, VariantAssessmentsByCondition } from '../models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AssessmentsDataServiceConfig, VariantPageLibServiceType } from '../../../store/models/library-config.model';
import {
  getVariantIdType,
  SearchConfig,
  VariantId,
  variantIdToBody,
  variantIdToBody2,
  variantIdToBody3,
  variantIdToHttpParams,
  VariantIdTypes,
} from '../../variant-page/models';
import { VariantPageLibConfigService } from '../../variant-page/services/variant-page-lib-config.service';
import { environment } from '../../../../../../environments/environment';

@Injectable()
export class AssessmentsDataService {
  constructor(private http: HttpClient) {}

  get serviceConfig(): AssessmentsDataServiceConfig {
    return VariantPageLibConfigService.getServiceConfig(VariantPageLibServiceType.AssessmentsService);
  }

  submitToClinvar(varId: string): Observable<any> {
    const body = { varIds: [varId] };
    const url = this.serviceConfig.submitClinvar;

    return this.http.post(url, body);
  }

  deleteAssessment(varId: string, orgId: string): Observable<any> {
    const body = { varIds: [varId], organization: orgId };
    const url = this.serviceConfig.deleteAssessment;

    return this.http.post(url, body);
  }

  getJointVariantAssessmentsByCondition(varId: VariantId): Observable<{
    assessments_groups: VariantAssessmentsByCondition[];
  }> {
    const variantId = variantIdToBody2(varId, false, true);
    // const url = '/assets/json/all_interpretations.json';
    // return this.http.get<{assessments_groups: VariantAssessmentsByCondition[]}>(url);

    const url = `${environment.beBaseURL}/api/org/fetch_all_interpretations`;
    return this.http.post<{ assessments_groups: VariantAssessmentsByCondition[] }>(url, {
      ...variantId,
      analysis_id: varId.analysisId
    });
  }
}
