import { Action, combineReducers, createFeatureSelector, MemoizedSelector } from '@ngrx/store';

import * as fromSigVariants from './sig-variants.reducer';
import * as fromSigDetails from './sig-details.reducer';
import * as fromSigGenes from './sig-genes.reducer';

export interface SigState {
  variants: fromSigVariants.SigVariantsState;
  details: fromSigDetails.SigDetailsState;
  genes: fromSigGenes.SigGenesState;
}

export function reducers(state: SigState | undefined, action: Action) {
  return combineReducers({
    variants: fromSigVariants.reducer,
    details: fromSigDetails.reducer,
    genes: fromSigGenes.reducer,
  })(state, action);
}

export const getSigState: MemoizedSelector<any, any> = createFeatureSelector<SigState>('sig');
