import { ChangeDetectionStrategy, Component, forwardRef, OnInit } from '@angular/core';
import { MiniApps } from '../../../shared/models/mini-app.model';
import { Breadcrumb } from '../../../store/models';
import { AppService } from '../../../store/services';
import { Observable } from 'rxjs';
import { CitationResponse } from '../../../store/models/citation.model';
import { CitationsTileComponent } from '../../components/citations-tile/citations-tile.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { BreadcrumbComponent } from '../../../shared/containers/breadcrumb/breadcrumb.component';
import { TopNavComponent } from '../../../shared/containers/top-nav/top-nav.component';

@Component({
  selector: 'gnx-citations-page',
  templateUrl: './citations-page.component.html',
  styleUrls: ['./citations-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TopNavComponent, BreadcrumbComponent, NgIf, NgFor, CitationsTileComponent, AsyncPipe],
})
export class CitationsPageComponent implements OnInit {
  MiniApps = MiniApps;
  crumbs: Breadcrumb[];

  citationsResponse$: Observable<CitationResponse>;

  constructor(public appService: AppService) {}

  ngOnInit(): void {
    this.crumbs = [
      {
        name: 'Search page',
        route: '/',
      },
      {
        name: 'Franklin Mentions',
        route: '/citations',
      },
    ];
    this.citationsResponse$ = this.appService.getCitations();
  }
}
