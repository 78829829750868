import {
  ApplicationType,
  AssessmentToolsComponent,
  GeneAssessmentComponent,
  MaxiApplicationType,
} from '../../variant-page/modules/variant-page/models';
import { VariantPopupArea } from '../modules/analysis-variant/models';

export function variantPopupAreaToPopupApp(area: VariantPopupArea): ApplicationType | MaxiApplicationType | null {
  switch (area) {
    case VariantPopupArea.Gene:
    case VariantPopupArea.Evidence:
    case VariantPopupArea.Predictions:
    case VariantPopupArea.Frequencies:
    case VariantPopupArea.Transcripts:
    case VariantPopupArea.InternalFrequency:
    case VariantPopupArea.CommunityFrequency:
    case VariantPopupArea.Confidence:
    case VariantPopupArea.SvConfidence:
    case VariantPopupArea.Occurrences:
    case VariantPopupArea.InternalOccurrence:
    case VariantPopupArea.Compound:
    case VariantPopupArea.References:
    case VariantPopupArea.RohSummary:
    case VariantPopupArea.FamilyZygosity:
    case VariantPopupArea.CompoundFamilies:
    case VariantPopupArea.CustomAnnotation:
    case VariantPopupArea.RepeatAssessment:
      return ApplicationType.AssessmentTools;

    case VariantPopupArea.SequenceBrowser:
      return MaxiApplicationType.SequenceBrowser;

    case VariantPopupArea.Conditions:
      return ApplicationType.Conditions;

    case VariantPopupArea.ACMGClassification:
      return ApplicationType.ACMGClassification;

    case VariantPopupArea.ACMGClassificationSV:
      return ApplicationType.ACMGClassificationSV;

    case VariantPopupArea.Publications:
      return ApplicationType.Publications;

    case VariantPopupArea.UserClassification:
      return ApplicationType.Interpretation;

    case VariantPopupArea.GenesAndRegions:
      return ApplicationType.GenesAndRegions;

    case VariantPopupArea.SomaticClinicalEvidence:
      return ApplicationType.ClinicalEvidence;

    case VariantPopupArea.AddSomaticEvidence:
      return ApplicationType.AddSomaticEvidence;

    case VariantPopupArea.GeneAssessment:
    case VariantPopupArea.GeneCuration:
      return ApplicationType.GeneAssessment;

    case VariantPopupArea.InterpretationHistory:
      return ApplicationType.InterpretationHistory;

    case VariantPopupArea.CommunityClassification:
      return ApplicationType.CommunityClassification;

    case VariantPopupArea.OncogenicClassification:
      return ApplicationType.OncogenicClassification;

    default:
      return null;
  }
}

export function variantPopupAreaToPopupWidget(
  area: VariantPopupArea,
): AssessmentToolsComponent | GeneAssessmentComponent | null {
  switch (area) {
    case VariantPopupArea.Evidence:
      return AssessmentToolsComponent.Evidence;

    case VariantPopupArea.Predictions:
      return AssessmentToolsComponent.Predictions;

    case VariantPopupArea.Frequencies:
      return AssessmentToolsComponent.Frequencies;

    case VariantPopupArea.Transcripts:
      return AssessmentToolsComponent.Transcripts;

    case VariantPopupArea.SequenceBrowser:
      return AssessmentToolsComponent.SequenceBrowser;

    case VariantPopupArea.InternalFrequency:
      return AssessmentToolsComponent.InternalFrequency;

    case VariantPopupArea.InternalOccurrence:
      return AssessmentToolsComponent.InternalOccurrence;

    case VariantPopupArea.Occurrences:
      return AssessmentToolsComponent.Occurrences;

    case VariantPopupArea.Confidence:
      return AssessmentToolsComponent.SomaticConfidence;

    case VariantPopupArea.SvConfidence:
      return AssessmentToolsComponent.SvConfidence;

    case VariantPopupArea.Compound:
      return AssessmentToolsComponent.Compound;

    case VariantPopupArea.CommunityFrequency:
      return AssessmentToolsComponent.CommunityFrequencyDetailed;

    case VariantPopupArea.References:
      return AssessmentToolsComponent.References;

    case VariantPopupArea.FamilyZygosity:
      return AssessmentToolsComponent.FamilyZygosity;

    case VariantPopupArea.CompoundFamilies:
      return AssessmentToolsComponent.CompoundFamilies;

    case VariantPopupArea.RohSummary:
      return AssessmentToolsComponent.RohSummary;

    case VariantPopupArea.CustomAnnotation:
      return AssessmentToolsComponent.CustomAnnotation;

    case VariantPopupArea.RepeatAssessment:
      return AssessmentToolsComponent.RepeatAssessment;

    default:
      return null;
  }
}
