import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApplicationType, ApplicationTypeEnumValues, VariantId, VariantPageConfig } from '../models';
import { Observable } from 'rxjs';
import { PreloadDataRequest } from '../models/preload-data-request';
import { VariantPageLibConfigService } from './variant-page-lib-config.service';
import { PreloadServiceConfig, VariantPageLibServiceType } from '../../../store';

@Injectable()
export class PreloadService {
  private applicationTypeToId: { [key in ApplicationTypeEnumValues]: number } = {
    [ApplicationType.CommunityClassification]: 0,
    [ApplicationType.CommunityClassificationDemoApp]: 1,
    [ApplicationType.ACMGClassification]: 2,
    [ApplicationType.ACMGClassificationSV]: 3,
    [ApplicationType.AssessmentTools]: 4,
    [ApplicationType.Publications]: 5,
    [ApplicationType.GeneAssessment]: 6,
    [ApplicationType.ClinicalTrials]: 7,
    [ApplicationType.ClinicalEvidence]: 8,
    [ApplicationType.Interpretation]: 9,
    [ApplicationType.InterpretationHistory]: 10,
    [ApplicationType.Conditions]: 11,
    [ApplicationType.GenesAndRegions]: 12,
    [ApplicationType.SimilarCases]: 13,
    [ApplicationType.ContactNetwork]: 14,
    [ApplicationType.OncogenicClassification]: 15,
    [ApplicationType.CuratedGeneData]: 16,
  };

  constructor(private http: HttpClient) {}

  get serviceConfig(): PreloadServiceConfig {
    return VariantPageLibConfigService.getServiceConfig(VariantPageLibServiceType.PreloadService);
  }

  public preloadData(variantId: VariantId, config: VariantPageConfig): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    const body = this.buildPayloadRequestBody(variantId, config);

    return this.http.post<any>(this.serviceConfig.preloadData, body, { headers });
  }

  buildPayloadRequestBody(variantId: VariantId, config: VariantPageConfig): PreloadDataRequest {
    const requestBody: PreloadDataRequest = {
      forApplications: this.getApplicationsValues(config.applications),
    };
    if ('sv_type' in variantId) {
      requestBody.sv = {
        chr: variantId.chr,
        startPos: variantId.start_pos,
        endPos: variantId.end_pos,
        svType: variantId.sv_type,
        ciPos: variantId.ci_pos,
        ciEnd: variantId.ci_end,
        mate: variantId.mate
          ? {
              chr: variantId.mate.chr,
              startPos: variantId.mate.start_pos,
              endPos: variantId.mate.end_pos,
            }
          : undefined,
      };
      if (variantId.annotationVersion) {
        requestBody.sv.version = variantId.annotationVersion;
      }
      if (variantId.referenceGenome) {
        requestBody.sv.referenceVersion = variantId.referenceGenome;
      }
    } else if (!('start_pos' in variantId)) {
      requestBody.snp = {
        chr: variantId.chr,
        pos: variantId.pos,
        ref: variantId.ref,
        alt: variantId.alt,
      };
      if (variantId.annotationVersion) {
        requestBody.snp.version = variantId.annotationVersion;
      }
      if (variantId.referenceGenome) {
        requestBody.snp.referenceVersion = variantId.referenceGenome;
      }
    }
    return requestBody;
  }

  getApplicationsValues(apps: ApplicationType[]): number[] {
    return apps.map((val) => this.applicationTypeToId[val]);
  }
}
