import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClickCursorDirective } from '../../directives/click-cursor.directive';

@Component({
  selector: 'gnx-variant-type-toggle',
  templateUrl: './variant-type-toggle.component.html',
  styleUrls: ['./variant-type-toggle.component.scss'],
  standalone: true,
  imports: [ClickCursorDirective],
})
export class VariantTypeToggleComponent {
  @Input() isTumor: boolean;
  @Output() isTumorChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}
}
