import { createSelector, MemoizedSelector } from '@ngrx/store';
import * as fromReducer from '../reducers/auth.reducer';
import { AuthState } from '../reducers/auth.reducer';
import { AuthModuleState, getAuthModuleState } from '../reducers';
import { FeatureBit, InviteeDataResponse, TokenDetails, UserDetails, UserPermission } from '../../models';
import { Organization } from '../../../../knowledge-base/modules/variants/models';
import { AzureErrors } from '../../../../login-page/containers/login-page/login-page.data';
import { UserUtils } from '../../utils/user.utils';

const getAuthState: MemoizedSelector<AuthModuleState, AuthState> = createSelector(
  getAuthModuleState,
  (state: AuthModuleState) => state.auth,
);

export const getTokenDetails: MemoizedSelector<AuthModuleState, TokenDetails> = createSelector(
  getAuthState,
  fromReducer.getTokenDetails,
);

export const getUserDetails: MemoizedSelector<AuthModuleState, UserDetails> = createSelector(
  getAuthState,
  fromReducer.getUserDetails,
);

export const getJoinStep: MemoizedSelector<AuthModuleState, number> = createSelector(
  getAuthState,
  fromReducer.getJoinStep,
);

export const getIsLoggedIn: MemoizedSelector<AuthModuleState, boolean> = createSelector(
  getUserDetails,
  (userDetails: UserDetails) => !!userDetails && !!userDetails.organization,
);

export const getAuthErrorDescription: MemoizedSelector<
  AuthModuleState,
  { login?: string; signUp?: string; reset?: string; setInfo?: string; changePassword?: string }
> = createSelector(getAuthState, fromReducer.getAuthErrorDescription);

export const getSignUpInProgress: MemoizedSelector<AuthModuleState, boolean> = createSelector(
  getAuthState,
  fromReducer.getSignUpInProgress,
);

export const getLoginInProgress: MemoizedSelector<AuthModuleState, boolean> = createSelector(
  getAuthState,
  fromReducer.getLoginInProgress,
);

export const getInviteeData: MemoizedSelector<AuthModuleState, InviteeDataResponse> = createSelector(
  getAuthState,
  fromReducer.getInviteeData,
);

export const getFeatureBits: MemoizedSelector<AuthModuleState, { [key in FeatureBit]?: boolean }> = createSelector(
  getUserDetails,
  (userDetails) => {
    return userDetails ? userDetails.feature_bits || {} : {};
  },
);

export const getIsUserSuperAdmin: MemoizedSelector<AuthModuleState, boolean> = createSelector(
  getUserDetails,
  (userDetails) => !!userDetails?.is_super_admin,
);

export const getUserSigs: MemoizedSelector<AuthModuleState, string[]> = createSelector(getUserDetails, (userDetails) =>
  userDetails ? userDetails.user_sigs || [] : undefined,
);

export const getUpdateUserInProgress: MemoizedSelector<AuthModuleState, boolean> = createSelector(
  getAuthState,
  fromReducer.getUpdateUserInProgress,
);

export const getInviteUsersInProgress: MemoizedSelector<AuthModuleState, boolean> = createSelector(
  getAuthState,
  fromReducer.getInviteUsersInProgress,
);

export const getUserOrganization: MemoizedSelector<AuthModuleState, Organization> = createSelector(
  getUserDetails,
  (user) => user?.organization,
);

export const getUserCountry: MemoizedSelector<AuthModuleState, string> = createSelector(
  getUserDetails,
  (user) => user?.country,
);

export const getUserPermissions: MemoizedSelector<AuthModuleState, { allowed_actions: UserPermission[] }> =
  createSelector(getUserDetails, (user) => user?.permissions);

export const getUserAllowedPermissions: MemoizedSelector<AuthModuleState, UserPermission[]> = createSelector(
  getUserPermissions,
  (permissions) => permissions?.allowed_actions || [],
);

export const getIsUserOrgAdmin: MemoizedSelector<AuthModuleState, boolean> = createSelector(
  getUserDetails,
  (userDetails) => UserUtils.isUserAnAdmin(userDetails),
);

export const getAzureAuthErrors: MemoizedSelector<AuthModuleState, AzureErrors> = createSelector(
  getAuthState,
  fromReducer.getAzureAuthErrorDescription,
);

export const getIsEnterpriseConnection: MemoizedSelector<AuthModuleState, boolean> = createSelector(
  getUserDetails,
  (userDetails) => !!userDetails.auth0_id && !userDetails.auth0_id?.startsWith('auth0'),
);

export const getMfaToken: MemoizedSelector<AuthModuleState, string> = createSelector(
  getAuthState,
  (state) => state.mfaToken,
);

export const getMfaEnrollmentUri: MemoizedSelector<AuthModuleState, string> = createSelector(
  getAuthState,
  (state) => state.mfaEnrollmentUri,
);
