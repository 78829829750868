import { AnalysisResult, AnalysisSingleVariant, ECSResult, VariantActionValue } from '../../../analysis-variant/models';
import * as fromAction from './../actions/ecs-analysis-results.action';
import { getVariantId } from '../../utils/analysis-result.utils';
import * as fromInterpretation from '../actions/variant-interpretation.action';

export interface ECSAnalysisResultsState {
  data: ECSResult[];
  loading: boolean;
  loaded: boolean;
}

const initialState: ECSAnalysisResultsState = {
  data: [],
  loading: false,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: fromAction.ECSAnalysisResultsAction | fromInterpretation.VariantInterpretationAction,
): ECSAnalysisResultsState {
  switch (action.type) {
    case fromAction.LOAD_ECS_ANALYSIS_RESULTS: {
      return {
        ...state,
        data: [],
        loading: true,
        loaded: false,
      };
    }
    case fromAction.LOAD_ECS_ANALYSIS_RESULTS_SUCCESS: {
      return {
        ...state,
        data: action.data,
        loading: false,
        loaded: true,
      };
    }
    case fromAction.LOAD_ECS_ANALYSIS_RESULTS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case fromInterpretation.TAKE_BULK_VARIANT_ACTION_SUCCESS: {
      // If it's a workbench variant action with false (remove) value, and we don't have count state (workbench doesn't have pagination)
      if (action.actionType === VariantActionValue.in_workbench && !action.value) {
        // remove from the workbench variants list on bulk remove from workbench
        return {
          ...state,
          data: state.data.reduce<ECSResult[]>((acc, result) => {
            const female = result?.variants?.female?.filter(
              (variant) => !action.variantIds.some((id) => getVariantId(variant.data) === id),
            );
            const male = result?.variants?.male?.filter(
              (variant) => !action.variantIds.some((id) => getVariantId(variant.data) === id),
            );

            return female?.length || male?.length
              ? [
                  ...acc,
                  {
                    ...result,
                    variants: { female, male },
                  },
                ]
              : acc;
          }, []),
        };
      }
      return state;
    }
    case fromAction.CLEAR_ECS_ANALYSIS_RESULTS: {
      return { ...initialState };
    }
  }

  return state;
}
