import { createSelector, MemoizedSelector, MemoizedSelectorWithProps } from '@ngrx/store';
import { SigVariantsState } from '../reducers/sig-variants.reducer';
import { getSigState, SigState } from '../reducers';
import { SIG_TAB_PAGE_SIZE, SigTabCounts } from '../../models';
import { AnalysisResult } from '../../../analysis/modules/analysis-variant/models';
import { SigClassifiedVariant } from '../../models/sig-variant.model';

const getSigVariantsState: MemoizedSelector<SigState, SigVariantsState> = createSelector(
  getSigState,
  (state) => state.variants,
);

export const getSigSuggestedVariantsCount: MemoizedSelector<SigState, SigTabCounts> = createSelector(
  getSigVariantsState,
  (state) => state.suggestedVariantsCount,
);

export const getSigSuggestedVariantsCountLoading: MemoizedSelector<SigState, boolean> = createSelector(
  getSigVariantsState,
  (state) => state.loadingSuggestedCount,
);

export const getSigSuggestedVariantsCountLoaded: MemoizedSelector<SigState, boolean> = createSelector(
  getSigVariantsState,
  (state) => state.loadedSuggestedCount,
);

export const getSigSuggestedVariants: MemoizedSelector<SigState, AnalysisResult[]> = createSelector(
  getSigVariantsState,
  (state) => state.suggestedVariants,
);

export const getSigSuggestedVariantsLoading: MemoizedSelector<SigState, boolean> = createSelector(
  getSigVariantsState,
  (state) => state.loadingSuggested,
);

export const getSigSuggestedVariantsLoaded: MemoizedSelector<SigState, boolean> = createSelector(
  getSigVariantsState,
  (state) => state.loadedSuggested,
);

export const getSigSuggestedVariantsMaxPageNo: MemoizedSelector<SigState, number> = createSelector(
  getSigSuggestedVariantsCount,
  (count) => (count ? Math.ceil(count.num_variants / SIG_TAB_PAGE_SIZE) : 100),
);

export const getSigSuggestedVariantsCurrentPage: MemoizedSelector<SigState, number> = createSelector(
  getSigVariantsState,
  (state) => state.currentPage.suggested,
);

/*
 * Classified Variants
 * */
export const getSigClassifiedVariantsCount: MemoizedSelector<SigState, SigTabCounts> = createSelector(
  getSigVariantsState,
  (state) => state.classifiedVariantsCount,
);

export const getSigClassifiedVariants: MemoizedSelector<SigState, SigClassifiedVariant[]> = createSelector(
  getSigVariantsState,
  (state) => state.classifiedVariants,
);

export const getSigClassifiedVariantsLoading: MemoizedSelector<SigState, boolean> = createSelector(
  getSigVariantsState,
  (state) => state.loadingClassified,
);

export const getSigClassifiedVariantsLoaded: MemoizedSelector<SigState, boolean> = createSelector(
  getSigVariantsState,
  (state) => state.loadedClassified,
);

export const getSigClassifiedVariantsMaxPageNo: MemoizedSelector<SigState, number> = createSelector(
  getSigClassifiedVariantsCount,
  (count) => (count ? Math.ceil(count.num_variants / SIG_TAB_PAGE_SIZE) : 100),
);

export const getSigClassifiedVariantsCurrentPage: MemoizedSelector<SigState, number> = createSelector(
  getSigVariantsState,
  (state) => state.currentPage.classified,
);

export const getVariantsCategoryFilters: MemoizedSelectorWithProps<
  SigState,
  {
    category: string;
  },
  any
> = createSelector(getSigVariantsState, (state, props) => state.categoriesFilters[props.category]);
