import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as fromAction from '../actions/organization-custom-schema.action';
import * as fromAuthStore from './../../modules/auth0/store';
import { AppService } from '../services';

@Injectable()
export class OrganizationCustomSchemaEffect {
  constructor(
    private actions$: Actions,
    private service: AppService,
  ) {}

  loadOrganizationCustomFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthStore.LOAD_USER_DETAILS_SUCCESS),
      switchMap(() => [new fromAction.GetOrganizationCustomSchema()]),
    ),
  );

  getOrganizationCustomFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GET_ORGANIZATION_CUSTOM_SCHEMA),
      switchMap((action: fromAction.GetOrganizationCustomSchemaSuccess) =>
        this.service.getOrgCustomSchema().pipe(
          map((schema) => new fromAction.GetOrganizationCustomSchemaSuccess(schema)),
          catchError((error) => of(new fromAction.GetOrganizationCustomSchemaFail(error))),
        ),
      ),
    ),
  );
}
