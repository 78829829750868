import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromAction from './../actions/interpretation-history.action';

import { InterpretationService } from '../../services/interpretation.service';

@Injectable()
export class InterpretationHistoryEffect {
  constructor(
    private actions$: Actions,
    private interpretationService: InterpretationService,
  ) {}

  loadInterpretationHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_INTERPRETATION_HISTORY),
      switchMap((action: fromAction.LoadInterpretationHistory) =>
        this.interpretationService.getInterpretationHistory(action.variant).pipe(
          switchMap((data) => [new fromAction.LoadInterpretationHistorySuccess(data)]),
          catchError((error) => of(new fromAction.LoadInterpretationHistoryFail(error))),
        ),
      ),
    ),
  );
}
