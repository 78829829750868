import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { delay, forkJoin, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {
  AnalysisResult,
  AnalysisSingleVariant,
  CosmicFrequency,
  SnpResult,
  SomaticVariantInterpretation,
  VariantInterpretation,
  VariantLabel,
  VariantLabelFields,
  VariantLabelsResponse,
  VariantType,
} from '../../analysis-variant/models';
import {
  EXPORT_VARIANT_TYPE,
  ExportAsExcelModel,
  MAX_VARIANTS_EXPORT_EXCEL_EMAIL,
} from '../../variants/models/export-as-excel.model';
import { VariantsCount } from '../models';
import { environment } from '../../../../../../environments/environment';
import { AnalysisType } from '../../../../../store/models';
import { AnalysisLean, AnalysisLeanListResponse } from '../../analyses/store';

@Injectable({ providedIn: 'root' })
export class JsonService {
  variantLabelsResponse$: Observable<VariantLabelsResponse>;

  constructor(private http: HttpClient) {}

  getCosmicFrequency(cosmicIds: string[], disease: string, annotationVersion?: number): Observable<CosmicFrequency> {
    const url = `${environment.beBaseURL}/api/api/variant/cosmic`;
    let params = new HttpParams().append('disease', disease);

    if (annotationVersion) {
      params = params.append('annotation_version', annotationVersion.toString());
    }

    for (const cosmic of cosmicIds) {
      params = params.append('cosmic_id', cosmic);
    }

    return this.http.get<CosmicFrequency>(url, { params });
  }

  getAnalysisVariants(
    queryParams,
    analysisId: number,
    analysisType: AnalysisType,
    variantType = VariantType.All,
  ): Observable<AnalysisSingleVariant[]> {
    const url = `${environment.beBaseURL}/api/api/analysis/${analysisId.toString()}/${variantType}/variants/`;
    // const url = `/assets/json/loh-variants-results.json`;
    // const url = `/assets/json/family-snp-variants-results.json`;
    // const url = `/assets/json/family-sv-variants-results.json`;

    return this.http.get<AnalysisSingleVariant[]>(url, { params: queryParams }).pipe(map((data) => (data as any).data));
  }

  getAnalysisVariantsExcel(
    queryParams,
    analysisId: number,
    variantType = VariantType.All,
    isLink = false,
    maxOnlineExport = MAX_VARIANTS_EXPORT_EXCEL_EMAIL,
  ): Observable<any | ExportAsExcelModel> {
    if (queryParams.page_size > maxOnlineExport || variantType === VariantType.SV) {
      return this.getAnalysisVariantsExcelByEmail(queryParams, analysisId, variantType);
    }

    const url = `${environment.beBaseURL}/api/api/analysis/${analysisId.toString()}/${variantType}/variants/`;
    if (isLink) {
      return this.http.get(url, { params: queryParams });
    } else {
      return this.http.get(url, { params: queryParams, responseType: 'blob' });
    }
  }

  getAnalysisVariantsExcelByEmail(
    queryParams,
    analysisId: number,
    variantType = VariantType.All,
  ): Observable<ExportAsExcelModel> {
    const url = `${
      environment.beBaseURL
    }/api/api/analysis/${analysisId.toString()}/${variantType}/variants/offline-export`;
    return this.http.get<ExportAsExcelModel>(url, { params: queryParams });
  }

  getAnalysisVariantsDetails(
    queryParams,
    analysisId: number,
    variantType = VariantType.All,
  ): Observable<AnalysisSingleVariant[] | any[]> {
    const url = `${
      environment.beBaseURL
    }/api/api/analysis/${analysisId.toString()}/${variantType}/variants/full-details/`;
    return this.http.get(url, { params: queryParams }).pipe(map((data) => (data as any).data));
  }

  getAnalysisVariantsCount(queryParams, analysisId, variantType = VariantType.All): Observable<VariantsCount> {
    const url = `${environment.beBaseURL}/api/api/analysis/${analysisId.toString()}/${variantType}/variants/count/`;
    return this.http.get<VariantsCount>(url, { params: queryParams });
  }

  updateSomaticVariantInterpretation(analysisId: number, varId: string, interpretation: SomaticVariantInterpretation) {
    const url = `${environment.beBaseURL}/api/api/analysis/somatic_interpretation/${analysisId}/${varId}`;
    const headers: HttpHeaders = new HttpHeaders();
    headers.set('Content-Type', 'application/json');

    return this.http.post(url, interpretation, { headers });
  }

  fetchVariantInterpretation(analysisId: number, varId: string) {
    const url = `${environment.beBaseURL}/api/api/analysis/somatic_interpretation/${analysisId}/${varId}`;
    const headers: HttpHeaders = new HttpHeaders();
    headers.set('Content-Type', 'application/json');

    return this.http.get(url, { headers });
  }

  bulkUpdateVariantInterpretation(
    analysisId: number,
    variantIds: string[],
    compoundVariants?: {
      first_variant: { var_id: string; relation: string };
      second_variant: { var_id: string; relation: string };
    }[],
    relevance?: {
      reasons: string[];
      reasoning_details?: string;
    },
    workbench?: {
      add: boolean;
    },
    report?: {
      add: boolean;
      bin_name: string;
    },
    significance?: {
      bin_name: string;
      reason?: string;
      reasoning_details?: string;
    },
  ): Observable<{ [key: string]: any }> {
    const url = `${environment.beBaseURL}/api/analysis/variant/edit_batch`;
    const headers: HttpHeaders = new HttpHeaders();
    headers.set('Content-Type', 'application/json');

    return this.http.post(
      url,
      {
        variant_ids: variantIds,
        compound_variant_ids: compoundVariants,
        analysis_id: analysisId,
        relevance_action_params: relevance,
        in_workbench_action_params: workbench,
        in_report_action_params: report?.bin_name || !report?.add ? undefined : report,
        reporting_category_params: report?.bin_name || !report?.add ? report : undefined,
        clinical_significance_params: significance,
      },
      { headers },
    );
  }

  updateVariantInterpretation(
    analysisId: number,
    varId: string,
    data: VariantInterpretation,
    variantType = VariantType.SNP,
    compoundVarId?: string,
  ): Observable<{ [key: string]: any }> {
    const urls = varId
      .split('_')
      .map(
        (id) =>
          `${
            environment.beBaseURL
          }/api/api/analysis/${analysisId.toString()}/variant/interpretation?variant_id=${encodeURIComponent(
            id,
          )}&variant_type=${variantType}`,
      )
      .map((url) => (!!compoundVarId ? `${url}&compound_variant_id=${encodeURIComponent(compoundVarId)}` : url));

    return forkJoin(urls.map((url) => this.http.patch(url, data))).pipe(map((result) => result[0]));
  }

  getSingleAnalyses(
    search: string,
    variantType?: VariantType,
    isReady?: boolean,
    pageSize?: number,
  ): Observable<AnalysisLeanListResponse> {
    const url = `${environment.beBaseURL}/api/api/analysis/list_single_analyses`;
    // const url = `assets/json/list-single-analyses.json`;
    let params = new HttpParams();

    if (pageSize) {
      params = params.append('page_size', pageSize);
    }
    if (search) {
      params = params.append('search', search);
    }
    if (variantType) {
      params = params.append('variant_type', variantType);
    }
    if (isReady) {
      params = params.append('is_ready', isReady.toString());
    }

    return this.http.get<AnalysisLeanListResponse>(url, { params });
  }

  getMoreSingleAnalyses(nextUrl: string): Observable<AnalysisLeanListResponse> {
    const url = `${environment.beBaseURL}/api/api/analysis/list_single_analyses?${nextUrl.split('?')[1]}`;
    return this.http.get<AnalysisLeanListResponse>(url);
  }

  getAnalysis(analysisId: number): Observable<AnalysisLean> {
    const url = `${environment.beBaseURL}/api/api/analysis/${analysisId}`;
    return this.http.get<AnalysisLean>(url, {});
  }

  getVariantLabels(): Observable<VariantLabelsResponse> {
    if (!this.variantLabelsResponse$) {
      const url = `${environment.beBaseURL}/api/variant/labels/options`;
      this.variantLabelsResponse$ = this.http.get<VariantLabelsResponse>(url, {}).pipe(shareReplay(1));
    }
    return this.variantLabelsResponse$;
  }

  addRemoveLabel(
    label: VariantLabel,
    variantId: string,
    analysisId: number,
    variantType: VariantType,
    remove: boolean,
    comment?: string,
    fields?: VariantLabelFields[],
  ): Observable<{ var_id: string; analysis_id: string; labels: VariantLabel[] }> {
    return this.http
      .post<any>(`${environment.beBaseURL}/api/analysis/variant/labels/${remove ? 'remove' : 'add'}`, {
        var_id: variantId,
        analysis_id: analysisId,
        label,
        variant_type: EXPORT_VARIANT_TYPE[variantType],
        comment,
        label_fields: fields,
      })
      .pipe(delay(500));
  }

  explainConfidence(variant: AnalysisResult, analysisId: number, referenceVersion: string): Observable<string> {
    const url = `${environment.beBaseURL}/api/ai/confidence_reason`;
    const snpResult = variant.data as SnpResult;
    const body = {
      chr: snpResult.chr,
      pos: snpResult.start_pos,
      ref: snpResult.ref,
      alt: snpResult.obs,
      analysis_id: analysisId,
      reference_version: referenceVersion,
      transcript: snpResult.transcript,
    };

    return this.http.post<{ confidence_reason: string }>(url, body).pipe(map((res) => res.confidence_reason));
  }
}
