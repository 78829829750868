import { DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';
import sanitizeHtml, { IOptions } from 'sanitize-html';

export const SANITIZATION_OPTIONS: IOptions = {
  allowedAttributes: {
    '*': ['style'],
  },
  allowedStyles: {
    '*': {
      // Match HEX and RGB
      color: [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
      'background-color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
      'text-align': [/^left$/, /^right$/, /^center$/],
      // Match any number with px, em, or %
      'font-size': [/^\d+(?:px|em|%)$/],
    },
    p: {
      'font-size': [/^\d+rem$/],
    },
  },
};

@Pipe({
  name: 'sanitizeHtml',
  standalone: true,
})
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value) {
    value = sanitizeHtml(value, SANITIZATION_OPTIONS);
    value = this.insertTableBorders(value);
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  insertTableBorders(value: string): string {
    value = value.replace(
      /<table\s*(?:style="(.*?))?"?>/g,
      '<table style="border: 1px solid #e3e3e3; border-collapse: collapse; $1">',
    );
    value = value.replace(
      /<th\s*(?:style="(.*?))?"?>/g,
      '<th style="border: 1px solid #e3e3e3; border-collapse: collapse; $1">',
    );
    value = value.replace(
      /<td\s*(?:style="(.*?))?"?>/g,
      '<td style="border: 1px solid #e3e3e3; border-collapse: collapse; $1">',
    );
    return value;
  }
}
