import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { authReducers } from './store/reducers';
import { authEffects } from './store/effects';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { AuthService } from './services/auth.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EnvRedirectPopupComponent } from './containers/env-redirect-popup/env-redirect-popup.component';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('auth0', authReducers),
    EffectsModule.forFeature(authEffects),
    MatSnackBarModule,
    EnvRedirectPopupComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
      deps: [AuthService],
    },
  ],
})
export class Auth0Module {}
