<div class="dialog-header-container">
  <div class="small-header-label">ORGANIZATION LISTS</div>
  <div class="large-header-label">Variant Lists</div>
  <img class="close-btn" src="assets/svg/x-icon.svg" width="14" height="14" (click)="onClose()" />
</div>
<mat-dialog-content class="dialog-body">
  @if (loading$ | async) {
    <gnx-dots-loader gnxBlock margin="30px 0 0 0" />
  } @else if (loaded$ | async) {
    @if ((lists$ | async)?.length) {
      <div gnxFlex [column]="true" gap="6px">
        @for (list of lists$ | async; track $index) {
          <div
            gnxBlock
            background="#fff"
            padding="6px 10px"
            border="1px solid #D5D7DB"
            borderRadius="5px"
            gnxFlex
            gap="4px"
            justify="space-between"
            align="center"
            cursor="pointer"
            (click)="selected[list.name] = !selected?.[list.name]"
          >
            <gnx-checkbox
              [checked]="!!selected?.[list.name]"
              (checkedChange)="selected[list.name] = $event"
              gnxText
              fontWeight="700"
              size="20px"
              gnxTextOverflowEllipsis
              #listName="gnxTextOverflowEllipsis"
              [matTooltip]="listName.tooltipText"
              [matTooltipDisabled]="!listName.isOverflowed"
              matTooltipClass="blue-md-tooltip wide multi-line"
            >
              {{ list.name }}
            </gnx-checkbox>
            <div gnxText size="12px"></div> <!--ToDo add date when BE returns it -->
          </div>
        }
      </div>
    } @else {
      <div gnxText size="14px" color="#727272" textAlign="center">No variant lists found</div>
    }
  } @else if (!!(error$ | async)) {
    <div gnxText color="#FF5252" size="14px" textAlign="center">An error occurred loading variant lists</div>
  }
</mat-dialog-content>
<mat-dialog-actions gnxFlex justify="space-between" gnxBlock border="1px 0 0 0 solid #d5d7db" margin="0 20px">
  <div gnxFlex gap="10px">
    <div><strong>Case level</strong> - mark variants as:</div>
    <gnx-checkbox [checked]="!!notRelevant" (checkedChange)="notRelevant = $event" [disabled]="!!addToWb">
      Not Relevant
    </gnx-checkbox>
    <gnx-checkbox [checked]="!!addToWb" (checkedChange)="addToWb = $event" [disabled]="!!notRelevant">
      Add to Workbench
    </gnx-checkbox>
  </div>
  <div gnxFlex>
    <a (click)="onClose()">Cancel</a>
    <button [disabled]="!hasChanges"(click)="onSave()">Apply</button>
  </div>
</mat-dialog-actions>
