import { Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { AsyncPipe, NgClass, NgFor, NgIf, TitleCasePipe } from '@angular/common';

import { select, Store } from '@ngrx/store';

import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';

import * as fromAuthStore from '../../../modules/auth0/store';
import { getFeatureBits, SetRedirectAfterAuth } from '../../../modules/auth0/store';
import { FeatureBit, UserDetails } from '../../../modules/auth0/models';
import { Observable } from 'rxjs';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { RouterLink } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { MiniApps } from '../../models';
import { UserUtils } from '../../../modules/auth0/utils/user.utils';
import { I18nPipe } from '../../i18n';
import { MatTooltip } from '@angular/material/tooltip';
import { TextOverflowEllipsisDirective } from '../../directives/text-overflow-ellipsis.directive';
import { LanguageSelectorComponent } from '../../i18n/language-selector/language-selector.component';
import { ClickCursorDirective } from '../../directives/click-cursor.directive';
import { AnalysisSearchBarComponent } from '../../components/analysis-search-bar/analysis-search-bar.component';
import { SearchBarComponent } from '../../components/search-bar/search-bar.component';
import { RtlDirective } from '../../i18n/rtl.directive';

@Component({
  selector: 'gnx-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    RtlDirective,
    NgIf,
    SearchBarComponent,
    AnalysisSearchBarComponent,
    NgFor,
    MatMenuTrigger,
    ClickCursorDirective,
    NgClass,
    LanguageSelectorComponent,
    MatMenu,
    MatMenuItem,
    TextOverflowEllipsisDirective,
    MatTooltip,
    AsyncPipe,
    TitleCasePipe,
    I18nPipe,
  ],
})
export class TopNavComponent implements OnInit {
  @Input() @HostBinding('class.small') small: boolean;
  @Input() activeMiniApp: MiniApps;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  private userAvailable: boolean;

  userDetails$: Observable<UserDetails>;
  isProperlyLoggedIn$: Observable<boolean>;
  showAssets$: Observable<boolean>;
  isAdmin: boolean;
  featureBits$: Observable<{ [id: string]: boolean }>;
  FeatureBit = FeatureBit;
  languageSelectorEnabled = false;

  readonly MINI_APPS = [
    {
      app: MiniApps.ClinicalDB,
      label: 'SEARCH',
      icon: 'assets/svg/clinical-db.svg',
      path: '/clinical-db/home',
    },
    {
      app: MiniApps.MyVariants,
      label: 'KNOWLEDGE_BASE',
      icon: 'assets/svg/my-variants.svg',
      path: '/clinical-db/knowledge-base',
    },
    {
      app: MiniApps.AnalysisTool,
      label: 'MY_CASES',
      icon: 'assets/svg/analyze-tool.svg',
      path: '/analysis-tool',
    },
  ];

  constructor(
    private authStore$: Store<fromAuthStore.AuthModuleState>,
    private ngxZendeskService: NgxZendeskWebwidgetService,
  ) {}

  ngOnInit() {
    this.userDetails$ = this.authStore$.pipe(
      select(fromAuthStore.getUserDetails),
      tap((userDetails) => {
        this.userAvailable = !!userDetails;
        if (this.userAvailable) {
          this.isAdmin = UserUtils.isUserAnAdmin(userDetails);
        }
      }),
    );
    this.isProperlyLoggedIn$ = this.authStore$.pipe(select(fromAuthStore.getIsLoggedIn));
    this.featureBits$ = this.authStore$.pipe(select(getFeatureBits));

    this.showAssets$ = this.authStore$.pipe(
      select(getFeatureBits),
      map((fb) => !!fb[FeatureBit.Assets] || fb[FeatureBit.Samples]),
    );

    window.addEventListener('keydown', (event) => {
      if (event.ctrlKey && event.shiftKey && event.code === 'KeyL') {
        this.languageSelectorEnabled = !this.languageSelectorEnabled;
      }
    });
  }

  logout() {
    this.authStore$.dispatch(new fromAuthStore.Logout());
  }

  setRedirectAfterAuth() {
    this.authStore$.dispatch(new SetRedirectAfterAuth());
  }

  openZendeskPopup() {
    this.ngxZendeskService.zE('webWidget', 'open');
  }

  goToHelpPage() {
    window.open('http://help.genoox.com/en/', '_BLANK');
  }

  showVariantSearchBar() {
    return window.location.pathname.includes('/clinical-db/variant');
  }

  showAnalysisSearchBar() {
    return window.location.pathname.includes('/analysis-tool/analysis');
  }

  learnMoreClicked() {
    if (!this.learnMoreDiscovered) {
      localStorage.setItem('learnMoreDiscovered', 'true');
    }
  }

  get learnMoreDiscovered() {
    return localStorage.getItem('learnMoreDiscovered');
  }
}
