import { SomaticEvidence } from '../../../analysis-variant/models';

export interface CaseSummary {
  treatments_data: TreatmentsData;
}

export interface TreatmentsData {
  treatments: Treatment[];
  num_drugs: number;
  num_trials: number;
}

export interface Treatment {
  id: string;
  drugs: Drug[];
  relevant_biomarkers: RelevantBiomarker[];
  evidence_summary: EvidenceSummary;
  evidence_details: EvidenceDetail[];
  in_report: boolean;
}

export interface Drug {
  drug_name: string;
  drug_commercial_name?: string;
  drug_type?: string;
}

export interface RelevantBiomarker {
  indication: string;
  biomarker: Biomarker;
  is_responsive: boolean;
}

export interface Biomarker {
  biomarker_type: string;
  sample_biomarker_value: string;
  genes: string[];
  pdot?: string;
  cdot?: string;
  sv_type?: string;
}

export interface EvidenceSummary {
  evidence_level: string;
  approval_status: string;
  indication: string;
  clinical_significance?: string;
  is_responsive: boolean;
}

export interface EvidenceDetail {
  evidence: SomaticEvidence;
  biomarkers: Biomarker[];
}
