import { Action } from '@ngrx/store';
import { VariantAssessment, VariantAssessmentsByCondition } from '../../models';
import { SnpVariantId } from '../../../variant-page/models';

export const LOAD_VARIANT_ASSESSMENTS_FAIL = '[Variant Page] Load Variant Assessments Fail';

export class LoadVariantAssessmentsFail implements Action {
  readonly type = LOAD_VARIANT_ASSESSMENTS_FAIL;

  constructor(public error: any) {}
}

export const DELETE_VARIANT_ASSESSMENT = '[Variant Page] Delete Variant Assessment';
export const DELETE_VARIANT_ASSESSMENT_SUCCESS = '[Variant Page] Delete Variant Assessment Success';
export const DELETE_VARIANT_ASSESSMENT_FAIL = '[Variant Page] Delete Variant Assessment Fail';

export class DeleteVariantAssessment implements Action {
  readonly type = DELETE_VARIANT_ASSESSMENT;

  constructor(
    public varId: string,
    public organization_id: string,
  ) {}
}

export class DeleteVariantAssessmentSuccess implements Action {
  readonly type = DELETE_VARIANT_ASSESSMENT_SUCCESS;

  constructor(
    public varId: string,
    public organization_id: string,
  ) {}
}

export class DeleteVariantAssessmentFail implements Action {
  readonly type = DELETE_VARIANT_ASSESSMENT_FAIL;

  constructor(public error: any) {}
}

export const LOAD_JOINT_VARIANT_ASSESSMENTS = '[Variant Page] Load Joint Variant Assessments';
export const LOAD_JOINT_VARIANT_ASSESSMENTS_SUCCESS = '[Variant Page] Load Joint Variant Assessments Success';
export const LOAD_JOINT_VARIANT_ASSESSMENTS_FAIL = '[Variant Page] Load Joint Variant Assessments Fail';

export class LoadJointVariantAssessments implements Action {
  readonly type = LOAD_JOINT_VARIANT_ASSESSMENTS;

  constructor(public variantId: SnpVariantId) {}
}

export class LoadJointVariantAssessmentsSuccess implements Action {
  readonly type = LOAD_JOINT_VARIANT_ASSESSMENTS_SUCCESS;

  constructor(public assessmentsByCondition: VariantAssessmentsByCondition[]) {}
}

export class LoadJointVariantAssessmentsFail implements Action {
  readonly type = LOAD_JOINT_VARIANT_ASSESSMENTS_FAIL;

  constructor(public error: any) {}
}

export type AssessmentsActions =
  | LoadVariantAssessmentsFail
  | DeleteVariantAssessment
  | DeleteVariantAssessmentSuccess
  | DeleteVariantAssessmentFail
  | LoadJointVariantAssessments
  | LoadJointVariantAssessmentsSuccess
  | LoadJointVariantAssessmentsFail;
