import * as fromAction from '../actions';
import {
  AnalysisResult,
  ECSResult,
  getAnalysisResultVariantId,
  VariantActionValue,
  VariantInterpretation,
  VariantInterpretations,
} from '../../../analysis-variant/models';
import { NOT_RELEVANT_SIGNIFICANCE_GROUP } from '../../../workbench/store';
import { getVariantInterpretation } from '../../utils/interpretation.utils';
import { cloneDeep as _cloneDeep } from 'lodash';
import * as fromLabelActions from '../../../variants/store/actions/variant-labels.action';
import * as fromPopupHeaderActions from '../../../../../variant-page/modules/interpretation/store/actions/interpretation-header.action';
import { getCompoundVariantId } from '../../utils/analysis-result.utils';

export interface VariantInterpretationState {
  interpretations: { [varId: string]: VariantInterpretation };
  checkedResults: Set<AnalysisResult>;
  bulkActionLoading: boolean;
}

export const initialState: VariantInterpretationState = {
  interpretations: {},
  checkedResults: new Set(),
  bulkActionLoading: false,
};

const setInterpretation = (
  state: VariantInterpretationState,
  varIds: string[],
  key: string,
  loading: boolean,
  value?: any,
): VariantInterpretationState => {
  const getState = (varKey, val?) =>
    state.interpretations[varKey]
      ? {
          ...state.interpretations[varKey],
          [key]: val !== undefined ? val : state.interpretations[varKey][key],
          loading: {
            ...state.interpretations[varKey].loading,
            [key]: loading,
          },
        }
      : state;

  const variants = varIds.reduce((pre, cur) => ({ ...pre, [cur]: getState(cur, value) }), {});

  return {
    ...state,
    interpretations: {
      ...state.interpretations,
      ...variants,
    },
  };
};

const setClinicalSignificance = (state: VariantInterpretationState, varIds: string[], significance: string) => {
  let newState: VariantInterpretationState = state;
  if (significance) {
    newState = setInterpretation(
      newState,
      varIds,
      VariantActionValue.in_report,
      false,
      significance !== NOT_RELEVANT_SIGNIFICANCE_GROUP,
    );
  }
  newState = setInterpretation(
    newState,
    varIds,
    VariantActionValue.irrelevant,
    false,
    significance === NOT_RELEVANT_SIGNIFICANCE_GROUP,
  );
  return newState;
};

const setCheckedResults = (
  state: VariantInterpretationState,
  analysisResults?: AnalysisResult[],
): VariantInterpretationState => {
  let checkedResults: Set<AnalysisResult> = new Set(state.checkedResults);

  if (!analysisResults) {
    checkedResults.clear();
  } else if (analysisResults.length === 1) {
    const analysisResult = analysisResults[0];
    const found = Array.from(checkedResults).find(
      (result) => getAnalysisResultVariantId(result) === getAnalysisResultVariantId(analysisResult),
    );
    if (found) {
      checkedResults.delete(found);
    } else {
      checkedResults.add(analysisResult);
    }
    // checkedResults.has(analysisResult) ? checkedResults.delete(analysisResult) : checkedResults.add(analysisResult);  ToDo - revert back to this code after full-details flow is killed
  } else {
    checkedResults = new Set<AnalysisResult>(analysisResults);
  }

  return {
    ...state,
    checkedResults,
  };
};

export function reducer(
  state = initialState,
  action:
    | fromAction.VariantInterpretationAction
    | fromAction.ECSAnalysisResultsAction
    | fromAction.AnalysisResultsAction
    | fromLabelActions.VariantLabelsAction
    | fromPopupHeaderActions.InterpretationHeaderAction,
): VariantInterpretationState {
  switch (action.type) {
    case fromAction.LOAD_ANALYSIS_RESULTS_SUCCESS:
    case fromAction.LOAD_ECS_ANALYSIS_RESULTS_SUCCESS: {
      return {
        ...state,
        interpretations: (action.data as (AnalysisResult | ECSResult)[]).reduce(
          (interpretations: VariantInterpretations, result: AnalysisResult | ECSResult): VariantInterpretations => ({
            ...interpretations,
            ...getVariantInterpretation(result),
          }),
          {},
        ),
      };
    }
    case fromAction.TOGGLE_VARIANT_CHECKED: {
      return setCheckedResults(state, action.analysisResults);
    }
    case fromAction.CLEAR_VARIANTS_CHECKED: {
      return {
        ...state,
        checkedResults: new Set(),
      };
    }
    case fromAction.CLEAR_ANALYSIS_RESULTS: {
      return {
        ...initialState,
      };
    }
    case fromAction.REMOVE_VARIANT: {
      const interpretations = _cloneDeep(state.interpretations);
      delete interpretations[action.varId];

      return {
        ...state,
        interpretations,
      };
    }
    case fromAction.UPDATE_SOMATIC_VARIANT_INTERPRETATION_SUCCESS: {
      return {
        ...state,
        interpretations: {
          ...state.interpretations,
          [action.variantId]: {
            ...state.interpretations[action.variantId],
            ...{
              irrelevant:
                action.interpretation.mark_as_clinically_irrelevant !== undefined
                  ? action.interpretation.mark_as_clinically_irrelevant
                  : !!state.interpretations[action.variantId]?.irrelevant,
              in_report:
                action.interpretation.include_in_report !== undefined
                  ? action.interpretation.include_in_report
                  : !!state.interpretations[action.variantId]?.in_report,
              in_workbench:
                action.interpretation.include_in_workbench !== undefined
                  ? action.interpretation.include_in_workbench
                  : !!state.interpretations[action.variantId]?.in_workbench,
            },
          },
        },
      };
    }
    case fromPopupHeaderActions.INCLUDE_IN_REPORT: {
      return setInterpretation(state, [action.variantId.hashId], 'in_report', true);
    }
    case fromPopupHeaderActions.INCLUDE_IN_WORKBENCH: {
      return setInterpretation(state, [action.variantId.hashId], 'in_workbench', true);
    }
    case fromPopupHeaderActions.MARK_IRRELEVANT: {
      return setInterpretation(state, [action.variantId.hashId], 'irrelevant', true);
    }
    case fromAction.TAKE_BULK_VARIANT_ACTION: {
      return {
        ...state,
        bulkActionLoading: true,
      };
    }
    case fromAction.TAKE_BULK_VARIANT_ACTION_SUCCESS: {
      let newState: VariantInterpretationState = state;
      if (action.actionType === VariantActionValue.addComment || action.actionType === VariantActionValue.addEvidence) {
        return newState;
      }
      if (action.actionType === VariantActionValue.clinicalSignificance) {
        return {
          ...setClinicalSignificance(newState, action.variantIds, action.clinicalSignificance),
          checkedResults: new Set(),
          bulkActionLoading: false,
        };
      }
      if (action.actionType === VariantActionValue.in_workbench && !action.value) {
        newState = setInterpretation(newState, action.variantIds, VariantActionValue.in_report, false, false);
      }
      if (action.actionType === VariantActionValue.irrelevant && action.value) {
        newState = setInterpretation(newState, action.variantIds, VariantActionValue.in_report, false, false);
      }
      if (action.actionType === VariantActionValue.in_report && action.value) {
        newState = setInterpretation(newState, action.variantIds, VariantActionValue.irrelevant, false, false);
        newState = setInterpretation(newState, action.variantIds, VariantActionValue.in_workbench, false, true);
        if (action.clinicalSignificance) {
          newState = setClinicalSignificance(newState, action.variantIds, action.clinicalSignificance);
        }
      }

      return {
        ...setInterpretation(newState, action.variantIds, action.actionType, false, action.value),
        checkedResults: new Set(),
        bulkActionLoading: false,
      };
    }
    case fromAction.TAKE_BULK_VARIANT_ACTION_FAIL: {
      return {
        ...state,
        bulkActionLoading: false,
      };
    }
    case fromAction.INCLUDE_IN_REPORT: {
      return setInterpretation(
        state,
        [getCompoundVariantId(action.variantId, action.compoundVariantId)],
        'in_report',
        true,
        undefined,
      );
    }
    case fromAction.INCLUDE_IN_REPORT_SUCCESS: {
      return setInterpretation(
        state,
        [getCompoundVariantId(action.variantId, action.compoundVariantId)],
        'in_report',
        false,
        action.value,
      );
    }
    case fromAction.INCLUDE_IN_REPORT_FAIL: {
      return setInterpretation(
        state,
        [getCompoundVariantId(action.variantId, action.compoundVariantId)],
        'in_report',
        false,
        undefined,
      );
    }
    case fromAction.INCLUDE_IN_WORKBENCH_SUCCESS: {
      return setInterpretation(
        state,
        [getCompoundVariantId(action.variantId, action.pairVariantId)],
        'in_workbench',
        false,
        action.value,
      );
    }
    case fromAction.INCLUDE_IN_WORKBENCH_FAIL: {
      return setInterpretation(
        state,
        [getCompoundVariantId(action.variantId, action.pairVariantId)],
        'in_workbench',
        false,
        undefined,
      );
    }
    case fromAction.MARK_IRRELEVANT: {
      return setInterpretation(
        state,
        [getCompoundVariantId(action.variantId, action.pairVariantId)],
        'irrelevant',
        true,
        undefined,
      );
    }
    case fromAction.MARK_IRRELEVANT_SUCCESS: {
      return setInterpretation(
        state,
        [getCompoundVariantId(action.variantId, action.pairVariantId)],
        'irrelevant',
        false,
        action.value,
      );
    }
    case fromAction.MARK_IRRELEVANT_FAIL: {
      return setInterpretation(
        state,
        [getCompoundVariantId(action.variantId, action.pairVariantId)],
        'irrelevant',
        false,
        undefined,
      );
    }
    case fromLabelActions.ADD_REMOVE_LABEL: {
      return action.showProgressOnVariantTile
        ? setInterpretation(state, [action.variantId], 'orthogonal', true)
        : state;
    }
    case fromLabelActions.ADD_REMOVE_LABEL_SUCCESS: {
      return action.showProgressOnVariantTile
        ? setInterpretation(state, [action.variantId], 'orthogonal', false, action.remove)
        : state;
    }
    case fromLabelActions.ADD_REMOVE_LABEL_FAIL: {
      return action.showProgressOnVariantTile
        ? setInterpretation(state, [action.variantId], 'orthogonal', false)
        : state;
    }
    case fromAction.SET_VARIANT_CLINICAL_SIGNIFICANCE_SUCCESS: {
      return setClinicalSignificance(state, [action.varId], action.significance);
    }
  }

  return state;
}

export const getVariantInterpretations = (state: VariantInterpretationState) => state.interpretations;
export const getCheckedResults = (state: VariantInterpretationState) => state.checkedResults;
