import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import { VariantPageState } from '../reducers';
import * as fromReducer from '../reducers/variant-details.reducer';
import { VariantDetailsState } from '../reducers/variant-details.reducer';
import { SearchConfig, SnpVariantDetails, VariantCaseResponse, VariantDetails, VariantId } from '../../models';

export const getVariantDetailsState: MemoizedSelector<VariantPageState, VariantDetailsState> = createSelector(
  fromFeature.getVariantPageState,
  (state: fromFeature.VariantPageState) => state?.variantDetails,
);

export const getVariantDetails: MemoizedSelector<VariantPageState, VariantDetails> = createSelector(
  getVariantDetailsState,
  fromReducer.getVariantDetailsEntity,
);

export const getCompoundVariantDetails: MemoizedSelector<VariantPageState, VariantDetails> = createSelector(
  getVariantDetailsState,
  fromReducer.getCompoundVariantDetailsEntity,
);

export const getVariantId: MemoizedSelector<VariantPageState, VariantId> = createSelector(
  getVariantDetailsState,
  fromReducer.getVariantId,
);

export const getCompoundVariantId: MemoizedSelector<VariantPageState, VariantId> = createSelector(
  getVariantDetailsState,
  fromReducer.getCompoundVariantId,
);

export const getSelectedRegions: MemoizedSelector<VariantPageState, { [key: string]: boolean }> = createSelector(
  getVariantDetailsState,
  fromReducer.getSelectedRegions,
);

export const getVariantDetailsLoading: MemoizedSelector<VariantPageState, boolean> = createSelector(
  getVariantDetailsState,
  fromReducer.getVariantDetailsLoadingEntity,
);

export const getVariantDetailsLoaded: MemoizedSelector<VariantPageState, boolean> = createSelector(
  getVariantDetailsState,
  fromReducer.getVariantDetailsLoadedEntity,
);

export const getSessionId: MemoizedSelector<VariantPageState, string> = createSelector(
  getVariantDetailsState,
  fromReducer.getSessionId,
);

export const getSearchConfig: MemoizedSelector<VariantPageState, SearchConfig> = createSelector(
  getVariantDetailsState,
  fromReducer.getSearchConfig,
);

export const getSearchConfigPercent: MemoizedSelector<VariantPageState, number> = createSelector(
  getSearchConfig,
  (config) => {
    let sum = 0;
    Object.keys(config || {}).forEach((key) => {
      if ((config[key] || '').toString().length) {
        sum += PERCENTAGES(config.zygosity)[key] || 0;
      }
    });
    return sum;
  },
);

export const getSearchConfigValid: MemoizedSelector<VariantPageState, boolean> = createSelector(
  getSearchConfig,
  (config: SearchConfig) =>
    config &&
    Object.keys(config)
      .filter((key) => key !== 'search_term')
      .some((key) => (config[key] || '').toString().length) &&
    config.zygosity !== 'TUMOR',
);

export const getSearchCaseSaved: MemoizedSelector<VariantPageState, boolean> = createSelector(
  getVariantDetailsState,
  fromReducer.getSearchCaseSaved,
);

export const getSearchCaseSaving: MemoizedSelector<VariantPageState, boolean> = createSelector(
  getVariantDetailsState,
  fromReducer.getSearchCaseSaving,
);

export const getSearchCasesSaved: MemoizedSelector<
  VariantPageState,
  {
    [caseName: string]: VariantCaseResponse;
  }
> = createSelector(getVariantDetailsState, fromReducer.getSearchCasesSaved);

export const getVariantGoogleSearchLink: MemoizedSelector<VariantPageState, string> = createSelector(
  getVariantDetails,
  (variantDetails: SnpVariantDetails) => {
    return `https://scholar.google.com/scholar?q=${encodeURI(getVariantSearchTerm(variantDetails))}`;
  },
);

const getVariantSearchTerm = (variantDetails: VariantDetails): string => {
  let searchTerm = '';

  if (!('sv_type' in variantDetails) && !('regions' in variantDetails)) {
    if (variantDetails.gene) {
      searchTerm += `"${variantDetails.gene}" AND (`;

      const searchStrings: string[] = getCDotSearchStrings(variantDetails.c_dot).concat(
        getPDotSearchStrings(variantDetails.p_dot),
      );
      searchStrings.forEach((str) => {
        if (searchStrings.indexOf(str) !== 0) {
          searchTerm += ' | ';
        }
        searchTerm += `"${str}"`;
      });

      searchTerm += `)`;
    } else {
      searchTerm += `${variantDetails.chr}-${variantDetails.position} ${variantDetails.alt}>${variantDetails.ref}`;
    }
  } else if ('sv_type' in variantDetails) {
    searchTerm += `${variantDetails.range_id.chrom}:${variantDetails.range_id.start}-${variantDetails.range_id.end}?${variantDetails.sv_type}`;
  } else {
    searchTerm += `${variantDetails.regions[0].chrom}:${variantDetails.regions[0].start}-${variantDetails.regions[0].end}`;
  }

  return searchTerm;
};

const getCDotSearchStrings = (cDot: string): string[] => {
  const searchStrings = [];

  if (cDot) {
    const cDotStr = cDot.substring(2); // Remove the 'c.'
    searchStrings.push(cDotStr);

    if (cDotStr.includes('>')) {
      searchStrings.push(cDotStr.replace('>', '->'));
      searchStrings.push(cDotStr.replace('>', '-->'));
      searchStrings.push(cDotStr.replace('>', '/'));
    }
  }

  return searchStrings;
};

const getPDotSearchStrings = (pDot: string): string[] => {
  const searchStrings = [];

  if (pDot) {
    const pDotStr = pDot.substring(2); // Remove the 'p.'
    searchStrings.push(pDotStr);

    let pDotShortenStr = pDotStr;
    Object.keys(aminoAcidShortenMap).forEach((key) => {
      const regExp: RegExp = new RegExp(key, 'g');
      pDotShortenStr = pDotShortenStr.replace(regExp, `${aminoAcidShortenMap[key]}`);
    });

    if (pDotShortenStr !== pDotStr) {
      searchStrings.push(pDotShortenStr);
    }
  }

  return searchStrings;
};

const aminoAcidShortenMap = {
  Ala: 'A',
  Arg: 'R',
  Asn: 'N',
  Asp: 'D',
  Cys: 'C',
  Gln: 'Q',
  Glu: 'E',
  Gly: 'G',
  His: 'H',
  Ile: 'I',
  Leu: 'L',
  Lys: 'K',
  Met: 'M',
  Phe: 'F',
  Pro: 'P',
  Ser: 'S',
  Thr: 'T',
  Trp: 'W',
  Tyr: 'Y',
  Val: 'V',
};

const PERCENTAGES = (zygosity: string): { [key: string]: number } => {
  return zygosity === 'TUMOR'
    ? {
        birth_date: 10,
        consanguinity: 0,
        sex: 10,
        phenotypes: 0,
        cancer_type: 60,
        ethnicity: 10,
        zygosity: 0,
        family_inheritance_status: 0,
        reported_classification: 10,
      }
    : {
        birth_date: 10,
        consanguinity: 10,
        sex: 10,
        phenotypes: 30,
        cancer_type: 0,
        ethnicity: 10,
        zygosity: 10,
        family_inheritance_status: 10,
        reported_classification: 10,
      };
};
