import { Action } from '@ngrx/store';

export interface AnalyticsAction extends Action {
  analyticsEvent: AnalyticsEvent; // Typically the object that was interacted with (e.g. 'Video'))
}

export interface AnalyticsEvent {
  eventCategory: string; // Typically the object that was interacted with (e.g. 'Video')
  eventAction: string; // The type of interaction (e.g. 'play')
  eventLabel?: string; // Useful for categorizing events (e.g. 'Fall Campaign')
  eventValue?: number; // A numeric value associated with the event (e.g. 42)
}

export function instanceOfAnalyticsAction(action: Action): action is AnalyticsAction {
  return 'analyticsEvent' in action;
}

/**
 * created an analytics event from the Action type
 * e.g type = '[Variants] Follow variant' is converted to
 * event {
 *   eventCategory : 'Variants',
 *   eventAction : 'Follow variant'
 * }
 * @param type - the type of the Action
 */
export function extractEventFromType(type: string): AnalyticsEvent {
  return {
    eventAction: type.split('] ')[1],
    eventCategory: type.substring(type.indexOf('[') + 1, type.indexOf(']')),
  };
}
