import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromReducer from '../reducers/route.reducer';

import { AppModuleState, getAppModuleState, RouterStateUrl } from '../reducers';
import { NavigationEnd, Params } from '@angular/router';
import { RouterReducerState } from '@ngrx/router-store';
import { VariantType } from '../../modules/analysis/modules/analysis-variant/models';
import { DiscoveryAnalysisType } from '../../modules/discovery/store/models/discovery.model';

const getRouteState: MemoizedSelector<AppModuleState, fromReducer.RouteState> = createSelector(
  getAppModuleState,
  (state: AppModuleState) => state.route,
);

export const getRouterState: MemoizedSelector<AppModuleState, RouterReducerState<RouterStateUrl>> = createSelector(
  getAppModuleState,
  (state) => state.routerReducer,
);

export const getRouterStateUrl: MemoizedSelector<any, any> = createSelector(
  getRouterState,
  (state: RouterReducerState<RouterStateUrl>) => (state ? state.state : {}),
);

export const getPreviousRoute: MemoizedSelector<AppModuleState, NavigationEnd> = createSelector(
  getRouteState,
  fromReducer.getPreviousRoute,
);
export const getPreviousRouteUrl: MemoizedSelector<AppModuleState, string> = createSelector(
  getRouteState,
  fromReducer.getPreviousRouteUrl,
);
export const getPreviousAnalysesUrl: MemoizedSelector<AppModuleState, string> = createSelector(
  getRouteState,
  fromReducer.getPreviousAnalysesUrl,
);

export const getQueryParams: MemoizedSelector<AppModuleState, Params> = createSelector(
  getRouterStateUrl,
  (model) => (model ? model.queryParams : {}) as Params,
);

export const getParams: MemoizedSelector<AppModuleState, Params> = createSelector(
  getRouterStateUrl,
  (model) => (model ? model.params : {}) as Params,
);

export const getPath: MemoizedSelector<AppModuleState, string> = createSelector(
  getRouterStateUrl,
  (model) => model.path,
);

export const getParentPath: MemoizedSelector<AppModuleState, string> = createSelector(
  getRouterStateUrl,
  (model) => model.parentPath,
);

export const getAnalysisId: MemoizedSelector<any, number> = createSelector(getParams, (params) =>
  params && params.analysisId ? parseInt(params.analysisId, 10) : -1,
);

export const getVariantType: MemoizedSelector<any, VariantType> = createSelector(getParams, (params) =>
  params && params.variantType ? params.variantType : VariantType.All,
);

export const getDiscoveryAnalysisType: MemoizedSelector<any, DiscoveryAnalysisType> = createSelector(
  getParams,
  (params) => params?.analysisType,
);
