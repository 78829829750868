<button class="close-btn" (click)="onClose()"></button>
<div class="header">
  <h1>Invite Team member</h1>
  <form [formGroup]="searchForm">
    <div class="input-container search-bar">
      <input
        placeholder="Invite using email"
        type="email"
        formControlName="email"
        (mouseenter)="$event.stopImmediatePropagation()"
        (mouseleave)="$event.stopImmediatePropagation()"
        matTooltipClass="blue-md-tooltip"
        #tooltip="matTooltip"
        matTooltip="Email already exists in the list"
      />
      <button class="add-btn" [disabled]="searchForm.controls.email.errors" (click)="addUserToList(tooltip)"></button>
    </div>
  </form>
</div>
<div class="users-list">
  <div *ngIf="!usersList.length" class="empty-list w-100">
    <img src="/assets/svg/group-3.svg" width="89" height="98" class="add-icon" />
    <p>Invite by entering email on the field above</p>
    <img src="/assets/svg/Oval.svg" class="arrow" />
  </div>

  <div class="user" *ngFor="let user of usersList">
    <ngx-avatar class="col-a" [bgColor]="'#007da8'" [size]="50" [name]="user.email.substr(0, 2)"></ngx-avatar>
    <div class="col-b">
      <span class="email">{{ user.email }}</span>
    </div>
    <gnx-checkbox (checkedChange)="user.admin = true">
      Admin&nbsp;
      <img
        src="assets/svg/info.svg"
        width="15"
        height="15"
        matTooltip="Admins can invite new team members, and manage your organization account"
        matTooltipClass="blue-md-tooltip center"
      />
    </gnx-checkbox>
    <img
      class="delete-btn m-3"
      src="/assets/svg/trash-can.svg"
      width="18"
      height="17"
      (click)="deleteMember(user.email)"
    />
  </div>
</div>
<div class="footer">
  <button class="send-btn" [disabled]="!usersList.length" (click)="onSendInvitations()">
    <span *ngIf="!sendingInvitations">Send</span>
    <gnx-dots-loader *ngIf="sendingInvitations"></gnx-dots-loader>
  </button>
</div>
