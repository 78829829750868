import { Action } from '@ngrx/store';
import { AnalysisDetails } from '../../../../../../store';

export const LOAD_CANCER_TYPE = '[Analysis] Load Cancer Type';
export const LOAD_CANCER_TYPE_SUCCESS = '[Analysis] Load Cancer Type Success';
export const LOAD_CANCER_TYPE_FAIL = '[Analysis] Load Cancer Type Fail';
export const UPDATE_CANCER_TYPE = '[Analysis] Update Cancer Type';
export const UPDATE_CANCER_TYPE_SUCCESS = '[Analysis] Update Cancer Type Success';
export const UPDATE_CANCER_TYPE_FAIL = '[Analysis] Update Cancer Type Fail';

export class LoadCancerType implements Action {
  readonly type = LOAD_CANCER_TYPE;

  constructor(public analysisId: number) {}
}

export class LoadCancerTypeSuccess implements Action {
  readonly type = LOAD_CANCER_TYPE_SUCCESS;

  constructor(public payload: string) {}
}

export class LoadCancerTypeFail implements Action {
  readonly type = LOAD_CANCER_TYPE_FAIL;

  constructor(public err: any) {}
}

export class UpdateCancerType implements Action {
  readonly type = UPDATE_CANCER_TYPE;

  constructor(public payload: string) {}
}

export class UpdateCancerTypeSuccess implements Action {
  readonly type = UPDATE_CANCER_TYPE_SUCCESS;

  constructor(
    public payload: string,
    public analysisData: AnalysisDetails,
  ) {}
}

export class UpdateCancerTypeFail implements Action {
  readonly type = UPDATE_CANCER_TYPE_FAIL;

  constructor(public error: any) {}
}

export type CancerTypeAction = LoadCancerType | LoadCancerTypeSuccess | LoadCancerTypeFail | UpdateCancerType;
