import { NgModule } from '@angular/core';

import { I18nPipe } from './i18n.pipe';
import { I18nService } from './i18n.service';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { MatMenuModule } from '@angular/material/menu';
import { RtlDirective } from './rtl.directive';

const exports = [I18nPipe, LanguageSelectorComponent, RtlDirective];

@NgModule({
  imports: [CommonModule, MatNativeDateModule, MatMenuModule, ...exports],
  exports: [...exports],
  providers: [I18nService],
})
export class I18nModule {}
