import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MiniApps } from '../../../../shared/models';
import { SubMenuComponent } from '../../../../shared-lazy/components/sub-menu/sub-menu.component';
import { HeaderComponent } from '../header/header.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { TopNavComponent } from '../../../../shared/containers/top-nav/top-nav.component';
import { AnalysisToolsState, LoadCustomStatusOptions } from '../../store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'gnx-analysis-tool',
  templateUrl: './analysis-tool.component.html',
  styleUrls: ['./analysis-tool.component.scss'],
  standalone: true,
  imports: [TopNavComponent, NgIf, HeaderComponent, SubMenuComponent, RouterOutlet, AsyncPipe],
})
export class AnalysisToolComponent implements OnInit, OnDestroy {
  MiniApps = MiniApps;

  constructor(private store$: Store<AnalysisToolsState>) {}

  ngOnInit() {
    this.store$.dispatch(new LoadCustomStatusOptions());
  }

  ngOnDestroy() {}
}
