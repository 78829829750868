import { createSelector, MemoizedSelector } from '@ngrx/store';
import { CommunityClassificationState, getCommunityClassificationState } from '../reducers';

import * as fromReducerSelectors from '../reducers/assessments.reducer';
import { VariantAssessment, VariantAssessmentsByCondition } from '../../models';
import { AssessmentsState, getAssessmentsByConditionEntity } from '../reducers/assessments.reducer';

const getAssessmentsState: MemoizedSelector<CommunityClassificationState, AssessmentsState> = createSelector(
  getCommunityClassificationState,
  (state: CommunityClassificationState) => state.assessments,
);

export const getAssessments: MemoizedSelector<CommunityClassificationState, VariantAssessment[]> = createSelector(
  getAssessmentsState,
  fromReducerSelectors.getAssessmentsEntity,
);

export const getAssessmentsByCondition: MemoizedSelector<
  CommunityClassificationState,
  VariantAssessmentsByCondition[]
> = createSelector(getAssessmentsState, fromReducerSelectors.getAssessmentsByConditionEntity);

export const getAssessmentsLoading: MemoizedSelector<CommunityClassificationState, boolean> = createSelector(
  getAssessmentsState,
  fromReducerSelectors.getLoadingEntity,
);

export const getAssessmentsLoaded: MemoizedSelector<CommunityClassificationState, boolean> = createSelector(
  getAssessmentsState,
  fromReducerSelectors.getLoadedEntity,
);

export const getAssessmentsSorted: MemoizedSelector<CommunityClassificationState, VariantAssessment[]> = createSelector(
  getAssessments,
  (assessments) => (assessments ? [...assessments].sort((a, b) => (b.last_updated > a.last_updated ? 1 : -1)) : []),
);
