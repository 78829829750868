import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gnx-shimmer',
  templateUrl: './shimmer.component.html',
  styleUrls: ['./shimmer.component.scss'],
  standalone: true,
})
export class ShimmerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
