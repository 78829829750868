<img
  *ngIf="mode !== SnackbarMode.Error && mode !== SnackbarMode.SuccessNoPanda"
  class="panda"
  src="/assets/png/panda-1.png"
  srcset="assets/png/panda-1@2x.png 2x, assets/png/panda-1@3x.png 3x"
/>
<div class="text">
  <div class="message" *ngIf="!!message">{{ message }}</div>
  <div class="details" *ngIf="!!details">{{ details }}</div>
</div>
<img
  class="close-btn"
  [src]="this.mode === SnackbarMode.Error ? '/assets/svg/x-black-turned.svg' : '/assets/svg/x-white-medium.svg'"
  width="14"
  height="14"
  (click)="close.emit()"
/>
