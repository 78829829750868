import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromAction from '../actions';
import * as fromRootAction from '../../../../../../store/actions';
import { EDIT_PATIENT_INFO, EditPatientInfo, LOAD_PATIENT_INFO_SUCCESS } from '../actions';
import { Injectable } from '@angular/core';
import { WorkbenchService } from '../services/workbench.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class CaseDataEffects {
  constructor(
    private actions$: Actions,
    private service: WorkbenchService,
    private dialog: MatDialog,
  ) {}

  loadPatientInfo$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_PATIENT_INFO),
      switchMap((action: fromAction.LoadPatientInfo) =>
        this.service.getPatientInfo(action.analysisId).pipe(
          map((result) => new fromAction.LoadPatientInfoSuccess(action.analysisId, result)),
          catchError((err) => of(new fromAction.LoadPatientInfoFail(action.analysisId, err))),
        ),
      ),
    ),
  );

  loadPedigreeUrl$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_PEDIGREE_URL),
      switchMap((action: fromAction.LoadPedigreeUrl) =>
        this.service.getPedigreeUrl(action.analysisId).pipe(
          map((result) => new fromAction.LoadPedigreeUrlSuccess(action.analysisId, result.url)),
          catchError((err) => of(new fromAction.LoadPedigreeUrlFail(action.analysisId, err))),
        ),
      ),
    ),
  );

  editPatientInfo$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EDIT_PATIENT_INFO),
      switchMap((action: EditPatientInfo) =>
        this.service.editPatientInfo(action.analysisId, action.caseContext, action.customData).pipe(
          map((result) => new fromAction.LoadPatientInfoSuccess(action.analysisId, result)),
          catchError((err) => of(new fromAction.LoadPatientInfoFail(action.analysisId, err))),
        ),
      ),
    ),
  );

  // @Effect()
  // editPatientInfoSuccess$: Observable<any> = this.actions$.pipe(
  //   ofType(LOAD_PATIENT_INFO_SUCCESS),
  //   switchMap((action: EditPatientInfo) => of(new fromRootAction.ReloadAnalysisDetails())
  //   )
  // );
}
