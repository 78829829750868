import {
  AnalysisCompoundVariant,
  AnalysisResult,
  ECSResult,
  SnpResult,
  SomaticSnpResult,
  SomaticSvResult,
  SvResult,
  VariantInterpretation,
  VariantType,
} from '../../analysis-variant/models';
import { flatMap as _flatMap } from 'lodash';
import { getVariantId } from './analysis-result.utils';
import { LohResult } from '../../analysis-variant/models/loh-result.model';

export const getVariantInterpretation = (result: AnalysisResult | ECSResult) => {
  if ('type' in result) {
    return getInterpretation(result);
  }
  return getECSInterpretation(result);
};

export const getECSInterpretation = (data: ECSResult) => {
  return _flatMap(data.variants).reduce((pre, cur) => ({ ...pre, ...getInterpretation(cur) }), {});
};

const getInterpretation = (result: AnalysisResult): { [varId: string]: VariantInterpretation } => {
  if (!result) {
    return {};
  }
  switch (result.type) {
    case VariantType.SNP:
      return {
        [getVariantId(result.data)]: result.data.hasOwnProperty('mark_as_clinically_irrelevant')
          ? getSomaticInterpretation(result.data as SomaticSnpResult)
          : {
              in_workbench: (result.data as SnpResult).in_workbench,
              ...(result.data as SnpResult).user_interpretation,
            },
      };
    case VariantType.RNA:
    case VariantType.SV:
      return {
        [getVariantId(result.data)]: result.data.hasOwnProperty('mark_as_clinically_irrelevant')
          ? getSomaticInterpretation(result.data as SomaticSnpResult)
          : new VariantInterpretation((result.data as SvResult).users_interpretations_data.user_interpretation),
      };
    case VariantType.Compound:
      const data = (result as AnalysisCompoundVariant).data;
      return {
        [getVariantId(data)]: {
          in_workbench: !!data.in_workbench,
          in_report: !!data.in_report,
          irrelevant: !!data.irrelevant,
        },
      };
    case VariantType.LOH:
      return {
        [getVariantId(result.data)]: {
          in_workbench: !!(result.data as LohResult)?.users_interpretations_data?.user_interpretation?.in_workbench,
          in_report: !!(result.data as LohResult)?.users_interpretations_data?.user_interpretation?.in_report,
          irrelevant: !!(result.data as LohResult)?.users_interpretations_data?.user_interpretation?.irrelevant,
        },
      };
  }
};

const getSomaticInterpretation = (data: SomaticSnpResult | SomaticSvResult) => ({
  in_workbench: (data as SomaticSnpResult).in_workbench || (data as SomaticSvResult).include_in_workbench,
  in_report: data.include_in_report,
  irrelevant: data.mark_as_clinically_irrelevant,
  interpretation_tags: data.interpretation_tags,
});
