import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import * as fromAction from '../actions/cancer-type.actions';
import { catchError, flatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { WorkbenchService } from '../services/workbench.service';
import { Action, select, Store } from '@ngrx/store';
import { VariantsState } from '../../../variants/store/reducers';
import { AnalysisDetails } from '../../../../../../store/models';
import { AppModuleState } from '../../../../../../store/reducers';
import { getAnalysisDetails } from '../../../../../../store/selectors';
import { AppService } from '../../../../../../store/services';
import { EditPatientInfo, LoadSomaticBiomarkers } from '../actions';
import { WorkbenchModuleState } from '../reducers';
import { getPatientInfoResponse } from '../selectors';
import { SearchConfig } from '../../../../../variant-page/modules/variant-page/models';

@Injectable()
export class CancerTypeEffects {
  constructor(
    private actions$: Actions,
    private service: WorkbenchService,
    private mainService: AppService,
    private variantsStore$: Store<VariantsState>,
    private rootStore$: Store<AppModuleState>,
    private wbStore$: Store<WorkbenchModuleState>,
  ) {}

  loadCancerType$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_CANCER_TYPE),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisDetails))),
      switchMap(([action, analysis]: [fromAction.LoadCancerType, AnalysisDetails]) =>
        of(new fromAction.LoadCancerTypeSuccess(analysis ? analysis.disease : undefined)),
      ),
      catchError((err) => of(new fromAction.LoadCancerTypeFail(err))),
    ),
  );

  updateCancerType$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.UPDATE_CANCER_TYPE),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisDetails))),
      withLatestFrom(this.wbStore$.pipe(select(getPatientInfoResponse))),
      switchMap(([[action, analysis], caseContext]: [[fromAction.UpdateCancerType, AnalysisDetails], SearchConfig]) =>
        this.mainService.updateAnalysisDetails(analysis.id, { disease: action.payload }).pipe(
          flatMap((analysisDetails: AnalysisDetails) => [
            new EditPatientInfo(analysis.id, {
              ...caseContext,
              custom_data: {
                ...caseContext.custom_data,
                clinically_significant_genes: analysisDetails.custom_data.clinically_significant_genes,
              },
            }),
            new fromAction.UpdateCancerTypeSuccess(action.payload, analysisDetails),
          ]),
          catchError((err) => of(new fromAction.UpdateCancerTypeFail(err))),
        ),
      ),
    ),
  );

  reloadBiomarkersAfterCancerUpdate$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.UPDATE_CANCER_TYPE_SUCCESS),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisDetails))),
      map(
        ([action, analysis]: [fromAction.UpdateCancerTypeSuccess, AnalysisDetails]) =>
          new LoadSomaticBiomarkers(analysis.id, [analysis.disease]),
      ),
    ),
  );
}
