<div class="header">
  <img class="close-btn" src="assets/svg/x-icon.svg" width="14" height="14" (click)="onClose()" />
</div>

<div class="icon" [style.background-color]="bgc">
  <img [src]="data.path" style="width: 100%" />
</div>
<div class="path" (click)="copyToClipboard()">
  <span>{{ data.path }}</span>
</div>

<div class="info">You can press "SHIFT + X" to switch background color</div>

<div class="dialog-actions">
  <button class="cancel-btn" mat-dialog-close (click)="onClose()">Close</button>
</div>

<div class="success-message" *ngIf="state === 'success'">Copied to clipboard</div>
