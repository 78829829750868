import { createSelector, MemoizedSelector, MemoizedSelectorWithProps } from '@ngrx/store';
import { AnalysisToolsState, getAnalysisToolsState } from '../reducers';
import { CustomStatusState } from '../reducers/custom-status.reducer';
import { AnalysisCustomWorkflowStatus } from '../../models';
import {
  analysisStatusDefaultUserOptions,
  AnalysisWorkflowConfig,
  AnalysisWorkflowStatus,
} from '../../modules/analyses/store/models';

const getCustomStatusState: MemoizedSelector<AnalysisToolsState, CustomStatusState> = createSelector(
  getAnalysisToolsState,
  (state) => state.customStatusOptions,
);

export const getCustomStatusOptions: MemoizedSelector<AnalysisToolsState, AnalysisCustomWorkflowStatus[]> =
  createSelector(getCustomStatusState, (state) => state.data);

export const getCustomAnalysisStatusOptionsLoading: MemoizedSelector<AnalysisToolsState, boolean> = createSelector(
  getCustomStatusState,
  (state) => state.loading,
);

export const getCustomStatusOptionsLoaded: MemoizedSelector<AnalysisToolsState, boolean> = createSelector(
  getCustomStatusState,
  (state) => state.loaded,
);

export const getCustomOrDefaultAnalysisStatusOptions: MemoizedSelector<
  AnalysisToolsState,
  AnalysisCustomWorkflowStatus[]
> = createSelector(getCustomStatusOptions, (options) =>
  options?.length
    ? options
    : analysisStatusDefaultUserOptions.map((option) => ({
        status: option,
        status_description: AnalysisWorkflowConfig[option].label,
        parent_status: option,
        parent_status_description: AnalysisWorkflowConfig[option].label,
      })),
);

export const getAnalysisStatusOptions: MemoizedSelectorWithProps<
  AnalysisToolsState,
  { analysis: { status: number; parent_status: AnalysisWorkflowStatus; status_description: string } },
  AnalysisCustomWorkflowStatus[]
> = createSelector(getCustomOrDefaultAnalysisStatusOptions, (options, props) =>
  options.some((o) => o.status === props.analysis.status)
    ? [...options]
    : [
        ...options,
        {
          status: props.analysis.status,
          parent_status: props.analysis.parent_status,
          status_description: props.analysis.status_description,
        },
      ],
);
