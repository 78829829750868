<span *ngIf="phenotype.type">{{ phenotype.type }}</span>
<span>
  <span>
    <strong
      [ngClass]="{ error: phenotype.value === 'error' }"
      [innerHTML]="addHighlight(phenotype.name) | safeHtml"
    ></strong>
    <span [innerHTML]="addHighlight(phenotype.short_desc) | safeHtml"></span>
  </span>
  <span [innerHTML]="addHighlight(phenotype.long_desc) | safeHtml"></span>
</span>
