import { DataPair, VariantPosition } from '../../../../modules/analysis/modules/analysis-variant/models';
import { VariantReferenceType } from '../../../../modules/variant-page/modules/variant-page/models';

export interface Variant {
  varId: string;
  gene?: string;
  hgvsC?: string;
  hgvsP?: string;
  conditions?: string[];
  conflictingAssessments?: string[];
  germlineAssessments?: string[];
  oncogenicAssessments?: string[];
  assessment: string;
  somaticAssessments: string[];
  numOfSubmissions: number;
  lastUpdated: string;
  snpVariant?: {
    chromosome?: string;
    startPos?: string;
    endPos?: string;
    reference?: string;
    alternative?: string;
    variationType?: string;
    referenceVersion?: VariantReferenceType;
  };
  svVariant?: {
    varId: string;
    chromosome: string;
    startPos: string;
    endPos: string;
    variationType: string;
    cipos: DataPair;
    ciend: DataPair;
    zygosity: string;
    rnaType?: string;
    svLength?: number;
    breakendMates?: {
      chromosome: string;
      varId: string;
      variationType: string;
      cipos: DataPair;
      startPos: string;
      endPos: string;
      genes: string[];
    }[];
    referenceVersion?: VariantReferenceType;
  };
  svGenes?: string[];
  suggested?: boolean;
}

export interface VariantsExportResponse {
  exportDeliveryMethod: 'email' | 'download';
  exportedDataPath: string;
}
