import * as fromAction from './../actions';

export enum CaseDataComponentType {
  FamilyMembers = 'family-members',
  FamilyPedigree = 'family-pedigree',
  PatientInfo = 'patient-info',
  Phenotypes = 'phenotypes',
  Panels = 'panels',
  ReferralNotes = 'referral-notes',
  Referral = 'referral',
  SampleInfo = 'sample-info',
  SubjectInfo = 'subject-info',
  CancerType = 'cancer-type',
  GenesSelected = 'genes-selected',
  Comments = 'comments',
  SomaticSampleInfo = 'somatic-sample-info',
}

export enum CaseDataTab {
  CaseData,
  Protocols,
}

export const CaseDataTabsConfig: { [key in CaseDataTab]: { title: string; icon: string } } = {
  [CaseDataTab.CaseData]: {
    title: 'CASE DATA',
    icon: 'assets/svg/icons-analyze-tool-blu.svg',
  },
  [CaseDataTab.Protocols]: {
    title: 'PROTOCOLS',
    icon: 'assets/svg/check-app.svg',
  },
};

export enum AnalysisDataComponentType {
  QC = 'qc',
  Results = 'results',
  EcsResults = 'ecs-results',
  CaseSummary = 'case-summary',
}

export enum SubjectInfoType {
  Name = 'name',
  DOB = 'dob',
  SEX = 'sex',
  ID = 'id',
  Ethnicity = 'ethnicity',
  SpecimenType = 'specimen-type',
  CollectionDate = 'collection-date',
}

export interface CaseDataSectionConfig {
  components: CaseDataComponentType[];
}

export interface AnalysisDataSectionConfig {
  components: AnalysisDataComponentType[];
  ecsResults: boolean;
}

export interface WorkbenchConfig {
  caseData: CaseDataSectionConfig;
  analysisData: AnalysisDataSectionConfig;
}

export interface ComponentConfig {
  loadAction: any;
}

export const CaseDataComponentsConfig: { [key: string]: ComponentConfig } = {
  [CaseDataComponentType.Phenotypes]: {
    loadAction: null,
  },
  [CaseDataComponentType.PatientInfo]: {
    loadAction: null,
  },
  [CaseDataComponentType.Panels]: {
    loadAction: null,
  },
  [CaseDataComponentType.FamilyMembers]: {
    loadAction: null,
  },
  [CaseDataComponentType.ReferralNotes]: {
    loadAction: fromAction.LoadReferralNotes,
  },
  [CaseDataComponentType.Referral]: {
    loadAction: null,
  },
  [CaseDataComponentType.SampleInfo]: {
    loadAction: null,
  },
  [CaseDataComponentType.SubjectInfo]: {
    loadAction: null,
  },
  [CaseDataComponentType.CancerType]: {
    loadAction: fromAction.LoadCancerType,
  },
};

export const AnalysisDataComponentsConfig: { [key: string]: ComponentConfig } = {
  [AnalysisDataComponentType.QC]: {
    loadAction: fromAction.LoadQualityControl,
  },
  [AnalysisDataComponentType.Results]: {
    loadAction: null,
  },
  [AnalysisDataComponentType.EcsResults]: {
    loadAction: null,
  },
  [AnalysisDataComponentType.CaseSummary]: {
    loadAction: null,
  },
};
