export interface CosmicFrequencyEntry {
  alternation_sample_count: number;
  total_tumor_count: number;
  total_sample_count: number;
  alternation_tumor_count: number;
}

export interface CosmicFrequency {
  total: CosmicFrequencyEntry;
  requested_disease: CosmicFrequencyEntry;
  all_diseases: { disease_id: string; frequency: CosmicFrequencyEntry }[];
}

export interface CosmicFrequencyEntities {
  [varId: string]: CosmicFrequency;
}

export interface CosmicFrequencyEntitiesState {
  [varId: string]: boolean;
}
