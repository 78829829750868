import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';

import * as fromAction from '../actions/interpretation-header.action';
import { catchError, switchMap } from 'rxjs/operators';
import { InterpretationService } from '../../services/interpretation.service';
import { VariantInterpretation } from '../../models';
import { Observable, of } from 'rxjs';

@Injectable()
export class InterpretationHeaderEffect {
  constructor(
    private actions$: Actions,
    private interpretationService: InterpretationService,
  ) {}

  followVariant$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.FOLLOW_VARIANT),
      switchMap((action: fromAction.FollowVariant) =>
        this.interpretationService.setFollow(action.value, action.variantId).pipe(
          switchMap((data: VariantInterpretation) => [new fromAction.FollowVariantSuccess(data.follow)]),
          catchError((error) => of(new fromAction.FollowVariantFail(error))),
        ),
      ),
    ),
  );
}
