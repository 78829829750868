import { createSelector, MemoizedSelector } from '@ngrx/store';
import { getSigState, SigState } from '../reducers';
import { SigDetailsState } from '../reducers/sig-details.reducer';
import { SigDetails, SigTotalCounts } from '../../models';

const getSigDetailsState: MemoizedSelector<SigState, SigDetailsState> = createSelector(
  getSigState,
  (state) => state.details,
);

export const getSigDetails: MemoizedSelector<SigState, SigDetails> = createSelector(
  getSigDetailsState,
  (state) => state.sigDetails,
);

export const getSigDetailsLoading: MemoizedSelector<SigState, boolean> = createSelector(
  getSigDetailsState,
  (state) => state.loadingDetails,
);

export const getSigDetailsLoaded: MemoizedSelector<SigState, boolean> = createSelector(
  getSigDetailsState,
  (state) => state.loadedDetails,
);

export const getSigCounts: MemoizedSelector<SigState, SigTotalCounts> = createSelector(
  getSigDetailsState,
  (state) => state.totalCounts,
);

export const getSigCountsLoading: MemoizedSelector<SigState, boolean> = createSelector(
  getSigDetailsState,
  (state) => state.totalCountsLoading,
);

export const getSigCountsLoaded: MemoizedSelector<SigState, boolean> = createSelector(
  getSigDetailsState,
  (state) => state.totalCountsLoaded,
);
