import { Action } from '@ngrx/store';
import {
  AzureConnectionResponse,
  InviteeDataResponse,
  TokenDetails,
  UserDetails,
  UserLocationData,
} from '../../models';
import { AnalyticsAction, AnalyticsEvent, extractEventFromType } from '../../../../store/actions/analytics.action';
import { AzureErrors } from '../../../../login-page/containers/login-page/login-page.data';

// Token load from localStorage into the NGRX store and validation

export const LOAD_EXISTING_TOKEN = '[Auth] Load Existing token';
export const LOAD_EXISTING_TOKEN_SUCCESS = '[Auth] Load Existing token Success';
export const LOAD_EXISTING_TOKEN_FAIL = '[Auth] Load Existing token Fail';
export const RENEW_TOKEN = '[Auth] Renew Token';
export const RENEW_TOKEN_SUCCESS = '[Auth] Renew Token Success';
export const RENEW_TOKEN_FAIL = '[Auth] Renew Token Fail';

export class LoadExistingToken implements Action {
  readonly type = LOAD_EXISTING_TOKEN;
}

export class LoadExistingTokenSuccess implements Action {
  readonly type = LOAD_EXISTING_TOKEN_SUCCESS;

  constructor(public payload: TokenDetails) {}
}

export class LoadExistingTokenFail implements Action {
  readonly type = LOAD_EXISTING_TOKEN_FAIL;

  constructor(public err: any) {}
}

export class RenewToken implements Action {
  readonly type = RENEW_TOKEN;
}

export class RenewTokenSuccess implements Action {
  readonly type = RENEW_TOKEN_SUCCESS;

  constructor(public payload: TokenDetails) {}
}

export class RenewTokenFail implements Action {
  readonly type = RENEW_TOKEN_FAIL;

  constructor(public error: any) {}
}

// Retrieving the login and user data into the NGRX store and the localStorage

export const LOAD_USER_DETAILS = '[Auth] Load user details';
export const LOAD_USER_DETAILS_SUCCESS = '[Auth] Load user details success';
export const LOAD_USER_DETAILS_FAIL = '[Auth] Load user details fail';

export class LoadUserDetails implements Action {
  readonly type = LOAD_USER_DETAILS;

  constructor() {}
}

export class LoadUserDetailsSuccess implements Action {
  readonly type = LOAD_USER_DETAILS_SUCCESS;

  constructor(public userDetails: UserDetails) {}
}

export class LoadUserDetailsFail implements Action {
  readonly type = LOAD_USER_DETAILS_FAIL;

  constructor(public error: any) {}
}

// Login and logout triggers

export const SET_REDIRECT_AFTER_AUTH = '[Auth] Set Redirect After Login';
export const REDIRECT_AFTER_AUTH = '[Auth] Redirect After Login';
export const LOGIN = '[Auth] Login';
export const LOGIN_FAILURE = '[Auth] Login failure';
export const LOGIN_SUCCESS = '[Auth] Login success';
export const LOGOUT = '[Auth] Logout';
export const LOGOUT_SUCCESS = '[Auth] Logout success';
export const LOGOUT_FAILURE = '[Auth] Logout failure';
export const LOGIN_MFA_REQUIRED = '[Auth] Login MFA required';
export const LOGIN_WITH_MFA = '[Auth] Login with MFA';

export class Login implements Action {
  readonly type = LOGIN;

  constructor(
    public email,
    public password,
  ) {}
}

export class LoginFailure implements Action {
  readonly type = LOGIN_FAILURE;

  constructor(public err: any) {}
}

export class LoginSuccess implements Action {
  readonly type = LOGIN_SUCCESS;

  constructor(
    public payload: TokenDetails,
    public dontRedirect = false,
  ) {}
}

export class LoginMFARequired implements Action {
  readonly type = LOGIN_MFA_REQUIRED;

  constructor(
    public mfaToken: string,
    public mfaEnrollmentUri?: string,
  ) {}
}

export class LoginWithMFA implements Action {
  readonly type = LOGIN_WITH_MFA;

  constructor(
    public email: string,
    public mfaToken: string,
    public otpCode: string,
  ) {}
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export class LogoutSuccess implements Action {
  readonly type = LOGOUT_SUCCESS;
}

export class LogoutFailure implements Action {
  readonly type = LOGOUT_FAILURE;

  constructor(public err: any) {}
}

export class SetRedirectAfterAuth implements Action {
  readonly type = SET_REDIRECT_AFTER_AUTH;

  constructor(public url?: string) {}
}

export class RedirectAfterAuth implements Action {
  readonly type = REDIRECT_AFTER_AUTH;

  constructor() {}
}

// Azure Login

export const AZURE_LOGIN = '[Auth] Azure Login';
export const AZURE_LOGIN_FAILED = '[Auth] Azure Login failed';
export const GET_AZURE_CONNECTION_NAME = '[Auth] Get Azure connection name';
export const GET_AZURE_CONNECTION_NAME_SUCCESS = '[Auth] Get Azure connection name success';

export class AzureLogin implements Action {
  readonly type = AZURE_LOGIN;

  constructor(
    public email,
    public dontRedirect = false,
  ) {}
}

export class AzureLoginFailed implements Action {
  readonly type = AZURE_LOGIN_FAILED;

  constructor(public error: AzureErrors) {}
}

export class GetAzureConnectionName implements Action {
  readonly type = GET_AZURE_CONNECTION_NAME;

  constructor(
    public email,
    public dontRedirect = false,
  ) {}
}

export class GetAzureConnectionNameSuccess implements Action {
  readonly type = GET_AZURE_CONNECTION_NAME_SUCCESS;

  constructor(
    public payload: AzureConnectionResponse,
    public dontRedirect = false,
  ) {}
}

export const SIGN_UP = '[Auth] Sign Up';
export const SIGN_UP_FAILURE = '[Auth] Sign Up failure';
export const SIGN_UP_SUCCESS = '[Auth] Sign Up success';
export const QUIT_SIGN_UP = '[Auth] Quit sign up';

export class SignUp implements Action {
  readonly type = SIGN_UP;

  constructor(
    public email: string,
    public password: string,
    public inviteeId: string,
    public fromPopup: boolean,
  ) {}
}

export class SignUpFailure implements Action {
  readonly type = SIGN_UP_FAILURE;

  constructor(public err: any) {}
}

export class SignUpSuccess implements Action {
  readonly type = SIGN_UP_SUCCESS;

  constructor() {}
}

export class QuitSignUp implements Action {
  readonly type = QUIT_SIGN_UP;

  constructor() {}
}

export const SET_INFO = '[Auth] Set Info';
export const SET_INFO_SUCCESS = '[Auth] Set Info Success';
export const SET_INFO_FAILURE = '[Auth] Set Info failure';

export class SetInfo implements Action {
  readonly type = SET_INFO;

  constructor(
    public fullName: string,
    public role: string,
    public organization: string,
    public organizationId: string,
    public fromPopup: boolean,
  ) {}
}

export class SetInfoSuccess implements Action {
  readonly type = SET_INFO_SUCCESS;
}

export class SetInfoFailure implements Action {
  readonly type = SET_INFO_FAILURE;

  constructor(public err: string) {}
}

export const INVITE_USERS = '[Auth] Invite Users';
export const INVITE_USERS_FAILURE = '[Auth] Invite Users failure';
export const INVITE_USERS_SUCCESS = '[Auth] Invite Users success';

export class InviteUsers implements AnalyticsAction {
  readonly type = INVITE_USERS;
  analyticsEvent: AnalyticsEvent;

  constructor(
    public emails: {
      email: string;
      admin: boolean;
    }[],
    public fromPopup: boolean,
    public fromSettings?: boolean,
  ) {
    this.analyticsEvent = extractEventFromType(this.type);
    this.analyticsEvent.eventValue = emails.length;
  }
}

export class InviteUsersFailure implements AnalyticsAction {
  readonly type = INVITE_USERS_FAILURE;
  analyticsEvent: AnalyticsEvent;

  constructor(public err: string) {
    this.analyticsEvent = extractEventFromType(this.type);
  }
}

export class InviteUsersSuccess implements Action {
  readonly type = INVITE_USERS_SUCCESS;
}

export const SET_SIGN_UP_STEP = '[Auth] Set Sign Up Step';

export class SetSignUpStep implements Action {
  readonly type = SET_SIGN_UP_STEP;

  constructor(public step: number) {}
}

export const RESEND_EMAIL = '[Auth] Resend Email';
export const RESEND_EMAIL_SUCCESS = '[Auth] Resend Email success';
export const RESEND_EMAIL_FAILURE = '[Auth] Resend Email failure';

export class ResendEmail implements Action {
  readonly type = RESEND_EMAIL;

  constructor(public email?: string) {}
}

export class ResendEmailSuccess implements Action {
  readonly type = RESEND_EMAIL_SUCCESS;

  constructor() {}
}

export class ResendEmailFailure implements Action {
  readonly type = RESEND_EMAIL_FAILURE;

  constructor(public err: string) {}
}

export const CHANGE_PASSWORD = '[Auth] Change Password';
export const CHANGE_PASSWORD_FAILURE = '[Auth] Change Password failure';

export class ChangePassword implements Action {
  readonly type = CHANGE_PASSWORD;

  constructor(
    public email: string,
    public userId: string,
    public password: string,
    public oldPassword: string,
    public token?: string,
  ) {}
}

export class ChangePasswordFailure implements Action {
  readonly type = CHANGE_PASSWORD_FAILURE;

  constructor(public err: string) {}
}

export const SEND_RESET_EMAIL = '[Auth] Send Reset Email';
export const SEND_RESET_EMAIL_FAILURE = '[Auth] Send Reset Email failure';

export class SendResetEmail implements Action {
  readonly type = SEND_RESET_EMAIL;

  constructor(public email: string) {}
}

export class SendResetEmailFailure implements Action {
  readonly type = SEND_RESET_EMAIL_FAILURE;

  constructor(public err: string) {}
}

export const GET_INVITEE_DATA = '[Auth] Get Invitee Data';
export const GET_INVITEE_DATA_SUCCESS = '[Auth] Get Invitee Data success';
export const GET_INVITEE_DATA_FAILURE = '[Auth] Get Invitee Data failure';

export class GetInviteeData implements Action {
  readonly type = GET_INVITEE_DATA;

  constructor(public inviteId: string) {}
}

export class GetInviteeDataSuccess implements Action {
  readonly type = GET_INVITEE_DATA_SUCCESS;

  constructor(public inviteeData: InviteeDataResponse) {}
}

export class GetInviteeDataFailure implements Action {
  readonly type = GET_INVITEE_DATA_FAILURE;

  constructor(public err: string) {}
}

export const DETERMINE_USER_REGION = '[Auth] Determine User Region';
export const USER_REGION_UPDATE = '[Auth] User Region Update';

export class DetermineUserRegion implements Action {
  readonly type = DETERMINE_USER_REGION;
}

export class UserRegionUpdate implements Action {
  readonly type = USER_REGION_UPDATE;

  constructor(public region: UserLocationData) {}
}

export const UPDATE_USER_INFO = '[Auth] Update User Info';
export const UPDATE_USER_INFO_SUCCESS = '[Auth] Update User Info Success';
export const UPDATE_USER_INFO_FAIL = '[Auth] Update User Info Fail';

export class UpdateUserInfo implements Action {
  readonly type = UPDATE_USER_INFO;

  constructor(
    public name: string,
    public role: string,
  ) {}
}

export class UpdateUserInfoSuccess implements Action {
  readonly type = UPDATE_USER_INFO_SUCCESS;
}

export class UpdateUserInfoFail implements Action {
  readonly type = UPDATE_USER_INFO_FAIL;

  constructor(public err: any) {}
}

export type AuthAction =
  | LoadExistingToken
  | LoadExistingTokenSuccess
  | RenewToken
  | RenewTokenSuccess
  | RenewTokenFail
  | LoadUserDetails
  | LoadUserDetailsSuccess
  | LoadUserDetailsFail
  | SetRedirectAfterAuth
  | RedirectAfterAuth
  | Login
  | LoginSuccess
  | LoginFailure
  | Logout
  | LogoutSuccess
  | LogoutFailure
  | AzureLogin
  | AzureLoginFailed
  | GetAzureConnectionName
  | GetAzureConnectionNameSuccess
  | SignUp
  | SignUpSuccess
  | SignUpFailure
  | SetInfo
  | SetInfoSuccess
  | SetInfoFailure
  | InviteUsers
  | InviteUsersFailure
  | InviteUsersSuccess
  | SetSignUpStep
  | ResendEmail
  | ResendEmailSuccess
  | ResendEmailFailure
  | SendResetEmail
  | SendResetEmailFailure
  | ChangePassword
  | ChangePasswordFailure
  | GetInviteeData
  | GetInviteeDataSuccess
  | GetInviteeDataFailure
  | DetermineUserRegion
  | UserRegionUpdate
  | QuitSignUp
  | UpdateUserInfo
  | UpdateUserInfoFail
  | UpdateUserInfoSuccess
  | LoginMFARequired
  | LoginWithMFA;
