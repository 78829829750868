import { Action } from '@ngrx/store';
import { Analysis, AnalysisUpdateBulkPayload } from '../models';
import { ReportExportType } from '../../../reporting/store';

export const UPDATE_ANALYSIS = '[Analyses] Update analysis';
export const UPDATE_ANALYSIS_FAIL = '[Analyses] Update analysis fail';
export const UPDATE_ANALYSIS_SUCCESS = '[Analyses] Update analysis success';

export class UpdateAnalysis implements Action {
  readonly type = UPDATE_ANALYSIS;

  constructor(
    public payload: Analysis,
    public errorSnackbarMsg?: string,
    public properties?: string[],
  ) {}
}

export class UpdateAnalysisFail implements Action {
  readonly type = UPDATE_ANALYSIS_FAIL;

  constructor(
    public error: any,
    public payload: Analysis,
    public properties?: string[],
  ) {}
}

export class UpdateAnalysisSuccess implements Action {
  readonly type = UPDATE_ANALYSIS_SUCCESS;

  constructor(
    public payload: Analysis,
    public properties?: string[],
  ) {}
}

export const DELETE_ANALYSIS = '[Analyses] Delete analysis';
export const DELETE_ANALYSIS_FAIL = '[Analyses] Delete analysis fail';
export const DELETE_ANALYSIS_SUCCESS = '[Analyses] Delete analysis success';

export class DeleteAnalysis implements Action {
  readonly type = DELETE_ANALYSIS;

  constructor(public analysisId: number) {}
}

export class DeleteAnalysisFail implements Action {
  readonly type = DELETE_ANALYSIS_FAIL;

  constructor(public payload: any) {}
}

export class DeleteAnalysisSuccess implements Action {
  readonly type = DELETE_ANALYSIS_SUCCESS;

  constructor(public analysisId: number) {}
}

export const GENERATE_REPORT_LINKS_TILE = '[Analyses] Generate report links in tile';
export const GENERATE_REPORT_LINKS_TILE_FAIL = '[Analyses]Generate report links in tile fail';
export const GENERATE_REPORT_LINKS_TILE_SUCCESS = '[Analyses] Generate report links in tile success';

export class GenerateReportLinksTile implements Action {
  readonly type = GENERATE_REPORT_LINKS_TILE;

  constructor(
    public analysisId: number,
    public exportTypes: ReportExportType[],
  ) {}
}

export class GenerateReportLinksTileSuccess implements Action {
  readonly type = GENERATE_REPORT_LINKS_TILE_SUCCESS;

  constructor(
    public fileLinks: { [key in ReportExportType]: string },
    public analysisId: number,
    public exportTypes: ReportExportType[],
  ) {}
}

export class GenerateReportLinksTileFail implements Action {
  readonly type = GENERATE_REPORT_LINKS_TILE_FAIL;

  constructor(public error: any) {}
}

export const UPDATE_ANALYSES_BULK = '[Analyses] Update analyses bulk';
export const UPDATE_ANALYSES_BULK_FAIL = '[Analyses] Update analyses bulk fail';
export const UPDATE_ANALYSES_BULK_SUCCESS = '[Analyses] Update analyses bulk success';

export class UpdateAnalysesBulk implements Action {
  readonly type = UPDATE_ANALYSES_BULK;

  constructor(public payload: AnalysisUpdateBulkPayload) {}
}

export class UpdateAnalysesBulkFail implements Action {
  readonly type = UPDATE_ANALYSES_BULK_FAIL;

  constructor(public err: any) {}
}

export class UpdateAnalysesBulkSuccess implements Action {
  readonly type = UPDATE_ANALYSES_BULK_SUCCESS;

  constructor(public result: Analysis[]) {}
}

export const RERUN_ANALYSIS_TREES = '[Analyses] Rerun analysis trees';
export const RERUN_ANALYSIS_TREES_SUCCESS = '[Analyses] Rerun analysis trees success';
export const RERUN_ANALYSIS_TREES_FAIL = '[Analyses] Rerun analysis trees fail';

export class RerunAnalysisTrees implements Action {
  readonly type = RERUN_ANALYSIS_TREES;
  constructor(public analysisId: number) {}
}

export class RerunAnalysisTreesSuccess implements Action {
  readonly type = RERUN_ANALYSIS_TREES_SUCCESS;
  constructor(public analysisId: number) {}
}

export class RerunAnalysisTreesFail implements Action {
  readonly type = RERUN_ANALYSIS_TREES_FAIL;
  constructor(
    public analysisId: number,
    public error: any,
  ) {}
}

export const RERUN_CASE_ANNOTATIONS = '[Analyses] Rerun case annotations';
export const RERUN_CASE_ANNOTATIONS_SUCCESS = '[Analyses] Rerun case annotations success';
export const RERUN_CASE_ANNOTATIONS_FAIL = '[Analyses] Rerun case annotations fail';

export class RerunCaseAnnotations implements Action {
  readonly type = RERUN_CASE_ANNOTATIONS;
  constructor(public analysisId: number) {}
}

export class RerunCaseAnnotationsSuccess implements Action {
  readonly type = RERUN_CASE_ANNOTATIONS_SUCCESS;
  constructor(public analysisId: number) {}
}

export class RerunCaseAnnotationsFail implements Action {
  readonly type = RERUN_CASE_ANNOTATIONS_FAIL;
  constructor(
    public analysisId: number,
    public error: any,
  ) {}
}

export type AnalysisActionsAction =
  | UpdateAnalysis
  | UpdateAnalysisFail
  | UpdateAnalysisSuccess
  | UpdateAnalysesBulk
  | UpdateAnalysesBulkSuccess
  | UpdateAnalysesBulkFail
  | DeleteAnalysis
  | DeleteAnalysisFail
  | DeleteAnalysisSuccess
  | GenerateReportLinksTile
  | GenerateReportLinksTileSuccess
  | GenerateReportLinksTileFail
  | RerunAnalysisTrees
  | RerunAnalysisTreesSuccess
  | RerunAnalysisTreesFail
  | RerunCaseAnnotations
  | RerunCaseAnnotationsSuccess
  | RerunCaseAnnotationsFail;
