import { Action } from '@ngrx/store';
import { SnackbarMode } from '../models/snackbar.model';

export const OPEN_SNACKBAR = '[Snackbar] Open Snackbar';

export class OpenSnackbar implements Action {
  readonly type = OPEN_SNACKBAR;

  constructor(
    public msg: string,
    public mode: SnackbarMode,
    public details?: string,
    public duration?: number,
  ) {}
}

export type SnackbarActions = OpenSnackbar;
