export enum ZygosityDescription {
  het = 'Heterozygote',
  hom = 'Homozygote',
  hom_ref = 'Homozygote (ref)',
  hemi_alt = 'Hemizygote',
  uncalled = 'Uncalled',
}

export enum ZygosityMitochrondriaDescription {
  het = 'Heteroplasmy',
  hom = 'Homoplasmy',
  hom_ref = 'Homozygote (ref)',
  hemi_alt = 'Hemizygote',
  uncalled = 'Uncalled',
}

export enum ZygosityShortDescription {
  het = 'Het',
  hom = 'Hom',
  hom_ref = 'Hom ref',
  hemi_alt = 'Hemi',
  uncalled = 'Uncalled',
}
