import { createAction, props } from '@ngrx/store';
import { SigSuggestedGene } from '../../models';
import { GeneCurations } from '../../../variant-page/modules/gene-curation/models';

export const loadSuggestedGenes = createAction('[SIG] Load suggested genes');
export const loadSuggestedGenesSuccess = createAction(
  '[SIG] Load suggested genes success',
  props<{ genes: SigSuggestedGene[]; count: number }>(),
);
export const loadSuggestedGenesFail = createAction('[SIG] Load suggested genes fail', props<{ error: any }>());
export const loadMoreSuggestedGenes = createAction('[SIG] Load more curated genes');

export const loadCuratedGenes = createAction('[SIG] Load curated genes');
export const loadCuratedGenesSuccess = createAction(
  '[SIG] Load curated genes success',
  props<{ genes: GeneCurations[]; count: number }>(),
);
export const loadCuratedGenesFail = createAction('[SIG] Load curated genes fail', props<{ error: any }>());
export const loadMoreCuratedGenes = createAction('[SIG] Load more curated genes');

export const SaveGenesCategoryFilters = createAction(
  '[SIG] Save genes category filters',
  props<{ queryParams: any; category: string }>(),
);

export const loadSigGenes = createAction('[SIG] Load sig genes', props<{ sigId: string }>());

export const setGeneIrrelevant = createAction('[SIG] Set gene irrelevant', props<{ gene: string; sigId: string }>());
export const setGeneIrrelevantSuccess = createAction('[SIG] Set gene irrelevant success', props<{ gene: string }>());
export const setGeneIrrelevantFail = createAction(
  '[SIG] Set gene irrelevant fail',
  props<{
    gene: string;
    error: any;
  }>(),
);
