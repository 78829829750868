import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SearchConfig, VariantId, variantIdToBody2 } from '../models';
import { environment } from '../../../../../../environments/environment';

@Injectable()
export class AppBannerService {
  constructor(private http: HttpClient) {}

  requestExpertOpinion(variantId: VariantId, details: string, caseContext: SearchConfig) {
    const url = `${environment.beBaseURL}/api/contact_network/expert_opinion`;
    const body = {
      ...variantIdToBody2(variantId),
      details,
      case_context: caseContext,
    };

    return this.http.post(url, body);
  }
}
