import * as fromAction from './../actions/variant-page-config.action';
import { VariantPageConfig } from '../../../variant-page/modules/variant-page/models';

export interface VariantPageConfigState {
  [key: number]: {
    config: VariantPageConfig;
    loading: boolean;
    loaded: boolean;
  };
}

const initialState: VariantPageConfigState = {};

export function reducer(state = initialState, action: fromAction.VariantPageConfigAction): VariantPageConfigState {
  switch (action.type) {
    case fromAction.GET_VARIANT_PAGE_CONFIG: {
      return {
        ...state,
        [getStoreId(action.analysisId, action.variantType)]: {
          config: {},
          loading: true,
          loaded: false,
        },
      };
    }
    case fromAction.GET_VARIANT_PAGE_CONFIG_SUCCESS: {
      return {
        ...state,
        [getStoreId(action.analysisId, action.variantType)]: {
          config: { ...action.payload, popupAppType: action.popupAppType },
          loading: false,
          loaded: true,
        },
      };
    }
    case fromAction.GET_VARIANT_PAGE_CONFIG_FAIL: {
      return {
        ...state,
        [getStoreId(action.analysisId, action.variantType)]: {
          ...state[getStoreId(action.analysisId, action.variantType)],
          loading: false,
          loaded: true,
        },
      };
    }
  }

  return state;
}

function getStoreId(analysisId: number, variantType: string) {
  return `${analysisId}_${variantType}`;
}

export const getVariantPageConfig = (state: VariantPageConfigState, props) =>
  state.hasOwnProperty(getStoreId(props.analysisId, props.variantType))
    ? state[getStoreId(props.analysisId, props.variantType)].config
    : undefined;

export const getVariantPageConfigLoading = (state: VariantPageConfigState, props) =>
  state.hasOwnProperty(getStoreId(props.analysisId, props.variantType))
    ? state[getStoreId(props.analysisId, props.variantType)].loading
    : false;

export const getVariantPageConfigLoaded = (state: VariantPageConfigState, props) =>
  state.hasOwnProperty(getStoreId(props.analysisId, props.variantType))
    ? state[getStoreId(props.analysisId, props.variantType)].loaded
    : false;
