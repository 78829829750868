import { Action } from '@ngrx/store';
import { TeamMember } from '../../models';

export const UPDATE_ORGANIZATION_INFO = '[Auth] Update organization info';
export const UPDATE_ORGANIZATION_INFO_FAIL = '[Auth] Update organization info fail';
export const UPDATE_ORGANIZATION_INFO_SUCCESS = '[Auth] Update organization info success';

export class UpdateOrgInfo implements Action {
  readonly type = UPDATE_ORGANIZATION_INFO;

  constructor(public name: string) {}
}

export class UpdateOrgInfoSuccess implements Action {
  readonly type = UPDATE_ORGANIZATION_INFO_SUCCESS;
}

export class UpdateOrgInfoFail implements Action {
  readonly type = UPDATE_ORGANIZATION_INFO_FAIL;

  constructor(public err: any) {}
}

export const GET_TEAM_MEMBERS = '[Auth] Get team members';
export const GET_TEAM_MEMBERS_SUCCESS = '[Auth] Get team members success';
export const GET_TEAM_MEMBERS_FAIL = '[Auth] Get team members fail';

export class GetTeamMembers implements Action {
  readonly type = GET_TEAM_MEMBERS;

  constructor(public extended: boolean) {}
}

export class GetTeamMembersSuccess implements Action {
  readonly type = GET_TEAM_MEMBERS_SUCCESS;

  constructor(public members: TeamMember[]) {}
}

export class GetTeamMembersFail implements Action {
  readonly type = GET_TEAM_MEMBERS_FAIL;

  constructor(public err: any) {}
}

export const SET_MEMBER_ROLES = '[Auth] Set member roles';
export const SET_MEMBER_ROLES_SUCCESS = '[Auth] Set member roles success';
export const SET_MEMBER_ROLES_FAIL = '[Auth] Set member roles fail';

export class SetMemberRoles implements Action {
  readonly type = SET_MEMBER_ROLES;

  constructor(
    public userId: string,
    public email: string,
    public isStatusPending: boolean,
    public roles: string[],
  ) {}
}

export class SetMemberRolesSuccess implements Action {
  readonly type = SET_MEMBER_ROLES_SUCCESS;

  constructor(
    public email: string,
    public roles: string[],
  ) {}
}

export class SetMemberRolesFail implements Action {
  readonly type = SET_MEMBER_ROLES_FAIL;

  constructor(
    public err: any,
    public email: string,
  ) {}
}

export const DELETE_MEMBER = '[Auth] Delete member';
export const DELETE_MEMBER_SUCCESS = '[Auth] Delete member success';
export const DELETE_MEMBER_FAIL = '[Auth] Delete member fail';
export const DELETE_MEMBER_CANCEL = '[Auth] Delete member cancel';

export class DeleteMember implements Action {
  readonly type = DELETE_MEMBER;

  constructor(
    public userId: string,
    public email: string,
    public isPending: boolean,
    public userName?: string,
  ) {}
}

export class DeleteMemberSuccess implements Action {
  readonly type = DELETE_MEMBER_SUCCESS;

  constructor(public email: string) {}
}

export class DeleteMemberFail implements Action {
  readonly type = DELETE_MEMBER_FAIL;

  constructor(
    public err: any,
    public email: string,
  ) {}
}

export class DeleteMemberCancel implements Action {
  readonly type = DELETE_MEMBER_CANCEL;

  constructor(public email: string) {}
}

export type OrganizationAction =
  | UpdateOrgInfo
  | UpdateOrgInfoSuccess
  | UpdateOrgInfoFail
  | GetTeamMembers
  | GetTeamMembersSuccess
  | GetTeamMembersFail
  | SetMemberRoles
  | SetMemberRolesSuccess
  | SetMemberRolesFail
  | DeleteMember
  | DeleteMemberSuccess
  | DeleteMemberFail
  | DeleteMemberCancel;
