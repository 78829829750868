export const COMPOUND_ID_SEPARATOR = '-&&-';
export const getVariantId = (variant): string => {
  if (variant.hasOwnProperty('var_id')) {
    // snp
    return variant.var_id;
  } else if (variant.hasOwnProperty('variant_data')) {
    // sv
    return variant.variant_data.var_id;
  } else if (variant.hasOwnProperty('loh_data')) {
    // loh
    return variant.loh_data.id;
  } else {
    // compound
    const varIds = variant.variants?.map((x) => getVariantId(x.data));
    return getCompoundVariantId(varIds[0], varIds[1]);
  }
};

export const getCompoundVariantId = (variantId1: string, variantId2: string) => {
  if (!variantId2) {
    return variantId1;
  }
  return [variantId1, variantId2].sort().join(COMPOUND_ID_SEPARATOR);
};
