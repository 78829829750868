<div class="gnx-dialog-header">
  <div class="gnx-dialog-title">Download {{ data.fileType }} Files</div>
  <div class="gnx-dialog-close-btn" (click)="onClose()">&#x2715;</div>
</div>
<div class="gnx-dialog-body">
  <div class="note">
    the download links are valid for 12 hours. If download fails because of expired links - close the popup and reopen
    to generate new links.
  </div>
  <div class="links-container">
    <div *ngFor="let file of data.files" class="file-link">
      <a [href]="file.url" target="_blank">{{ file.name }}</a>
    </div>
  </div>
</div>
