import { WorkbenchConfig } from '../models/config.model';

import * as fromAction from '../actions/config.action';

export interface WorkbenchConfigState {
  config: WorkbenchConfig;
  loading: boolean;
  loaded: boolean;
}

const initialState: WorkbenchConfigState = {
  config: null,
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action: fromAction.ConfigAction): WorkbenchConfigState {
  switch (action.type) {
    case fromAction.LOAD_WORKBENCH_CONFIG: {
      return {
        ...state,
        config: null,
        loading: true,
        loaded: false,
      };
    }
    case fromAction.LOAD_WORKBENCH_CONFIG_SUCCESS: {
      return {
        ...state,
        config: action.config,
        loading: false,
        loaded: true,
      };
    }
    case fromAction.LOAD_WORKBENCH_CONFIG_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }

  return state;
}

export const getConfig = (state: WorkbenchConfigState) => state.config;
export const getConfigLoading = (state: WorkbenchConfigState) => state.loading;
export const getConfigLoaded = (state: WorkbenchConfigState) => state.loaded;
