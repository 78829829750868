import {
  CaseFilterAction,
  LOAD_CASE_FILTER_DATA,
  LOAD_CASE_FILTER_DATA_SUCCESS,
  LOAD_CASE_FILTER_DATA_FAIL,
  SAVE_CASE_FILTER_DATA,
  SAVE_CASE_FILTER_DATA_SUCCESS,
  SAVE_CASE_FILTER_DATA_FAIL,
} from '../actions';
import { CaseFilterData } from '../models';

export interface CaseFilterState {
  caseFilterData: CaseFilterData;
  caseFilterDataLoaded: boolean;
  caseFilterDataLoading: boolean;
}

export const initialState: CaseFilterState = {
  caseFilterData: null,
  caseFilterDataLoaded: false,
  caseFilterDataLoading: false,
};

export function reducer(state = initialState, action: CaseFilterAction): CaseFilterState {
  switch (action.type) {
    case LOAD_CASE_FILTER_DATA:
      return {
        ...state,
        caseFilterDataLoaded: false,
        caseFilterDataLoading: true,
      };
    case LOAD_CASE_FILTER_DATA_SUCCESS:
      return {
        ...state,
        caseFilterData: action.data,
        caseFilterDataLoaded: true,
        caseFilterDataLoading: false,
      };
    case LOAD_CASE_FILTER_DATA_FAIL:
      return { ...state, caseFilterDataLoaded: false, caseFilterDataLoading: false };
    case SAVE_CASE_FILTER_DATA:
      return {
        ...state,
        caseFilterDataLoaded: false,
        caseFilterDataLoading: true,
      };
    case SAVE_CASE_FILTER_DATA_SUCCESS:
      return {
        ...state,
        caseFilterData: action.data,
        caseFilterDataLoaded: true,
        caseFilterDataLoading: false,
      };
    case SAVE_CASE_FILTER_DATA_FAIL:
      return { ...state, caseFilterDataLoaded: false, caseFilterDataLoading: false };
    default:
      return state;
  }
}

export const getCaseFilterDataResponse = (state: CaseFilterState) => state?.caseFilterData;
export const getCaseFilterDataResponseLoaded = (state: CaseFilterState) => state?.caseFilterDataLoaded;
export const getCaseFilterDataResponseLoading = (state: CaseFilterState) => state?.caseFilterDataLoading;
