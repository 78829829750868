import { Action } from '@ngrx/store';
import { OrganizationCustomSchema } from '../models';

export const GET_ORGANIZATION_CUSTOM_SCHEMA = '[Root] Get organization custom schema';
export const GET_ORGANIZATION_CUSTOM_SCHEMA_SUCCESS = '[Root] Get organization custom schema success';
export const GET_ORGANIZATION_CUSTOM_SCHEMA_FAIL = '[Root] Get organization custom schema fail';

export class GetOrganizationCustomSchema implements Action {
  readonly type = GET_ORGANIZATION_CUSTOM_SCHEMA;
}

export class GetOrganizationCustomSchemaSuccess implements Action {
  readonly type = GET_ORGANIZATION_CUSTOM_SCHEMA_SUCCESS;

  constructor(public payload: OrganizationCustomSchema) {}
}

export class GetOrganizationCustomSchemaFail implements Action {
  readonly type = GET_ORGANIZATION_CUSTOM_SCHEMA_FAIL;

  constructor(public error: any) {}
}

export type OrganizationCustomSchemaAction =
  | GetOrganizationCustomSchema
  | GetOrganizationCustomSchemaSuccess
  | GetOrganizationCustomSchemaFail;
