import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AppModuleState, getAppModuleState } from '../reducers';

import * as fromReducer from './../reducers/genes.reducer';
import { DataStateModel } from '../../shared/models/data-state.model';
import { Phenotype } from '../models';

const getGenesState: MemoizedSelector<AppModuleState, fromReducer.GenesState> = createSelector(
  getAppModuleState,
  (state) => state.genes,
);

export const getGeneSuggestions: MemoizedSelector<AppModuleState, DataStateModel<Phenotype[]>> = createSelector(
  getGenesState,
  (state) => state.genes,
);
