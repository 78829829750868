<div class="dialog-header">
  <div class="subtitle">SAVED FILTERS</div>
  <div class="title">Filter List</div>
  <div class="header-info">Marked filters will appear on the quick filters panel</div>
  <img src="assets/svg/esc-with-txt.svg" width="35" height="32" (click)="onClose()" />
</div>
<mat-dialog-content>
  <div class="search-and-merge">
    <div class="search-wrapper">
      <img src="assets/svg/search-icon.svg" />
      <input [formControl]="searchControl" placeholder="Search" #searchInput />
    </div>
    <div gnxFlexItem flex="0 0 62px"></div>
    <a
      gnxAnchor
      href="/clinical-db/assets/filter-trees"
      target="_blank"
      gnxText
      size="14px"
    >
      Filter trees
      <img src="assets/svg/ex-link.svg" alt="link" />
    </a>
    <ng-container *ngIf="mergeAllowed">
      <div class="merge-button" *ngIf="!inMerge" (click)="inMerge = true">
        <img src="assets/svg/merge.svg" />
        Merge filters
      </div>
      <ng-container *ngIf="inMerge">
        <a class="cancel-btn" (click)="inMerge = false">Cancel</a>
        <button class="create-merge-btn" [disabled]="mergedFilters.length < 2" (click)="onCreateMerge()">Create</button>
      </ng-container>
    </ng-container>
  </div>
  <div class="presets" cdkDropList (cdkDropListDropped)="onDrop($event)">
    <div class="loader" *ngIf="loading$ | async">
      <gnx-dots-loader></gnx-dots-loader>
    </div>
    @if (loadingError$ | async) {
      <div gnxText fontWeight="bold" color="#e03a39">
        Quick filters failed to load, try to refresh the page
      </div>
    } @else {
      <div
        *ngFor="let preset of searchResult; trackBy: trackByFunction"
        cdkDrag
        [cdkDragData]="preset"
        [matTooltip]="canPresetBeMerged(preset)?.tooltip"
        [matTooltipDisabled]="!inMerge || canPresetBeMerged(preset).result"
        matTooltipClass="blue-md-tooltip wide"
      >
        <div
          class="preset"
          [class.disabled]="inMerge && !canPresetBeMerged(preset).result"
        >
          <div class="first-row">
            <img class="drag-handle" src="assets/svg/grip.svg" cdkDragHandle />
            <gnx-checkbox
              *ngIf="inMerge"
              [disabled]="!canPresetBeMerged(preset).result"
              [checked]="mergedFilters.includes(preset.id)"
              (checkedChange)="addToMerge(preset.id, $event)"
              matTooltip="Add to new merged filter"
              matTooltipClass="blue-md-tooltip"
            >
            </gnx-checkbox>
            <div
              class="quick-filter-btn"
              *ngIf="!inMerge"
              (click)="onChangeVisible(preset, !preset.quick_filter)"
              [matTooltip]="(!preset.quick_filter ? 'Show on' : 'Hide from') + ' quick filter panel'"
              matTooltipClass="blue-md-tooltip"
            >
              <div class="eye-image" [class.on]="preset.quick_filter"></div>
            </div>
            <div class="preset-name">
              {{ preset.name }}
              <img src="assets/svg/merge.svg" *ngIf="preset.isMerged" class="merge-icon" />
              <img src="assets/svg/filter-tree.svg" *ngIf="preset.isTreeLabel" class="merge-icon" width="18px" />
            </div>
            <div class="preset-date">{{ preset.created_at | date }}</div>
            <div class="preset-actions" *ngIf="!inMerge">
              <button (click)="onSelect(preset)">Apply now</button>
              <div
                *ngIf="!preset.isTreeLabel && (preset.type !== 'geneview_default_filter')"
                class="delete-btn"
                [class.disabled]="!(isOrgAdmin$ | async) || isPresetPartOfMerge(preset)"
                [matTooltip]="(isOrgAdmin$ | async) ? '' : 'Only admin users can remove filters'"
                matTooltipClass="blue-md-tooltip"
                (click)="$event.stopImmediatePropagation()"
              >
                <div
                  matTooltip="This filter is part of a merged filter and cannot be deleted"
                  matTooltipClass="blue-md-tooltip"
                  [matTooltipDisabled]="!isPresetPartOfMerge(preset) && (isOrgAdmin$ | async)"
                >
                  <img src="assets/svg/trash.svg" (click)="onDelete($event, preset)" />
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="preset.description" class="description-row">{{ preset.description }}</div>
        </div>
      </div>
    }
  </div>
  <div *ngIf="!searchResult.length" class="empty-state">
    We couldn’t find any match<br />
    Try modifying your search
  </div>
</mat-dialog-content>
<!--<mat-dialog-actions>-->
<!--  <a (click)="onClose()">Cancel</a>-->
<!--  <button (click)="onApply()" [disabled]="!isDirty">-->
<!--    <span *ngIf="!applyingChanges">Save</span>-->
<!--    <gnx-dots-loader *ngIf="applyingChanges"></gnx-dots-loader>-->
<!--  </button>-->
<!--</mat-dialog-actions>-->
