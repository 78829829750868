import { createAction, props } from '@ngrx/store';
import { SigDetails, SigTotalCounts } from '../../models';

export const loadSigDetails = createAction('[SIG] Load SIG details', props<{ sigId: string }>());
export const loadSigDetailsSuccess = createAction('[SIG] Load SIG details success', props<{ payload: SigDetails }>());
export const loadSigDetailsFail = createAction('[SIG] Load SIG details fail', props<{ error: any }>());

export const loadSigCounts = createAction('[SIG] Load SIG counts', props<{ sigId: string }>());
export const loadSigCountsSuccess = createAction('[SIG] Load sig counts success', props<{ payload: SigTotalCounts }>());
export const loadSigCountsFail = createAction('[SIG] Load sig counts fail', props<{ error: any }>());
