import { Injectable } from '@angular/core';
import { WorkbenchService } from '../../modules/workbench/store/services/workbench.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { loadCaseHistory, loadCaseHistoryFail, loadCaseHistorySuccess } from '../actions';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class CaseHistoryEffect {
  constructor(
    private actions$: Actions,
    private service: WorkbenchService,
  ) {}

  loadCaseHistory$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCaseHistory),
      switchMap((action) =>
        this.service.getCaseHistory(action.analysisId).pipe(
          map((data) => loadCaseHistorySuccess({ data })),
          catchError((error) => of(loadCaseHistoryFail({ error }))),
        ),
      ),
    ),
  );
}
