import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as fromAction from './../actions/sig-genes.action';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { SigService } from '../services';
import { SIG_TAB_PAGE_SIZE, SigGenesCategoryTab } from '../../models';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppModuleState } from '../../../../store/reducers';
import { getQueryParams } from '../../../../store/selectors';
import { SigState } from '../reducers';
import { getSigDetails, getSigGenesCategoryCurrentPage } from '../selectors';
import { loadSigCounts } from '../actions';

@Injectable()
export class SigGenesEffect {
  constructor(
    private actions$: Actions,
    private sigService: SigService,
    private rootStore$: Store<AppModuleState>,
    private store$: Store<SigState>,
  ) {}

  loadCuratedGenes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadCuratedGenes),
      withLatestFrom(this.rootStore$.pipe(select(getQueryParams)), this.store$.pipe(select(getSigDetails))),
      switchMap(([action, params, sig]) =>
        this.sigService.getCuratedGenes(sig.sig_id, sig.annotation_version, 1, SIG_TAB_PAGE_SIZE, params).pipe(
          map((response) => fromAction.loadCuratedGenesSuccess(response)),
          catchError((error) => of(fromAction.loadCuratedGenesFail({ error }))),
        ),
      ),
    ),
  );

  loadMoreCuratedGenes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadMoreCuratedGenes),
      withLatestFrom(
        this.rootStore$.pipe(select(getQueryParams)),
        this.store$.pipe(select(getSigDetails)),
        this.store$.pipe(
          select(getSigGenesCategoryCurrentPage, { category: SigGenesCategoryTab[SigGenesCategoryTab.curated] }),
        ),
      ),
      switchMap(([action, params, sig, curPage]) =>
        this.sigService.getCuratedGenes(sig.sig_id, sig.annotation_version, curPage, SIG_TAB_PAGE_SIZE, params).pipe(
          map((response: any) => fromAction.loadCuratedGenesSuccess(response)),
          catchError((error) => of(fromAction.loadCuratedGenesFail({ error }))),
        ),
      ),
    ),
  );

  loadSuggestedGenes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadSuggestedGenes),
      withLatestFrom(this.rootStore$.pipe(select(getQueryParams)), this.store$.pipe(select(getSigDetails))),
      switchMap(([action, params, sig]) =>
        this.sigService.getSuggestedGenes(sig.sig_id, sig.annotation_version, 1, SIG_TAB_PAGE_SIZE, params).pipe(
          map((response) => fromAction.loadSuggestedGenesSuccess(response)),
          catchError((error) => of(fromAction.loadSuggestedGenesFail({ error }))),
        ),
      ),
    ),
  );

  loadMoreSuggestedGenes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadMoreSuggestedGenes),
      withLatestFrom(
        this.rootStore$.pipe(select(getQueryParams)),
        this.store$.pipe(select(getSigDetails)),
        this.store$.pipe(
          select(getSigGenesCategoryCurrentPage, { category: SigGenesCategoryTab[SigGenesCategoryTab.suggested] }),
        ),
      ),
      switchMap(([action, params, sig, curPage]) =>
        this.sigService.getSuggestedGenes(sig.sig_id, sig.annotation_version, curPage, SIG_TAB_PAGE_SIZE, params).pipe(
          map((response: any) => fromAction.loadSuggestedGenesSuccess(response)),
          catchError((error) => of(fromAction.loadSuggestedGenesFail({ error }))),
        ),
      ),
    ),
  );

  loadSigGenes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadSigGenes),
      switchMap(({ sigId }) =>
        of(fromAction.loadSuggestedGenes(), fromAction.loadCuratedGenes(), loadSigCounts({ sigId })),
      ),
    ),
  );

  markGeneIrrelevant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.setGeneIrrelevant),
      switchMap((action) =>
        this.sigService.setSuggestedGeneIrrelevant(action.sigId, action.gene).pipe(
          map((response) => fromAction.setGeneIrrelevantSuccess({ gene: action.gene })),
          catchError((error) => of(fromAction.setGeneIrrelevantFail({ gene: action.gene, error }))),
        ),
      ),
    ),
  );
}
