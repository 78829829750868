<button mat-button [matMenuTriggerFor]="langMenu" class="dropdown-trigger">
  <img src="/assets/svg/cogs.svg" width="32px" />
</button>

<mat-menu #langMenu="matMenu" class="dropdown-menu">
  <button
    mat-menu-item
    *ngFor="let language of languages"
    [ngClass]="{ selected: currentLanguage.key === language.key }"
    (click)="setLanguage(language)"
  >
    {{ language.title }}
  </button>
</mat-menu>
