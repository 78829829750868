import { Action } from '@ngrx/store';
import { BatchQcExportResponse, ExportFamilyCarrierTests, AnalysisFileForDownload } from '../models';

export const GET_DOWNLOAD_ANALYSIS_FILE_URL = '[Analysis] Get download analysis file url';
export const GET_DOWNLOAD_ANALYSIS_FILE_URL_SUCCESS = '[Analysis] Get download analysis file url success';
export const GET_DOWNLOAD_ANALYSIS_FILE_URL_FAIL = '[Analysis] Get download analysis file url fail';

export class GetDownloadAnalysisFileUrl implements Action {
  readonly type = GET_DOWNLOAD_ANALYSIS_FILE_URL;

  constructor(
    public analysisId: number,
    public fileType: string,
  ) {}
}

export class GetDownloadAnalysisFileUrlSuccess implements Action {
  readonly type = GET_DOWNLOAD_ANALYSIS_FILE_URL_SUCCESS;

  constructor(
    public result: AnalysisFileForDownload[],
    public fileType: string,
  ) {}
}

export class GetDownloadAnalysisFileUrlFail implements Action {
  readonly type = GET_DOWNLOAD_ANALYSIS_FILE_URL_FAIL;

  constructor(
    public error: any,
    public fileType: string,
  ) {}
}

export const DOWNLOAD_ANALYSIS_FILE = '[Analysis] Download analysis file';
export const DOWNLOAD_ANALYSIS_FILE_SUCCESS = '[Analysis] Download analysis file success';
export const DOWNLOAD_ANALYSIS_FILE_FAIL = '[Analysis] Download analysis file fail';

export class DownloadAnalysisFile implements Action {
  readonly type = DOWNLOAD_ANALYSIS_FILE;

  constructor(
    public analysisId: number,
    public fileType: string,
  ) {}
}

export class DownloadAnalysisFileSuccess implements Action {
  readonly type = DOWNLOAD_ANALYSIS_FILE_SUCCESS;

  constructor(
    public files: AnalysisFileForDownload[],
    public fileType: string,
  ) {}
}

export class DownloadAnalysisFileFail implements Action {
  readonly type = DOWNLOAD_ANALYSIS_FILE_FAIL;

  constructor(
    public error: any,
    public fileType: string,
  ) {}
}

export const DOWNLOAD_ANALYSIS_FILE_OPEN_POPUP = '[Analysis] Download analysis file open popup';

export class DownloadAnalysisFileOpenPopup implements Action {
  readonly type = DOWNLOAD_ANALYSIS_FILE_OPEN_POPUP;

  constructor(
    public files: AnalysisFileForDownload[],
    public fileType: string,
  ) {}
}

export const DOWNLOAD_FAMILY_CARRIERS = '[Analysis] Download family carriers';
export const DOWNLOAD_FAMILY_CARRIERS_SUCCESS = '[Analysis] Download family carriers success';
export const DOWNLOAD_FAMILY_CARRIERS_FAIL = '[Analysis] Download family carriers fail';

export class DownloadFamilyCarriers implements Action {
  readonly type = DOWNLOAD_FAMILY_CARRIERS;

  constructor(
    public payload: ExportFamilyCarrierTests,
    public fileName: string,
  ) {}
}

export class DownloadFamilyCarriersSuccess implements Action {
  readonly type = DOWNLOAD_FAMILY_CARRIERS_SUCCESS;

  constructor(
    public path: string,
    public fileName: string,
  ) {}
}

export class DownloadFamilyCarriersFail implements Action {
  readonly type = DOWNLOAD_FAMILY_CARRIERS_FAIL;

  constructor(public error: any) {}
}

export const CHECK_CONTROL_SAMPLE_REPORT_EXPORT = '[Analysis] Check control sample report export';
export const CHECK_CONTROL_SAMPLE_REPORT_EXPORT_SUCCESS = '[Analysis] Check control sample report export success';
export const CHECK_CONTROL_SAMPLE_REPORT_EXPORT_FAIL = '[Analysis] Check control sample report export fail';

export class CheckControlSampleReportExport implements Action {
  readonly type = CHECK_CONTROL_SAMPLE_REPORT_EXPORT;

  constructor(public analysisId: number) {}
}

export class CheckControlSampleReportExportSuccess implements Action {
  readonly type = CHECK_CONTROL_SAMPLE_REPORT_EXPORT_SUCCESS;

  constructor(public result: BatchQcExportResponse) {}
}

export class CheckControlSampleReportExportFail implements Action {
  readonly type = CHECK_CONTROL_SAMPLE_REPORT_EXPORT_FAIL;

  constructor(public error: any) {}
}

export const EXPORT_CONTROL_SAMPLE_REPORT_REPORT = '[Analysis] Export control sample report Report';

export class ExportControlSampleReportReport implements Action {
  readonly type = EXPORT_CONTROL_SAMPLE_REPORT_REPORT;

  constructor(public path: string) {}
}

export const EXPORT_FILTERED_VARIANTS = '[Analysis] Export filtered variants';
export const EXPORT_FILTERED_VARIANTS_SUCCESS = '[Analysis] Export filtered variants success';
export const EXPORT_FILTERED_VARIANTS_FAIL = '[Analysis] Export filtered variants fail';

export class ExportFilteredVariants implements Action {
  readonly type = EXPORT_FILTERED_VARIANTS;

  constructor(public analysisId: number) {}
}

export class ExportFilteredVariantsSuccess implements Action {
  readonly type = EXPORT_FILTERED_VARIANTS_SUCCESS;

  constructor(public payload: any) {}
}

export class ExportFilteredVariantsFail implements Action {
  readonly type = EXPORT_FILTERED_VARIANTS_FAIL;

  constructor(public error: any) {}
}

export const DOWNLOAD_ANALYSIS_SAMPLE_FILE = '[Analysis] Download analysis sample file';
export const DOWNLOAD_ANALYSIS_SAMPLE_FILE_SUCCESS = '[Analysis] Download analysis sample file success';
export const DOWNLOAD_ANALYSIS_SAMPLE_FILE_FAIL = '[Analysis] Download analysis sample file fail';

export class DownloadAnalysisSampleFile implements Action {
  readonly type = DOWNLOAD_ANALYSIS_SAMPLE_FILE;

  constructor(
    public sampleId: number,
    public fileId: string,
    public fileType: string,
  ) {}
}

export class DownloadAnalysisSampleFileSuccess implements Action {
  readonly type = DOWNLOAD_ANALYSIS_SAMPLE_FILE_SUCCESS;

  constructor(
    public sampleId: number,
    public fileId: string,
    public downloadLink: string,
  ) {}
}

export class DownloadAnalysisSampleFileFail implements Action {
  readonly type = DOWNLOAD_ANALYSIS_SAMPLE_FILE_FAIL;

  constructor(
    public sampleId: number,
    public fileId: string,
    public error: any,
  ) {}
}

export type DownloadAnalysisFileAction =
  | GetDownloadAnalysisFileUrl
  | GetDownloadAnalysisFileUrlSuccess
  | GetDownloadAnalysisFileUrlFail
  | DownloadAnalysisFile
  | DownloadAnalysisFileSuccess
  | DownloadAnalysisFileFail
  | DownloadFamilyCarriers
  | DownloadFamilyCarriersSuccess
  | DownloadFamilyCarriersFail
  | CheckControlSampleReportExport
  | CheckControlSampleReportExportSuccess
  | CheckControlSampleReportExportFail
  | ExportFilteredVariants
  | ExportFilteredVariantsSuccess
  | ExportFilteredVariantsFail
  | DownloadAnalysisSampleFile
  | DownloadAnalysisSampleFileSuccess
  | DownloadAnalysisSampleFileFail;
