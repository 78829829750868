import {
  AnalysisCompoundVariant,
  AnalysisResult,
  AnalysisSingleVariant,
  getAnalysisResultSingleDataVariantId,
  getAnalysisResultVariantId,
  IgvLink,
  SnpResult,
  SomaticSnpResult,
  SomaticSvResult,
  SvResult,
  VariantActionValue,
  VariantType,
} from '../../../analysis-variant/models';
import { COMPOUND_ID_SEPARATOR, getVariantId } from '../../utils/analysis-result.utils';

import * as fromAction from './../actions/analysis-results.action';
import * as fromInterpretation from '../actions/variant-interpretation.action';
import * as fromLabelActions from '../../../variants/store/actions/variant-labels.action';
import * as fromIgvLinkActions from './../../../shared/store/actions/igv-link.action';
import * as fromDiscussionActions from '../../../../../variant-page/modules/discussion/store/actions/discussion.action';
import {
  InjectLastMessageInVariantsList,
  UpdateMessageInVariantsList,
} from '../../../../../variant-page/modules/discussion/models/discussion.utils';
import { VariantsCount } from '../../../shared/models';
import { NOT_RELEVANT_SIGNIFICANCE_GROUP } from '../models';
import { AnalysisType } from '../../../../../../store';

export interface AnalysisResultState {
  data: AnalysisResult[];
  count: VariantsCount;
  loading: boolean;
  loaded: boolean;
  errorLoading: any;
  clinicalSignificanceGroups: string[];
  clinicalSignificanceGroupsDisplayNames: { [section: string]: string };
  clinicalSignificanceGroupsLoading: boolean;
  clinicalSignificanceGroupsLoaded: boolean;
  clinicalSignificanceReasoningOptions: string[];
  clinicalSignificanceReasoningOptionsLoading: boolean;
  clinicalSignificanceReasoningOptionsLoaded: boolean;
  setVariantClinicalSignificanceInProgress: { [key: string]: boolean };
  desktopIgvLinks: {
    [varId: string]: IgvLink;
  };
  varIdsUiSortedByGroup: {
    group?: string;
    var_ids: string[];
  }[];
}

const initialState: AnalysisResultState = {
  data: [],
  count: {
    number_of_pages: -1,
    number_of_results: undefined,
    gene_count: undefined,
    page_number: -1,
  },
  loading: false,
  loaded: false,
  errorLoading: null,
  clinicalSignificanceGroups: [],
  clinicalSignificanceGroupsDisplayNames: {},
  clinicalSignificanceGroupsLoading: false,
  clinicalSignificanceGroupsLoaded: false,
  clinicalSignificanceReasoningOptions: [],
  clinicalSignificanceReasoningOptionsLoading: false,
  clinicalSignificanceReasoningOptionsLoaded: false,
  setVariantClinicalSignificanceInProgress: {},
  desktopIgvLinks: {},
  varIdsUiSortedByGroup: [],
};

export function reducer(
  state = initialState,
  action:
    | fromAction.AnalysisResultsAction
    | fromInterpretation.VariantInterpretationAction
    | fromLabelActions.VariantLabelsAction
    | fromIgvLinkActions.IgvLinkActions
    | fromDiscussionActions.DiscussionAction,
): AnalysisResultState {
  switch (action.type) {
    case fromAction.LOAD_ANALYSIS_VARIANTS_COUNT: {
      return {
        ...state,
        count: {
          ...state.count,
          number_of_results: undefined,
          gene_count: undefined,
        },
      };
    }
    case fromAction.LOAD_ANALYSIS_VARIANTS_COUNT_SUCCESS: {
      return {
        ...state,
        count: {
          ...state.count,
          ...action.count,
        },
      };
    }
    case fromAction.LOAD_ANALYSIS_RESULTS: {
      return {
        ...state,
        count: {
          ...state.count,
          page_number: action.queryParams?.curr_page,
        },
        data: [],
        loading: true,
        loaded: false,
        errorLoading: null,
      };
    }
    case fromAction.LOAD_ANALYSIS_RESULTS_SUCCESS: {
      return {
        ...state,
        data: action.data,
        loading: false,
        loaded: true,
        varIdsUiSortedByGroup: generateUiSortSnapshot(state.clinicalSignificanceGroups, action.data),
      };
    }
    case fromAction.LOAD_ANALYSIS_VARIANTS_DETAILS_SUCCESS: {
      const data =
        action.data?.length > 0
          ? state.data.map((entity) => {
              const updated = action.data.find((x) => getVariantId(x.data) === getVariantId(entity.data));
              return updated ? { ...updated } : { ...entity };
            })
          : state.data;

      return {
        ...state,
        data,
      };
    }
    case fromAction.LOAD_ANALYSIS_RESULTS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        errorLoading: action.error,
      };
    }
    case fromInterpretation.UPDATE_SOMATIC_VARIANT_INTERPRETATION_SUCCESS: {
      if (!state.data.some((entity) => getVariantId(entity.data) === action.variantId)) {
        return { ...state };
      }

      const data = state.data.map((entity: AnalysisSingleVariant): AnalysisSingleVariant => {
        if (action.variantId === getVariantId(entity.data)) {
          const result = {
            ...entity,
            data: {
              ...entity.data,
              ...action.interpretation,
              interpretation_text: action.interpretation.interpretation,
            },
          };

          if (action.interpretation.history && action.interpretation.history.length > 0) {
            (result.data as SomaticSnpResult | SomaticSvResult).classification_flag =
              action.interpretation.history[0].classification;
          }
          return result;
        } else {
          return { ...entity };
        }
      });

      return {
        ...state,
        data,
      };
    }
    case fromAction.REMOVE_VARIANT: {
      let data = [];
      const hasVariant = (variant: AnalysisResult) =>
        action.varId === getVariantId(variant.data) ||
        action.varId.split('_').reverse().join('_') === getVariantId(variant.data);

      data = state.data.filter((variant) => !hasVariant(variant));

      return {
        ...state,
        data,
      };
    }
    case fromAction.CLEAR_ANALYSIS_RESULTS: {
      return {
        ...initialState,
        clinicalSignificanceGroups: state.clinicalSignificanceGroups,
        clinicalSignificanceGroupsDisplayNames: state.clinicalSignificanceGroupsDisplayNames,
        clinicalSignificanceGroupsLoading: state.clinicalSignificanceGroupsLoading,
        clinicalSignificanceGroupsLoaded: state.clinicalSignificanceGroupsLoaded,
        clinicalSignificanceReasoningOptions: state.clinicalSignificanceReasoningOptions,
        clinicalSignificanceReasoningOptionsLoading: state.clinicalSignificanceReasoningOptionsLoading,
        clinicalSignificanceReasoningOptionsLoaded: state.clinicalSignificanceReasoningOptionsLoaded,
      };
    }
    case fromInterpretation.TAKE_BULK_VARIANT_ACTION_SUCCESS: {
      // If it's a workbench variant action with false (remove) value, and we don't have count state (workbench doesn't have pagination)
      if (action.actionType === VariantActionValue.in_workbench && !action.value && state.count.number_of_pages < 0) {
        // remove from the workbench variants list on bulk remove from workbench
        const data = state.data.filter((result) => !action.variantIds.some((id) => getVariantId(result.data) === id));
        return {
          ...state,
          data,
          varIdsUiSortedByGroup: generateUiSortSnapshot(state.clinicalSignificanceGroups, data),
        };
      }
      if (action.actionType === VariantActionValue.in_report) {
        let newState: AnalysisResult[] = setOrgSomaticClassificationFlag(state.data, action.variantIds);
        if (action.clinicalSignificance) {
          newState = setClinicalSignificance(
            state.data,
            action.variantIds,
            action.clinicalSignificance,
            action.irrelevantReasons,
            action.irrelevantReasonDetails,
          );
        }
        return {
          ...state,
          data: newState,
        };
      }
      if (action.actionType === VariantActionValue.clinicalSignificance) {
        return {
          ...state,
          data: setClinicalSignificance(
            state.data,
            action.variantIds,
            action.value ? action.clinicalSignificance : null,
            action.irrelevantReasons,
            action.irrelevantReasonDetails,
          ),
        };
      }
      if (action.actionType === VariantActionValue.irrelevant && action.analysisType !== AnalysisType.tumor) {
        return {
          ...state,
          data: setClinicalSignificance(
            state.data,
            action.variantIds,
            action.value ? NOT_RELEVANT_SIGNIFICANCE_GROUP : null,
            action.irrelevantReasons,
            action.irrelevantReasonDetails,
          ),
        };
      }
      return state;
    }
    case fromInterpretation.INCLUDE_IN_WORKBENCH_SUCCESS: {
      // If it's a workbench variant action with false (remove) value, and we don't have count state (workbench doesn't have pagination)
      if (!action.value && state.count.number_of_pages < 0) {
        // remove from the workbench variants list on bulk remove from workbench
        return {
          ...state,
          data: state.data.filter(
            (result) =>
              getVariantId(result.data) !== action.variantId && getVariantId(result.data) !== action.pairVariantId,
          ),
        };
      }
      return state;
    }
    case fromAction.GET_CLINICAL_SIGNIFICANCE_GROUPS: {
      return {
        ...state,
        clinicalSignificanceGroups: [],
        clinicalSignificanceGroupsDisplayNames: {},
        clinicalSignificanceGroupsLoading: true,
        clinicalSignificanceGroupsLoaded: false,
      };
    }
    case fromAction.GET_CLINICAL_SIGNIFICANCE_GROUPS_SUCCESS: {
      const groups = action.payload.map((group) => group.section_name);
      return {
        ...state,
        clinicalSignificanceGroups: groups,
        clinicalSignificanceGroupsDisplayNames: action.payload.reduce(
          (pre, cur) => ({ ...pre, [cur.section_name]: cur.section_display_name }),
          {},
        ),
        clinicalSignificanceGroupsLoading: false,
        clinicalSignificanceGroupsLoaded: true,
        varIdsUiSortedByGroup: generateUiSortSnapshot(groups, state.data),
      };
    }
    case fromAction.GET_CLINICAL_SIGNIFICANCE_GROUPS_FAIL: {
      return {
        ...state,
        clinicalSignificanceGroupsLoading: false,
        clinicalSignificanceGroupsLoaded: false,
      };
    }
    case fromAction.GET_CLINICAL_SIGNIFICANCE_REASONING_OPTIONS: {
      return {
        ...state,
        clinicalSignificanceReasoningOptionsLoading: true,
        clinicalSignificanceReasoningOptionsLoaded: false,
      };
    }
    case fromAction.GET_CLINICAL_SIGNIFICANCE_REASONING_OPTIONS_SUCCESS: {
      return {
        ...state,
        clinicalSignificanceReasoningOptions: action.payload,
        clinicalSignificanceReasoningOptionsLoading: false,
        clinicalSignificanceReasoningOptionsLoaded: true,
      };
    }
    case fromAction.GET_CLINICAL_SIGNIFICANCE_REASONING_OPTIONS_FAIL: {
      return {
        ...state,
        clinicalSignificanceReasoningOptionsLoading: false,
        clinicalSignificanceReasoningOptionsLoaded: false,
      };
    }
    case fromAction.SET_VARIANT_CLINICAL_SIGNIFICANCE: {
      return {
        ...state,
        setVariantClinicalSignificanceInProgress: {
          ...state.setVariantClinicalSignificanceInProgress,
          [action.significance]: true,
        },
      };
    }
    case fromAction.SET_VARIANT_CLINICAL_SIGNIFICANCE_SUCCESS: {
      return {
        ...state,
        data: setClinicalSignificance(state.data, [action.varId], action.significance, action.reasons, action.details),
        setVariantClinicalSignificanceInProgress: {
          ...state.setVariantClinicalSignificanceInProgress,
          [action.significance]: false,
        },
      };
    }
    case fromAction.SET_VARIANT_CLINICAL_SIGNIFICANCE_FAIL: {
      return {
        ...state,
        setVariantClinicalSignificanceInProgress: {
          ...state.setVariantClinicalSignificanceInProgress,
          [action.significance]: false,
        },
      };
    }
    case fromAction.CLEAR_VARIANT_CLINICAL_SIGNIFICANCE: {
      return {
        ...state,
        setVariantClinicalSignificanceInProgress: {
          ...state.setVariantClinicalSignificanceInProgress,
          ['no-group']: true,
        },
      };
    }
    case fromAction.CLEAR_VARIANT_CLINICAL_SIGNIFICANCE_FAIL: {
      return {
        ...state,
        setVariantClinicalSignificanceInProgress: {
          ...state.setVariantClinicalSignificanceInProgress,
          ['no-group']: false,
        },
      };
    }
    case fromAction.CLEAR_VARIANT_CLINICAL_SIGNIFICANCE_SUCCESS: {
      return {
        ...state,
        data: setClinicalSignificance(state.data, [action.varId], null),
        setVariantClinicalSignificanceInProgress: {
          ...state.setVariantClinicalSignificanceInProgress,
          ['no-group']: false,
        },
      };
    }
    case fromAction.UPDATE_CLINICAL_SIGNIFICANCE_ORDERING: {
      return {
        ...state,
        setVariantClinicalSignificanceInProgress: {
          ...state.setVariantClinicalSignificanceInProgress,
          ...action.payload.map((x) => x.bin_name).reduce((prev, cur) => ({ ...prev, [cur]: true }), {}),
        },
      };
    }
    case fromAction.UPDATE_CLINICAL_SIGNIFICANCE_ORDERING_SUCCESS: {
      const varIdsUiSortedByGroup = state.varIdsUiSortedByGroup.map((group) => {
        const changed = action.payload.find((x) => x.bin_name === group.group);
        return !!changed
          ? {
              group: changed.bin_name,
              var_ids: changed.workbench_units_in_order.map(
                (x) => x.var_id || [...x.compound_ids]?.sort()?.join(COMPOUND_ID_SEPARATOR),
              ),
            }
          : group;
      });
      return {
        ...state,
        varIdsUiSortedByGroup,
        setVariantClinicalSignificanceInProgress: {
          ...state.setVariantClinicalSignificanceInProgress,
          ...action.payload.map((x) => x.bin_name).reduce((prev, cur) => ({ ...prev, [cur]: false }), {}),
        },
      };
    }
    case fromAction.UPDATE_CLINICAL_SIGNIFICANCE_ORDERING_FAIL: {
      return {
        ...state,
        varIdsUiSortedByGroup: [...state.varIdsUiSortedByGroup],
        setVariantClinicalSignificanceInProgress: {
          ...state.setVariantClinicalSignificanceInProgress,
          ...action.payload.map((x) => x.bin_name).reduce((prev, cur) => ({ ...prev, [cur]: false }), {}),
        },
      };
    }
    case fromIgvLinkActions.GET_VARIANT_IGV_LINK: {
      const varId = getAnalysisResultSingleDataVariantId(action.variantType, action.variantData);
      return {
        ...state,
        desktopIgvLinks: {
          ...state.desktopIgvLinks,
          [varId]: {
            loading: true,
            loaded: false,
            link: undefined,
          },
        },
      };
    }
    case fromIgvLinkActions.GET_VARIANT_IGV_LINK_SUCCESS: {
      const varId = getAnalysisResultSingleDataVariantId(action.variantType, action.variantData);
      return {
        ...state,
        desktopIgvLinks: {
          ...state.desktopIgvLinks,
          [varId]: {
            loading: false,
            loaded: true,
            link: action.link?.link,
            custom_link: action.link?.custom_link,
          },
        },
      };
    }
    case fromIgvLinkActions.GET_VARIANT_IGV_LINK_FAIL: {
      const varId = getAnalysisResultSingleDataVariantId(action.variantType, action.variantData);
      return {
        ...state,
        desktopIgvLinks: {
          ...state.desktopIgvLinks,
          [varId]: {
            loading: false,
            loaded: false,
          },
        },
      };
    }
    case fromDiscussionActions.GET_MESSAGES_SUCCESS: {
      if (!action.messages?.length) {
        return state;
      }
      return {
        ...state,
        data: InjectLastMessageInVariantsList(action.messages[action.messages.length - 1], state.data),
      };
    }
    case fromDiscussionActions.SUBMIT_MESSAGE_SUCCESS: {
      return {
        ...state,
        data: action.update
          ? UpdateMessageInVariantsList(action.message, state.data)
          : InjectLastMessageInVariantsList(action.message, state.data),
      };
    }
  }

  return state;
}

const setClinicalSignificance = (
  results: AnalysisResult[],
  varIds: string[],
  significance: string,
  reasonings?: string[],
  details?: string,
): AnalysisResult[] => {
  return results.map((variant) => {
    if (!varIds.some((id) => id === getAnalysisResultVariantId(variant))) {
      return variant;
    }
    const type = variant?.type;
    switch (type) {
      case VariantType.Compound: {
        const compound: AnalysisCompoundVariant = variant as AnalysisCompoundVariant;
        return {
          ...compound,
          data: {
            ...compound.data,
            clinical_significance_data: significance
              ? {
                  significance,
                  reasonings,
                  text_information: details,
                }
              : null,
            variants: significance
              ? [...compound.data.variants]
              : compound.data.variants.map((value) => ({
                  ...value,
                  data: { ...value.data, clinical_significance_data: null },
                })),
          },
        };
      }
      default: {
        const singleVariant: AnalysisSingleVariant = variant as AnalysisSingleVariant;
        return {
          ...singleVariant,
          data: {
            ...singleVariant.data,
            clinical_significance_data: significance
              ? {
                  significance,
                  reasonings,
                  text_information: details,
                }
              : null,
          },
        };
      }
    }
    return variant;
  });
};

const setOrgSomaticClassificationFlag = (results: AnalysisResult[], varIds: string[]): AnalysisResult[] => {
  return results.map((result) => {
    const data = result.data;
    if (
      'latest_flag_for_disease' in data &&
      data.latest_flag_for_disease &&
      !data.classification_flag &&
      varIds.some((id) => id === getVariantId(data))
    ) {
      return {
        ...result,
        data: {
          ...data,
          classification_flag: data.latest_flag_for_disease,
          sub_level_classification: data.latest_sub_classification_for_disease,
        },
      };
    }
    return result;
  });
};

const generateUiSortSnapshot = (groups: string[], data: AnalysisResult[]): { group?: string; var_ids: string[] }[] => {
  return [
    ...groups.map((group) => ({
      group,
      var_ids: data
        .filter((result) => (result?.data as SnpResult | SvResult)?.clinical_significance_data?.significance === group)
        .map((res) => getAnalysisResultVariantId(res)),
    })),
    {
      var_ids: data
        .filter((result) => !(result?.data as SnpResult | SvResult)?.clinical_significance_data?.significance)
        .map((res) => getAnalysisResultVariantId(res)),
    },
  ];
};

export const getAnalysisResultData = (state: AnalysisResultState) => state.data;
export const getVariantsCount = (state: AnalysisResultState) => state.count;
export const getAnalysisResultLoading = (state: AnalysisResultState) => state.loading;
export const getAnalysisResultLoaded = (state: AnalysisResultState) => state.loaded;
export const getAnalysisResultsLoadError = (state: AnalysisResultState) => state.errorLoading;
export const getClinicalSignificanceGroups = (state: AnalysisResultState) => state.clinicalSignificanceGroups;
export const getClinicalSignificanceGroupsDisplayNames = (state: AnalysisResultState) =>
  state.clinicalSignificanceGroupsDisplayNames;
export const getClinicalSignificanceGroupsLoading = (state: AnalysisResultState) =>
  state.clinicalSignificanceGroupsLoading;
export const getClinicalSignificanceGroupsLoaded = (state: AnalysisResultState) =>
  state.clinicalSignificanceGroupsLoaded;
export const getSetVariantClinicalSignificanceInProgress = (state: AnalysisResultState) =>
  state.setVariantClinicalSignificanceInProgress;
export const getClinicalSignificanceReasoningOptions = (state: AnalysisResultState) =>
  state.clinicalSignificanceReasoningOptions;
export const getClinicalSignificanceReasoningOptionsLoading = (state: AnalysisResultState) =>
  state.clinicalSignificanceReasoningOptionsLoading;
export const getClinicalSignificanceReasoningOptionsLoaded = (state: AnalysisResultState) =>
  state.clinicalSignificanceReasoningOptionsLoaded;
