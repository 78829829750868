import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ClickCursorDirective } from '../../directives/click-cursor.directive';

@Component({
  selector: 'gnx-franklin-chip',
  templateUrl: './franklin-chip.component.html',
  styleUrls: ['./franklin-chip.component.scss'],
  standalone: true,
  imports: [ClickCursorDirective],
})
export class FranklinChipComponent implements OnInit {
  @Output() remove: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
