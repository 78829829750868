import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as fromAction from '../actions/case-custom-fields.action';
import { AppService } from '../services';

@Injectable()
export class CaseCustomFieldsEffect {
  constructor(
    private actions$: Actions,
    private service: AppService,
  ) {}

  getCaseCustomFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GET_CASE_CUSTOM_FIELDS),
      switchMap((action: fromAction.GetCaseCustomFields) =>
        this.service.getCaseCustomFields(action.caseType, action.assayId, action.analysisType).pipe(
          map(
            (fields) =>
              new fromAction.GetCaseCustomFieldsSuccess(
                fields.fields,
                fields.case_resolution_fields,
                fields.patient_clinical_info_fields,
                fields.sample_details_fields,
              ),
          ),
          catchError((error) => of(new fromAction.GetCaseCustomFieldsFail(error))),
        ),
      ),
    ),
  );
}
