export enum FeatureBit {
  Discovery = 'show_new_discovery',
  Reports = 'show_reports',
  Coverage = 'show_coverage',
  Assets = 'show_assets',
  Assays = 'show_assays',
  AssaysNew = 'show_assays_new',
  Samples = 'show_samples',
  SigVariantHighlights = 'show_sig_highlights',
  ShowExpandedEvidenceExplanations = 'show_expanded_evidence_explanations',
  CreateCaseFromAssay = 'create_case_from_assay',
  ShowCasesDashboard = 'show_cases_dashboard',
  ShowSimilarVariantsCustomEmpty = 'show_cma_similar_variants_internal_empty',
  EnableViewReport = 'enable_view_report',
  EnableSomaticVariantsTable = 'enable_somatic_variants_table',
  ShowNetworkEmail = 'show_network_email',
  ShowSuggested = 'show_suggested',
  ShowSomaticTree = 'show_somatic_tree',
  EnableCnvTree = 'enable_cnv_tree',
  ShowAiPhenotypes = 'show_ai_phenotypes',
  SingletonSampleSelect = 'singleton_sample_select',
  CaseWithoutFiles = 'case_without_files',
  EnablePermissions = 'enable_permissions',
  UseNewReportExport = 'use_new_report_export',
  UnlockCommunityFreq = 'unlock_community_freq',
  ReportFinalConfirmation = 'report_final_confirmation',
  ShowSomaticDashboard = 'show_somatic_dashboard',
  MaccabiSummaryExport = 'maccabi_summary_export',
  ShowAlamutLink = 'show_alamut_link',
  ShowGenomeNexusLink = 'show_genome_nexus_link',
  XmlReport = 'xml_report',
  ShowSimilarSvInRegionViewer = 'show_similar_sv_in_region_viewer',
  SampleAddFile = 'sample_add_file',
  UploadSampleSheet = 'upload_sample_sheet',
  ShowLegacySomaticReport = 'show_legacy_somatic_report',
  LimitlessVariantsExport = 'limitless_variants_export',
  CreditReport = 'credit_report',
  DisableAutoCaseFromSearch = 'disable_auto_case_from_search',
  MarkAcmgRules = 'mark_acmg_rules',
  ShowMatchmaker = 'show_matchmaker',
  CreateBedPanels = 'create_bed_panels',
  useRevisionReasonRichText = 'use_revision_reason_rich_text',
  ShowVardaLink = 'show_varda_link',
  BlurbAi = 'blurb_ai',
  ShowKbAssigneeFilter = 'show_kb_assignee_filter',
  CompoundClassificationDisable = 'compound_classification_disable',
  SupportIonGermlineUpload = 'support_ion_germline_upload',
  FilterCasesActiveSteps = 'filter_cases_active_steps',
  ExportFilteredVariants = 'export_quick_filters',
  ShowInternalFrequencyGraphs = 'show_internal_frequency_graphs',
  ShowCaseAccess = 'show_case_access',
  PharmaSearchAgg = 'pharma_search_agg',
  DisableShareClassification = 'disable_share_classification',
  ShowGeneViz = 'show_gene_viz',
  ShowGeneImages = 'show_gene_images',
  EnableVariantCuration = 'enable_variant_curation',
  EnableTreeFastRun = 'enable_tree_fast_run',
  AllowUserRunAnnotation = 'allow_user_run_annotation',
}
