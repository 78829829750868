import { createAction, props } from '@ngrx/store';
import { Phenotype } from '../models';

export const loadVariantsAutocomplete = createAction('[Root] Load variants autocomplete', props<{ payload: string }>());
export const loadVariantsAutocompleteFail = createAction(
  '[Root] Load variants autocomplete fail',
  props<{ err: any }>(),
);
export const loadVariantsAutocompleteSuccess = createAction(
  '[Root] Load variants autocomplete success',
  props<{ payload: Phenotype[] }>(),
);
export const clearVariantsAutocomplete = createAction('[Root] Clear variants autocomplete');
