import { InviteeDataResponse, TokenDetails, UserDetails, UserLocationData } from '../../models';
import {
  AuthAction,
  AZURE_LOGIN,
  AZURE_LOGIN_FAILED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILURE,
  GET_INVITEE_DATA_SUCCESS,
  INVITE_USERS,
  INVITE_USERS_FAILURE,
  INVITE_USERS_SUCCESS,
  LOAD_EXISTING_TOKEN,
  LOAD_EXISTING_TOKEN_SUCCESS,
  LOAD_USER_DETAILS_SUCCESS,
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_MFA_REQUIRED,
  LOGIN_SUCCESS,
  LOGIN_WITH_MFA,
  LOGOUT,
  QUIT_SIGN_UP,
  RENEW_TOKEN,
  RENEW_TOKEN_SUCCESS,
  SEND_RESET_EMAIL,
  SEND_RESET_EMAIL_FAILURE,
  SET_INFO,
  SET_INFO_FAILURE,
  SET_INFO_SUCCESS,
  SET_SIGN_UP_STEP,
  SIGN_UP,
  SIGN_UP_FAILURE,
  SIGN_UP_SUCCESS,
  UPDATE_USER_INFO,
  UPDATE_USER_INFO_FAIL,
  UPDATE_USER_INFO_SUCCESS,
  USER_REGION_UPDATE,
} from '../actions';
import { AzureErrors } from '../../../../login-page/containers/login-page/login-page.data';

export interface AuthState {
  userDetails: UserDetails;
  tokenDetails: TokenDetails;
  joinStep: number;
  errors: { login?: string; signUp?: string; reset?: string; setInfo?: string; changePassword?: string };
  azureErrors?: AzureErrors;
  signUpInProgress: boolean;
  loginInProgress: boolean;
  userRegion: UserLocationData;
  inviteeData: InviteeDataResponse;
  inviteUsersInProgress: boolean;
  updateUserInProgress: boolean;
  mfaToken: string;
  mfaEnrollmentUri: string;
}

const authInitialState: AuthState = {
  userDetails: null,
  tokenDetails: null,
  joinStep: 0,
  errors: null,
  signUpInProgress: false,
  loginInProgress: false,
  userRegion: null,
  inviteeData: null,
  inviteUsersInProgress: false,
  updateUserInProgress: false,
  mfaToken: undefined,
  mfaEnrollmentUri: undefined,
};

export function authReducer(state = authInitialState, action: AuthAction): AuthState {
  switch (action.type) {
    case RENEW_TOKEN:
    case CHANGE_PASSWORD:
    case SEND_RESET_EMAIL:
    case LOAD_EXISTING_TOKEN: {
      return {
        ...state,
        errors: null,
      };
    }
    case LOGIN: {
      return {
        ...state,
        errors: null,
        loginInProgress: true,
      };
    }
    case LOGOUT: {
      return {
        ...authInitialState,
      };
    }
    case SIGN_UP:
    case SET_INFO: {
      return {
        ...state,
        signUpInProgress: true,
        errors: null,
      };
    }
    case SIGN_UP_FAILURE: {
      return {
        ...state,
        errors: { signUp: action.err },
        signUpInProgress: false,
      };
    }
    case SIGN_UP_SUCCESS:
    case SET_INFO_SUCCESS: {
      return {
        ...state,
        signUpInProgress: false,
      };
    }
    case RENEW_TOKEN_SUCCESS:
    case LOGIN_SUCCESS:
    case LOAD_EXISTING_TOKEN_SUCCESS: {
      return {
        ...state,
        tokenDetails: action.payload,
        loginInProgress: false,
      };
    }
    case LOAD_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        userDetails: {
          ...action.userDetails,
          feature_bits: {
            ...action.userDetails.feature_bits,
            // enable_new_gene_curation: true,
            // enable_case_details_page: true,
          },
        },
      };
    }
    case SET_SIGN_UP_STEP: {
      return {
        ...state,
        joinStep: action.step,
      };
    }
    case USER_REGION_UPDATE: {
      return {
        ...state,
        userRegion: action.region,
      };
    }
    case QUIT_SIGN_UP: {
      return {
        ...state,
        signUpInProgress: false,
      };
    }
    case GET_INVITEE_DATA_SUCCESS: {
      return {
        ...state,
        inviteeData: action.inviteeData,
      };
    }
    case SET_INFO_FAILURE: {
      return {
        ...state,
        errors: { setInfo: action.err },
        signUpInProgress: false,
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        errors: { login: action.err },
        loginInProgress: false,
      };
    }
    case SEND_RESET_EMAIL_FAILURE: {
      return {
        ...state,
        errors: { reset: action.err },
      };
    }
    case CHANGE_PASSWORD_FAILURE: {
      return {
        ...state,
        errors: { changePassword: action.err },
      };
    }
    case UPDATE_USER_INFO: {
      return {
        ...state,
        updateUserInProgress: true,
      };
    }
    case UPDATE_USER_INFO_FAIL:
    case UPDATE_USER_INFO_SUCCESS: {
      return {
        ...state,
        updateUserInProgress: false,
      };
    }
    case INVITE_USERS: {
      return { ...state, inviteUsersInProgress: true };
    }
    case INVITE_USERS_SUCCESS:
    case INVITE_USERS_FAILURE: {
      return { ...state, inviteUsersInProgress: false };
    }
    case AZURE_LOGIN: {
      return {
        ...state,
        azureErrors: null,
        loginInProgress: true,
      };
    }
    case AZURE_LOGIN_FAILED: {
      return {
        ...state,
        azureErrors: action.error,
        loginInProgress: false,
      };
    }
    case LOGIN_MFA_REQUIRED: {
      return {
        ...state,
        mfaToken: action.mfaToken,
        mfaEnrollmentUri: action.mfaEnrollmentUri,
        loginInProgress: false,
      };
    }
    case LOGIN_WITH_MFA: {
      return {
        ...state,
        errors: null,
        loginInProgress: true,
      };
    }
  }
  return state;
}

export const getUserDetails = (state: AuthState) => state.userDetails;
export const getTokenDetails = (state: AuthState) => state.tokenDetails;
export const getJoinStep = (state: AuthState) => state.joinStep;
export const getAuthErrorDescription = (state: AuthState) => state.errors;
export const getAzureAuthErrorDescription = (state: AuthState) => state.azureErrors;
export const getSignUpInProgress = (state: AuthState) => state.signUpInProgress;
export const getLoginInProgress = (state: AuthState) => state.loginInProgress;
export const getUserRegion = (state: AuthState) => state.userRegion;
export const getInviteeData = (state: AuthState) => state.inviteeData;
export const getUpdateUserInProgress = (state: AuthState) => state.updateUserInProgress;
export const getInviteUsersInProgress = (state: AuthState) => state.inviteUsersInProgress;
