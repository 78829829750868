import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { JsonService } from '../../../shared/services/json.service';
import { AppModuleState, getAnalysisId } from '../../../../../../store';
import * as fromActions from '../actions';
import * as fromWBActions from '../../../workbench/store/actions';
import { MatDialog } from '@angular/material/dialog';
import { AddOrthogonalCommentDialogComponent } from '../../components/add-orthogonal-comment-dialog/add-orthogonal-comment-dialog.component';
import { VariantLabelFields } from '../../../analysis-variant/models';

@Injectable()
export class VariantLabelsEffect {
  constructor(
    private actions$: Actions,
    private rootStore$: Store<AppModuleState>,
    private jsonService: JsonService,
    private dialog: MatDialog,
  ) {}

  $addRemoveLabel: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.ADD_REMOVE_LABEL),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisId))),
      switchMap(([action, analysisId]: [fromActions.AddRemoveLabel, number]) =>
        this.jsonService
          .addRemoveLabel(
            action.label.label,
            action.variantId,
            analysisId,
            action.variantType,
            action.remove,
            action.comment,
            action.fields,
          )
          .pipe(
            switchMap((res) => [
              new fromActions.AddRemoveLabelSuccess(
                res,
                action.showProgressOnVariantTile,
                action.variantId,
                action.remove,
              ),
              new fromWBActions.ReloadAnalysisVariants(),
            ]),
            catchError((err) =>
              of(new fromActions.AddRemoveLabelFail(err, action.showProgressOnVariantTile, action.variantId)),
            ),
          ),
      ),
    ),
  );
}
