import { Action } from '@ngrx/store';
import {
  getVariantIdType,
  SearchHistoryResponse,
  VariantId,
  variantIdToString,
  VariantSearchResponse,
} from '../models';
import { AnalyticsAction, AnalyticsEvent, extractEventFromType } from './analytics.action';
import { SearchConfig } from '../../modules/variant-page/modules/variant-page/models';
import { Analysis } from '../../modules/analysis/modules/analyses/store';

export const SEARCH_VARIANT = '[Root] Search variant';
export const SEARCH_VARIANT_SUCCESS = '[Root] Search variant success';
export const SEARCH_VARIANT_SUCCESS_NO_REDIRECT = '[Root] Search variant success no redirect';
export const SEARCH_VARIANT_FAIL = '[Root] Search variant fail';

export class SearchVariant implements Action {
  readonly type = SEARCH_VARIANT;

  constructor(
    public payload: string,
    public redirect: boolean,
    public referenceGenome?: string,
    public config?: any,
  ) {}
}

export class SearchVariantSuccess implements AnalyticsAction {
  readonly type = SEARCH_VARIANT_SUCCESS;
  analyticsEvent: AnalyticsEvent;

  constructor(
    public variant?: VariantId,
    public gene?: string,
  ) {
    if (variant) {
      const label = `${getVariantIdType(variant)} ${variantIdToString(variant, true)}`;

      this.analyticsEvent = extractEventFromType(this.type);
      this.analyticsEvent.eventLabel = label;
    }
  }
}

export class SearchVariantSuccessNoRedirect implements Action {
  readonly type = SEARCH_VARIANT_SUCCESS_NO_REDIRECT;

  constructor(public variant: VariantId) {}
}

export class SearchVariantFail implements AnalyticsAction {
  readonly type = SEARCH_VARIANT_FAIL;
  public analyticsEvent: AnalyticsEvent;

  constructor(
    public error: any,
    public payload: string,
  ) {
    this.analyticsEvent = extractEventFromType(this.type);
    const statusText = error.statusText ? error.statusText : 'FAILED TO PARSE ERROR MESSAGE';
    this.analyticsEvent.eventLabel = `${statusText} (${payload})`;
  }
}

export const SEARCH_HISTORY = '[Root] Search history';
export const SEARCH_HISTORY_SUCCESS = '[Root] Search history success';
export const SEARCH_HISTORY_FAIL = '[Root] Search history fail';

export class SearchHistory implements Action {
  readonly type = SEARCH_HISTORY;

  constructor(public searchTerm: string) {}
}

export class SearchHistorySuccess implements Action {
  readonly type = SEARCH_HISTORY_SUCCESS;

  constructor(public response: SearchHistoryResponse) {}
}

export class SearchHistoryFail implements Action {
  readonly type = SEARCH_HISTORY_FAIL;

  constructor(public error: any) {}
}

export const SEARCH_ANALYSES = '[Root] Search analyses';
export const SEARCH_ANALYSES_SUCCESS = '[Root] Search analyses success';
export const SEARCH_ANALYSES_FAIL = '[Root] Search analyses fail';

export class SearchAnalyses implements Action {
  readonly type = SEARCH_ANALYSES;

  constructor(public payload: string) {}
}

export class SearchAnalysesSuccess implements Action {
  readonly type = SEARCH_ANALYSES_SUCCESS;

  constructor(public analyses: Analysis[]) {}
}

export class SearchAnalysesFail implements Action {
  readonly type = SEARCH_ANALYSES_FAIL;

  constructor(public error: any) {}
}

export const CLEAR_SEARCH_VARIANT_ERROR = '[Root] Clear search variant error';

export class ClearSearchVariantError implements Action {
  readonly type = CLEAR_SEARCH_VARIANT_ERROR;
}

export const CLEAR_SEARCH_DATA = '[Root] Clear search data';

export class ClearSearchData implements Action {
  readonly type = CLEAR_SEARCH_DATA;
}

export const FIND_VARIANTS_FROM_SEARCH = '[Root] Find variants from search';
export const FIND_VARIANTS_FROM_SEARCH_SUCCESS = '[Root] Find variants from search success';

export class FindVariantsFromSearch implements Action {
  readonly type = FIND_VARIANTS_FROM_SEARCH;

  constructor(
    public payload: string,
    public successAction: any,
    public failAction: any,
    public referenceGenome?: string,
    public disableChoice: boolean = false,
    public disableRoh: boolean = false,
    public context?: SearchConfig,
  ) {}
}

export class FindVariantsFromSearchSuccess implements Action {
  readonly type = FIND_VARIANTS_FROM_SEARCH_SUCCESS;

  constructor(
    public payload: string,
    public result: VariantSearchResponse,
    public successAction: any,
    public failAction: any,
    public referenceGenome?: string,
    public disableChoice: boolean = false,
    public disableRoh: boolean = false,
    public context?: SearchConfig,
  ) {}
}

export const CHOOSE_SEARCH_VARIANT = '[Root] Choose search variant';

export class ChooseSearchVariant implements Action {
  readonly type = CHOOSE_SEARCH_VARIANT;

  constructor(
    public response: VariantSearchResponse,
    public chooseAction: any,
  ) {}
}

export const UNSUPPORTED_TRANSCRIPT = '[Root] Unsupported transcript';

export class UnsupportedTranscript implements Action {
  readonly type = UNSUPPORTED_TRANSCRIPT;

  constructor(
    public variant: VariantId,
    public transcript: string,
    public continueAction: any,
    public cancelAction: any,
  ) {}
}

export type SearchAction =
  | SearchVariant
  | SearchVariantSuccess
  | SearchVariantSuccessNoRedirect
  | SearchVariantFail
  | SearchHistory
  | SearchHistorySuccess
  | SearchHistoryFail
  | SearchAnalyses
  | SearchAnalysesSuccess
  | SearchAnalysesFail
  | ClearSearchVariantError
  | ClearSearchData
  | ChooseSearchVariant
  | UnsupportedTranscript;
