import { Action } from '@ngrx/store';

export const OPEN_SAVE_FILE_WINDOW = '[Shared] Open Save File Window';
export const OPEN_SAVE_FILE_LINK_WINDOW = '[Shared] Open Save File Link Window';

export class OpenSaveFileWindow implements Action {
  readonly type = OPEN_SAVE_FILE_WINDOW;

  constructor(
    public data: any,
    public name: string,
  ) {}
}

export class OpenSaveFileLinkWindow implements Action {
  readonly type = OPEN_SAVE_FILE_LINK_WINDOW;

  constructor(
    public link: string,
    public name: string,
  ) {}
}
