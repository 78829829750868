import { Action } from '@ngrx/store';
import { AnalysisResult, AnalysisSingleVariant, VariantType } from '../../../analysis-variant/models';
import { AnalysisType } from '../../../../../../store/models';
import {
  AnalyticsAction,
  AnalyticsEvent,
  extractEventFromType,
} from '../../../../../variant-page/modules/shared/models';
import { CasePanel } from '../../../../../variant-page/modules/variant-page/models';
import { Params } from '@angular/router';
import { VariantsCount } from '../../../shared/models';
import { UpdateBinOrderingRequest } from '../models';

export const LOAD_ANALYSIS_RESULTS = '[Analysis] Load analysis results';
export const RELOAD_ANALYSIS_VARIANTS = '[Variants] Reload Analysis Variants';
export const LOAD_ANALYSIS_RESULTS_SUCCESS = '[Analysis] Load analysis results success';
export const LOAD_ANALYSIS_RESULTS_FAIL = '[Analysis] Load analysis results fail';
export const CLEAR_ANALYSIS_RESULTS = '[Analysis] Clear analysis results';
export const REMOVE_VARIANT = '[Analysis] Delete Variant';

export class LoadAnalysisResults implements Action {
  readonly type = LOAD_ANALYSIS_RESULTS;

  constructor(
    public source: 'wb' | 'variant-list',
    public analysisId: number,
    public analysisType: AnalysisType,
    public phenotypes?: string[],
    public cancer_type?: string,
    public panels?: CasePanel[],
    public queryParams?: Params,
  ) {}
}

export class ReloadAnalysisVariants implements Action {
  readonly type = RELOAD_ANALYSIS_VARIANTS;
}

export class LoadAnalysisResultsSuccess implements Action {
  readonly type = LOAD_ANALYSIS_RESULTS_SUCCESS;

  constructor(
    public analysisId: number,
    public data: AnalysisResult[],
  ) {}
}

export class LoadAnalysisResultsFail implements Action {
  readonly type = LOAD_ANALYSIS_RESULTS_FAIL;

  constructor(
    public analysisId: number,
    public error: any,
  ) {}
}

export class ClearAnalysisResults implements Action {
  readonly type = CLEAR_ANALYSIS_RESULTS;

  constructor() {}
}

export class RemoveVariant implements Action {
  readonly type = REMOVE_VARIANT;

  constructor(public varId: string) {}
}

export const LOAD_ANALYSIS_VARIANTS_DETAILS = '[Analysis] Load Analysis Variants Details';
export const LOAD_ANALYSIS_VARIANTS_DETAILS_FAIL = '[Analysis] Load Analysis Variants Details Fail';
export const LOAD_ANALYSIS_VARIANTS_DETAILS_SUCCESS = '[Analysis] Load Analysis Variants Details Success';

export class LoadAnalysisVariantsDetails implements Action {
  readonly type = LOAD_ANALYSIS_VARIANTS_DETAILS;

  constructor(public queryParams: Params) {}
}

export class LoadAnalysisVariantsDetailsFail implements Action {
  readonly type = LOAD_ANALYSIS_VARIANTS_DETAILS_FAIL;

  constructor(public payload: any) {}
}

export class LoadAnalysisVariantsDetailsSuccess implements Action {
  readonly type = LOAD_ANALYSIS_VARIANTS_DETAILS_SUCCESS;

  constructor(public data: AnalysisSingleVariant[]) {}
}

export const LOAD_ANALYSIS_VARIANTS_COUNT = '[Variants] Load Analysis Variants Count';
export const LOAD_ANALYSIS_VARIANTS_COUNT_SUCCESS = '[Variants] Load Analysis Variants Count Success';
export const LOAD_ANALYSIS_VARIANTS_COUNT_FAIL = '[Variants] Load Analysis Variants Count Fail';

export class LoadAnalysisVariantsCount implements Action {
  readonly type = LOAD_ANALYSIS_VARIANTS_COUNT;

  constructor(public queryParams: Params) {}
}

export class LoadAnalysisVariantsCountSuccess implements Action {
  readonly type = LOAD_ANALYSIS_VARIANTS_COUNT_SUCCESS;

  constructor(public count: VariantsCount) {}
}

export class LoadAnalysisVariantsCountFail implements Action {
  readonly type = LOAD_ANALYSIS_VARIANTS_COUNT_FAIL;

  constructor(public error: any) {}
}

export const GET_CLINICAL_SIGNIFICANCE_GROUPS = '[Analysis] Get clinical significance groups';
export const GET_CLINICAL_SIGNIFICANCE_GROUPS_SUCCESS = '[Analysis] Get clinical significance groups success';
export const GET_CLINICAL_SIGNIFICANCE_GROUPS_FAIL = '[Analysis] Get clinical significance groups fail';

export class GetClinicalSignificanceGroups implements Action {
  readonly type = GET_CLINICAL_SIGNIFICANCE_GROUPS;

  constructor() {}
}

export class GetClinicalSignificanceGroupsSuccess implements Action {
  readonly type = GET_CLINICAL_SIGNIFICANCE_GROUPS_SUCCESS;

  constructor(
    public assayId: string,
    public payload: { section_name: string; section_display_name: string }[],
  ) {}
}

export class GetClinicalSignificanceGroupsFail implements Action {
  readonly type = GET_CLINICAL_SIGNIFICANCE_GROUPS_FAIL;

  constructor(
    public assayId: string,
    public error: any,
  ) {}
}

export const GET_CLINICAL_SIGNIFICANCE_REASONING_OPTIONS = '[Analysis] Get clinical significance reasoning options';
export const GET_CLINICAL_SIGNIFICANCE_REASONING_OPTIONS_SUCCESS =
  '[Analysis] Get clinical significance reasoning options success';
export const GET_CLINICAL_SIGNIFICANCE_REASONING_OPTIONS_FAIL =
  '[Analysis] Get clinical significance reasoning options fail';

export class GetClinicalSignificanceReasoningOptions implements Action {
  readonly type = GET_CLINICAL_SIGNIFICANCE_REASONING_OPTIONS;

  constructor() {}
}

export class GetClinicalSignificanceReasoningOptionsSuccess implements Action {
  readonly type = GET_CLINICAL_SIGNIFICANCE_REASONING_OPTIONS_SUCCESS;

  constructor(
    public assayId: string,
    public payload: string[],
  ) {}
}

export class GetClinicalSignificanceReasoningOptionsFail implements Action {
  readonly type = GET_CLINICAL_SIGNIFICANCE_REASONING_OPTIONS_FAIL;

  constructor(
    public assayId: string,
    public err: any,
  ) {}
}

export const SET_VARIANT_CLINICAL_SIGNIFICANCE = '[Analysis] Set variant clinical significance';
export const SET_VARIANT_CLINICAL_SIGNIFICANCE_SUCCESS = '[Analysis] Set variant clinical significance success';
export const SET_VARIANT_CLINICAL_SIGNIFICANCE_FAIL = '[Analysis] Set variant clinical significance fail';

export class SetVariantClinicalSignificance implements Action {
  readonly type = SET_VARIANT_CLINICAL_SIGNIFICANCE;

  constructor(
    public analysisType: AnalysisType,
    public variantType: VariantType,
    public varId: string,
    public analysisId: number,
    public significance: string,
    public reasons?: string[],
    public details?: string,
  ) {}
}

export class SetVariantClinicalSignificanceSuccess implements AnalyticsAction {
  analyticsEvent: AnalyticsEvent;
  readonly type = SET_VARIANT_CLINICAL_SIGNIFICANCE_SUCCESS;

  constructor(
    public analysisType: AnalysisType,
    public variantType: VariantType,
    public varId: string,
    public significance: string,
    public reasons?: string[],
    public details?: string,
  ) {
    this.analyticsEvent = {
      ...extractEventFromType(this.type),
      eventLabel: `${variantType} -> ${significance}`,
    };
  }
}

export class SetVariantClinicalSignificanceFail implements Action {
  readonly type = SET_VARIANT_CLINICAL_SIGNIFICANCE_FAIL;

  constructor(
    public error: any,
    public significance: string,
  ) {}
}

export const CLEAR_VARIANT_CLINICAL_SIGNIFICANCE = '[Analysis] Clear variant clinical significance';
export const CLEAR_VARIANT_CLINICAL_SIGNIFICANCE_SUCCESS = '[Analysis] Clear variant clinical significance success';
export const CLEAR_VARIANT_CLINICAL_SIGNIFICANCE_FAIL = '[Analysis] Clear variant clinical significance fail';

export class ClearVariantClinicalSignificance implements Action {
  readonly type = CLEAR_VARIANT_CLINICAL_SIGNIFICANCE;

  constructor(
    public analysisType: AnalysisType,
    public variantType: VariantType,
    public varId: string,
    public analysisId: number,
    public significance: string,
    public reasons?: string[],
    public details?: string,
  ) {}
}

export class ClearVariantClinicalSignificanceSuccess implements AnalyticsAction {
  analyticsEvent: AnalyticsEvent;
  readonly type = CLEAR_VARIANT_CLINICAL_SIGNIFICANCE_SUCCESS;

  constructor(
    public analysisType: AnalysisType,
    public variantType: VariantType,
    public varId: string,
    public significance: string,
  ) {
    this.analyticsEvent = {
      ...extractEventFromType(this.type),
      eventLabel: `${variantType} <- ${significance}`,
    };
  }
}

export class ClearVariantClinicalSignificanceFail implements Action {
  readonly type = CLEAR_VARIANT_CLINICAL_SIGNIFICANCE_FAIL;

  constructor(
    public varId: string,
    public error: any,
  ) {}
}

export const UPDATE_CLINICAL_SIGNIFICANCE_ORDERING = '[Analysis] Update clinical significance ordering';
export const UPDATE_CLINICAL_SIGNIFICANCE_ORDERING_SUCCESS = '[Analysis] Update clinical significance ordering success';
export const UPDATE_CLINICAL_SIGNIFICANCE_ORDERING_FAIL = '[Analysis] Update clinical significance ordering fail';

export class UpdateClinicalSignificanceOrdering implements Action {
  readonly type = UPDATE_CLINICAL_SIGNIFICANCE_ORDERING;

  constructor(
    public payload: UpdateBinOrderingRequest[],
    public analysisId: number,
  ) {}
}

export class UpdateClinicalSignificanceOrderingSuccess implements Action {
  readonly type = UPDATE_CLINICAL_SIGNIFICANCE_ORDERING_SUCCESS;

  constructor(
    public payload: UpdateBinOrderingRequest[],
    public analysisId: number,
  ) {}
}

export class UpdateClinicalSignificanceOrderingFail implements Action {
  readonly type = UPDATE_CLINICAL_SIGNIFICANCE_ORDERING_FAIL;

  constructor(
    public error: any,
    public payload: UpdateBinOrderingRequest[],
    public analysisId: number,
  ) {}
}

export type AnalysisResultsAction =
  | LoadAnalysisResults
  | ReloadAnalysisVariants
  | LoadAnalysisResultsSuccess
  | LoadAnalysisResultsFail
  | ClearAnalysisResults
  | LoadAnalysisVariantsDetails
  | LoadAnalysisVariantsDetailsSuccess
  | LoadAnalysisVariantsDetailsFail
  | LoadAnalysisVariantsCount
  | LoadAnalysisVariantsCountSuccess
  | LoadAnalysisVariantsCountFail
  | RemoveVariant
  | GetClinicalSignificanceGroups
  | GetClinicalSignificanceGroupsSuccess
  | GetClinicalSignificanceGroupsFail
  | SetVariantClinicalSignificance
  | SetVariantClinicalSignificanceSuccess
  | SetVariantClinicalSignificanceFail
  | ClearVariantClinicalSignificance
  | ClearVariantClinicalSignificanceSuccess
  | ClearVariantClinicalSignificanceFail
  | UpdateClinicalSignificanceOrdering
  | UpdateClinicalSignificanceOrderingSuccess
  | UpdateClinicalSignificanceOrderingFail
  | GetClinicalSignificanceReasoningOptions
  | GetClinicalSignificanceReasoningOptionsSuccess
  | GetClinicalSignificanceReasoningOptionsFail;
