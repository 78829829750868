<div class="home-hints">
  <div class="examples-label">Examples:</div>
  <div>
    <span
      *ngFor="let type of TYPES"
      class="home-hint"
      [ngClass]="{ active: expandedExamples === type, disabled: !examples[type] }"
      (click)="expandedExamples = expandedExamples === type ? null : type"
    >
      {{ type }}
    </span>
  </div>
</div>
<mat-expansion-panel
  class="mat-elevation-z0"
  [expanded]="!!expandedExamples"
  [ngClass]="{ top: searchContext || searchControl.value }"
>
  <div class="close-btn" (click)="expandedExamples = null">
    <img src="assets/svg/x-white.svg" />
    Close
  </div>
  <div class="example-label">{{ expandedExamples }} Examples</div>
  <span *ngFor="let example of examples[this.expandedExamples]" (click)="selectExample(example)" class="hint-item">
    {{ example.format }}
  </span>
</mat-expansion-panel>
<div class="search-wrapper">
  <input
    [formControl]="searchControl"
    (keyup.enter)="onClassify()"
    placeholder="Enter variant, gene or select an example above"
  />
  <div class="select-wrapper" [style.flex]="'0 0 108px'" data-qa="reference">
    <strong>REFERENCE</strong>
    <mat-select [(value)]="hgValue">
      <mat-option value="hg19">hg19</mat-option>
      <mat-option value="hg38">hg38</mat-option>
    </mat-select>
  </div>
  <div class="select-wrapper" [style.flex]="'0 0 130px'" data-qa="analysis-type">
    <strong>TYPE</strong>
    <mat-select [(value)]="isTumor">
      <mat-option [value]="false">Germline</mat-option>
      <mat-option [value]="true">Somatic</mat-option>
    </mat-select>
  </div>
  <div class="home-classify-btn green" (click)="onClassify()">
    <span *ngIf="!(searching$ | async)">Search</span>
    <img *ngIf="!(searching$ | async)" src="assets/svg/search-white.svg" />
    <gnx-dots-loader *ngIf="searching$ | async"></gnx-dots-loader>
  </div>
  <div class="search-error" *ngIf="searchError$ | async as searchError">{{ searchError }}</div>
</div>
<gnx-questionnaire
  #questionnaire
  [searchControl]="searchControl"
  (contextChange)="setContext($event)"
  (search)="onClassify($event)"
>
</gnx-questionnaire>
<div *ngIf="false" class="multiple-roh" (click)="onMultipleRoh()">
  <img src="assets/svg/stack.svg" width="18" height="22" />
  Search Multiple ROH
</div>
