import { Component, EventEmitter, forwardRef, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
  selector: 'gnx-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgIf],
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
  @Input() checked: boolean | boolean[] | undefined;
  @Input() @HostBinding('class.disabled') disabled: boolean;
  @Output() checkedChange: EventEmitter<boolean | boolean[]> = new EventEmitter<boolean>();

  onChange: (newVal: boolean | boolean[]) => {};
  onTouched: () => {};

  constructor() {}

  ngOnInit() {}

  @HostListener('click', ['$event'])
  onClick(event) {
    event.stopImmediatePropagation();

    if (Array.isArray(this.checked)) {
      const allTrue: boolean = this.checked.every(Boolean);
      if (allTrue) {
        this.checked = this.checked.map(() => false);
      } else {
        this.checked = this.checked.map(() => true);
      }
    } else {
      this.checked = this.checked !== undefined ? !this.checked : false;
    }

    this.checkedChange.emit(this.checked);
    if (this.onChange) {
      this.onChange(this.checked);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.checked = obj;
  }

  get checkStatus(): boolean | undefined {
    if (Array.isArray(this.checked)) {
      const allTrue: boolean = this.checked.every(Boolean);
      const someTrue: boolean = this.checked.some(Boolean);
      if (allTrue) {
        return true;
      } else if (someTrue) {
        return undefined;
      }
      return false;
    } else {
      return this.checked;
    }
  }
}
