<!--<div class="close-btn" (click)="onClose()">-->
<!--  <img src="/assets/svg/esc-blk.svg" />-->
<!--</div>-->
<div class="top-section">
  <img
    src="assets/png/redirect-panda.png"
    srcset="assets/png/redirect-panda@2x.png 2x, assets/png/redirect-panda@3x.png 3x"
  />
</div>
<div class="bottom-section">
  <div class="text-area">
    <div class="title">Are You in the Correct Franklin Environment?</div>
    <div>
      It seems you've logged in to {{ currentHost }}. You should be accessing the dedicated environment for your account
    </div>
    <div class="redirect-link">
      {{ data?.redirectHost }}
    </div>
  </div>
  <div class="btns-area">
    <a class="redirect-btn" [href]="redirectLink">REDIRECT ME</a>
    <!--    <div class="cancel-btn" (click)="onClose()">Stay here</div>-->
    <div class="comment">You may need to log in again after being redirected.</div>
  </div>
</div>
