import { Action } from '@ngrx/store';
import {
  AllSomaticBiomarkerResponse,
  SomaticBiomarkerResponse,
  SomaticBioMarkerType,
  SomaticBiomarkerUserData,
} from '../models';

export const LOAD_SOMATIC_BIOMARKERS = '[Analysis] Load somatic TMB';
export const LOAD_SOMATIC_BIOMARKERS_SUCCESS = '[Analysis] Load somatic TMB success';
export const LOAD_SOMATIC_BIOMARKERS_FAIL = '[Analysis] Load somatic TMB fail';

export class LoadSomaticBiomarkers implements Action {
  readonly type = LOAD_SOMATIC_BIOMARKERS;

  constructor(
    public analysisId: number,
    public diseases: string[],
  ) {}
}

export class LoadSomaticBiomarkersSuccess implements Action {
  readonly type = LOAD_SOMATIC_BIOMARKERS_SUCCESS;

  constructor(public payload: AllSomaticBiomarkerResponse) {}
}

export class LoadSomaticBiomarkersFail implements Action {
  readonly type = LOAD_SOMATIC_BIOMARKERS_FAIL;

  constructor(public error: any) {}
}

export const LOAD_SOMATIC_MARKER_USER_DATA = '[Analysis] Load somatic marker user data';
export const LOAD_SOMATIC_MARKER_USER_DATA_SUCCESS = '[Analysis] Load somatic marker user data success';
export const LOAD_SOMATIC_MARKER_USER_DATA_FAIL = '[Analysis] Load somatic marker user data fail';

export class LoadSomaticMarkerUserData implements Action {
  readonly type = LOAD_SOMATIC_MARKER_USER_DATA;

  constructor(public analysisId: number) {}
}

export class LoadSomaticMarkerUserDataSuccess implements Action {
  readonly type = LOAD_SOMATIC_MARKER_USER_DATA_SUCCESS;

  constructor(public data: SomaticBiomarkerUserData[]) {}
}

export class LoadSomaticMarkerUserDataFail implements Action {
  readonly type = LOAD_SOMATIC_MARKER_USER_DATA_FAIL;

  constructor(public error: any) {}
}

export const UPDATE_SOMATIC_MARKER_USER_DATA = '[Analysis] Update somatic marker user data';
export const UPDATE_SOMATIC_MARKER_USER_DATA_SUCCESS = '[Analysis] Update somatic marker user data success';
export const UPDATE_SOMATIC_MARKER_USER_DATA_FAIL = '[Analysis] Update somatic marker user data fail';

export class UpdateSomaticMarkerUserData implements Action {
  readonly type = UPDATE_SOMATIC_MARKER_USER_DATA;

  constructor(
    public analysisId: number,
    public payload: SomaticBiomarkerUserData,
  ) {}
}

export class UpdateSomaticMarkerUserDataSuccess implements Action {
  readonly type = UPDATE_SOMATIC_MARKER_USER_DATA_SUCCESS;

  constructor(public payload: SomaticBiomarkerUserData) {}
}

export class UpdateSomaticMarkerUserDataFail implements Action {
  readonly type = UPDATE_SOMATIC_MARKER_USER_DATA_FAIL;

  constructor(public error: any) {}
}

export const UPSERT_BIOMARKER = '[Analysis] UpsertBiomarker';

export class UpsertBiomarker implements Action {
  readonly type = UPSERT_BIOMARKER;

  constructor(
    public analysisId: number,
    public biomarker: SomaticBioMarkerType,
    public score: number,
    public level: string,
  ) {}
}

export type SomaticBiomarkersAction =
  | LoadSomaticBiomarkers
  | LoadSomaticBiomarkersSuccess
  | LoadSomaticBiomarkersFail
  | LoadSomaticMarkerUserData
  | LoadSomaticMarkerUserDataSuccess
  | LoadSomaticMarkerUserDataFail
  | UpdateSomaticMarkerUserData
  | UpdateSomaticMarkerUserDataSuccess
  | UpdateSomaticMarkerUserDataFail
  | UpsertBiomarker;
