import { AssaysAction } from '../actions';

import * as fromAction from './../actions/assays.action';
import { BriefAssayListEntity, ExtendedAssayListEntity } from '../models/assay.model';

export interface AssaysState {
  assayBriefList: BriefAssayListEntity[];
  assayBriefListLoading: boolean;
  assayBriefListLoaded: boolean;
  assayExtendedList: ExtendedAssayListEntity[];
  assayExtendedListLoaded: boolean;
  assayExtendedListLoading: boolean;
  assaysInUse: string[];
  assaysInUseLoading: boolean;
  assaysInUseLoaded: boolean;
}

const initialState: AssaysState = {
  assayBriefList: [],
  assayBriefListLoading: false,
  assayBriefListLoaded: false,
  assayExtendedList: [],
  assayExtendedListLoading: false,
  assayExtendedListLoaded: false,
  assaysInUseLoading: false,
  assaysInUseLoaded: false,
  assaysInUse: [],
};

export function reducer(state = initialState, action: AssaysAction): AssaysState {
  switch (action.type) {
    case fromAction.GET_ASSAY_BRIEF_LIST: {
      return {
        ...state,
        assayBriefListLoading: true,
      };
    }
    case fromAction.GET_ASSAY_BRIEF_LIST_SUCCESS: {
      return {
        ...state,
        assayBriefList: action.list,
        assayBriefListLoading: false,
        assayBriefListLoaded: true,
      };
    }
    case fromAction.GET_ASSAY_BRIEF_LIST_FAIL: {
      return {
        ...state,
        assayBriefListLoading: false,
        assayBriefListLoaded: false,
      };
    }

    case fromAction.GET_ASSAYS_EXTENDED_LIST: {
      return {
        ...state,
        assayExtendedListLoaded: false,
        assayExtendedListLoading: true,
      };
    }
    case fromAction.GET_ASSAYS_EXTENDED_LIST_FAIL: {
      return {
        ...state,
        assayExtendedListLoaded: false,
        assayExtendedListLoading: false,
      };
    }
    case fromAction.GET_ASSAYS_EXTENDED_LIST_SUCCESS: {
      return {
        ...state,
        assayExtendedList: action.assays,
        assayExtendedListLoaded: true,
        assayExtendedListLoading: false,
      };
    }
    case fromAction.CLEAR_ASSAYS_EXTENDED_LIST: {
      return {
        ...state,
        assayExtendedListLoaded: false,
        assayExtendedList: [],
      };
    }
    case fromAction.GET_ASSAYS_IN_USE: {
      return {
        ...state,
        assaysInUse: [],
        assaysInUseLoading: true,
        assaysInUseLoaded: false,
      };
    }
    case fromAction.GET_ASSAYS_IN_USE_SUCCESS: {
      return {
        ...state,
        assaysInUse: action.payload,
        assaysInUseLoading: false,
        assaysInUseLoaded: true,
      };
    }
    case fromAction.GET_ASSAYS_IN_USE_FAIL: {
      return {
        ...state,
        assaysInUseLoading: false,
        assaysInUseLoaded: false,
      };
    }
  }

  return state;
}
