import { SearchConfig, VariantCaseResponse, VariantDetails, VariantId } from '../../models';

import * as fromAction from '../actions/variant-details.action';
import { SAVE_VARIANT_CASE, SAVE_VARIANT_CASE_FAIL, SAVE_VARIANT_CASE_SUCCESS } from '../actions';
import { AnalysisDetailsAction, LOAD_ANALYSIS_DETAILS_SUCCESS } from '../../../../../../store/actions';

export interface VariantDetailsState {
  variantId: VariantId;
  compoundVariantId?: VariantId;
  variantDetails: VariantDetails;
  compoundVariantDetails?: VariantDetails;
  selectedRegions: { [key: string]: boolean };
  loaded: boolean;
  loading: boolean;
  sessionId: string;
  config: SearchConfig;
  caseSaved: boolean;
  caseSaving: boolean;
  casesSaved: { [caseName: string]: VariantCaseResponse };
}

export const initialState: VariantDetailsState = {
  variantId: null,
  variantDetails: null,
  selectedRegions: null,
  loaded: false,
  loading: false,
  sessionId: null,
  config: null,
  caseSaved: false,
  caseSaving: false,
  casesSaved: {},
};

const generateUniqueId = (): string => {
  return (
    Math.random().toString(16).substring(2, 10) +
    '-' +
    Math.random().toString(16).substring(2, 6) +
    '-' +
    Math.random().toString(16).substring(2, 6) +
    '-' +
    Math.random().toString(16).substring(2, 6) +
    '-' +
    Math.random().toString(16).substring(2, 14)
  );
};

export function reducer(
  state = initialState,
  action: fromAction.VariantDetailsAction | AnalysisDetailsAction,
): VariantDetailsState {
  switch (action.type) {
    case fromAction.LOAD_VARIANT_DETAILS: {
      return {
        ...state,
        variantId: action.variantId,
        compoundVariantId: action.compoundVariantId,
        variantDetails: null,
        compoundVariantDetails: null,
        loading: true,
        loaded: false,
        sessionId: generateUniqueId(),
      };
    }
    case fromAction.LOAD_VARIANT_DETAILS_SUCCESS: {
      return {
        ...state,
        variantDetails: action.variantDetails,
        compoundVariantDetails: action.compoundVariantDetails,
        selectedRegions:
          'regions' in action.variantDetails
            ? action.variantDetails.regions.reduce(
                (acc, crt) => ({
                  ...acc,
                  [crt.chrom + '-' + crt.start + '-' + crt.end]: true,
                }),
                {},
              )
            : {},
        loading: false,
        loaded: true,
      };
    }
    case fromAction.LOAD_VARIANT_DETAILS_FAIL: {
      return {
        ...state,
        variantDetails: null,
        loaded: false,
        loading: false,
      };
    }
    case fromAction.CLEAR_VARIANT_DETAILS: {
      return {
        ...state,
        variantDetails: null,
        compoundVariantDetails: null,
        loaded: false,
        loading: false,
        config: null,
      };
    }
    case fromAction.SET_SEARCH_DATA: {
      return {
        ...state,
        config: action.config,
        caseSaved: false,
      };
    }
    case fromAction.PATCH_SEARCH_DATA: {
      return {
        ...state,
        config: {
          ...state.config,
          ...action.config,
        },
        caseSaved: false,
      };
    }
    case fromAction.TOGGLE_REGION: {
      return {
        ...state,
        selectedRegions: {
          ...state.selectedRegions,
          [action.regionId]: !state.selectedRegions[action.regionId],
        },
      };
    }
    case LOAD_ANALYSIS_DETAILS_SUCCESS: {
      return {
        ...state,
        config: {
          ...(state.config || {}),
          phenotypes: [...(state.config?.phenotypes || []), ...(action.payload?.phenotypes || [])],
        },
      };
    }
    case SAVE_VARIANT_CASE: {
      return {
        ...state,
        caseSaved: false,
        caseSaving: true,
      };
    }
    case SAVE_VARIANT_CASE_SUCCESS: {
      return {
        ...state,
        caseSaved: true,
        caseSaving: false,
        casesSaved: action.savedCase
          ? {
              ...state.casesSaved,
              [action.caseName]: {
                id: action.savedCase.id,
                name: action.savedCase.name || state.casesSaved[action.caseName]?.name,
              },
            }
          : state.casesSaved,
      };
    }
    case SAVE_VARIANT_CASE_FAIL: {
      return {
        ...state,
        caseSaved: false,
        caseSaving: false,
      };
    }
  }

  return state;
}

export const getVariantId = (state: VariantDetailsState) => state?.variantId;
export const getCompoundVariantId = (state: VariantDetailsState) => state?.compoundVariantId;
export const getVariantDetailsEntity = (state: VariantDetailsState) => state?.variantDetails;
export const getCompoundVariantDetailsEntity = (state: VariantDetailsState) => state?.compoundVariantDetails;
export const getSelectedRegions = (state: VariantDetailsState) => state?.selectedRegions;
export const getVariantDetailsLoadingEntity = (state: VariantDetailsState) => state?.loading;
export const getVariantDetailsLoadedEntity = (state: VariantDetailsState) => state?.loaded;
export const getSessionId = (state: VariantDetailsState) => state?.sessionId;
export const getSearchConfig = (state: VariantDetailsState) => state?.config;
export const getSearchCaseSaved = (state: VariantDetailsState) => state?.caseSaved;
export const getSearchCaseSaving = (state: VariantDetailsState) => state?.caseSaving;
export const getSearchCasesSaved = (state: VariantDetailsState) => state?.casesSaved;
