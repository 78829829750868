import { Action } from '@ngrx/store';
import { ConditionDetailsResponse, ConditionsQuery, ConditionsResponse } from '../models';
import { VariantId } from '../../../variant-page/models';

export const GET_CONDITIONS = '[Conditions] Get Conditions';
export const GET_CONDITIONS_SUCCESS = '[Conditions] Get Conditions Success';
export const GET_CONDITIONS_FAIL = '[Conditions] Get Conditions Fail';
export const CLEAR_CONDITIONS_DATA = '[Conditions] Clear Conditions Data';

export class GetConditions implements Action {
  readonly type = GET_CONDITIONS;

  constructor(
    public variantId: VariantId,
    public gene: string,
    public query: ConditionsQuery,
    public searchByGene?: boolean,
  ) {}
}

export class GetConditionsSuccess implements Action {
  readonly type = GET_CONDITIONS_SUCCESS;

  constructor(
    public variantId: VariantId,
    public gene: string,
    public payload: ConditionsResponse,
  ) {}
}

export class GetConditionsFail implements Action {
  readonly type = GET_CONDITIONS_FAIL;

  constructor(
    public variantId: VariantId,
    public gene: string,
    public error: any,
  ) {}
}

export const GET_MORE_CONDITIONS = '[Conditions] Get More Conditions';
export const GET_MORE_CONDITIONS_SUCCESS = '[Conditions] Get More Conditions Success';
export const GET_MORE_CONDITIONS_FAIL = '[Conditions] Get More Conditions Fail';

export class GetMoreConditions implements Action {
  readonly type = GET_MORE_CONDITIONS;

  constructor(
    public variantId: VariantId,
    public gene: string,
    public query: ConditionsQuery,
  ) {}
}

export class GetMoreConditionsSuccess implements Action {
  readonly type = GET_MORE_CONDITIONS_SUCCESS;

  constructor(
    public variantId: VariantId,
    public gene: string,
    public payload: ConditionsResponse,
  ) {}
}

export class GetMoreConditionsFail implements Action {
  readonly type = GET_MORE_CONDITIONS_FAIL;

  constructor(
    public variantId: VariantId,
    public gene: string,
    public error: any,
  ) {}
}

export class ClearConditionsData implements Action {
  readonly type = CLEAR_CONDITIONS_DATA;
}

export const GET_CONDITION_DETAILS = '[Conditions] Get Condition Details';
export const GET_CONDITION_DETAILS_SUCCESS = '[Conditions] Get Condition Details Success';
export const GET_CONDITION_DETAILS_FAIL = '[Conditions] Get Condition Details Fail';
export const CLEAR_CONDITION_DETAILS_DATA = '[Conditions] Clear Condition Details Data';

export class GetConditionDetails implements Action {
  readonly type = GET_CONDITION_DETAILS;

  constructor(
    public variantId: VariantId,
    public gene: string,
    public genes: string[],
    public conditionId: string,
  ) {}
}

export class GetConditionDetailsSuccess implements Action {
  readonly type = GET_CONDITION_DETAILS_SUCCESS;

  constructor(
    public variantId: VariantId,
    public gene: string,
    public payload: ConditionDetailsResponse,
  ) {}
}

export class GetConditionDetailsFail implements Action {
  readonly type = GET_CONDITION_DETAILS_FAIL;

  constructor(
    public variantId: VariantId,
    public gene: string,
    public error: any,
  ) {}
}

export class ClearConditionDetailsData implements Action {
  readonly type = CLEAR_CONDITION_DETAILS_DATA;

  constructor(
    public variantId: VariantId,
    public gene: string,
  ) {}
}

export const SELECT_TAB_INDEX = '[Conditions] Select Tab Index';

export class SelectTabIndex implements Action {
  readonly type = SELECT_TAB_INDEX;

  constructor(
    public variantId: VariantId,
    public gene: string,
    public index: number,
  ) {}
}

export type ConditionsAction =
  | GetConditions
  | GetConditionsSuccess
  | GetConditionsFail
  | GetMoreConditions
  | GetMoreConditionsSuccess
  | GetMoreConditionsFail
  | SelectTabIndex
  | ClearConditionsData
  | GetConditionDetails
  | GetConditionDetailsSuccess
  | GetConditionDetailsFail
  | ClearConditionDetailsData;
