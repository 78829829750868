import { createAction, props } from '@ngrx/store';
import { SigTabCounts } from '../../models/sig-counts.model';
import { AnalysisResult } from '../../../analysis/modules/analysis-variant/models/analysis-result.model';
import { SigClassifiedVariant } from '../../models/sig-variant.model';
import { SearchConfig, VariantId } from '../../../variant-page/modules/variant-page/models';
import { VariantInterpretation } from '../../../variant-page/modules/interpretation/models';

export const loadSuggestedVariantsAndCount = createAction('[SIG] Load suggested variants and count');

export const loadSuggestedVariantsCount = createAction('[SIG] Load suggested variants count');
export const loadSuggestedVariantsCountSuccess = createAction(
  '[SIG] Load suggested variants count success',
  props<{ payload: SigTabCounts }>(),
);
export const loadSuggestedVariantsCountFail = createAction(
  '[SIG] Load suggested variants count fail',
  props<{ error: any }>(),
);

export const loadSuggestedVariants = createAction('[SIG] Load suggested variants');
export const loadSuggestedVariantsSuccess = createAction(
  '[SIG] Load suggested variants success',
  props<{ payload: AnalysisResult[] }>(),
);
export const loadSuggestedVariantsFail = createAction('[SIG] Load suggested variants fail', props<{ error: any }>());
export const loadMoreSuggestedVariants = createAction('[SIG] Load more suggested variants');

/*
 * Classified Variants Actions
 * */
export const loadClassifiedVariants = createAction('[SIG] Load classified variants');
export const loadClassifiedVariantsSuccess = createAction(
  '[SIG] Load classified variants success',
  props<{ variants: SigClassifiedVariant[]; total_count: number; genes_count: number }>(),
);
export const loadClassifiedVariantsFail = createAction('[SIG] Load classified variants fail', props<{ error: any }>());
export const loadMoreClassifiedVariants = createAction('[SIG] Load more classified variants');

export const SaveVariantsCategoryFilters = createAction(
  '[SIG] Save variants category filters',
  props<{ queryParams: any; category: string }>(),
);

export const classifySigVariant = createAction(
  '[SIG] Classify sig variant',
  props<{ variantId: VariantId; interpretation: VariantInterpretation; caseContext: SearchConfig }>(),
);
export const classifySigVariantSuccess = createAction('[SIG] Classify sig variant success');
export const classifySigVariantFail = createAction('[SIG] Classify sig variant fail', props<{ err: any }>());

export const loadSigVariantClassification = createAction(
  '[SIG] Load sig variant classification',
  props<{ variantId: VariantId }>(),
);
