import * as fromFiltersStructure from '../actions/filters-structure.action';
import * as fromLastUsed from '../actions/last-used-filter.action';
import { FilterGroup } from '../../../shared/models/filter.model';

export interface FiltersStructureState {
  entities: FilterGroup[];
  loaded: boolean;
  loading: boolean;
}

export const initialState: FiltersStructureState = {
  entities: [],
  loaded: false,
  loading: false,
};

export function reducer(
  state = initialState,
  action: fromFiltersStructure.FiltersStructureAction | fromLastUsed.LastUsedFilterAction,
): FiltersStructureState {
  switch (action.type) {
    case fromLastUsed.LOAD_LAST_USED_FILTER_AND_STRUCTURE:
    case fromFiltersStructure.LOAD_FILTERS_STRUCTURE: {
      return {
        ...state,
        entities: [],
        loading: true,
      };
    }

    case fromFiltersStructure.LOAD_FILTERS_STRUCTURE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        entities: action.payload,
      };
    }

    case fromFiltersStructure.LOAD_FILTERS_STRUCTURE_FAIL: {
      return {
        ...state,
        loaded: false,
        loading: false,
      };
    }

    case fromFiltersStructure.UPDATE_FILTER_OPTIONS: {
      return {
        ...state,
        entities: state.entities.map((group) =>
          group.filters.some((value) => value.id in action.filters)
            ? {
                ...group,
                filters: [
                  ...group.filters.map((value) =>
                    value.id in action.filters
                      ? {
                          ...value,
                          options: [...(action.replace ? [] : [...value.options]), ...action.filters[value.id]],
                        }
                      : value,
                  ),
                ],
              }
            : group,
        ),
      };
    }
  }

  return state;
}

export const getFilterStructureEntities = (state: FiltersStructureState) => state.entities;
export const getFiltersStructureLoaded = (state: FiltersStructureState) => state.loaded;
export const getFiltersStructureLoading = (state: FiltersStructureState) => state.loading;
