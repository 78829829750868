import { Action } from '@ngrx/store';
import { CaseFilterData } from '../models';

export const LOAD_CASE_FILTER_DATA = '[Analysis] Load case filter data';
export const LOAD_CASE_FILTER_DATA_SUCCESS = '[Analysis] Load case filter data Success';
export const LOAD_CASE_FILTER_DATA_FAIL = '[Analysis] Load case filter data Fail';
export const SAVE_CASE_FILTER_DATA = '[Analysis] save case filter data';
export const SAVE_CASE_FILTER_DATA_SUCCESS = '[Analysis] save case filter data Success';
export const SAVE_CASE_FILTER_DATA_FAIL = '[Analysis] save case filter data Fail';

export class LoadCaseFilterData implements Action {
  readonly type = LOAD_CASE_FILTER_DATA;

  constructor() {}
}

export class LoadCaseFilterDataSuccess implements Action {
  readonly type = LOAD_CASE_FILTER_DATA_SUCCESS;

  constructor(public data: CaseFilterData) {}
}

export class LoadCaseFilterDataFail implements Action {
  readonly type = LOAD_CASE_FILTER_DATA_FAIL;

  constructor(public error: any) {}
}

export class SaveCaseFilterData implements Action {
  readonly type = SAVE_CASE_FILTER_DATA;

  constructor(public customData: CaseFilterData) {}
}

export class SaveCaseFilterDataSuccess implements Action {
  readonly type = SAVE_CASE_FILTER_DATA_SUCCESS;

  constructor(public data: CaseFilterData) {}
}

export class SaveCaseFilterDataFail implements Action {
  readonly type = SAVE_CASE_FILTER_DATA_FAIL;

  constructor(public error: any) {}
}

export type CaseFilterAction =
  | LoadCaseFilterData
  | LoadCaseFilterDataSuccess
  | LoadCaseFilterDataFail
  | SaveCaseFilterData
  | SaveCaseFilterDataSuccess
  | SaveCaseFilterDataFail;
