import { VariantIdTypes } from '../../../../store';

export type url = string;

export interface VariantDataServiceConfig {
  analysisDetails?: url;
  contactMembersUrl?: url;
  contactPostOwnersUrl?: url;
  contactCuratorUrl?: url;
}

export interface AssessmentsDataServiceConfig {
  assessments: url;
  submitClinvar: url;
  deleteAssessment: url;
}

export interface ACMGServiceConfig {
  beUrl: url;
}

export interface AcmgSvServiceConfig {
  beUrl: url;
}

export interface OncogenicServiceConfig {
  beUrl: url;
}

export interface FeedServiceConfig {
  getPosts: url;
  postPublication: url;
  shareClassification: url;
  shareRule?: url;
  postNote: url;
  deletePost: url;
  findPublication: url;
  getUsers: url;
}

export interface DiscussionServiceConfig {
  getTopics: url;
  deleteTopic: url;
  getUsers: url;
  getMessages: url;
  deleteMessage: url;
  createTopic: url;
  submitMessage: url;
}

export interface NoteServiceConfig {
  getNotes: url;
  deleteNote: url;
  createNote: url;
  createPublicationNote: url;
}

export interface EvidencesServiceConfig {
  getEvidences?: url;
}

export interface AssessmentToolsServiceConfig {
  getFrequencies?: url;
  getInternalFrequency?: url;
  getCommunityFrequency?: url;
  getInternalOccurrence?: url;
  getSomaticFrequency?: url;
  getSomaticConfidence?: url;
  getPredictions?: url;
  getGeneCoverage?: url;
  getGenePanels?: url;
  getRegionViewer?: url;
  getSvRegionViewer?: url;
  getSvRegionViewerCytobands?: url;
  getSequenceBrowser?: url;
  getFamilyZygosity?: url;
  getFamilyZygositySV?: url;
  getTranscripts?: url;
  getCompoundVariants?: url;
  getCustomAnnotation?: url;
  getOccurrences?: url;
  getSvConfidence?: url;
  getSvConfidenceSamples?: url;
  getRohSummary?: url;
  exportRohSummary?: url;
  getVariantPriority?: url;
  getFamilyVariantPriority?: url;
}

export interface VariantSummaryServiceConfig {
  getVariantSummary?: url;
}

export interface PublicationsServiceConfig {
  getPublications: url;
  addPublication?: url;
}

export interface CurationServiceConfig {
  createEvidenceUrl: url;
}

export interface ClinicalEvidenceServiceConfig {
  getClinicalEvidence: url;
  getSomaticClassification: url;
}

export interface ClinicalTrialsServiceConfig {
  getClinicalTrials: url;
  getLocationsUrl: url;
}

export interface InterpretationServiceConfig {
  getInterpretation: url;
  saveInterpretation: url;
  patchInterpretation?: url;
  regeneration?: url;
  inheritanceModel?: url;
  interpretationHistory?: url;
  inWorkbench?: url;
}

export interface ConditionsServiceConfig {
  getConditions: url;
  getConditionDetails?: url;
}

export interface GenesRegionsServiceConfig {
  getGenesRegions: url;
}

export interface PreloadServiceConfig {
  preloadData: url;
}

export interface SimilarCasesServiceConfig {
  getSimilarCases: url;
}

export interface SimilarVariantsServiceConfig {
  getOrgSimilarVariants: url;
  getOrgSimilarVariantsEthnicities: url;
  getCommSimilarVariants: url;
}

export enum VariantPageLibServiceType {
  VariantDataService = 'variantDataService',
  AssessmentsService = 'assessmentsService',
  AcmgService = 'acmgService',
  AcmgSvService = 'acmgSvService',
  CogenicService = 'cogenicService',
  FeedService = 'feedService',
  DiscussionService = 'discussionService',
  NoteService = 'noteService',
  EvidencesService = 'evidencesService',
  AssessmentToolsService = 'assessmentToolsService',
  VariantSummaryService = 'variantSummaryService',
  PublicationsService = 'publicationsService',
  CurationService = 'curationService',
  ClinicalTrialsService = 'clinicalTrialsService',
  ClinicalEvidenceService = 'clinicalEvidenceService',
  InterpretationService = 'interpretationService',
  ConditionsService = 'conditionsService',
  GenesRegionsService = 'genesRegionsService',
  PreloadService = 'preloadService',
  SimilarCasesService = 'similarCasesService',
  SimilarVariantsService = 'similarVariantsService',
}

export interface VariantPageLibConfig {
  [VariantPageLibServiceType.VariantDataService]?:
    | { [id in VariantIdTypes]?: VariantDataServiceConfig }
    | VariantDataServiceConfig;
  [VariantPageLibServiceType.AssessmentsService]?: { [id in VariantIdTypes]?: AssessmentsDataServiceConfig };
  [VariantPageLibServiceType.AcmgService]?: { [id in VariantIdTypes]?: ACMGServiceConfig };
  [VariantPageLibServiceType.AcmgSvService]?: { [id in VariantIdTypes]?: AcmgSvServiceConfig };
  [VariantPageLibServiceType.FeedService]?: { [id in VariantIdTypes]?: FeedServiceConfig };
  [VariantPageLibServiceType.DiscussionService]?: { [id in VariantIdTypes]?: DiscussionServiceConfig };
  [VariantPageLibServiceType.NoteService]?: { [id in VariantIdTypes]?: NoteServiceConfig };
  [VariantPageLibServiceType.EvidencesService]?: { [id in VariantIdTypes]?: EvidencesServiceConfig };
  [VariantPageLibServiceType.AssessmentToolsService]?: { [id in VariantIdTypes]?: AssessmentToolsServiceConfig };
  [VariantPageLibServiceType.VariantSummaryService]?: { [id in VariantIdTypes]?: VariantSummaryServiceConfig };
  [VariantPageLibServiceType.PublicationsService]?:
    | { [id in VariantIdTypes]?: PublicationsServiceConfig }
    | PublicationsServiceConfig;
  [VariantPageLibServiceType.CurationService]?: { [id in VariantIdTypes]?: CurationServiceConfig };
  [VariantPageLibServiceType.ClinicalTrialsService]?: { [id in VariantIdTypes]?: ClinicalTrialsServiceConfig };
  [VariantPageLibServiceType.ClinicalEvidenceService]?: { [id in VariantIdTypes]?: ClinicalEvidenceServiceConfig };
  [VariantPageLibServiceType.InterpretationService]?: { [id in VariantIdTypes]?: InterpretationServiceConfig };
  [VariantPageLibServiceType.ConditionsService]?:
    | { [id in VariantIdTypes]?: ConditionsServiceConfig }
    | ConditionsServiceConfig;
  [VariantPageLibServiceType.GenesRegionsService]?: { [id in VariantIdTypes]?: GenesRegionsServiceConfig };
  [VariantPageLibServiceType.PreloadService]?: { [id in VariantIdTypes]?: PreloadServiceConfig } | PreloadServiceConfig;
  [VariantPageLibServiceType.SimilarCasesService]?: { [id in VariantIdTypes]?: SimilarCasesServiceConfig };
  [VariantPageLibServiceType.SimilarVariantsService]?: { [id in VariantIdTypes]?: SimilarVariantsServiceConfig };
}

export enum AppType {
  Variant = 'variant',
  Gene = 'gene',
}
