import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  GET_CASE_PANELS,
  GET_PANEL_GENES,
  GET_PANELS,
  GET_PANELS_WITH_ALL_VERSIONS,
  GetCasePanels,
  GetCasePanelsFail,
  GetCasePanelsSuccess,
  GetKbPanelsSuccess,
  GetPanelGenes,
  GetPanelGenesFail,
  GetPanelGenesSuccess,
  GetPanels,
  GetPanelsFail,
  GetPanelsWithAllVersions,
  GetPanelsWithAllVersionsFail,
  GetPanelsWithAllVersionsSuccess,
} from '../actions';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PanelsService } from '../services';
import { Store } from '@ngrx/store';
import { AuthModuleState } from '../../modules/auth0/store';

@Injectable()
export class PanelsEffect {
  constructor(
    private actions: Actions,
    private service: PanelsService,
    private authStore$: Store<AuthModuleState>,
  ) {}

  getPanels$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(GET_PANELS),
      switchMap((action: GetPanels) =>
        this.service.getKbPanels(action.payload, null, action.analysisId, action.assayId).pipe(
          map((data) => new GetKbPanelsSuccess(data.panels, action.payload)),
          catchError((error) => of(new GetPanelsFail(error.message))),
        ),
      ),
    ),
  );

  getPanelGenes$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(GET_PANEL_GENES),
      switchMap((action: GetPanelGenes) =>
        this.service.getKbPanelGenes(action.panelId, action.location).pipe(
          map((data) => new GetPanelGenesSuccess(data.genes, action.locationString + ':' + action.panelId)),
          catchError((error) => of(new GetPanelGenesFail(error.message))),
        ),
      ),
    ),
  );

  getCasePanels$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(GET_CASE_PANELS),
      switchMap((action: GetCasePanels) =>
        this.service.getCasePanels(action.panelIds).pipe(
          map((data) => new GetCasePanelsSuccess(data?.panels)),
          catchError((error) => of(new GetCasePanelsFail(error.message))),
        ),
      ),
    ),
  );

  getAllPanelsWithVersions$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(GET_PANELS_WITH_ALL_VERSIONS),
      switchMap((action: GetPanelsWithAllVersions) =>
        this.service.getAllPanelsWithVersions().pipe(
          map((data) => new GetPanelsWithAllVersionsSuccess(data?.panels)),
          catchError((error) => of(new GetPanelsWithAllVersionsFail(error))),
        ),
      ),
    ),
  );
}
