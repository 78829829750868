export interface Feedback {
  sv_variant?: {
    chrom;
    start_pos;
    end_pos;
    sv_type;
    ci_pos;
    ci_end;
    bnd_orientation;
    bnd_mate;
    reference_genome_type;
  };
  snp_variant?: {
    chrom;
    pos;
    ref;
    alt;
  };
  roh?: {
    chrom;
    start;
    end;
  }; // all of the above are optional but one of snp/sv/roh should be not empty
  case_context?: any; // case data as passed in other apis,
  agree?: boolean; // optional
  feedback?: string; // optional
  link?: string; // optional
  component?: string; // (required - the name of the widget)
}
