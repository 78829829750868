import { Action } from '@ngrx/store';
import { getVariantIdType, VariantId, variantIdToString } from '../../../variant-page/models';
import { Feed } from '../models';
import { AnalyticsAction, AnalyticsEvent, extractEventFromType } from '../../../shared/models';
import { SimilarVariant } from '../../../similar-variants/store/models';
import Publication = Feed.Publication;
import NotePost = Feed.NotePost;
import PublicationPost = Feed.Publication;
import User = Feed.User;
import Post = Feed.Post;
import ClassificationPost = Feed.ClassificationPost;
import Notifications = Feed.Notifications;
import RulePost = Feed.RulePost;
import PostType = Feed.PostType;

export const GET_POSTS = '[Feed] Get posts';
export const GET_POSTS_SUCCESS = '[Feed] Get posts success';
export const GET_POSTS_FAILURE = '[Feed] Get posts failure';
export const POST_NOTE = '[Feed] Post note';
export const POST_NOTE_SUCCESS = '[Feed] Post note success';
export const POST_NOTE_FAILURE = '[Feed] Post note failure';
export const GET_USERS = '[Feed] Get users';
export const GET_USERS_SUCCESS = '[Feed] Get users success';
export const GET_USERS_FAILURE = '[Feed] Get users failure';
export const DELETE_POST = '[Feed] Delete post';
export const DELETE_POST_SUCCESS = '[Feed] Delete post success';
export const DELETE_POST_FAILURE = '[Feed] Delete post failure';
export const POST_PUBLICATION = '[Feed] Post publication';
export const POST_PUBLICATION_SUCCESS = '[Feed] Post publication success';
export const POST_PUBLICATION_FAILURE = '[Feed] Post publication failure';
export const SHARE_CLASSIFICATION = '[Feed] Share classification';
export const SHARE_CLASSIFICATION_SUCCESS = '[Feed] Share classification success';
export const SHARE_CLASSIFICATION_FAILURE = '[Feed] Share classification failure';
export const SHOW_SHARE_RULE = '[Feed] Show share rule';
export const SHOW_VARIANT_DETAILS = '[Feed] Show variant details';
export const SHARE_RULE = '[Feed] Share rule';
export const SHARE_RULE_SUCCESS = '[Feed] Share rule success';
export const SHARE_RULE_FAILURE = '[Feed] Share rule failure';
export const FIND_PUBLICATION = '[Feed] Find publication';
export const FIND_PUBLICATION_SUCCESS = '[Feed] Find publication success';
export const FIND_PUBLICATION_FAILURE = '[Feed] Find publication failure';
export const GET_NOTIFICATIONS = '[Feed] Get Notifications';
export const GET_NOTIFICATIONS_SUCCESS = '[Feed] Get Notifications success';
export const GET_NOTIFICATIONS_FAILURE = '[Feed] Get Notifications failure';

export class GetPosts implements Action {
  readonly type = GET_POSTS;

  constructor(public variantId: VariantId) {}
}

export class GetPostsSuccess implements Action {
  readonly type = GET_POSTS_SUCCESS;

  constructor(
    public variantId: VariantId,
    public posts: Post[],
  ) {}
}

export class GetPostsFailure implements Action {
  readonly type = GET_POSTS_FAILURE;

  constructor(public err: string) {}
}

export class PostNote implements Action {
  readonly type = POST_NOTE;

  constructor(
    public variantId: VariantId,
    public comment: string,
    public mentions: string[],
  ) {}
}

export class PostNoteSuccess implements AnalyticsAction {
  readonly type = POST_NOTE_SUCCESS;
  analyticsEvent: AnalyticsEvent;

  constructor(
    public notePost: NotePost,
    public variantId: VariantId,
  ) {
    const label = `Post note success to ${getVariantIdType(variantId)} ${variantIdToString(variantId, true)}`;

    this.analyticsEvent = extractEventFromType(this.type);
    this.analyticsEvent.eventLabel = label;
  }
}

export class PostNoteFailure implements AnalyticsAction {
  readonly type = POST_NOTE_FAILURE;
  analyticsEvent: AnalyticsEvent;

  constructor(
    public err: string,
    public variantId: VariantId,
  ) {
    const label = `Post note failure to ${getVariantIdType(variantId)} ${variantIdToString(variantId, true)}`;

    this.analyticsEvent = extractEventFromType(this.type);
    this.analyticsEvent.eventLabel = label;
  }
}

export class GetUsers implements Action {
  readonly type = GET_USERS;

  constructor(
    public variantId: VariantId,
    public searchString: string,
  ) {}
}

export class GetUsersSuccess implements Action {
  readonly type = GET_USERS_SUCCESS;

  constructor(
    public variantId: VariantId,
    public users: User[],
  ) {}
}

export class GetUsersFailure implements Action {
  readonly type = GET_USERS_FAILURE;

  constructor(public err: string) {}
}

export class DeletePost implements Action {
  readonly type = DELETE_POST;

  constructor(
    public varId: VariantId,
    public postId: string,
    public postType: PostType,
  ) {}
}

export class DeletePostSuccess implements AnalyticsAction {
  readonly type = DELETE_POST_SUCCESS;
  analyticsEvent: AnalyticsEvent;

  constructor(
    public postId: string,
    public variantId: VariantId,
    public postType: PostType,
  ) {
    const variantString = `${getVariantIdType(variantId)} ${variantIdToString(variantId, true)}`;
    const label = `Delete post success. type: ${postType}, variant: ${variantString}`;

    this.analyticsEvent = extractEventFromType(this.type);
    this.analyticsEvent.eventLabel = label;
  }
}

export class DeletePostFailure implements Action {
  readonly type = DELETE_POST_FAILURE;

  constructor(public err: string) {}
}

export class PostPublication implements Action {
  readonly type = POST_PUBLICATION;

  constructor(
    public variantId: VariantId,
    public pubmedId: string,
    public comment: string,
    public mentions: string[],
  ) {}
}

export class PostPublicationSuccess implements AnalyticsAction {
  readonly type = POST_PUBLICATION_SUCCESS;
  analyticsEvent: AnalyticsEvent;

  constructor(
    public publicationPost: PublicationPost,
    public variantId: VariantId,
  ) {
    const variantString = `${getVariantIdType(variantId)} ${variantIdToString(variantId, true)}`;
    const label = `Post publication success. pubmed id: ${publicationPost.pubmed_id}, variant: ${variantString}`;

    this.analyticsEvent = extractEventFromType(this.type);
    this.analyticsEvent.eventLabel = label;
  }
}

export class PostPublicationFailure implements Action {
  readonly type = POST_PUBLICATION_FAILURE;

  constructor(public err: string) {}
}

export class ShareClassification implements Action {
  readonly type = SHARE_CLASSIFICATION;

  constructor(
    public variantId: VariantId,
    public classification: string,
    public comment: string,
    public mentions: string[],
  ) {}
}

export class ShareClassificationSuccess implements AnalyticsAction {
  readonly type = SHARE_CLASSIFICATION_SUCCESS;
  analyticsEvent: AnalyticsEvent;

  constructor(
    public classificationPost: ClassificationPost,
    public variantId: VariantId,
  ) {
    const variantString = `${getVariantIdType(variantId)} ${variantIdToString(variantId, true)}`;
    const label = `Share classification success. variant: ${variantString}`;

    this.analyticsEvent = extractEventFromType(this.type);
    this.analyticsEvent.eventLabel = label;
  }
}

export class ShareClassificationFailure implements Action {
  readonly type = SHARE_CLASSIFICATION_FAILURE;

  constructor(public err: string) {}
}

export class ShowVariantDetails implements Action {
  readonly type = SHOW_VARIANT_DETAILS;

  constructor(
    public variantId: VariantId,
    public variantDetails?: SimilarVariant,
  ) {}
}

export class ShowShareRule implements Action {
  readonly type = SHOW_SHARE_RULE;

  constructor(
    public rule?: string,
    public newInfluence?: string,
    public oldInfluence?: string,
  ) {}
}

export class ShareRule implements Action {
  readonly type = SHARE_RULE;

  constructor(
    public variantId: VariantId,
    public publicationId?: number,
    public comment?: string,
  ) {}
}

export class ShareRuleSuccess implements AnalyticsAction {
  readonly type = SHARE_RULE_SUCCESS;
  analyticsEvent: AnalyticsEvent;

  constructor(
    public rulePost: RulePost,
    public variantId: VariantId,
  ) {
    const variantString = `${getVariantIdType(variantId)} ${variantIdToString(variantId, true)}`;
    const label = `Share rule success. variant: ${variantString}`;

    this.analyticsEvent = extractEventFromType(this.type);
    this.analyticsEvent.eventLabel = label;
  }
}

export class ShareRuleFailure implements Action {
  readonly type = SHARE_RULE_FAILURE;

  constructor(public err: string) {}
}

export class FindPublication implements Action {
  readonly type = FIND_PUBLICATION;

  constructor(
    public variantId: VariantId,
    public pubmedId: string,
  ) {}
}

export class FindPublicationSuccess implements AnalyticsAction {
  readonly type = FIND_PUBLICATION_SUCCESS;
  analyticsEvent: AnalyticsEvent;

  constructor(
    public variantId: VariantId,
    public publication: Publication,
  ) {
    if (publication) {
      const label = `Find publication success: ${publication.pubmed_id}`;

      this.analyticsEvent = extractEventFromType(this.type);
      this.analyticsEvent.eventLabel = label;
    }
  }
}

export class FindPublicationFailure implements AnalyticsAction {
  readonly type = FIND_PUBLICATION_FAILURE;
  analyticsEvent: AnalyticsEvent;

  constructor(
    public variantId: VariantId,
    public pubmedId: string,
    public err: string,
  ) {
    const label = `Find publication failure. Searched: ${pubmedId}`;

    this.analyticsEvent = extractEventFromType(this.type);
    this.analyticsEvent.eventLabel = label;
  }
}

export class GetNotifications implements Action {
  readonly type = GET_NOTIFICATIONS;

  constructor(public variantId: VariantId) {}
}

export class GetNotificationsSuccess implements Action {
  readonly type = GET_NOTIFICATIONS_SUCCESS;

  constructor(
    public variantId: VariantId,
    public notifications: Notifications,
  ) {}
}

export class GetNotificationsFailure implements Action {
  readonly type = GET_NOTIFICATIONS_FAILURE;

  constructor(
    public variantId: VariantId,
    public err: string,
  ) {}
}

export type FeedAction =
  | GetPosts
  | GetPostsSuccess
  | GetPostsFailure
  | PostNote
  | PostNoteSuccess
  | PostNoteFailure
  | GetUsers
  | GetUsersSuccess
  | GetUsersFailure
  | DeletePost
  | DeletePostSuccess
  | DeletePostFailure
  | PostPublication
  | PostPublicationSuccess
  | PostPublicationFailure
  | ShareClassification
  | ShareClassificationSuccess
  | ShareClassificationFailure
  | ShowVariantDetails
  | ShowShareRule
  | ShareRule
  | ShareRuleSuccess
  | ShareRuleFailure
  | FindPublication
  | FindPublicationSuccess
  | FindPublicationFailure
  | GetNotifications
  | GetNotificationsSuccess
  | GetNotificationsFailure;
