import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as fromAction from './../actions/assays.action';
import {
  DELETE_ASSAY,
  DeleteAssay,
  DeleteAssayFail,
  DeleteAssaySuccess,
  GET_ASSAYS_EXTENDED_LIST,
  GET_ASSAYS_IN_USE,
  GetAssaysExtendedList,
  GetAssaysExtendedListFail,
  GetAssaysExtendedListSuccess,
  GetAssaysInUseFail,
  GetAssaysInUseSuccess,
} from './../actions/assays.action';
import { AssayService } from '../services';
import { ExtendedAssayListEntity } from '../models/assay.model';

@Injectable()
export class AssaysEffect {
  constructor(
    private actions$: Actions,
    private assayService: AssayService,
  ) {}

  getAssayBriefList$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GET_ASSAY_BRIEF_LIST),
      switchMap(() =>
        this.assayService.getAssayBriefList().pipe(
          map((assays) => new fromAction.GetAssayBriefListSuccess(assays)),
          catchError((err) => of(new fromAction.GetAssayBriefListFail(err))),
        ),
      ),
    ),
  );

  getAssayExtendedList$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_ASSAYS_EXTENDED_LIST),
      switchMap((action: GetAssaysExtendedList) => {
        return this.assayService.getAssayExtendedList(action.search, action.ordering);
      }),
      map((assays: ExtendedAssayListEntity[]) => new GetAssaysExtendedListSuccess(assays)),
      catchError((err) => of(new GetAssaysExtendedListFail(err))),
    ),
  );

  deleteAssay$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(DELETE_ASSAY),
      switchMap((action: DeleteAssay) => {
        return this.assayService.deleteAssay(action.assayId).pipe(map(() => action.assayId));
      }),
      map((assayId: string) => new DeleteAssaySuccess(assayId)),
      catchError((err) => of(new DeleteAssayFail(err))),
    ),
  );

  getAssaysInUse$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_ASSAYS_IN_USE),
      switchMap(() =>
        this.assayService.getAssaysInUse().pipe(
          map((results) => new GetAssaysInUseSuccess(results)),
          catchError((error) => of(new GetAssaysInUseFail(error))),
        ),
      ),
    ),
  );
}
