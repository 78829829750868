import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AvatarModule } from 'ngx-avatar-ivy';

import { SettingsPageComponent } from './containers/settings-page/settings-page.component';
import { ProfileDetailsComponent } from './components/profile-details/profile-details.component';
import { OrganizationInfoComponent } from './components/organization-info/organization-info.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { ReactiveFormsModule } from '@angular/forms';
import { InviteMemberPopupComponent } from './components/invite-member-popup/invite-member-popup.component';
import { SharedModule } from '../../shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { SettingsSnackbarEffects } from './store/settings-snackbar.effects';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AvatarModule,
    MatSelectModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatTooltipModule,
    SharedModule,
    EffectsModule.forFeature([SettingsSnackbarEffects]),
    SettingsPageComponent,
    ProfileDetailsComponent,
    OrganizationInfoComponent,
    InviteMemberPopupComponent,
  ],
  exports: [ProfileDetailsComponent, OrganizationInfoComponent],
})
export class SettingsPageModule {}
