import { Action } from '@ngrx/store';
import { ArticleTagsAutocompleteResponse, PublicationsQuery } from '../models';

export const GET_ARTICLE_TAGS_AUTOCOMPLETE = '[Publications] Get article tags autocomplete';
export const GET_ARTICLE_TAGS_AUTOCOMPLETE_SUCCESS = '[Publications] Get article tags autocomplete success';
export const GET_ARTICLE_TAGS_AUTOCOMPLETE_FAIL = '[Publications] Get article tags autocomplete fail';

export class GetArticleTagsAutocomplete implements Action {
  readonly type = GET_ARTICLE_TAGS_AUTOCOMPLETE;

  constructor(
    public query: PublicationsQuery,
    public prefixText: string,
  ) {}
}

export class GetArticleTagsAutocompleteSuccess implements Action {
  readonly type = GET_ARTICLE_TAGS_AUTOCOMPLETE_SUCCESS;

  constructor(public payload: ArticleTagsAutocompleteResponse) {}
}

export class GetArticleTagsAutoCompleteFail implements Action {
  readonly type = GET_ARTICLE_TAGS_AUTOCOMPLETE_FAIL;

  constructor(public error: any) {}
}

export type ArticleTagsAction =
  | GetArticleTagsAutocomplete
  | GetArticleTagsAutocompleteSuccess
  | GetArticleTagsAutoCompleteFail;
