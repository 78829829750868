import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as fromAction from '../actions/assessments.action';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { AssessmentsDataService } from '../../services/assessments-data.service';
import { VariantAssessment, VariantAssessmentsByCondition } from '../../models';
import { forkJoin, Observable, of } from 'rxjs';
import { Action, select, Store } from '@ngrx/store';
import { getSearchConfig } from '../../../variant-page/store/selectors';
import { SearchConfig } from '../../../variant-page/models';
import { VariantPageState } from '../../../variant-page/store/reducers';
import { map } from 'rxjs';
import { AnalysisType } from '../../../../../../store';

@Injectable()
export class AssessmentsEffect {
  constructor(
    private actions$: Actions,
    private dataService: AssessmentsDataService,
    private variantStore$: Store<VariantPageState>,
  ) {}

  deleteAssessment$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.DELETE_VARIANT_ASSESSMENT),
      switchMap((action: fromAction.DeleteVariantAssessment) =>
        this.dataService.deleteAssessment(action.varId, action.organization_id).pipe(
          switchMap(() => [new fromAction.DeleteVariantAssessmentSuccess(action.varId, action.organization_id)]),
          catchError((error) => of(new fromAction.DeleteVariantAssessmentFail(error))),
        ),
      ),
    ),
  );

  getJointVariantAssessments$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_JOINT_VARIANT_ASSESSMENTS),
      switchMap((action: fromAction.LoadJointVariantAssessments) =>
        this.dataService.getJointVariantAssessmentsByCondition(action.variantId).pipe(
          map(
            (assessmentsByCondition: { assessments_groups: VariantAssessmentsByCondition[] }) =>
              new fromAction.LoadJointVariantAssessmentsSuccess(assessmentsByCondition.assessments_groups),
          ),
          catchError((error) => of(new fromAction.LoadVariantAssessmentsFail(error))),
        ),
      ),
    ),
  );
}
