import * as fromReducer from '../reducers/quality-control.reducer';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { getWorkbenchModuleState, WorkbenchModuleState } from '../reducers';
import { SampleQC } from '../models';

const getQualityControlState: MemoizedSelector<WorkbenchModuleState, fromReducer.QualityControlState> = createSelector(
  getWorkbenchModuleState,
  (state: WorkbenchModuleState) => state.qualityControl,
);

export const getQualityControlSample: MemoizedSelector<WorkbenchModuleState, SampleQC> = createSelector(
  getQualityControlState,
  ({ data }) => data?.[0],
);

export const getQualityControl: MemoizedSelector<WorkbenchModuleState, { [id: string]: SampleQC }> = createSelector(
  getQualityControlState,
  ({ data }) =>
    data
      ? data.reduce((pre: { [id: string]: SampleQC }, curr: SampleQC) => {
          return {
            ...pre,
            [curr.sample.id]: curr,
          };
        }, {})
      : {},
);

export const getQualityControlLoaded: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getQualityControlState,
  fromReducer.getQualityControlLoaded,
);

export const getQualityControlLoading: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getQualityControlState,
  fromReducer.getQualityControlLoading,
);

export const getQCExportBatchLoading: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getQualityControlState,
  (state) => state.exportBatchLoading,
);

export const getQCExportBatchReady: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getQualityControlState,
  (state) => state.exportBatchReady,
);

export const getQCExportBatchPath: MemoizedSelector<WorkbenchModuleState, string> = createSelector(
  getQualityControlState,
  (state) => state.exportBatchPath,
);

export const getQCExportBatchError: MemoizedSelector<WorkbenchModuleState, any> = createSelector(
  getQualityControlState,
  (state) => state.exportBatchError,
);

export const getQCSnpProfileExportLoading: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getQualityControlState,
  (state) => state.exportSnpProfileLoading,
);

export const getQCSnpProfileExportReady: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getQualityControlState,
  (state) => state.exportSnpProfileReady,
);

export const getQCSnpProfileExportPath: MemoizedSelector<WorkbenchModuleState, string> = createSelector(
  getQualityControlState,
  (state) => state.exportSnpProfilePath,
);

export const getQCSnpProfileExportError: MemoizedSelector<WorkbenchModuleState, any> = createSelector(
  getQualityControlState,
  (state) => state.exportSnpProfileError,
);
