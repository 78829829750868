import { VariantInterpretationType } from './variant-interpretation.model';

export enum VariantAction {
  addEvidence = 'add_evidence',
  addComment = 'add_comment',
  clinicalSignificance = 'clinical_significance',
  copyHgvs = 'copy_hgvs',
  addToVariantList = 'add_to_variant_list',
  removeFromVariantLists = 'remove_from_variant_lists',
}

export type VariantActionType = VariantAction | VariantInterpretationType;

export const VariantActionValue = {
  ...VariantAction,
  ...VariantInterpretationType,
};
