import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { BriefAssayListEntity, ExtendedAssayListEntity } from '../models/assay.model';

@Injectable()
export class AssayService {
  constructor(private http: HttpClient) {}

  getAssayBriefList(): Observable<BriefAssayListEntity[]> {
    return this.http
      .get<{ assays: BriefAssayListEntity[] }>(`${environment.beBaseURL}/api/assay/search`)
      .pipe(map(({ assays }) => assays));
  }

  getAssayExtendedList(search: string, order: string): Observable<ExtendedAssayListEntity[]> {
    const params = new HttpParams().append('search', search).append('ordering', order);
    return this.http.get<any>(`${environment.beBaseURL}/api/api/assay/full-list`, { params });
  }

  deleteAssay(assayId: string): Observable<any> {
    return this.http.delete(`${environment.beBaseURL}/api/api/assay/${assayId}`);
  }

  getAssaysInUse(): Observable<string[]> {
    return this.http
      .get<{
        results: string[];
      }>(`${environment.beBaseURL}/api/api/assay/used`)
      .pipe(map((res) => res.results));
  }
}
