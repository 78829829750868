import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromAnalysisDetails from '../reducers/analysis-details.reducer';
import { AnalysisDetailsState } from '../reducers/analysis-details.reducer';
import {
  AnalysisDetails,
  AnalysisPhenotypeGroup,
  AnalysisReferral,
  AnalysisSampleDetails,
  Assay,
  FamilyMemberRelation,
} from '../models';
import { AppModuleState, getAppModuleState } from '../reducers';
import { MemoizedSelectorWithProps } from '@ngrx/store/src/selector';

export const getAnalysisDetailsState: MemoizedSelector<AppModuleState, AnalysisDetailsState> = createSelector(
  getAppModuleState,
  (state) => state.analysisDetails,
);

export const getAnalysisDetails: MemoizedSelector<AppModuleState, AnalysisDetails> = createSelector(
  getAnalysisDetailsState,
  fromAnalysisDetails.getAnalysisDetails,
);

export const getAnalysisSamples: MemoizedSelector<
  AppModuleState,
  {
    [id: string]: AnalysisSampleDetails;
  }
> = createSelector(getAnalysisDetailsState, fromAnalysisDetails.getAnalysisSamples);

export const getAnalysisDetailsLoading: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getAnalysisDetailsState,
  fromAnalysisDetails.getAnalysisDetailsLoading,
);

export const getAnalysisDetailsLoaded: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getAnalysisDetailsState,
  fromAnalysisDetails.getAnalysisDetailsLoaded,
);

export const getFamilySamples: MemoizedSelector<AppModuleState, FamilyMemberRelation[]> = createSelector(
  getAnalysisDetails,
  (x) => (x?.family_samples_relation ? x.family_samples_relation : []),
);

export const getAnalysisPhenotypes: MemoizedSelector<AppModuleState, AnalysisPhenotypeGroup[]> = createSelector(
  getAnalysisDetails,
  (analysis) => (analysis ? analysis.phenotypes_grouped : []),
);

export const getFlattenedAnalysisPhenotypes: MemoizedSelector<AppModuleState, string[]> = createSelector(
  getAnalysisDetails,
  (analysis) => (analysis ? analysis.phenotypes : []),
);

export const getAnalysisType: MemoizedSelector<AppModuleState, any> = createSelector(getAnalysisDetails, (analysis) =>
  analysis ? analysis.analysis_type : '',
);

export const getAnalysisStatusUpdating: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getAnalysisDetailsState,
  fromAnalysisDetails.getAnalysisStatusUpdating,
);

export const getAnalysisFollowUpdating: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getAnalysisDetailsState,
  fromAnalysisDetails.getAnalysisFollowUpdating,
);

export const getAnalysisReferral: MemoizedSelector<AppModuleState, AnalysisReferral> = createSelector(
  getAnalysisDetails,
  (analysis) => (analysis ? analysis.referral : undefined),
);

export const getAnalysisMainSample: MemoizedSelector<AppModuleState, AnalysisSampleDetails> = createSelector(
  getAnalysisDetails,
  (analysis: AnalysisDetails) => (analysis ? analysis.main_sample : undefined),
);

export const getAnalysisHasCoverage: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getAnalysisDetails,
  (analysis: AnalysisDetails) => (analysis ? analysis.main_sample.has_coverage : false),
);

export const getAnalysisAssay: MemoizedSelector<AppModuleState, Assay> = createSelector(
  getAnalysisDetails,
  (analysis) => (analysis ? analysis.assay : undefined),
);

export const getAnalysisSampleCoverageProgress: MemoizedSelectorWithProps<
  AppModuleState,
  {
    id: string;
  },
  number
> = createSelector(getAnalysisSamples, (samples, { id }) =>
  samples ? samples[id]?.process_status.coverage_pe : undefined,
);
