import { MenuOption } from '../../../shared-lazy/components/sub-menu/sub-menu.component';

export const ANALYSIS_SUBMENU_DEFAULT_ACTIVE = 'workbench';
export const ANALYSIS_TOOL_WITH_HEADER = [
  'details',
  'workbench',
  'variants',
  'coverage',
  'viewer',
  'report-v2',
  'history',
];
export const ANALYSIS_TOOL_WITH_SUBMENU = [
  'analyses',
  'germline-dashboard',
  'somatic-dashboard',
  'details',
  'workbench',
  'variants',
  'coverage',
  'viewer',
  'report-v2',
  'history',
];
export const ANALYSIS_TOOL_WITH_WHITE_BACKGROUND = ['workbench', 'variants', 'coverage', 'panel', 'history'];
export const ANALYSIS_MENU_OPTIONS: MenuOption[] = [
  {
    title: 'Case Details',
    value: 'details',
    disabled: false,
  },
  {
    title: 'Workbench',
    value: 'workbench',
    disabled: false,
  },
  {
    title: 'Variants',
    value: 'variants',
    disabled: false,
  },
  {
    title: 'Coverage Report',
    value: 'coverage',
    disabled: false,
  },
  {
    title: 'Build Report',
    value: 'report',
    disabled: false,
  },
  {
    title: 'Report Preview',
    isBeta: false,
    value: 'report-v2',
    disabled: false,
  },
  {
    title: 'View Report',
    value: 'viewer',
    disabled: false,
    disabledTooltip: 'No report was generated',
  },
  {
    title: 'Case History',
    value: 'history',
    disabled: false,
  },
];

export const ANALYSIS_LIST_MENU_OPTIONS: MenuOption[] = [
  {
    title: 'All Cases',
    value: 'analyses',
    disabled: false,
  },
  {
    title: 'Germline Dashboard',
    value: 'germline-dashboard',
    disabled: false,
  },
  {
    title: 'Somatic Dashboard',
    value: 'somatic-dashboard',
    disabled: false,
  },
];
