<div class="container">
  <a class="logo-container" routerLink="/" gnxRtl>
    <img src="/assets/svg/franklin-dark-bg.svg" />
  </a>
  <gnx-search-bar *ngIf="showVariantSearchBar()"></gnx-search-bar>
  <gnx-analysis-search-bar *ngIf="showAnalysisSearchBar()"></gnx-analysis-search-bar>
  <div class="apps-nav-container" gnxRtl>
    <a
      class="app-link"
      *ngFor="let app of MINI_APPS"
      [routerLink]="app.path"
      [class.active]="app.app === activeMiniApp"
    >
      <img [src]="app.icon" gnxRtl /><span>{{ app.label | i18n }}</span>
    </a>
  </div>
  <div *ngIf="showVariantSearchBar() || showAnalysisSearchBar()" class="spacer"></div>
  <!--  <ng-content></ng-content>-->
  <div class="right-side-container" gnxRtl>
    <div class="more" [matMenuTriggerFor]="menu" *ngIf="!(isProperlyLoggedIn$ | async)" (click)="learnMoreClicked()">
      <div class="red-dot" [ngClass]="{ hidden: learnMoreDiscovered }"></div>
      Learn More
      <div class="triangle"></div>
    </div>
    <gnx-language-selector *ngIf="languageSelectorEnabled"></gnx-language-selector>
    <img
      src="assets/svg/support.svg"
      width="24"
      height="24"
      [matMenuTriggerFor]="menu"
      *ngIf="isProperlyLoggedIn$ | async"
    />
    <mat-menu #menu="matMenu" class="drop-down-menu top-nav-menu">
      <button mat-menu-item routerLink="/about">About Franklin</button>
      <button mat-menu-item (click)="goToHelpPage()">Help Center</button>
      <button
        *ngIf="(userDetails$ | async) && (featureBits$ | async)[FeatureBit.Assets]"
        mat-menu-item
        (click)="openZendeskPopup()"
      >
        Contact Support
      </button>
      <button mat-menu-item routerLink="/citations">Publications</button>
    </mat-menu>
    <div class="login-container" *ngIf="(userDetails$ | async) && (isProperlyLoggedIn$ | async)">
      <div>
        <div
          class="user-name"
          gnxTextOverflowEllipsis
          #userNameOverflow="gnxTextOverflowEllipsis"
          [matTooltip]="userNameOverflow.isOverflowed ? ((userDetails$ | async).name | titlecase) : ''"
          matTooltipPosition="below"
        >
          {{ (userDetails$ | async).name | titlecase }}
        </div>
        <div class="community-name-container" [matMenuTriggerFor]="userMenu">
          <div
            class="community-name"
            gnxTextOverflowEllipsis
            #communityNameOverflow="gnxTextOverflowEllipsis"
            [matTooltip]="communityNameOverflow.isOverflowed ? (userDetails$ | async).organization?.name : ''"
            matTooltipPosition="below"
          >
            {{ (userDetails$ | async).organization?.name }}
          </div>
          <div class="triangle"></div>
        </div>
        <mat-menu #userMenu="matMenu" class="top-nav-menu">
          <button
            mat-menu-item
            *ngIf="(userDetails$ | async).communities.length > 0"
            [matMenuTriggerFor]="communitiesMenu"
          >
            My Communities
          </button>
          <button
            mat-menu-item
            *ngIf="isAdmin"
            [routerLink]="['/post-join']"
            [queryParams]="{ step: 2, manual_invite: true }"
          >
            Invite Teammates
          </button>
          <button mat-menu-item [routerLink]="['/clinical-db', 'assets']" *ngIf="showAssets$ | async">Assets</button>
          <button
            mat-menu-item
            *ngIf="(featureBits$ | async)[FeatureBit.Discovery]"
            [routerLink]="['/clinical-db', 'discovery']"
          >
            Discovery
          </button>
          <button
            mat-menu-item
            *ngIf="(userDetails$ | async).user_sigs.length > 0"
            [routerLink]="['/clinical-db', 'sig', (userDetails$ | async).user_sigs[0]]"
          >
            SIG
          </button>
          <button mat-menu-item [routerLink]="['/settings']">Settings</button>
          <button
            mat-menu-item
            [routerLink]="['/credit-report']"
            *ngIf="(featureBits$ | async)[FeatureBit.CreditReport]"
          >
            Credit report
          </button>
          <button mat-menu-item (click)="logout()">Log out</button>
        </mat-menu>
        <mat-menu #communitiesMenu="matMenu" class="top-nav-menu">
          <button mat-menu-item *ngFor="let community of (userDetails$ | async).communities">
            {{ community.name }}
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="sign-up-container" *ngIf="!(userDetails$ | async) || !(isProperlyLoggedIn$ | async)">
      <ng-container *ngIf="!(userDetails$ | async)">
        <div class="icon-button" routerLink="/login" (click)="setRedirectAfterAuth()">Log In</div>
        <button routerLink="/join" (click)="setRedirectAfterAuth()" class="border-button">Sign Up</button>
      </ng-container>
      <ng-container *ngIf="!!(userDetails$ | async) && !(isProperlyLoggedIn$ | async)">
        <div class="icon-button" (click)="logout()">Log Out</div>
        <button routerLink="/post-join" [queryParams]="{ step: 1, midway: true }" class="border-button">
          Finish Sign Up
        </button>
      </ng-container>
    </div>
  </div>
</div>
