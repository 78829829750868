import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EditVariantService } from '../services';

import * as fromAction from './../actions/edit-variant.action';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class EditVariantEffect {
  constructor(
    private actions$: Actions,
    private service: EditVariantService,
    private dialog: MatDialog,
  ) {}

  editVariantSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.editVariantSubmit),
      switchMap((action) =>
        this.service.editVariantSubmit(action.analysisId, action.varId, action.startPos, action.endPos).pipe(
          map((result) => fromAction.editVariantSubmitSuccess()),
          catchError((error) => of(fromAction.editVariantSubmitFail())),
        ),
      ),
    ),
  );

  editVariantSubmitSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAction.editVariantSubmitSuccess),
        tap(() => this.dialog.closeAll()),
      ),
    { dispatch: false },
  );
}
