import { SomaticEvidence } from '../../../analysis-variant/models';
import { TmbByVafResponse } from '../../components/tmb-by-vaf/tmb-by-vaf.model';

export type SomaticBioMarkerType = 'TMB' | 'MSI' | 'MET_EX14' | 'HRD' | 'MMR';

export interface SomaticBiomarkerResponse {
  biomarker_type: SomaticBioMarkerType;
  classification: number;
  criteria_score: number;
  criteria_level: string;
  therapeutic: SomaticEvidence[][];
  prognostic: SomaticEvidence[][];
  diagnostic: SomaticEvidence[][];
  confidence?: { name: string; value: string }[];
  score_by_vaf?: TmbByVafResponse;
  extra_data?: {
    tmb?: {
      score_by_vaf?: TmbByVafResponse;
    };
  };
}

export interface AllSomaticBiomarkerResponse {
  biomarkers: SomaticBiomarkerResponse[];
}

export interface SomaticBiomarkerUserData {
  biomarker_type: SomaticBioMarkerType;
  excluded_from_report?: boolean;
  somatic_classification?: string;
}
