import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import * as fromAction from './../actions';
import { LazyLoaderService } from '../services/lazy-loader.service';
import { AppModuleState } from '../reducers';
import { Store } from '@ngrx/store';

@Injectable()
export class LazyLoaderEffect {
  constructor(
    private actions$: Actions,
    private lazyLoaderService: LazyLoaderService,
    private store$: Store<AppModuleState>,
  ) {}

  lazyLoadModule$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAction.LAZY_LOAD_MODULE),
        switchMap((action: fromAction.LazyLoadModule) =>
          this.lazyLoaderService.loadModule(action.module).then(() => this.store$.dispatch(action.onLoadAction)),
        ),
      ),
    { dispatch: false },
  );
}
