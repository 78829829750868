import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: 'input[gnxInput],textarea[gnxInput]',
  standalone: true,
})
export class InputDirective {
  @HostBinding('class.gnx-input') enabled = true;

  constructor() {}
}
