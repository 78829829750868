import { Action } from '@ngrx/store';
import { VariantPopupSelection } from '../../modules/analysis/modules/analysis-variant/models';

export const LOAD_VARIANT_POPUP = '[Variants] Load variant popup';

export class LoadVariantPopup implements Action {
  readonly type = LOAD_VARIANT_POPUP;

  constructor(
    public payload: VariantPopupSelection[],
    public selectedIndex: number = 0,
  ) {}
}
