import { VariantInterpretation } from '../../models';
import { isEqual as _isEqual, isEmpty as _isEmpty, isNumber as _isNumber } from 'lodash';

const COMPARE_FIELDS = [
  'flag',
  'interpretation_sub_level',
  'conditions_associated',
  'inheritance_model',
  'text',
  'custom_data',
  'compound_flag',
];

export function isVariantInterpretationEqual(data: VariantInterpretation, initialData: VariantInterpretation) {
  if (!data.somatic_interpretation && !data.germline_interpretation) {
    return compareSingleInterpretation(data, initialData);
  } else {
    return !(
      (data.somatic_interpretation &&
        !compareSingleInterpretation(data.somatic_interpretation, initialData.somatic_interpretation)) ||
      (data.germline_interpretation &&
        !compareSingleInterpretation(data.germline_interpretation, initialData.germline_interpretation))
    );
  }
}

function compareSingleInterpretation(data: VariantInterpretation, initialData: VariantInterpretation) {
  const current = Object.keys(data)
    .filter((key) => COMPARE_FIELDS.includes(key) && (!_isNumber(data[key]) ? !_isEmpty(data[key]) : true))
    .reduce((cur, k) => Object.assign(cur, { [k]: data[k] }), {});
  const initial = Object.keys(initialData)
    .filter(
      (key) => COMPARE_FIELDS.includes(key) && (!_isNumber(initialData[key]) ? !_isEmpty(initialData[key]) : true),
    )
    .reduce((cur, k, i, arr) => Object.assign(cur, { [k]: initialData[k] }), {});
  return _isEqual(current, initial);
}
