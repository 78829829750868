import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import { Observable } from 'rxjs';

import * as fromActions from '../actions/open-filter-dialog.actions';
import { select, Store } from '@ngrx/store';
import { FiltersState } from '../reducers';
import { LoadFilterDialogComponent } from '../../components/load-filter-dialog/load-filter-dialog.component';
import { SaveFilterDialogComponent } from '../../components/save-filter-dialog/save-filter-dialog.component';
import { AnalysisType, AppModuleState, getAnalysisType, getParams, getVariantType } from '../../../../../../store';
import { VariantType } from '../../../analysis-variant/models';
import { LoadFilterDialogData } from '../models/dialog-data';
import { Params } from '@angular/router';

@Injectable()
export class OpenFilterDialogEffect {
  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
    private store$: Store<FiltersState>,
    private rootStore$: Store<AppModuleState>,
  ) {}

  openLoadFilterDialog$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.OPEN_LOAD_FILTER_DIALOG),
        withLatestFrom(this.rootStore$.pipe(select(getVariantType))),
        tap(([action, variantType]: [fromActions.OpenLoadFilterDialog, VariantType]) =>
          this.dialog.open<LoadFilterDialogComponent, LoadFilterDialogData>(LoadFilterDialogComponent, {
            width: '780px',
            height: '692px',
            position: { top: '30px' },
            autoFocus: false,
            panelClass: ['paddingless-dialog', 'grey-bg-modal'],
            data: {
              analysis: action.analysisDetails,
              variantType,
              inMergeMode: action.inMergeMode,
            },
          }),
        ),
      ),
    { dispatch: false },
  );

  openSaveFilterDialog$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.OPEN_SAVE_FILTER_DIALOG),
        withLatestFrom(this.rootStore$.pipe(select(getParams)), this.rootStore$.pipe(select(getAnalysisType))),
        tap(
          ([action, { variantType, analysisId }, analysisType]: [
            fromActions.OpenSaveFilterDialog,
            Params,
            AnalysisType,
          ]) =>
            this.dialog.open(SaveFilterDialogComponent, {
              width: '780px',
              height: '502px',
              position: { top: '30px' },
              autoFocus: false,
              panelClass: ['paddingless-dialog', 'grey-bg-modal'],
              data: {
                analysisId,
                analysisType,
                variantType: variantType || VariantType.All,
                newFilterType: action.newFilterType,
                newFilterData: action.newFilterData,
              },
            }),
        ),
      ),
    { dispatch: false },
  );
}
