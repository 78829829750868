import { ActionReducerMap, createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromCaseSummary from './case-summary.reducer';
import * as fromConfig from './config.reducer';
import * as fromAnalysisResult from './analysis-results.reducer';
import * as fromPatientInfo from './case-data.reducer';
import * as fromQC from './quality-control.reducer';
import * as fromReferralNotes from './referral-notes.reducer';
import * as fromCancerType from './cancer_type.reducer';
import * as fromDownloadAnalysisFiles from './download-analysis-files.reducer';
import * as fromExportResults from './export-results.reducer';
import * as fromSomaticBiomarkers from './somatic-biomarkers.reducer';
import * as fromCaseFilter from './case-filter.reducer';
import * as fromEcsAnalysisResults from './ecs-analysis-results.reducer';
import * as fromAdditionalInformation from './analysis-additional-information.reducer';
import * as fromVariantInterpretation from './variant-interpretation.reducer';
import * as fromCaseProtocols from './case-protocols.reducer';
import * as fromVariantList from './variant-list.reducer';

export interface WorkbenchModuleState {
  caseSummary: fromCaseSummary.CaseSummaryState;
  config: fromConfig.WorkbenchConfigState;
  analysisResult: fromAnalysisResult.AnalysisResultState;
  patientInfo: fromPatientInfo.PatientInfoState;
  qualityControl: fromQC.QualityControlState;
  referralNotes: fromReferralNotes.ReferralNotesState;
  cancerType: fromCancerType.CancerTypeState;
  downloadAnalysisFiles: fromDownloadAnalysisFiles.DownloadAnalysisFilesState;
  exportResults: fromExportResults.ExportResultsState;
  somaticBiomarkers: fromSomaticBiomarkers.SomaticBiomarkersState;
  caseFilter: fromCaseFilter.CaseFilterState;
  ecsAnalysisResults: fromEcsAnalysisResults.ECSAnalysisResultsState;
  additionalInformation: fromAdditionalInformation.AnalysisAdditionalInformationState;
  variantInterpretation: fromVariantInterpretation.VariantInterpretationState;
  protocols: fromCaseProtocols.CaseProtocolsState;
  variantLists: fromVariantList.VariantListsState;
}

export const workbenchReducers: ActionReducerMap<WorkbenchModuleState> = {
  caseSummary: fromCaseSummary.reducer,
  config: fromConfig.reducer,
  analysisResult: fromAnalysisResult.reducer,
  patientInfo: fromPatientInfo.reducer,
  qualityControl: fromQC.reducer,
  referralNotes: fromReferralNotes.reducer,
  cancerType: fromCancerType.reducer,
  downloadAnalysisFiles: fromDownloadAnalysisFiles.reducer,
  exportResults: fromExportResults.reducer,
  somaticBiomarkers: fromSomaticBiomarkers.reducer,
  caseFilter: fromCaseFilter.reducer,
  ecsAnalysisResults: fromEcsAnalysisResults.reducer,
  additionalInformation: fromAdditionalInformation.reducer,
  variantInterpretation: fromVariantInterpretation.reducer,
  protocols: fromCaseProtocols.reducer,
  variantLists: fromVariantList.reducer,
};

export const getWorkbenchModuleState: MemoizedSelector<any, WorkbenchModuleState> = createSelector(
  (state) => state.workbench,
  (value) => value,
);
