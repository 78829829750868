import { createSelector, MemoizedSelector, MemoizedSelectorWithProps } from '@ngrx/store';
import { SigGenesState } from '../reducers/sig-genes.reducer';
import { getSigState, SigState } from '../reducers';
import { SIG_TAB_PAGE_SIZE, SigSuggestedGene, SigTabCounts } from '../../models';
import { GeneCurations } from '../../../variant-page/modules/gene-curation/models';

const getSigGenesState: MemoizedSelector<SigState, SigGenesState> = createSelector(getSigState, (state) => state.genes);

export const getSigGenesCategoryCurrentPage: MemoizedSelectorWithProps<SigState, { category: string }, number> =
  createSelector(getSigGenesState, (state, { category }) => state.currentPage?.[category]);

export const getSigGenesCategoryCount: MemoizedSelectorWithProps<
  SigState,
  {
    category: string;
  },
  SigTabCounts
> = createSelector(getSigGenesState, (state, { category }) => state.categoriesCount?.[category]);

export const getSigGenesCategoryMaxPageNo: MemoizedSelectorWithProps<SigState, { category: string }, number> =
  createSelector(getSigGenesCategoryCount, (count) => (count ? Math.ceil(count.num_genes / SIG_TAB_PAGE_SIZE) : 100));

export const getSigGenesCategoryFilters: MemoizedSelectorWithProps<SigState, { category: string }, any> =
  createSelector(getSigGenesState, (state, { category }) => state.categoriesFilters[category]);

export const getSigCuratedGenes: MemoizedSelector<SigState, GeneCurations[]> = createSelector(
  getSigGenesState,
  (state) => state.curatedGenes,
);

export const getSigCuratedGenesLoading: MemoizedSelector<SigState, boolean> = createSelector(
  getSigGenesState,
  (state) => state.loadingCurated,
);

export const getSigCuratedGenesLoaded: MemoizedSelector<SigState, boolean> = createSelector(
  getSigGenesState,
  (state) => state.loadedCurated,
);

export const getSigSuggestedGenes: MemoizedSelector<SigState, SigSuggestedGene[]> = createSelector(
  getSigGenesState,
  (state) => state.suggestedGenes,
);

export const getSigSuggestedGenesLoading: MemoizedSelector<SigState, boolean> = createSelector(
  getSigGenesState,
  (state) => state.loadingSuggested,
);

export const getSigSuggestedGenesLoaded: MemoizedSelector<SigState, boolean> = createSelector(
  getSigGenesState,
  (state) => state.loadedSuggested,
);

export const getMarkIrrelevantLoading: MemoizedSelector<SigState, { [gene: string]: boolean }> = createSelector(
  getSigGenesState,
  (state) => state.markIrrelevantLoading,
);
