import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromAction from '../actions';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PhenotypesService } from '../services';

@Injectable()
export class PhenotypesEffect {
  constructor(
    private actions: Actions,
    private service: PhenotypesService,
  ) {}

  getPhenotypes$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(fromAction.GET_PHENOTYPES),
      switchMap((action: fromAction.GetPhenotypes) =>
        this.service.getPhenotypes(action.payload, action.queryType).pipe(
          map((data) => new fromAction.GetPhenotypesSuccess(data, action.payload)),
          catchError((error) => of(new fromAction.GetPhenotypesFail(error.message))),
        ),
      ),
    ),
  );

  getGenePhenotypes$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(fromAction.GET_GENE_PHENOTYPES),
      switchMap((action: fromAction.GetGenePhenotypes) =>
        this.service.getGenePhenotypes(action.searchTerm, action.gene, action.queryType).pipe(
          map((data) => new fromAction.GetGenePhenotypesSuccess(data)),
          catchError((error) => of(new fromAction.GetGenePhenotypesFail(error.message))),
        ),
      ),
    ),
  );
}
