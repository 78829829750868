import { SnpVariant, SvVariant } from '../../../../../store/models';
import { Linkout } from '../../../../../store/models/variant-linkouts.model';

export type VariantDetails = SnpVariantDetails | SvVariantDetails | RohVariantDetails;

export interface SnpVariantDetails {
  gene: string;
  c_dot: string;
  p_dot: string;
  g_dot?: string;
  genome_nexus_gdot?: string;
  ref: string;
  alt: string;
  chr: string;
  position: number;
  zygosity: string;
  rs: string;
  region: string;
  effect: string;
  exon: number;
  base: number;
  agg_frequency: number;
  int_frequency: number;
  confidence: string;
  agg_prediction: string;
  is_followed: boolean;
  var_id: string;
  transcript: string;
  reference_version: string;
  refseq_accession: string;
  linkouts?: Linkout[];
}

export interface RangeId {
  chrom: string;
  start: number;
  end: number;
  reference: string;
  range_str: string;
}

export interface SvVariantDetails {
  sv_type: string;
  range_id: RangeId;
  genes: string[];
  reference: string;
  severest_impact: string;
  severest_region: string;
  dgv_ids: string[];
  cytobands_description: string;
  linkouts?: Linkout[];
  is_large_sv: boolean;
}

export interface RohVariantDetails {
  regions: RangeId[];
  genes: string[];
  num_of_bases: number;
}

export enum PanelLocation {
  Public = 1,
  Private,
  Assay,
}

export enum AffectionStatus {
  UNKNOWN = 'UNKNOWN',
  UNAFFECTED = 'UNAFFECTED',
  AFFECTED = 'AFFECTED',
}

export interface CasePanel {
  id: string;
  location?: PanelLocation;
  name?: string;
  uuid?: string;
}

export interface CaseFamilyMember {
  sex?: 'UNKNOWN' | 'MALE' | 'FEMALE';
  relation?: string;
  name?: string;
  ethnicity?: string[];
  // is_affected?: boolean;
  affection_status?: AffectionStatus;
  include_secondary_findings?: boolean;
  phenotypes?: string[];
  single_analysis_id?: number;
  subject_id?: number;
  sample_or_analysis_identifier?: string;
  samples: { sample_s3_id: string; batch_id?: number }[];
}

export interface SearchConfig {
  birth_date?: number;
  description?: string;
  sex?: string;
  phenotypes?: string[];
  phenotypes_grouped?: { name: string; phenotypes: string[] }[];
  assay_hard_panels?: CasePanel[];
  kb_panels?: CasePanel[];
  kb_hard_panels?: CasePanel[];
  kb_panel_ids?: string[];
  inactive_kb_panels?: string[];
  inactive_kb_panel_ids?: string[];
  ethnicity?: string[];
  zygosity?: string;
  cancer_type?: string;
  tumor_purity?: number;
  family_inheritance_status?: string;
  reported_classification?: string;
  consanguinity?: string;
  search_term?: string;
  family_members?: CaseFamilyMember[];
  include_secondary_findings?: boolean;
  disease?: string;
  custom_data?: { [key: string]: any };
  patient_clinical_info_data?: { [key: string]: any };
  custom_case_resolution_data?: { [key: string]: any };
  comments?: string;
}

export interface SearchData {
  search_data: {
    search_session_id: string;
    search_input_text: string;
    case_context?: SearchConfig;
    sv_variant?: SvVariant;
    snp_variant?: SnpVariant;
  };
}

export interface VariantCaseRequest {
  case_name: string;
  case_context?: SearchConfig;
  sv_variants?: {
    variant: SvVariant;
    info?: {
      zygosity?: string;
      reported_classification?: string;
      family_inheritance_status?: string;
    };
  }[];
  snp_variants?: {
    variant: SnpVariant;
    info?: {
      zygosity?: string;
      reported_classification?: string;
      family_inheritance_status?: string;
    };
  }[];
}

export interface VariantCaseResponse {
  id: number;
  name?: string;
}

export function getVariantDetailsGenes(variantDetails: VariantDetails): string[] {
  if (!variantDetails) {
    return [];
  }
  return 'sv_type' in variantDetails
    ? variantDetails.genes
    : 'regions' in variantDetails
      ? variantDetails.genes
      : [variantDetails.gene];
}

export function getVariantDetailsTitle(variantDetails: VariantDetails): string {
  if (!variantDetails) {
    return '';
  }
  return 'sv_type' in variantDetails
    ? `${variantDetails.sv_type.toUpperCase()}:${
        variantDetails.genes.length >= 4
          ? variantDetails.genes[0] + ',' + variantDetails.genes[1] + '...'
          : variantDetails.genes.join(',')
      }`
    : 'regions' in variantDetails
      ? `${
          variantDetails.regions[0].chrom
        }:${variantDetails.regions[0].start.toLocaleString()}-${variantDetails.regions[0].end.toLocaleString()}${
          variantDetails.regions.length > 1 ? ', ...' : ''
        }`
      : `${variantDetails.gene.toUpperCase()}:${variantDetails.c_dot}`;
}
