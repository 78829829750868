const referencesRegExp: RegExp = /<ol type="references">.*?<\/ol>/g;

export const addPublication = (
  date: string | number,
  id: string | number,
  url: string,
  name: string,
  title: string,
  value: string,
): string => {
  if (!value) {
    value = '';
  }
  const year: number = new Date(date).getUTCFullYear();
  const link = `<a href="${url}" target="_blank">PMID: ${id}</a>`;
  const text = `<li>${name} | ${year} | ${link}<br>${title}</li>`;
  const valueParts: string[] = value.split(referencesRegExp);
  let newValue: string;
  if (valueParts.length > 1) {
    newValue = value.match(referencesRegExp)[0];
    const newValueParts: string[] = newValue.split('</ol>');
    newValueParts.splice(1, 0, text, '</ol>');
    newValue = valueParts[0] + newValueParts.join('') + valueParts[1];
  } else {
    newValue = `${value}<br><ol type="references">${text}</ol>`;
  }
  return newValue;
};

export const removePublication = (id: string, value: string): string => {
  let newValue: string = value;
  const idRegExp: RegExp = new RegExp(`\\b${id}\\b`, 'g');
  const references: string[] = newValue.match(/<li>.*?<\/li>/g);
  const matchedRefs: string[] = references?.filter((reference) => idRegExp.test(reference));
  matchedRefs?.forEach((reference) => (newValue = newValue.replace(reference, '')));
  return newValue;
};

export const extractPublications = (references: string): string[] => {
  return references?.match(/PMID: (\d+)/g)?.map((value: string) => value.slice(6));
};
