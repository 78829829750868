import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AppModuleState, getAppModuleState } from '../reducers';
import * as fromReducer from '../reducers/variants-count.reducer';

export const getVariantsCountState: MemoizedSelector<AppModuleState, any> = createSelector(
  getAppModuleState,
  (state: AppModuleState) => state.variantsCount,
);

export const getUserVariantsCount: MemoizedSelector<any, number> = createSelector(
  getVariantsCountState,
  fromReducer.getUserVariantsCount,
);
