import { VariantHighlight } from './variant-highlights.model';
import { VariantNormalFrequency } from '../../shared/models/frequencies';
import {
  ClingenDosageSensitivity,
  DatabaseOccurrenceData,
  ExtraVCFAnnotation,
  SvGenomicData,
  VariantPosition,
} from './sv-result.model';
import { VariantWarnings } from './variant-warnings.model';
import { InterpretationTag, LatestComment } from './snp-result.model';
import { BaseVariant } from './analysis-result.model';

export interface DataPair {
  first: number;
  second: number;
}

export enum RnaMarkerType {
  UNKNOWN = 'UNKNOWN',
  CNV = 'CNV',
  ALTERNATIVE_SPLICING = 'ALTERNATIVE_SPLICING',
  EXPRESSION_IMBALANCE = 'EXPRESSION_IMBALANCE',
  DELETION = 'DELETION',
  DUPLICATION = 'DUPLICATION',
}

export const getRnaTypeDescription = (rnaType: RnaMarkerType): string => {
  return rnaType === RnaMarkerType.ALTERNATIVE_SPLICING
    ? 'Alt. splicing'
    : rnaType === RnaMarkerType.EXPRESSION_IMBALANCE
      ? 'Expression imbalance'
      : rnaType === RnaMarkerType.DELETION
        ? 'Deletion'
        : rnaType === RnaMarkerType.DUPLICATION
          ? 'Duplication'
          : rnaType;
};

export interface SomaticSvResult extends BaseVariant {
  annotation_data: {
    cytobands_display_description: string;
    genes_symbols: string[];
    genomic_data: {
      refseq: SvGenomicData;
      ensembl: SvGenomicData;
    };
    classification: {
      score: number;
      classification: string;
    };
    occurrence_data: {
      databases_occurrence_data: DatabaseOccurrenceData[];
      aggregated_occurrence_data: {
        frequency: number;
        occurrences: number;
        populations: string[];
      };
    };
    evidences: {
      clingen_dosage_sensitivity: {
        gene: ClingenDosageSensitivity;
        region: ClingenDosageSensitivity;
      };
      decipher_classified_data: {
        decipherLink: string;
        position: {
          positionStr: string;
        };
        classification: string;
      };
      clinvar: {
        total: number;
        classifications: {
          classification: string;
          count: number;
        }[];
        variant_link: string;
      };
      decipher: {
        total: number;
        classifications: {
          classification: string;
          count: number;
        }[];
      };
      does_evidence_exist: boolean;
    };
    exons: number[];
    extra_vcf_annotations: ExtraVCFAnnotation[];
    num_exons_in_gene: number;
  };
  evidences: Record<'diagnostic' | 'prognostic' | 'therapeutic', { level: string }[][]>;
  genes_symbols: string[];
  hgvs_p: string;
  exon: number;
  exons: number[];
  hgvs_c: string;
  chr: string;
  rna_type?: RnaMarkerType;
  start_pos: number;
  end_pos: number;
  therapeutic_count: number;
  diagnostic_count: number;
  prognostic_count: number;
  vaf: number;
  depth: number;
  confidence: number;
  quality: number;
  confidence_text: string;
  internal_occurrence: number;
  internal_percent: number;
  var_id: string;
  classification: number;
  region: string;
  region_value: number;
  classification_flag?: number;
  sub_level_classification?: string;
  draft_sub_classification?: string;
  draft_classification_flag?: number;
  latest_sub_classification_for_disease?: string;
  latest_flag_for_disease?: number;
  other_classification_flags?: number[];
  germline_classification: number;
  draft_germline_classification_flag?: number;
  mark_as_clinically_irrelevant: boolean;
  include_in_report: boolean;
  include_in_workbench: boolean;
  ref: string;
  obs: string;
  highlights: VariantHighlight[];
  warnings: VariantWarnings[];
  variation_type: {
    readable_name: string;
    readable_name_short: string;
    name: string;
  };
  cipos: DataPair;
  ciend: DataPair;
  breakend_mates: {
    var_id: string;
    variation_type: string;
    cipos: DataPair;
    position: VariantPosition;
    genes: string[];
  }[];
  copy_number?: number;
  raw_copy_number?: number;
  fusion_data?: {
    variant?: {
      mate_chromosome?: string;
      mate_cipos?: DataPair;
      mate_start_pos?: number;
      supporting_reads?: number;
    };
    annotation?: {
      mate_genomic_data?: {
        refseq: SvGenomicData;
        ensembl: SvGenomicData;
      };
    };
  };
  cosmic_ids: {
    cosmic_id: string;
    cosmic_url: string;
  }[];
  cosmic_frequencies: {
    af: number;
    samples_num: number;
    tot_samples: number;
  };
  sv_length?: number;
  expression_imbalance?: {
    imbalance_score: number;
    imbalance_pval: number;
    breakpoint_range: string;
  };
  is_manually_called?: boolean;
  latest_comment?: LatestComment;
  depth_obs?: number;
  interpretation_tags: InterpretationTag[];
}

export interface SomaticSvResultDetails {
  rna_type: string;
  exon: number;
  exons: number[];
  classification: number;
  var_id: string;
  polyphen2: number;
  hgvs_p: string;
  franklin_link: string;
  quality: number;
  polyphen2_pred: string;
  depth: number;
  mt: number;
  chr: string;
  evidences: {
    therapeutic: any[][];
    prognostic: any[][];
    diagnostic: any[][];
  };
  start_pos: number;
  genes_symbols: string[];
  end_pos: number;
  ref: string;
  sift_pred: string;
  frequencies: {
    [key: string]: VariantNormalFrequency;
  };
  germline_classification: number;
  cv_accession: any[];
  depth_obs: number;
  transcript: string;
  classification_flag?: any;
  depth_ref: number;
  sift: number;
  mt_pred: string;
  obs: string;
  hgvs_c: string;
  cipos: DataPair;
  ciend: DataPair;
  variant_callers: {
    readable_name: string;
  }[];
  breakend_mates: {
    var_id: string;
    variation_type: string;
    cipos: DataPair;
    position: VariantPosition;
    genes: string[];
  }[];
  variation_type: {
    readable_name: string;
    readable_name_short: string;
    name: string;
  };
  fusion_data?: {
    variant?: {
      mate_chromosome?: string;
      mate_cipos?: DataPair;
      mate_start_pos?: number;
      zscore?: string;
      pvalue?: string;
      splitReads?: string;
      readCoverage5?: string;
      readCoverage3?: string;
      in_frame_cds?: string;
      novel_exon_boundaries?: string;
      strand1?: string;
      strand2?: string;
    };
    annotation?: {
      mate_genomic_data?: {
        refseq: SvGenomicData;
        ensembl: SvGenomicData;
      };
    };
  };
  annotation_data: {
    genomic_data: {
      refseq: SvGenomicData;
      ensembl: SvGenomicData;
    };
    exons: number[];
  };
  cosmic_ids: {
    cosmic_id: string;
    cosmic_url: string;
  }[];
  cosmic_frequencies: {
    af: number;
    samples_num: number;
    tot_samples: number;
  };
  paired_end_reads_evidence?: number;
  split_reads_evidence?: number | string;
  expression_imbalance?: {
    imbalance_score: number;
    imbalance_pval: number;
    breakpoint_range: string;
  };
  norm_count: number;
  pval?: number;
  raw_copy_number?: number;
  copy_number_confidence_interval?: {
    first_value?: number;
    second_value?: number;
  };
  num_amplicon_tiles?: number;
}
