import { Action } from '@ngrx/store';
import { ECSResult } from '../../../analysis-variant/models';

export const LOAD_ECS_ANALYSIS_RESULTS = '[Analysis] Load ECS analysis results';
export const LOAD_ECS_ANALYSIS_RESULTS_SUCCESS = '[Analysis] Load ECS analysis results success';
export const LOAD_ECS_ANALYSIS_RESULTS_FAIL = '[Analysis] Load ECS analysis results fail';
export const CLEAR_ECS_ANALYSIS_RESULTS = '[Analysis] Clear ECS analysis results';

export class LoadECSAnalysisResults implements Action {
  readonly type = LOAD_ECS_ANALYSIS_RESULTS;

  constructor(public analysisId: number) {}
}

export class LoadECSAnalysisResultsSuccess implements Action {
  readonly type = LOAD_ECS_ANALYSIS_RESULTS_SUCCESS;

  constructor(
    public analysisId: number,
    public data: ECSResult[],
  ) {}
}

export class LoadECSAnalysisResultsFail implements Action {
  readonly type = LOAD_ECS_ANALYSIS_RESULTS_FAIL;

  constructor(
    public analysisId: number,
    public error: any,
  ) {}
}

export class ClearECSAnalysisResults implements Action {
  readonly type = CLEAR_ECS_ANALYSIS_RESULTS;

  constructor() {}
}

export type ECSAnalysisResultsAction =
  | LoadECSAnalysisResults
  | LoadECSAnalysisResultsSuccess
  | LoadECSAnalysisResultsFail
  | ClearECSAnalysisResults;
