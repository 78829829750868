export interface TokenDetails {
  tokenId: string;
  accessToken: string;
  expiration: number;
}

export interface MFALoginResponse {
  mfa_token: string;
  mfa_enrollment_uri?: string;
}
