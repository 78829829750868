import { Action } from '@ngrx/store';
import { ReportExportType, ReportPhase } from '../models';
import { UserDetails } from '../../../../../auth0/models';

export const GENERATE_REPORTING_DATA = '[Reporting] Generate reporting data';
export const GENERATE_REPORTING_DATA_SUCCESS = '[Reporting] Generate reporting data success';
export const GENERATE_REPORTING_DATA_FAIL = '[Reporting] Generate reporting data fail';
export const GENERATE_REPORTING_DATA_WARNING = '[Reporting] Generate reporting data warning';

export const SAVE_REPORTING_DATA = '[Reporting] Save reporting data';
export const SAVE_REPORTING_DATA_SUCCESS = '[Reporting] Save reporting data Success';
export const SAVE_REPORTING_DATA_FAIL = '[Reporting] Save reporting data Fail';

export class GenerateReportingData implements Action {
  readonly type = GENERATE_REPORTING_DATA;

  public constructor(
    public reportType: string,
    public phase?: ReportPhase,
  ) {}
}

export class GenerateReportingDataSuccess implements Action {
  readonly type = GENERATE_REPORTING_DATA_SUCCESS;

  constructor(public payload: any) {}
}

export class GenerateReportingDataFail implements Action {
  readonly type = GENERATE_REPORTING_DATA_FAIL;

  constructor(public payload: any) {}
}

export class GenerateReportingDataWarning implements Action {
  readonly type = GENERATE_REPORTING_DATA_WARNING;
}

export class SaveReportingData implements Action {
  readonly type = SAVE_REPORTING_DATA;

  constructor(
    public reportType: string,
    public phase?: ReportPhase,
  ) {}
}

export class SaveReportingDataSuccess implements Action {
  readonly type = SAVE_REPORTING_DATA_SUCCESS;

  constructor(public payload: any) {}
}

export class SaveReportingDataFail implements Action {
  readonly type = SAVE_REPORTING_DATA_FAIL;

  constructor(public payload: any) {}
}

export const CLEAR_REPORTING_DATA = '[Reporting] Clear reporting data';

export class ClearReportingData implements Action {
  readonly type = CLEAR_REPORTING_DATA;
}

export const EXPORT_RECENT_REPORT_TO_WORD = '[Reporting] Export recent report to word';
export const EXPORT_RECENT_REPORT_TO_WORD_SUCCESS = '[Reporting] Export recent report to word success';
export const EXPORT_RECENT_REPORT_TO_WORD_FAIL = '[Reporting] Export recent report to word fail';

export class ExportRecentReportToWord implements Action {
  readonly type = EXPORT_RECENT_REPORT_TO_WORD;

  constructor(public analysisId: number) {}
}

export class ExportRecentReportToWordSuccess implements Action {
  readonly type = EXPORT_RECENT_REPORT_TO_WORD_SUCCESS;

  constructor(
    public fileLink: string,
    public analysisId: number,
  ) {}
}

export class ExportRecentReportToWordFail implements Action {
  readonly type = EXPORT_RECENT_REPORT_TO_WORD_FAIL;

  constructor(public error: any) {}
}

// these new actions support both PDF and DOC, and should replace the old word export. But - since rare disease report still works with it, we keep it
export const EXPORT_RECENT_REPORT = '[Reporting] Export recent report';
export const EXPORT_RECENT_REPORT_SUCCESS = '[Reporting] Export recent report success';
export const EXPORT_RECENT_REPORT_FAIL = '[Reporting] Export recent report fail';
export const DOWNLOAD_EXPORTED_REPORT = '[Reporting] Download exported report';

export class ExportRecentReport implements Action {
  readonly type = EXPORT_RECENT_REPORT;

  constructor(
    public analysisId: number,
    public exportType: ReportExportType,
  ) {}
}

export class ExportRecentReportSuccess implements Action {
  readonly type = EXPORT_RECENT_REPORT_SUCCESS;

  constructor(
    public fileLink: string,
    public analysisId: number,
    public exportType: ReportExportType,
  ) {}
}

export class ExportRecentReportFail implements Action {
  readonly type = EXPORT_RECENT_REPORT_FAIL;

  constructor(public error: any) {}
}

export class DownloadExportedReport implements Action {
  readonly type = DOWNLOAD_EXPORTED_REPORT;

  constructor(
    public analysisId: number,
    public exportType: ReportExportType,
  ) {}
}

export const SIGN_REPORT = '[Reporting] Sign revised report';

export class SignReport implements Action {
  readonly type = SIGN_REPORT;

  public constructor(
    public reportType: string,
    public phase: ReportPhase,
    public user: UserDetails,
  ) {}
}

export const SHOW_CONFIRM_FINAL_REPORT_DIALOG = '[Reporting] Show confirm final report dialog';

export class ShowConfirmFinalReportDialog implements Action {
  readonly type = SHOW_CONFIRM_FINAL_REPORT_DIALOG;

  public constructor(
    public reportType: string,
    public phase?: ReportPhase,
  ) {}
}

export type ReportingDataAction =
  | GenerateReportingData
  | GenerateReportingDataSuccess
  | GenerateReportingDataFail
  | SaveReportingData
  | SaveReportingDataSuccess
  | SaveReportingDataFail
  | ClearReportingData
  | ExportRecentReportToWord
  | ExportRecentReportToWordSuccess
  | ExportRecentReportToWordFail
  | ExportRecentReport
  | ExportRecentReportSuccess
  | ExportRecentReportFail
  | DownloadExportedReport
  | ShowConfirmFinalReportDialog;
