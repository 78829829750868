<div class="popup-header">
  <strong class="text">Reach out to community members</strong>
  <img
    src="/assets/png/panda-contact.png"
    srcset="assets/png/panda-contact@2x.png 2x, assets/png/panda-contact@3x.png 3x"
  />
</div>
<div class="popup-content">
  <div>
    {{ mainMessage }}<br />
    <strong>With their approval, you will receive an email with their details.</strong>
  </div>
  <button (click)="contactCallback()">Reach out</button>
</div>
