export const languages: Language[] = [
  {
    key: 'en',
    alt: 'English',
    title: 'Not Bad',
    datetimeFormat: 'MM/dd/yyyy h:mm:ss a',
    dateLocaleKey: 'en-US',
  },
  {
    key: 'hb',
    alt: 'Hebrew',
    title: 'Perfection',
    datetimeFormat: 'dd/MM/yyyy h:mm:ss a',
    dateLocaleKey: 'he',
  },
];

export declare class Language {
  key: string;
  alt: string;
  title: string;
  datetimeFormat: string;
  dateLocaleKey: string;
}
