import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { SnackbarMode } from '../../../store/models/snackbar.model';
import { ClickCursorDirective } from '../../../shared/directives/click-cursor.directive';
import { NgIf } from '@angular/common';

@Component({
  selector: 'gnx-panda-toast',
  templateUrl: './panda-toast.component.html',
  styleUrls: ['./panda-toast.component.scss'],
  standalone: true,
  imports: [NgIf, ClickCursorDirective],
})
export class PandaToastComponent implements OnInit {
  SnackbarMode = SnackbarMode;

  @Input() message: string;
  @Input() details: string;
  @Input() mode: SnackbarMode = SnackbarMode.Success;

  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('class.error') classError = false;
  @HostBinding('class.info') classInfo = false;

  constructor() {}

  ngOnInit() {
    if (this.mode === SnackbarMode.Error) {
      this.classError = true;
    } else if (this.mode === SnackbarMode.Info) {
      this.classInfo = true;
    }
  }
}
