import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  DELETE_MEMBER_FAIL,
  GetTeamMembers,
  INVITE_USERS_FAILURE,
  INVITE_USERS_SUCCESS,
  SET_MEMBER_ROLES_FAIL,
  UPDATE_ORGANIZATION_INFO_FAIL,
  UPDATE_ORGANIZATION_INFO_SUCCESS,
  UPDATE_USER_INFO_FAIL,
  UPDATE_USER_INFO_SUCCESS,
} from '../../auth0/store/actions';
import { OpenSnackbar } from '../../../store/actions';
import { switchMap } from 'rxjs/operators';
import { SnackbarMode } from '../../../store/models/snackbar.model';
import { of } from 'rxjs';

@Injectable()
export class SettingsSnackbarEffects {
  constructor(private actions$: Actions) {}

  openSuccessSnackbar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UPDATE_ORGANIZATION_INFO_SUCCESS, UPDATE_USER_INFO_SUCCESS),
      switchMap(() => of(new OpenSnackbar('Changes saved successfully!', SnackbarMode.Success))),
    ),
  );

  openErrorSnackbar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_MEMBER_ROLES_FAIL, UPDATE_ORGANIZATION_INFO_FAIL, UPDATE_USER_INFO_FAIL),
      switchMap(() => of(new OpenSnackbar("Changes could'nt be Saved. Please try again later.", SnackbarMode.Error))),
    ),
  );

  inviteUsersSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(INVITE_USERS_SUCCESS),
      switchMap(() =>
        of(new OpenSnackbar('Invitations sent successfully!!!', SnackbarMode.Success), new GetTeamMembers(true)),
      ),
    ),
  );

  inviteUsersFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(INVITE_USERS_FAILURE),
      switchMap(() => of(new OpenSnackbar('Failed to sent invitations. Please try again later.', SnackbarMode.Error))),
    ),
  );

  deleteMemberFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DELETE_MEMBER_FAIL),
      switchMap(() => of(new OpenSnackbar('Failed to delete member. Please try again later.', SnackbarMode.Error))),
    ),
  );
}
