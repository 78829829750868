import { Injectable } from '@angular/core';

import { catchError, mergeMap, Observable, of, withLatestFrom } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as fromMainStore from '../../../variants/store';
import * as fromActions from '../actions/default-filter.action';
import { LoadDefaultFilter } from '../actions/default-filter.action';

import { FiltersService } from '../services/filters.service';
import { parseDbParams, toQueryParams } from '../../../shared/utils/parse.utils';
import { OpenSnackbar } from '../../../../../../store/actions/snackbar.actions';
import { SnackbarMode } from '../../../../../../store/models/snackbar.model';
import { getAnalysisId, getAnalysisType, getVariantType } from '../../../../../../store/selectors';
import { AppModuleState } from '../../../../../../store/reducers';
import { UpdateQueryParams } from '../../../../../../store/actions';
import { VariantType } from '../../../analysis-variant/models';
import { AnalysisType } from '../../../../../../store/models';
import { FiltersState } from '../reducers';
import { getFlattenedFiltersStructureEntities } from '../selectors';
import { FilterStructure } from '../../../shared/models/filter-structure.model';

@Injectable()
export class DefaultFilterEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<fromMainStore.VariantsState>,
    private filterStore$: Store<FiltersState>,
    private filterService: FiltersService,
    private rootStore$: Store<AppModuleState>,
  ) {}

  loadDefaultFilter$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LOAD_DEFAULT_FILTER),
      withLatestFrom(
        this.rootStore$.pipe(select(getAnalysisType)),
        this.rootStore$.pipe(select(getAnalysisId)),
        this.rootStore$.pipe(select(getVariantType)),
      ),
      switchMap(
        ([action, analysisType, analysisId, variantType]: [LoadDefaultFilter, AnalysisType, number, VariantType]) => {
          return this.filterService
            .getDefaultFilter(
              action.analysisType || analysisType,
              analysisId !== -1 ? analysisId : action.analysisId,
              action.variantType || variantType,
            )
            .pipe(
              map((data) => (data as any).data),
              map((result) => new fromActions.LoadDefaultFilterSuccess(result)),
              catchError((error) => of(new fromActions.LoadDefaultFilterFail(error))),
            );
        },
      ),
    ),
  );

  loadDefaultFilterSuccess$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LOAD_DEFAULT_FILTER_SUCCESS),
      withLatestFrom(this.filterStore$.pipe(select(getFlattenedFiltersStructureEntities))),
      map(([action, filtersStructure]: [fromActions.LoadDefaultFilterSuccess, { [id: string]: FilterStructure }]) =>
        toQueryParams(parseDbParams(action.defaultFilter, filtersStructure)),
      ),
      mergeMap((queryParams) => [
        // new OpenSnackbar('Success! Default filter loaded', SnackbarMode.Success),
        new UpdateQueryParams(queryParams, true),
      ]),
    ),
  );

  loadDefaultFilterFail$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LOAD_DEFAULT_FILTER_FAIL),
      switchMap(() => of(new OpenSnackbar('Error! Load default filter failed', SnackbarMode.Error))),
    ),
  );
}
