import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import * as fromAction from '../actions/cancer-types.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CancerTypesService } from '../services';

@Injectable()
export class CancerTypesEffects {
  constructor(
    private actions$: Actions,
    private service: CancerTypesService,
  ) {}

  loadCancerTypes$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GET_CANCER_TYPES),
      switchMap((action: fromAction.GetCancerTypes) =>
        this.service.getCancerTypes(action.prefixText).pipe(
          map((result) => new fromAction.GetCancerTypesSuccess(result, action.prefixText)),
          catchError((err) => of(new fromAction.GetCancerTypesFail(err))),
        ),
      ),
    ),
  );
}
