import { createSelector, MemoizedSelector } from '@ngrx/store';
import { getWorkbenchModuleState, WorkbenchModuleState } from '../reducers';
import { CaseSummaryState } from '../reducers/case-summary.reducer';
import { CaseSummary } from '../models';

import * as fromReducer from './../reducers/case-summary.reducer';

const getCaseSummaryState: MemoizedSelector<WorkbenchModuleState, CaseSummaryState> = createSelector(
  getWorkbenchModuleState,
  (state: WorkbenchModuleState) => state.caseSummary,
);

export const getCaseSummary: MemoizedSelector<WorkbenchModuleState, CaseSummary> = createSelector(
  getCaseSummaryState,
  fromReducer.getCaseSummary,
);

export const getCaseSummaryLoaded: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getCaseSummaryState,
  fromReducer.getCaseSummaryLoaded,
);

export const getCaseSummaryLoading: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getCaseSummaryState,
  fromReducer.getCaseSummaryLoading,
);

export const getCaseSummaryError: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getCaseSummaryState,
  fromReducer.getCaseSummaryError,
);

export const getTreatmentInReportSaving: MemoizedSelector<WorkbenchModuleState, { [treatmentId: string]: boolean }> = createSelector(
  getCaseSummaryState,
  (state) => state.treatmentInReportSaving
)
