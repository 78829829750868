import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

import * as fromAction from './../actions/somatic-biomarkers.action';
import { catchError, map, switchMap } from 'rxjs/operators';
import { WorkbenchService } from '../services/workbench.service';
import { SomaticBiomarkerResponse } from '../models';

@Injectable()
export class SomaticBiomarkerEffect {
  constructor(
    private actions$: Actions,
    private wbService: WorkbenchService,
  ) {}

  LoadSomaticBiomarkers$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_SOMATIC_BIOMARKERS),
      switchMap((action: fromAction.LoadSomaticBiomarkers) =>
        this.wbService.getSomaticBiomarkerData(action.analysisId, action.diseases).pipe(
          map((result) => new fromAction.LoadSomaticBiomarkersSuccess(result)),
          catchError((error) => of(new fromAction.LoadSomaticBiomarkersFail(error))),
        ),
      ),
    ),
  );

  loadSomaticBiomarkerUserData$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_SOMATIC_MARKER_USER_DATA),
      switchMap((action: fromAction.LoadSomaticMarkerUserData) =>
        this.wbService.getSomaticBiomarkerUserData(action.analysisId).pipe(
          map((result) => new fromAction.LoadSomaticMarkerUserDataSuccess(result)),
          catchError((error) => of(new fromAction.LoadSomaticMarkerUserDataFail(error))),
        ),
      ),
    ),
  );

  updateSomaticBiomarkerUserData$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.UPDATE_SOMATIC_MARKER_USER_DATA),
      switchMap((action: fromAction.UpdateSomaticMarkerUserData) =>
        this.wbService.updateSomaticBiomarkerUserData(action.analysisId, action.payload).pipe(
          map((result) =>
            result.success
              ? new fromAction.UpdateSomaticMarkerUserDataSuccess(action.payload)
              : new fromAction.UpdateSomaticMarkerUserDataFail(result),
          ),
          catchError((error) => of(new fromAction.UpdateSomaticMarkerUserDataFail(error))),
        ),
      ),
    ),
  );

  upsertBiomarker$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.UPSERT_BIOMARKER),
      switchMap((action: fromAction.UpsertBiomarker) =>
        this.wbService.upsertBiomarker(action.analysisId, action.biomarker, action.score, action.level).pipe(
          map((result) => new fromAction.LoadSomaticBiomarkersSuccess(result)),
          catchError((error) => of(new fromAction.LoadSomaticBiomarkersFail(error))),
        ),
      ),
    ),
  );
}
