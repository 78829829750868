import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppModuleState } from '../reducers';
import { getPatientInfoResponse } from '../../modules/analysis/modules/workbench/store/selectors';
import { LOAD_VARIANT_POPUP, LoadVariantPopup } from '../actions';
import { variantPopupAreaToPopupApp, variantPopupAreaToPopupWidget } from '../../modules/analysis/models';
import { WorkbenchModuleState } from '../../modules/analysis/modules/workbench/store/reducers';
import { SearchConfig } from '../../modules/variant-page/modules/variant-page/models';
import { getAnalysisDetails } from '../selectors';
import { AnalysisDetails } from '../models';

@Injectable()
export class PopupEffects {
  constructor(
    private actions: Actions,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private rootStore: Store<AppModuleState>,
    private workbenchStore$: Store<WorkbenchModuleState>,
  ) {}

  openVariantPagePopup$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(LOAD_VARIANT_POPUP),
        withLatestFrom(
          this.workbenchStore$.pipe(select(getPatientInfoResponse)),
          this.rootStore.pipe(select(getAnalysisDetails)),
        ),
        tap(([action, caseContext, analysis]: [LoadVariantPopup, SearchConfig, AnalysisDetails]) =>
          this.router.navigate([{ outlets: { dialog: 'variant/dialog' } }], {
            queryParamsHandling: 'preserve',
            state: {
              variantData: action.payload.map((selection) => ({
                ...selection,
                referenceGenome:
                  analysis?.main_sample?.reference_genome_version?.toLowerCase() ||
                  action.payload[action.selectedIndex].referenceGenome,
              })),
              variantDataIndex: action.selectedIndex,
              searchConfig: {
                ...caseContext,
                zygosity: action.payload[action.selectedIndex].variant.zygosity,
              },
              app: variantPopupAreaToPopupApp(action.payload[action.selectedIndex].area),
              widget: variantPopupAreaToPopupWidget(action.payload[action.selectedIndex].area),
              analysisType: analysis.analysis_type,
              analysisId: action.payload[action.selectedIndex].analysisId,
              discoveryAnalysisId: action.payload[action.selectedIndex].discoveryAnalysisId,
              annotationVersion: action.payload[action.selectedIndex].variant.annotationVersion,
              parentAppType: action.payload[action.selectedIndex].app_type,
              assayId: analysis?.assay?.uuid,
            },
          }),
        ),
      ),
    { dispatch: false },
  );
}
