import { VariantNormalFrequency } from '../../shared/models/frequencies';
import {
  CuratedSubmissions,
  CustomAnnotation,
  PhenotypeRelevance,
  SnpDiseases,
  SnpInterpretations,
  SnpPublicationsData,
  SnpResult,
  SnpUserInterpretation,
} from './snp-result.model';

export interface OvergrowthSnpResult extends SnpResult {
  var_id: string;
  chr: string;
  start_pos: number;
  end_pos: number;
  closest_distance: number;
  closest_distance_ens: number;
  exon: number;
  exon_ens: number;
  gene: string;
  ref: string;
  obs: string;
  region: string;
  effect: string;
  hgvs_c: string;
  hgvs_c_ens: string;
  hgvs_p: string;
  hgvs_p_ens: string;
  transcript: string;
  transcript_ens: string;
  dbsnp: string;
  zygosity: string;
  quality: number;
  gq: number;
  pl: string;
  gl: string;
  depth: number;
  depth_ref: number;
  depth_obs: number;
  ab: number;
  fisher_strand: number;
  read_pos_rank_sum: number;
  mq: number;
  mq_rank_sum: number;
  classification: number;
  aggregated_frequency: number;
  internal_percent: number;
  sample_count: string; // despite it's name this is actually a string of sample count and freq together "xx (yy%)"
  confidence: number;
  confidence_text: string;
  aggregated_prediction: number;
  aggregated_prediction_desc: string;
  sift: number;
  sift_pred: string;
  polyphen2: number;
  polyphen2_pred: string;
  mt: number;
  mt_pred: string;
  ma: number;
  ma_pred: string;
  fathmm: number;
  fathmm_pred: string;
  ada_score: number;
  ada_pred: string;
  gerp: number;
  genocanyon_score: number;
  genocanyon_pred: string;
  integrated_fitcons_score: number;
  integrated_fitcons_pred: string;
  diseases: SnpDiseases;
  frequencies: {
    [key: string]: VariantNormalFrequency;
  };
  curated_submissions: CuratedSubmissions;
  interpreted_community_assessments: string[];
  interpretations: SnpInterpretations;
  publications_data?: SnpPublicationsData;
  closest_exon: number;
  closest_exon_ens: number;
  phenotypes_search_data?: PhenotypeRelevance;
  user_annotations: CustomAnnotation[];
  in_workbench: boolean;
  user_interpretation: SnpUserInterpretation;
  revel: number;
  revel_pred: string;
  metalr: number;
  metalr_pred: string;
  splice_ai: number;
  splice_ai_pred: string;
  ncer_pred: string;
  ncer: number;
  inheritance_model?: string;
  inheritance_model_short?: string;
  vaf: number;
  cosmic_data: {
    frequency: number;
    entries: {
      id: string;
      url: string;
    }[];
  };
  relation: string;
}
