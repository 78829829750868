import { RangeOption } from '../models';
import * as fromAction from './../actions/prediction-options.action';

export interface PredictionOptionsState {
  data: { [key: string]: RangeOption[] };
  loading: boolean;
  loaded: boolean;
}

const initialState: PredictionOptionsState = {
  data: {},
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action: fromAction.PredictionOptionsAction): PredictionOptionsState {
  switch (action.type) {
    case fromAction.GET_PREDICTION_OPTIONS: {
      return { ...state, loading: true, loaded: false, data: undefined };
    }
    case fromAction.GET_PREDICTION_OPTIONS_SUCCESS: {
      return { ...state, loading: false, loaded: true, data: action.payload };
    }
    case fromAction.GET_PREDICTION_OPTIONS_FAIL: {
      return { ...state, loading: false, loaded: false };
    }
  }
  return { ...state };
}
