import { Message, Topic, User } from '../../models/discussion.models';
import {
  CREATE_TOPIC_SUCCESS,
  DELETE_MESSAGE_SUCCESS,
  DELETE_TOPIC_SUCCESS,
  DiscussionAction,
  GET_MESSAGES_SUCCESS,
  GET_TOPICS_SUCCESS,
  GET_USERS_SUCCESS,
  RESET_DISCUSSION_STATE,
  SUBMIT_MESSAGE_SUCCESS,
} from '../actions/discussion.action';

export interface DiscussionState {
  topics: Topic[];
  messages: Message[];
  users: User[];
  changedState: boolean;
}

const discussionInitialState: DiscussionState = {
  topics: [],
  messages: [],
  users: [],
  changedState: false,
};

export function discussionReducer(state: DiscussionState = discussionInitialState, action: DiscussionAction) {
  switch (action.type) {
    case GET_TOPICS_SUCCESS: {
      return {
        ...state,
        topics: [...action.topics].reverse(),
      };
    }
    case GET_MESSAGES_SUCCESS: {
      return {
        ...state,
        messages: action.messages,
      };
    }
    case SUBMIT_MESSAGE_SUCCESS: {
      return {
        ...state,
        messages: action.update
          ? state.messages.map((message) =>
              message.message_id === action.message.message_id ? action.message : message,
            )
          : [...state.messages, action.message],
      };
    }
    case CREATE_TOPIC_SUCCESS: {
      return {
        ...state,
        topics: [action.topic, ...state.topics],
      };
    }
    case GET_USERS_SUCCESS: {
      return {
        ...state,
        users: action.users,
      };
    }
    case RESET_DISCUSSION_STATE: {
      return {
        ...discussionInitialState,
      };
    }
    case DELETE_MESSAGE_SUCCESS: {
      return {
        ...state,
        messages: state.messages.filter((message) => message.message_id !== action.messageId),
        changedState: true,
      };
    }
    case DELETE_TOPIC_SUCCESS: {
      return {
        ...state,
        topics: state.topics.filter((topic) => topic.topic_id !== action.topicId),
        changedState: true,
      };
    }
  }
  return state;
}

export const getTopics = (state: DiscussionState) => state.topics;
export const getMessages = (state: DiscussionState) => state.messages;
export const getUsers = (state: DiscussionState) => state.users;
export const getChangedState = (state: DiscussionState) => state.changedState;
