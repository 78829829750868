import { Action } from '@ngrx/store';
import { AnalysisDataComponentType, CaseDataComponentType } from '../models/config.model';

export const LOAD_CASE_DATA_COMPONENTS = '[Analysis] Load case data components';
export const LOAD_ANALYSIS_DATA_COMPONENTS = '[Analysis] Load analysis data components';

export class LoadCaseDataComponents implements Action {
  readonly type = LOAD_CASE_DATA_COMPONENTS;

  constructor(
    public analysisId: number,
    public components: CaseDataComponentType[],
  ) {}
}

export class LoadAnalysisDataComponents implements Action {
  readonly type = LOAD_ANALYSIS_DATA_COMPONENTS;

  constructor(
    public analysisId: number,
    public components: AnalysisDataComponentType[],
  ) {}
}

export const CLEAR_WORKBENCH = '[Analysis] Clear';

export class ClearWorkbench implements Action {
  readonly type = CLEAR_WORKBENCH;

  constructor() {}
}

export type WorkbenchAction = LoadCaseDataComponents | LoadAnalysisDataComponents | ClearWorkbench;
