import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AppModuleState, getAppModuleState } from '../reducers';
import { Phenotype } from '../models';

export const getVariantsAutoState: MemoizedSelector<AppModuleState, any> = createSelector(
  getAppModuleState,
  (state: AppModuleState) => state.variantsAutocomplete,
);

export const getVariantsAutocomplete: MemoizedSelector<any, Phenotype[]> = createSelector(
  getVariantsAutoState,
  (state) => state.autocompleteSuggestions,
);

export const getVariantsAutocompleteLoaded: MemoizedSelector<any, boolean> = createSelector(
  getVariantsAutoState,
  (state) => state.autocompleteLoaded,
);

export const getVariantsAutocompleteLoading: MemoizedSelector<any, boolean> = createSelector(
  getVariantsAutoState,
  (state) => state.autocompleteLoading,
);
