<div class="container">
  <div class="input-container">
    <div class="control-wrapper">
      <gnx-dots-loader class="loader" *ngIf="optionsLoading"></gnx-dots-loader>
      <input
        #searchInputElm
        [placeholder]="placeholder || ''"
        [matAutocomplete]="autocomplete"
        [formControl]="inputControl"
        (keyup.enter)="onEnter()"
        (blur)="onBlur()"
      />
      <div class="arrow-wrapper" (click)="searchInputElm.focus()" [class.disabled]="disabled">
        <img src="/assets/svg/arrow-down-blue.svg" />
      </div>
    </div>
    <mat-autocomplete
      #autocomplete="matAutocomplete"
      [panelWidth]="autoCompleteFixedWidthPx"
      class="autocomplete-dropdown"
    >
      <ng-container *ngFor="let option of filteredOptions; let i = index">
        <div class="option-header" *ngIf="option.group && (i === 0 || option.group !== filteredOptions[i - 1].group)">
          {{ option.group | uppercase }}
        </div>
        <mat-option
          [value]="option.value"
          (onSelectionChange)="onSelect(option)"
          [class.error-phenotype]="option.value === 'error'"
        >
          <span [class.error-msg]="option.value === 'error'">{{ option.label }}</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </div>
  <div class="gene-panel-chips">
    <gnx-chips
      [chips]="optionsLoading ? [] : getSelectionLabels()"
      (remove)="onRemoveSelection($event)"
      [showClearAll]="!disabled"
      (clearAll)="onClearAll()"
      [disableRemove]="disabled"
      [class.disabled]="disabled"
    ></gnx-chips>
  </div>
</div>
