export enum WidgetType {
  Assessments = 'assessments',
}

export interface WidgetConfig {
  type: WidgetType;
  top: number;
  left: number;
  height: number;
  width: number;
}

export interface OverviewConfig {
  widgets: WidgetConfig[];
}
