import { SearchAction } from '../actions';

import * as fromAction from './../actions/search.action';
import { SearchHistoryResponse, VariantId } from '../models';
import { Analysis } from '../../modules/analysis/modules/analyses/store';

export interface SearchVariantState {
  term: string;
  result?: VariantId;
  analysisResults?: Analysis[];
  history: SearchHistoryResponse;
  searching: boolean;
  searchDone: boolean;
  searchError: string;
}

const initialState: SearchVariantState = {
  term: '',
  history: null,
  searching: false,
  searchDone: false,
  searchError: '',
};

export function reducer(state = initialState, action: SearchAction): SearchVariantState {
  switch (action.type) {
    case fromAction.SEARCH_ANALYSES:
    case fromAction.SEARCH_VARIANT: {
      return {
        ...state,
        term: action.payload,
        searching: true,
        searchError: '',
        searchDone: false,
        result: undefined,
        history: undefined,
        analysisResults: [],
      };
    }
    case fromAction.SEARCH_VARIANT_SUCCESS_NO_REDIRECT:
    case fromAction.SEARCH_VARIANT_SUCCESS: {
      return {
        ...state,
        searching: false,
        searchDone: true,
        result: action.variant,
      };
    }
    case fromAction.SEARCH_ANALYSES_SUCCESS: {
      return {
        ...state,
        searching: false,
        searchDone: true,
        analysisResults: action.analyses,
      };
    }
    case fromAction.SEARCH_ANALYSES_FAIL:
    case fromAction.SEARCH_VARIANT_FAIL: {
      return {
        ...state,
        searching: false,
        searchDone: false,
        searchError: action.error,
      };
    }
    case fromAction.SEARCH_HISTORY_SUCCESS: {
      return {
        ...state,
        history: action.response,
      };
    }
    case fromAction.CHOOSE_SEARCH_VARIANT: {
      return {
        ...state,
        searching: false,
      };
    }
    case fromAction.CLEAR_SEARCH_VARIANT_ERROR: {
      return {
        ...state,
        searchError: '',
      };
    }
    case fromAction.CLEAR_SEARCH_DATA: {
      return { ...initialState };
    }
  }

  return state;
}

export const getSearchTerm = (state: SearchVariantState) => state.term;
export const getSearching = (state: SearchVariantState) => state.searching;
export const getSearchDone = (state: SearchVariantState) => state.searchDone;
export const getSearchError = (state: SearchVariantState) => state.searchError;
export const getSearchResult = (state: SearchVariantState) => state.result;
export const getAnalysisResults = (state: SearchVariantState) => state.analysisResults;
export const getSearchHistory = (state: SearchVariantState) => state.history;
