import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AUTH_USER_ROLES, UserDetails } from '../../../auth0/models';
import { FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { SetInfo, UpdateUserInfo } from '../../../auth0/store/actions';
import { select, Store } from '@ngrx/store';
import { AuthModuleState } from '../../../auth0/store/reducers';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { getUpdateUserInProgress } from '../../../auth0/store/selectors';
import { Observable } from 'rxjs';
import { DotsLoaderComponent } from '../../../../shared/components/dots-loader/dots-loader.component';
import { ClickCursorDirective } from '../../../../shared/directives/click-cursor.directive';
import { RouterLink } from '@angular/router';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { NgIf, NgClass, NgFor, AsyncPipe } from '@angular/common';

@Component({
  selector: 'gnx-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
  animations: [
    trigger('grow', [
      state('inOut', style({ flex: 1000 })),
      transition(':leave', animate(500, style({ flex: 0 }))),
      transition(':enter', [style({ flex: 0 }), animate(500)]),
    ]),
  ],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    NgClass,
    MatSelect,
    NgFor,
    MatOption,
    RouterLink,
    ClickCursorDirective,
    DotsLoaderComponent,
    AsyncPipe,
  ],
})
export class ProfileDetailsComponent implements OnChanges, OnInit {
  ROLES = AUTH_USER_ROLES;

  @Input() data: UserDetails;

  profileForm: FormGroup;
  formChanged = false;
  updateUserInProgress$: Observable<boolean>;

  constructor(
    private fb: FormBuilder,
    private authStore$: Store<AuthModuleState>,
  ) {}

  ngOnChanges() {
    this.profileForm = this.fb.group({
      name: [
        this.data.name,
        [Validators.required, this.fullNameValidator.bind(this), this.notEmailValidator.bind(this)],
      ],
      email: [{ value: this.data.email, disabled: true }],
      role: [
        this.ROLES.includes(this.data.roles[0]) ? this.data.roles[0] : 'Other',
        [Validators.required, this.roleValidator.bind(this)],
      ],
      other: [this.ROLES.includes(this.data.roles[0]) ? '' : this.data.roles[0], [this.otherRoleValidator.bind(this)]],
    });

    this.profileForm.valueChanges.subscribe(() => (this.formChanged = true));
  }

  ngOnInit() {
    this.updateUserInProgress$ = this.authStore$.pipe(select(getUpdateUserInProgress));
  }

  onCancel() {
    this.profileForm.controls.name.setValue(this.data.name);
    this.profileForm.controls.role.setValue(this.ROLES.includes(this.data.roles[0]) ? this.data.roles[0] : 'Other');
    this.profileForm.controls.other.setValue(this.ROLES.includes(this.data.roles[0]) ? '' : this.data.roles[0]);
    this.formChanged = false;
  }

  onSubmit() {
    // this.formChanged = false;
    this.authStore$.dispatch(
      new UpdateUserInfo(
        this.profileForm.get('name').value,
        this.profileForm.get(this.profileForm.get('role').value === 'Other' ? 'other' : 'role').value,
      ),
    );
  }

  get p() {
    return this.profileForm.controls;
  }

  private fullNameValidator(control: FormControl) {
    const email = control.value.trim().toLowerCase();
    if (!email.includes(' ')) {
      return {
        fullName: true,
      };
    }
    return null;
  }

  private notEmailValidator(control: FormControl) {
    const email = control.value.trim().toLowerCase();
    if (email.includes('@')) {
      return {
        email: true,
      };
    }
    return null;
  }

  private roleValidator(control: FormControl) {
    if (!this.profileForm) {
      return;
    }
    const role = control.value;
    if (role !== 'Other') {
      this.p.other.setErrors(null);
    } else if (!this.p.other.value) {
      this.p.other.setErrors(
        role !== 'Other'
          ? null
          : {
              required: true,
            },
      );
    }
    return null;
  }

  private otherRoleValidator(control: FormControl) {
    const role = control.value;
    if (!role && this.profileForm && this.p.role.value === 'Other') {
      return {
        required: true,
      };
    }
    return null;
  }
}
