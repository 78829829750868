import { Action } from '@ngrx/store';
import { CaseType } from '../../modules/case-dialogs/models';
import { AnalysisType } from '../models';
import { DynamicInputField } from '../../shared/models';

export const GET_CASE_CUSTOM_FIELDS = '[Create Case] Get custom fields';
export const GET_CASE_CUSTOM_FIELDS_SUCCESS = '[Create Case] Get custom fields success';
export const GET_CASE_CUSTOM_FIELDS_FAIL = '[Create Case] Get custom fields fail';

export class GetCaseCustomFields implements Action {
  readonly type = GET_CASE_CUSTOM_FIELDS;

  constructor(
    public caseType?: CaseType,
    public assayId?: string,
    public analysisType?: AnalysisType,
  ) {}
}

export class GetCaseCustomFieldsSuccess implements Action {
  readonly type = GET_CASE_CUSTOM_FIELDS_SUCCESS;

  constructor(
    public fields: DynamicInputField[],
    public caseResolutionFields: DynamicInputField[],
    public patientClinicalInfoFields: DynamicInputField[],
    public generalCaseFields: DynamicInputField[],
  ) {}
}

export class GetCaseCustomFieldsFail implements Action {
  readonly type = GET_CASE_CUSTOM_FIELDS_FAIL;

  constructor(public error: any) {}
}

export type CaseCustomFieldsAction = GetCaseCustomFields | GetCaseCustomFieldsSuccess | GetCaseCustomFieldsFail;
