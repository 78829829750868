export const svgIcons = [
  '/assets/svg/!-contrast.svg',
  '/assets/svg/!.svg',
  '/assets/svg/3-dot.svg',
  '/assets/svg/add-contrast.svg',
  '/assets/svg/add-pedigree-plus-sign.svg',
  '/assets/svg/add-symbol.svg',
  '/assets/svg/add-to-list-active.svg',
  '/assets/svg/add-to-list.svg',
  '/assets/svg/add.svg',
  '/assets/svg/affected-switch-off.svg',
  '/assets/svg/affected-switch-on.svg',
  '/assets/svg/alert.svg',
  '/assets/svg/analyses-no-results.svg',
  '/assets/svg/analyze-tool.svg',
  '/assets/svg/arrow-blue.svg',
  '/assets/svg/arrow-circle.svg',
  '/assets/svg/arrow-curved-2.svg',
  '/assets/svg/arrow-curved.svg',
  '/assets/svg/arrow-curver-long.svg',
  '/assets/svg/arrow-down-blue.svg',
  '/assets/svg/arrow-down-red.svg',
  '/assets/svg/arrow-right-blue.svg',
  '/assets/svg/arrow-right.svg',
  '/assets/svg/arrow-up-blue.svg',
  '/assets/svg/arrow-up-green.svg',
  '/assets/svg/arrow-up.svg',
  '/assets/svg/arrow.svg',
  '/assets/svg/assets-assays.svg',
  '/assets/svg/assets-filters.svg',
  '/assets/svg/assets-panels.svg',
  '/assets/svg/assets-samples.svg',
  '/assets/svg/assistance.svg',
  '/assets/svg/back.svg',
  '/assets/svg/BED-file.svg',
  '/assets/svg/bell-header.svg',
  '/assets/svg/bell-white.svg',
  '/assets/svg/bell.svg',
  '/assets/svg/Binocular-hover.svg',
  '/assets/svg/Binocular.svg',
  '/assets/svg/blue-back-arrow.svg',
  '/assets/svg/blue-plus.svg',
  '/assets/svg/bookmark.svg',
  '/assets/svg/box-logo.svg',
  '/assets/svg/breadcrumb-arrow.svg',
  '/assets/svg/browser1.svg',
  '/assets/svg/bubble.svg',
  '/assets/svg/calendar-empty.svg',
  '/assets/svg/calendar.svg',
  '/assets/svg/campass.svg',
  '/assets/svg/case-details-blue-empty.svg',
  '/assets/svg/case-details-white-big.svg',
  '/assets/svg/case-details-white.svg',
  '/assets/svg/case-details.svg',
  '/assets/svg/case-from-report-cta.svg',
  '/assets/svg/case-from-vcf-cta.svg',
  '/assets/svg/case-icon-ecs.svg',
  '/assets/svg/case-icon-family.svg',
  '/assets/svg/case-icon-from-report.svg',
  '/assets/svg/case-icon-germline-report.svg',
  '/assets/svg/case-icon-overgrowth.svg',
  '/assets/svg/case-icon-single.svg',
  '/assets/svg/case-icon-somatic-report.svg',
  '/assets/svg/case-icon-somatic.svg',
  '/assets/svg/case-snackbar-created.svg',
  '/assets/svg/case-snackbar-error.svg',
  '/assets/svg/case-snackbar-info.svg',
  '/assets/svg/case-snackbar-processing.svg',
  '/assets/svg/case-snackbar-upload-done.svg',
  '/assets/svg/cell-sick.svg',
  '/assets/svg/chat-icon-2.svg',
  '/assets/svg/check-1.svg',
  '/assets/svg/check-box-empty-hover.svg',
  '/assets/svg/check-box-empty.svg',
  '/assets/svg/check-box-pressed.svg',
  '/assets/svg/check-green.svg',
  '/assets/svg/check-inbox.svg',
  '/assets/svg/check-mark-green.svg',
  '/assets/svg/check-white.svg',
  '/assets/svg/check.svg',
  '/assets/svg/checked-v.svg',
  '/assets/svg/circle-chart.svg',
  '/assets/svg/clear-all.svg',
  '/assets/svg/clinical-db.svg',
  '/assets/svg/clinical-icon.svg',
  '/assets/svg/clinical-info.svg',
  '/assets/svg/clinical_icon.svg',
  '/assets/svg/clipboard-white.svg',
  '/assets/svg/close-blue.svg',
  '/assets/svg/close-round-blue.svg',
  '/assets/svg/close.svg',
  '/assets/svg/cma-reports.svg',
  '/assets/svg/CMA.svg',
  '/assets/svg/cnv-variants.svg',
  '/assets/svg/cogs.svg',
  '/assets/svg/colloborate-1.svg',
  '/assets/svg/colloborate.svg',
  '/assets/svg/combined-shape-copy-2.svg',
  '/assets/svg/comment-blue.svg',
  '/assets/svg/comment-icon.svg',
  '/assets/svg/community-blue.svg',
  '/assets/svg/community-only.svg',
  '/assets/svg/community.svg',
  '/assets/svg/computer-icon.svg',
  '/assets/svg/contact-back.svg',
  '/assets/svg/copy-blue.svg',
  '/assets/svg/copy-success-green.svg',
  '/assets/svg/copy.svg',
  '/assets/svg/cross.svg',
  '/assets/svg/custom-carrier-app.svg',
  '/assets/svg/define-by-user-icon-pressed.svg',
  '/assets/svg/define-by-user-icon.svg',
  '/assets/svg/delete-icon.svg',
  '/assets/svg/delorian.svg',
  '/assets/svg/discussion.svg',
  '/assets/svg/disease-associated-hover.svg',
  '/assets/svg/disease-associated.svg',
  '/assets/svg/dna-hover.svg',
  '/assets/svg/dna-icon.svg',
  '/assets/svg/dna.svg',
  '/assets/svg/doc.svg',
  '/assets/svg/done-illus.svg',
  '/assets/svg/down-arrow.svg',
  '/assets/svg/down-up.svg',
  '/assets/svg/drag-copy.svg',
  '/assets/svg/drag-hand.svg',
  '/assets/svg/drag-handle.svg',
  '/assets/svg/drop-black.svg',
  '/assets/svg/drop-box.svg',
  '/assets/svg/drop-hand.svg',
  '/assets/svg/drop-white.svg',
  '/assets/svg/drop.svg',
  '/assets/svg/duo-app.svg',
  '/assets/svg/duo.svg',
  '/assets/svg/ecs-1.svg',
  '/assets/svg/ecs-2.svg',
  '/assets/svg/ecs-3.svg',
  '/assets/svg/ecs-4.svg',
  '/assets/svg/edit-circle.svg',
  '/assets/svg/edit-var.svg',
  '/assets/svg/edit.svg',
  '/assets/svg/email.svg',
  '/assets/svg/empty-image.svg',
  '/assets/svg/empty-state-unassigned.svg',
  '/assets/svg/error-alert.svg',
  '/assets/svg/error-icon.svg',
  '/assets/svg/error.svg',
  '/assets/svg/esc-blk.svg',
  '/assets/svg/esc-white-copy-2.svg',
  '/assets/svg/esc-white.svg',
  '/assets/svg/esc-with-txt.svg',
  '/assets/svg/ethnicity.svg',
  '/assets/svg/everyone.svg',
  '/assets/svg/evidence-definitive.svg',
  '/assets/svg/evidence-disputed.svg',
  '/assets/svg/evidence-level-no-bg.svg',
  '/assets/svg/evidence-limited.svg',
  '/assets/svg/evidence-moderate.svg',
  '/assets/svg/evidence-no.svg',
  '/assets/svg/evidence-refuted.svg',
  '/assets/svg/evidence-strong.svg',
  '/assets/svg/ex-link-white.svg',
  '/assets/svg/ex-link.svg',
  '/assets/svg/ex-popup.svg',
  '/assets/svg/exons-icon.svg',
  '/assets/svg/expand-arrow-round.svg',
  '/assets/svg/expand-arrow-white.svg',
  '/assets/svg/expand-arrow.svg',
  '/assets/svg/expand-icon.svg',
  '/assets/svg/export-pdf.svg',
  '/assets/svg/export-white.svg',
  '/assets/svg/export-xlsx.svg',
  '/assets/svg/export-xml.svg',
  '/assets/svg/export.svg',
  '/assets/svg/eye-close.svg',
  '/assets/svg/eye-gray.svg',
  '/assets/svg/eye-open.svg',
  '/assets/svg/family-app.svg',
  '/assets/svg/family.svg',
  '/assets/svg/feed-bg-2.svg',
  '/assets/svg/feed-bg-dark-blue.svg',
  '/assets/svg/feed-bg.svg',
  '/assets/svg/female-copy.svg',
  '/assets/svg/female-dark-blue.svg',
  '/assets/svg/female-illustration-breast.svg',
  '/assets/svg/female-illustration-ovarian-1.svg',
  '/assets/svg/female-illustration-ovarian-2.svg',
  '/assets/svg/female-illustration.svg',
  '/assets/svg/female-rounded.svg',
  '/assets/svg/female.svg',
  '/assets/svg/filter-list.svg',
  '/assets/svg/fingerprint-new.svg',
  '/assets/svg/fingerprint.svg',
  '/assets/svg/flag-1AB29C.svg',
  '/assets/svg/flag-919fa5.svg',
  '/assets/svg/flag-blue-fill.svg',
  '/assets/svg/flag-blue.svg',
  '/assets/svg/flag-e03a39.svg',
  '/assets/svg/flag-f19a29.svg',
  '/assets/svg/flag-link-hover.svg',
  '/assets/svg/flag-link.svg',
  '/assets/svg/flag-white-2.svg',
  '/assets/svg/flag-white.svg',
  '/assets/svg/flag.svg',
  '/assets/svg/flag2.svg',
  '/assets/svg/flash.svg',
  '/assets/svg/folder-big.svg',
  '/assets/svg/folder-drop.svg',
  '/assets/svg/folder-white.svg',
  '/assets/svg/folder.svg',
  '/assets/svg/franklin-dark-bg.svg',
  '/assets/svg/franklin-min-logo.svg',
  '/assets/svg/franklin_logo.svg',
  '/assets/svg/from-cma-empty.svg',
  '/assets/svg/from-cma.svg',
  '/assets/svg/from-report-empty.svg',
  '/assets/svg/from-report-icon.svg',
  '/assets/svg/ftp-icon.svg',
  '/assets/svg/funnel.svg',
  '/assets/svg/gauge-desktop.svg',
  '/assets/svg/gauge-Medium.svg',
  '/assets/svg/gauge-No.svg',
  '/assets/svg/gauge-Strong.svg',
  '/assets/svg/gauge-sv-edit.svg',
  '/assets/svg/gauge-sv.svg',
  '/assets/svg/gauge-Weak.svg',
  '/assets/svg/gender-plus.svg',
  '/assets/svg/gene-arrow-minus.svg',
  '/assets/svg/gene-arrow-plus.svg',
  '/assets/svg/gene-arrow.svg',
  '/assets/svg/gene-assessment-hover.svg',
  '/assets/svg/gene-assessment-white.svg',
  '/assets/svg/gene-assessment.svg',
  '/assets/svg/gene-details1.svg',
  '/assets/svg/gene-panels.svg',
  '/assets/svg/gene.svg',
  '/assets/svg/genes-app-disabled.svg',
  '/assets/svg/genes-icon.svg',
  '/assets/svg/genes.svg',
  '/assets/svg/genoox-hover.svg',
  '/assets/svg/genoox-logo-new-dark.svg',
  '/assets/svg/genoox.svg',
  '/assets/svg/germline-report-icon.svg',
  '/assets/svg/globe-blue.svg',
  '/assets/svg/globe.svg',
  '/assets/svg/google-drive-logo.svg',
  '/assets/svg/google.svg',
  '/assets/svg/graph-icon-hover.svg',
  '/assets/svg/graph-icon.svg',
  '/assets/svg/greater.svg',
  '/assets/svg/grip.svg',
  '/assets/svg/group-12.svg',
  '/assets/svg/group-3.svg',
  '/assets/svg/group-dna.svg',
  '/assets/svg/grouping.svg',
  '/assets/svg/hand-icon-empty.svg',
  '/assets/svg/hand-icon.svg',
  '/assets/svg/hand-like-icon.svg',
  '/assets/svg/history.svg',
  '/assets/svg/home-bg-bottom-right-4.svg',
  '/assets/svg/home-bg-top-left.svg',
  '/assets/svg/hospital.svg',
  '/assets/svg/i.svg',
  '/assets/svg/icons-error-circle-copy.svg',
  '/assets/svg/illustration.svg',
  '/assets/svg/in-workbench.svg',
  '/assets/svg/info-2-black-line.svg',
  '/assets/svg/info-2.svg',
  '/assets/svg/info-blue-gray.svg',
  '/assets/svg/info-blue.svg',
  '/assets/svg/info-circle-solid.svg',
  '/assets/svg/info-grey.svg',
  '/assets/svg/info.svg',
  '/assets/svg/interpretation-bright-left.svg',
  '/assets/svg/interpretation-bright.svg',
  '/assets/svg/interpretation-history-app-dark.svg',
  '/assets/svg/interpretation-history-app.svg',
  '/assets/svg/interpretation.svg',
  '/assets/svg/invite.svg',
  '/assets/svg/kit-regions-icon.svg',
  '/assets/svg/layers.svg',
  '/assets/svg/left-arrow.svg',
  '/assets/svg/less-Info.svg',
  '/assets/svg/link.svg',
  '/assets/svg/load.svg',
  '/assets/svg/location-pin.svg',
  '/assets/svg/location.svg',
  '/assets/svg/lock.svg',
  '/assets/svg/mail-envelope.svg',
  '/assets/svg/male-copy.svg',
  '/assets/svg/male-dark-blue.svg',
  '/assets/svg/male-full.svg',
  '/assets/svg/male-illustration.svg',
  '/assets/svg/male-rounded.svg',
  '/assets/svg/male.svg',
  '/assets/svg/manage-icon-1.svg',
  '/assets/svg/manage-icon.svg',
  '/assets/svg/mastermind.svg',
  '/assets/svg/matchmaker.svg',
  '/assets/svg/minimize.svg',
  '/assets/svg/missing.svg',
  '/assets/svg/more-actions-blue.svg',
  '/assets/svg/more-actions-small.svg',
  '/assets/svg/more-actions.svg',
  '/assets/svg/more-info.svg',
  '/assets/svg/move.svg',
  '/assets/svg/my-var-icon.svg',
  '/assets/svg/my-variant.svg',
  '/assets/svg/my-variants.svg',
  '/assets/svg/negative-black.svg',
  '/assets/svg/negative.svg',
  '/assets/svg/neutral.svg',
  '/assets/svg/new-icon.svg',
  '/assets/svg/no-gender-rounded.svg',
  '/assets/svg/no-results.svg',
  '/assets/svg/no-samples.svg',
  '/assets/svg/no-variants-image.svg',
  '/assets/svg/not-relevant-blue.svg',
  '/assets/svg/not-relevant-red.svg',
  '/assets/svg/not-relevant-small.svg',
  '/assets/svg/not-relevant.svg',
  '/assets/svg/not-targeted.svg',
  '/assets/svg/note-blue.svg',
  '/assets/svg/note-icon.svg',
  '/assets/svg/nr-copy-3.svg',
  '/assets/svg/nr.svg',
  '/assets/svg/ok.svg',
  '/assets/svg/open-in-new-tab.svg',
  '/assets/svg/outcome.svg',
  '/assets/svg/oval-2.svg',
  '/assets/svg/oval-blue.svg',
  '/assets/svg/Oval.svg',
  '/assets/svg/overgrowth.svg',
  '/assets/svg/overlap.svg',
  '/assets/svg/panels/genes-list-empty-state.svg',
  '/assets/svg/panels/germline-panel.svg',
  '/assets/svg/panels/knowledge-base-section-icon.svg',
  '/assets/svg/panels/manage-icon.svg',
  '/assets/svg/panels/public-panel-blue-background.svg',
  '/assets/svg/panels/public-panel.svg',
  '/assets/svg/panels/remove-white.svg',
  '/assets/svg/panels/share-icon.svg',
  '/assets/svg/panels/somatic-panel-big.svg',
  '/assets/svg/panels/somatic-panel.svg',
  '/assets/svg/panels.svg',
  '/assets/svg/paperplane-blue.svg',
  '/assets/svg/patient-privacy.svg',
  '/assets/svg/paw-with-bg.svg',
  '/assets/svg/pdf.svg',
  '/assets/svg/ped-tree-female.svg',
  '/assets/svg/ped-tree-male.svg',
  '/assets/svg/pedigree-app.svg',
  '/assets/svg/pencil.svg',
  '/assets/svg/pending.svg',
  '/assets/svg/pie.svg',
  '/assets/svg/pill-icon.svg',
  '/assets/svg/pin-blue.svg',
  '/assets/svg/pin.svg',
  '/assets/svg/pipelineSettings.svg',
  '/assets/svg/planets.svg',
  '/assets/svg/play.svg',
  '/assets/svg/plus.svg',
  '/assets/svg/positive-black.svg',
  '/assets/svg/positive.svg',
  '/assets/svg/pregnant-app.svg',
  '/assets/svg/privacy-private.svg',
  '/assets/svg/privacy-team.svg',
  '/assets/svg/private-panel.svg',
  '/assets/svg/processing.svg',
  '/assets/svg/prognostic-icon-copy-13.svg',
  '/assets/svg/prognostic-icon-copy-2.svg',
  '/assets/svg/prognostic-icon.svg',
  '/assets/svg/public-panel-blue.svg',
  '/assets/svg/public-panel.svg',
  '/assets/svg/public.svg',
  '/assets/svg/publications-blue.svg',
  '/assets/svg/publications-hover.svg',
  '/assets/svg/publications-icon.svg',
  '/assets/svg/publications.svg',
  '/assets/svg/question-circle-solid.svg',
  '/assets/svg/quote.svg',
  '/assets/svg/radio-empty-hover.svg',
  '/assets/svg/radio-empty-idle.svg',
  '/assets/svg/radio-hover.svg',
  '/assets/svg/radio-pressed.svg',
  '/assets/svg/radio-selected-hover.svg',
  '/assets/svg/radio-selected-idle.svg',
  '/assets/svg/recommendations.svg',
  '/assets/svg/regenerate.svg',
  '/assets/svg/relevant-blue.svg',
  '/assets/svg/relevant-circle.svg',
  '/assets/svg/relevant-green.svg',
  '/assets/svg/relevant-small.svg',
  '/assets/svg/relevant.svg',
  '/assets/svg/report-active.svg',
  '/assets/svg/report-idle.svg',
  '/assets/svg/report.svg',
  '/assets/svg/reports.svg',
  '/assets/svg/resistance-black.svg',
  '/assets/svg/resistance.svg',
  '/assets/svg/responsive-black.svg',
  '/assets/svg/responsive.svg',
  '/assets/svg/revert-to-default.svg',
  '/assets/svg/revert.svg',
  '/assets/svg/ribbon.svg',
  '/assets/svg/robot-icon-pressed.svg',
  '/assets/svg/robot-icon.svg',
  '/assets/svg/roh-segments.svg',
  '/assets/svg/round-v.svg',
  '/assets/svg/rule-met-v.svg',
  '/assets/svg/rule-unmet-x.svg',
  '/assets/svg/S3.svg',
  '/assets/svg/sample-icon-pressed.svg',
  '/assets/svg/sample-icon.svg',
  '/assets/svg/sample-per-folder-pressed.svg',
  '/assets/svg/sample-per-folder.svg',
  '/assets/svg/sample-sheets-case.svg',
  '/assets/svg/sampleSettings.svg',
  '/assets/svg/save-white.svg',
  '/assets/svg/save.svg',
  '/assets/svg/search-blue.svg',
  '/assets/svg/search-gray.svg',
  '/assets/svg/search-icon.svg',
  '/assets/svg/search-white.svg',
  '/assets/svg/search.svg',
  '/assets/svg/secure.svg',
  '/assets/svg/select-arrow-white.svg',
  '/assets/svg/select-arrow.svg',
  '/assets/svg/send.svg',
  '/assets/svg/sending.svg',
  '/assets/svg/sent.svg',
  '/assets/svg/sex-female.svg',
  '/assets/svg/sex-male.svg',
  '/assets/svg/sex-unknown.svg',
  '/assets/svg/share-copy.svg',
  '/assets/svg/share-it.svg',
  '/assets/svg/share.svg',
  '/assets/svg/SIG.svg',
  '/assets/svg/similar-hover.svg',
  '/assets/svg/similar.svg',
  '/assets/svg/single-app.svg',
  '/assets/svg/somatic-app.svg',
  '/assets/svg/sort-down.svg',
  '/assets/svg/sort.svg',
  '/assets/svg/stack.svg',
  '/assets/svg/star-copy.svg',
  '/assets/svg/star-empty.svg',
  '/assets/svg/star-full.svg',
  '/assets/svg/star-idle.svg',
  '/assets/svg/star-with-bg.svg',
  '/assets/svg/star-yellow.svg',
  '/assets/svg/star.svg',
  '/assets/svg/stethoscope.svg',
  '/assets/svg/subject-cancer.svg',
  '/assets/svg/subject-family.svg',
  '/assets/svg/subject-female.svg',
  '/assets/svg/subject-male.svg',
  '/assets/svg/subject-pregnant.svg',
  '/assets/svg/subject-tumor.svg',
  '/assets/svg/submint-clinvar.svg',
  '/assets/svg/suggested.svg',
  '/assets/svg/summary-report.svg',
  '/assets/svg/support.svg',
  '/assets/svg/tail-arrow-black.svg',
  '/assets/svg/tail-arrow.svg',
  '/assets/svg/talk-icon.svg',
  '/assets/svg/target.svg',
  '/assets/svg/targeted.svg',
  '/assets/svg/tech-info.svg',
  '/assets/svg/test-info.svg',
  '/assets/svg/tetris.svg',
  '/assets/svg/tile-indicators/clinically-irrelevant.svg',
  '/assets/svg/tile-indicators/included-report.svg',
  '/assets/svg/tile-indicators/included-workbench.svg',
  '/assets/svg/tile-indicators/manually-added.svg',
  '/assets/svg/tile-indicators/orthogonal-testing.svg',
  '/assets/svg/tile-indicators/suspected-germline.svg',
  '/assets/svg/tiles-illu.svg',
  '/assets/svg/trash-can.svg',
  '/assets/svg/trash.svg',
  '/assets/svg/trials-hover.svg',
  '/assets/svg/trials.svg',
  '/assets/svg/triangle.svg',
  '/assets/svg/tube.svg',
  '/assets/svg/tumor-report-icon.svg',
  '/assets/svg/ufo.svg',
  '/assets/svg/unassigned-icon-idle.svg',
  '/assets/svg/unknown-black.svg',
  '/assets/svg/unknown-gender.svg',
  '/assets/svg/unknown.svg',
  '/assets/svg/up-vcf.svg',
  '/assets/svg/upload-files-icon.svg',
  '/assets/svg/upload-icon.svg',
  '/assets/svg/upload-white.svg',
  '/assets/svg/upload.svg',
  '/assets/svg/uploading.svg',
  '/assets/svg/user-avatar-blue.svg',
  '/assets/svg/v-green.svg',
  '/assets/svg/v-red.svg',
  '/assets/svg/V.svg',
  '/assets/svg/variant-details1.svg',
  '/assets/svg/variant-not-relevant.svg',
  '/assets/svg/variants-app.svg',
  '/assets/svg/variants.svg',
  '/assets/svg/vcf-analysis.svg',
  '/assets/svg/vcf-done.svg',
  '/assets/svg/vcf-file.svg',
  '/assets/svg/vcf.svg',
  '/assets/svg/VV.svg',
  '/assets/svg/warning.svg',
  '/assets/svg/white-back-arrow.svg',
  '/assets/svg/white-plus.svg',
  '/assets/svg/whole-exome.svg',
  '/assets/svg/whole-genome.svg',
  '/assets/svg/workbench-separator.svg',
  '/assets/svg/workbench-tile-icon.svg',
  '/assets/svg/world.svg',
  '/assets/svg/x-black-turned.svg',
  '/assets/svg/x-black.svg',
  '/assets/svg/x-blue-circle.svg',
  '/assets/svg/x-blue.svg',
  '/assets/svg/x-icon.svg',
  '/assets/svg/x-white-medium.svg',
  '/assets/svg/x-white.svg',
  '/assets/svg/xls.svg',
  '/assets/svg/zygosity.svg',
];
