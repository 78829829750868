export interface SigClassifiedVariant {
  var_id: string;
  gene: string;
  transcript: string;
  hgvs_c: string;
  snp_variant: {
    var_id: string;
    chromosome: string;
    start_pos: number;
    end_pos: number;
    reference: string;
    alternative: string;
    variation_type: string;
  };
  sv_variant?: {
    var_id: string;
    chromosome: string;
    start_pos: number;
    end_pos: number;
    variation_type: string;
  };
  assessment: string;
  conditions: string[];
  num_of_submissions: number;
  last_updated: string;
  conflicting_assessments: any[];
  submitter_orgs: string[];
  hgvs_p: string;
  impact: string;
  region: string;
  sv_genes: any[];
  submitted_by: string[];
}

export enum VariantCategoryTab {
  classified,
  suggested,
}
