import { Directive, HostBinding, Input } from '@angular/core';
import { FlexDirective } from './flex.directive';

@Directive({
  selector: '[gnxFlexItem]',
  standalone: true,
})
export class FlexItemDirective extends FlexDirective {
  @HostBinding('style.flex') @Input() flex: string;
  @HostBinding('style.min-width') @Input() minWidth: string;
  @HostBinding('style.min-height') @Input() minHeight: string;
  @HostBinding('style.align-self') @Input() alignSelf: 'flex-start' | 'center' | 'flex-end' | 'stretch';
  @Input() displayFlex: boolean;

  @HostBinding('style.display')
  get displayStyle(): string {
    return this.displayFlex ? (this.inline ? 'inline-flex' : 'flex') : undefined;
  }
}
