import { Action } from '@ngrx/store';
import { VariantId } from '../../../variant-page/models';

export const INCLUDE_IN_REPORT = '[Interpretation] Include in report';

export class IncludeInReport implements Action {
  readonly type = INCLUDE_IN_REPORT;

  constructor(
    public value: boolean,
    public variantId: VariantId,
    public reportSectionUuid?: string,
    public compoundVariantId?: VariantId,
  ) {}
}

export const MARK_IRRELEVANT = '[Interpretation] Mark irrelevant';

export class MarkIrrelevant implements Action {
  readonly type = MARK_IRRELEVANT;

  constructor(
    public value: boolean,
    public variantId: VariantId,
    public openReasonDialog = true,
    public compoundVariantId?: VariantId,
  ) {}
}

export const FOLLOW_VARIANT = '[Interpretation] Follow variant';
export const FOLLOW_VARIANT_SUCCESS = '[Interpretation] Follow variant Success';
export const FOLLOW_VARIANT_FAIL = '[Interpretation] Follow variant Fail';

export class FollowVariant implements Action {
  readonly type = FOLLOW_VARIANT;

  constructor(
    public value: boolean,
    public variantId: VariantId,
  ) {}
}

export class FollowVariantSuccess implements Action {
  readonly type = FOLLOW_VARIANT_SUCCESS;

  constructor(public value: boolean) {}
}

export class FollowVariantFail implements Action {
  readonly type = FOLLOW_VARIANT_FAIL;

  constructor(public error: any) {}
}

export const INCLUDE_IN_WORKBENCH = '[Interpretation] Include in workbench';

export class IncludeInWorkbench implements Action {
  readonly type = INCLUDE_IN_WORKBENCH;

  constructor(
    public value: boolean,
    public variantId: VariantId,
    public compoundVariantId?: VariantId,
  ) {}
}

export type InterpretationHeaderAction =
  | IncludeInReport
  | MarkIrrelevant
  | FollowVariant
  | FollowVariantSuccess
  | FollowVariantFail
  | IncludeInWorkbench;
