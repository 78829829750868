export interface MultipartUpload {
  s3_paths: string[];
  origin_file_name: string;
  upload_id: string;
}

export interface SingleUpload {
  s3_path: string;
  origin_file_name: string;
}
