import { createAction, props } from '@ngrx/store';
import { CaseProtocol, CaseProtocolStep } from '../models';

export const getCaseProtocols = createAction('[Analysis] Get case protocols', props<{ analysisId: number }>());

export const getCaseProtocolsSuccess = createAction(
  '[Analysis] Get case protocols success',
  props<{ analysisId: number; data: CaseProtocol[] }>(),
);

export const getCaseProtocolsFail = createAction(
  '[Analysis] Get case protocols fail',
  props<{ analysisId: number; error: any }>(),
);

export const updateCaseProtocols = createAction(
  '[Analysis] Update case protocols',
  props<{ analysisId: number; protocols: CaseProtocol[] }>(),
);

export const clearCaseProtocols = createAction('[Analysis] Clear case protocols');

export const getProtocolStepOptions = createAction('[Analysis] Get protocol step options');

export const getProtocolStepOptionsSuccess = createAction(
  '[Analysis] Get protocol step options success',
  props<{ data: CaseProtocolStep[] }>(),
);

export const getProtocolStepOptionsFail = createAction(
  '[Analysis] Get protocol step options fail',
  props<{ error: any }>(),
);
