import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgIf } from '@angular/common';
import { CheckboxComponent } from '../checkbox/checkbox.component';

@Component({
  selector: 'gnx-smart-checkbox',
  templateUrl: './smart-checkbox.component.html',
  styleUrls: ['./smart-checkbox.component.scss'],
  standalone: true,
  imports: [CheckboxComponent, NgIf],
})
export class SmartCheckboxComponent<Type> implements OnInit {
  @Input() set: Set<Type>;
  @Input() list: Type[];
  @Input() emptyLabel: string;
  @Input() itemName = 'variant';
  @Input() disabled: boolean;
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}
}
