import { Component, HostListener, OnInit } from '@angular/core';

import { svgIcons } from '../../../../assets/scripts/icons-svg';
import { pngIcons } from '../../../../assets/scripts/icons-png';
import { gifIcons } from '../../../../assets/scripts/icons-gif';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IconDetailsDialogComponent } from './icon-details-dialog/icon-details-dialog.component';
import { MatTooltip } from '@angular/material/tooltip';
import { NgFor, NgClass, NgStyle } from '@angular/common';

@Component({
  selector: 'gnx-icons-directory',
  templateUrl: './icons-directory.component.html',
  styleUrls: ['./icons-directory.component.scss'],
  standalone: true,
  imports: [NgFor, NgClass, NgStyle, MatTooltip],
})
export class IconsDirectoryComponent implements OnInit {
  public formats = ['svg', 'png', 'gif'];
  public svgIcons = svgIcons;
  public pngIcons = pngIcons;
  public gifIcons = gifIcons;
  activeSection = this.formats[0];
  colors = ['#d8d8d8', 'black', 'white'];
  activeColor = this.colors[0];
  iconDialog: MatDialogRef<IconDetailsDialogComponent>;

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.code === 'KeyX' && event.shiftKey) {
      this.nextColor();
    }
  }

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  nextColor(color?) {
    if (color) {
      this.activeColor = color;
    } else {
      const index = this.colors.findIndex((item) => item === this.activeColor);
      if (index + 1 >= this.colors.length) {
        this.activeColor = this.colors[0];
      } else {
        this.activeColor = this.colors[index + 1];
      }
    }
    if (this.iconDialog?.componentInstance) {
      this.iconDialog.componentInstance.changeColor(this.activeColor);
    }
  }

  showIconInfo(icon) {
    this.iconDialog = this.dialog.open(IconDetailsDialogComponent, {
      width: '840px ',
      backdropClass: 'popup-backdrop',
      panelClass: 'popup-panel',
      hasBackdrop: true,
      data: {
        path: icon,
      },
    });
  }

  get activeIcons() {
    return this[`${this.activeSection}Icons`];
  }
}
