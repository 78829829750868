import * as fromReducer from '../reducers/referral-notes.reducer';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { getWorkbenchModuleState, WorkbenchModuleState } from '../reducers';
import { ReferralNotes } from '../models';

const getReferralNotesState: MemoizedSelector<WorkbenchModuleState, fromReducer.ReferralNotesState> = createSelector(
  getWorkbenchModuleState,
  (state: WorkbenchModuleState) => state.referralNotes,
);

export const getReferralNotes: MemoizedSelector<WorkbenchModuleState, ReferralNotes> = createSelector(
  getReferralNotesState,
  fromReducer.getReferralNotes,
);

export const getReferralNotesLoaded: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getReferralNotesState,
  fromReducer.getReferralNotesLoaded,
);

export const getReferralNotesLoading: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getReferralNotesState,
  fromReducer.getReferralNotesLoading,
);
