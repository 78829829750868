import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './home-page/containers/home-page/home-page.component';
import { SettingsPageComponent } from './modules/settings-page/containers/settings-page/settings-page.component';
import { LoginGuard } from './modules/auth0/guards/login.guard';
import { FeatureBit } from './modules/auth0/models/feature-bits.model';
import { FeatureBitsGuard } from './modules/auth0/guards/feature-bits.guard';
import { CitationsPageComponent } from './home-page/containers/citations-page/citations-page.component';
import { SIGGuard } from './modules/auth0/guards/sig.guard';
import { HomePageInitParamsResolver } from './home-page/containers/home-page/home-page-init-params.resolver';
import { AboutFranklinComponent } from './static-pages/about-franklin/about-franklin.component';
import { IconsDirectoryComponent } from './dev-module/modules/icons-directory/icons-directory.component';
import { environment } from '../environments/environment';

let devRoutes: Routes = [];
if (!environment.production) {
  devRoutes = [
    {
      path: 'icons',
      component: IconsDirectoryComponent,
    },
  ];
}
/*
 * Notes:
 * - No need to use LoginGuard alongside FeatureBit, since FeatureBit calls LoginGuard before validating the feature bits
 * */
const routes: Routes = [
  {
    path: 'analysis-tool',
    loadChildren: () => import('./modules/analysis/analysis.module').then((m) => m.AnalysisModule),
  },
  {
    path: 'clinical-db',
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'home' },
      { path: 'home', component: HomePageComponent, resolve: { params: HomePageInitParamsResolver } },
      {
        path: 'variant/:variantType/:variantId',
        loadChildren: () => import('./modules/variant-page/variant-page.module').then((m) => m.VariantPageModule),
      },
      {
        path: 'variant/:variantId',
        redirectTo: 'variant/snp/:variantId',
      },
      {
        path: 'gene/:referenceVersion/:geneSymbol',
        loadChildren: () => import('./modules/gene-page/gene-page.module').then((m) => m.GenePageModule),
      },
      {
        path: 'gene/:geneSymbol',
        redirectTo: 'gene/hg19/:geneSymbol',
      },
      {
        path: 'knowledge-base',
        loadChildren: () => import('./knowledge-base/knowledge-base.module').then((m) => m.KnowledgeBaseModule),
      },
      {
        path: 'discovery',
        loadChildren: () => import('./modules/discovery/discovery.module').then((m) => m.DiscoveryModule),
        canActivate: [FeatureBitsGuard],
        data: { featureBit: FeatureBit.Discovery },
      },
      {
        path: 'assets',
        loadChildren: () => import('./modules/assets-page/assets-page.module').then((m) => m.AssetsPageModule),
      },
      {
        path: 'sig/:sigId',
        loadChildren: () => import('./modules/sig/sig.module').then((m) => m.SigModule),
        canActivate: [SIGGuard],
      },
    ],
  },
  {
    path: 'variant/:variantType/:variantId',
    pathMatch: 'full',
    redirectTo: 'clinical-db/variant/:variantType/:variantId',
  },
  { path: 'variant/:variantId', pathMatch: 'full', redirectTo: 'clinical-db/variant/snp/:variantId' },
  { path: 'my-variants', pathMatch: 'full', redirectTo: 'clinical-db/knowledge-base/variants' },
  {
    path: 'settings',
    component: SettingsPageComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'credit-report',
    loadChildren: () => import('./modules/credit-report/credit-report.module').then((m) => m.CreditReportModule),
    canActivate: [LoginGuard, FeatureBitsGuard],
    data: { featureBitOrList: [FeatureBit.CreditReport] },
  },
  {
    path: 'post-join',
    loadChildren: () => import('./post-join-page/post-join-page.module').then((m) => m.PostJoinPageModule),
  },
  {
    path: 'join',
    loadChildren: () => import('./join-page/join-page.module').then((m) => m.JoinPageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./login-page/login-page.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'reset',
    loadChildren: () => import('./reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
  },
  {
    path: 'samples',
    loadChildren: () => import('./modules/samples/samples.module').then((m) => m.SamplesModule),
    canActivate: [FeatureBitsGuard],
    data: { featureBitOrList: [FeatureBit.Assets, FeatureBit.Samples] },
  },
  {
    path: 'variant',
    loadChildren: () => import('./modules/variant-page/variant-page.module').then((m) => m.VariantPageModule),
    outlet: 'dialog',
  },
  {
    path: 'new-case',
    loadChildren: () => import('./modules/case-dialogs/case-dialogs.module').then((m) => m.CaseDialogsModule),
    outlet: 'dialog',
  },
  {
    path: 'edit-case',
    loadChildren: () => import('./modules/case-dialogs/case-dialogs.module').then((m) => m.CaseDialogsModule),
    outlet: 'dialog',
  },
  {
    path: 'citations',
    component: CitationsPageComponent,
  },
  { path: 'about', pathMatch: 'full', redirectTo: 'about-franklin' },
  {
    path: 'about-franklin',
    component: AboutFranklinComponent,
  },
  {
    path: 'gene',
    loadChildren: () => import('./modules/gene-page/gene-page.module').then((m) => m.GenePageModule),
    outlet: 'dialog',
  },
  {
    path: 'whole-genome',
    loadChildren: () =>
      import('./modules/whole-genome-page/whole-genome-page.module').then((m) => m.WholeGenomePageModule),
    outlet: 'dialog',
  },
  ...devRoutes,
  { path: '', pathMatch: 'full', redirectTo: 'clinical-db' },
  { path: '**', redirectTo: 'clinical-db' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
