import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface EnvRedirectData {
  redirectHost: string;
}

@Component({
  selector: 'gnx-env-redirect-popup',
  templateUrl: './env-redirect-popup.component.html',
  styleUrls: ['./env-redirect-popup.component.scss'],
  standalone: true,
})
export class EnvRedirectPopupComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<EnvRedirectPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EnvRedirectData,
  ) {}

  ngOnInit(): void {}

  onClose(): void {
    this.dialogRef.close();
  }

  get redirectLink(): string {
    return `${window.location.protocol}//${this.data.redirectHost}${window.location.pathname}`;
  }

  get currentHost(): string {
    return window?.location?.host;
  }
}
