import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { TextComponent } from '../text/text.component';
import { ClickCursorDirective } from '../../directives/click-cursor.directive';
import { MatTooltip } from '@angular/material/tooltip';
import { FlexItemDirective } from '../../directives/flex-item.directive';
import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { BlockDirective } from '../../directives/block.directive';
import { MatTabLink, MatTabNav, MatTabNavPanel } from '@angular/material/tabs';

export interface LabelIconTab<k> {
  icon: string;
  label: string;
  value: k;
  disabledTooltip?: string;
  disabled?: boolean;
}

export interface TemplateTab<k> {
  value: k;
  template: TemplateRef<any>;
  disabledTooltip?: string;
  disabled?: boolean;
}

@Component({
  selector: 'gnx-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatTabNav,
    BlockDirective,
    NgFor,
    FlexItemDirective,
    MatTabLink,
    MatTooltip,
    ClickCursorDirective,
    NgIf,
    TextComponent,
    NgTemplateOutlet,
    MatTabNavPanel,
  ],
})
export class TabsComponent<k extends keyof T, T> implements OnInit {
  @Input() tabs: (LabelIconTab<k> | TemplateTab<k>)[];
  @Input() activeValue: T;
  @Input() tabWidth: string;
  @Output() tabSelect: EventEmitter<T> = new EventEmitter<T>();

  constructor() {}

  ngOnInit(): void {}
}
