import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { LoginGuard } from './login.guard';
import { filter, map, take } from 'rxjs/operators';
import { AuthModuleState } from '../store/reducers';
import { select, Store } from '@ngrx/store';
import { getFeatureBits } from '../store/selectors';
import { AppModuleState } from '../../../store/reducers';
import { getPreviousRouteUrl } from '../../../store/selectors';

@Injectable({
  providedIn: 'root',
})
export class FeatureBitsGuard {
  constructor(
    private loginGuard: LoginGuard,
    private authStore$: Store<AuthModuleState>,
    private router: Router,
    private rootStore: Store<AppModuleState>,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return combineLatest([
      this.authStore$.pipe(select(getFeatureBits)),
      this.rootStore.pipe(select(getPreviousRouteUrl)),
      this.loginGuard.canActivate(route, state) as Observable<any>,
    ]).pipe(
      filter(([featureBits]) => !!featureBits),
      map(([featureBits, prevUrl, loggedIn]) => {
        let value = false;
        if (route.data.featureBitOrList) {
          value = loggedIn ? route.data.featureBitOrList.some((fb) => featureBits?.[fb]) : false;
        } else {
          value = loggedIn ? featureBits[route.data?.featureBit] : false;
        }

        if (!value && !prevUrl) {
          route.data.redirectUrl
            ? this.router.navigate([route.data.redirectUrl])
            : this.router.navigate(['/clinical-db']);
        }

        return value;
      }),
      take(1),
    );
  }
}
