export enum VariantType {
  SNP = 'snp',
  SV = 'sv',
  Compound = 'compound',
  All = 'all',
  LOH = 'loh',
  ROH = 'roh',
  RNA = 'rna',
  Fusion = 'fusion',
}
