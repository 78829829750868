import { Action } from '@ngrx/store';
import * as fromAction from '../actions/variant-evidences-selection.action';
import { VariantEvidenceOptionModel, VariantEvidenceValueModel } from '../models/variant-evidences-selection.nodel';
import { VariantEvidencesAction } from '../actions/variant-evidences-selection.action';

export interface VariantEvidencesOptionsState {
  loading: boolean;
  loaded: boolean;
  variantEvidenceOptionsValues?: VariantEvidenceOptionModel[];
}

export interface VariantEvidencesValueState {
  variantEvidenceValues: VariantEvidenceValueModel[];
  details: string;
}

export interface VariantEvidencesSelectionState {
  variantEvidencesOptions: VariantEvidencesOptionsState;
  variantEvidenceValue?: VariantEvidencesValueState;
}

export const initialState: VariantEvidencesSelectionState = {
  variantEvidencesOptions: {
    loaded: false,
    loading: false,
    variantEvidenceOptionsValues: [],
  },
};

export function reducer(state = initialState, action: VariantEvidencesAction): VariantEvidencesSelectionState {
  switch (action.type) {
    case fromAction.GET_VARIANT_EVIDENCES_OPTIONS: {
      return {
        ...state,
        variantEvidencesOptions: {
          loaded: false,
          loading: true,
        },
      };
    }
    case fromAction.GET_VARIANT_EVIDENCES_OPTIONS_SUCCESS: {
      return {
        ...state,
        variantEvidencesOptions: {
          loaded: true,
          loading: false,
          variantEvidenceOptionsValues: action.payload?.categories,
        },
      };
    }
    case fromAction.GET_VARIANT_EVIDENCES_OPTIONS_FAIL: {
      return {
        ...state,
        variantEvidencesOptions: {
          loaded: false,
          loading: false,
        },
      };
    }

    case fromAction.SET_VARIANT_EVIDENCES_VALUES: {
      return {
        ...state,
        variantEvidenceValue: action.payload,
      };
    }
    default:
      return state;
  }
}
