import { EnrichmentKit } from '../../modules/analysis/modules/analyses/store/models';
import { EnrichmentKitsAction } from '../actions';

import * as fromAction from './../actions/enrichment-kits.action';

export interface EnrichmentKitsState {
  data: EnrichmentKit[];
  loading: boolean;
  loaded: boolean;
}

const initialState: EnrichmentKitsState = {
  data: [],
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action: EnrichmentKitsAction): EnrichmentKitsState {
  switch (action.type) {
    case fromAction.GET_ENRICHMENT_KIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromAction.GET_ENRICHMENT_KIT_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
      };
    }
    case fromAction.GET_ENRICHMENT_KIT_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }

  return state;
}

export const getEnrichmentKitsLoaded = (state: EnrichmentKitsState) => state.loaded;
export const getEnrichmentKitsLoading = (state: EnrichmentKitsState) => state.loading;
export const getEnrichmentKits = (state: EnrichmentKitsState) => state.data;
