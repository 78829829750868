import { CuratedEvidenceData } from '../models/curation/curated-evidence.model';

export const CREATE_EVIDENCE = '[Publication - Curation] Insert Evidence';
export const CREATE_EVIDENCE_SUCCESS = '[Publication - Curation] Insert Evidence Success';
export const CREATE_EVIDENCE_FAIL = '[Publication - Curation] Insert Evidence Failure';
export const CLEAR_CURATION_DATA = '[Publication - Curation] Insert Evidence Reset';

export class CreateEvidence {
  readonly type = CREATE_EVIDENCE;

  constructor(public evidenceData: CuratedEvidenceData) {}
}

export class CreateEvidenceSuccess {
  readonly type = CREATE_EVIDENCE_SUCCESS;

  constructor() {}
}

export class CreateEvidenceFailure {
  readonly type = CREATE_EVIDENCE_FAIL;

  constructor() {}
}

export class ClearCurationData {
  readonly type = CLEAR_CURATION_DATA;

  constructor() {}
}

export type CurationAction = CreateEvidence | CreateEvidenceSuccess | CreateEvidenceFailure | ClearCurationData;
