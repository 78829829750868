import { DataStateModel } from '../../shared/models/data-state.model';
import { Phenotype } from '../models';

import * as fromAction from './../actions/genes.action';

export interface GenesState {
  genes: DataStateModel<Phenotype[]>;
}

const initialState: GenesState = {
  genes: {
    data: [],
    loaded: false,
    loading: false,
  },
};

export function reducer(state = initialState, action: fromAction.GenesAction): GenesState {
  switch (action.type) {
    case fromAction.SEARCH_GENES: {
      return { ...state, genes: { ...state.genes, loading: true, loaded: false } };
    }
    case fromAction.SEARCH_GENES_SUCCESS: {
      return { ...state, genes: { data: action.suggestions, loading: false, loaded: true } };
    }
    case fromAction.SEARCH_GENES_FAIL: {
      return { ...state, genes: { ...state.genes, loading: false, loaded: false } };
    }
    case fromAction.CLEAR_GENES: {
      return { ...initialState };
    }
  }

  return {
    ...state,
  };
}
