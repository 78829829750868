import {
  GET_CASE_PANELS,
  GET_CASE_PANELS_FAIL,
  GET_CASE_PANELS_SUCCESS,
  GET_KB_PANELS_SUCCESS,
  GET_PANEL_GENES_SUCCESS,
  GET_PANELS,
  GET_PANELS_FAIL,
  GET_PANELS_SUCCESS,
  GET_PANELS_WITH_ALL_VERSIONS,
  GET_PANELS_WITH_ALL_VERSIONS_FAIL,
  GET_PANELS_WITH_ALL_VERSIONS_SUCCESS,
  PanelsAction,
  REMOVE_CASE_PANEL,
} from '../actions';
import { PanelSelectionItem, PanelSuggestion } from '../models';
import { PanelData } from '../../knowledge-base/modules/panels/store';
import { PanelLocation } from '../../modules/variant-page/modules/variant-page/models';

export interface PanelsState {
  panels: PanelSuggestion[];
  panelSuggestions?: PanelSelectionItem[];
  casePanelSuggestions?: PanelSelectionItem[];
  allPanelsWithVersions?: PanelSelectionItem[];
  genes: { [id: number]: any };
  loading: boolean;
  loaded: boolean;
  casePanelLoading: boolean;
  casePanelLoaded: boolean;
  allPanelsWithVersionsLoading: boolean;
  allPanelsWithVersionsLoaded: boolean;
  error?: any;
}

const panelsInitialState: PanelsState = {
  panels: [],
  panelSuggestions: [],
  casePanelSuggestions: [],
  allPanelsWithVersions: [],
  genes: {},
  loading: false,
  loaded: false,
  casePanelLoading: false,
  casePanelLoaded: false,
  allPanelsWithVersionsLoading: false,
  allPanelsWithVersionsLoaded: false,
};

export function panelsReducer(state = panelsInitialState, action: PanelsAction): PanelsState {
  switch (action.type) {
    case GET_PANELS: {
      return {
        ...state,
        panels: [],
        loading: true,
        loaded: false,
        error: undefined,
      };
    }
    case GET_PANELS_SUCCESS: {
      const panelSuggestions: PanelSelectionItem[] = action.payload.map((item) => {
        return {
          id: item.id,
          name: item.name,
          description: item.description,
          num_genes: item.num_genes,
          location: item.location,
          location_desc: item.location_desc,
          uuid: item.uuid,
          version: item.version,
        };
      });
      return {
        ...state,
        panels: action.payload?.length || !action.searchTerm ? action.payload : [],
        panelSuggestions: [...panelSuggestions],
        loading: false,
        loaded: true,
      };
    }
    case GET_KB_PANELS_SUCCESS: {
      const panelSuggestions: PanelSelectionItem[] = action.payload.map(panelDataToSelectionItem);
      return {
        ...state,
        panelSuggestions,
        loading: false,
        loaded: true,
      };
    }
    case GET_PANELS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    }
    case GET_PANEL_GENES_SUCCESS: {
      return {
        ...state,
        genes: {
          ...state.genes,
          [action.panelId]: action.payload.map((gene) => gene.gene_symbol),
        },
      };
    }
    case GET_CASE_PANELS: {
      return {
        ...state,
        casePanelSuggestions: [],
        casePanelLoading: true,
        casePanelLoaded: false,
      };
    }
    case GET_CASE_PANELS_SUCCESS: {
      const panelSuggestions: PanelSelectionItem[] = action.payload.map(panelDataToSelectionItem);

      return {
        ...state,
        casePanelSuggestions: panelSuggestions,
        casePanelLoading: false,
        casePanelLoaded: true,
      };
    }
    case GET_CASE_PANELS_FAIL: {
      return {
        ...state,
        casePanelLoaded: false,
        casePanelLoading: false,
      };
    }
    case REMOVE_CASE_PANEL: {
      return {
        ...state,
        casePanelSuggestions: [...state.casePanelSuggestions].filter((p) => p.id !== action.panelId),
      };
    }
    case GET_PANELS_WITH_ALL_VERSIONS: {
      return {
        ...state,
        allPanelsWithVersions: [],
        allPanelsWithVersionsLoading: true,
        allPanelsWithVersionsLoaded: false,
      };
    }
    case GET_PANELS_WITH_ALL_VERSIONS_SUCCESS: {
      return {
        ...state,
        allPanelsWithVersions: action.payload.map(panelDataToSelectionItem),
        allPanelsWithVersionsLoading: false,
        allPanelsWithVersionsLoaded: true,
      };
    }
    case GET_PANELS_WITH_ALL_VERSIONS_FAIL: {
      return {
        ...state,
        allPanelsWithVersionsLoading: false,
        allPanelsWithVersionsLoaded: false,
      };
    }
  }
  return state;
}

const panelDataToSelectionItem = (item: PanelData): PanelSelectionItem => {
  return {
    id: item.panel_id,
    name: item.name,
    description: item.description,
    num_genes: item.num_genes,
    location: item.assay_panel ? 3 : item.public ? PanelLocation.Public : PanelLocation.Private,
    input_type: item.input_type,
    assay_panel: item.assay_panel,
    latest_version_panel_id: item.latest_version_panel_id,
    version: item.version,
    public: item.public,
  };
};

export const getPanels = (state: PanelsState) => state.panels;
export const getPanelSuggestions = (state: PanelsState) => state.panelSuggestions;
export const getPanelGenes = (state: PanelsState, props: { panelId: string }) => state.genes[props.panelId];
export const getPanelsLoading = (state: PanelsState) => state.loading;
export const getPanelsLoaded = (state: PanelsState) => state.loaded;
export const getPanelsError = (state: PanelsState) => state.error;
