import { Component, Inject, OnInit } from '@angular/core';
import { AnalysisResult, VariantType } from '../../../analysis-variant/models';
import { MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { AsyncPipe, NgFor } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClickCursorDirective } from '../../../../../../shared/directives/click-cursor.directive';
import { addToVariantLists, loadVariantLists, VariantList, WorkbenchModuleState } from '../../store';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  getVariantLists,
  getVariantListsErrorLoading,
  getVariantListsLoaded,
  getVariantListsLoading,
} from '../../store/selectors/variant-list.selector';
import { BlockDirective } from '../../../../../../shared/directives/block.directive';
import { FlexDirective } from '../../../../../../shared/directives/flex.directive';
import { CheckboxComponent } from '../../../../../../shared/components/checkbox/checkbox.component';
import { TextDirective } from '../../../../../../shared/directives/text.directive';
import { DotsLoaderComponent } from '../../../../../../shared/components/dots-loader/dots-loader.component';
import { AnalysisType } from '../../../../../../store';
import { TextOverflowEllipsisDirective } from '../../../../../../shared/directives/text-overflow-ellipsis.directive';
import { MatTooltip } from '@angular/material/tooltip';

export interface AddToVariantListsDialogData {
  analysisId: number;
  analysisType: AnalysisType;
  variantType: VariantType.SNP | VariantType.SV;
  variants: AnalysisResult[];
  referenceVersion: 'HG19' | 'HG38';
}

@Component({
  selector: 'gnx-add-to-variant-lists-dialog',
  standalone: true,
  imports: [
    ClickCursorDirective,
    MatDialogContent,
    ReactiveFormsModule,
    MatSelect,
    NgFor,
    MatOption,
    MatDialogActions,
    MatDialogClose,
    AsyncPipe,
    BlockDirective,
    FlexDirective,
    CheckboxComponent,
    TextDirective,
    DotsLoaderComponent,
    TextOverflowEllipsisDirective,
    MatTooltip,
  ],
  templateUrl: './add-to-variant-lists-dialog.component.html',
  styleUrl: './add-to-variant-lists-dialog.component.scss',
})
export class AddToVariantListsDialogComponent implements OnInit {
  lists$: Observable<VariantList[]>;
  loading$: Observable<boolean>;
  loaded$: Observable<boolean>;
  error$: Observable<any>;

  selected: { [id: string]: boolean } = {};
  notRelevant: boolean;
  addToWb: boolean;

  constructor(
    private dialogRef: MatDialogRef<AddToVariantListsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddToVariantListsDialogData,
    private store$: Store<WorkbenchModuleState>,
  ) {}

  ngOnInit(): void {
    this.store$.dispatch(
      loadVariantLists({
        analysisId: this.data.analysisId,
        analysisType: this.data.analysisType === AnalysisType.tumor ? AnalysisType.tumor : AnalysisType.sample,
        variantType: this.data.variantType,
        referenceVersion: this.data.referenceVersion,
      }),
    );
    this.lists$ = this.store$.pipe(select(getVariantLists));
    this.loading$ = this.store$.pipe(select(getVariantListsLoading));
    this.loaded$ = this.store$.pipe(select(getVariantListsLoaded));
    this.error$ = this.store$.pipe(select(getVariantListsErrorLoading));
  }

  onClose(): void {
    this.dialogRef.close();
  }

  get hasChanges(): boolean {
    return Object.values(this.selected).some((selected) => selected);
  }

  onSave(): void {
    this.store$.dispatch(
      addToVariantLists({
        analysisId: this.data.analysisId,
        referenceVersion: this.data.referenceVersion,
        variantType: this.data.variantType,
        variants: this.data.variants,
        variantLists: Object.keys(this.selected).filter((id) => this.selected[id]),
        analysisType: this.data.analysisType === AnalysisType.tumor ? AnalysisType.tumor : AnalysisType.sample,
        addToWorkbench: !!this.addToWb,
        markNotRelevant: !!this.notRelevant,
      }),
    );

    this.onClose();
  }
}
