import { GET_USER_VARIANTS_COUNT_SUCCESS, VariantsCountAction } from '../actions';

export interface VariantsCountState {
  count: number;
}

export const initialState: VariantsCountState = {
  count: 0,
};

export const reducer = (state = initialState, action: VariantsCountAction): VariantsCountState => {
  switch (action.type) {
    case GET_USER_VARIANTS_COUNT_SUCCESS: {
      return {
        count: action.count,
      };
    }
  }
  return state;
};

export const getUserVariantsCount = (state: VariantsCountState) => state.count;
