import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import * as fromAction from './../actions/config.action';
import { WorkbenchService } from '../services/workbench.service';

@Injectable()
export class ConfigEffect {
  constructor(
    private actions$: Actions,
    private service: WorkbenchService,
  ) {}

  loadConfig$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_WORKBENCH_CONFIG),
      switchMap((action: fromAction.LoadWorkbenchConfig) =>
        this.service.getConfig(action.analysisId).pipe(
          switchMap((result) => [new fromAction.LoadWorkbenchConfigSuccess(action.analysisId, result)]),
          catchError((err) => of(new fromAction.LoadWorkbenchConfigFail(action.analysisId, err))),
        ),
      ),
    ),
  );
}
