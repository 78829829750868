import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Breadcrumb } from '../../../store/models';
import { Router } from '@angular/router';
import { I18nPipe } from '../../i18n/i18n.pipe';
import { ClickCursorDirective } from '../../directives/click-cursor.directive';
import { NgFor } from '@angular/common';

@Component({
  selector: 'gnx-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  animations: [
    trigger('appear', [
      state('inOut', style({ maxWidth: '300px' })),
      transition(':leave', animate(1000, style({ maxWidth: 0, overflow: 'hidden' }))),
      transition(':enter', [style({ maxWidth: 0, overflow: 'hidden' }), animate(1000)]),
    ]),
  ],
  standalone: true,
  imports: [NgFor, ClickCursorDirective, I18nPipe],
})
export class BreadcrumbComponent implements OnChanges {
  @ViewChild('container') containerRef: ElementRef;

  @Input() crumbs: Breadcrumb[];

  intervalId: any;

  constructor(private router: Router) {}

  ngOnChanges() {
    this.intervalId = setInterval(this.checkOverflow.bind(this), 1000);
  }

  onClick(crumb: Breadcrumb) {
    if (crumb.route) {
      this.router.navigate([crumb.route], { queryParams: crumb.queryParams });
    }
    if (crumb.callback) {
      crumb.callback.apply(null, crumb.callbackParams);
    }
  }

  checkOverflow(): void {
    if (this.containerRef && this.containerRef.nativeElement) {
      if (this.containerRef.nativeElement.scrollWidth > this.containerRef.nativeElement.clientWidth) {
        this.crumbs.splice(0, 1);
      } else {
        clearInterval(this.intervalId);
      }
    }
  }

  trackByFunction(index, item: Breadcrumb) {
    return item.name;
  }
}
