import * as fromAction from './../actions/case-protocols.action';
import { createReducer, on } from '@ngrx/store';
import { CaseProtocol, CaseProtocolStep } from '../models';

export interface CaseProtocolsState {
  data?: CaseProtocol[];
  loading: boolean;
  loaded: boolean;
  stepOptions?: CaseProtocolStep[];
}

const initialState: CaseProtocolsState = {
  loading: false,
  loaded: false,
};

export const reducer = createReducer(
  initialState,
  on(fromAction.getCaseProtocols, (state) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(fromAction.getCaseProtocolsSuccess, (state, { analysisId, data }) => ({
    ...state,
    data,
    loading: false,
    loaded: true,
  })),
  on(fromAction.getCaseProtocolsFail, (state) => ({
    ...state,
    loading: false,
    loaded: false,
  })),
  on(fromAction.updateCaseProtocols, (state) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(fromAction.clearCaseProtocols, (state) => ({
    ...initialState,
  })),
  on(fromAction.getProtocolStepOptionsSuccess, (state, { data }) => ({
    ...state,
    stepOptions: data,
  })),
);
