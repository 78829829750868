/*
 * To update any classification color:
 *  1- update the relevant const below
 *  2- update the relevant variable in _classification-colors.scss
 *  */
export const CLASSIFICATION_COLOR_PATHOGENIC = '#e03a39';
export const CLASSIFICATION_COLOR_LIKELY_PATHOGENIC = '#f19a29';
export const CLASSIFICATION_COLOR_UNCERTAIN_PATHOGENIC = '#FACF34'; // possibly pathogenic
export const CLASSIFICATION_COLOR_UNCERTAIN = '#919fa5';
export const CLASSIFICATION_COLOR_UNCERTAIN_BENIGN = '#9ADAF0'; // possibly benign
export const CLASSIFICATION_COLOR_LIKELY_BENIGN = '#217EC6';
export const CLASSIFICATION_COLOR_BENIGN = '#1AB29C';
export const CLASSIFICATION_COLOR_FALSE = '#484950';
export const CLASSIFICATION_COLOR_CONFLICT = '#392dae';
export const CLASSIFICATION_COLOR_NA = '#ECF0F0';
export const CLASSIFICATION_COLOR_NA_ALT = '#D9DBDB';
export const CLASSIFICATION_COLOR_GERMLINE = '#222';
export const CLASSIFICATION_COLOR_SUSPECTED_GERMLINE = '#FACF34';
export const CLASSIFICATION_COLOR_TIER1 = '#e03a39';
export const CLASSIFICATION_COLOR_TIER2 = '#f19a29';
export const CLASSIFICATION_COLOR_TIER3 = '#919fa5';
export const CLASSIFICATION_COLOR_TIER4 = '#1ab29c';

export const CLASSIFICATIONS_ARRAY: {
  value: number;
  label: string;
  altLabel?: string;
  shortLabel?: string;
  color: string;
  step: number;
}[] = [
  { value: 128, label: 'Pathogenic', color: CLASSIFICATION_COLOR_PATHOGENIC, step: 6, shortLabel: 'P' },
  { value: 64, label: 'Likely Pathogenic', color: CLASSIFICATION_COLOR_LIKELY_PATHOGENIC, step: 5, shortLabel: 'LP' },
  { value: 60, label: 'Uncertain', color: CLASSIFICATION_COLOR_UNCERTAIN, step: 4, shortLabel: 'VUS' },
  {
    value: 24,
    label: 'VUS - Leaning Pathogenic',
    color: CLASSIFICATION_COLOR_UNCERTAIN_PATHOGENIC,
    step: 4,
    shortLabel: 'VUS',
  },
  {
    value: 16,
    label: 'VUS - Leaning Pathogenic',
    color: CLASSIFICATION_COLOR_UNCERTAIN_PATHOGENIC,
    step: 4,
    shortLabel: 'VUS',
  },
  {
    value: 8,
    label: 'VUS - Leaning Pathogenic',
    altLabel: 'VUS-P',
    color: CLASSIFICATION_COLOR_UNCERTAIN_PATHOGENIC,
    step: 4,
    shortLabel: 'VUS',
  }, // Uncertain Pathogenic
  {
    value: 4,
    label: 'Uncertain Significance',
    altLabel: 'VUS',
    color: CLASSIFICATION_COLOR_UNCERTAIN,
    step: 3,
    shortLabel: 'VUS',
  },
  {
    value: 32,
    label: 'VUS - Leaning Benign',
    altLabel: 'VUS-B',
    color: CLASSIFICATION_COLOR_UNCERTAIN_BENIGN,
    step: 2,
    shortLabel: 'VUS',
  }, // Uncertain Benign
  { value: 2, label: 'Likely Benign', color: CLASSIFICATION_COLOR_LIKELY_BENIGN, step: 1, shortLabel: 'LB' },
  { value: 1, label: 'Benign', color: CLASSIFICATION_COLOR_BENIGN, step: 0, shortLabel: 'B' },
  { value: 0, label: 'No classification', altLabel: 'None', color: CLASSIFICATION_COLOR_NA, step: 0 },
  { value: -1, label: 'Germline', color: CLASSIFICATION_COLOR_GERMLINE, step: 0 },
];

export const SOMATIC_GERMLINE_CLASSIFICATIONS_ARRAY = CLASSIFICATIONS_ARRAY.map((classification) => ({
  ...classification,
  label: classification.label.replace('Possibly', 'VUS - Leaning'),
}));

export const SOMATIC_CLASSIFICATIONS_ARRAY: {
  label: string;
  somaticLabel: string;
  somaticLabelShort: string;
  color: string;
  value: number;
  key: string;
}[] = [
  {
    value: null,
    somaticLabel: 'Not classified',
    somaticLabelShort: 'No class',
    label: 'No classification',
    color: CLASSIFICATION_COLOR_NA,
    key: 'UNKNOWN',
  },
  {
    value: 0,
    somaticLabel: 'Not classified',
    somaticLabelShort: 'No class',
    label: 'No classification',
    color: CLASSIFICATION_COLOR_NA,
    key: 'UNKNOWN',
  },
  {
    value: 1,
    somaticLabel: 'Tier 1',
    somaticLabelShort: 'Tier 1',
    label: 'Tier 1 - Strong clinical significance',
    color: CLASSIFICATION_COLOR_TIER1,
    key: 'TIER_1',
  },
  {
    value: 2,
    somaticLabel: 'Tier 2',
    somaticLabelShort: 'Tier 2',
    label: 'Tier2 - Potential clinical significance',
    color: CLASSIFICATION_COLOR_TIER2,
    key: 'TIER_2',
  },
  {
    value: 3,
    somaticLabel: 'Tier 3 - VUS',
    somaticLabelShort: 'Tier 3',
    label: 'Tier3 - Unknown clinical significance',
    color: CLASSIFICATION_COLOR_TIER3,
    key: 'TIER_3',
  },
  {
    value: 4,
    somaticLabel: 'Tier 4',
    somaticLabelShort: 'Tier 4',
    label: 'Tier4 - Benign or likely benign',
    color: CLASSIFICATION_COLOR_TIER4,
    key: 'TIER_4',
  },
  {
    value: -2,
    somaticLabel: 'False variant',
    somaticLabelShort: 'F/V',
    label: 'False variant',
    color: CLASSIFICATION_COLOR_FALSE,
    key: 'FALSE',
  },
  {
    value: 5,
    somaticLabel: 'False variant',
    somaticLabelShort: 'F/V',
    label: 'False variant',
    color: CLASSIFICATION_COLOR_FALSE,
    key: 'FALSE',
  },
  {
    value: -1,
    somaticLabel: 'Suspected as germline',
    somaticLabelShort: 'S/G',
    label: 'Suspected as germline',
    color: CLASSIFICATION_COLOR_SUSPECTED_GERMLINE,
    key: 'SUSPECTED_GERMLINE',
  },
];

export const EVIDENCES_ARRAY: { label: string; altLabel: string; icon: string; color: string; value: string }[] = [
  {
    value: 'A',
    icon: 'A',
    label: 'Level A - FDA approved',
    altLabel: 'Level A - included in professional guidelines',
    color: '#e03a39',
  },
  {
    value: 'B',
    icon: 'B',
    label: 'Level B - well-powered studies',
    altLabel: 'Level B - well-powered studies',
    color: '#f19a29',
  },
  {
    value: 'C',
    icon: 'C',
    label: 'Level C - different tumor type',
    altLabel: 'Level C - multiple small studies',
    color: '#79bad9',
  },
  {
    value: 'D',
    icon: 'D',
    label: 'Level D - preclinical studies',
    altLabel: 'Level D - small studies or a few case-reports',
    color: '#1d8fc2',
  },
  { value: 'NA', icon: '/', label: 'Not applicable', altLabel: 'Not applicable', color: '#F5F8F8' },
  { value: '?', icon: '?', label: 'No level', altLabel: 'No level', color: '#e3e3e3' },
];

export enum VariantPageClassification {
  pathogenic = 'PATHOGENIC',
  likelyPathogenic = 'LIKELY_PATHOGENIC',
  suspectedPathogenic = 'SUSPECTED_PATHOGENIC',
  uncertain = 'VUS',
  likelyBenign = 'LIKELY_BENIGN',
  suspectedBenign = 'SUSPECTED_BENIGN',
  benign = 'BENIGN',
  false = 'FALSE',
  none = 'NONE',
  conflict = 'CONFLICT',
}

export const VARIANT_PAGE_CLASSIFICATION_COLORS: { [key in VariantPageClassification]: string } = {
  [VariantPageClassification.pathogenic]: CLASSIFICATION_COLOR_PATHOGENIC,
  [VariantPageClassification.likelyPathogenic]: CLASSIFICATION_COLOR_LIKELY_PATHOGENIC,
  [VariantPageClassification.suspectedPathogenic]: CLASSIFICATION_COLOR_LIKELY_PATHOGENIC,
  [VariantPageClassification.uncertain]: CLASSIFICATION_COLOR_UNCERTAIN,
  [VariantPageClassification.likelyBenign]: CLASSIFICATION_COLOR_LIKELY_BENIGN,
  [VariantPageClassification.suspectedBenign]: CLASSIFICATION_COLOR_LIKELY_BENIGN,
  [VariantPageClassification.benign]: CLASSIFICATION_COLOR_BENIGN,
  [VariantPageClassification.false]: CLASSIFICATION_COLOR_FALSE,
  [VariantPageClassification.conflict]: CLASSIFICATION_COLOR_CONFLICT,
  [VariantPageClassification.none]: CLASSIFICATION_COLOR_NA,
};

export const VARIANT_PAGE_CLASSIFICATION_ALTERNATE_COLORS: { [key in VariantPageClassification]?: string } = {
  [VariantPageClassification.none]: CLASSIFICATION_COLOR_NA_ALT,
};

export const CLASSIFICATIONS = CLASSIFICATIONS_ARRAY.reduce((prev, val) => {
  return {
    ...prev,
    [val.value]: val,
  };
}, {});

export const SOMATIC_GERMLINE_CLASSIFICATIONS = SOMATIC_GERMLINE_CLASSIFICATIONS_ARRAY.reduce((prev, val) => {
  return {
    ...prev,
    [val.value]: val,
  };
}, {});

export const SOMATIC_CLASSIFICATIONS = SOMATIC_CLASSIFICATIONS_ARRAY.reduce((prev, val) => {
  return {
    ...prev,
    [val.value]: val,
  };
}, {});

export const SOMATIC_CLASSIFICATIONS_BY_KEY = SOMATIC_CLASSIFICATIONS_ARRAY.reduce((prev, val) => {
  return {
    ...prev,
    [val.key]: val,
  };
}, {});

export const EVIDENCES = EVIDENCES_ARRAY.reduce((prev, val) => {
  return {
    ...prev,
    [val.value]: val,
  };
}, {});

export enum EvidenceDirection {
  Supports = 0,
  DoesNotSupport = 1,
  Unknown = 2,
}

export enum RareDiseaseUserClassification {
  NA = 0,
  PATHOGENIC = 1,
  LIKELY_PATHOGENIC = 2,
  BENIGN = 3,
  LIKELY_BENIGN = 4,
  FALSE = 5,
  VUS = 6,
}

export enum PlatformUserClassificationBitmap {
  NA = 0,
  PATHOGENIC = 1,
  LIKELY_PATHOGENIC = 2,
  BENIGN = 4,
  LIKELY_BENIGN = 8,
  FALSE = 16,
  VUS = 32,
}

export enum PlatformSVUserClassificationBitmap {
  BENIGN = 1,
  LIKELY_BENIGN = 2,
  UNCERTAIN_SIGNIFICANCE = 4,
  POSSIBLY_PATHOGENIC_MODERATE = 8,
  POSSIBLY_PATHOGENIC_LOW = 16,
  POSSIBLY_BENIGN = 32,
  LIKELY_PATHOGENIC = 64,
  PATHOGENIC = 128,
}

export enum PlatformGermlineClassificationBitmap {
  NONE = 0,
  BENIGN = 1,
  SUSPECTED_BENIGN = 2,
  VUS = 4,
  VUS_PATHOGENIC_MODERATE = 8,
  VUS_PATHOGENIC_LOW = 16,
  VUS_BENIGN = 32,
  SUSPECTED_PATHOGENIC = 64,
  PATHOGENIC = 128,
}

// based on the proto filters.proto enum ClassificationValues used in the somatic report for germline_classification
export enum ProtoGermlineClassificationEnum {
  UNDEFINED_CLASSIFICATION = 0,
  BENIGN = 1,
  LIKELY_BENIGN = 2,
  UNCERTAIN_SIGNIFICANCE = 3,
  POSSIBLY_PATHOGENIC_MODERATE = 4,
  POSSIBLY_PATHOGENIC_LOW = 5,
  POSSIBLY_BENIGN = 6,
  LIKELY_PATHOGENIC = 7,
  PATHOGENIC = 8,
  DISEASE_RELATED = 9,
  DRUG_RELATED = 10,
}

export enum ProtoInterpretationAssessmentEnum {
  NONE = 0,
  PATHOGENIC = 1,
  SUSPECTED_PATHOGENIC = 2,
  VUS = 3,
  SUSPECTED_BENIGN = 4,
  BENIGN = 5,
  FALSE = 6,
  CONFLICT = 7,
  TIER_5 = 8,
  TIER_3 = 9,
  TIER_2 = 10,
  ONCOGENIC = 11,
  LIKELY_ONCOGENIC = 12,
}

export const getColorClassFromClassification = (classification: number): string => {
  switch (classification) {
    case 128: {
      return 'pathogenic';
    }
    case 64: {
      return 'likely-pathogenic';
    }
    case 24:
    case 16: {
      return 'uncertain-pathogenic';
    }
    case 8: {
      return 'uncertain-benign';
    }
    case 60:
    case 4: {
      return 'uncertain';
    }
    case 2: {
      return 'likely-benign';
    }
    case 1: {
      return 'benign';
    }
    case 0: {
      return 'none';
    }
    case -1: {
      return 'germline';
    }
  }
  return '';
};

export const getSomaticColorClassFromClassification = (classification: number): string => {
  switch (classification) {
    case 1: {
      return 'tier1';
    }
    case 2: {
      return 'tier2';
    }
    case 3: {
      return 'tier3';
    }
    case 4: {
      return 'tier4';
    }
    case 0: {
      return 'none';
    }
    case -1: {
      return 'suspected-germline';
    }
    case -2: {
      return 'false';
    }
  }
  return '';
};
