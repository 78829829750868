import { createReducer, on } from '@ngrx/store';
import { Phenotype } from '../models';
import { clearVariantsAutocomplete, loadVariantsAutocomplete, loadVariantsAutocompleteSuccess } from '../actions';

export const variantsAutocompleteFeatureKey = 'variantsAutocomplete';

export interface State {
  autocompleteSuggestions: Phenotype[];
  autocompleteLoading: boolean;
  autocompleteLoaded: boolean;
}

export const initialState: State = {
  autocompleteSuggestions: undefined,
  autocompleteLoading: undefined,
  autocompleteLoaded: undefined,
};

export function reducer(autoState, autoPayload) {
  return createReducer(
    initialState,
    on(loadVariantsAutocomplete, (state) => ({
      autocompleteSuggestions: [],
      autocompleteLoaded: false,
      autocompleteLoading: true,
    })),
    on(loadVariantsAutocompleteSuccess, (state, { payload }) => ({
      autocompleteSuggestions: payload,
      autocompleteLoaded: true,
      autocompleteLoading: false,
    })),
    on(loadVariantsAutocomplete, (state) => ({
      autocompleteSuggestions: [],
      autocompleteLoaded: false,
      autocompleteLoading: false,
    })),
    on(clearVariantsAutocomplete, (state) => ({
      ...state,
      autocompleteSuggestions: [],
    })),
  )(autoState, autoPayload);
}
