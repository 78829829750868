<div class="close-btn" (click)="onClose()">
  <img src="/assets/svg/esc-blk.svg" />
</div>
<div gnxFlex gnxBlock [column]="true" height="100%">
  <div gnxBlock gnxFlexItem flex="0 0 110px" background="#F7F8F8" border="0 0 1px 0 solid #d5d7db"></div>
  <div
    gnxBlock
    gnxFlex
    gnxFlexItem
    position="relative"
    flex="1"
    [column]="true"
    justify="center"
    gap="8px"
    padding="20px 48px 0"
  >
    <div gnxBlock position="absolute" width="100%" inset="-41px 0" height="82px">
      <img gnxBlock margin="0 auto" src="assets/svg/bell-green.svg" />
    </div>
    <div gnxText size="32px" color="#18244a">Unable to save your changes</div>
    <div>
      While you were reviewing the protocol, changes were made to it. Close this pop-up to refresh and then make your
      changes
    </div>
    <button (click)="onClose()">REFRESH</button>
  </div>
</div>
