import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TextOverflowEllipsisDirective } from '../../directives/text-overflow-ellipsis.directive';
import { ClickCursorDirective } from '../../directives/click-cursor.directive';
import { DndDraggableDirective, DndDropzoneDirective } from 'ngx-drag-drop';
import { MatTooltip } from '@angular/material/tooltip';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'gnx-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    MatTooltip,
    DndDraggableDirective,
    DndDropzoneDirective,
    ClickCursorDirective,
    TextOverflowEllipsisDirective,
  ],
})
export class ChipsComponent implements OnInit {
  @Input() chips: string[] = [];
  @Input() warningChips: string[] = [];
  @Input() warningTooltip: string;
  @Input() showClearAll = false;
  @Input() disableRemove: boolean;
  @Input() dropZones: string[];

  @Output() remove: EventEmitter<string> = new EventEmitter<string>();
  @Output() clearAll: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
}
