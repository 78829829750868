import * as fromReducer from '../reducers/case-data.reducer';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { getWorkbenchModuleState, WorkbenchModuleState } from '../reducers';
import { CasePanel, SearchConfig } from '../../../../../variant-page/modules/variant-page/models';

const getPatientInfoState: MemoizedSelector<WorkbenchModuleState, fromReducer.PatientInfoState> = createSelector(
  getWorkbenchModuleState,
  (state: WorkbenchModuleState) => state?.patientInfo,
);

export const getPatientInfoResponse: MemoizedSelector<WorkbenchModuleState, SearchConfig> = createSelector(
  getPatientInfoState,
  fromReducer.getPatientInfoResponse,
);

export const getPatientInfoResponseLoaded: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getPatientInfoState,
  fromReducer.getPatientInfoResponseLoaded,
);

export const getPatientInfoResponseLoading: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getPatientInfoState,
  fromReducer.getPatientInfoResponseLoading,
);

export const getPedigreeUrl: MemoizedSelector<WorkbenchModuleState, string> = createSelector(
  getPatientInfoState,
  fromReducer.getPedigreeUrl,
);

export const getPedigreeUrlLoaded: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getPatientInfoState,
  fromReducer.getPedigreeUrlLoaded,
);

export const getPedigreeUrlLoading: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getPatientInfoState,
  fromReducer.getPedigreeUrlLoading,
);

export const getToggledPhenotypes: MemoizedSelector<WorkbenchModuleState, string[]> = createSelector(
  getPatientInfoState,
  fromReducer.getToggledPhenotypes,
);

export const getToggledPanels: MemoizedSelector<WorkbenchModuleState, CasePanel[]> = createSelector(
  getPatientInfoState,
  fromReducer.getToggledPanels,
);
