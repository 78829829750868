import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { WorkbenchService } from '../services/workbench.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as fromAction from './../actions/workbench.action';
import { AnalysisDataComponentsConfig, CaseDataComponentsConfig } from '../models';
import { Observable } from 'rxjs';
import { ClearAnalysisResults, ClearPatientInfo, ClearQualityControl, clearCaseProtocols } from '../actions';

@Injectable()
export class WorkbenchEffects {
  constructor(
    private actions$: Actions,
    private service: WorkbenchService,
  ) {}

  loadAnalysisDataComponents$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_ANALYSIS_DATA_COMPONENTS),
      switchMap((action: fromAction.LoadAnalysisDataComponents) =>
        action.components
          .filter((component) => AnalysisDataComponentsConfig[component].loadAction)
          .map((component) => new AnalysisDataComponentsConfig[component].loadAction(action.analysisId)),
      ),
    ),
  );

  loadCaseDataComponents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_CASE_DATA_COMPONENTS),
      switchMap((action: fromAction.LoadCaseDataComponents) =>
        action.components
          .filter((component) => CaseDataComponentsConfig[component]?.loadAction)
          .map((component) => new CaseDataComponentsConfig[component].loadAction(action.analysisId)),
      ),
    ),
  );

  clearWorkbench$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.CLEAR_WORKBENCH),
      switchMap(() => [
        new ClearAnalysisResults(),
        new ClearQualityControl(),
        new ClearPatientInfo(),
        clearCaseProtocols(),
      ]),
    ),
  );
}
