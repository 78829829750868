import { Component, Input, OnInit } from '@angular/core';
import { GeneWebsite } from '../../models';

@Component({
  selector: 'gnx-external-link',
  templateUrl: './external-link.component.html',
  styleUrls: ['./external-link.component.scss'],
  standalone: true,
})
export class ExternalLinkComponent implements OnInit {
  @Input() url: string;

  constructor() {}

  ngOnInit(): void {}

  protected readonly GeneWebsite = GeneWebsite;
}
