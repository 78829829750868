import { createSelector, MemoizedSelector } from '@ngrx/store';
import { getWorkbenchModuleState, WorkbenchModuleState } from '../reducers';
import { SomaticBiomarkersState } from '../reducers/somatic-biomarkers.reducer';
import { AllSomaticBiomarkerResponse, SomaticBioMarkerType, SomaticBiomarkerUserData } from '../models';

const getSomaticBiomarkersState: MemoizedSelector<WorkbenchModuleState, SomaticBiomarkersState> = createSelector(
  getWorkbenchModuleState,
  (state) => state.somaticBiomarkers,
);

export const getSomaticBiomarkersData: MemoizedSelector<WorkbenchModuleState, AllSomaticBiomarkerResponse> =
  createSelector(getSomaticBiomarkersState, (state) => state.data);

export const getSomaticBiomarkersTypes: MemoizedSelector<WorkbenchModuleState, SomaticBioMarkerType[]> = createSelector(
  getSomaticBiomarkersState,
  (state) => state.data?.biomarkers?.map((value) => value.biomarker_type),
);

export const getSomaticBiomarkersDataLoading: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getSomaticBiomarkersState,
  (state) => state.loading,
);

export const getSomaticBiomarkersDataLoaded: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getSomaticBiomarkersState,
  (state) => state.loaded,
);

export const getSomaticBiomarkerUserData: MemoizedSelector<WorkbenchModuleState, SomaticBiomarkerUserData[]> =
  createSelector(getSomaticBiomarkersState, (state) => state.userData);

export const getSomaticBiomarkersUserDataAsObject: MemoizedSelector<
  WorkbenchModuleState,
  { SomaticBioMarkerType?: SomaticBiomarkerUserData }
> = createSelector(getSomaticBiomarkerUserData, (data) =>
  data ? data.reduce((prev, curr) => ({ ...prev, [curr.biomarker_type]: curr }), {}) : {},
);

export const getSomaticBiomarkerUserDataLoading: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getSomaticBiomarkersState,
  (state) => state.userDataLoading,
);

export const getSomaticBiomarkerUserDataLoaded: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getSomaticBiomarkersState,
  (state) => state.userDataLoaded,
);

export const getSomaticBiomarkerUserDataInUpdate: MemoizedSelector<WorkbenchModuleState, SomaticBioMarkerType> =
  createSelector(getSomaticBiomarkersState, (state) => state.userDataInUpdate);
