import { Component, Input, OnInit } from '@angular/core';
import { Phenotype } from '../../../store/models';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'gnx-phenotype-option',
  templateUrl: './phenotype-option.component.html',
  styleUrls: ['./phenotype-option.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, SafeHtmlPipe],
})
export class PhenotypeOptionComponent implements OnInit {
  @Input() input: string;
  @Input() phenotype: Phenotype;

  constructor() {}

  ngOnInit(): void {}

  addHighlight(str) {
    if (!str || !this.input) {
      return '';
    }
    const highlightRegex = new RegExp('(' + this.input + ')', 'gi');
    return str.replace(highlightRegex, '<span class="highlight">$1</span>');
  }
}
