import { SampleQC } from '../models';
import {
  CHECK_QC_BATCH_EXPORT,
  CHECK_QC_BATCH_EXPORT_FAIL,
  CHECK_QC_BATCH_EXPORT_SUCCESS,
  CHECK_QC_SNP_PROFILE_RESULTS_EXPORT,
  CHECK_QC_SNP_PROFILE_RESULTS_EXPORT_FAIL,
  CHECK_QC_SNP_PROFILE_RESULTS_EXPORT_SUCCESS,
  CLEAR_QUALITY_CONTROL,
  EXPORT_QUALITY_CONTROL,
  EXPORT_QUALITY_CONTROL_FAIL,
  EXPORT_QUALITY_CONTROL_SUCCESS,
  LOAD_QUALITY_CONTROL,
  LOAD_QUALITY_CONTROL_FAIL,
  LOAD_QUALITY_CONTROL_SUCCESS,
  QualityControlAction,
} from '../actions';

export interface QualityControlState {
  data: SampleQC[];
  loaded: boolean;
  loading: boolean;
  exportLoading: boolean;
  exportBatchLoading: boolean;
  exportBatchReady?: boolean;
  exportBatchPath?: string;
  exportBatchError?: any;
  exportSnpProfileLoading: boolean;
  exportSnpProfileReady?: boolean;
  exportSnpProfilePath?: string;
  exportSnpProfileError?: any;
}

export const initialState: QualityControlState = {
  data: [],
  loaded: false,
  loading: false,
  exportLoading: false,
  exportBatchLoading: false,
  exportSnpProfileLoading: false,
};

export function reducer(state = initialState, action: QualityControlAction): QualityControlState {
  switch (action.type) {
    case LOAD_QUALITY_CONTROL:
      return {
        ...state,
        data: null,
        loaded: false,
        loading: true,
      };
    case LOAD_QUALITY_CONTROL_SUCCESS:
      return {
        ...state,
        data: action.data,
        loaded: true,
        loading: false,
      };
    case LOAD_QUALITY_CONTROL_FAIL:
      return { ...state, loaded: false, loading: false };

    case EXPORT_QUALITY_CONTROL:
      return { ...state, exportLoading: true };

    case EXPORT_QUALITY_CONTROL_SUCCESS:
    case EXPORT_QUALITY_CONTROL_FAIL:
      return { ...state, exportLoading: false };

    case CLEAR_QUALITY_CONTROL:
      return { ...initialState };

    case CHECK_QC_BATCH_EXPORT:
      return {
        ...state,
        exportBatchLoading: true,
        exportBatchReady: false,
        exportBatchPath: undefined,
        exportBatchError: undefined,
      };
    case CHECK_QC_BATCH_EXPORT_SUCCESS:
      return {
        ...state,
        exportBatchLoading: false,
        exportBatchReady: action.result.qc_ready,
        exportBatchPath: action.result.file_path,
      };
    case CHECK_QC_BATCH_EXPORT_FAIL:
      return {
        ...state,
        exportBatchLoading: false,
        exportBatchError: action.error,
      };

    case CHECK_QC_SNP_PROFILE_RESULTS_EXPORT:
      return {
        ...state,
        exportSnpProfileLoading: true,
        exportSnpProfileReady: false,
        exportSnpProfilePath: undefined,
        exportSnpProfileError: undefined,
      };
    case CHECK_QC_SNP_PROFILE_RESULTS_EXPORT_SUCCESS:
      return {
        ...state,
        exportSnpProfileLoading: false,
        exportSnpProfileReady: action.result.qc_ready,
        exportSnpProfilePath: action.result.file_path,
      };
    case CHECK_QC_SNP_PROFILE_RESULTS_EXPORT_FAIL:
      return {
        ...state,
        exportSnpProfileLoading: false,
        exportSnpProfileError: action.error,
      };

    default:
      return state;
  }
}

export const getQualityControl = (state: QualityControlState) => state.data;
export const getQualityControlLoaded = (state: QualityControlState) => state.loaded;
export const getQualityControlLoading = (state: QualityControlState) => state.loading;
