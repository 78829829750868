import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromReducer from '../reducers/raffle.reducer';
import { getAppModuleState, VariantPageModuleState } from '../reducers';

export const getRaffleState: MemoizedSelector<VariantPageModuleState, fromReducer.RaffleVariantState> = createSelector(
  getAppModuleState,
  (state: VariantPageModuleState) => state.raffle,
);

export const getIsRaffleVariant: MemoizedSelector<VariantPageModuleState, boolean> = createSelector(
  getRaffleState,
  fromReducer.getIsRaffleVariant,
);
