import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserDetails } from '../../../auth0/models';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { AuthModuleState } from '../../../auth0/store/reducers';
import { select, Store } from '@ngrx/store';
import { InviteUsers } from '../../../auth0/store/actions';
import { getInviteUsersInProgress } from '../../../auth0/store/selectors';
import { filter } from 'rxjs/operators';
import { emailDomainValidator } from '../../../auth0/utils/email-domain-blacklist';
import { DotsLoaderComponent } from '../../../../shared/components/dots-loader/dots-loader.component';
import { CheckboxComponent } from '../../../../shared/components/checkbox/checkbox.component';
import { AvatarModule } from 'ngx-avatar-ivy';
import { NgIf, NgFor } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { ClickCursorDirective } from '../../../../shared/directives/click-cursor.directive';

@Component({
  selector: 'gnx-invite-member-popup',
  templateUrl: './invite-member-popup.component.html',
  styleUrls: ['./invite-member-popup.component.scss'],
  standalone: true,
  imports: [
    ClickCursorDirective,
    ReactiveFormsModule,
    MatTooltip,
    NgIf,
    NgFor,
    AvatarModule,
    CheckboxComponent,
    DotsLoaderComponent,
  ],
})
export class InviteMemberPopupComponent implements OnInit {
  searchForm: FormGroup;
  usersList: { email: string; admin?: boolean }[] = [];
  sendingInvitations: boolean;

  constructor(
    private dialogReg: MatDialogRef<InviteMemberPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserDetails,
    private fb: FormBuilder,
    private authStore$: Store<AuthModuleState>,
  ) {}

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      email: [
        '',
        [Validators.email, Validators.pattern(/^\S*[@]\S*[.]\S*$/), Validators.required, emailDomainValidator],
      ],
    });
  }

  onClose() {
    this.dialogReg.close();
  }

  onSendInvitations() {
    this.sendingInvitations = true;
    this.authStore$.dispatch(
      new InviteUsers(
        this.usersList.map((user) => ({ email: user.email, admin: user.admin })),
        true,
        true,
      ),
    );
    this.authStore$
      .pipe(
        select(getInviteUsersInProgress),
        filter((value) => !!value),
      )
      .subscribe((res) => this.onClose());
  }

  addUserToList(tooltip) {
    if (!this.usersList.find((user) => user.email === this.searchForm.controls.email.value)) {
      this.usersList.push({ email: this.searchForm.controls.email.value });
      this.searchForm.controls.email.setValue('');
    } else {
      tooltip.show();
    }
  }

  deleteMember(email: string) {
    this.usersList = this.usersList.filter((user) => user.email !== email);
  }
}
