<svg
  *ngIf="checkStatus === undefined"
  class="unchecked"
  xmlns="http://www.w3.org/2000/svg"
  width="12"
  height="12"
  viewBox="0 0 12 12"
>
  <rect width="11" height="11" x=".5" y=".5" fill="#FFF" fill-rule="evenodd" stroke="#999" rx="2"></rect>
  <line x1="4" y1="6" x2="8" y2="6" stroke="#086893" stroke-width="2" stroke-linecap="round" />
</svg>
<svg
  *ngIf="checkStatus === false || checkStatus === null"
  class="unchecked"
  xmlns="http://www.w3.org/2000/svg"
  width="12"
  height="12"
  viewBox="0 0 12 12"
>
  <rect width="11" height="11" x=".5" y=".5" fill="#FFF" fill-rule="evenodd" stroke="#999" rx="2"></rect>
</svg>
<svg
  *ngIf="checkStatus === true"
  class="checked"
  xmlns="http://www.w3.org/2000/svg"
  width="12"
  height="12"
  viewBox="0 0 12 12"
>
  <g fill="none" fill-rule="evenodd">
    <rect width="12" height="12" fill="#408EBD" rx="2"></rect>
    <path
      fill="#FFF"
      d="M5.156 8.682a.896.896 0 0 1-.815-.245L2.422 6.518a.897.897 0 0 1 1.269-1.269l1.423 1.423L8.129 3.08a.897.897 0 0 1 1.375 1.153L6.024 8.38a.897.897 0 0 1-.868.302z"
    ></path>
  </g>
</svg>
<ng-content></ng-content>
