import {
  VariantEvidencesOptionsState,
  VariantEvidencesSelectionState,
} from '../reducers/variant-evidences-selection.reducer';
import { createSelector } from '@ngrx/store';
import { AppModuleState, getAppModuleState } from '../reducers';

const getVariantEvidencesSelectionState = createSelector(
  getAppModuleState,
  (state: AppModuleState) => state.variantEvidencesSelection,
);

export const getVariantEvidencesOptionsState = createSelector(
  getVariantEvidencesSelectionState,
  (state: VariantEvidencesSelectionState) => state.variantEvidencesOptions,
);

export const getVariantEvidencesOptions = createSelector(
  getVariantEvidencesOptionsState,
  (state: VariantEvidencesOptionsState) => state.variantEvidenceOptionsValues,
);

export const getVariantEvidencesValueState = createSelector(
  getVariantEvidencesSelectionState,
  (state: VariantEvidencesSelectionState) => state.variantEvidenceValue,
);
