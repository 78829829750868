import { TeamMember, UserDetails } from '../models';

export class UserUtils {
  static ORGANIZATION_ADMIN_ROLE = 'organization admin';

  static isUserAnAdmin(user: UserDetails | TeamMember) {
    return user.org_roles.findIndex((value: string) => value.toLowerCase() === this.ORGANIZATION_ADMIN_ROLE) > -1;
  }

  static removeAdminRole(user: TeamMember): string[] {
    return user.org_roles.filter((role) => role.toLowerCase() !== this.ORGANIZATION_ADMIN_ROLE);
  }

  static addAdminRole(user: TeamMember): string[] {
    return [...user.org_roles, this.ORGANIZATION_ADMIN_ROLE];
  }
}
