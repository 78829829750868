import { Action } from '@ngrx/store';

export const ADD_COMMENT_TO_VARIANT = '[Variants] Add comment to variant';

export class AddCommentToVariant implements Action {
  readonly type = ADD_COMMENT_TO_VARIANT;

  constructor(
    public varId: string,
    public comment: string,
    public isPrivate: boolean,
  ) {}
}

export type VariantCommentsActions = AddCommentToVariant;
