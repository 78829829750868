export interface FreqMetadata {
  label: string;
  populations: string[];
}

export const FREQ_DBS_METADATA: { [key: string]: FreqMetadata } = {
  one_k: {
    label: '1K Genome',
    populations: ['afr', 'amr', 'eas', 'eur', 'sas'],
  },
  esp: {
    label: 'ESP 6500',
    populations: ['ea', 'aa'],
  },
  exac: {
    label: 'ExAC',
    populations: ['afr', 'amr', 'eas', 'fin', 'nfe', 'sas', 'oth'],
  },
  uk10k: {
    label: 'UK10K (Control)',
    populations: ['alspac', 'twinsuk'],
  },
  exom_gnomad: {
    label: 'gnomAD (Exome)',
    populations: ['afr', 'amr', 'eas', 'fin', 'nfe', 'sas', 'asj', 'oth'],
  },
  genome_gnomad: {
    label: 'gnomAD (Genome)',
    populations: ['afr', 'amr', 'eas', 'fin', 'nfe', 'sas', 'asj', 'oth'],
  },
};
