import { Action } from '@ngrx/store';
import { AnalysisType } from '../../../../store';
import { AddVariantToCaseStatus, StatusCheckResEnum } from '../../edit-case-flow/models/add-variant-to-case.model';

export const OPEN_ADD_VARIANTS_TO_CASE_DIALOG = '[Edit Case dialog] Open add variants to case dialog';
export const ADD_VARIANTS_TO_CASE = '[Edit Case dialog] Add variants to case';
export const ADD_VARIANTS_TO_CASE_SUCCESS = '[Edit Case dialog] Add variants to case success';
export const ADD_VARIANTS_TO_CASE_ERROR = '[Edit Case dialog] Add variants to case error';
export const ADD_VARIANTS_TO_CASE_STATUS_TIMER_TRIGGER = '[Edit Case dialog] Add variants to case status timer tick';
export const ADD_VARIANTS_TO_CASE_STATUS_CHECK = '[Edit Case dialog] Add variants to case status check';
export const ADD_VARIANTS_TO_CASE_STATUS_CHECK_SUCCESS = '[Edit Case dialog] Add variants to case status check success';
export const ADD_VARIANTS_TO_CASE_STATUS_CHECK_ERROR = '[Edit Case dialog] Add variants to case status check error';
export const ADD_VARIANTS_TO_CASE_HIDE_PROCESS = '[Edit Case dialog] Add variants to case hide process';

export class OpenAddVariantsToCaseDialog implements Action {
  readonly type = OPEN_ADD_VARIANTS_TO_CASE_DIALOG;
}

export class AddVariantsToCase implements Action {
  readonly type = ADD_VARIANTS_TO_CASE;

  constructor(
    public variantName: string,
    public analysisType: AnalysisType,
    public zygosizy?: string,
    public family_inheritance_status?: string,
    public vaf?: string,
    public copy_number?: number,
  ) {}
}

export class AddVariantsToCaseSuccess implements Action {
  readonly type = ADD_VARIANTS_TO_CASE_SUCCESS;

  constructor(public payload: AddVariantToCaseStatus) {}
}

export class AddVariantsToCaseError implements Action {
  readonly type = ADD_VARIANTS_TO_CASE_ERROR;

  constructor(public error: any) {}
}

export class AddVariantToCaseStatusTimerTrigger implements Action {
  readonly type = ADD_VARIANTS_TO_CASE_STATUS_TIMER_TRIGGER;
}

export class AddVariantToCaseStatusCheck implements Action {
  readonly type = ADD_VARIANTS_TO_CASE_STATUS_CHECK;

  constructor(public variantStatus: AddVariantToCaseStatus) {}
}

export class AddVariantToCaseStatusCheckSuccess implements Action {
  readonly type = ADD_VARIANTS_TO_CASE_STATUS_CHECK_SUCCESS;

  constructor(
    public status: StatusCheckResEnum,
    public processId: string,
    public variantId: string,
  ) {}
}

export class AddVariantToCaseStatusCheckError implements Action {
  readonly type = ADD_VARIANTS_TO_CASE_STATUS_CHECK_ERROR;
}

export class AddVariantToCaseHideProcess implements Action {
  readonly type = ADD_VARIANTS_TO_CASE_HIDE_PROCESS;

  constructor(public processId: string) {}
}

export type EditCaseDialogAction =
  | OpenAddVariantsToCaseDialog
  | AddVariantsToCase
  | AddVariantsToCaseSuccess
  | AddVariantsToCaseError
  | AddVariantToCaseStatusTimerTrigger
  | AddVariantToCaseStatusCheck
  | AddVariantToCaseStatusCheckSuccess
  | AddVariantToCaseStatusCheckError
  | AddVariantToCaseHideProcess;
