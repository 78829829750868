import { Injectable } from '@angular/core';
import { WorkbenchService } from '../services/workbench.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import * as fromAction from './../actions/case-protocols.action';
import { select, Store } from '@ngrx/store';
import { WorkbenchModuleState } from '../reducers';
import { getCaseProtocolsData } from '../selectors';
import { isEqual as _isEqual } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { UnableToSavePopupComponent } from '../../../../../../components/unable-to-save-popup/unable-to-save-popup.component';

@Injectable()
export class CaseProtocolsEffect {
  constructor(
    private actions$: Actions,
    private service: WorkbenchService,
    private store$: Store<WorkbenchModuleState>,
    private matDialog: MatDialog,
  ) {}

  getCaseProtocols$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.getCaseProtocols),
      switchMap((action) =>
        this.service.getAnalysisProtocols(action.analysisId).pipe(
          map((result) => fromAction.getCaseProtocolsSuccess({ analysisId: action.analysisId, data: result })),
          catchError((error) => of(fromAction.getCaseProtocolsFail({ analysisId: action.analysisId, error }))),
        ),
      ),
    ),
  );

  updateCaseProtocols$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.updateCaseProtocols),
      withLatestFrom(this.store$.pipe(select(getCaseProtocolsData))),
      switchMap(([action, originalData]) =>
        this.service.getAnalysisProtocols(action.analysisId).pipe(
          switchMap((current) => {
            if (_isEqual(current, originalData)) {
              return this.service.updateAnalysisProtocols(action.analysisId, action.protocols).pipe(
                map((result) => fromAction.getCaseProtocolsSuccess({ analysisId: action.analysisId, data: result })),
                catchError((error) => of(fromAction.getCaseProtocolsFail({ analysisId: action.analysisId, error }))),
              );
            } else {
              const saveConflictDialog = this.matDialog.open<UnableToSavePopupComponent>(UnableToSavePopupComponent, {
                width: '570px',
                height: '400px',
                backdropClass: 'popup-backdrop',
                panelClass: 'popup-panel',
                hasBackdrop: true,
              });
              return saveConflictDialog
                .afterClosed()
                .pipe(
                  switchMap(() =>
                    of(fromAction.getCaseProtocolsSuccess({ analysisId: action.analysisId, data: current })),
                  ),
                );
            }
          }),
        ),
      ),
    ),
  );

  getProtocolStepOptions$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.getProtocolStepOptions),
      switchMap(() =>
        this.service.getProtocolStepOptions().pipe(
          map((data) => fromAction.getProtocolStepOptionsSuccess({ data })),
          catchError((error) => of(fromAction.getProtocolStepOptionsFail(error))),
        ),
      ),
    ),
  );
}
