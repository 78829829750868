import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  getVariantIdType,
  RohVariantId,
  SearchConfig,
  SearchData,
  SvVariantId,
  VariantCaseRequest,
  VariantCaseResponse,
  VariantDetails,
  VariantId,
  variantIdToBody,
  variantIdToBody2,
  variantIdToBody3,
  variantIdToHttpParams,
  variantIdToHttpParamsToBody,
  VariantIdTypes,
} from '../models';
import { environment } from '../../../../../../environments/environment';
import { VariantPageLibConfigService } from './variant-page-lib-config.service';
import { VariantDataServiceConfig, VariantPageLibServiceType } from '../../../store/models';
import { ContactNetworkData } from '../models/contact.model';

export type url = string;

@Injectable()
export class VariantDataService {
  constructor(private http: HttpClient) {}

  get serviceConfig(): VariantDataServiceConfig {
    return VariantPageLibConfigService.getServiceConfig(VariantPageLibServiceType.VariantDataService);
  }

  getVariantDetails(varId: VariantId): Observable<VariantDetails> {
    const varType = getVariantIdType(varId);
    VariantPageLibConfigService.variantIdType = varType;
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    const apiUrl =
      varType === VariantIdTypes.RNA || varType === VariantIdTypes.SV
        ? (varId as SvVariantId)?.repeatData
          ? `${environment.beBaseURL}/api/fetch_repeat_details`
          : `${environment.beBaseURL}/api/fetch_sv_details`
        : varType === VariantIdTypes.ROH
          ? `${environment.beBaseURL}/api/fetch_roh_regions_details`
          : `${environment.beBaseURL}/api/fetch_variant_details`;

    if ((varId as RohVariantId).roh_regions) {
      const body = variantIdToBody(varId);
      return this.http.post<VariantDetails>(apiUrl, body);
    } else if ([VariantIdTypes.SNP, VariantIdTypes.SNP_TUMOR].includes(varId.variantIdType)) {
      const body = variantIdToHttpParamsToBody(varId, true);
      return this.http.post<VariantDetails>(apiUrl, body);
    } else {
      const params = variantIdToHttpParams(varId, true);
      return this.http.get<VariantDetails>(apiUrl, { headers, params });
    }
  }

  setSearchData(data: SearchData): Observable<{ updated: boolean }> {
    const headers = { Accept: 'application/json' };

    return this.http.put<any>(`${environment.beBaseURL}/api/search_data`, data, { headers });
  }

  saveVariantCase(data: VariantCaseRequest): Observable<VariantCaseResponse> {
    return this.http.post<VariantCaseResponse>(`${environment.beBaseURL}/api/analysis/create/from_search_async`, data);
  }

  updateVariantCaseName(caseId: number, caseName: string): Observable<VariantCaseResponse> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.patch<VariantCaseResponse>(
      `${environment.beBaseURL}/api/api/analysis/${caseId}`,
      {
        name: caseName,
      },
      { headers },
    );
  }

  updateVariantCase(caseId: number, caseContext: SearchConfig): Observable<SearchConfig> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<SearchConfig>(
      `${environment.beBaseURL}/api/analysis/case_details`,
      {
        analysis_id: caseId,
        case_context: caseContext,
      },
      { headers },
    );
  }

  contactMembers(
    contextVariant: VariantId,
    subjectVariant: VariantId,
    postId: string,
    sampleId: string,
    sigId: string,
    gene: string,
    samplesAffected: {
      sample_identifier: string;
    }[],
  ): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const path = sigId
      ? this.serviceConfig.contactCuratorUrl
      : postId
        ? this.serviceConfig.contactPostOwnersUrl
        : this.serviceConfig.contactMembersUrl;
    const body = sigId
      ? {}
      : {
          context_variant:
            getVariantIdType(contextVariant) === VariantIdTypes.SNP
              ? {
                  ...variantIdToBody2(contextVariant).variant,
                }
              : undefined,
          sv_context_variant:
            getVariantIdType(contextVariant) === VariantIdTypes.SV
              ? {
                  ...variantIdToBody2(contextVariant).sv_variant,
                }
              : undefined,
          subject_variant:
            subjectVariant &&
            subjectVariant !== contextVariant &&
            getVariantIdType(subjectVariant) === VariantIdTypes.SNP
              ? {
                  ...variantIdToBody(subjectVariant).variant,
                }
              : undefined,
          sv_subject_variant:
            subjectVariant &&
            subjectVariant !== contextVariant &&
            getVariantIdType(subjectVariant) === VariantIdTypes.SV
              ? {
                  ...variantIdToBody2(subjectVariant).sv_variant,
                }
              : undefined,
          post_id: postId,
          samples_filter: sampleId ? [{ sample_identifier: sampleId }] : [...(samplesAffected || [])],
        };
    const params = new HttpParams({ fromObject: { gene, sigId } });
    return this.http.post<any>(path, body, { headers, params });
  }

  getContactNetworkData(variant: VariantId): Observable<ContactNetworkData> {
    const body = variantIdToHttpParamsToBody(variant);
    return this.http.post<ContactNetworkData>(`${environment.beBaseURL}/api/contact_network/fetch_blurb`, body);
  }

  contactNetwork(title: string, body: string, variant: VariantId, context: SearchConfig): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(
      `${environment.beBaseURL}/api/contact_network/send`,
      {
        title,
        body,
        snp_variant: getVariantIdType(variant) === VariantIdTypes.SNP ? variantIdToBody3(variant) : undefined,
        sv_variant: getVariantIdType(variant) === VariantIdTypes.SV ? variantIdToBody3(variant) : undefined,
        case_context: context,
      },
      { headers },
    );
  }
}
