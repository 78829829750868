import { createSelector, MemoizedSelector } from '@ngrx/store';
import { DiscussionModuleState, getDiscussionModuleState } from '../reducers';
import * as fromReducer from '../reducers/discussion.reducer';
import { DiscussionState } from '../reducers/discussion.reducer';
import { Message, Topic, User } from '../../models/discussion.models';

export const getDiscussionState: MemoizedSelector<DiscussionModuleState, DiscussionState> = createSelector(
  getDiscussionModuleState,
  (state) => state.discussion,
);

export const getTopics: MemoizedSelector<DiscussionModuleState, Topic[]> = createSelector(
  getDiscussionState,
  fromReducer.getTopics,
);

export const getUsers: MemoizedSelector<DiscussionModuleState, User[]> = createSelector(
  getDiscussionState,
  fromReducer.getUsers,
);

export const getMessages: MemoizedSelector<DiscussionModuleState, Message[]> = createSelector(
  getDiscussionState,
  fromReducer.getMessages,
);

export const getTopicsWithMessages: MemoizedSelector<DiscussionModuleState, Topic[]> = createSelector(
  getTopics,
  getMessages,
  (topics: Topic[], messages: Message[]) => {
    if (!messages.length) {
      return [];
    }
    return topics
      .map((topic) => ({
        ...topic,
        messages: messages.filter((message) => message.topic_id === topic.topic_id),
      }))
      .filter((temp) => temp.messages && temp.messages.length);
  },
);

export const getDiscussionsChangedState: MemoizedSelector<DiscussionModuleState, boolean> = createSelector(
  getDiscussionState,
  fromReducer.getChangedState,
);
