import { Action } from '@ngrx/store';
import { ClassificationApiRule, ClassificationCategoryType, ClassificationRuleOption } from '../../models';
import { OncogenicClassificationRule } from '../../../oncogenic-classification/store';

export const SHOW_RECLASSIFICATION_POPUP = '[Classification] Show Reclassification Popup';
export const SHOW_MANUAL_RULE_SELECT_POPUP = '[Classification] Show Manual Rule Select Popup';

export class ShowReclassificationPopup implements Action {
  readonly type = SHOW_RECLASSIFICATION_POPUP;

  constructor(
    public showState: boolean,
    public rule?: ClassificationApiRule,
  ) {}
}

export class ShowManualRuleSelectPopup implements Action {
  readonly type = SHOW_MANUAL_RULE_SELECT_POPUP;

  constructor(
    public showState: boolean,
    public category?: ClassificationCategoryType,
    public ruleNames?: ClassificationRuleOption[],
  ) {}
}

export const SHOW_ONCOGENIC_RECLASSIFICATION_POPUP = '[Classification] Show Oncogenic Reclassification Popup';

export class ShowOncogenicReclassificationPopup implements Action {
  readonly type = SHOW_ONCOGENIC_RECLASSIFICATION_POPUP;

  constructor(
    public showState: boolean,
    public rule?: OncogenicClassificationRule,
  ) {}
}


export type PopupsAction = ShowReclassificationPopup | ShowManualRuleSelectPopup | ShowOncogenicReclassificationPopup;
