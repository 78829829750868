<div class="gray-background">
  <span class="close-icon" [mat-dialog-close]="false"></span>
</div>
<div class="msg-container">
  <img src="/assets/svg/campass.svg" width="85" height="80" />
  <div class="msg-title">Unsupported Transcript</div>
  <div class="msg-subtitle">
    The transcript version you were looking for is currently not supported.<br />
    The results are shown for transcript version {{ data }}.
  </div>
  <div class="dialog-actions">
    <div class="success-btn" [mat-dialog-close]="true">Continue</div>
    <div class="cancel-btn" [mat-dialog-close]="false">Cancel</div>
  </div>
</div>
