import { createSelector, MemoizedSelector } from '@ngrx/store';
import { getWorkbenchModuleState, WorkbenchModuleState } from '../reducers';
import { VariantInterpretationState } from '../reducers/variant-interpretation.reducer';

import * as fromReducer from './../reducers/variant-interpretation.reducer';
import { AnalysisResult, VariantInterpretation } from '../../../analysis-variant/models';

const getVariantInterpretationState: MemoizedSelector<WorkbenchModuleState, VariantInterpretationState> =
  createSelector(getWorkbenchModuleState, (state: WorkbenchModuleState) => state.variantInterpretation);

export const getVariantInterpretations: MemoizedSelector<
  WorkbenchModuleState,
  { [varId: string]: VariantInterpretation }
> = createSelector(getVariantInterpretationState, fromReducer.getVariantInterpretations);

export const getCheckedResults: MemoizedSelector<WorkbenchModuleState, Set<AnalysisResult>> = createSelector(
  getVariantInterpretationState,
  fromReducer.getCheckedResults,
);

export const getBulkActionLoading: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getVariantInterpretationState,
  (state) => state.bulkActionLoading,
);
