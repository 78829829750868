import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FiltersState } from '../../store/reducers';
import { Store } from '@ngrx/store';
import { CreateSavedFilter } from '../../store/actions';
import { SaveFilterDialogData, SaveFilterDialogResponse } from '../../store/models/dialog-data';
import { DotsLoaderComponent } from '../../../../../../shared/components/dots-loader/dots-loader.component';
import { CheckboxComponent } from '../../../../../../shared/components/checkbox/checkbox.component';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { NgClass, NgIf } from '@angular/common';
import { ClickCursorDirective } from '../../../../../../shared/directives/click-cursor.directive';

@Component({
  selector: 'gnx-save-filter-dialog',
  templateUrl: './save-filter-dialog.component.html',
  styleUrls: ['./save-filter-dialog.component.scss'],
  standalone: true,
  imports: [
    ClickCursorDirective,
    MatDialogContent,
    ReactiveFormsModule,
    NgIf,
    NgClass,
    CdkTextareaAutosize,
    MatDialogActions,
    CheckboxComponent,
    DotsLoaderComponent,
  ],
})
export class SaveFilterDialogComponent implements OnInit, AfterViewInit {
  saveForm: FormGroup;

  saveFilterLoading: boolean;

  @ViewChild('nameInput') nameInputRef: ElementRef;

  constructor(
    private dialogRef: MatDialogRef<SaveFilterDialogComponent, SaveFilterDialogResponse>,
    @Inject(MAT_DIALOG_DATA) public data: SaveFilterDialogData,
    private fb: FormBuilder,
    private store$: Store<FiltersState>,
  ) {}

  ngAfterViewInit() {
    this.nameInputRef.nativeElement.focus();
  }

  ngOnInit() {
    this.saveForm = this.fb.group({
      name: ['', Validators.required],
      description: [this.data.description || '', Validators.maxLength(700)],
      quick_filter: [true],
    });

    if (this.data.newFilterData) {
      this.saveForm.addControl('data', this.fb.control(this.data.newFilterData));
    }
  }

  onClose(success?: boolean) {
    this.dialogRef.close({ success: !!success, formData: this.saveForm.value });
  }

  onSave() {
    Object.keys(this.saveForm.controls).forEach((control) => this.saveForm.controls[control].markAsTouched());

    if (this.saveForm.valid) {
      this.store$.dispatch(
        new CreateSavedFilter(
          this.data.analysisId,
          this.data.analysisType || 'all',
          this.data.variantType,
          this.saveForm.value,
        ),
      );
      this.saveFilterLoading = true;

      this.onClose(true);
    }
  }

  get nameFormControl(): FormControl {
    return this.saveForm.get('name') as FormControl;
  }

  get descriptionFormControl(): FormControl {
    return this.saveForm.get('description') as FormControl;
  }

  get quickFilterFormControl(): FormControl {
    return this.saveForm.get('quick_filter') as FormControl;
  }

  get title(): string {
    return this.data.newFilterType === 'merged' ? 'Enter Merged Filter Details' : 'Enter Filter Details';
  }

  get subtitle(): string {
    return this.data.newFilterType === 'merged' ? 'NEW MERGE FILTER' : 'NEW FILTER';
  }
}
