import { ActionReducerMap, createFeatureSelector, MemoizedSelector } from '@ngrx/store';

import * as fromFiltersStructure from './filters-structure.reducer';
import * as fromFilterTree from './filter-tree.reducer';
import * as fromLastUsedFilter from './last-used-filter.reducer';
// import * as fromPredefinedFilters from './predefined-filters.reducer';

export interface FiltersState {
  filtersStructure: fromFiltersStructure.FiltersStructureState;
  filterTree: fromFilterTree.FilterTreeState;
  lastUsedFilter: fromLastUsedFilter.LastUsedFilterState;
  // predefinedFilters: fromPredefinedFilters.PredefinedFiltersState;
}

export const getFiltersState: MemoizedSelector<FiltersState, FiltersState> =
  createFeatureSelector<FiltersState>('filters');

export const filtersReducers: ActionReducerMap<FiltersState> = {
  filtersStructure: fromFiltersStructure.reducer,
  filterTree: fromFilterTree.reducer,
  lastUsedFilter: fromLastUsedFilter.reducer,
  // predefinedFilters: fromPredefinedFilters.reducer,
};
