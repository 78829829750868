import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { combineLatest } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class AnalysisService {
  constructor(private http: HttpClient) {}

  reanalyse() {
    return this.http.post<any>(`${environment.beBaseURL}/api/analysis/reanalyze`, {});
  }

  checkCaseNameAvailability(name: string) {
    const params = new HttpParams().append('name', name);

    return combineLatest([
      this.http.get<{ available: boolean }>(`${environment.beBaseURL}/api/api/analysis/check-availability`, { params }),
      this.http.get<{ available: boolean }>(`${environment.beBaseURL}/api/api/sample/check-availability`, { params }),
    ]).pipe(
      map(
        ([analysisAvailability, sampleAvailability]) => analysisAvailability.available && sampleAvailability.available,
      ),
    );
  }
}
