import { Action } from '@ngrx/store';
import { RangeOption } from '../models';

export const GET_PREDICTION_OPTIONS = '[Root] Get prediction options';
export const GET_PREDICTION_OPTIONS_SUCCESS = '[Root] Get prediction options success';
export const GET_PREDICTION_OPTIONS_FAIL = '[Root] Get prediction options fail';

export class GetPredictionOptions implements Action {
  readonly type = GET_PREDICTION_OPTIONS;
}

export class GetPredictionOptionsSuccess implements Action {
  readonly type = GET_PREDICTION_OPTIONS_SUCCESS;

  constructor(public payload: { [key: string]: RangeOption[] }) {}
}

export class GetPredictionOptionsFail implements Action {
  readonly type = GET_PREDICTION_OPTIONS_FAIL;

  constructor(public error: any) {}
}

export type PredictionOptionsAction = GetPredictionOptions | GetPredictionOptionsSuccess | GetPredictionOptionsFail;
