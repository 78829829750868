import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
  standalone: true,
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, search: string, highlightClass: string): unknown {
    if (!value || !search) {
      return value;
    }
    const highlightRegex = new RegExp('(' + search + ')', 'gi');
    return value.replace(highlightRegex, `<span class="${highlightClass}">$1</span>`);
  }
}
