import * as fromActions from '../actions/analysis-details.action';
import * as fromWorkbenchActions from '../../modules/analysis/modules/workbench/store/actions';
import * as fromReportingActions from '../../modules/analysis/modules/reporting/store/actions';
import { AnalysisDetails, AnalysisSampleDetails } from '../models';

export interface AnalysisDetailsState {
  data: AnalysisDetails;
  samples: { [id: string]: AnalysisSampleDetails };
  loading: boolean;
  loaded: boolean;
  isStatusUpdating: boolean;
  isFollowUpdating: boolean;
  isAssigneeUpdating: boolean;
}

export const initialState: AnalysisDetailsState = {
  data: {} as AnalysisDetails,
  samples: {},
  loading: false,
  loaded: false,
  isStatusUpdating: false,
  isFollowUpdating: false,
  isAssigneeUpdating: false,
};

export function reducer(
  state = initialState,
  action:
    | fromActions.AnalysisDetailsAction
    | fromWorkbenchActions.UpdateCancerTypeSuccess
    | fromReportingActions.GenerateReportingDataSuccess,
): AnalysisDetailsState {
  switch (action.type) {
    case fromActions.LOAD_ANALYSIS_DETAILS: {
      if (action.analysisId === state.data.id) {
        return state;
      }
      return {
        ...state,
        data: {} as AnalysisDetails,
        samples: {},
        loading: true,
        loaded: false,
      };
    }

    case fromActions.LOAD_ANALYSIS_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        samples: action.payload?.samples?.reduce((pre, cur) => ({ ...pre, [cur.s3_id]: cur }), {}),
        loading: false,
        loaded: true,
      };
    }

    case fromActions.LOAD_ANALYSIS_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case fromActions.UPDATE_ANALYSIS_STATUS: {
      return {
        ...state,
        isStatusUpdating: true,
      };
    }

    case fromActions.UPDATE_ANALYSIS_STATUS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        samples: action.payload.samples.reduce((pre, cur) => ({ ...pre, [cur.s3_id]: cur }), {}),
        isStatusUpdating: false,
      };
    }

    case fromActions.UPDATE_ANALYSIS_STATUS_FAIL: {
      return {
        ...state,
        isStatusUpdating: false,
      };
    }

    case fromActions.UPDATE_ANALYSIS_FOLLOW: {
      return {
        ...state,
        isFollowUpdating: true,
      };
    }

    case fromActions.UPDATE_ANALYSIS_FOLLOW_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        isFollowUpdating: false,
      };
    }

    case fromActions.UPDATE_ANALYSIS_FOLLOW_FAIL: {
      return {
        ...state,
        isFollowUpdating: false,
      };
    }

    case fromWorkbenchActions.UPDATE_CANCER_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.analysisData,
      };
    }

    case fromReportingActions.GENERATE_REPORTING_DATA_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          last_report_uuid: action.payload.report_uuid,
        },
      };
    }

    case fromActions.UPDATE_ANALYSIS_ASSIGNEE: {
      return {
        ...state,
        isAssigneeUpdating: true,
      };
    }

    case fromActions.UPDATE_ANALYSIS_ASSIGNEE_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        isAssigneeUpdating: false,
      };
    }

    case fromActions.UPDATE_ANALYSIS_ASSIGNEE_FAIL: {
      return {
        ...state,
        isAssigneeUpdating: false,
      };
    }

    case fromActions.CLEAR_ANALYSIS_DETAILS: {
      return initialState;
    }
  }

  return state;
}

export const getAnalysisDetails = (state: AnalysisDetailsState) => state.data;
export const getAnalysisSamples = (state: AnalysisDetailsState) => state.samples;
export const getAnalysisDetailsLoading = (state: AnalysisDetailsState) => state.loading;
export const getAnalysisDetailsLoaded = (state: AnalysisDetailsState) => state.loaded;
export const getAnalysisStatusUpdating = (state: AnalysisDetailsState) => state.isStatusUpdating;
export const getAnalysisFollowUpdating = (state: AnalysisDetailsState) => state.isFollowUpdating;
