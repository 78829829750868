import { AnalysisResultSingleData, VariantType } from '../../../analysis-variant/models';
import { Action } from '@ngrx/store';

export const GET_VARIANT_IGV_LINK = '[Variants] Get Variant IGV Link';
export const GET_VARIANT_IGV_LINK_SUCCESS = '[Variants] Get Variant IGV Link Success';
export const GET_VARIANT_IGV_LINK_FAIL = '[Variants] Get Variant IGV Link Fail';

export class GetVariantIgvLink implements Action {
  readonly type = GET_VARIANT_IGV_LINK;

  constructor(
    public analysisId: number,
    public sampleS3Id: string,
    public variantType: VariantType,
    public variantData: AnalysisResultSingleData,
    public additionalSamplesS3Id: string[],
  ) {}
}

export class GetVariantIgvLinkSuccess implements Action {
  readonly type = GET_VARIANT_IGV_LINK_SUCCESS;

  constructor(
    public variantType: VariantType,
    public variantData: AnalysisResultSingleData,
    public link: {
      link: string;
      custom_link?: string;
    },
  ) {}
}

export class GetVariantIgvLinkFail implements Action {
  readonly type = GET_VARIANT_IGV_LINK_FAIL;

  constructor(
    public variantType: VariantType,
    public variantData: AnalysisResultSingleData,
    public error: any,
  ) {}
}

export const GET_CASE_IGV_LINK = '[Variants] Get Case IGV Link';
export const GET_CASE_IGV_LINK_SUCCESS = '[Variants] Get Case IGV Link Success';
export const GET_CASE_IGV_LINK_FAIL = '[Variants] Get Case IGV Link Fail';

export class GetCaseIgvLink implements Action {
  readonly type = GET_CASE_IGV_LINK;
  constructor(
    public analysisId: number,
    public sampleS3Id: string,
    public additionalSamplesS3Id: string[],
  ) {}
}

export class GetCaseIgvLinkSuccess implements Action {
  readonly type = GET_CASE_IGV_LINK_SUCCESS;
  constructor(
    public link: {
      link: string;
      custom_link?: string;
    },
  ) {}
}

export class GetCaseIgvLinkFail implements Action {
  readonly type = GET_CASE_IGV_LINK_FAIL;
  constructor(public error: any) {}
}

export type IgvLinkActions =
  | GetVariantIgvLink
  | GetVariantIgvLinkSuccess
  | GetVariantIgvLinkFail
  | GetCaseIgvLink
  | GetCaseIgvLinkSuccess
  | GetCaseIgvLinkFail;
