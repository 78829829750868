import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, fromEvent, merge, Observable, of } from 'rxjs';

import { select, Store } from '@ngrx/store';
import { catchError, delay, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as fromActions from '../actions/variant-details.action';
import { SaveVariantCase, SET_SEARCH_DATA_SUCCESS } from '../actions';
import { VariantDataService } from '../../services/variant-data.service';
import {
  getSearchTermFromVariantId,
  getVariantDetailsTitle,
  SearchConfig,
  SearchData,
  VariantCaseRequest,
  VariantCaseResponse,
  VariantDetails,
  VariantId,
} from '../../models';
import { PreloadService } from '../../services/preload.service';
import { MatDialog } from '@angular/material/dialog';
import {
  getSearchCaseSaved,
  getSearchCasesSaved,
  getSearchConfig,
  getSearchConfigPercent,
  getSearchConfigValid,
  getSessionId,
  getVariantDetails,
  getVariantId,
} from '../selectors';
import { VariantPageState } from '../reducers';
import {
  SaveCaseDialogComponent,
  SaveCaseDialogData,
} from '../../containers/save-case-dialog/save-case-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { FeatureBit, UserDetails } from '../../../../../auth0/models';
import * as fromReducers from '../../../../../../store/reducers';
import { AuthModuleState, getFeatureBits } from '../../../../../auth0/store';

@Injectable()
export class VariantDetailsEffect {
  constructor(
    private actions$: Actions,
    private store$: Store<VariantPageState>,
    private rootStore$: Store<fromReducers.AppModuleState>,
    private authStore$: Store<AuthModuleState>,
    private dataService: VariantDataService,
    private preloadService: PreloadService,
    private matDialog: MatDialog,
    private router: Router,
  ) {}

  loadVariantDetails$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LOAD_VARIANT_DETAILS),
      switchMap((action: fromActions.LoadVariantDetails) =>
        combineLatest([
          this.dataService.getVariantDetails(action.variantId),
          action.compoundVariantId?.hashId ? this.dataService.getVariantDetails(action.compoundVariantId) : of(null),
        ]).pipe(
          switchMap((data: VariantDetails[]) => [new fromActions.LoadVariantDetailsSuccess(data[0], data[1])]),
          catchError((error) => of(new fromActions.LoadVariantDetailsFail(error))),
        ),
      ),
    ),
  );

  preloadDataInBE$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.PRELOAD_DATA_IN_BE),
      switchMap((action: fromActions.PreloadDataInBE) =>
        this.preloadService.preloadData(action.variantId, action.config).pipe(
          map((response) => new fromActions.PreloadDataInBESuccess(response)),
          catchError((error) => of(new fromActions.PreloadDataInBEFail(error))),
        ),
      ),
    ),
  );

  setSearchData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.SET_SEARCH_DATA, fromActions.PATCH_SEARCH_DATA),
      filter(() => !this.router.url.includes('analysis')),
      withLatestFrom(
        this.store$.pipe(select(getSessionId)),
        this.store$.pipe(
          select(getVariantId),
          filter((res) => !!res),
        ),
        this.store$.pipe(
          select(getSearchConfig),
          filter((res) => !!res),
        ),
      ),
      map(([, sessionId, variant, config]: [fromActions.SetSearchData, string, VariantId, SearchConfig]) => ({
        search_data: {
          search_session_id: sessionId,
          search_input_text: config?.search_term || getSearchTermFromVariantId(variant),
          case_context: config,
          snp_variant:
            'ref' in variant
              ? {
                  chrom: variant.chr,
                  pos: variant.pos,
                  ref: variant.ref,
                  alt: variant.alt,
                  reference_version: variant.referenceGenome,
                }
              : undefined,
          sv_variant:
            'sv_type' in variant
              ? {
                  chrom: variant.chr,
                  start_pos: variant.start_pos,
                  end_pos: variant.end_pos,
                  sv_type: variant.sv_type,
                  reference_version: variant.referenceGenome,
                }
              : undefined,
        },
      })),
      switchMap((data: SearchData) =>
        this.dataService.setSearchData(data).pipe(
          map((response) =>
            response.updated
              ? new fromActions.SetSearchDataSuccess(data)
              : new fromActions.SetSearchDataFail(response.updated),
          ),
          catchError((err) => of(new fromActions.SetSearchDataFail(err))),
        ),
      ),
    ),
  );

  autosaveVariantCase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_SEARCH_DATA_SUCCESS),
      withLatestFrom(
        this.store$.pipe(select(getSearchConfigPercent)),
        this.store$.pipe(select(getSearchCaseSaved)),
        this.store$.pipe(select(getSearchCasesSaved)),
        this.store$.pipe(select(getVariantDetails)),
        this.store$.pipe(select(getSearchConfig)),
      ),
      withLatestFrom(this.authStore$.pipe(select(getFeatureBits))),
      filter(([[, percent, saved]]) => percent >= 30 && !saved),
      switchMap(([[, , , savedCases, variant, config], fbs]) => {
        const caseName: string = config?.search_term || getVariantDetailsTitle(variant);
        if (savedCases && savedCases[caseName]) {
          return this.dataService.updateVariantCase(savedCases[caseName].id, config).pipe(
            map(() => new fromActions.SaveVariantCaseSuccess()),
            catchError((err: HttpErrorResponse) => of(new fromActions.SaveVariantCaseFail(err.error))),
          );
        }
        const userDetails: UserDetails = JSON.parse(localStorage.getItem('userDetails'));
        if (
          !!fbs[FeatureBit.DisableAutoCaseFromSearch] ||
          userDetails?.email?.includes('genoox.com') ||
          userDetails?.email?.includes('gmail.com')
        ) {
          return of({ type: 'Null Action' });
        }
        return of(new SaveVariantCase(caseName));
      }),
    ),
  );

  saveVariantCase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.SAVE_VARIANT_CASE),
      withLatestFrom(
        this.store$.pipe(select(getVariantDetails)),
        this.store$.pipe(select(getSearchConfig)),
        this.store$.pipe(select(getSearchCasesSaved)),
      ),
      switchMap(
        ([action, variant, config, savedCases]: [
          fromActions.SaveVariantCase,
          VariantDetails,
          SearchConfig,
          { [name: string]: VariantCaseResponse },
        ]) => {
          const caseName: string = config?.search_term || getVariantDetailsTitle(variant);
          if (savedCases && savedCases[caseName]) {
            return this.dataService.updateVariantCaseName(savedCases[caseName].id, action.caseName).pipe(
              map((response) => new fromActions.SaveVariantCaseSuccess(caseName, response)),
              catchError((err: HttpErrorResponse) => of(new fromActions.SaveVariantCaseFail(err.error))),
            );
          }
          config = { ...(config || {}) };
          config.birth_date = config.birth_date ? new Date(config.birth_date).getTime() : undefined;
          const { zygosity, reported_classification } = config || {};
          const body: VariantCaseRequest = {
            case_name: action.caseName,
            case_context: config,
            snp_variants:
              'ref' in variant
                ? [
                    {
                      variant: {
                        chrom: variant.chr,
                        pos: variant.position,
                        ref: variant.ref,
                        alt: variant.alt,
                        reference_version: variant.reference_version,
                      },
                      info: {
                        reported_classification,
                        zygosity,
                      },
                    },
                  ]
                : undefined,
            sv_variants:
              'sv_type' in variant
                ? [
                    {
                      variant: {
                        chrom: variant.range_id.chrom,
                        start_pos: variant.range_id.start,
                        end_pos: variant.range_id.end,
                        sv_type: variant.sv_type,
                        reference_version: variant.range_id.reference,
                      },
                      info: {
                        reported_classification,
                        zygosity,
                      },
                    },
                  ]
                : undefined,
          };
          return this.dataService.saveVariantCase(body).pipe(
            map(
              (response) =>
                new fromActions.SaveVariantCaseSuccess(caseName, { id: response.id, name: action.caseName }),
            ),
            catchError((err: HttpErrorResponse) => of(new fromActions.SaveVariantCaseFail(err.error))),
          );
        },
      ),
    ),
  );

  openSaveCaseDialog$: Observable<any> = createEffect(
    () =>
      merge(
        this.actions$.pipe(ofType(fromActions.OPEN_SAVE_CASE_DIALOG)),
        fromEvent(window, 'beforeunload disabled-for-now').pipe(
          filter(
            () =>
              !this.router.url.includes('/analysis/') &&
              !this.router.url.includes('/roh/') &&
              !localStorage.getItem('noAutoSaveDialog'),
          ),
          withLatestFrom(this.store$.pipe(select(getSearchConfigValid)), this.store$.pipe(select(getSearchCaseSaved))),
          filter(([, valid, saved]: [BeforeUnloadEvent, boolean, boolean]) => valid && !saved),
          map(([e]: [BeforeUnloadEvent, boolean, boolean]) => {
            e.preventDefault();
            e.returnValue = 'stop';
            return new fromActions.OpenSaveCaseDialog(false);
          }),
          delay(1),
        ),
      ).pipe(
        withLatestFrom(
          this.store$.pipe(select(getVariantDetails)),
          this.store$.pipe(select(getSearchConfig)),
          this.store$.pipe(select(getSearchCasesSaved)),
        ),
        tap(
          ([action, variantDetails, config, savedCases]: [
            fromActions.OpenSaveCaseDialog,
            VariantDetails,
            SearchConfig,
            { [name: string]: VariantCaseResponse },
          ]) => {
            const caseName: string = config?.search_term || getVariantDetailsTitle(variantDetails);
            this.matDialog.open(SaveCaseDialogComponent, {
              width: '780px',
              position: { top: '70px' },
              backdropClass: 'popup-backdrop',
              panelClass: ['paddingless-dialog', 'grey-bg-modal'],
              disableClose: true,
              data: {
                manual: action.manual,
                variantName: savedCases[caseName]?.name || caseName,
                alreadySaved: !!savedCases[caseName]?.name,
              } as SaveCaseDialogData,
            });
          },
        ),
      ),
    { dispatch: false },
  );
}
