import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SelectOption } from '../../../shared/models';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ChipsComponent } from '../../../shared/components/chips/chips.component';
import { MatOption } from '@angular/material/core';
import { ClickCursorDirective } from '../../../shared/directives/click-cursor.directive';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { DotsLoaderComponent } from '../../../shared/components/dots-loader/dots-loader.component';
import { NgIf, NgFor, UpperCasePipe } from '@angular/common';

export const NO_RESULTS: SelectOption[] = [
  {
    label: "We couldn't find any match\nCheck the spelling or try a wider range",
    value: 'error',
  },
];

@Component({
  selector: 'gnx-auto-complete-with-chips',
  templateUrl: './auto-complete-with-chips.component.html',
  styleUrls: ['./auto-complete-with-chips.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoCompleteWithChipsComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    NgIf,
    DotsLoaderComponent,
    MatAutocompleteTrigger,
    ReactiveFormsModule,
    ClickCursorDirective,
    MatAutocomplete,
    NgFor,
    MatOption,
    ChipsComponent,
    UpperCasePipe,
  ],
})
export class AutoCompleteWithChipsComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() options: SelectOption[];
  @Input() optionsLoading: boolean;
  @Input() placeholder: string;
  @Input() autoCompleteFixedWidthPx: number;
  @Input() disabled = false;

  @Input() selections: string[] = [];
  @Output() selectionsChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  filteredOptions: SelectOption[];

  onChange: (newVal: string[]) => {};
  onTouched: () => {};

  inputControl: FormControl = new FormControl();

  constructor() {}

  ngOnInit(): void {
    this.inputControl.valueChanges.subscribe((value) => {
      this.search.emit(value);
      if (!!value && value !== 'error') {
        const result = [
          ...this.options.filter(
            (option) =>
              option.label?.toLowerCase()?.startsWith(value?.toString()?.toLowerCase()) &&
              !this.selections?.includes(option.value),
          ),
        ];
        this.filteredOptions = result.length ? result : NO_RESULTS;
      } else {
        this.resetFilteredOptions();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options?.currentValue?.length !== changes.options?.previousValue?.length) {
      this.resetFilteredOptions();
    }

    if (changes.selections?.currentValue?.length !== changes.selections?.previousValue?.length) {
      this.resetFilteredOptions();
    }

    if (
      changes.options?.currentValue?.length === changes.options?.previousValue?.length &&
      changes.options?.currentValue?.length > 0
    ) {
      const curOptions = changes.options.currentValue as SelectOption[];
      const prevOptions = changes.options.previousValue as SelectOption[];
      if (curOptions.some((option) => !prevOptions.some((prevOption) => prevOption.value === option.value))) {
        this.resetFilteredOptions();
      }
    }

    if (this.disabled) {
      this.inputControl.disable();
    }
  }

  resetFilteredOptions(): void {
    this.filteredOptions = this.options?.filter((option) => !this.selections?.includes(option.value)) || [];
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.selections = obj;
  }

  updateModel(): void {
    this.selectionsChange.emit(this.selections);
    if (this.onChange) {
      this.onChange(this.selections);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  onSelect(option: SelectOption) {
    if (this.options.some((opt) => opt.value === option.value)) {
      this.selections = [...this.selections, option.value];
      this.updateModel();
    }
    this.resetFilteredOptions();
    setTimeout(() => {
      this.inputControl.setValue('');
    });
  }

  onEnter() {
    const searchText = this.inputControl.value?.toLowerCase();
    const option = this.filteredOptions.find((opt) => opt.label.toLowerCase() === searchText);
    if (option) {
      this.onSelect(option);
    }
  }

  onBlur() {
    setTimeout(() => {
      this.inputControl.setValue('');
      this.resetFilteredOptions();
    }, 250);
  }

  onRemoveSelection(removedLabel: string) {
    this.selections = this.selections.filter((x) => {
      return this.options.find((opt) => opt.value === x)?.label !== removedLabel && x !== removedLabel;
    });
    this.updateModel();
  }

  onClearAll() {
    this.selections = [];
    this.updateModel();
    this.resetFilteredOptions();
  }

  getSelectionLabels(): string[] {
    return this.selections.map((x) => this.options?.find((opt) => opt.value === x)?.label || x);
  }
}
