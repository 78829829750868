import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import { VariantPageState } from '../reducers';
import * as fromReducer from '../reducers/contact.reducer';
import { ContactState } from '../reducers/contact.reducer';
import { ContactNetworkData } from '../../models/contact.model';

export const getContactState: MemoizedSelector<VariantPageState, ContactState> = createSelector(
  fromFeature.getVariantPageState,
  (state: fromFeature.VariantPageState) => state.contact,
);

export const getContactedMembers: MemoizedSelector<VariantPageState, { [key: string]: string[] }> = createSelector(
  getContactState,
  fromReducer.getContactedMembers,
);

export const getContactingMembers: MemoizedSelector<VariantPageState, { [key: string]: string }> = createSelector(
  getContactState,
  fromReducer.getContactingMembers,
);

export const getContactedNetwork: MemoizedSelector<VariantPageState, boolean> = createSelector(
  getContactState,
  fromReducer.getContactedNetwork,
);

export const getContactingNetwork: MemoizedSelector<VariantPageState, boolean> = createSelector(
  getContactState,
  fromReducer.getContactingNetwork,
);

export const getContactNetworkData: MemoizedSelector<VariantPageState, ContactNetworkData> = createSelector(
  getContactState,
  fromReducer.getContactNetworkData,
);
