import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { emailDomainValidator } from '../../modules/auth0/utils/email-domain-blacklist';
import { NgIf } from '@angular/common';
import { ClickCursorDirective } from '../../shared/directives/click-cursor.directive';

export interface SubmitSuccessDialogData {
  imgSrc?: string;
  imgSrcSet?: string;
  title?: string;
  boldDescription?: string;
  description?: string;
  buttonLabel?: string;
  askForEmail?: boolean;
}

@Component({
  selector: 'gnx-submit-success',
  templateUrl: './submit-success.component.html',
  styleUrls: ['./submit-success.component.scss'],
  standalone: true,
  imports: [ClickCursorDirective, NgIf, MatDialogClose],
})
export class SubmitSuccessComponent implements OnInit {
  emailFormControl: FormControl;

  constructor(
    public dialogRef: MatDialogRef<SubmitSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SubmitSuccessDialogData,
  ) {}

  ngOnInit() {
    this.emailFormControl = new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(/^\S*[@]\S*[.]\S*$/),
      emailDomainValidator,
    ]);
  }

  onClose() {
    this.dialogRef.close(this.emailFormControl.value);
  }
}
