import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AppModuleState, getAppModuleState } from '../reducers';
import { OrganizationCustomSchemaState } from '../reducers/organization-custom-schema.reducer';
import { OrganizationCustomSchema } from '../models';

const getOrgCustomSchemaState: MemoizedSelector<AppModuleState, OrganizationCustomSchemaState> = createSelector(
  getAppModuleState,
  (state: AppModuleState) => state.organizationCustomSchema,
);

export const getOrganizationCustomSchema: MemoizedSelector<AppModuleState, OrganizationCustomSchema> = createSelector(
  getOrgCustomSchemaState,
  (state) => state.data,
);
