export interface VariantEvidenceOptionModel {
  evidence_category_name: string;
  value_options?: Array<string>;
}

export interface VariantEvidenceValueModel {
  evidence_category_name: string;
  value: string;
}

export interface VariantEvidencesDataModel {
  categories: VariantEvidenceValueModel[];
  details?: string;
}
