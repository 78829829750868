import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AppModuleState, getAppModuleState } from '../reducers';
import { EnrichmentKitsState } from '../reducers/enrichment-kits.reducer';
import { EnrichmentKit } from '../../modules/analysis/modules/analyses/store/models';

import * as fromReducer from './../reducers/enrichment-kits.reducer';

const getEnrichmentKitsState: MemoizedSelector<AppModuleState, EnrichmentKitsState> = createSelector(
  getAppModuleState,
  (state) => state.enrichmentKits,
);

export const getEnrichmentKits: MemoizedSelector<AppModuleState, EnrichmentKit[]> = createSelector(
  getEnrichmentKitsState,
  fromReducer.getEnrichmentKits,
);

export const getEnrichmentKitsLoading: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getEnrichmentKitsState,
  fromReducer.getEnrichmentKitsLoading,
);

export const getEnrichmentKitsLoaded: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getEnrichmentKitsState,
  fromReducer.getEnrichmentKitsLoaded,
);
