import { Action } from '@ngrx/store';
import { CasePanel, SearchConfig } from '../../../../../variant-page/modules/variant-page/models';

export const LOAD_PATIENT_INFO = '[Analysis] Load patient info';
export const LOAD_PATIENT_INFO_SUCCESS = '[Analysis] Load patient info success';
export const LOAD_PATIENT_INFO_FAIL = '[Analysis] Load patient info fail';

export class LoadPatientInfo implements Action {
  readonly type = LOAD_PATIENT_INFO;

  constructor(public analysisId: number) {}
}

export class LoadPatientInfoSuccess implements Action {
  readonly type = LOAD_PATIENT_INFO_SUCCESS;

  constructor(
    public analysisId: number,
    public data: SearchConfig,
  ) {}
}

export class LoadPatientInfoFail implements Action {
  readonly type = LOAD_PATIENT_INFO_FAIL;

  constructor(
    public analysisId: number,
    public error: any,
  ) {}
}

export const LOAD_PEDIGREE_URL = '[Analysis] Load PedigreeUrl';
export const LOAD_PEDIGREE_URL_SUCCESS = '[Analysis] Load PedigreeUrl success';
export const LOAD_PEDIGREE_URL_FAIL = '[Analysis] Load PedigreeUrl fail';

export class LoadPedigreeUrl implements Action {
  readonly type = LOAD_PEDIGREE_URL;

  constructor(public analysisId: number) {}
}

export class LoadPedigreeUrlSuccess implements Action {
  readonly type = LOAD_PEDIGREE_URL_SUCCESS;

  constructor(
    public analysisId: number,
    public url: string,
  ) {}
}

export class LoadPedigreeUrlFail implements Action {
  readonly type = LOAD_PEDIGREE_URL_FAIL;

  constructor(
    public analysisId: number,
    public error: any,
  ) {}
}

export const CLEAR_PATIENT_INFO = '[Analysis] Clear patient info';

export class ClearPatientInfo implements Action {
  readonly type = CLEAR_PATIENT_INFO;

  constructor() {}
}

export const EDIT_PATIENT_INFO = '[Analysis] Edit patient info';

export class EditPatientInfo implements Action {
  readonly type = EDIT_PATIENT_INFO;

  constructor(
    public analysisId: number,
    public caseContext: SearchConfig,
    public customData?: any,
  ) {}
}

export const TOGGLE_PHENOTYPE = '[Analysis] Toggle phenotype';

export class TogglePhenotype implements Action {
  readonly type = TOGGLE_PHENOTYPE;

  constructor(public phenotype: string) {}
}

export const TOGGLE_PANEL = '[Analysis] Toggle panel';

export class TogglePanel implements Action {
  readonly type = TOGGLE_PANEL;

  constructor(public panels: CasePanel[]) {}
}

export type CaseDataAction =
  | LoadPatientInfo
  | LoadPatientInfoSuccess
  | LoadPatientInfoFail
  | LoadPedigreeUrl
  | LoadPedigreeUrlSuccess
  | LoadPedigreeUrlFail
  | ClearPatientInfo
  | EditPatientInfo
  | TogglePhenotype
  | TogglePanel;
