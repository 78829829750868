import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'gnx-flex',
  templateUrl: './flex.component.html',
  styleUrls: ['./flex.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class FlexComponent {
  @Input() gap: string;
  @Input() padding: string;
  @Input() inline: boolean;
  @Input() column: boolean;
  @Input() align: 'flex-start' | 'center' | 'flex-end' | 'stretch';
  @Input() justify: 'flex-start' | 'center' | 'flex-end' | 'space-around' | 'space-between' | 'space-evenly';
  @Input() wrap: boolean;

  @HostBinding('style')
  get styles(): Partial<CSSStyleDeclaration> {
    let rowGap: string;
    let columnGap: string;

    if (this.gap) {
      [rowGap, columnGap] = this.gap.split(' ');
      columnGap = columnGap ?? rowGap;
    }

    return {
      display: this.inline ? 'inline-flex' : 'flex',
      flexDirection: this.column ? 'column' : undefined,
      padding: this.padding,
      rowGap,
      columnGap,
      alignItems: this.align,
      justifyContent: this.justify,
      flexWrap: this.wrap ? 'wrap' : undefined,
    };
  }
}
