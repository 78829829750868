<div class="container">
  <div class="header">
    <div class="header-background">
      <img
        src="/assets/png/round@3x.png"
        srcset="assets/png/round@2x.png 2x, assets/png/round@3x.png 3x"
        class="round-1"
      />
      <img
        src="/assets/png/round-copy@3x.png"
        srcset="assets/png/round-copy@2x.png 2x, assets/png/round-copy@3x.png 3x"
        class="round-2"
      />
    </div>
    <gnx-top-nav></gnx-top-nav>
    <div class="header-content">
      <div class="header-extension">
        <div class="back-btn" (click)="location.back()">
          <img src="/assets/svg/blue-back-arrow.svg" />
          <div>Back</div>
        </div>
        <h1>Settings</h1>
      </div>
    </div>
  </div>
  <div class="settings">
    <gnx-dots-loader *ngIf="pageLoading"></gnx-dots-loader>
    <div *ngIf="!pageLoading" class="settings-header">
      <ngx-avatar [bgColor]="'#007da8'" [size]="133" [name]="userDetails.name"></ngx-avatar>
      <div class="left-column">
        <h2>{{ userDetails.name }}</h2>
        <p>{{ userDetails.organization.name }}</p>
      </div>
      <div class="right-column">
        <div>
          <strong>{{ variantsCount$ | async }}</strong>
          <p>Variants Classified</p>
        </div>
      </div>
    </div>
    <div *ngIf="!pageLoading" class="settings-components">
      <mat-tab-group [disableRipple]="true" #matTabGroup>
        <mat-tab label="Profile Details">
          <ng-template matTabContent>
            <gnx-profile-details
              class="tap-content"
              [data]="userDetails"
              [class.overflow-hidden]="matTabGroup.selectedIndex !== 0"
            ></gnx-profile-details>
          </ng-template>
        </mat-tab>
        <mat-tab label="Organization Info">
          <ng-template matTabContent>
            <gnx-organization-info
              class="tap-content"
              [isAdmin]="isAdmin"
              [data]="userDetails"
              [class.overflow-hidden]="matTabGroup.selectedIndex !== 1"
            ></gnx-organization-info>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
