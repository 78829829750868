<gnx-checkbox
  [checked]="!set.size || set.size === list.length ? !!set.size : undefined"
  (checkedChange)="checkedChange.emit($event)"
  [disabled]="disabled"
>
  <div *ngIf="emptyLabel && !set.size" class="checked-variants-label">
    {{ emptyLabel }}
  </div>
  <div *ngIf="!!set.size" class="checked-variants-label">
    <strong>{{ set.size }} {{ set.size !== 1 ? itemName + 's' : itemName }}</strong> selected
  </div>
  <ng-content></ng-content>
</gnx-checkbox>
