import { Gene } from '../../knowledge-base/modules/genes/models';
import { PanelLocation } from '../../modules/variant-page/modules/variant-page/models';

export interface PanelSuggestionResponse {
  count: number;
  next: string;
  previous?: any;
  results: PanelSuggestion[];
}

export interface KbPanelSuggestionResponse {
  count: number;
  genes: Gene[];
}

export interface PanelSuggestion {
  id: number;
  name: string;
  location?: PanelLocation;
  location_desc?: string;
  num_genes?: number;
  uuid?: string;
  description?: string;
  created_at?: Date;
  updated_at?: Date;
  type?: number;
  privacy_level?: number;
  source?: any;
  incomplete_penetrance?: boolean;
  external_uuid?: string;
  input_type?: PanelInputType;
  version?: number;
}

export interface PanelSelectionItem {
  id: string | number;
  name: string;
  num_genes?: number;
  description?: string;
  location?: PanelLocation;
  location_desc?: string;
  uuid?: string;
  input_type?: PanelInputType;
  assay_panel?: boolean;
  public?: boolean;
  latest_version_panel_id?: string;
  version?: number;
}

export const ERROR_PANEL: PanelSuggestion = {
  id: -1,
  name: "We couldn't find any match\nCheck the spelling or try searching a synonym",
};

export interface GenePanel {
  evidence_level?: string;
  panel_name?: string;
  panel_location: number;
  panel_id: number;
  references?: any[];
  inheritance_type?: string;
  diseases?: { name: string }[];
  imprinted_status?: string;
  last_updated?: string;
  penetrance?: string;
}

export interface GenePanelsResponse {
  data: GenePanel[];
}

export enum PANEL_LOCATION {
  Public = 1,
  Private = 2,
}

export type PanelInputType = 'BED' | 'GENES';
