import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[gnxText]',
  standalone: true,
})
export class TextDirective {
  @HostBinding('style.font-size') @Input() size: string;
  @HostBinding('style.line-height') @Input() lineHeight: string;
  @HostBinding('style.color') @Input() color: string;
  @HostBinding('style.white-space') @Input() whiteSpace: 'nowrap' | 'pre' | 'pre-wrap' | 'pre-line' | 'break-spaces';
  @HostBinding('style.font-weight') @Input() fontWeight: string;
  @HostBinding('style.direction') @Input() direction: 'ltr' | 'rtl';
  @HostBinding('style.text-align') @Input() textAlign: 'left' | 'center' | 'right' | 'justify';
  @HostBinding('style.text-transform') @Input() transform: 'capitalize' | 'uppercase' | 'lowercase';
  @HostBinding('style.writing-mode') @Input() writingMode: 'tb' | 'lr' | 'rl';
  @HostBinding('style.line-break') @Input() lineBreak:
    | 'anywhere'
    | 'auto'
    | 'loose'
    | 'normal'
    | 'strict'
    | 'inherit'
    | 'initial';
}
