import { Action } from '@ngrx/store';
import {
  AnalysisResult,
  AnalysisResultSingleData,
  SomaticVariantInterpretation,
  VariantActionType,
  VariantType,
} from '../../../analysis-variant/models';
import { AnalysisType } from '../../../../../../store';

export const TAKE_BULK_VARIANT_ACTION = '[Analysis] Take bulk variant action';
export const TAKE_BULK_VARIANT_ACTION_SUCCESS = '[Analysis] Take bulk variant action success';
export const TAKE_BULK_VARIANT_ACTION_FAIL = '[Analysis] Take bulk variant action fail';

export class TakeBulkVariantAction implements Action {
  readonly type = TAKE_BULK_VARIANT_ACTION;

  constructor(
    public analysisId: number,
    public analysisType: AnalysisType,
    public actionType: VariantActionType,
    public value: boolean,
    public analysisResults?: AnalysisResult[],
    public irrelevantReasons?: string[],
    public irrelevantReasonDetails?: string,
    public clinicalSignificance?: string,
    public clinicalSignificanceDropIndex?: number,
  ) {}
}

export class TakeBulkVariantActionSuccess implements Action {
  readonly type = TAKE_BULK_VARIANT_ACTION_SUCCESS;

  constructor(
    public analysisId: number,
    public analysisType: AnalysisType,
    public actionType: VariantActionType,
    public value: boolean,
    public analysisResults: AnalysisResult[],
    public variantIds: string[],
    public irrelevantReasons?: string[],
    public irrelevantReasonDetails?: string,
    public clinicalSignificance?: string,
    public clinicalSignificanceDropIndex?: number,
  ) {}
}

export class TakeBulkVariantActionFail implements Action {
  readonly type = TAKE_BULK_VARIANT_ACTION_FAIL;

  constructor(
    public actionType: VariantActionType,
    public value: boolean,
    public error: any,
  ) {}
}

export const INCLUDE_IN_REPORT = '[Analysis] Include in report';
export const INCLUDE_IN_REPORT_SUCCESS = '[Analysis] Include in report success';
export const INCLUDE_IN_REPORT_FAIL = '[Analysis] Include in report fail';

export class IncludeInReport implements Action {
  readonly type = INCLUDE_IN_REPORT;

  constructor(
    public value: boolean,
    public variantId: string,
    public variantObject: AnalysisResultSingleData,
    public variantType = VariantType.SNP,
    public compoundVariantId?: string,
  ) {}
}

export class IncludeInReportSuccess implements Action {
  readonly type = INCLUDE_IN_REPORT_SUCCESS;

  constructor(
    public value: boolean,
    public variantId: string,
    public variantObject: AnalysisResultSingleData,
    public variantType = VariantType.SNP,
    public compoundVariantId?: string,
  ) {}
}

export class IncludeInReportFail implements Action {
  readonly type = INCLUDE_IN_REPORT_FAIL;

  constructor(
    public variantId: string,
    public error: any,
    public compoundVariantId?: string,
  ) {}
}

export const MARK_IRRELEVANT = '[Analysis] Mark irrelevant';
export const MARK_IRRELEVANT_SUCCESS = '[Analysis] Mark irrelevant Success';
export const MARK_IRRELEVANT_FAIL = '[Analysis] Mark irrelevant Fail';
export const MARK_IRRELEVANT_DIALOG = '[Analysis] Mark irrelevant dialog';

export class MarkIrrelevant implements Action {
  readonly type = MARK_IRRELEVANT;

  constructor(
    public value: boolean,
    public variantId: string,
    public variantType: VariantType,
    public openReasonDialog = true,
    public reasons?: string[],
    public pairVariantId?: string,
  ) {}
}

export class MarkIrrelevantSuccess implements Action {
  readonly type = MARK_IRRELEVANT_SUCCESS;

  constructor(
    public variantId: string,
    public variantType: VariantType,
    public value: boolean,
    public reasons?: string[],
    public details?: string,
    public pairVariantId?: string,
  ) {}
}

export class MarkIrrelevantFail implements Action {
  readonly type = MARK_IRRELEVANT_FAIL;

  constructor(
    public variantId: string,
    public error: any,
    public pairVariantId?: string,
  ) {}
}

export class MarkIrrelevantDialog implements Action {
  readonly type = MARK_IRRELEVANT_DIALOG;

  constructor(
    public variantId: string,
    public variantType: VariantType,
    public pairVariantId?: string,
  ) {}
}

export const INCLUDE_IN_WORKBENCH_SUCCESS = '[Variants] Include in workbench Success';
export const INCLUDE_IN_WORKBENCH_FAIL = '[Variants] Include in workbench Fail';

export class IncludeInWorkbenchSuccess implements Action {
  readonly type = INCLUDE_IN_WORKBENCH_SUCCESS;

  constructor(
    public variantId: string,
    public value: boolean,
    public pairVariantId: string,
  ) {}
}

export class IncludeInWorkbenchFail implements Action {
  readonly type = INCLUDE_IN_WORKBENCH_FAIL;

  constructor(
    public variantId: string,
    public error: any,
    public pairVariantId: string,
  ) {}
}

export const TOGGLE_VARIANT_CHECKED = '[Analysis] Toggle Variant Checked';

export class ToggleVariantChecked implements Action {
  readonly type = TOGGLE_VARIANT_CHECKED;

  constructor(public analysisResults?: AnalysisResult[]) {}
}

export const CLEAR_VARIANTS_CHECKED = '[Analysis] Clear Variants Checked';

export class ClearVariantsChecked implements Action {
  readonly type = CLEAR_VARIANTS_CHECKED;
}


export const SHOW_NEW_EVIDENCE_POPUP = '[Variants] Show new evidence popup';
export const CLOSE_NEW_EVIDENCE_POPUP = '[Variants] Close new evidence popup';

export class ShowNewEvidencePopup implements Action {
  readonly type = SHOW_NEW_EVIDENCE_POPUP;

  constructor(
    public value: boolean,
    public varIdObj: any,
    public variantInterpretations: any,
  ) {}
}

export class CloseNewEvidencePopup implements Action {
  readonly type = CLOSE_NEW_EVIDENCE_POPUP;
}

export const SHOW_ADD_COMMENT_POPUP = '[Variants] Show add comment popup';
export const CLOSE_ADD_COMMENT_POPUP = '[Variants] Close add comment popup';

export class ShowAddCommentPopup implements Action {
  readonly type = SHOW_ADD_COMMENT_POPUP;

  constructor(public varId: string) {}
}

export class CloseAddCommentPopup implements Action {
  readonly type = CLOSE_ADD_COMMENT_POPUP;
}

export const APPROVE_SUGGESTED_CLASSIFICATION_FAIL = '[Analysis] Approve suggested classification fail';

export class ApproveSuggestedClassificationFail implements Action {
  readonly type = APPROVE_SUGGESTED_CLASSIFICATION_FAIL;

  constructor(public error: any) {}
}

export const UPDATE_SOMATIC_VARIANT_INTERPRETATION_SUCCESS = '[Variants] Update somatic variant interpretation success';

export class UpdateSomaticVariantInterpretationSuccess implements Action {
  readonly type = UPDATE_SOMATIC_VARIANT_INTERPRETATION_SUCCESS;

  constructor(
    public variantId: string,
    public interpretation: SomaticVariantInterpretation,
  ) {}
}

export type VariantInterpretationAction =
  | TakeBulkVariantAction
  | TakeBulkVariantActionSuccess
  | TakeBulkVariantActionFail
  | IncludeInReport
  | IncludeInReportSuccess
  | IncludeInReportFail
  | MarkIrrelevant
  | MarkIrrelevantSuccess
  | MarkIrrelevantFail
  | IncludeInWorkbenchSuccess
  | IncludeInWorkbenchFail
  | ToggleVariantChecked
  | ClearVariantsChecked
  | UpdateSomaticVariantInterpretationSuccess
  | ShowNewEvidencePopup
  | ShowAddCommentPopup;
