import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { Actions, createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { AnalyticsAction, AnalyticsEvent, instanceOfAnalyticsAction } from '../actions/analytics.action';

// @ts-ignore
declare var ga: any;

const waitForGoogleAnalyticsSetup = (): any =>
  new Promise((resolve, reject) => {
    if (ga === undefined) {
      setTimeout(() => (ga === undefined ? reject("Can't set up Google Analytics") : resolve(ga)), 5000);
    } else {
      resolve(ga);
    }
  });

@Injectable()
export class AnalyticsEffect {
  constructor(private actions$: Actions) {}

  analyticsEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        tap((action: Action) => {
          if (instanceOfAnalyticsAction(action)) {
            const event = (action as AnalyticsAction).analyticsEvent as AnalyticsEvent;
            waitForGoogleAnalyticsSetup()
              .then((ga) => ga(`${ga.getAll()[0].get('name')}.send`, { hitType: 'event', ...event }))
              .catch((err) => console.error(err));
          }
        }),
        catchError((err, caught) => {
          console.error('Error with tapping for events', err);
          return caught;
        }),
      ),
    { dispatch: false },
  );
}
