export interface OrganizationTagsConfig {
  tags: {
    name: string;
    parent: string;
  }[];
}

export interface OrganizationConfig {
  interpretation_tags_config: OrganizationTagsConfig;
  sv_interpretation_tags_config: OrganizationTagsConfig;
  somatic_interpretation_tags_config: OrganizationTagsConfig;
  somatic_sv_interpretation_tags_config: OrganizationTagsConfig;
  is_internal_frequency_by_gene_enabled: boolean;
  use_point_based_interpretation: boolean;
  support_draft_approval: boolean;
  knowledge_base_config: { show_only_curated_conditions: boolean };
}

export interface InterpretationTagForUI {
  name: string;
  children?: string[];
}
