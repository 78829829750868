import { CommunityClassificationState, getCommunityClassificationState } from '../reducers';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromReducer from '../reducers/clinvar.reducer';

export const getClinvarState: MemoizedSelector<CommunityClassificationState, fromReducer.ClinvarState> = createSelector(
  getCommunityClassificationState,
  (state: CommunityClassificationState) => state.clinvar,
);

export const getClinvarSubmitting: MemoizedSelector<CommunityClassificationState, boolean> = createSelector(
  getClinvarState,
  fromReducer.getSubmitting,
);

export const getClinvarSubmited: MemoizedSelector<CommunityClassificationState, boolean> = createSelector(
  getClinvarState,
  fromReducer.getSubmitted,
);
