<div gnxBlock background="#fff" border="0 0 1px 0 solid #d5d7db" position="relative">
  <div gnxFlexItem displayFlex="true" minHeight="150px" align="center" justify="center" column="true">
    <div gnxText size="14px" lineHeight="18px">FILTER TREE</div>
    <div gnxText size="34px" lineHeight="54px">{{ title }}</div>
  </div>
  <img
    src="assets/svg/x-blue.svg"
    (click)="dialogRef.close()"
    gnxBlock
    position="absolute"
    inset="16px 16px auto auto"
    cursor="pointer"
  />
</div>
<div gnxFlexItem flex="1" [formGroup]="treeFormGroup" gnxBlock height="100%" overflow="auto">
  <div gnxBlock padding="0 35px" margin="0 auto">
    <div gnxFlex gap="10px" column="true" gnxBlock padding="32px 0 32px 0">
      <div>
        <label gnxLabel [error]="!treeFormGroup.valid && treeFormGroup.get('name').hasError('inUse')">
          Name*
          <ng-container *ngIf="!treeFormGroup.valid && treeFormGroup.get('name').hasError('inUse')">
            - already in use
          </ng-container>
        </label>
        <div gnxBlock position="relative">
          <input
            gnxInput
            formControlName="name"
            placeholder="Filter tree name"
            [class.disabled]="!(treeNamesLoaded$ | async)"
          />
          <gnx-dots-loader *ngIf="!(treeNamesLoaded$ | async)" gnxBlock position="absolute" inset="10px 0" />
        </div>
      </div>
      <div gnxFlex gap="10px">
        <div gnxFlexItem flex="1" [class.disabled]="!isNew || isClone">
          <label for="caseType">Filter case type*</label>
          <mat-select
            gnxSelect
            id="caseType"
            formControlName="case_type"
            placeholder="Choose"
            (selectionChange)="onCaseTypeChange($event)"
          >
            <mat-option *ngFor="let option of FILTER_TREE_CASE_TYPE_OPTIONS" [value]="option.value">{{
              option.label
            }}</mat-option>
          </mat-select>
        </div>
        @if (formGroup.get(['tree', 'variant_type']).value) {
          <div
            gnxFlexItem
            flex="1"
            [class.disabled]="!isNew || isClone || cnvTreeDisabled || treeFormGroup.get('case_type').value === 'family'"
          >
            <label for="variantType">Filter variant type*</label>
            <mat-select gnxSelect id="variantType" formControlName="variant_type">
              <mat-option *ngFor="let option of FILTER_TREE_VARIANT_TYPE_OPTIONS" [value]="option.value">
                {{ option.label }}
              </mat-option>
            </mat-select>
          </div>
        }
      </div>
      <div [formGroup]="formGroup">
        <label for="assay">Assay</label>
        <gnx-auto-complete-with-chips
          id="assay"
          formControlName="tree_assays"
          placeholder="Choose"
          [selections]="formGroup.get('tree_assays')?.value || []"
          [options]="assaysAsOptions$ | async"
          [optionsLoading]="assaysLoading$ | async"
        ></gnx-auto-complete-with-chips>
      </div>
      <div>
        <gnx-checkbox formControlName="use_phenotypes_as_filter">
          <div gnxFlex gap="4px">
            <div>Use case phenotypes as filters</div>
            <div
              matTooltip="When activated Franklin will use the case phenotypes to filter out genes unrelated to the clinical case"
              matTooltipClass="blue-md-tooltip wider"
            >
              <img src="assets/svg/info-blue.svg" width="12" height="12" />
            </div>
          </div>
        </gnx-checkbox>
      </div>
      <div>
        <label gnxLabel>Description</label>
        <textarea
          gnxInput
          formControlName="description"
          placeholder="Filter tree description"
          gnxTextArea
          resize="none"
          rows="4"
        ></textarea>
      </div>
    </div>
  </div>
</div>
<mat-dialog-actions
  gnxBlock
  border="1px 0 0 solid #d5d7db"
  padding="0 16px"
  gnxFlexItem
  flex="0 0 69px"
  gap="16px"
  justify="flex-end"
  background="#fff"
>
  @if (!this.isNew) {
    <div
      gnxBlock
      margin="0 auto 0 0"
      matTooltip="Filter tree is associated with cases in the organization"
      [matTooltipDisabled]="data.isDeleteAllowed"
      matTooltipClass="blue-md-tooltip wide"
    >
      <gnx-button type="red" padded="true" size="h42" (click)="onDelete()" [disabled]="!data.isDeleteAllowed">
        Delete filter tree
      </gnx-button>
    </div>
  }
  <a gnxAnchor (click)="onCancel()">Cancel</a>
  <gnx-button
    type="blue"
    padded="true"
    size="h42"
    (click)="onSave()"
    [disabled]="!formGroup.valid || formGroup.pristine"
  >
    {{ isNew ? 'Create' : 'Save' }}
  </gnx-button>
</mat-dialog-actions>
