export class ValueRange {
  f: number;
  t: number;

  constructor(f: number, t: number) {
    this.f = isNaN(f) ? null : f;
    this.t = isNaN(t) ? null : t;
  }

  public toString = (): string => {
    return `${this.getValueString(this.f)}__${this.getValueString(this.t)}`;
  };

  private getValueString(n: number): string {
    return n === null ? 'null' : n.toString();
  }
}
