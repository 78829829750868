export interface ClinicalSignificanceMetadata {
  key: string;
  label: string;
  colorClass: string;
}

export interface ClinicalSignificanceMapObject {
  [key: string]: ClinicalSignificanceMetadata;
}

export const CLINICAL_SIGNIFICANCE_ARRAY: ClinicalSignificanceMetadata[] = [
  { key: 'Other', label: 'Other', colorClass: 'uncertain' },
  { key: 'Benign', label: 'B', colorClass: 'benign' },
  { key: 'Likely benign', label: 'LB', colorClass: 'likely-benign' },
  { key: 'Likely pathogenic', label: 'LP', colorClass: 'likely-pathogenic' },
  { key: 'Pathogenic', label: 'P', colorClass: 'pathogenic' },
  { key: 'Uncertain', label: 'VUS', colorClass: 'uncertain' },
  { key: 'Conflicting interpretations of pathogenicity', label: 'Pathogenicity conflict', colorClass: 'none' },
  { key: 'Drug response', label: 'Drug response', colorClass: 'none' },
  { key: 'Protective', label: 'Protective', colorClass: 'none' },
  { key: 'Risk factor', label: 'Risk factor', colorClass: 'pathogenic' },
  { key: 'Different', label: 'Different', colorClass: 'none' },
  { key: 'Not provided', label: 'Not provided', colorClass: 'none' },
  { key: 'Conflicting data from submitters', label: 'Data Conflict', colorClass: 'none' },
];

export const CLINICAL_SIGNIFICANCE_BY_KEY: ClinicalSignificanceMapObject = CLINICAL_SIGNIFICANCE_ARRAY.reduce(
  (prev, val) => {
    return {
      ...prev,
      [val.key]: val,
    };
  },
  {},
);
