import { ApplicationRef, Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { Moment } from 'moment';

import { Language, languages } from './languages.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class I18nService {
  private static state: Subject<{ [key: string]: string }>;
  private static data: { [key: string]: string };
  private static currentLanguage: Language;

  constructor(
    private http: HttpClient,
    private ref: ApplicationRef,
    private dateAdapter: DateAdapter<Moment>,
  ) {
    if (!I18nService.state) {
      I18nService.state = new Subject();
    }

    const locale: string = localStorage.getItem('locale');
    this.initLanguage(locale || 'en');
  }

  private fetch(locale: string) {
    this.http.get(`assets/langs/${locale}.json`).subscribe((data: { [key: string]: string }) => {
      I18nService.data = data;
      I18nService.state.next(data);
      this.ref.tick();
    });
  }

  private initLanguage(locale: string) {
    const language: Language = languages.find((it) => it.key === locale);
    if (language) {
      this.applyLanguage(language);
    } else {
      throw new Error(`Incorrect locale used for I18nService: ${locale}`);
    }
  }

  isReady(): boolean {
    return !!I18nService.data;
  }

  setLanguage(language: Language) {
    localStorage.setItem('locale', language.key);

    this.applyLanguage(language);
  }

  private applyLanguage(language: Language) {
    if (!I18nService.currentLanguage || I18nService.currentLanguage.key !== language.key) {
      I18nService.currentLanguage = language;
      moment.locale(language.key);
      this.dateAdapter.setLocale(language.key);
      this.fetch(language.key);
    }
  }

  getLanguage(): Language {
    return I18nService.currentLanguage;
  }

  subscribe(sub: any, err?: any): Subscription {
    return I18nService.state.subscribe(sub, err);
  }

  /**
   * A translation body could contain variables indexed by numbers.
   * Variables substitution used only if second argument (templateVars) provided.
   *
   * For example: const result = this.i18n.getTranslation('No such name ${0} in the list ${1}', ['username', 'userlist']);
   * The result will be 'No such name username in the list userlist'
   */
  public getTranslation(phrase: string, templateVars?: string[]): string {
    const value = (I18nService.data && I18nService.data[phrase]) || phrase;
    if (templateVars) {
      return templateVars.reduce((result: string, templateVar: string, idx: number): string => {
        return result.replace(`\$\{${idx}\}`, templateVar);
      }, value);
    } else {
      return value;
    }
  }

  public getMomentLocaleKey() {
    let key = this.getLanguage().key;
    if (key === 'us') {
      key = 'en';
    }
    if (key === 'cn') {
      key = 'zh-cn';
    }
    return key;
  }
}
