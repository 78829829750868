import {
  GET_ORGANIZATION_CUSTOM_SCHEMA,
  GET_ORGANIZATION_CUSTOM_SCHEMA_FAIL,
  GET_ORGANIZATION_CUSTOM_SCHEMA_SUCCESS,
  OrganizationCustomSchemaAction,
} from '../actions';
import { OrganizationCustomSchema } from '../models';

export interface OrganizationCustomSchemaState {
  data: OrganizationCustomSchema;
  loading: boolean;
  loaded: boolean;
}

export const initialState: OrganizationCustomSchemaState = {
  data: undefined,
  loading: false,
  loaded: false,
};

export const reducer = (
  state = initialState,
  action: OrganizationCustomSchemaAction,
): OrganizationCustomSchemaState => {
  switch (action.type) {
    case GET_ORGANIZATION_CUSTOM_SCHEMA: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ORGANIZATION_CUSTOM_SCHEMA_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
      };
    }
    case GET_ORGANIZATION_CUSTOM_SCHEMA_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
};
