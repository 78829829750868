import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gnx-privacy-label',
  templateUrl: './privacy-label.component.html',
  styleUrls: ['./privacy-label.component.scss'],
  standalone: true,
})
export class PrivacyLabelComponent implements OnInit {
  @Input() patientLabel = 'patient';

  constructor() {}

  ngOnInit(): void {}
}
