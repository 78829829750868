import { VariantNormalFrequency } from '../../shared/models/frequencies';
import { VariantHighlight } from './variant-highlights.model';
import { VariantEvidencesDataModel } from '../../../../../store/models/variant-evidences-selection.nodel';
import { VariantLabelModel } from './variant-label.model';
import { ExtraVCFAnnotation } from './sv-result.model';
import { BaseVariant } from './analysis-result.model';
import { VariantCaller } from './variant-caller.model';
import { Linkout } from '../../../../../store/models/variant-linkouts.model';
import { FilterTreeLabel } from '../../../../../shared-lazy/models';

export interface OmimConditionData {
  omim_id: string;
  link: string;
  inheritance_models: string[];
}

export interface AssociatedCondition {
  name: string;
  inheritance_models: string[];
  external_sources_data: {
    omim: OmimConditionData[];
  };
  max_alphabeta: number;
  max_alphabeta_description: string;
  sources: number[];
}

export interface PhenotypeRelevance {
  prioritizedRelevantDiseases: string[];
  relevantPhenotypes: string[];
  score: number;
  relevantSearchTerms: string[];
  missingSearchTerms: string[];
}

export interface SnpChange {
  ref: string;
  obs: string;
}

export interface CustomAnnotation {
  custom_annotation: {
    annotation_name: string;
    annotation_index?: number;
    annotation_type: string;
  };
  match: string;
}

export interface SubmissionsSummary {
  evidence_sources: string[];
  count_by_classification: CountByClassification;
  entries: { count: number; classification: string }[];
  total_num_classifications: number;
}

export interface CountByClassification {
  [classificationKey: string]: number;
}

export interface CuratedSubmissions {
  all_classifications: string[];
  clinvar?: {
    total_count: number;
    variant_link: string;
    classifications: {
      count: number;
      classification: string;
    }[];
  };
  uniprot?: {
    uniprot_id: string;
    variant_type: string;
    variant_link: string;
  };
  submissions_summary: SubmissionsSummary;
}

export interface SnpInterpretations {
  [key: number]: SnpInterpretation;

  bitmap: number;
  draft_classification_flag?: number;
  interpretation_tags?: InterpretationTag[];
  latest_interpretation_save_type?: 'NONE' | 'DRAFT' | 'FINAL' | 'PRE_APPROVAL';
  curated_conditions?: CuratedCondition[];
}

export interface CuratedCondition {
  assessment: string;
  condition: string;
  inheritance_model: string;
}

export interface InterpretationTag {
  tag: string;
}

export interface SnpInterpretation {
  acmg_rules: string[];
  user_id: number;
  text: string;
  flag: number;
  analysis: string;
  created_on: string;
  updated_on: string;
  interpretation_evidence?: VariantEvidencesDataModel;
  draft: boolean;
  interpretation_sub_level?: string;
  interpretation_tags: InterpretationTag[];
}

export interface SnpDiseases {
  count: number;
  diseases_evidence_strength: string;
  data: AssociatedCondition[];
  inheritance_models: string[];
}

export interface SnpPublicationsData {
  genePublicationsCount: number;
  variantPublicationsCount: number;
}

export interface SnpUserInterpretation {
  flag: number;
  draft?: boolean;
  interpretation_sub_level?: string;
  latest_interpretation_save_type?: 'NONE' | 'DRAFT' | 'FINAL' | 'PRE_APPROVAL';
  curated_conditions?: CuratedCondition[];
}

export interface ClinicalSignificance {
  significance: string;
  reasoning?: string;
  reasonings: string[];
  text_information: string;
}

export interface LatestComment {
  content: string;
  message_id: string;
  timestamp: number;
  topic_id: string;
  user_email: string;
  user_name: string;
  is_private?: boolean;
}

export interface FamilyComparisonResult {
  analysis_id: number;
  analysis_name: string;
  highest_classification?: string;
  gene_symbol?: string;
  in_family_panel?: boolean;
}

export interface InternalFrequencyData {
  internal_frequency_percentage: number;
  number_of_samples: number;
  num_homozygotes?: number;
  sample_ids: string[];
}

export interface StrandReadCount {
  forward_strand_ref_allele_count: number;
  forward_strand_alt_allele_count: number;
  backward_strand_ref_allele_count: number;
  backward_strand_alt_allele_count: number;
}

export interface SnpResult extends BaseVariant {
  var_id: string;
  chr: string;
  start_pos: number;
  end_pos: number;
  closest_distance: number;
  closest_distance_ens: number;
  alpha_missense: number;
  bayes_del: number;
  exon: number;
  exon_ens: number;
  gene: string;
  ref: string;
  obs: string;
  region: string;
  effect: string;
  hgvs_c: string;
  hgvs_c_ens: string;
  hgvs_p: string;
  hgvs_p_single_letter: string;
  hgvs_p_ens: string;
  transcript: string;
  transcript_ens: string;
  dbsnp: string;
  zygosity: string;
  quality: number;
  gq: number;
  pl: string;
  gl: string;
  depth: number;
  depth_ref: number;
  depth_obs: number;
  ab: number;
  fisher_strand: number;
  read_pos_rank_sum: number;
  mq: number;
  mq_rank_sum: number;
  read_counts_per_strand?: number;
  strand_read_counts?: StrandReadCount;
  median_base_quality?: number;
  read_pos?: number;
  median_fragment_length?: number;
  classification: number;
  aggregated_frequency: number;
  confidence: number;
  confidence_text: string;
  aggregated_prediction: number;
  aggregated_prediction_desc: string;
  sift: number;
  sift_pred: string;
  polyphen2: number;
  polyphen2_pred: string;
  mt: number;
  mt_pred: string;
  ma: number;
  ma_pred: string;
  fathmm: number;
  fathmm_pred: string;
  ada_score: number;
  ada_pred: string;
  gerp: number;
  genocanyon_score: number;
  genocanyon_pred: string;
  integrated_fitcons_score: number;
  integrated_fitcons_pred: string;
  diseases: SnpDiseases;
  frequencies: {
    [key: string]: VariantNormalFrequency | number;
  };
  max_frequency: number;
  curated_submissions: CuratedSubmissions;
  interpreted_community_assessments: string[];
  interpretations: SnpInterpretations;
  publications_data?: SnpPublicationsData;
  closest_exon: number;
  closest_exon_ens: number;
  phenotypes_search_data?: PhenotypeRelevance;
  user_annotations: CustomAnnotation[];
  in_workbench: boolean;
  user_interpretation: SnpUserInterpretation;
  revel: number;
  revel_pred: string;
  metalr: number;
  metalr_pred: string;
  splice_ai: number;
  splice_ai_pred: string;
  splice_ai_exome: number;
  splice_ai_exome_pred: string;
  splice_ai_genome: number;
  splice_ai_genome_pred: string;
  ncer_pred: string;
  ncer: number;
  inheritance_model?: string;
  inheritance_model_short?: string;
  variant_callers_text: string;
  variant_callers_toolip: string;
  variant_callers: VariantCaller[];
  suspected_compound_stats: {
    pathogenic: number;
    likely_pathogenic: number;
    vus: number;
  };
  suspected_snp_sv_compound_stats: {
    pathogenic: number;
    likely_pathogenic: number;
    vus: number;
  };
  clinical_significance_data?: ClinicalSignificance;
  highlights: VariantHighlight[];
  community_frequency?: {
    num_samples?: number;
    num_homozygote?: number;
  };
  filter_value: string;
  parentage: {
    is_from_father: boolean;
    is_from_mother: boolean;
    is_father_affected: boolean;
    is_mother_affected: boolean;
    father_zygosity: string;
    mother_zygosity: string;
  };
  relevant_prediction_tool_data: {
    tool: string;
    tool_score: number;
    prediction_description: string;
  };
  debug_message: string;
  samples: {
    confidence: string;
    depth_alt: number;
    depth_obs: number;
    internal_id: string;
    relation: string;
    zygosity: string;
  }[];
  primitive_variants: string[];
  vaf: number;
  latest_comment: LatestComment;
  is_manually_called?: boolean;
  labels: Array<VariantLabelModel>;
  extra_vcf_annotations: ExtraVCFAnnotation[];
  relation: string;
  families_comparison_result?: FamilyComparisonResult[];
  somatic_internal_frequency_data: InternalFrequencyData;
  internal_frequency_data: InternalFrequencyData;
  phasing_data?: {
    allele_type: 'ALLELE_A' | 'ALLELE_B';
    phase_set: number;
  };
  priority_score?: number;
  linkouts?: Linkout[];
  filter_tree_labels?: FilterTreeLabel[];
  classification_rules: { rule_id: string; influence: string }[];
  dragen_tags?: {
    mnv_tag: string;
    is_mosaic: boolean;
    is_homology: boolean;
  };
}
