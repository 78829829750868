import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import * as fromActions from '../../../../../../store/actions/variant-evidences-selection.action';
import * as fromInterpretationsActions from '../../../workbench/store/actions/variant-interpretation.action';
import { AppModuleState, getAnalysisId, getVariantEvidencesOptions } from '../../../../../../store';
import { debounceTime, takeUntil, withLatestFrom } from 'rxjs/operators';
import {
  VariantEvidenceOptionModel,
  VariantEvidenceValueModel,
} from '../../../../../../store/models/variant-evidences-selection.nodel';
import { MatSelect } from '@angular/material/select';
import { Actions, ofType } from '@ngrx/effects';
import { Subject } from 'rxjs';
import { DotsLoaderComponent } from '../../../../../../shared/components/dots-loader/dots-loader.component';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatIcon } from '@angular/material/icon';
import { MatChipListbox, MatChipOption, MatChipRemove } from '@angular/material/chips';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClickCursorDirective } from '../../../../../../shared/directives/click-cursor.directive';

interface VariantEvidenceOptionViewModel extends VariantEvidenceOptionModel {
  selected?: any;
  model?: any;
  shown?: boolean;
}

@Component({
  selector: 'gnx-add-evidence-to-variant-dialog',
  templateUrl: './add-evidence-to-variant-dialog.component.html',
  styleUrls: ['./add-evidence-to-variant-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    ClickCursorDirective,
    MatSelect,
    ReactiveFormsModule,
    FormsModule,
    NgFor,
    NgIf,
    MatOption,
    MatCheckbox,
    MatRadioGroup,
    MatRadioButton,
    MatChipListbox,
    MatChipOption,
    MatIcon,
    MatChipRemove,
    CdkTextareaAutosize,
    MatDialogActions,
    DotsLoaderComponent,
  ],
})
export class AddEvidenceToVariantDialogComponent implements OnInit, OnDestroy {
  @ViewChild(MatSelect) groupsSelect: MatSelect;
  evidenceValues: VariantEvidenceValueModel[] = [];
  evidenceValuesB: VariantEvidenceValueModel[] = [];
  evidenceOptions: VariantEvidenceOptionViewModel[];
  public details = '';
  private destroyed$: Subject<void> = new Subject();
  public saving = false;

  constructor(
    private dialogRef: MatDialogRef<AddEvidenceToVariantDialogComponent>,
    private rootStore$: Store<AppModuleState>,
    private actions$: Actions,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.rootStore$.dispatch(new fromActions.GetVariantEvidencesOptions());

    this.rootStore$
      .pipe(
        select(getVariantEvidencesOptions),
        withLatestFrom(this.rootStore$.pipe(select(getAnalysisId))),
        debounceTime(100),
        takeUntil(this.destroyed$),
      )
      .subscribe(([options, analysisId]) => {
        const analysisEvidences = this.data.interpretations?.[analysisId]?.interpretation_evidence;
        this.details = analysisEvidences?.details;

        this.evidenceOptions = options?.length
          ? options.map((item) => {
              let matchIndex = -1;
              if (analysisEvidences) {
                if (item.value_options?.length) {
                  matchIndex = analysisEvidences.categories.findIndex(
                    (category: VariantEvidenceValueModel) =>
                      category.evidence_category_name === item.evidence_category_name &&
                      item.value_options.find((opt) => opt === category.value),
                  );
                } else {
                  const res = analysisEvidences.categories.find(
                    (category: VariantEvidenceValueModel) =>
                      category.evidence_category_name === item.evidence_category_name,
                  );
                  if (res) {
                    this.evidenceValues.push(res.evidence_category_name);
                  }
                }
              }
              return {
                ...item,
                selected: matchIndex > -1 ? analysisEvidences.categories[matchIndex].value : false,
                model: matchIndex > -1 ? analysisEvidences.categories[matchIndex] : undefined,
                shown: matchIndex > -1,
              };
            })
          : [];
        this.getChips();
      });

    this.actions$
      .pipe(ofType(fromInterpretationsActions.CLOSE_NEW_EVIDENCE_POPUP), takeUntil(this.destroyed$))
      .subscribe(() => this.onClose());
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  onClose() {
    this.dialogRef.close();
  }

  onEvidenceValuesChange(values) {
    this.getChips();
  }

  onRadioChange(e, value) {
    value.model = {
      evidence_category_name: value.evidence_category_name,
      value: e.value,
    };
    this.getChips();
  }

  onCheckboxClick(event, value) {
    if (event.checked) {
      value.selected = value.value_options[0];
      value.shown = true;
      value.model = {
        evidence_category_name: value.evidence_category_name,
        value: value.value_options[0],
      };
    } else {
      value.selected = false;
      value.model = null;
    }
    this.getChips();
  }

  remove(event, value) {
    let matchInModel;
    if (value.value) {
      matchInModel = this.evidenceValues.findIndex(
        (item) => item.evidence_category_name === value.evidence_category_name && item.value === value.value,
      );
    } else {
      matchInModel = this.evidenceValues.findIndex((item) => item === value.evidence_category_name);
    }
    if (matchInModel > -1) {
      this.evidenceValues.splice(matchInModel, 1);
    }
    const matchInOptions = this.evidenceOptions.findIndex(
      (item) =>
        item.model?.evidence_category_name === value.evidence_category_name && item.model?.value === value.value,
    );
    if (matchInOptions > -1) {
      this.evidenceOptions[matchInOptions] = {
        ...this.evidenceOptions[matchInOptions],
        selected: false,
        model: null,
      };
    }
    this.getChips();
  }

  getChips() {
    this.evidenceValuesB = [
      ...this.evidenceValues.map((item) => {
        return {
          evidence_category_name: item,
          value: '',
        };
      }),
      ...this.evidenceOptions
        .filter((item) => item.model)
        .map((item) => {
          return item.model;
        }),
    ];

    this.updateValueStore();
  }

  updateValueStore() {
    this.rootStore$.dispatch(
      new fromActions.SetVariantEvidencesValues({
        variantEvidenceValues: [...this.evidenceValuesB],
        details: this.details,
      }),
    );
  }

  saveChanges() {
    this.saving = true;
    this.rootStore$.dispatch(new fromActions.SaveEvidencesForVariant(this.data.varIdObj));
  }

  public toggleShown(event: MouseEvent, option: VariantEvidenceOptionViewModel) {
    event.preventDefault();
    option.shown = !option.shown;
  }
}
