import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { ClickCursorDirective } from '../../../../../../shared/directives/click-cursor.directive';

@Component({
  selector: 'gnx-export-as-excel-dialog',
  templateUrl: './export-as-excel-dialog.component.html',
  styleUrls: ['./export-as-excel-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, ClickCursorDirective],
})
export class ExportAsExcelDialogComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ExportAsExcelDialogComponent>) {}

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
  }
}
