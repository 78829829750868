import { createSelector, MemoizedSelector } from '@ngrx/store';
import * as fromReducer from '../reducers/phenotypes.reducer';
import { PhenotypesState } from '../reducers/phenotypes.reducer';
import { AppModuleState, getAppModuleState } from '../reducers';
import { GenePhenotypesResponse, Phenotype } from '../models';

const getPhenotypesState: MemoizedSelector<AppModuleState, PhenotypesState> = createSelector(
  getAppModuleState,
  (state: AppModuleState) => state.phenotypes,
);

export const getPhenotypes: MemoizedSelector<AppModuleState, Phenotype[]> = createSelector(
  getPhenotypesState,
  fromReducer.getPhenotypeSuggestions,
);

export const getPhenotypesLoading: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getPhenotypesState,
  fromReducer.getPhenotypesLoading,
);

export const getPhenotypesLoaded: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getPhenotypesState,
  fromReducer.getPhenotypesLoaded,
);

export const getGenePhenotypes: MemoizedSelector<AppModuleState, GenePhenotypesResponse> = createSelector(
  getPhenotypesState,
  fromReducer.getGenePhenotypeSuggestions,
);

export const getGenePhenotypesLoading: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getPhenotypesState,
  fromReducer.getGenePhenotypesLoading,
);

export const getGenePhenotypesLoaded: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getPhenotypesState,
  fromReducer.getGenePhenotypesLoaded,
);

export const getPhenotypesError: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getPhenotypesState,
  fromReducer.getPhenotypesError,
);
