<div class="close-btn" (click)="onClose()">
  <img src="/assets/svg/esc-blk.svg" />
</div>
<div class="top-section"></div>
<div class="bottom-section">
  <span class="img-wrapper">
    <img [src]="data?.imgSrc || '/assets/svg/v-green.svg'" [srcset]="data?.imgSrcSet || '/assets/svg/v-green.svg'" />
  </span>
  <div class="title">
    {{ data?.title || 'Request Submitted' }}
  </div>
  <div class="subtitle">
    <b>{{ data?.boldDescription || 'This may take a while' }}</b
    ><br />
    {{ data?.description || 'We will notify you by email when the upload is completed.' }}
  </div>
  <input *ngIf="data.askForEmail" placeholder="yourname@company.com" />
  <button class="ok-btn" mat-dialog-close [disabled]="data.askForEmail && emailFormControl?.invalid">
    {{ data?.buttonLabel || 'CLOSE' }}
  </button>
</div>
