import { Action } from '@ngrx/store';
import { SearchConfig, VariantId } from '../../models';
import { ContactNetworkData } from '../../models/contact.model';

export const OPEN_CONTACT_MEMBERS_POPUP = '[Variant Page] Open Contact Member Popup';

export class OpenContactMembersPopup implements Action {
  readonly type = OPEN_CONTACT_MEMBERS_POPUP;

  constructor(
    public top: number,
    public left: number,
    public contactCallback: () => void,
    public cancelCallback: () => void,
    public mainMessage?: string,
  ) {}
}

export const CONTACT_MEMBERS = '[Variant Page] Contact Member';

export class ContactMembers implements Action {
  readonly type = CONTACT_MEMBERS;

  constructor(
    public contextVariant: VariantId,
    public subjectVariant?: VariantId,
    public postId?: string,
    public sampleId?: string,
    public sigId?: string,
    public gene?: string,
    public samplesAffected?: { sample_identifier: string }[],
  ) {}
}

export const CONTACT_MEMBERS_SUCCESS = '[Variant Page] Contact Member Success';

export class ContactMembersSuccess implements Action {
  readonly type = CONTACT_MEMBERS_SUCCESS;

  constructor(
    public contextVariant: VariantId,
    public subjectVariant?: VariantId,
    public postId?: string,
    public sampleId?: string,
    public sigId?: string,
    public gene?: string,
  ) {}
}

export const CONTACT_MEMBERS_FAIL = '[Variant Page] Contact Member Fail';

export class ContactMembersFail implements Action {
  readonly type = CONTACT_MEMBERS_FAIL;

  constructor(public error: string) {}
}

export const CONTACT_NETWORK = '[Variant Page] Contact Network';

export class ContactNetwork implements Action {
  readonly type = CONTACT_NETWORK;

  constructor(
    public title: string,
    public body: string,
    public variant: VariantId,
    public context: SearchConfig,
  ) {}
}

export const CONTACT_NETWORK_SUCCESS = '[Variant Page] Contact Network Success';

export class ContactNetworkSuccess implements Action {
  readonly type = CONTACT_NETWORK_SUCCESS;

  constructor() {}
}

export const CONTACT_NETWORK_FAIL = '[Variant Page] Contact Network Fail';

export class ContactNetworkFail implements Action {
  readonly type = CONTACT_NETWORK_FAIL;

  constructor(public error: string) {}
}

export const GET_CONTACT_NETWORK_DATA = '[Variant Page] Get Contact Network Data';

export class GetContactNetworkData implements Action {
  readonly type = GET_CONTACT_NETWORK_DATA;

  constructor(public variant: VariantId) {}
}

export const GET_CONTACT_NETWORK_DATA_SUCCESS = '[Variant Page] Get Contact Network Data Success';

export class GetContactNetworkDataSuccess implements Action {
  readonly type = GET_CONTACT_NETWORK_DATA_SUCCESS;

  constructor(public data: ContactNetworkData) {}
}

export const GET_CONTACT_NETWORK_DATA_FAIL = '[Variant Page] Get Contact Network Data Fail';

export class GetContactNetworkDataFail implements Action {
  readonly type = GET_CONTACT_NETWORK_DATA_FAIL;

  constructor(public error: string) {}
}

export type ContactAction =
  | OpenContactMembersPopup
  | ContactMembers
  | ContactMembersSuccess
  | ContactMembersFail
  | ContactNetwork
  | ContactNetworkSuccess
  | ContactNetworkFail
  | GetContactNetworkData
  | GetContactNetworkDataSuccess
  | GetContactNetworkDataFail;
