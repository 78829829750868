import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Ethnicity } from '../models';
import { environment } from '../../../environments/environment';

@Injectable()
export class EthnicitiesService {
  constructor(private http: HttpClient) {}

  getEthnicityOptions(): Observable<Ethnicity[]> {
    return this.http.get<Ethnicity[]>(`${environment.beBaseURL}/api/api/ethnicity/list/`);
  }
}
