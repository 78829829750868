import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadGenePopup } from '../actions/gene-page-popup.action';
import { tap } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class GenePagePopupEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
  ) {}

  loadGenePopup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadGenePopup),
        tap((action) =>
          this.router.navigate([{ outlets: { dialog: 'gene/dialog' } }], {
            queryParamsHandling: 'preserve',
            state: action.config,
          }),
        ),
      ),
    { dispatch: false },
  );
}
