import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';
import { I18nService } from './i18n.service';
import { Language } from './languages.model';

@Directive({
  selector: '[gnxRtl]',
  exportAs: 'gnxRtl',
  standalone: true,
})
export class RtlDirective implements AfterViewInit {
  lang: Language;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private i18n: I18nService,
  ) {}

  public ngAfterViewInit(): void {
    this.handleDirection();
    this.i18n.subscribe((l) => {
      this.handleDirection();
    });
  }

  handleDirection() {
    this.lang = this.i18n.getLanguage();
    if (this.lang.key === 'hb') {
      this.renderer.setAttribute(this.el.nativeElement, 'dir', 'rtl');
    } else {
      this.renderer.setAttribute(this.el.nativeElement, 'dir', 'ltr');
    }
  }

  getLocaleKey() {
    return this.lang?.dateLocaleKey || 'en-US';
  }
}
