import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AppModuleState, getAppModuleState } from '../reducers';
import * as fromReducer from '../reducers/ethnicities.reducer';
import { ERROR_ETHNICITY, Ethnicity, EthnicityEntities } from '../models';
import { MemoizedSelectorWithProps } from '@ngrx/store/src/selector';

export const getEthnicitiesState: MemoizedSelector<AppModuleState, fromReducer.EthnicitiesState> = createSelector(
  getAppModuleState,
  (state: AppModuleState) => state.ethnicity,
);

export const getEthnicityOptions: MemoizedSelector<AppModuleState, Ethnicity[]> = createSelector(
  getEthnicitiesState,
  fromReducer.getEthnicityOptions,
);

export const searchEthnicityOptions: MemoizedSelectorWithProps<
  AppModuleState,
  {
    searchTerm: string;
  },
  Ethnicity[]
> = createSelector(getEthnicityOptions, (list, { searchTerm }) => {
  list = !searchTerm ? list : list.filter((value) => value.name.toLowerCase().startsWith(searchTerm.toLowerCase()));
  return list.length ? list : [ERROR_ETHNICITY];
});

export const getEthnicityEntities: MemoizedSelector<AppModuleState, EthnicityEntities> = createSelector(
  getEthnicityOptions,
  (ethnicities: Ethnicity[]) =>
    ethnicities.reduce((result: EthnicityEntities, eth: Ethnicity) => ({ ...result, [eth.id]: eth }), {}),
);

export const getEthnicityOptionsLoading: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getEthnicitiesState,
  fromReducer.getEthnicityLoading,
);

export const getEthnicityOptionsLoaded: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getEthnicitiesState,
  fromReducer.getEthnicityLoaded,
);
