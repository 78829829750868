import { Action } from '@ngrx/store';
import { WorkbenchConfig } from '../models/config.model';

export const LOAD_WORKBENCH_CONFIG = '[Analysis] Load config';
export const LOAD_WORKBENCH_CONFIG_SUCCESS = '[Analysis] Load config success';
export const LOAD_WORKBENCH_CONFIG_FAIL = '[Analysis] Load config fail';

export class LoadWorkbenchConfig implements Action {
  readonly type = LOAD_WORKBENCH_CONFIG;

  constructor(public analysisId: number) {}
}

export class LoadWorkbenchConfigSuccess implements Action {
  readonly type = LOAD_WORKBENCH_CONFIG_SUCCESS;

  constructor(
    public analysisId: number,
    public config: WorkbenchConfig,
  ) {}
}

export class LoadWorkbenchConfigFail implements Action {
  readonly type = LOAD_WORKBENCH_CONFIG_FAIL;

  constructor(
    public analysisId: number,
    public err: any,
  ) {}
}

export type ConfigAction = LoadWorkbenchConfig | LoadWorkbenchConfigSuccess | LoadWorkbenchConfigFail;
