import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TextDirective } from '../../shared/directives/text.directive';
import { FlexItemDirective } from '../../shared/directives/flex-item.directive';
import { BlockDirective } from '../../shared/directives/block.directive';
import { FlexDirective } from '../../shared/directives/flex.directive';
import { ClickCursorDirective } from '../../shared/directives/click-cursor.directive';

@Component({
  selector: 'gnx-unable-to-save-popup',
  templateUrl: './unable-to-save-popup.component.html',
  styleUrls: ['./unable-to-save-popup.component.scss'],
  standalone: true,
  imports: [ClickCursorDirective, FlexDirective, BlockDirective, FlexItemDirective, TextDirective],
})
export class UnableToSavePopupComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<UnableToSavePopupComponent>) {}

  ngOnInit(): void {}

  onClose() {
    this.dialogRef.close();
  }
}
