import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { discussionReducer, DiscussionState } from './discussion.reducer';

export interface DiscussionModuleState {
  discussion: DiscussionState;
}

export const discussionReducers: ActionReducerMap<DiscussionModuleState> = {
  discussion: discussionReducer,
};

export const getDiscussionModuleState = createFeatureSelector<DiscussionModuleState>('discussion');
