import { Action } from '@ngrx/store';
import {
  CaseType,
  CreateCaseData,
  CreateCaseDialogSection,
  CreateCaseFamilyRequest,
  CreateCaseForm,
  CreateCaseRequest,
  CreateCaseSingleSampleForm,
  NewCaseVariantEntry,
  UpdateCaseStatusRequest,
  UploadCaseSamplesData,
  VcfInspectionResult,
} from '../../models';
import { AnalyticsAction, AnalyticsEvent, extractEventFromType } from '../../../../store/actions/analytics.action';
import { VariantId, VariantSearchResponse } from '../../../../store/models';
import { SearchConfig } from '../../../variant-page/modules/variant-page/models';

export const OPEN_CASE_DIALOG = '[Create Case] Open case dialog';
export const OPEN_CREATE_CASE_DIALOG = '[Create Case] Open Create Case Dialog';
export const OPEN_EXPORT_CASE_DIALOG = '[Create Case] Open Export Case Dialog';
export const CREATE_CASE = '[Create Case] Create case';
export const CREATE_CASE_SUCCESS = '[Create Case] Create case success';
export const CREATE_CASE_FAIL = '[Create Case] Create case fail';
export const CLEAR_CREATE_CASE = '[Create Case] Clear create case';
export const CREATE_CASE_UPDATE_FORM = '[Create Case] Create case update form';
export const SUBMIT_CASE_FOR_CREATION = '[Create Case] Submit case for creation';

// const getEventLabelFromCaseRequest = (caseRequest: CreateCaseRequest): string => {
//   const sampleFiles = caseRequest.sample.files_creations_requests
//     .filter(filePath => (filePath && filePath.path))
//     .map(filePath => filePath.path.split('/').pop())
//     .map(fileName => fileName.substr(0, fileName.indexOf('?')));
//   return `Name = ${caseRequest.case_data.name}, Phenotypes = ${(caseRequest.case_data.phenotypes || []).join(';')}, Sample = ${sampleFiles.join('; ')}`;
// };

export class OpenNewCaseDialog implements AnalyticsAction {
  readonly type = OPEN_CASE_DIALOG;
  analyticsEvent: AnalyticsEvent;

  constructor(public caseType: CaseType) {
    const label = 'Open New Case Dialog';

    this.analyticsEvent = extractEventFromType(this.type);
    this.analyticsEvent.eventLabel = label;
  }
}

export class OpenCreateCaseDialog implements Action {
  readonly type = OPEN_CREATE_CASE_DIALOG;

  constructor(
    public onSave: (caseContext: SearchConfig) => void,
    public caseContext?: SearchConfig,
    public section?: CreateCaseDialogSection,
    public customSections?: CreateCaseDialogSection[],
  ) {}
}

export class OpenExportCaseDialog implements Action {
  readonly type = OPEN_EXPORT_CASE_DIALOG;

  constructor(
    public caseName: string,
    public caseId: number,
    public variantId: VariantId,
  ) {}
}

export class CreateCase implements Action {
  readonly type = CREATE_CASE;

  constructor(
    public caseType: string,
    public payload: CreateCaseRequest | CreateCaseFamilyRequest,
    public uploadFileRequest: UploadCaseSamplesData[] = [],
  ) {}
}

export class CreateCaseSuccess implements AnalyticsAction {
  readonly type = CREATE_CASE_SUCCESS;
  analyticsEvent: AnalyticsEvent;

  constructor(public caseData: CreateCaseRequest | CreateCaseFamilyRequest) {
    // const label = getEventLabelFromCaseRequest(caseData);

    this.analyticsEvent = extractEventFromType(this.type);
    // this.analyticsEvent.eventLabel = label;
  }
}

export class CreateCaseFail implements AnalyticsAction {
  readonly type = CREATE_CASE_FAIL;
  analyticsEvent: AnalyticsEvent;

  constructor(
    public error: any,
    caseForm?: CreateCaseForm,
  ) {
    // const label = `${getEventLabelFromCaseRequest(caseForm)}, Error = ${error.message}`;

    this.analyticsEvent = extractEventFromType(this.type);
    // this.analyticsEvent.eventLabel = label;
  }
}

export class ClearCreateCase implements Action {
  readonly type = CLEAR_CREATE_CASE;
}

export class CreateCaseUpdateForm implements Action {
  readonly type = CREATE_CASE_UPDATE_FORM;

  constructor(public payload: CreateCaseSingleSampleForm) {}
}

export class SubmitCaseForCreation implements Action {
  readonly type = SUBMIT_CASE_FOR_CREATION;

  constructor(
    public caseType: string,
    public payload: CreateCaseForm,
  ) {}
}

export const GET_CASE_SAMPLES_UPLOAD_URL = '[Analyses] Get case samples upload url';
export const GET_CASE_SAMPLES_UPLOAD_URL_FAIL = '[Analyses] Get case samples upload url fail';
export const UPLOAD_CASE_SAMPLES = '[Analyses] Upload Case Sample';
export const UPDATE_CASE_STATUS = '[Analyses] Update case status';
export const UPDATE_CASE_STATUS_FAIL = '[Analyses] Update case status fail';

export class GetCaseSamplesUploadUrl implements Action {
  readonly type = GET_CASE_SAMPLES_UPLOAD_URL;

  constructor(
    public caseData: CreateCaseData,
    public sample: File,
  ) {}
}

export class GetCaseSamplesUploadUrlFail implements Action {
  readonly type = GET_CASE_SAMPLES_UPLOAD_URL_FAIL;

  constructor(public err: any) {}
}

export class UploadCaseSamples implements Action {
  readonly type = UPLOAD_CASE_SAMPLES;

  constructor(public payload: UploadCaseSamplesData) {}
}

export class UpdateCaseStatus implements Action {
  readonly type = UPDATE_CASE_STATUS;

  constructor(public payload: UpdateCaseStatusRequest) {}
}

export class UpdateCaseStatusFail implements Action {
  readonly type = UPDATE_CASE_STATUS_FAIL;

  constructor(public err: any) {}
}

export const NEW_CASE_ADD_VARIANT = '[Create Case] New case add variant';
export const NEW_CASE_ADD_VARIANT_OLD = '[Create Case] New case add variant old';
export const NEW_CASE_ADD_VARIANT_FOUND = '[Create Case] New case add variant found';
export const NEW_CASE_ADD_VARIANT_SUCCESS = '[Create Case] New case add variant success';
export const NEW_CASE_ADD_VARIANT_FAIL = '[Create Case] New case add variant fail';
export const NEW_CASE_CHOOSE_VARIANT = '[Create Case] New case choose variant';
export const NEW_CASE_REMOVE_VARIANT = '[Create Case] Remove variant';
export const NEW_CASE_CLEAR_VARIANTS = '[Create Case] Clear variants';

export class NewCaseAddVariant implements Action {
  readonly type = NEW_CASE_ADD_VARIANT;

  constructor(
    public searchText: string,
    public context?: SearchConfig,
    public referenceVersion?: string,
  ) {}
}

export class NewCaseAddVariantOld implements Action {
  readonly type = NEW_CASE_ADD_VARIANT_OLD;

  constructor(
    public searchText: string,
    public context?: SearchConfig,
    public referenceVersion?: string,
  ) {}
}

export class NewCaseAddVariantFound implements Action {
  readonly type = NEW_CASE_ADD_VARIANT_FOUND;

  constructor(public variant: VariantId) {}
}

export class NewCaseAddVariantsSuccess implements Action {
  readonly type = NEW_CASE_ADD_VARIANT_SUCCESS;

  constructor(
    public variants: NewCaseVariantEntry[],
    public failedInputs: string[],
  ) {}
}

export class NewCaseAddVariantsFail implements Action {
  readonly type = NEW_CASE_ADD_VARIANT_FAIL;

  constructor(public error: any) {}
}

export class NewCaseChooseVariant implements Action {
  readonly type = NEW_CASE_CHOOSE_VARIANT;

  constructor(public response: VariantSearchResponse) {}
}

export class NewCaseRemoveVariant implements Action {
  readonly type = NEW_CASE_REMOVE_VARIANT;

  constructor(public variantId: VariantId) {}
}

export class NewCaseClearVariants implements Action {
  readonly type = NEW_CASE_CLEAR_VARIANTS;
}

export const NEW_CASE_VALIDATE_FILE = '[Create Case] New case validate file';
export const NEW_CASE_VALIDATE_FILE_SUCCESS = '[Create Case] New case validate file success';
export const NEW_CASE_VALIDATE_FILE_FAIL = '[Create Case] New case validate file fail';

export class NewCaseValidateFile implements Action {
  readonly type = NEW_CASE_VALIDATE_FILE;

  constructor(public file: File) {}
}

export class NewCaseValidateFileSuccess implements Action {
  readonly type = NEW_CASE_VALIDATE_FILE_SUCCESS;

  constructor(
    public fileName: string,
    public headerFileUrl: string,
    public metadata: VcfInspectionResult,
  ) {}
}

export class NewCaseValidateFileFail implements Action {
  readonly type = NEW_CASE_VALIDATE_FILE_FAIL;

  constructor(
    public fileName: string,
    public error: any,
  ) {}
}

export type CreateCaseAction =
  | OpenNewCaseDialog
  | OpenCreateCaseDialog
  | OpenExportCaseDialog
  | SubmitCaseForCreation
  | CreateCase
  | CreateCaseFail
  | CreateCaseSuccess
  | ClearCreateCase
  | CreateCaseUpdateForm
  | GetCaseSamplesUploadUrl
  | GetCaseSamplesUploadUrlFail
  | UploadCaseSamples
  | UpdateCaseStatus
  | UpdateCaseStatusFail
  | NewCaseAddVariant
  | NewCaseAddVariantOld
  | NewCaseAddVariantsSuccess
  | NewCaseAddVariantsFail
  | NewCaseRemoveVariant
  | NewCaseChooseVariant
  | NewCaseClearVariants
  | NewCaseValidateFile
  | NewCaseValidateFileSuccess
  | NewCaseValidateFileFail;
