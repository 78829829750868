import { Action } from '@ngrx/store';
import { GenePanel } from '../models';
import { PanelData } from '../../knowledge-base/modules/panels/store';

export const GET_GENE_PANELS = '[Root] Get Gene Panels';
export const GET_GENE_PANELS_SUCCESS = '[Root] Get Gene Panels Success';
export const GET_GENE_KB_PANELS_SUCCESS = '[Root] Get Gene KB Panels Success';
export const GET_GENE_PANELS_FAIL = '[Root] Get Gene Panels Fail';
export const CLEAR_GENE_PANELS = '[Root] Clear Gene Panels';

export class GetGenePanels implements Action {
  readonly type = GET_GENE_PANELS;

  constructor(public gene: string) {}
}

export class GetGenePanelsSuccess implements Action {
  readonly type = GET_GENE_PANELS_SUCCESS;

  constructor(
    public gene: string,
    public payload: GenePanel[],
  ) {}
}

export class GetGeneKbPanelsSuccess implements Action {
  readonly type = GET_GENE_KB_PANELS_SUCCESS;

  constructor(
    public gene: string,
    public payload: PanelData[],
  ) {}
}

export class GetGenePanelsFail implements Action {
  readonly type = GET_GENE_PANELS_FAIL;

  constructor(
    public gene: string,
    public error: any,
  ) {}
}

export class ClearGenePanels implements Action {
  readonly type = CLEAR_GENE_PANELS;
}

export type GenePanelsAction =
  | GetGenePanels
  | GetGenePanelsSuccess
  | GetGeneKbPanelsSuccess
  | GetGenePanelsFail
  | ClearGenePanels;
