import { Component, OnInit, Renderer2 } from '@angular/core';

import { AuthService } from './modules/auth0/services/auth.service';
import { environment } from '../environments/environment';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { AppModuleState, GetPredictionOptions, selectRichTemplateData } from './store';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { RouterOutlet } from '@angular/router';
import { RtlDirective } from './shared/i18n/rtl.directive';
import { NgIf } from '@angular/common';
import { ClassificationMetadataService } from './store/services/classification-metadata/classification-metadata.service';

declare var dataLayer: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NgIf, RtlDirective, RouterOutlet],
})
export class AppComponent implements OnInit {
  STYLES_REG_EXP: RegExp = /<style.*?>(.*)<\/style>/;
  FONT_FAMILY_REG_EXP: RegExp = /font-family:".*?";*/g;
  FONT_SIZE_REG_EXP: RegExp = /font-size:[\d\w\.]*;*/g;
  styleElement: HTMLStyleElement;

  sus = false;

  // @HostListener('window:resize', ['$event'])
  checkSus() {
    const indexOfString = (str: string, query: string) => {
      for (let i = 0; i < str.length; i++) {
        for (let q = 0; q < query.length; q++) {
          if (str[i + q] !== query[q]) {
            break;
          }
          if (q === query.length - 1) {
            return i;
          }
        }
      }
      return -1;
    };

    let sus = false;

    if (/PhantomJS/.test(window.navigator.userAgent)) {
      sus = true;
    } else if (
      navigator.userAgent.indexOf('Firefox') !== -1 &&
      window.innerWidth === 1000 &&
      [600, 700, 800, 900, 1000].includes(window.innerHeight)
    ) {
      sus = true;
      // } else if (!(navigator.plugins instanceof PluginArray) || navigator.plugins.length === 0) {
      //   sus = true;
    } else if ((window as any).callPhantom || (window as any)._phantom) {
      sus = true;
    }

    (() => {
      if (!Function.prototype.bind) {
        sus = true;
      } else if (Function.prototype.bind.toString().replace(/bind/g, 'Error') !== Error.toString()) {
        sus = true;
      } else if (Function.prototype.toString.toString().replace(/toString/g, 'Error') !== Error.toString()) {
        sus = true;
      }
    })();

    try {
      // @ts-ignore
      null[0]();
    } catch (e) {
      if (indexOfString(e.stack, 'phantomjs') > -1) {
        sus = true;
      }
    }

    if (sus) {
      dataLayer.push({ event: 'Suspected Browser Blocked' });
    }
    this.sus = sus;
  }

  constructor(
    private authService: AuthService,
    private rootStore$: Store<AppModuleState>,
    private ngxZendeskService: NgxZendeskWebwidgetService,
    private store$: Store<AppModuleState>,
    private renderer: Renderer2,
    private classificationService: ClassificationMetadataService,
  ) {
    const script = document.createElement('script');
    script.async = false;
    // sets the environment to DEV on tag manager if we are not working on production
    script.src = 'https://www.googletagmanager.com/gtm.js?id=' + environment.gTagManagerTrackingCode;
    document.head.prepend(script);

    this.ngxZendeskService.initZendesk();
  }

  ngOnInit(): void {
    this.authService.init();
    this.classificationService.init();
    this.checkSus();
    this.store$.dispatch(new GetPredictionOptions());
    this.rootStore$
      .pipe(select(selectRichTemplateData), filter(Boolean))
      .subscribe((templates) => this.applyStyles(templates));
  }

  applyStyles(data: Record<string, string>) {
    const styles = Object.entries(data)
      .map((entry) => {
        const matches = this.STYLES_REG_EXP.exec(entry[1]);
        if (!matches?.length) {
          return '';
        }
        const noFFMatch: string = matches[1].replace(this.FONT_FAMILY_REG_EXP, '');
        const noFFFSMatch: string = noFFMatch.replace(this.FONT_SIZE_REG_EXP, '');
        return `.${entry[0]}{ ${noFFFSMatch} }`;
      })
      .join('\n');
    this.injectStyles(styles);
  }

  injectStyles(styles: string) {
    if (this.styleElement) {
      this.renderer.removeChild(document.head, this.styleElement);
    }
    this.styleElement = this.renderer.createElement('style');
    const text = this.renderer.createText(styles);
    this.renderer.appendChild(this.styleElement, text);
    this.renderer.appendChild(document.head, this.styleElement);
  }
}
