import {
  AnalysisSingleVariant,
  OvergrowthSnpResult,
  SnpResult,
  SomaticSnpResult,
  SomaticSvResult,
  SvResult,
} from '../../../../analysis/modules/analysis-variant/models';
import * as momentImported from 'moment/moment';
import { Message } from './discussion.models';

export function InjectLastMessageInVariantsList(message: Message, variants): AnalysisSingleVariant[] {
  return variants.map((i: AnalysisSingleVariant) => {
    if (
      (i.data as SnpResult | SomaticSnpResult | SomaticSvResult | OvergrowthSnpResult).var_id === message.var_id ||
      (i.data as SvResult).variant_data?.var_id === message.var_id
    ) {
      return {
        ...i,
        data: {
          ...i.data,
          latest_comment: {
            content: message.message_content,
            is_private: message.is_private,
            message_id: message.message_id,
            timestamp: momentImported(message.submission_date).valueOf(),
            topic_id: message.topic_id,
            user_email: message.user_id.id,
            user_name: message.user_name,
          },
        },
      };
    } else {
      return i;
    }
  });
}

export function UpdateMessageInVariantsList(message: Message, variants): AnalysisSingleVariant[] {
  return variants.map((i: AnalysisSingleVariant) => {
    if (
      ((i.data as SnpResult | SomaticSnpResult | SomaticSvResult | OvergrowthSnpResult).var_id === message.var_id ||
        (i.data as SvResult).variant_data?.var_id === message.var_id) &&
      i.data.latest_comment.message_id === message.message_id
    ) {
      return {
        ...i,
        data: {
          ...i.data,
          latest_comment: {
            ...i.data.latest_comment,
            content: message.message_content,
          },
        },
      };
    } else {
      return i;
    }
  });
}
