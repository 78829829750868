import { Action } from '@ngrx/store';
import { FilterGroup } from '../../../shared/models/filter.model';
import { VariantType } from '../../../analysis-variant/models';
import { SelectOption } from '../../../shared/models/select-option.model';
import { AnalysisType } from '../../../../../../store/models';

export const LOAD_FILTERS_STRUCTURE = '[Filters] Load FiltersStructure';
export const LOAD_FILTERS_STRUCTURE_FAIL = '[Filters] Load FiltersStructure Fail';
export const LOAD_FILTERS_STRUCTURE_SUCCESS = '[Filters] Load FiltersStructure Success';

export class LoadFiltersStructure implements Action {
  readonly type = LOAD_FILTERS_STRUCTURE;

  constructor(
    public analysisId: number,
    public analysisType: AnalysisType,
    public variantType: VariantType,
  ) {}
}

export class LoadFiltersStructureFail implements Action {
  readonly type = LOAD_FILTERS_STRUCTURE_FAIL;

  constructor(public payload: any) {}
}

export class LoadFiltersStructureSuccess implements Action {
  readonly type = LOAD_FILTERS_STRUCTURE_SUCCESS;

  constructor(public payload: FilterGroup[]) {}
}

export const UPDATE_FILTER_OPTIONS = '[Filters] Update Filter Options';
export const UPDATE_FILTER_OPTIONS_FAIL = '[Filters] Update Filter Options Fail';

export class UpdateFilterOptions implements Action {
  readonly type = UPDATE_FILTER_OPTIONS;

  constructor(
    public filters: { [id: string]: SelectOption[] },
    public replace = true,
    public updateQueryParams = true,
  ) {}
}

export class UpdateFilterOptionsFail implements Action {
  readonly type = UPDATE_FILTER_OPTIONS_FAIL;

  constructor(public err: any) {}
}

export type FiltersStructureAction =
  | LoadFiltersStructure
  | LoadFiltersStructureFail
  | LoadFiltersStructureSuccess
  | UpdateFilterOptions;
