import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as fromAction from './../actions/search.action';
import { SearchHistory } from './../actions/search.action';
import {
  getVariantIdType,
  parseAsVariantId,
  RohRegion,
  SearchWarning,
  SearchWarningType,
  VariantId,
  VariantSearchResponse,
} from '../models';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ChooseVariantPopupComponent } from '../../components/choose-variant-popup/choose-variant-popup.component';
import { AppService } from '../services';
import { UnsupportedTranscriptDialogComponent } from '../../shared/containers/unsupported-transcript-dialog/unsupported-transcript-dialog.component';
import { Action, select, Store } from '@ngrx/store';
import { AuthModuleState } from '../../modules/auth0/store/reducers';
import { getUserDetails } from '../../modules/auth0/store/selectors';
import { UserDetails } from '../../modules/auth0/models';
import { QUIT_SIGN_UP } from '../../modules/auth0/store/actions';
import { LazyLoadModule } from '../actions';
import { OpenRegistrationPopup } from '../../modules/variant-page/store/actions';
import { SearchConfig } from '../../modules/variant-page/modules/variant-page/models';
import { AnalysisService } from '../../modules/analysis/modules/analyses/services/analysis.service';

@Injectable()
export class SearchEffect {
  redirectUrl: string;
  searchContext: SearchConfig;

  constructor(
    private actions$: Actions,
    private searchService: AppService,
    private analysisService: AnalysisService,
    private router: Router,
    private dialog: MatDialog,
    private authStore$: Store<AuthModuleState>,
  ) {}

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.SEARCH_VARIANT),
      map(
        (action: fromAction.SearchVariant) =>
          new fromAction.FindVariantsFromSearch(
            action.payload,
            action.redirect ? fromAction.SearchVariantSuccess : fromAction.SearchVariantSuccessNoRedirect,
            fromAction.SearchVariantFail,
            action.referenceGenome,
            !action.redirect,
            false,
            action.config,
          ),
      ),
    ),
  );

  searchAnalyses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.SEARCH_ANALYSES),
      switchMap((action: fromAction.SearchAnalyses) =>
        this.analysisService.getAnalyses(action.payload).pipe(
          map((result) => new fromAction.SearchAnalysesSuccess(result.results)),
          catchError((error) => of(new fromAction.SearchAnalysesFail(error))),
        ),
      ),
    ),
  );

  findVariantsFromSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.FIND_VARIANTS_FROM_SEARCH),
      mergeMap((action: fromAction.FindVariantsFromSearch) => {
        return this.searchService
          .searchVariant(action.payload, action.referenceGenome, action.context, action.disableRoh)
          .pipe(
            map(
              (result: VariantSearchResponse) =>
                new fromAction.FindVariantsFromSearchSuccess(
                  action.payload,
                  result,
                  action.successAction,
                  action.failAction,
                  action.referenceGenome,
                  action.disableChoice,
                  action.disableRoh,
                  action.context,
                ),
            ),
            catchError((error) =>
              of(
                new action.failAction(
                  error.error.errors
                    ? error.error.errors[0]
                    : 'Franklin didn’t understand what you meant, please check format examples',
                  action.payload,
                ),
              ),
            ),
          );
      }),
    ),
  );

  findVariantsFromSearchSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.FIND_VARIANTS_FROM_SEARCH_SUCCESS),
      mergeMap((action: fromAction.FindVariantsFromSearchSuccess) => {
        this.searchContext = action.context;
        if (
          !action.result.roh_regions &&
          (action.result.snp_variants || action.result.sv_variants)?.length > 1 &&
          !action.disableChoice
        ) {
          return [new fromAction.ChooseSearchVariant(action.result, action.successAction)];
        } else if (action.result?.best_variant_option?.warnings?.length) {
          return this.getWarningsActions(
            action.result.best_variant_option.warnings,
            parseAsVariantId(action.result.roh_regions || (action.result.snp_variants || action.result.sv_variants)[0]),
          );
        } else if (
          action.result.roh_regions ||
          (action.result.snp_variants || action.result.sv_variants)?.length === 1 ||
          action.disableChoice
        ) {
          return [
            new action.successAction(
              parseAsVariantId(
                action.result.roh_regions || (action.result.snp_variants || action.result.sv_variants)[0],
              ),
            ),
          ];
        } else if (action.result.response_type === 'GENE') {
          return [new action.successAction(null, action.result.gene)];
        } else {
          return [new action.failAction('no variants found', action.payload)];
        }
      }),
    ),
  );

  searchSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAction.SEARCH_VARIANT_SUCCESS),
        withLatestFrom(this.authStore$.pipe(select(getUserDetails))),
        tap(([action, user]: [fromAction.SearchVariantSuccess, UserDetails]) => {
          if (action.variant) {
            const variant: VariantId = action.variant;
            const hgValue: string = localStorage.getItem('lastHGValue');
            const variantId =
              'sv_type' in variant
                ? `${variant.chr}-${variant.start_pos}-${variant.end_pos}-${variant.sv_type}${
                    hgValue === 'hg38' ? '-hg38' : ''
                  }`
                : 'start_pos' in variant
                  ? variant.roh_regions.reduce((acc: string, crt: RohRegion, idx: number) => {
                      return acc + (idx ? ';' : '') + `${crt.chrom}-${crt.start_pos}-${crt.end_pos}`;
                    }, '')
                  : `${variant.chr}-${variant.pos}-${variant.ref}-${variant.alt}${hgValue === 'hg38' ? '-hg38' : ''}`;
            const url = this.router.serializeUrl(
              this.router.createUrlTree([
                'clinical-db',
                'variant',
                getVariantIdType(action.variant, this.searchContext),
                variantId,
              ]),
            );
            let searchesCount: number = parseInt(localStorage.getItem('searches'), 10) || 0;
            if (!user) {
              localStorage.setItem('searches', (++searchesCount).toString());
            }
            if (searchesCount % 5 === 0 && !user) {
              this.redirectUrl = url;
              this.authStore$.dispatch(
                new LazyLoadModule(
                  () => import('../../modules/variant-page/variant-page.module').then((m) => m.VariantPageModule),
                  new OpenRegistrationPopup(url, 'optional'),
                ),
              );
            } else {
              this.router.navigateByUrl(url, { state: this.searchContext });
            }
          } else if (action.gene) {
            const hgValue: string = localStorage.getItem('lastHGValue') || 'hg19';
            this.router.navigate(['clinical-db', 'gene', hgValue, action.gene]);
          }
        }),
      ),
    { dispatch: false },
  );

  quitSignUp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QUIT_SIGN_UP),
        tap(() => {
          const searchesCount: number = parseInt(localStorage.getItem('searches'), 10) || 0;
          if (searchesCount % 5 === 0 && this.redirectUrl) {
            this.router.navigateByUrl(this.redirectUrl, { state: this.searchContext });
            this.redirectUrl = null;
          }
        }),
      ),
    { dispatch: false },
  );

  chooseVariantPopup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAction.CHOOSE_SEARCH_VARIANT),
        tap((action: fromAction.ChooseSearchVariant) =>
          this.dialog.open(ChooseVariantPopupComponent, {
            width: '570px',
            maxHeight: '684px',
            minHeight: '300px',
            backdropClass: 'error-popup-backdrop',
            panelClass: 'error-popup-panel',
            hasBackdrop: true,
            data: {
              variants: action.response.sv_variants || action.response.snp_variants,
              onChooseAction: action.chooseAction,
            },
          }),
        ),
      ),
    { dispatch: false },
  );

  unsupportedTranscript$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.UNSUPPORTED_TRANSCRIPT),
      switchMap((action: fromAction.UnsupportedTranscript) => {
        const dialogRef = this.dialog.open(UnsupportedTranscriptDialogComponent, {
          height: '500px',
          maxHeight: '500px',
          width: '570px',
          data: action.transcript,
        });
        return dialogRef
          .afterClosed()
          .pipe(map((data) => (data ? new action.continueAction(action.variant) : new action.cancelAction('', ''))));
      }),
    ),
  );

  searchHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.SEARCH_HISTORY),
      switchMap((action: SearchHistory) =>
        this.searchService.searchHistory(action.searchTerm).pipe(
          map((response) => new fromAction.SearchHistorySuccess(response)),
          catchError((err) => of(new fromAction.SearchHistoryFail(err))),
        ),
      ),
    ),
  );

  private getWarningsActions(warnings: SearchWarning[], variantId: VariantId): Action[] {
    const actions = [];
    warnings.forEach((warning) => {
      switch (warning.warning_type) {
        case SearchWarningType.UNSUPPORTED_TRANSCRIPT:
          actions.push(
            new fromAction.UnsupportedTranscript(
              variantId,
              warning.supported_transcript,
              fromAction.SearchVariantSuccess,
              fromAction.SearchVariantFail,
            ),
          );
          break;
      }
    });

    return actions;
  }
}
