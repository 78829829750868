import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromReducer from '../reducers/cancer-types.reducer';
import { AppModuleState, getAppModuleState } from '../reducers';
import { CancerTypes } from '../models';

const getCancerTypesState: MemoizedSelector<AppModuleState, fromReducer.CancerTypesState> = createSelector(
  getAppModuleState,
  (state: AppModuleState) => state.cancerTypes,
);

export const getCancerTypes: MemoizedSelector<AppModuleState, CancerTypes> = createSelector(
  getCancerTypesState,
  fromReducer.getCancerTypes,
);

export const getCancerTypesLoaded: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getCancerTypesState,
  fromReducer.getCancerTypesLoaded,
);

export const getCancerTypesLoading: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getCancerTypesState,
  fromReducer.getCancerTypesLoading,
);
