import { Action } from '@ngrx/store';
import { PanelSuggestion } from '../models';
import { PanelData } from '../../knowledge-base/modules/panels/store';
import { PanelLocation } from '../../modules/variant-page/modules/variant-page/models';

export const GET_PANELS = '[Root] Get panels';
export const GET_PANELS_FAIL = '[Root] Get panels fail';
export const GET_PANELS_SUCCESS = '[Root] Get panels success';
export const GET_KB_PANELS_SUCCESS = '[Root] Get kb panels success';

export class GetPanels implements Action {
  readonly type = GET_PANELS;

  constructor(
    public payload: string,
    public analysisId?: number,
    public assayId?: string,
    public forceNewPanels = false,
  ) {}
}

export class GetPanelsFail implements Action {
  readonly type = GET_PANELS_FAIL;

  constructor(public payload: any) {}
}

export class GetPanelsSuccess implements Action {
  readonly type = GET_PANELS_SUCCESS;

  constructor(
    public payload: PanelSuggestion[],
    public searchTerm: string,
  ) {}
}

export class GetKbPanelsSuccess implements Action {
  readonly type = GET_KB_PANELS_SUCCESS;

  constructor(
    public payload: PanelData[],
    public searchTerm: string,
  ) {}
}

export const GET_PANELS_WITH_ALL_VERSIONS = '[Root] Get panels with all versions';
export const GET_PANELS_WITH_ALL_VERSIONS_FAIL = '[Root] Get panels with all versions fail';
export const GET_PANELS_WITH_ALL_VERSIONS_SUCCESS = '[Root] Get panels with all versions success';

export class GetPanelsWithAllVersions implements Action {
  readonly type = GET_PANELS_WITH_ALL_VERSIONS;

  constructor() {}
}

export class GetPanelsWithAllVersionsSuccess implements Action {
  readonly type = GET_PANELS_WITH_ALL_VERSIONS_SUCCESS;

  constructor(public payload: PanelData[]) {}
}

export class GetPanelsWithAllVersionsFail implements Action {
  readonly type = GET_PANELS_WITH_ALL_VERSIONS_FAIL;

  constructor(public error: any) {}
}

export const GET_PANEL_GENES = '[Root] Get panel genes';
export const GET_PANEL_GENES_FAIL = '[Root] Get panel genes fail';
export const GET_PANEL_GENES_SUCCESS = '[Root] Get panel genes success';

export class GetPanelGenes implements Action {
  readonly type = GET_PANEL_GENES;

  constructor(
    public locationString: string,
    public panelId: string,
    public limit?: number,
    public analysisId?: number,
    public location?: PanelLocation,
  ) {}
}

export class GetPanelGenesFail implements Action {
  readonly type = GET_PANEL_GENES_FAIL;

  constructor(public payload: any) {}
}

export class GetPanelGenesSuccess implements Action {
  readonly type = GET_PANEL_GENES_SUCCESS;

  constructor(
    public payload: any[],
    public panelId: string,
  ) {}
}

export const GET_CASE_PANELS = '[Root] Get case panels';
export const GET_CASE_PANELS_FAIL = '[Root] Get case panels fail';
export const GET_CASE_PANELS_SUCCESS = '[Root] Get case panels success';
export const REMOVE_CASE_PANEL = '[Root] Remove case panel';

export class GetCasePanels implements Action {
  readonly type = GET_CASE_PANELS;

  constructor(public panelIds: string[]) {}
}

export class GetCasePanelsFail implements Action {
  readonly type = GET_CASE_PANELS_FAIL;

  constructor(public error: any) {}
}

export class GetCasePanelsSuccess implements Action {
  readonly type = GET_CASE_PANELS_SUCCESS;

  constructor(public payload: PanelData[]) {}
}

export class RemoveCasePanel implements Action {
  readonly type = REMOVE_CASE_PANEL;

  constructor(public panelId: string) {}
}

export type PanelsAction =
  | GetPanels
  | GetPanelsSuccess
  | GetPanelsFail
  | GetKbPanelsSuccess
  | GetPanelGenes
  | GetPanelGenesSuccess
  | GetPanelGenesFail
  | GetCasePanels
  | GetCasePanelsSuccess
  | GetCasePanelsFail
  | RemoveCasePanel
  | GetPanelsWithAllVersions
  | GetPanelsWithAllVersionsSuccess
  | GetPanelsWithAllVersionsFail;
