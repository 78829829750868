import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as fromAction from './../actions/sig-details.action';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SigService } from '../services';
import { of } from 'rxjs';

@Injectable()
export class SigDetailsEffect {
  constructor(
    private actions$: Actions,
    private service: SigService,
  ) {}

  loadSigDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadSigDetails),
      switchMap((action) =>
        this.service.getSigDetails(action.sigId).pipe(
          map((results) => fromAction.loadSigDetailsSuccess({ payload: results })),
          catchError((error) => of(fromAction.loadSigDetailsFail({ error }))),
        ),
      ),
    ),
  );

  loadSigCounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadSigCounts),
      switchMap((action) =>
        this.service.getSigCounts(action.sigId).pipe(
          map((results) => fromAction.loadSigCountsSuccess({ payload: results })),
          catchError((error) => of(fromAction.loadSigCountsFail(error))),
        ),
      ),
    ),
  );
}
