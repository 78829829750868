import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AppModuleState, getAppModuleState } from '../reducers';
import { OrganizationConfigState } from '../reducers/organization-config.reducer';
import { InterpretationTagForUI, OrganizationConfig, OrganizationTagsConfig } from '../models';
import { VariantIdTypes } from '../../modules/variant-page/modules/variant-page/models';
import { InterpretationType } from '../../modules/variant-page/modules/interpretation/containers/interpretation/interpretation.data';

const getOrganizationConfigState: MemoizedSelector<AppModuleState, OrganizationConfigState> = createSelector(
  getAppModuleState,
  (state: AppModuleState) => state.organizationConfig,
);

export const getOrganizationConfig: MemoizedSelector<AppModuleState, OrganizationConfig> = createSelector(
  getOrganizationConfigState,
  (state: OrganizationConfigState) => state.data,
);

export const getOrganizationConfigLoading: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getOrganizationConfigState,
  (state: OrganizationConfigState) => state.loading,
);

export const getOrganizationConfigLoaded: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getOrganizationConfigState,
  (state: OrganizationConfigState) => state.loaded,
);

export const getOrganizationConfigInterpretationTagsForUI = (
  variantType: VariantIdTypes,
  interpretationType: InterpretationType
): MemoizedSelector<AppModuleState, InterpretationTagForUI[]> =>
  createSelector(getOrganizationConfig, (config: OrganizationConfig) => {
    let typeConfig: OrganizationTagsConfig;
    if (
      variantType === VariantIdTypes.SNP ||
      (variantType === VariantIdTypes.SNP_TUMOR &&
        [InterpretationType.oncogenic, InterpretationType.germline].includes(interpretationType))
    ) {
      typeConfig = config.interpretation_tags_config;
    } else if (variantType === VariantIdTypes.SV) {
      typeConfig = config.sv_interpretation_tags_config;
    } else if (variantType === VariantIdTypes.SNP_TUMOR) {
      typeConfig = config.somatic_interpretation_tags_config;
    } else if (variantType === VariantIdTypes.SV_TUMOR) {
      typeConfig = config.somatic_sv_interpretation_tags_config;
    } else {
      typeConfig = { tags: [] };
    }

    return typeConfig?.tags
      ?.filter((tag) => !tag.parent)
      .map((tag) => ({
        name: tag.name,
        children: config.interpretation_tags_config.tags.filter((x) => x.parent === tag.name).map((x) => x.name),
      }));
  });
