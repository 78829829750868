import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AcmgSection, VariantAIInterpretationPayload, VariantInterpretation } from '../models';
import {
  getVariantIdType,
  SearchConfig,
  SnpVariantId,
  SvVariantId,
  VariantId,
  variantIdToBody,
  variantIdToBody2,
  variantIdToBody4,
  variantIdToHttpParams,
  variantIdToHttpParamsToBody,
  VariantIdTypes,
} from '../../variant-page/models';
import { map } from 'rxjs/operators';
import { VariantPageLibConfigService } from '../../variant-page/services/variant-page-lib-config.service';
import { InterpretationServiceConfig, VariantPageLibServiceType } from '../../../store';
import { environment } from '../../../../../../environments/environment';
import { AnalysisType } from '../../../../../store/models';
import { DynamicInputField } from '../../../../../shared/models';
import { CustomHttpParamEncoder } from '../../../../../shared/utils/http-param-encoder';

@Injectable({ providedIn: 'root' })
export class InterpretationService {
  constructor(private http: HttpClient) {}

  get serviceConfig(): InterpretationServiceConfig {
    return VariantPageLibConfigService.getServiceConfig(VariantPageLibServiceType.InterpretationService);
  }

  public getInterpretation(
    variantId: VariantId,
    caseContext: SearchConfig,
    compoundVariantId?: VariantId,
    conditionId?: string,
    allowedPoints?: number,
    vciEnabled?: boolean,
  ): Observable<VariantInterpretation> {
    VariantPageLibConfigService.variantIdType = getVariantIdType(variantId);
    const varType = getVariantIdType(variantId);
    let url: string;
    let isPOST = false;
    if (vciEnabled) {
      url = `${environment.beBaseURL}/api/organization/variants/acmg/interpretation/fetch_final`;

      return this.http.post<VariantInterpretation>(url, {
        ...variantIdToBody(variantId),
        associated_condition_id: conditionId,
        allowed_points: allowedPoints,
      });
    } else if (caseContext?.zygosity === 'TUMOR') {
      if (varType === VariantIdTypes.SNP) {
        url = `${environment.beBaseURL}/api/org/somatic/fetch_interpretation`;
        isPOST = true;
      } else {
        url = `${environment.beBaseURL}/api/org/somatic/sv_interpretation`;
      }
    } else if (varType === VariantIdTypes.SNP && variantId.analysisType === AnalysisType.tumor) {
      url = `${environment.beBaseURL}/api/org/somatic/fetch_joint_interpretation`;
      isPOST = true;
    } else if (varType === VariantIdTypes.SV && variantId.analysisType === AnalysisType.tumor) {
      url = `${environment.beBaseURL}/api/org/somatic/sv_interpretation`;
    } else if (varType === VariantIdTypes.SV || varType === VariantIdTypes.RNA) {
      url = `${environment.beBaseURL}/api/org/sv_interpretation`;
    } else if (varType === VariantIdTypes.ROH) {
      url = `${environment.beBaseURL}/api/org/loh_interpretation`;
    } else {
      url = `${environment.beBaseURL}/api/org/fetch_interpretation`;
      isPOST = true;

      // url = '/assets/json/variant_interpretation.json';
      // isPOST = false;
    }

    if (isPOST) {
      const body = variantIdToHttpParamsToBody(variantId, true, compoundVariantId);
      if (variantId?.analysisId) {
        body.analysisId = variantId.analysisId;
      } else {
        delete body.analysis_id;
      }
      return this.http.post<VariantInterpretation>(url, body);
    } else {
      let params = variantIdToHttpParams(variantId, true, compoundVariantId);
      if (variantId?.analysisId) {
        params = params.set('analysis_id', variantId.analysisId?.toString());
      }
      return this.http.get<VariantInterpretation>(url, { params });
    }
  }

  public regenerateInterpretation(
    variantId?: string,
    analysis?: number,
    acmgRules?: string[],
  ): Observable<VariantInterpretation> {
    const url = this.serviceConfig.regeneration.replace(':variantId', variantId);

    const params = {
      variant_id: variantId,
      analysis: analysis.toString(),
      acmg_rules: acmgRules,
    };

    return this.http.put<VariantInterpretation>(url, params);
  }

  public getInheritanceModel(analysisId: number, variantId?: string): Observable<VariantInterpretation> {
    const url = this.serviceConfig.inheritanceModel
      .replace(':analysisId', analysisId?.toString())
      .replace(':variantId', variantId);

    return this.http.get<VariantInterpretation>(url);
  }

  public saveInterpretation(
    variantId: VariantId,
    interpretation: VariantInterpretation,
    caseContext?: SearchConfig,
    compoundVariant?: VariantId,
    asDraft?: boolean,
    vciEnabled?: boolean,
  ) {
    let url: string;
    if (vciEnabled) {
      url = `${environment.beBaseURL}/api/organization/variants/acmg/interpretation`;
      return this.http.put<VariantInterpretation>(url, {
        ...variantIdToBody(variantId),
        ...interpretation,
        draft: asDraft,
      });
    } else if (compoundVariant?.hashId) {
      url = `${environment.beBaseURL}/api/org/interpretation/compound`;
    } else if (caseContext?.zygosity === 'TUMOR') {
      url = `${environment.beBaseURL}/api/org/somatic/interpretation`;
    } else if (getVariantIdType(variantId) === VariantIdTypes.SNP && variantId.analysisType === AnalysisType.tumor) {
      url = `${environment.beBaseURL}/api/org/somatic/joint_interpretation`;
    } else {
      url = `${environment.beBaseURL}/api/org/interpretation`;
    }

    let upgradedInterpretation: VariantInterpretation = {
      ...interpretation,
      var_id: variantId?.hashId,
      ...variantIdToBody2(variantId, false, true),
      case_context: { ...caseContext, custom_fields: null },
      draft: !!asDraft,
    };

    if (compoundVariant) {
      upgradedInterpretation = {
        ...upgradedInterpretation,
        compound_var_id: compoundVariant.hashId,
        ...variantIdToBody2(compoundVariant, false, true, true),
      };
    }

    if (variantId?.analysisId) {
      upgradedInterpretation.analysis_id = variantId.analysisId;
      upgradedInterpretation.analysis_type = variantId.analysisType;
    }

    return this.http.put<VariantInterpretation>(url, upgradedInterpretation);
  }

  public getInterpretationHistory(variant: SnpVariantId | SvVariantId): Observable<VariantInterpretation[]> {
    const url = `${environment.beBaseURL}/api/api/variant/interpretations`;

    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set('analysis_id', variant.analysisId?.toString())
      .set('variant_id', variant.hashId);

    return this.http.get<VariantInterpretation[]>(url, { params });
  }

  public getAIInterpretation(payload: VariantAIInterpretationPayload): Observable<string> {
    const url = `${environment.beBaseURL}/api/ai/interpretation/blurb`;

    return this.http.post<{ interpretation: string }>(url, payload).pipe(map((result) => result.interpretation));
  }

  public setFollow(value: boolean, variantId: VariantId): Observable<any> {
    const url = `${environment.beBaseURL}/api/api/analysis/${variantId.analysisId?.toString()}/variant/interpretation`;
    const body = {
      follow: value,
    };
    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set('variant_type', VariantIdTypes.SNP)
      .set('variant_id', variantId.hashId);

    return this.http.patch(url, body, { params });
  }

  getInterpretationCustomFields(
    variantId: VariantId,
    variantType = VariantIdTypes.SNP,
  ): Observable<DynamicInputField[]> {
    const url = `${environment.beBaseURL}/api/interpretation/custom_fields/configuration`;
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const params = {
      variant_id: variantId.hashId,
      variant_type: variantType,
      analysis_id: variantId.analysisId ? variantId.analysisId?.toString() : '',
    };

    return this.http
      .get<{ fields: DynamicInputField[] }>(url, { headers, params })
      .pipe(map((result) => result.fields));
  }

  public approveSuggestedClassification(variantId: VariantId, caseContext: SearchConfig): Observable<any> {
    const body = {
      ...variantIdToBody2(variantId, false, true),
      case_context: { ...caseContext, custom_fields: null },
      analysis_id: variantId.analysisId,
      analysis_type: variantId.analysisType,
    };

    return this.http.put<VariantInterpretation>(`${environment.beBaseURL}/api/org/somatic/latest_interpretation`, body);
  }
}
