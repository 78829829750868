import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gnx-dots-loader',
  templateUrl: './dots-loader.component.html',
  styleUrls: ['./dots-loader.component.scss'],
  standalone: true,
})
export class DotsLoaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
