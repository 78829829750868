import { Action } from '@ngrx/store';
import * as fromAction from '../actions/export-as-excel.action';

export interface ExportAsExcelState {
  loading: boolean;
  loaded: boolean;
}

export const initialState: ExportAsExcelState = {
  loaded: false,
  loading: false,
};

export function reducer(state = initialState, action: Action): ExportAsExcelState {
  switch (action.type) {
    case fromAction.GET_VARIANTS_EXCEL: {
      return {
        loaded: false,
        loading: true,
      };
    }
    case fromAction.GET_VARIANTS_EXCEL_SUCCESS: {
      return {
        loaded: true,
        loading: false,
      };
    }
    case fromAction.GET_VARIANTS_EXCEL_FAIL: {
      return {
        loaded: false,
        loading: false,
      };
    }
    default:
      return state;
  }
}
