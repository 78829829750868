import { Action } from '@ngrx/store';
import { ApplicationType, MiniApplicationType } from '../../models';

export const DEMO_APP_CLICK = '[Variant Page] Demo app click';

export class DemoAppClick implements Action {
  readonly type = DEMO_APP_CLICK;

  constructor(public payload: ApplicationType | MiniApplicationType) {}
}

export type DemoApplicationsAction = DemoAppClick;
