import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: 'label[gnxLabel]',
  standalone: true,
})
export class LabelDirective {
  @HostBinding('class.gnx-label') enabled = true;
  @HostBinding('class.gnx-label-error') @Input() error: boolean;

  constructor() {}
}
