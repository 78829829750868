import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RangeOption } from '../models';
import { environment } from '../../../environments/environment';

@Injectable()
export class PredictionOptionsService {
  constructor(private http: HttpClient) {}

  getPredictionOptions(): Observable<{ [key: string]: RangeOption[] }> {
    return this.http.get<{ [key: string]: RangeOption[] }>(`${environment.beBaseURL}/api/api/prediction/options`);
  }
}
