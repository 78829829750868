import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppService } from '../services';
import {
  loadVariantsAutocomplete,
  loadVariantsAutocompleteFail,
  loadVariantsAutocompleteSuccess,
} from '../actions/variants-autocomplete.action';

@Injectable()
export class VariantsAutocompleteEffects {
  loadVariantsAutocomplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadVariantsAutocomplete),
      switchMap((action) => {
        return this.appService.getVariantsAutocomplete(action.payload).pipe(
          map((results) => loadVariantsAutocompleteSuccess({ payload: results })),
          catchError((err: string) => of(loadVariantsAutocompleteFail({ err }))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private appService: AppService,
  ) {}
}
