import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[click]',
  standalone: true,
})
export class ClickCursorDirective {
  @Input() disablePointer: boolean;
  @Input() @HostBinding('class.gnx-hover-effect') hoverEffect: boolean;

  @HostBinding('style.cursor')
  get cursor(): string {
    return this.disablePointer ? 'default' : 'pointer';
  }
}
