import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { VariantCurationActions } from '../actions';
import { VariantCurationService } from '../../services/variant-curation.service';
import { OpenSnackbar } from '../../../../../../store';
import { SnackbarMode } from '../../../../../../store/models/snackbar.model';

@Injectable()
export class VariantCurationEffects {
  constructor(
    private actions$: Actions,
    private service: VariantCurationService,
  ) {}

  loadVariantCurations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VariantCurationActions.loadVariantCurations),
      switchMap(({ variantId, conditionId, allowedPoints }) =>
        this.service.getVariantCurationAcmgData(variantId, conditionId, allowedPoints).pipe(
          map((data) => VariantCurationActions.loadVariantCurationsSuccess({ data })),
          catchError((error) => of(VariantCurationActions.loadVariantCurationsFailure({ error }))),
        ),
      ),
    );
  });

  loadVariantCurationsSection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VariantCurationActions.loadVariantCurationsSection),
      switchMap(({ variantId, conditionId, section, allowedPoints }) =>
        this.service.getVariantCurationSectionAcmgData(variantId, conditionId, section, allowedPoints).pipe(
          map((data) => VariantCurationActions.loadVariantCurationsSectionSuccess({ data })),
          catchError((error) => of(VariantCurationActions.loadVariantCurationsSectionFailure({ section, error }))),
        ),
      ),
    );
  });

  saveVariantCurationsSection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VariantCurationActions.saveVariantCurationsSection),
      switchMap(({ variantId, conditionId, section, data, allowedPoints }) =>
        this.service.setVariantCurationSectionAcmgData(variantId, conditionId, section, data, allowedPoints).pipe(
          map((data) => VariantCurationActions.saveVariantCurationsSectionSuccess({ data, section })),
          catchError((error) => of(VariantCurationActions.saveVariantCurationsSectionFailure({ section, error }))),
        ),
      ),
    );
  });

  showSnackbar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantCurationActions.saveVariantCurationsSectionSuccess),
      switchMap(() => of(new OpenSnackbar('Your changes has been saved', SnackbarMode.SuccessNoPanda))),
    ),
  );
}
