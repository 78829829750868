<div class="header">
  <div class="icon-types">
    <div
      class="icon-type"
      *ngFor="let format of formats"
      [ngClass]="{ active: activeSection === format }"
      (click)="activeSection = format"
    >
      {{ format }}
    </div>
  </div>
  <div class="bcg-color-list">
    <div
      class="bgc-color-item"
      *ngFor="let color of colors"
      [ngStyle]="{ 'background-color': color }"
      (click)="nextColor(color)"
    ></div>
    <img
      src="assets/svg/support.svg"
      width="24"
      height="24"
      matTooltip='You can press "SHIFT + X" to switch background color'
      matTooltipClass="blue-md-tooltip wide"
      matTooltipShowDelay="200"
    />
  </div>
</div>
<div class="icon-container" [style.background-color]="activeColor">
  <div class="icon-card" *ngFor="let icon of activeIcons">
    <img [src]="icon" width="100%" style="max-height: 70px; max-width: 80px" (click)="showIconInfo(icon)" />
  </div>
</div>
