import { InterpretationHeaderEffect } from './interpretation-header.effect';
import { VariantInterpretationEffect } from './variant-interpretation.effect';
import { InterpretationHistoryEffect } from './interpretation-history.effect';
import { InterpretationCustomFieldsEffect } from './interpretation-custom-fields.effect';
import { VariantCurationEffects } from './variant-curation.effects';

export const effects = [
  InterpretationHeaderEffect,
  VariantInterpretationEffect,
  InterpretationHistoryEffect,
  InterpretationCustomFieldsEffect,
  VariantCurationEffects,
];
