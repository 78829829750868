import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as fromAction from '../actions';
import { Injectable } from '@angular/core';
import { WorkbenchService } from '../services/workbench.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { WorkbenchModuleState } from '../reducers';
import { MatDialog } from '@angular/material/dialog';
import { DownloadAnalysisFilesPopupComponent } from '../../components/download-vcf-popup/download-analysis-files-popup.component';
import { OpenExportExcelDialog } from '../../../variants/store';
import { OpenSaveFileLinkWindow, OpenSnackbar } from '../../../../../../store';
import { SnackbarMode } from '../../../../../../store/models/snackbar.model';

@Injectable()
export class DownloadAnalysisFileEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<WorkbenchModuleState>,
    private service: WorkbenchService,
    private dialog: MatDialog,
  ) {}

  getDownloadAnalysisFileUrls: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GET_DOWNLOAD_ANALYSIS_FILE_URL),
      mergeMap((action: fromAction.GetDownloadAnalysisFileUrl) =>
        this.service.getAnalysisFileDownloadUrls(action.analysisId, action.fileType).pipe(
          map((result) => new fromAction.GetDownloadAnalysisFileUrlSuccess(result, action.fileType)),
          catchError((err) => of(new fromAction.GetDownloadAnalysisFileUrlFail(err, action.fileType))),
        ),
      ),
    ),
  );

  downloadAnalysisFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.DOWNLOAD_ANALYSIS_FILE),
      mergeMap((action: fromAction.DownloadAnalysisFile) =>
        this.service.getAnalysisFileDownloadUrls(action.analysisId, action.fileType).pipe(
          map((files) => new fromAction.DownloadAnalysisFileSuccess(files, action.fileType)),
          catchError((err) => of(new fromAction.DownloadAnalysisFileFail(err, action.fileType))),
        ),
      ),
    ),
  );

  downloadAnalysisFileSuccessSingle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.DOWNLOAD_ANALYSIS_FILE_SUCCESS),
      filter((action: fromAction.DownloadAnalysisFileSuccess) => action.files.length === 1),
      switchMap((action: fromAction.DownloadAnalysisFileSuccess) =>
        action.files.map((file) => new OpenSaveFileLinkWindow(file.url, file.name)),
      ),
    ),
  );

  downloadAnalysisFileSuccessMultiple$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.DOWNLOAD_ANALYSIS_FILE_SUCCESS),
      filter((action: fromAction.DownloadAnalysisFileSuccess) => action.files.length > 1),
      switchMap((action: fromAction.DownloadAnalysisFileSuccess) => [
        new fromAction.DownloadAnalysisFileOpenPopup(action.files, action.fileType),
      ]),
    ),
  );

  downloadAnalysisFileOpenPopup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAction.DOWNLOAD_ANALYSIS_FILE_OPEN_POPUP),
        tap((action: fromAction.DownloadAnalysisFileOpenPopup) =>
          this.dialog.open(DownloadAnalysisFilesPopupComponent, {
            width: '500px',
            data: { fileType: action.fileType, files: action.files },
            position: { top: '30px' },
            panelClass: 'paddingless-dialog',
          }),
        ),
      ),
    { dispatch: false },
  );

  downloadFamilyCarrierTests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.DOWNLOAD_FAMILY_CARRIERS),
      switchMap((action: fromAction.DownloadFamilyCarriers) =>
        this.service.getFamilyCarrierTestDownloadUrl({ ...action.payload, exported_file_name: action.fileName }).pipe(
          map((result) => new fromAction.DownloadFamilyCarriersSuccess(result, action.fileName)),
          catchError((error) => of(new fromAction.DownloadFamilyCarriersFail(error))),
        ),
      ),
    ),
  );

  downloadFamilyCarrierTestsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.DOWNLOAD_FAMILY_CARRIERS_SUCCESS),
      map(
        (action: fromAction.DownloadFamilyCarriersSuccess) => new OpenSaveFileLinkWindow(action.path, action.fileName),
      ),
    ),
  );

  checkExportControlSampleReport: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.CHECK_CONTROL_SAMPLE_REPORT_EXPORT),
      switchMap((action: fromAction.CheckControlSampleReportExport) =>
        this.service.checkExportControlSampleReport(action.analysisId).pipe(
          map((response) => new fromAction.CheckControlSampleReportExportSuccess(response)),
          catchError((err) => of(new fromAction.CheckControlSampleReportExportFail(err))),
        ),
      ),
    ),
  );

  exportControlSampleReport: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.EXPORT_CONTROL_SAMPLE_REPORT_REPORT),
      map(
        (action: fromAction.ExportControlSampleReportReport) =>
          new OpenSaveFileLinkWindow(action.path, `control_sample_report.xlsx`),
      ),
    ),
  );

  exportFilteredVariants: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.EXPORT_FILTERED_VARIANTS),
      switchMap((action: fromAction.ExportFilteredVariants) =>
        this.service.exportFilteredVariants(action.analysisId).pipe(
          map((result) => new fromAction.ExportFilteredVariantsSuccess(result)),
          catchError((error) => of(new fromAction.ExportFilteredVariantsFail(error))),
        ),
      ),
    ),
  );

  exportFilteredVariantsSuccess: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.EXPORT_FILTERED_VARIANTS_SUCCESS),
      map((action) => new OpenExportExcelDialog()),
    ),
  );

  downloadAnalysisSampleFile$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.DOWNLOAD_ANALYSIS_SAMPLE_FILE),
      switchMap((action: fromAction.DownloadAnalysisSampleFile) =>
        this.service.getAnalysisSampleFileDownloadUrl(action.sampleId, action.fileId, action.fileType).pipe(
          map(
            (result) =>
              new fromAction.DownloadAnalysisSampleFileSuccess(action.sampleId, action.fileId, result.download_link),
          ),
          catchError((error) =>
            of(new fromAction.DownloadAnalysisSampleFileFail(action.sampleId, action.fileId, error)),
          ),
        ),
      ),
    ),
  );

  downloadAnalysisSampleFileSuccess$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.DOWNLOAD_ANALYSIS_SAMPLE_FILE_SUCCESS),
      map(
        (action: fromAction.DownloadAnalysisSampleFileSuccess) =>
          new OpenSaveFileLinkWindow(action.downloadLink, 'moo'),
      ),
    ),
  );

  downloadAnalysisSampleFileFail$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAction.DOWNLOAD_ANALYSIS_SAMPLE_FILE_FAIL),
        map(() => new OpenSnackbar('Error retrieving download link', SnackbarMode.Error)),
      ),
  );
}
