import { createSelector, MemoizedSelector } from '@ngrx/store';
import * as fromRichTemplate from '../reducers/rich-template.reducer';
import { AppModuleState, getAppModuleState } from '../reducers';

export const selectRichTemplateState: MemoizedSelector<AppModuleState, fromRichTemplate.State> = createSelector(
  getAppModuleState,
  (state: AppModuleState) => state[fromRichTemplate.richTemplateFeatureKey],
);

export const selectRichTemplateData: MemoizedSelector<AppModuleState, Record<string, string>> = createSelector(
  selectRichTemplateState,
  (state: fromRichTemplate.State) => state.data,
);
