import { ApplicationType, MiniApplicationType, VariantPageConfig } from '../../models';

import * as fromAction from '../actions/config.action';
import * as fromPublicationsAction from '../../../publications/store/actions/publications.action';
import * as fromConditionsAction from '../../../conditions/store/actions/conditions.action';
import * as fromVariantAction from '../../store/actions';

const MINI_APP_WIDTH_KEY = 'miniAppWidth';
export const MINI_APP_MIN_WIDTH = 13;
export const MINI_APP_DEFAULT_WIDTH = 424;

const getExpandedWidth = (): number => {
  return parseInt(localStorage.getItem(MINI_APP_WIDTH_KEY), 10) || MINI_APP_DEFAULT_WIDTH;
};

export interface ConfigState {
  config: VariantPageConfig;
  miniAppExpandedState: number;
  defaultMiniApplications: MiniApplicationType[];
  defaultSelectedMiniApplication: MiniApplicationType;
  popupOpen: boolean;
}

const initialState: ConfigState = {
  config: null,
  miniAppExpandedState: getExpandedWidth(),
  defaultMiniApplications: [],
  defaultSelectedMiniApplication: null,
  popupOpen: false,
};

export function reducer(
  state = initialState,
  action:
    | fromAction.ConfigAction
    | fromPublicationsAction.PublicationsAction
    | fromConditionsAction.ConditionsAction
    | fromVariantAction.VariantDetailsAction,
): ConfigState {
  switch (action.type) {
    case fromAction.SET_VARIANT_PAGE_CONFIG: {
      return {
        ...state,
        config: action.config,
        defaultMiniApplications: action.config?.miniApplications || [],
      };
    }
    case fromAction.CHANGE_VARIANT_PAGE_APP_CONFIG: {
      return {
        ...state,
        config: {
          ...state.config,
          applicationsConfig: {
            ...state.config.applicationsConfig,
            [action.app]: {
              ...state.config.applicationsConfig[action.app],
              [action.property]: action.value,
            },
          },
        },
      };
    }
    case fromAction.LOAD_DEFAULT_APPLICATION: {
      return {
        ...state,
        config: {
          ...state.config,
          selectedApplication: state.config.defaultApplication,
          selectedMaxiApplication: null,
        },
      };
    }
    case fromAction.SET_SELECTED_APP_SUCCESS: {
      return {
        ...state,
        config: {
          ...state.config,
          selectedApplication: action.app,
          selectedMaxiApplication: null,
        },
      };
    }
    case fromAction.SET_SELECTED_MINI_APP: {
      return {
        ...state,
        config: {
          ...state.config,
          selectedMiniApplication: action.miniApp,
          miniApplicationsConfig: {
            ...state.config?.miniApplicationsConfig || {},
            ...action.config || {}
          }
          // selectedMaxiApplication: null
        },
        miniAppExpandedState:
          state.miniAppExpandedState < MINI_APP_DEFAULT_WIDTH ? getExpandedWidth() : state.miniAppExpandedState,
      };
    }
    case fromAction.SET_MINI_APPS: {
      return {
        ...state,
        defaultMiniApplications: state.config.miniApplications ? [...state.config.miniApplications] : [],
        defaultSelectedMiniApplication: state.config.selectedMiniApplication,
        config: {
          ...state.config,
          miniApplications: action.miniApps,
        },
      };
    }
    case fromAction.RESET_MINI_APPS: {
      return {
        ...state,
        config: {
          ...state.config,
          selectedMiniApplication: state.defaultSelectedMiniApplication,
          miniApplications: [...state.defaultMiniApplications],
        },
      };
    }
    case fromAction.SET_SELECTED_MAXI_APP: {
      return {
        ...state,
        config: {
          ...state.config,
          selectedMaxiApplication: action.maxiApp,
        },
      };
    }
    case fromAction.TOGGLE_MINI_APP_EXPANDED_STATE: {
      const miniAppExpandedState: number =
        action.width || (state.miniAppExpandedState < MINI_APP_DEFAULT_WIDTH ? getExpandedWidth() : MINI_APP_MIN_WIDTH);
      if (miniAppExpandedState >= MINI_APP_DEFAULT_WIDTH) {
        localStorage.setItem(MINI_APP_WIDTH_KEY, miniAppExpandedState.toString());
      }
      return {
        ...state,
        miniAppExpandedState,
      };
    }
    // case fromPublicationsAction.GET_PUBLICATIONS: {
    //   if (state.config.applicationsConfig[ApplicationType.Publications]) {
    //     return {
    //       ...state,
    //       config: {
    //         ...state.config,
    //         applicationsConfig: {
    //           ...state.config.applicationsConfig,
    //           [ApplicationType.Publications]: {
    //             ...state.config.applicationsConfig[ApplicationType.Publications],
    //             scope: null,
    //             selected: null
    //           }
    //         }
    //       }
    //     };
    //   }
    //   return state;
    // }
    case fromConditionsAction.GET_CONDITIONS: {
      if (state.config.applicationsConfig[ApplicationType.Conditions]) {
        return {
          ...state,
          config: {
            ...state.config,
            applicationsConfig: {
              ...state.config.applicationsConfig,
              [ApplicationType.Conditions]: {
                ...state.config.applicationsConfig[ApplicationType.Conditions],
                gene: null,
                selected: null,
              },
            },
          },
        };
      }
      return state;
    }
    case fromVariantAction.LOAD_VARIANT_DETAILS: {
      return {
        ...state,
        miniAppExpandedState:
          state.miniAppExpandedState < MINI_APP_DEFAULT_WIDTH ? getExpandedWidth() : state.miniAppExpandedState,
      };
    }
    case fromVariantAction.SET_VARIANT_POPUP_OPEN: {
      return {
        ...state,
        popupOpen: action.open,
      };
    }
    case fromVariantAction.CLEAR_VARIANT_PAGE_CONFIG: {
      return {
        ...initialState,
      };
    }
  }

  return state;
}

export const getConfigEntity = (state: ConfigState) => state.config;
export const getMiniAppExpandedState = (state: ConfigState) => state.miniAppExpandedState;
