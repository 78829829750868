import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromReducer from '../reducers/case-filter.reducer';
import { getWorkbenchModuleState, WorkbenchModuleState } from '../reducers';
import { CaseFilterData } from '../models';

const getCaseFilterState: MemoizedSelector<WorkbenchModuleState, fromReducer.CaseFilterState> = createSelector(
  getWorkbenchModuleState,
  (state: WorkbenchModuleState) => state.caseFilter,
);

export const getCaseFilter: MemoizedSelector<WorkbenchModuleState, CaseFilterData> = createSelector(
  getCaseFilterState,
  fromReducer.getCaseFilterDataResponse,
);

export const getCaseFilterLoaded: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getCaseFilterState,
  fromReducer.getCaseFilterDataResponseLoaded,
);

export const getCaseFilterLoading: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getCaseFilterState,
  fromReducer.getCaseFilterDataResponseLoading,
);
