import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: 'textarea[gnxTextArea]',
  standalone: true,
})
export class TextAreaDirective {
  @HostBinding('style.resize') @Input() resize: 'none' | 'horizontal' | 'vertical';

  constructor() {}
}
