import { Component, Inject, OnInit } from '@angular/core';
import { QuitSignUp, SetRedirectAfterAuth, SetSignUpStep } from '../../../auth0/store/actions';
import { FormBuilder } from '@angular/forms';
import { AuthModuleState } from '../../../auth0/store/reducers';
import { select, Store } from '@ngrx/store';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { getJoinStep, getUserDetails } from '../../../auth0/store/selectors';
import { take } from 'rxjs/operators';
import { RouterLink } from '@angular/router';
import { JoinFormComponent } from '../../../../shared-lazy/components/join-form/join-form.component';
import { NgClass, NgIf, AsyncPipe } from '@angular/common';
import { ClickCursorDirective } from '../../../../shared/directives/click-cursor.directive';

export interface FreemiumRegistrationFormData {
  urlAfterAuth: string;
  style: string;
}

@Component({
  selector: 'gnx-freemium-registration-form-popup',
  templateUrl: './freemium-registration-form-popup.component.html',
  styleUrls: ['./freemium-registration-form-popup.component.scss'],
  standalone: true,
  imports: [ClickCursorDirective, NgClass, NgIf, JoinFormComponent, RouterLink, AsyncPipe],
})
export class FreemiumRegistrationFormPopupComponent implements OnInit {
  STYLES: {
    [style: string]: {
      title: string;
      subtitle: string;
      features?: string;
      feature1?: string;
      feature2?: string;
      feature3?: string;
      image?: string;
      customClass?: string;
    };
  } = {
    undefined: {
      title: 'This feature is accessible only for registered users',
      subtitle: 'Sign up for free and join the Franklin Community',
      features: 'Community members enjoy:',
      feature1: 'Collaboration with fellow colleagues',
      feature2: 'Personal cases repository',
      feature3: 'Unique community cases data',
    },
    optional: {
      title: 'Join The Franklin Community',
      subtitle: 'Expanding genomic clinical knowledge - together!',
      features: 'Sign up today to:',
      feature1: 'Collaborate with community members',
      feature2: 'Analyze and manage your cases',
      feature3: 'Get variant-related automatic updates',
    },
    report: {
      title: 'Analyze all reported variants at once',
      subtitle: 'Sign up for free to enjoy this and more',
      image: 'assets/svg/case-from-report-cta.svg',
      customClass: 'report',
    },
    vcf: {
      title: 'Solve More Genetic Cases',
      subtitle: 'Sign up for free to enjoy this and more',
      image: 'assets/svg/case-from-vcf-cta.svg',
      customClass: 'vcf',
    },
  };

  public step$: Observable<number>;

  constructor(
    private fb: FormBuilder,
    private authStore$: Store<AuthModuleState>,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<FreemiumRegistrationFormPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FreemiumRegistrationFormData,
  ) {}

  ngOnInit() {
    this.authStore$.dispatch(new SetRedirectAfterAuth(this.data.urlAfterAuth));
    this.authStore$
      .pipe(select(getUserDetails), take(1))
      .subscribe((userDetails) => this.authStore$.dispatch(new SetSignUpStep(userDetails ? 1 : 0)));
    this.step$ = this.authStore$.pipe(select(getJoinStep));
  }

  onClose() {
    this.dialogRef.close();
    this.authStore$.dispatch(new QuitSignUp());
  }

  onLogin() {
    this.matDialog.closeAll();
  }
}
