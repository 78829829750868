import { Injectable, OnInit } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor, OnInit {
  IGNORED_ENDPOINTS: string[] = ['/api/v2/user/check_session', '/api/v2/user/login', environment.authDomain];

  constructor(private authService: AuthService) {}

  ngOnInit(): void {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.needsToken(request.url)) {
      return this.authService.getToken().pipe(
        switchMap((token) => {
          if (token) {
            return next.handle(
              request.clone({ headers: request.headers.set('Authorization', `Bearer ${token.accessToken}`) }),
            );
          }
          return next.handle(request);
        }),
      );
    }
    return next.handle(request);
  }

  needsToken(requestUrl: string): boolean {
    return !this.IGNORED_ENDPOINTS.some((url) => requestUrl.indexOf(url) >= 0);
  }
}
