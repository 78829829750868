import * as fromAssessments from './assessments.reducer';
import { AssessmentsState } from './assessments.reducer';
import { ActionReducerMap, createFeatureSelector, MemoizedSelector } from '@ngrx/store';
import * as fromClinvar from './clinvar.reducer';
import { ClinvarState } from './clinvar.reducer';

export interface CommunityClassificationState {
  assessments: AssessmentsState;
  clinvar: ClinvarState;
}

export const reducers: ActionReducerMap<CommunityClassificationState> = {
  assessments: fromAssessments.reducer,
  clinvar: fromClinvar.reducer,
};

export const getCommunityClassificationState: MemoizedSelector<any, any> =
  createFeatureSelector<any>('communityClassification');
