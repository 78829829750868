export interface CancerTypeOption {
  primaryName: string;
  matchedNames: string[];
}

export const ERROR_CANCER_TYPE: CancerTypeOption = {
  primaryName: "We couldn't find any match\nCheck the spelling or try searching a synonym",
  matchedNames: ['error'],
};

export interface CancerTypes {
  exactMatch: CancerTypeOption[];
  relatedSuggestion: CancerTypeOption[];
}
