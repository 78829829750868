import { Action } from '@ngrx/store';
import {
  SearchConfig,
  SearchData,
  VariantCaseResponse,
  VariantDetails,
  VariantId,
  VariantPageConfig,
} from '../../models';

export const LOAD_VARIANT_DETAILS = '[Variant Page] Load Variant Details';
export const LOAD_VARIANT_DETAILS_SUCCESS = '[Variant Page] Load Variant Details Success';
export const LOAD_VARIANT_DETAILS_FAIL = '[Variant Page] Load Variant Details Fail';
export const CLEAR_VARIANT_DETAILS = '[Variant Page] Clear Variant Details';

export class LoadVariantDetails implements Action {
  readonly type = LOAD_VARIANT_DETAILS;

  constructor(
    public variantId: VariantId,
    public compoundVariantId?: VariantId,
  ) {}
}

export class LoadVariantDetailsSuccess implements Action {
  readonly type = LOAD_VARIANT_DETAILS_SUCCESS;

  constructor(
    public variantDetails: VariantDetails,
    public compoundVariantDetails?: VariantDetails,
  ) {}
}

export class LoadVariantDetailsFail implements Action {
  readonly type = LOAD_VARIANT_DETAILS_FAIL;

  constructor(public error: any) {}
}

export class ClearVariantDetails implements Action {
  readonly type = CLEAR_VARIANT_DETAILS;
}

export const PRELOAD_DATA_IN_BE = '[Variant Page] Notify BE to Prepare Features';
export const PRELOAD_DATA_IN_BE_SUCCESS = '[Variant Page] Notify BE to Prepare Features Success';
export const PRELOAD_DATA_IN_BE_FAIL = '[Variant Page] Notify BE to Prepare Features Fail';

export class PreloadDataInBE implements Action {
  readonly type = PRELOAD_DATA_IN_BE;

  constructor(
    public variantId: VariantId,
    public config: VariantPageConfig,
  ) {}
}

export class PreloadDataInBESuccess implements Action {
  readonly type = PRELOAD_DATA_IN_BE_SUCCESS;

  constructor(public response: any) {}
}

export class PreloadDataInBEFail implements Action {
  readonly type = PRELOAD_DATA_IN_BE_FAIL;

  constructor(public error: any) {}
}

export const SET_SEARCH_DATA = '[Root] Set Search Data';
export const PATCH_SEARCH_DATA = '[Root] Patch Search Data';
export const SET_SEARCH_DATA_SUCCESS = '[Root] Set Search Data success';
export const SET_SEARCH_DATA_FAIL = '[Root] Set Search Data fail';

export class SetSearchData implements Action {
  readonly type = SET_SEARCH_DATA;

  constructor(public config?: SearchConfig) {}
}

export class PatchSearchData implements Action {
  readonly type = PATCH_SEARCH_DATA;

  constructor(public config?: SearchConfig) {}
}

export class SetSearchDataSuccess implements Action {
  readonly type = SET_SEARCH_DATA_SUCCESS;

  constructor(public searchData: SearchData) {}
}

export class SetSearchDataFail implements Action {
  readonly type = SET_SEARCH_DATA_FAIL;

  constructor(public error: any) {}
}

export const SAVE_VARIANT_CASE = '[Root] Save Variant Case';
export const SAVE_VARIANT_CASE_SUCCESS = '[Root] Save Variant Case success';
export const SAVE_VARIANT_CASE_FAIL = '[Root] Save Variant Case fail';

export class SaveVariantCase implements Action {
  readonly type = SAVE_VARIANT_CASE;

  constructor(public caseName: string) {}
}

export class SaveVariantCaseSuccess implements Action {
  readonly type = SAVE_VARIANT_CASE_SUCCESS;

  constructor(
    public caseName?: string,
    public savedCase?: VariantCaseResponse,
  ) {}
}

export class SaveVariantCaseFail implements Action {
  readonly type = SAVE_VARIANT_CASE_FAIL;

  constructor(public error: any) {}
}

export const TOGGLE_REGION = '[Variant Page] Toggle Region';

export class ToggleRegion implements Action {
  readonly type = TOGGLE_REGION;

  constructor(public regionId: string) {}
}

export const OPEN_SAVE_CASE_DIALOG = '[Variant Page] Open Save Case Dialog';

export class OpenSaveCaseDialog implements Action {
  readonly type = OPEN_SAVE_CASE_DIALOG;

  constructor(public manual?: boolean) {}
}

export type VariantDetailsAction =
  | LoadVariantDetails
  | LoadVariantDetailsSuccess
  | LoadVariantDetailsFail
  | ClearVariantDetails
  | PreloadDataInBE
  | PreloadDataInBESuccess
  | PreloadDataInBEFail
  | SetSearchData
  | PatchSearchData
  | SetSearchDataSuccess
  | SetSearchDataFail
  | SaveVariantCase
  | SaveVariantCaseSuccess
  | SaveVariantCaseFail
  | ToggleRegion
  | OpenSaveCaseDialog;
