import { Injectable } from '@angular/core';
import { VariantIdTypes } from '../../../../../store/models/variant-id.model';
import { AppType, VariantPageLibConfig, VariantPageLibServiceType } from '../../../store';
import { GENE_PAGE_SERVICE_CONFIG, VARIANT_PAGE_SERVICE_CONFIG } from '../consts/lib-service-config.consts';

@Injectable()
export class VariantPageLibConfigService {
  static variantIdType: VariantIdTypes = VariantIdTypes.SNP;

  static appType: AppType;

  static config: VariantPageLibConfig;

  static readonly GENE_PAGE_CONFIG = GENE_PAGE_SERVICE_CONFIG;

  static readonly VARIANT_PAGE_CONFIG = VARIANT_PAGE_SERVICE_CONFIG;

  static getServiceConfig(service: VariantPageLibServiceType) {
    if (!VariantPageLibConfigService.config) {
      return undefined;
    }
    return VariantPageLibConfigService.appType === AppType.Variant
      ? VariantPageLibConfigService.config[service]?.[VariantPageLibConfigService.variantIdType]
      : VariantPageLibConfigService.config[service];
  }
}
