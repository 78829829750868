import { Action } from '@ngrx/store';
import { AnalysisDetails } from '../models';

export const LOAD_ANALYSIS_DETAILS = '[Root] Load Analysis Details';
export const LOAD_ANALYSIS_DETAILS_SUCCESS = '[Root] Load Analysis Details Success';
export const LOAD_ANALYSIS_DETAILS_FAIL = '[Root] Load Analysis Details Fail';
export const RELOAD_ANALYSIS_DETAILS = '[Root] Reload Analysis Details';

export class LoadAnalysisDetails implements Action {
  readonly type = LOAD_ANALYSIS_DETAILS;

  constructor(public analysisId: number) {}
}

export class LoadAnalysisDetailsSuccess implements Action {
  readonly type = LOAD_ANALYSIS_DETAILS_SUCCESS;

  constructor(public payload: AnalysisDetails) {}
}

export class LoadAnalysisDetailsFail implements Action {
  readonly type = LOAD_ANALYSIS_DETAILS_FAIL;

  constructor(public payload: any) {}
}

export class ReloadAnalysisDetails implements Action {
  readonly type = RELOAD_ANALYSIS_DETAILS;
}

export const UPDATE_ANALYSIS_STATUS = '[Root] Update Analysis Status';
export const UPDATE_ANALYSIS_STATUS_SUCCESS = '[Root] Update Analysis Status Success';
export const UPDATE_ANALYSIS_STATUS_FAIL = '[Root] Update Analysis Status Fail';

export class UpdateAnalysisStatus implements Action {
  readonly type = UPDATE_ANALYSIS_STATUS;

  constructor(
    public analysisId: number,
    public status: number,
  ) {}
}

export class UpdateAnalysisStatusSuccess implements Action {
  readonly type = UPDATE_ANALYSIS_STATUS_SUCCESS;

  constructor(public payload: AnalysisDetails) {}
}

export class UpdateAnalysisStatusFail implements Action {
  readonly type = UPDATE_ANALYSIS_STATUS_FAIL;

  constructor(public payload: any) {}
}

export const UPDATE_ANALYSIS_FOLLOW = '[Root] Update Analysis Follow';
export const UPDATE_ANALYSIS_FOLLOW_SUCCESS = '[Root] Update Analysis Follow Success';
export const UPDATE_ANALYSIS_FOLLOW_FAIL = '[Root] Update Analysis Follow Fail';

export class UpdateAnalysisFollow implements Action {
  readonly type = UPDATE_ANALYSIS_FOLLOW;

  constructor(
    public analysisId: number,
    public value: boolean,
  ) {}
}

export class UpdateAnalysisFollowSuccess implements Action {
  readonly type = UPDATE_ANALYSIS_FOLLOW_SUCCESS;

  constructor(public payload: AnalysisDetails) {}
}

export class UpdateAnalysisFollowFail implements Action {
  readonly type = UPDATE_ANALYSIS_FOLLOW_FAIL;

  constructor(public payload: any) {}
}

export const CLEAR_ANALYSIS_DETAILS = '[Root] Clear Analysis Details';

export class ClearAnalysisDetails implements Action {
  readonly type = CLEAR_ANALYSIS_DETAILS;
}

export const UPDATE_ANALYSIS_ASSIGNEE = '[Root] Update Analysis Assignee';
export const UPDATE_ANALYSIS_ASSIGNEE_SUCCESS = '[Root] Update Analysis Assignee Success';
export const UPDATE_ANALYSIS_ASSIGNEE_FAIL = '[Root] Update Analysis Assignee Fail';

export class UpdateAnalysisAssignee implements Action {
  readonly type = UPDATE_ANALYSIS_ASSIGNEE;

  constructor(public payload: { assigned_user_email: string; analysisId?: number }) {}
}

export class UpdateAnalysisAssigneeSuccess implements Action {
  readonly type = UPDATE_ANALYSIS_ASSIGNEE_SUCCESS;

  constructor(public payload: AnalysisDetails) {}
}

export class UpdateAnalysisAssigneeFail implements Action {
  readonly type = UPDATE_ANALYSIS_ASSIGNEE_FAIL;

  constructor(public payload: any) {}
}

export const WARM_ANALYSIS = '[Root] Warm analysis';

export class WarmAnalysis implements Action {
  readonly type = WARM_ANALYSIS;

  constructor(public analysisId: number) {}
}

export type AnalysisDetailsAction =
  | LoadAnalysisDetails
  | LoadAnalysisDetailsSuccess
  | LoadAnalysisDetailsFail
  | UpdateAnalysisStatus
  | UpdateAnalysisStatusSuccess
  | UpdateAnalysisStatusFail
  | UpdateAnalysisFollow
  | UpdateAnalysisFollowSuccess
  | UpdateAnalysisFollowFail
  | ClearAnalysisDetails
  | UpdateAnalysisAssignee
  | UpdateAnalysisAssigneeSuccess
  | UpdateAnalysisAssigneeFail;
