<div class="header">
  <gnx-top-nav></gnx-top-nav>
</div>
<div class="container">
  <div class="section intro inline">
    <div class="section-content">
      <div class="section-title">
        &nbsp;&nbsp;&nbsp;&nbsp;The <br />World’s First <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Open Professional <br />&nbsp;&nbsp;&nbsp;Genomic Community
      </div>
      <div class="section-text">
        Franklin empowers genomic professionals to answer almost any genomic question - guiding more informed clinical
        decision making, determining more accurate care pathways and enabling more personalized and targeted therapies.
      </div>
    </div>
    <div class="bcg-image"></div>
    <div class="section-image"></div>
  </div>
  <div class="section community">
    <div class="section-title">Franklin’s Community</div>
    <div class="section-text">
      Who is the Franklin community? Our genomic professionals come from every stage of the medical genetics domain, and
      from every corner of the globe.
    </div>
  </div>

  <div class="section genome">
    <div class="section-title">The Building Data Blocks of the Actionable Genome</div>
    <div class="section-text">
      Franklin is a connectivity hub across the medical genetics domain. The network effect generated by the Franklin
      community extends the actionable genomic information that impacts patients' care. Franklin supports all vendors
      and workflows and thus enables any genetic professional to deliver more accurate, more actionable data insights.
    </div>
    <div class="bcg-image stats">
      <div class="stat searches"><span class="stat-primary">15M</span><span class="stat-secondary">Searches</span></div>
      <div class="stat variants">
        <span class="stat-primary">500M</span><span class="stat-secondary">Variants</span>
      </div>
      <div class="stat cases"><span class="stat-primary">150K</span><span class="stat-secondary">Cases</span></div>
      <div class="stat indications">
        <span class="stat-primary">350K</span><span class="stat-secondary">User Indications Shared</span>
      </div>
      <div class="stat organizations">
        <span class="stat-primary">2,000</span><span class="stat-secondary">Connected Organizations</span>
      </div>
    </div>
  </div>

  <div class="section insights inline">
    <div class="section-content">
      <div class="section-title">Actionable Insights</div>
      <div class="section-text">
        Franklin allows genomic professionals to spend less time pinpointing evidence, and more time drilling down into
        insights and actionability. We are building the largest, most diverse real-world evidence dataset – answering
        any genomic question through the power of the community, data and insights.
      </div>
    </div>
    <div class="section-image">
      <img class="img-responsive" src="/assets/png/static-pages/about/group-6@2x.png" />
    </div>
  </div>

  <div class="section why-franklin inline">
    <div class="section-image">
      <img class="img-responsive" src="/assets/png/static-pages/about/rosalind-franklin@2x.png" />
    </div>
    <div class="section-content">
      <div class="section-title">Why ‘Franklin’?</div>
      <div class="section-text">
        We named Franklin after Rosalind Franklin (1920-1958), whose research was crucial to our understanding of the
        molecular structures of DNA (deoxyribonucleic acid), and thus serves until today as the foundation for genomics.
      </div>
      <a class="section-link" href="https://en.wikipedia.org/wiki/Rosalind_Franklin" target="_blank">
        Read more about Rosalind Franklin
      </a>
    </div>
  </div>

  <div class="section summary">
    <div class="section-title">Franklin by Genoox</div>
    <div class="section-text">
      Genoox, Franklin's creator, uses the power of the community to make genomic data actionable, with a mission of
      creating the largest real world evidence network of genomic and clinical data. Genoox’s AI platform connects
      clinicians, genetic counselors, and healthcare organizations by sharing genetic insights, enabling platform users
      to make impactful discoveries providing better patient care.
    </div>
    <a class="cta-button" href="https://www.genoox.com/">Visit Genoox Website</a>
    <img src="/assets/png/static-pages/about/genoox@x2.png" height="422px" />

    <div class="cta-block" *ngIf="!(isLoggedIn$ | async)">
      <div class="cta-block-content">
        <span>Join Franklin Today</span>
        <span class="sub"
          >Join now to enjoy all the community and clinical information features on Franklin
          <span class="bold sub">for free!</span></span
        >
      </div>
      <a href="/join" class="cta-block-button"> SIGN UP </a>
    </div>
  </div>

  <div class="section outro"></div>
</div>
