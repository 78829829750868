import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as fromAction from './../actions/app-banner.action';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppBannerService } from '../../services/app-banner.service';
import { OpenSnackbar } from '../../../../../../store';
import { SnackbarMode } from '../../../../../../store/models/snackbar.model';

@Injectable()
export class AppBannerEffect {
  constructor(
    private actions$: Actions,
    private service: AppBannerService,
  ) {}

  requestExpertOpinion$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.REQUEST_EXPERT_OPINION),
      switchMap((action: fromAction.RequestExpertOpinion) =>
        this.service.requestExpertOpinion(action.variantId, action.details, action.caseContext).pipe(
          map((result) => new fromAction.RequestExpertOpinionSuccess(action.variantId)),
          catchError((error) => of(new fromAction.RequestExpertOpinionFail(action.variantId, error))),
        ),
      ),
    ),
  );

  requestExpertOpinionSuccess$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.REQUEST_EXPERT_OPINION_SUCCESS),
      map(
        (action: fromAction.RequestExpertOpinionSuccess) =>
          new OpenSnackbar('Your request was submitted', SnackbarMode.Success),
      ),
    ),
  );

  requestExpertOpinionFail$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.REQUEST_EXPERT_OPINION_FAIL),
      map(
        (action: fromAction.RequestExpertOpinionFail) =>
          new OpenSnackbar('An error occurred, try again later', SnackbarMode.Error),
      ),
    ),
  );
}
