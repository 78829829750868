import { Action } from '@ngrx/store';
import { VariantId } from '../../../../store/models';

export const GET_IS_RAFFLE_VARIANT_STATE = '[Variants] Get is raffle variant state';
export const GET_IS_RAFFLE_VARIANT_STATE_SUCCESS = '[Variants] Get is raffle variant state success';
export const GET_IS_RAFFLE_VARIANT_STATE_FAIL = '[Variants] Get is raffle variant state fail';

export class GetIsRaffleVariantState implements Action {
  readonly type = GET_IS_RAFFLE_VARIANT_STATE;

  constructor(public varId: VariantId) {}
}

export class GetIsRaffleVariantStateSuccess implements Action {
  readonly type = GET_IS_RAFFLE_VARIANT_STATE_SUCCESS;

  constructor(
    public varId: VariantId,
    public result: boolean,
  ) {}
}

export class GetIsRaffleVariantStateFail implements Action {
  readonly type = GET_IS_RAFFLE_VARIANT_STATE_FAIL;

  constructor(public error: any) {}
}

export type RaffleVariantAction =
  | GetIsRaffleVariantStateSuccess
  | GetIsRaffleVariantStateFail
  | GetIsRaffleVariantState;
