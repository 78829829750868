import { DynamicInputField } from '../../shared/models';
import { CaseCustomFieldsAction, GET_CASE_CUSTOM_FIELDS, GET_CASE_CUSTOM_FIELDS_SUCCESS } from '../actions';

export interface CaseCustomFieldsState {
  fields: DynamicInputField[];
  generalCaseFields: DynamicInputField[];
  caseResolutionFields: DynamicInputField[];
  patientClinicalInfoFields: DynamicInputField[];
  sampleDetailsFields: DynamicInputField[];
}

export const initialState: CaseCustomFieldsState = {
  fields: [],
  generalCaseFields: [],
  caseResolutionFields: [],
  patientClinicalInfoFields: [],
  sampleDetailsFields: [],
};

export const reducer = (state = initialState, action: CaseCustomFieldsAction): CaseCustomFieldsState => {
  switch (action.type) {
    case GET_CASE_CUSTOM_FIELDS: {
      return {
        ...state,
        fields: [],
        generalCaseFields: [],
        caseResolutionFields: [],
        patientClinicalInfoFields: [],
        sampleDetailsFields: [],
      };
    }
    case GET_CASE_CUSTOM_FIELDS_SUCCESS: {
      return {
        ...state,
        fields: action.fields,
        generalCaseFields: action.generalCaseFields,
        caseResolutionFields: action.caseResolutionFields,
        patientClinicalInfoFields: action.patientClinicalInfoFields,
      };
    }
  }
  return state;
};
