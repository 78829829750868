import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgIf } from '@angular/common';

@Component({
  selector: 'gnx-icon-details-dialog',
  templateUrl: './icon-details-dialog.component.html',
  styleUrls: ['./icon-details-dialog.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class IconDetailsDialogComponent implements OnInit {
  bgc = 'white';
  state = 'untouched';

  constructor(
    private dialogRef: MatDialogRef<IconDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { path: string },
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {}

  public onClose() {
    this.dialogRef.close();
  }

  public changeColor(color: string) {
    this.bgc = color;
  }

  public copyToClipboard() {
    const el = document.createElement('textarea');
    el.value = this.data.path;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.state = 'success';
    setTimeout(() => {
      this.state = 'untouched';
      this.ref.markForCheck();
    }, 2000);
  }
}
