import { createSelector, MemoizedSelector } from '@ngrx/store';
import { getVariantPageState, VariantPageState } from '../reducers';

import * as fromReducerSelectors from '../reducers/config.reducer';
import {
  ApplicationsConfig,
  ApplicationType,
  AssessmentToolsApplicationConfig,
  ConditionsConfig,
  FeedConfig,
  GeneCurationConfig,
  MiniApplicationConfig,
  MiniApplicationType,
  PublicationConfig,
  VariantPageConfig
} from '../../models';
import { VariantPopupAppType } from '../../../../../analysis/modules/analysis-variant/models';
import { ConfigState } from '../reducers/config.reducer';

export const getConfigState: MemoizedSelector<any, any> = createSelector(
  getVariantPageState,
  (state: VariantPageState) => state?.config,
);

export const getConfig: MemoizedSelector<any, VariantPageConfig> = createSelector(
  getConfigState,
  fromReducerSelectors.getConfigEntity,
);

export const getMiniAppExpandedState: MemoizedSelector<any, any> = createSelector(
  getConfigState,
  fromReducerSelectors.getMiniAppExpandedState,
);

export const getSelectedApplication: MemoizedSelector<VariantPageState, ApplicationType> = createSelector(
  getConfig,
  (config: VariantPageConfig) => (config ? config.selectedApplication : ApplicationType.ACMGClassification),
);

export const getSelectedMiniApplication: MemoizedSelector<VariantPageState, MiniApplicationType> = createSelector(
  getConfig,
  (config: VariantPageConfig) => (config ? config.selectedMiniApplication : MiniApplicationType.Feed),
);

export const getApplicationsConfig: MemoizedSelector<VariantPageState, ApplicationsConfig | undefined> = createSelector(
  getConfig,
  (config: VariantPageConfig) => config?.applicationsConfig || {},
);

export const getAssessmentToolsAppConfig: MemoizedSelector<
  VariantPageState,
  AssessmentToolsApplicationConfig | undefined
> = createSelector(
  getApplicationsConfig,
  (appConfig: ApplicationsConfig | undefined) => appConfig[ApplicationType.AssessmentTools] || { components: [] },
);

export const getConditionsAppConfig: MemoizedSelector<VariantPageState, ConditionsConfig | undefined> = createSelector(
  getApplicationsConfig,
  (appConfig: ApplicationsConfig | undefined) => appConfig[ApplicationType.Conditions] || {},
);

export const getPublicationsAppConfig: MemoizedSelector<VariantPageState, PublicationConfig | undefined> =
  createSelector(
    getApplicationsConfig,
    (appConfig: ApplicationsConfig | undefined) => appConfig[ApplicationType.Publications] || {},
  );

export const getFeedMiniAppConfig: MemoizedSelector<VariantPageState, FeedConfig | undefined> = createSelector(
  getApplicationsConfig,
  (appConfig: ApplicationsConfig | undefined) => appConfig[MiniApplicationType.Feed] || {},
);

export const getGeneCurationMiniAppConfig: MemoizedSelector<VariantPageState, GeneCurationConfig | undefined> =
  createSelector(
    getApplicationsConfig,
    (appConfig: ApplicationsConfig | undefined) => appConfig[MiniApplicationType.GeneCuration] || {},
  );

export const getApplyUrlNavigation: MemoizedSelector<VariantPageState, boolean> = createSelector(
  getConfig,
  (config) => !!config?.applyUrlNavigation,
);

export const getPopupAppType: MemoizedSelector<any, VariantPopupAppType> = createSelector(
  getConfig,
  (config) => config?.popupAppType || undefined,
);

export const getIsPopupOpen: MemoizedSelector<any, boolean> = createSelector(
  getConfigState,
  (state: ConfigState) => state?.popupOpen,
);


export const getMiniAppConfig: MemoizedSelector<any, MiniApplicationConfig> = createSelector(
  getConfig,
  (config) => config?.miniApplicationsConfig || {},
);
