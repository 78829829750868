<div class="citation-header">
  <strong
    #title
    [matTooltip]="citation.title"
    [matTooltipDisabled]="title.scrollWidth <= title.clientWidth"
    matTooltipClass="blue-md-tooltip wide"
  >
    {{ citation.title }}
  </strong>
  <img src="assets/svg/ex-link.svg" />
  <span>{{ citation.month.toString() | amParse: 'M' | amDateFormat: 'MMM' }} {{ citation.year }}</span>
</div>
<div class="citation-info">
  {{ citation.reference }}
</div>
