<gnx-top-nav [activeMiniApp]="MiniApps.ClinicalDB"></gnx-top-nav>
<div class="content">
  <div class="empty-small"></div>
  <div class="home-title">The Future of Genomic Medicine</div>
  <gnx-search *ngIf="showSearch$ | async"></gnx-search>
  <div
    *ngIf="showSearch$ | async"
    class="create-case-buttons"
    gnxElementPermissionResolver
    #createAction="permissionresolver"
    [requiredActionPermission]="UserPermission.CreateCase"
  >
    <span>Create new case from:</span>
    <div matTooltip="Permission required for this action" [matTooltipDisabled]="createAction.isActionPermitted">
      <div class="create-case-btn" (click)="createCaseFromCMA()" [class.disabled]="!createAction.isActionPermitted">
        <div class="img-wrapper"><img src="assets/svg/from-cma.svg" /></div>
        <div>CMA</div>
      </div>
    </div>
    <div matTooltip="Permission required for this action" [matTooltipDisabled]="createAction.isActionPermitted">
      <div class="create-case-btn" (click)="createCaseFromReport()" [class.disabled]="!createAction.isActionPermitted">
        <div class="img-wrapper"><img src="assets/svg/from-report-icon.svg" /></div>
        <div>Report</div>
      </div>
    </div>
    <div matTooltip="Permission required for this action" [matTooltipDisabled]="createAction.isActionPermitted">
      <div class="create-case-btn" (click)="createCaseFromVCF()" [class.disabled]="!createAction.isActionPermitted">
        <div class="img-wrapper"><img src="assets/svg/folder-white.svg" /></div>
        <div>VCF</div>
      </div>
    </div>
  </div>
  <div class="empty-big"></div>
  <div class="home-terms">
    By selecting ‘Search’ you accept our
    <a href="https://www.genoox.com/terms/" target="_blank">Terms and Conditions</a>
    and
    <a href="https://www.genoox.com/privacy-policy/" target="_blank">Privacy Policy</a>
  </div>
</div>
