import { createSelector, MemoizedSelector } from '@ngrx/store';
import { MemoizedSelectorWithProps } from '@ngrx/store/src/selector';

import * as fromReducer from './../reducers/variant-page-config.reducer';
import { VariantPageConfig } from '../../../variant-page/modules/variant-page/models';
import { getAppModuleState, VariantPageModuleState } from '../reducers';

const getVariantPageConfigState: MemoizedSelector<VariantPageModuleState, fromReducer.VariantPageConfigState> =
  createSelector(getAppModuleState, (state: VariantPageModuleState) => state.variantPageConfig);

export const getVariantPageConfig: MemoizedSelectorWithProps<VariantPageModuleState, any, VariantPageConfig> =
  createSelector(getVariantPageConfigState, fromReducer.getVariantPageConfig);

export const getVariantPageConfigLoading: MemoizedSelectorWithProps<VariantPageModuleState, any, boolean> =
  createSelector(getVariantPageConfigState, fromReducer.getVariantPageConfigLoading);

export const getVariantPageConfigLoaded: MemoizedSelectorWithProps<VariantPageModuleState, any, boolean> =
  createSelector(getVariantPageConfigState, fromReducer.getVariantPageConfigLoaded);
