<nav mat-tab-nav-bar [tabPanel]="tabPanel" color="accent" gnxBlock border="0 0 1px solid #d5d7db">
  <div
    *ngFor="let tab of tabs"
    gnxFlexItem
    displayFlex="true"
    column="true"
    [flex]="tabWidth ? '0 0 ' + tabWidth : '1'"
    gnxBlock
    height="58px"
    padding="0 16px"
    border="0 1px 0 0 solid #d5d7db"
    [matTooltip]="tab.disabledTooltip"
    [matTooltipDisabled]="!tab.disabled"
    matTooltipClass="blue-md-tooltip wider"
    matTooltipShowDelay="200"
    mat-tab-link
    [active]="activeValue === tab.value"
    [disabled]="tab.disabled"
    (click)="tabSelect.emit(tab.value)"
  >
    <div gnxFlexItem displayFlex="true" flex="1" gap="12px" justify="flex-start" align="center">
      <img *ngIf="tab.icon" [src]="tab.icon" [alt]="tab.icon" />
      <gnx-text *ngIf="tab.label" size="18px" fontWeight="bold">{{ tab.label }}</gnx-text>
      <ng-container *ngIf="tab.template" [ngTemplateOutlet]="tab.template"></ng-container>
    </div>
  </div>
</nav>
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
