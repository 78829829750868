import { Action } from '@ngrx/store';

export const GET_USER_VARIANTS_COUNT = '[Variants] Get user variants count';
export const GET_USER_VARIANTS_COUNT_SUCCESS = '[Variants] Get user variants count success';
export const GET_USER_VARIANTS_COUNT_FAIL = '[Variants] Get user variants count fail';

export class GetUserVariantsCount implements Action {
  readonly type = GET_USER_VARIANTS_COUNT;
}

export class GetUserVariantsCountSuccess implements Action {
  readonly type = GET_USER_VARIANTS_COUNT_SUCCESS;

  constructor(public count: number) {}
}

export class GetUserVariantsCountFail implements Action {
  readonly type = GET_USER_VARIANTS_COUNT_FAIL;

  constructor(public err: any) {}
}

export type VariantsCountAction = GetUserVariantsCount | GetUserVariantsCountFail | GetUserVariantsCountSuccess;
