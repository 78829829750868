import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromAction from '../actions/predefined-filters.action';
import * as fromTreeAction from '../actions/filter-tree.actions';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { FiltersService } from '../services/filters.service';
import { OpenSnackbar, UpdateQueryParams } from '../../../../../../store/actions';
import { SnackbarMode } from '../../../../../../store/models/snackbar.model';
import { select, Store } from '@ngrx/store';
import { AppModuleState } from '../../../../../../store/reducers';
import { getQueryParams, getVariantType } from '../../../../../../store/selectors';
import { Params } from '@angular/router';
import { FiltersState } from '../reducers';
import { getFlattenedFiltersStructureEntities } from '../selectors';
import { VariantType } from '../../../analysis-variant/models';
import { FilterStructure } from '../../../shared/models/filter-structure.model';
import { UpdateFilterOptions } from '../actions';
import { AnalysisType } from '../../../../../../store';

@Injectable()
export class PredefinedFiltersEffect {
  constructor(
    private actions$: Actions,
    private service: FiltersService,
    private rootStore$: Store<AppModuleState>,
    private store$: Store<FiltersState>,
  ) {}

  updateGvSavedFilters$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.UPDATE_GV_SAVED_FILTERS),
      switchMap((action: fromAction.UpdateGVSavedFilters) =>
        this.service.updateSavedFilters(action.analysisId, action.filters).pipe(
          map((result) => new fromAction.UpdateGVSavedFiltersSuccess(result)),
          catchError((err) => of(new fromAction.UpdateGVSavedFiltersFail(err))),
        ),
      ),
    ),
  );

  updateQuickFiltersMetadata$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.UPDATE_QUICK_FILTERS_METADATA),
      switchMap((action: fromAction.UpdateQuickFiltersMetadata) =>
        this.service.updateQuickFiltersMetadata(action.analysisType, action.variantType, action.payload).pipe(
          map((result) => new fromAction.UpdateQuickFiltersMetadataSuccess(action.payload)),
          catchError((err) => of(new fromAction.UpdateQuickFiltersMetadataFail(err))),
        ),
      ),
    ),
  );

  updateQuickFiltersMetadataFail$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.UPDATE_QUICK_FILTERS_METADATA_FAIL),
      map(() => new OpenSnackbar('Error! Updating quick filters failed', SnackbarMode.Error)),
    ),
  );

  createSavedFilter$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.CREATE_SAVED_FILTER),
      withLatestFrom(this.rootStore$.pipe(select(getQueryParams))),
      switchMap(([action, params]: [fromAction.CreateSavedFilter, Params]) => {
        const filter = {
          data: params,
          ...action.filter,
        };
        return this.service.createSavedFilter(action.analysisId, action.analysisType, action.variantType, filter).pipe(
          map(
            (savedFilter) =>
              new fromAction.CreateSavedFiltersSuccess(
                action.analysisId,
                action.analysisType,
                action.variantType,
                savedFilter,
              ),
          ),
          catchError((err) => of(new fromAction.CreateSavedFiltersFail(err))),
        );
      }),
    ),
  );

  createSavedFiltersSuccess$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.CREATE_SAVED_FILTER_SUCCESS),
      map((action: fromAction.CreateSavedFiltersSuccess) =>
        fromTreeAction.loadFilterTreeLabels({
          analysisId: action.analysisId,
          variantType: action.variantType,
          analysisType: action.analysisType as AnalysisType,
        }),
      ),
    ),
  );

  createSavedFiltersFail$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.CREATE_SAVED_FILTER_FAIL),
      switchMap((action: fromAction.CreateSavedFiltersFail) =>
        of(new OpenSnackbar('Error! Save filter failed', SnackbarMode.Error)),
      ),
    ),
  );

  deleteSavedFilter$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.DELETE_SAVED_FILTER),
      mergeMap((action: fromAction.DeleteSavedFilter) =>
        this.service.deleteSavedFilter(action.filterId).pipe(
          switchMap(() => [new fromAction.DeleteSavedFilterSuccess(action.filterId)]),
          catchError((error) => of(new fromAction.DeleteSavedFilterFail(error))),
        ),
      ),
    ),
  );

  applyQuickFilter$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.APPLY_QUICK_FILTER),
      withLatestFrom(
        this.rootStore$.pipe(select(getQueryParams)),
        this.rootStore$.pipe(select(getVariantType)),
        this.store$.pipe(select(getFlattenedFiltersStructureEntities)),
      ),
      switchMap(
        ([action, queryParams, varType, filters]: [
          fromAction.ApplyQuickFilter,
          Params,
          VariantType,
          { [id: string]: FilterStructure },
        ]) => {
          const item = action.filterContent;
          const analysis = action.analysis;
          const activePanels = queryParams.kb_panels
            ? typeof queryParams.kb_panels === 'string'
              ? [queryParams.kb_panels]
              : [...queryParams.kb_panels]
            : [];
          const presetOtherPanels = item?.other_panels
            ? typeof item.other_panels === 'string'
              ? [item.other_panels]
              : [...item.other_panels]
            : [];
          const presetKbPanels = item.kb_panels
            ? typeof item.kb_panels === 'string'
              ? [item.kb_panels]
              : [...item.kb_panels]
            : [];

          const other_panels = [...new Set([...presetOtherPanels, ...presetKbPanels])];

          const version =
            varType === VariantType.SNP || varType === VariantType.All
              ? analysis.main_sample.annotation_version
              : varType === VariantType.RNA
                ? analysis.main_sample.rna_annotation_version
                : analysis.main_sample.sv_annotation_version;
          const validFilters = Object.keys(item)
            .filter((f) => !(version < filters[f]?.min_version))
            .reduce((prev, cur) => ({ ...prev, [cur]: item[cur] }), {});

          if (other_panels.length) {
            return this.service.generateOtherPanelsFilterOptions(other_panels).pipe(
              switchMap((otherPanelFilterOptions) => {
                const params: Params = {
                  ...validFilters,
                  other_panels,
                  kb_panels: [...activePanels],
                  phenotypes: queryParams.phenotypes,
                };
                return [
                  new UpdateFilterOptions({ other_panels: otherPanelFilterOptions }, true, false),
                  new UpdateQueryParams(params, true),
                ];
              }),
            );
          } else {
            const params: Params = {
              ...validFilters,
              other_panels,
              kb_panels: [...activePanels],
              phenotypes: queryParams.phenotypes,
            };
            return [new UpdateFilterOptions({ other_panels: [] }, true, false), new UpdateQueryParams(params, true)];
          }
        },
      ),
    ),
  );
}
