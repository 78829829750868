import { createReducer, on } from '@ngrx/store';
import { VariantCurationActions } from '../actions';
import { AcmgData, AcmgSectionResult } from '../../models';

export interface VariantCurationState {
  curation: AcmgData;
  loading: boolean;
  loaded: boolean;
  sections: {
    [sectionId: string]: {
      curation: AcmgSectionResult;
      loading: boolean;
      loaded: boolean;
    };
  };
}

export const initialState: VariantCurationState = {
  curation: undefined,
  loading: undefined,
  loaded: undefined,
  sections: undefined,
};

export const reducer = createReducer(
  initialState,
  on(VariantCurationActions.loadVariantCurations, (state) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(VariantCurationActions.loadVariantCurationsSuccess, (state, action) => ({
    ...state,
    curation: action.data,
    loading: false,
    loaded: true,
  })),
  on(VariantCurationActions.loadVariantCurationsFailure, (state) => ({
    ...state,
    loading: false,
    loaded: false,
  })),
  on(VariantCurationActions.loadVariantCurationsSection, (state, action) => ({
    ...state,
    sections: {
      ...state.sections,
      [action.section]: {
        ...state.sections?.[action.section],
        loading: true,
        loaded: false,
      },
    },
  })),
  on(VariantCurationActions.loadVariantCurationsSectionSuccess, (state, action) => ({
    ...state,
    sections: {
      ...state.sections,
      [action.data.section]: {
        curation: action.data,
        loading: false,
        loaded: true,
      },
    },
  })),
  on(VariantCurationActions.loadVariantCurationsSectionFailure, (state, action) => ({
    ...state,
    sections: {
      ...state.sections,
      [action.section]: {
        curation: undefined,
        loading: false,
        loaded: false,
      },
    },
  })),
  on(VariantCurationActions.saveVariantCurationsSection, (state, action) => ({
    ...state,
    sections: {
      ...state.sections,
      [action.section]: {
        ...state.sections[action.section],
        loading: true,
        loaded: false,
      },
    },
  })),
  on(VariantCurationActions.saveVariantCurationsSectionSuccess, (state, action) => ({
    ...state,
    curation: action.data,
    sections: {
      ...state.sections,
      [action.section]: {
        ...state.sections[action.section],
        loading: false,
        loaded: true,
      },
    },
  })),
  on(VariantCurationActions.saveVariantCurationsSectionFailure, (state, action) => ({
    ...state,
    sections: {
      ...state.sections,
      [action.section]: {
        ...state.sections[action.section],
        loading: false,
        loaded: false,
      },
    },
  })),
);
