<div class="dialog-header">
  <div class="subtitle">{{ subtitle }}</div>
  <div class="title">{{ title }}</div>
  <img src="assets/svg/esc-with-txt.svg" width="35" height="32" (click)="onClose()" />
</div>
<mat-dialog-content>
  <form [formGroup]="saveForm">
    <div class="input-container">
      <div>
        <label
          *ngIf="nameFormControl.valid || nameFormControl.untouched"
          for="filter-name"
          [ngClass]="{ red: nameFormControl.invalid && nameFormControl.touched }"
        >
          Name*
        </label>
        <span class="red" *ngIf="nameFormControl.invalid && nameFormControl.touched">
          {{ nameFormControl.errors?.required ? 'Name is required' : '' }}
        </span>
      </div>
      <input
        id="filter-name"
        formControlName="name"
        #nameInput
        [ngClass]="{ red: nameFormControl.invalid && nameFormControl.touched }"
      />
    </div>
    <div class="input-container">
      <div>
        <label
          for="filter-description"
          *ngIf="descriptionFormControl.valid || descriptionFormControl.untouched"
          [ngClass]="{ red: descriptionFormControl.invalid && descriptionFormControl.touched }"
          >Description</label
        >
        <span class="red" *ngIf="descriptionFormControl.invalid && descriptionFormControl.touched">
          {{
            descriptionFormControl.errors?.maxlength
              ? 'Description too long (' +
                descriptionFormControl.errors?.maxlength?.actualLength +
                '/' +
                descriptionFormControl.errors?.maxlength?.requiredLength +
                ')'
              : ''
          }}
        </span>
      </div>
      <textarea
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        id="filter-description"
        formControlName="description"
        [ngClass]="{ red: descriptionFormControl.invalid && descriptionFormControl.touched }"
      ></textarea>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <gnx-checkbox [formControl]="quickFilterFormControl">Show on the quick filter panel</gnx-checkbox>
  <a (click)="onClose()">Cancel</a>
  <button [disabled]="saveForm.invalid" (click)="onSave()">
    <span *ngIf="!saveFilterLoading">Save</span>
    <gnx-dots-loader *ngIf="saveFilterLoading"></gnx-dots-loader>
  </button>
</mat-dialog-actions>
