import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, pairwise, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { NavigationEnd, Params, Router } from '@angular/router';
import {
  CLEAR_QUERY_PARAMS,
  ClearQueryParams,
  SetCurrentRoute,
  SetPreviousRoute,
  UPDATE_QUERY_PARAMS,
  UpdateQueryParams,
} from '../actions/route.action';
import { Observable } from 'rxjs';
import { ROUTER_NAVIGATED, ROUTER_NAVIGATION, RouterNavigatedAction } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import { AppModuleState } from '../reducers';
import * as fromAnalysisDetailsActions from '../actions/analysis-details.action';
import { getAnalysisDetails, getAnalysisId, getParams, getQueryParams } from '../selectors';
import { LoadPatientInfo } from '../../modules/analysis/modules/workbench/store';

declare var dataLayer: any;

@Injectable()
export class RouteEffect {
  constructor(
    private actions$: Actions,
    private rootStore$: Store<AppModuleState>,
    private router: Router,
  ) {}

  navigationEnd$ = createEffect(() =>
    this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      pairwise(),
      map((navigations) => new SetPreviousRoute(navigations[0] as NavigationEnd)),
    ),
  );

  navigationEnd1$ = createEffect(() =>
    this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map((navigation) => new SetCurrentRoute(navigation as NavigationEnd)),
    ),
  );

  updateDateLayer$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        tap((action: RouterNavigatedAction) => {
          if (action.payload.event.urlAfterRedirects) {
            dataLayer.push({ urlRedirectEvent: action.payload.event.url });
          }
        }),
      ),
    { dispatch: false },
  );

  updateQueryParams$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UPDATE_QUERY_PARAMS),
        withLatestFrom(this.rootStore$.pipe(select(getParams)), this.rootStore$.pipe(select(getQueryParams))),
        map(([action, params, queryParams]: [UpdateQueryParams, Params, Params]) => {
          this.router.navigate([this.router.routerState.snapshot.url.split('?')[0]], {
            queryParams: { ...(action.rewrite ? {} : queryParams), ...action.payload },
            replaceUrl: true,
          });
        }),
      ),
    { dispatch: false },
  );

  clearQueryParams$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(CLEAR_QUERY_PARAMS),
      withLatestFrom(this.rootStore$.pipe(select(getQueryParams))),
      map(
        ([action, queryParams]: [ClearQueryParams, any]) =>
          new UpdateQueryParams({ sort1: queryParams.sort1, sort1dir: queryParams.sort1dir }, true),
      ),
    ),
  );

  loadAnalysis: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisId)), this.rootStore$.pipe(select(getAnalysisDetails))),
      filter(([, analysisId, analysis]) => analysisId !== undefined && analysisId > 0 && analysisId !== analysis?.id),
      switchMap(([, analysisId]) => [
        new fromAnalysisDetailsActions.LoadAnalysisDetails(analysisId),
        new LoadPatientInfo(analysisId),
      ]),
    ),
  );

  clearAnalysis: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisId)), this.rootStore$.pipe(select(getAnalysisDetails))),
      filter(([, analysisId, analysis]) => (analysisId === undefined || analysisId <= 0) && !!analysis?.id),
      map(() => new fromAnalysisDetailsActions.ClearAnalysisDetails()),
    ),
  );
}
