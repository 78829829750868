import { createSelector, MemoizedSelector } from '@ngrx/store';
import { VariantCurationState } from '../reducers/variant-curation.reducer';
import { AcmgData, AcmgSectionResult } from '../../models';
import { getVariantInterpretationState, VariantInterpretationState } from '../reducers';
import { VariantInterpretation } from '../../models';

export const getVariantCurationState: MemoizedSelector<VariantInterpretationState, VariantCurationState> =
  createSelector(getVariantInterpretationState, (state: VariantInterpretationState) => state.curation);

export const getVariantCuration: MemoizedSelector<VariantInterpretationState, AcmgData> = createSelector(
  getVariantCurationState,
  (state: VariantCurationState) => state.curation,
);

export const getVariantCurationLoading: MemoizedSelector<VariantInterpretationState, boolean> = createSelector(
  getVariantCurationState,
  (state: VariantCurationState) => state.loading,
);

export const getVariantCurationLoaded: MemoizedSelector<VariantInterpretationState, boolean> = createSelector(
  getVariantCurationState,
  (state: VariantCurationState) => state.loaded,
);

export const getVariantCurationSection: (
  section: string,
) => MemoizedSelector<VariantInterpretationState, AcmgSectionResult> = (section: string) =>
  createSelector(getVariantCurationState, (state: VariantCurationState) => state.sections?.[section]?.curation);

export const getVariantCurationSectionLoading: (
  section: string,
) => MemoizedSelector<VariantInterpretationState, boolean> = (section: string) =>
  createSelector(getVariantCurationState, (state: VariantCurationState) => state.sections?.[section]?.loading);

export const getVariantCurationSectionLoaded: (
  section: string,
) => MemoizedSelector<VariantInterpretationState, boolean> = (section: string) =>
  createSelector(getVariantCurationState, (state: VariantCurationState) => state.sections?.[section]?.loaded);
