import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  AnalysisDetails,
  AnalysisReportReadyStatus,
  AnalysisType,
  CustomFieldsResponse,
  OrganizationConfig,
  OrganizationCustomSchema,
  Phenotype,
  RichTemplateResponse,
  SearchHistoryResponse,
  VariantMultiSearchResponse,
  VariantSearchResponse,
} from '../models';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CitationResponse } from '../models/citation.model';
import { CaseType, caseTypeToAnalysisType } from '../../modules/case-dialogs/models';
import { map } from 'rxjs/operators';

@Injectable()
export class AppService {
  constructor(private http: HttpClient) {}

  searchVariant(
    term: string,
    referenceGenome: string,
    searchContext: any,
    disableRoh = false,
  ): Observable<VariantSearchResponse> {
    const url = `${environment.beBaseURL}/api/parse_search`;
    const body = {
      search_text_input: term,
      case_context: searchContext,
      roh_allowed: !disableRoh,
      reference_version: referenceGenome,
    };
    const headers = { Accept: 'application/json' };

    return this.http.post<VariantSearchResponse>(url, body, { headers });
  }

  searchMultipleVariants(
    term: string,
    referenceGenome: string,
    searchContext: any,
    disableRoh = false,
  ): Observable<VariantMultiSearchResponse> {
    const url = `${environment.beBaseURL}/api/parse_variant_list`;
    const body = {
      search_text_input: term,
      case_context: searchContext,
      roh_allowed: !disableRoh,
      reference_version: referenceGenome,
    };
    const headers = { Accept: 'application/json' };

    return this.http.post<VariantMultiSearchResponse>(url, body, { headers });
  }

  searchHistory(term: string): Observable<SearchHistoryResponse> {
    const url = `${environment.beBaseURL}/api/latest_case?text=${encodeURIComponent(term)}`;
    const headers = { Accept: 'application/json' };

    return this.http.get<SearchHistoryResponse>(url, { headers });
  }

  getAnalysisDetails(analysisId: number): Observable<AnalysisDetails> {
    const url = `${environment.beBaseURL}/api/api/analysis/${analysisId}`;
    return this.http.get<AnalysisDetails>(url, {});
  }

  warmAnalysis(analysisId: number) {
    const url = `${environment.beBaseURL}/api/analysis/warmup`;
    const body = { analysis_id: analysisId };
    return this.http.post(url, body);
  }

  getAnalysisReadyForReportStatus(analysisId: number): Observable<AnalysisReportReadyStatus> {
    const url = `${environment.beBaseURL}/api/analysis/${analysisId}/report-ready-status`;
    // const url = `assets/json/analysis-report-ready-status.json`;
    return this.http.get<AnalysisReportReadyStatus>(url, {});
  }

  checkCancerTypeChangeAnnotation(analysisId: number): Observable<boolean> {
    const url = `${environment.beBaseURL}/api/somatic/analysis/cancer_type_change_requires_reannotation?analysis_id=${analysisId}`;
    return this.http.get<{ should_reannotate: boolean }>(url, {}).pipe(map((result) => result.should_reannotate));
  }

  updateAnalysisDetails(analysisId: number, body: any): Observable<AnalysisDetails> {
    const url = `${environment.beBaseURL}/api/api/analysis/${analysisId}`;

    return this.http.patch<AnalysisDetails>(url, body);
  }

  getUserVariantsCount(): Observable<{ count: number }> {
    return this.http.get<{ count: number }>(`${environment.beBaseURL}/api/user/interpretation_count`);
  }

  getCitations(): Observable<CitationResponse> {
    return this.http.get<CitationResponse>(`${environment.beBaseURL}/api/franklin_citations`);
  }

  getVariantsAutocomplete(input: string): Observable<Phenotype[]> {
    return this.http.get<Phenotype[]>(`${environment.beBaseURL}/api/api/analysis/search-options/?str=${input}`);
  }

  getCaseCustomFields(
    caseType: CaseType,
    assayId?: string,
    analysisType?: AnalysisType,
  ): Observable<CustomFieldsResponse> {
    const url = `${environment.beBaseURL}/api/analysis/custom_fields/configuration`;
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams().set('analysis_type', analysisType || caseTypeToAnalysisType(caseType));

    if (assayId) {
      params = params.append('assay_id', assayId);
    }

    return this.http.get<CustomFieldsResponse>(url, { headers, params });
  }

  getOrgCustomSchema(): Observable<OrganizationCustomSchema> {
    const url = `${environment.beBaseURL}/api/api/organization/custom-schema/`;
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get<OrganizationCustomSchema>(url, { headers });
  }

  getOrganizationConfig(): Observable<OrganizationConfig> {
    const url = `${environment.beBaseURL}/api/org/config`;
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get<OrganizationConfig>(url, { headers });
  }

  getRichTemplates(section: string): Observable<RichTemplateResponse> {
    const url = `${environment.beBaseURL}/api/organization/templates/get`;
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams().set('section', section);

    return this.http.get<RichTemplateResponse>(url, { headers, params });
  }
}
