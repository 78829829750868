import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';

@Injectable()
export class EditVariantService {
  constructor(private http: HttpClient) {}

  editVariantSubmit(analysisId: number, varId: string, startPos: number, endPos: number): Observable<any> {
    const body = {
      analysis_id: analysisId,
      edited_variant_id: varId,
      edit_info: {
        new_start_pos: startPos,
        new_end_pos: endPos,
      },
    };
    return this.http.post(`${environment.beBaseURL}/api/analysis/edit_sv_variant`, body);
  }
}
