import * as fromEditVariant from './edit-variant.reducer';
import { Action, combineReducers, createFeatureSelector, MemoizedSelector } from '@ngrx/store';

export interface EditVariantState {
  submission: fromEditVariant.EditVariantSubmitState;
}

export function reducers(state: EditVariantState, action: Action) {
  return combineReducers({
    submission: fromEditVariant.reducer,
  })(state, action);
}

export const getEditVariantState: MemoizedSelector<any, EditVariantState> =
  createFeatureSelector<EditVariantState>('editVariant');
