import { createSelector, MemoizedSelector } from '@ngrx/store';
import { getVariantPageState, VariantPageState } from '../reducers';
import { AppBannerState } from '../reducers/app-banner.reducer';
import { BannerType } from '../../models';

export const getAppBannerState: MemoizedSelector<VariantPageState, AppBannerState> = createSelector(
  getVariantPageState,
  (state) => state.banner,
);

export const getHiddenBanner: MemoizedSelector<VariantPageState, { [key in BannerType]: boolean }> = createSelector(
  getAppBannerState,
  (state) => state.hiddenBanner,
);
