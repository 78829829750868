import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import * as fromAction from '../actions/analysis-additional-information.action';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { WorkbenchService } from '../services/workbench.service';
import { OpenSnackbar } from '../../../../../../store';
import { SnackbarMode } from '../../../../../../store/models/snackbar.model';

@Injectable()
export class AnalysisAdditionalInformationEffects {
  constructor(
    private actions$: Actions,
    private service: WorkbenchService,
  ) {}

  loadAdditionalInformationTypes$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_ANALYSIS_ADDITIONAL_INFORMATION_TYPES),
      switchMap((action: fromAction.LoadAnalysisAdditionalInformationTypes) =>
        this.service.getAnalysisAdditionalInformationTypes(action.analysisId).pipe(
          map((result) => new fromAction.LoadAnalysisAdditionalInformationTypesSuccess(action.analysisId, result)),
          catchError((err) => of(new fromAction.LoadAnalysisAdditionalInformationTypesFail(err))),
        ),
      ),
    ),
  );

  loadAdditionalInformationGermlineBiomarkersAfterTypes$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_ANALYSIS_ADDITIONAL_INFORMATION_TYPES_SUCCESS),
      filter(
        (action: fromAction.LoadAnalysisAdditionalInformationTypesSuccess) => !!action.payload.supported_types?.length,
      ),
      map(
        (action: fromAction.LoadAnalysisAdditionalInformationTypesSuccess) =>
          new fromAction.LoadAnalysisGermlineBiomarkers(action.analysisId, action.payload.supported_types),
      ),
    ),
  );

  loadAdditionalInformationGermlineBiomarkers$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_ANALYSIS_GERMLINE_BIOMARKERS),
      switchMap((action: fromAction.LoadAnalysisGermlineBiomarkers) =>
        this.service.getAnalysisAdditionalInformationGermlineBiomarkers(action.analysisId, action.markerTypes).pipe(
          map((result) => new fromAction.LoadAnalysisInformationGermlineBiomarkersSuccess(result)),
          catchError((err) => of(new fromAction.LoadAnalysisInformationGermlineBiomarkersFail(err))),
        ),
      ),
    ),
  );

  changeGermlineBiomarkerInReport$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.CHANGE_GERMLINE_BIOMARKER_IN_REPORT),
      switchMap((action: fromAction.ChangeGermlineBiomarkerInReport) =>
        this.service.changeGermlineBiomarkerInReport(action.analysisId, action.biomarkerId, action.value).pipe(
          map((result) =>
            !!result.success
              ? new fromAction.ChangeGermlineBiomarkerInReportSuccess(
                  action.analysisId,
                  action.biomarkerId,
                  action.value,
                )
              : new fromAction.ChangeGermlineBiomarkerInReportFail(action.analysisId, action.biomarkerId, result),
          ),
          catchError((error) =>
            of(new fromAction.ChangeGermlineBiomarkerInReportFail(action.analysisId, action.biomarkerId, error)),
          ),
        ),
      ),
    ),
  );

  changeGermlineBiomarkerIrrelevant$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.CHANGE_GERMLINE_BIOMARKER_NOT_RELEVANT),
      switchMap((action: fromAction.ChangeGermlineBiomarkerNotRelevant) =>
        this.service.changeGermlineBiomarkerIrrelevant(action.analysisId, action.biomarkerId, action.irrelevantData).pipe(
          map((result) =>
            !!result.success
              ? new fromAction.ChangeGermlineBiomarkerNotRelevantSuccess(
                  action.analysisId,
                  action.biomarkerId,
                  action.irrelevantData,
                )
              : new fromAction.ChangeGermlineBiomarkerNotRelevantFail(action.analysisId, action.biomarkerId, result),
          ),
          catchError((error) =>
            of(new fromAction.ChangeGermlineBiomarkerNotRelevantFail(action.analysisId, action.biomarkerId, error)),
          ),
        ),
      ),
    ),
  );

  changeGermlineBiomarkerIrrelevantFail$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.CHANGE_GERMLINE_BIOMARKER_NOT_RELEVANT_FAIL),
      switchMap(() => of(new OpenSnackbar('Error! Marking biomarker as irrelevant failed', SnackbarMode.Error))),
    ),
  );

  changeBiomarkerInReportFail$: Observable<any> = createEffect(() =>
  this.actions$.pipe(
    ofType(fromAction.CHANGE_GERMLINE_BIOMARKER_IN_REPORT_FAIL),
    switchMap(() => of(new OpenSnackbar('Error! Setting biomarker in report failed', SnackbarMode.Error))),
  ))
}
