import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  ValidationErrors,
  Validators,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subject, timer } from 'rxjs';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AnalysisService } from '../../../../../../shared/services/analysis.service';
import { VariantPageState } from '../../store/reducers';
import { SaveVariantCase, SaveVariantCaseSuccess } from '../../store/actions';
import { getSearchCaseSaved, getSearchCaseSaving } from '../../store/selectors';
import { AuthModuleState } from '../../../../../auth0/store/reducers';
import { getIsLoggedIn } from '../../../../../auth0/store/selectors';
import { OpenRegistrationPopup } from '../../../../store/actions';
import { DotsLoaderComponent } from '../../../../../../shared/components/dots-loader/dots-loader.component';
import { CheckboxComponent } from '../../../../../../shared/components/checkbox/checkbox.component';
import { PrivacyLabelComponent } from '../../../../../../shared/components/privacy-label/privacy-label.component';
import { ClickCursorDirective } from '../../../../../../shared/directives/click-cursor.directive';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';

export interface SaveCaseDialogData {
  manual: boolean;
  variantName: string;
  alreadySaved: boolean;
}

@Component({
  selector: 'gnx-save-case-dialog',
  templateUrl: './save-case-dialog.component.html',
  styleUrls: ['./save-case-dialog.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    ClickCursorDirective,
    MatDialogContent,
    ReactiveFormsModule,
    FormsModule,
    NgClass,
    PrivacyLabelComponent,
    MatDialogActions,
    CheckboxComponent,
    DotsLoaderComponent,
    AsyncPipe,
  ],
})
export class SaveCaseDialogComponent implements OnInit, OnDestroy {
  caseSaving$: Observable<boolean>;
  destroy$: Subject<void> = new Subject<void>();

  checking: boolean;
  nameFormControl: FormControl;
  saveError: string;

  constructor(
    public dialogRef: MatDialogRef<SaveCaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SaveCaseDialogData,
    private fb: FormBuilder,
    private analysisService: AnalysisService,
    private store$: Store<VariantPageState>,
    private authStore$: Store<AuthModuleState>,
  ) {}

  ngOnInit(): void {
    this.caseSaving$ = this.store$.pipe(select(getSearchCaseSaving));
    this.nameFormControl = this.fb.control(
      this.data.variantName,
      Validators.required,
      this.caseNameValidator.bind(this),
    );
    this.nameFormControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((name) => {});
    this.dialogRef
      .backdropClick()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.onClose(true);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onSave() {
    const isLoggedIn$: Observable<boolean> = this.authStore$.pipe(select(getIsLoggedIn));
    isLoggedIn$.pipe(take(1)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.doSave();
      } else {
        this.store$.dispatch(new OpenRegistrationPopup());
      }
    });
  }

  onClose(cancelRouting = false) {
    this.dialogRef.close(cancelRouting);
    if (!this.data.manual && !cancelRouting) {
      this.store$.dispatch(new SaveVariantCaseSuccess());
    }
  }

  onCheckedChange(status: boolean) {
    if (status) {
      localStorage.setItem('noAutoSaveDialog', 'true');
    } else {
      localStorage.removeItem('noAutoSaveDialog');
    }
  }

  doSave() {
    this.saveError = '';
    this.store$.dispatch(new SaveVariantCase(this.nameFormControl.value));
    combineLatest([this.store$.pipe(select(getSearchCaseSaved)), this.store$.pipe(select(getSearchCaseSaving))])
      .pipe(
        filter(([, saving]: [boolean, boolean]) => !saving),
        take(1),
      )
      .subscribe(([saved]: [boolean, boolean]) => {
        if (saved) {
          this.onClose(true);
        } else {
          this.saveError = 'Save failed. Please try again. If this persists, contact us.';
        }
      });
  }

  caseNameValidator(ctrl: AbstractControl): Observable<ValidationErrors | null> {
    this.checking = true;
    return timer(500).pipe(
      switchMap(() =>
        this.analysisService.checkCaseNameAvailability(ctrl.value).pipe(
          map((available) => (!!available ? null : { notAvailable: true })),
          catchError(() => of(null)),
        ),
      ),
      tap((result) => {
        this.checking = false;
        if (result !== null) {
          ctrl.markAllAsTouched();
        }
      }),
    );
  }
}
