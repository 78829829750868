import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as fromAction from './../actions/ethnicities.action';
import { EthnicitiesService } from '../services';

@Injectable()
export class EthnicitiesEffect {
  constructor(
    private actions$: Actions,
    private service: EthnicitiesService,
  ) {}

  getEthnicityOptions$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GET_ETHNICITY_OPTIONS),
      switchMap((action) =>
        this.service.getEthnicityOptions().pipe(
          map((result) => new fromAction.GetEthnicityOptionsSuccess(result)),
          catchError((err) => of(new fromAction.GetEthnicityOptionsFail(err))),
        ),
      ),
    ),
  );
}
