import { createSelector, MemoizedSelector } from '@ngrx/store';
import { getVariantInterpretationState, VariantInterpretationState } from '../reducers';
import * as fromReducerSelectors from '../reducers/interpretation-history.reducer';
import { InterpretationHistoryState } from '../reducers/interpretation-history.reducer';
import { VariantInterpretation } from '../../models';

const getInterpretationHistoryState: MemoizedSelector<VariantInterpretationState, InterpretationHistoryState> =
  createSelector(getVariantInterpretationState, (state: VariantInterpretationState) => state.history);

export const getInterpretationHistoryData: MemoizedSelector<VariantInterpretationState, VariantInterpretation[]> =
  createSelector(getInterpretationHistoryState, fromReducerSelectors.getInterpretationHistoryEntities);

export const getInterpretationHistoryWithFlags: MemoizedSelector<VariantInterpretationState, VariantInterpretation[]> =
  createSelector(getInterpretationHistoryData, (data: VariantInterpretation[]) => data.filter((item) => item.flag));

export const getInterpretationHistoryLoaded: MemoizedSelector<VariantInterpretationState, boolean> = createSelector(
  getInterpretationHistoryState,
  fromReducerSelectors.getInterpretationHisotyLoadedEntity,
);

export const getInterpretationHistoryLoading: MemoizedSelector<VariantInterpretationState, boolean> = createSelector(
  getInterpretationHistoryState,
  fromReducerSelectors.getInterpretationHisotyLoadingEntity,
);
