import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromAction from '../actions/organization.action';
import { GetTeamMembers } from '../actions/organization.action';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthApiService } from '../../services/auth-api.service';
import { LoadUserDetails } from '../actions';
import {
  WarningDialogComponent,
  WarningDialogData,
} from '../../../../components/warning-dialog/warning-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class OrganizationEffects {
  constructor(
    private actions$: Actions,
    private authApiService: AuthApiService,
    private dialog: MatDialog,
  ) {}

  updateOrgInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.UPDATE_ORGANIZATION_INFO),
      switchMap((action: fromAction.UpdateOrgInfo) =>
        this.authApiService.updateOrganizationInfo(action.name).pipe(
          mergeMap((res) => [new fromAction.UpdateOrgInfoSuccess(), new LoadUserDetails()]),
          catchError((err) => of(new fromAction.UpdateOrgInfoFail(err))),
        ),
      ),
    ),
  );

  getTeamMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GET_TEAM_MEMBERS),
      switchMap((action: GetTeamMembers) =>
        (action.extended ? this.authApiService.getTeamMembersExtended() : this.authApiService.getTeamMembers()).pipe(
          map((res) => new fromAction.GetTeamMembersSuccess(res)),
          catchError((err) => of(new fromAction.GetTeamMembersFail(err))),
        ),
      ),
    ),
  );

  setMemberRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.SET_MEMBER_ROLES),
      switchMap((action: fromAction.SetMemberRoles) =>
        this.authApiService.setOrgMemberRole(action.userId, action.email, action.isStatusPending, action.roles).pipe(
          map((res) => new fromAction.SetMemberRolesSuccess(action.email, action.roles)),
          catchError((err) => of(new fromAction.SetMemberRolesFail(err, action.email))),
        ),
      ),
    ),
  );

  deleteMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.DELETE_MEMBER),
      switchMap((action: fromAction.DeleteMember) => {
        const confirmRemoveDialog = this.dialog.open(WarningDialogComponent, {
          width: '570px',
          height: '264px',
          backdropClass: 'popup-backdrop',
          panelClass: 'popup-panel',
          hasBackdrop: true,
          data: {
            title: 'Remove User?',
            description: `Are you sure you want to remove ${action.userName} from Franklin? `,
            yesButtonLabel: 'Yes, remove',
            cancelButtonLabel: 'No, Cancel',
            hideTopSection: true,
            reverseButtonsOrder: true,
          } as WarningDialogData,
        });
        return confirmRemoveDialog.afterClosed().pipe(
          switchMap((answer) =>
            answer
              ? this.authApiService.deleteOrgMember(action.userId, action.email, action.isPending).pipe(
                  map((res) => new fromAction.DeleteMemberSuccess(action.email)),
                  catchError((err) => of(new fromAction.DeleteMemberFail(err, action.email))),
                )
              : of(new fromAction.DeleteMemberCancel(action.email)),
          ),
        );
      }),
    ),
  );
}
