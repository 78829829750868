import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AuthModuleState, getAuthModuleState } from '../reducers';
import * as fromReducer from '../reducers/organization.reducer';
import { TeamMember } from '../../models';

const getOrganizationState: MemoizedSelector<AuthModuleState, fromReducer.OrganizationState> = createSelector(
  getAuthModuleState,
  (state: AuthModuleState) => state.organization,
);

export const getUpdateOrgInProgress: MemoizedSelector<AuthModuleState, boolean> = createSelector(
  getOrganizationState,
  fromReducer.getUpdateOrgInProgress,
);

export const getTeamMembers: MemoizedSelector<
  AuthModuleState,
  {
    data: TeamMember[];
    loaded: boolean;
    loading: boolean;
  }
> = createSelector(getOrganizationState, fromReducer.getTeamMembers);

export const getTeamMembersLoading: MemoizedSelector<AuthModuleState, boolean> = createSelector(
  getOrganizationState,
  fromReducer.getTeamMembersLoading,
);

export const getTeamMembersObject: MemoizedSelector<AuthModuleState, { [key: number]: TeamMember }> = createSelector(
  getTeamMembers,
  (users) =>
    users?.data?.reduce(
      (result, user) => ({
        ...result,
        [user.email]: user,
      }),
      {},
    ),
);
