import { createSelector, MemoizedSelector, MemoizedSelectorWithProps } from '@ngrx/store';
import * as fromReducer from '../reducers/gene-panels.reducer';
import { GenePanel } from '../models';
import { AppModuleState, getAppModuleState } from '../reducers';
import { PanelData } from '../../knowledge-base/modules/panels/store';

const getGenePanelsState: MemoizedSelector<AppModuleState, fromReducer.GenePanelsState> = createSelector(
  getAppModuleState,
  (state: AppModuleState) => state.genePanels,
);

export const getGenePanelsResponse: MemoizedSelectorWithProps<AppModuleState, { gene: string }, GenePanel[]> =
  createSelector(getGenePanelsState, fromReducer.getGenePanelsResponse);

export const getGeneKbPanelsResponse: MemoizedSelectorWithProps<AppModuleState, { gene: string }, PanelData[]> =
  createSelector(getGenePanelsState, fromReducer.getGeneKbPanelsResponse);

export const getGenePanelsLoading: MemoizedSelectorWithProps<AppModuleState, { gene: string }, boolean> =
  createSelector(getGenePanelsState, fromReducer.getGenePanelsLoading);

export const getGenePanelsLoaded: MemoizedSelectorWithProps<AppModuleState, { gene: string }, boolean> = createSelector(
  getGenePanelsState,
  fromReducer.getGenePanelsLoaded,
);
