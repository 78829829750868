import { AffectionStatus } from '../../modules/variant-page/modules/variant-page/models';

export const SEX_OPTIONS = [
  { name: 'Male', value: 'MALE', icon: 'assets/svg/male-copy.svg' },
  { name: 'Female', value: 'FEMALE', icon: 'assets/svg/female-copy.svg' },
  { name: 'Unknown', value: 'UNKNOWN', icon: 'assets/svg/unknown-gender.svg' },
];
export const ZYGOSITY_OPTIONS = [
  { name: 'Homozygote', value: 'HOM' },
  { name: 'Heterozygote', value: 'HET' },
  { name: 'Hemizygote', value: 'HEM' },
  { name: 'Unknown', value: 'UNKNOWN' },
];
export const MALE_X_ZYGOSITY_OPTIONS = [
  { name: 'Hemizygote', value: 'HEM' },
  { name: 'Somatic', value: 'TUMOR' },
  { name: 'Unknown', value: 'UNKNOWN' },
];
export const INHERITANCE_OPTIONS = [
  { name: 'Father', value: 'FATHER' },
  { name: 'Mother', value: 'MOTHER' },
  { name: 'Both Parents', value: 'BOTH_PARENTS' },
  { name: 'Denovo', value: 'DE_NOVO' },
  { name: 'Unknown', value: 'UNKNOWN' },
];
export const CLASSIFICATION_OPTIONS = [
  { name: 'Pathogenic', value: 'PATHOGENIC' },
  { name: 'Likely Pathogenic', value: 'SUSPECTED_PATHOGENIC' },
  { name: 'VUS', value: 'VUS' },
  { name: 'Likely Benign', value: 'SUSPECTED_BENIGN' },
  { name: 'Benign', value: 'BENIGN' },
  { name: 'Unknown', value: 'NONE' },
];
export const CONSANGUINEOUS_VALUE = 'CONSANGUINEOUS';
export const CONSANGUINITY_OPTIONS = [
  { name: 'Yes', value: CONSANGUINEOUS_VALUE },
  { name: 'No', value: 'NON_CONSANGUINEOUS' },
  { name: 'Unknown', value: 'UNKNOWN' },
];

export const CONSANGUINITY_RELATION_OPTIONS = [
  { name: 'First degree (Siblings)', value: 'FIRST_DEGREE' },
  { name: 'Second degree', value: 'SECOND_DEGREE' },
  { name: 'Third-degree', value: 'THIRD_DEGREE' },
  { name: 'Other', value: 'UNKNOWN' },
];

export const AFFECTED_OPTIONS = [
  { name: 'No', value: AffectionStatus.UNAFFECTED, otherName: 'Unaffected' },
  { name: 'Possibly', value: AffectionStatus.UNKNOWN, otherName: 'Possibly Affected' },
  { name: 'Yes', value: AffectionStatus.AFFECTED, otherName: 'Affected' },
];

export const MSI_OPTIONS = [
  { name: 'High', value: 'HIGH' },
  { name: 'Stable', value: 'STABLE' },
];

export const TMB_OPTIONS = [
  { name: 'High', value: 'HIGH' },
  { name: 'Intermediate', value: 'INTERMEDIATE' },
  { name: 'Low', value: 'LOW' },
];

export const SEXES = SEX_OPTIONS.reduce(
  (acc, crt) => ({
    ...acc,
    [crt.value]: crt.name,
  }),
  {},
);
export const ZYGOSITIES = ZYGOSITY_OPTIONS.reduce(
  (acc, crt) => ({
    ...acc,
    [crt.value]: crt.name,
  }),
  {},
);
export const MALE_X_ZYGOSITIES = MALE_X_ZYGOSITY_OPTIONS.reduce(
  (acc, crt) => ({
    ...acc,
    [crt.value]: crt.name,
  }),
  {},
);
export const INHERITANCES = INHERITANCE_OPTIONS.reduce(
  (acc, crt) => ({
    ...acc,
    [crt.value]: crt.name,
  }),
  {},
);
export const CLASSIFICATIONS = CLASSIFICATION_OPTIONS.reduce(
  (acc, crt) => ({
    ...acc,
    [crt.value]: crt.name,
  }),
  {},
);

export const CONSANGUINITY = CONSANGUINITY_OPTIONS.reduce(
  (acc, crt) => ({
    ...acc,
    [crt.value]: crt.name,
  }),
  {},
);
