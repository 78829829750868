<div class="close-btn" (click)="onClose()">
  <img src="/assets/svg/esc-blk.svg" />
</div>
<div class="left-side">
  <div class="logo">
    <img src="assets/svg/franklin_logo.svg" width="104" height="33" />
  </div>
  <div class="free-sticker" [ngClass]="STYLES[data.style].customClass">FREE</div>
  <div class="header" [ngClass]="STYLES[data.style].customClass">
    <strong> {{ STYLES[data.style].title }} </strong><br />
    {{ STYLES[data.style].subtitle }}
  </div>
  <div class="hat-image" [ngClass]="STYLES[data.style].customClass">
    <img
      *ngIf="!STYLES[data.style].image"
      src="/assets/png/panda-icon.png"
      srcset="assets/png/panda-icon@2x.png, assets/png/panda-icon@3x.png"
      class="panda"
    />
    <img *ngIf="STYLES[data.style].image" [src]="STYLES[data.style].image" />
  </div>
  <div *ngIf="STYLES[data.style].features" class="bottom-section">
    <div class="bottom-header">
      {{ STYLES[data.style].features }}
    </div>
    <div class="bottom-list">
      <div>
        <ul>
          <li>
            <span>
              {{ STYLES[data.style].feature1 }}
            </span>
          </li>
          <li>
            <span>
              {{ STYLES[data.style].feature2 }}
            </span>
          </li>
          <li>
            <span>
              {{ STYLES[data.style].feature3 }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="right-side">
  <gnx-join-form [step]="step$ | async" [inPopup]="true" (signUpComplete)="onClose()"></gnx-join-form>
  <div *ngIf="(step$ | async) === 0" class="already-joined">
    Already joined?
    <a routerLink="/login" (click)="onLogin()">Log in</a>
  </div>
  <div *ngIf="(step$ | async) === 0" class="bottom-section">
    <div class="certificates">
      <div class="title">
        <hr class="left" />
        <span>YOUR DATA IS SECURED</span>
        <hr class="right" />
      </div>
      <div class="cert-images">
        <img src="/assets/png/hipaa.png" srcset="assets/png/hipaa@2x.png, assets/png/hipaa@3x.png" class="hipaa" />
        <img src="/assets/png/iso.png" srcset="assets/png/iso@2x.png, assets/png/iso@3x.png" class="iso" />
        <img src="/assets/png/gdpr.png" srcset="assets/png/gdpr@2x.png, assets/png/gdpr@3x.png" class="gdpr" />
      </div>
    </div>
    <div class="disclaimer">Genoox is committed to the highest standards of data security and privacy</div>
  </div>
</div>
