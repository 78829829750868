import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { Router } from '@angular/router';

import { select, Store } from '@ngrx/store';

import { getAnalysisToolUrl } from '../../utils/url.utils';
import { CREATE_CASE_SUCCESS } from '../../../case-dialogs/store/actions';
import { LOAD_ANALYSES, LoadAnalyses } from '../../modules/analyses/store/actions';
import {
  MENU_NAVIGATE,
  MENU_NAVIGATE_SUCCESS,
  MenuNavigate,
  MenuNavigateFail,
  MenuNavigateSuccess,
} from '../../modules/shared/store/actions';
import { AppModuleState } from '../../../../store/reducers';
import { getAnalysisDetails } from '../../../../store/selectors';
import { AnalysisDetails } from '../../../../store/models';
import { environment } from '../../../../../environments/environment';
import { AuthModuleState } from '../../../auth0/store/reducers';
import { getUserOrganization } from '../../../auth0/store/selectors';
import { Organization } from '../../../../knowledge-base/modules/variants/models';
import {
  WarningDialogComponent,
  WarningDialogData,
} from '../../../../components/warning-dialog/warning-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AppService, OpenSaveFileLinkWindow } from '../../../../store';

const DemoReports: { analysisName: string; reportPath: string }[] = [
  {
    analysisName: 'TSHC_rep1',
    reportPath: `${environment.logoutCallbackUrl}assets/pdf/Hereditary cancer - PALB2.pdf`,
  },
  {
    analysisName: 'TSO500 CRC demo Somatic Analysis',
    reportPath: `${environment.logoutCallbackUrl}assets/pdf/report solid - pdf format.pdf`,
  },
  {
    analysisName: 'Virtual TSHC Sample Analysis',
    reportPath: `${environment.logoutCallbackUrl}assets/pdf/report Hereditary cancer.pdf`,
  },
];

@Injectable()
export class AnalysisEffect {
  private defaultAnalysesFilters: {
    assignedTo: string[];
    ordering: string;
    search: string;
    status: string[];
    assay: string[];
    customLabel: string[];
    activeStep: string[];
  };

  constructor(
    private actions$: Actions,
    private router: Router,
    private rootStore$: Store<AppModuleState>,
    private authStore$: Store<AuthModuleState>,
    private dialog: MatDialog,
    private appService: AppService,
  ) {}

  menuNavigate$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(MENU_NAVIGATE),
      withLatestFrom(
        this.rootStore$.pipe(select(getAnalysisDetails)),
        this.authStore$.pipe(select(getUserOrganization)),
      ),
      filter(
        ([action, analysis, org]: [MenuNavigate, AnalysisDetails, Organization]) =>
          action.payload !== 'viewer' || !this.isDemoReport(analysis, org),
      ),
      switchMap(([action, analysis, org]: [MenuNavigate, AnalysisDetails, Organization]) => {
        if (
          action.payload === 'report-v2' &&
          analysis.assay.report_metadata_config?.allow_open_report_with_unassessed_variants === false
        ) {
          return this.appService.getAnalysisReadyForReportStatus(analysis.id).pipe(
            switchMap((results) => {
              const isPlural = results.non_assessed_variants_count !== 1;
              if (!results?.ready) {
                return this.dialog
                  .open(WarningDialogComponent, {
                    width: '570px',
                    height: '350px',
                    backdropClass: 'popup-backdrop',
                    panelClass: 'popup-panel',
                    hasBackdrop: true,
                    disableClose: true,
                    data: {
                      title: 'Missing Assessment',
                      description: `There ${isPlural ? 'are' : 'is'} still ${results.non_assessed_variants_count} variant${isPlural ? 's' : ''} in the workbench waiting for your assessment`,
                      yesButtonLabel: 'Back to workbench',
                      hideCancelBtn: true,
                    } as WarningDialogData,
                  })
                  .afterClosed()
                  .pipe(map(() => new MenuNavigateSuccess(action.payload, 'workbench')));
              } else {
                return [new MenuNavigateSuccess(action.payload)];
              }
            }),
            catchError((err) => of(new MenuNavigateFail(action.payload, err))),
          );
        } else {
          return [new MenuNavigateSuccess(action.payload)];
        }
      }),
    ),
  );

  menuNavigateSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MENU_NAVIGATE_SUCCESS),
        withLatestFrom(this.rootStore$.pipe(select(getAnalysisDetails))),
        tap(([action, analysis]: [MenuNavigateSuccess, AnalysisDetails]) => {
          const noop = this.router.navigate([getAnalysisToolUrl(action.redirectUrl || action.payload, analysis)]);
        }),
      ),
    { dispatch: false },
  );

  menuNavigateDemoReport$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(MENU_NAVIGATE),
      withLatestFrom(
        this.rootStore$.pipe(select(getAnalysisDetails)),
        this.authStore$.pipe(select(getUserOrganization)),
      ),
      filter(
        ([action, analysis, org]: [MenuNavigate, AnalysisDetails, Organization]) =>
          action.payload === 'viewer' && this.isDemoReport(analysis, org),
      ),
      switchMap(([action, analysis, org]: [MenuNavigate, AnalysisDetails, Organization]) => [
        new OpenSaveFileLinkWindow(
          DemoReports.find((x) => x.analysisName === analysis.name)?.reportPath,
          `${analysis.name}.pdf`,
        ),
      ]),
    ),
  );

  loadAnalyses$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOAD_ANALYSES),
        take(1),
        tap(
          (action: LoadAnalyses) =>
            (this.defaultAnalysesFilters = {
              assignedTo: action.assignedTo,
              status: action.status,
              assay: action.assay,
              customLabel: action.customLabel,
              ordering: action.ordering,
              search: action.search,
              activeStep: action.activeStep,
            }),
        ),
      ),
    { dispatch: false },
  );

  reloadAnalysesAfterCreateCase$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(CREATE_CASE_SUCCESS),
      switchMap(() => [
        new LoadAnalyses(
          this.defaultAnalysesFilters.ordering,
          this.defaultAnalysesFilters.search,
          this.defaultAnalysesFilters.assignedTo,
          this.defaultAnalysesFilters.status,
          this.defaultAnalysesFilters.assay,
          this.defaultAnalysesFilters.activeStep,
          this.defaultAnalysesFilters.customLabel,
        ),
      ]),
    ),
  );

  isDemoReport = (analysis: AnalysisDetails, org: Organization) =>
    DemoReports.some((x) => x.analysisName === analysis.name) &&
    ['ulo_prod', 'test', 'demo', 'Demo'].includes(org.name);
}
