import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import * as fromAppStore from '../../../store';
import { getAnalysisResultSuggestions, SearchAnalyses } from '../../../store';
import { select, Store } from '@ngrx/store';
import { delay, Observable, Subject } from 'rxjs';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { Analysis } from '../../../modules/analysis/modules/analyses/store';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { HighlightPipe } from '../../pipes/highlight.pipe';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { I18nPipe } from '../../i18n/i18n.pipe';
import { MatOption } from '@angular/material/core';
import { DotsLoaderComponent } from '../dots-loader/dots-loader.component';
import { ClickCursorDirective } from '../../directives/click-cursor.directive';
import { RtlDirective } from '../../i18n/rtl.directive';
import { AsyncPipe, DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { MatAutocomplete, MatAutocompleteOrigin, MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'gnx-analysis-search-bar',
  templateUrl: './analysis-search-bar.component.html',
  styleUrls: ['./analysis-search-bar.component.scss'],
  standalone: true,
  imports: [
    MatAutocompleteOrigin,
    NgIf,
    RtlDirective,
    NgClass,
    ClickCursorDirective,
    DotsLoaderComponent,
    MatAutocompleteTrigger,
    ReactiveFormsModule,
    MatAutocomplete,
    NgFor,
    MatOption,
    AsyncPipe,
    DatePipe,
    I18nPipe,
    SafeHtmlPipe,
    HighlightPipe,
  ],
})
export class AnalysisSearchBarComponent implements OnInit, AfterViewInit, OnDestroy {
  public searching$: Observable<boolean>;
  public searchDone$: Observable<boolean>;
  public searchError$: Observable<string>;

  destroy$: Subject<void> = new Subject<void>();
  searchControl: FormControl;
  analyses$: Observable<Analysis[]>;
  private enterDisabled: boolean;

  constructor(
    private store$: Store<fromAppStore.AppModuleState>,
    private fb: FormBuilder,
    private router: Router,
  ) {}

  ngOnInit() {
    this.searchControl = this.fb.control('');
    this.searchControl.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500)).subscribe((value) => {
      this.store$.dispatch(new SearchAnalyses(value));
    });

    this.analyses$ = this.store$.pipe(select(getAnalysisResultSuggestions));
    this.searching$ = this.store$.pipe(select(fromAppStore.getSearching));
    this.searchDone$ = this.store$.pipe(select(fromAppStore.getSearchDone));
    this.searchError$ = this.store$.pipe(select(fromAppStore.getSearchError));

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        takeUntil(this.destroy$),
        delay(0),
      )
      .subscribe(() => {
        this.enterDisabled = false;
      });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.destroy$.next();
  }

  onSelect(analysisId: number) {
    this.enterDisabled = true;
    this.router.navigate([`/analysis-tool/analysis/workbench/${analysisId}`]);
  }

  onSearch() {
    if (this.enterDisabled) {
      return;
    }
    if (this.searchControl.value) {
      this.router.navigate(['/analysis-tool/analyses'], { queryParams: { search: this.searchControl.value } });
    } else {
      this.onEscape();
    }
  }

  onEscape() {
    this.searchControl.setValue('', { emitEvent: false });
  }
}
