import { PopupEffect } from './popup.effect';
import { RaffleVariantEffect } from './raffle.effect';
import { FollowVariantEffect } from './follow-variant.effect';
import { VariantPageConfigEffect } from './variant-page-config.effect';
import { VariantPageDataEffect } from './variant-page-data.effect';

export const variantPageEffects = [
  PopupEffect,
  RaffleVariantEffect,
  FollowVariantEffect,
  VariantPageConfigEffect,
  VariantPageDataEffect,
];
