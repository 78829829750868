import {
  AnalysisStatus,
  AnalysisWorkflowStatus,
  EnrichmentKit,
  QcConfig,
} from '../../modules/analysis/modules/analyses/store/models';
import { AnalysisType } from './analysis-type.model';
import { SVConfidenceGraphType } from '../../modules/variant-page/modules/assessment-tools/store/models';
import { BatchQcGenerationStrategy, TumorProfilingStrategy } from './batch_analysis.model';
import { ReportExportType } from '../../modules/analysis/modules/reporting/store';
import { Sample } from '../../modules/samples/store/models/results.model';
import { AffectionStatus } from '../../modules/variant-page/modules/variant-page/models';

export interface AnalysisSampleDetails {
  status: number;
  has_coverage: boolean;
  sequence_type: string;
  name: string;
  annotation_version: number;
  sv_annotation_version?: number;
  rna_annotation_version?: number;
  loh_annotation_version?: number;
  manual_variants_annotation_version?: number;
  subject: {
    internal_id: string;
    dob: string;
    sex: string;
    ethnicities: string[];
  };
  id: number;
  internal_id: string;
  s3_id: string;
  specimen_type: string;
  collected_at: string;
  received_at: string;
  ordered_at: string;
  partner_ethnicity: string;
  partner_name: string;
  bar_code: number;
  tissue_type: string;
  process_status: {
    coverage_pe: number;
  };
  enrichment_kit?: number;
  reference_genome_version: 'HG19' | 'HG38';
  batch?: {
    created_at: string;
    name: string;
  };
  bam_url?: string;
  bam_filename?: string;
  single_analysis_id?: number;
}

export interface FamilyPedTree {
  [key: string]: {
    associatedSample?: {
      sample: {
        isNew: boolean;
        sex: string;
        sample_id: number;
        id?: number;
        name: string;
        createdAt: string;
        subject: string;
        is_virtual?: boolean;
      };
      isAffected: boolean;
      sex: string;
    };
    type: string;
    fatherNodeId: string;
    motherNodeId: string;
  };
}

export interface AnalysisPhenotypeGroup {
  name: string;
  phenotypes: string[];
}

export interface DetailedStatus {
  status: AnalysisStatus;
  progress?: number;
  sample_files_uploading?: boolean;
}

export interface Subject {
  ethnicities: any[];
  dob: string;
  internal_id: string;
  sex: string;
}

export interface SampleConfig {
  is_control: boolean;
  file_format: string;
}

export interface PanelsConfig {
  filter_panels: boolean;
}

export interface Panel {
  uuid: string;
  name: string;
}

export interface AnalysisConfigurableOptions {
  panels: Panel[];
  somatic?: {
    diseases?: string[];
    met_exon_14_skipping_config?: {
      display?: boolean;
    };
    use_germline_interpretations_for_report?: boolean;
    use_rna_expression_controls?: boolean;
    tmb_config?: {
      display: boolean;
    };
    msi_config?: {
      display: boolean;
    };
    hrd_config?: {
      display: boolean;
    };
  };
}

export enum AssayInputSource {
  UNKNOWN_SOURCE = 'UNKNOWN_SOURCE',
  SEARCH = 'SEARCH',
  VCF = 'VCF',
  FASTQ = 'FASTQ',
  CSV = 'CSV',
  AED = 'AED',
}

export enum ConfidenceViewerType {
  UNKNOWN_CONFIDENCE_TYPE = 'UNKNOWN_CONFIDENCE_TYPE',
  EXOME = 'EXOME',
  WGS = 'WGS',
}

export interface ConfidenceConfig {
  supported_cnv_confidence_graphs?: SVConfidenceGraphType[];
  supported_whole_genome_graphs?: SVConfidenceGraphType[];
  use_feature_category?: boolean;
  confidence_viewer_type: ConfidenceViewerType;
}

export interface CreationData {
  createdAt: string;
  createdBy: User;
}

export interface User {
  name: string;
}

export type AssayAnalysisType = 'SOMATIC' | 'GERMLINE' | 'ECS' | 'CMA' | 'SOMA' | 'PGX';

export interface UserAnnotationConfig {
  annotation_id: string;
  annotation_index: number;
  annotation_name: string;
  annotation_source: string;
  annotation_type: string;
  can_filter_by: boolean;
  name: string;
  uuid: string;
}

export interface Assay {
  creation_data?: CreationData;
  num_of_samples?: number;
  analysis_configurable_options?: AnalysisConfigurableOptions;
  description?: string;
  report_configuration_uuid?: any;
  sample_config?: SampleConfig;
  name: string;
  input_source?: AssayInputSource;
  is_control?: boolean;
  custom_annotation_objects?: any[];
  analysis_type?: AssayAnalysisType;
  panels_config?: PanelsConfig;
  panels?: {
    name: string;
    num_genes: number;
    uuid: string;
  }[];
  qc_config?: QcConfig;
  uuid?: string;
  enrichment_kit?: EnrichmentKit;
  is_default?: boolean;
  confidence_config?: ConfidenceConfig;
  public?: boolean;
  duo_analysis_config?: {
    enabled?: boolean;
  };
  pipeline_config?: {
    variant_callers?: string[];
  };
  batch_analysis_config?: {
    qc_generation_strategy?: BatchQcGenerationStrategy;
    qc_profiling_config?: {
      tumor_profiling_strategy?: TumorProfilingStrategy;
    };
  };
  report_metadata_config?: {
    supported_export_formats: ReportExportType[];
    allow_open_report_with_unassessed_variants: boolean;
  };
  user_annotations_config?: {
    first_annotation?: UserAnnotationConfig;
    second_annotation?: UserAnnotationConfig;
    third_annotation?: UserAnnotationConfig;
    fourth_annotation?: UserAnnotationConfig;
    fifth_annotation?: UserAnnotationConfig;
  };
  workbench_config?: {
    show_prs?: boolean;
  };
  interpretation_config?: {
    short?: {
      hide_in_analysis?: boolean;
    };
  };
  filter_groups: {
    id: number;
    name: string;
    description: string;
    assay_ids: string[];
    created_at: string;
  }[];
}

export interface ReportConfiguration {
  finding_sections: any[];
  description: string;
  type: string;
  name: string;
  uuid: string;
}

export interface AnnotationVersions {
  snp_annotation_version?: number;
  cnv_annotation_version?: number;
  coverage_stats_version?: number;
  loh_annotation_version?: number;
  manual_variants_annotation_version?: number;
  repeats_annotation_version?: number;
  rna_annotation_version?: number;
}

export interface FamilyMemberRelation {
  affection_status?: AffectionStatus;
  analysis_name: string;
  created_at: string;
  ethnicities: string[];
  include_secondary_findings: boolean;
  phenotypes: string[];
  relation: string;
  samples: {
    id: number;
    s3_id: string;
    batch_id?: number;
  }[];
  sex: string;
  single_analysis_id: number;
  sample_or_analysis_id: string;
  subject_id: number;
  versions: AnnotationVersions;
}

export interface AnalysisDetailsFiles {
  analysis_name: string;
  analysis_id: number;
  samples: {
    sample_name: string;
    sample_id: number;
    files: {
      id: string;
      name: string;
      type: 'bam' | 'bai' | 'sample-file' | 'sequence-sample-file';
    }[];
  }[];
}

export interface AnalysisDetails {
  id: number;
  name: string;
  details?: any;
  analysis_type: AnalysisType;
  type: number;
  num_samples: number;
  created_at: Date;
  last_visited: Date;
  status: number;
  status_description: string;
  parent_status: AnalysisWorkflowStatus;
  phenotypes: any[];
  detailed_status: DetailedStatus;
  main_sample: AnalysisSampleDetails;
  type_desc: string;
  availability_status: number;
  disease: string;
  assay: Assay;
  assigned_user?: any;
  assigned_user_name: string;
  assigned_user_email: string;
  created_by: string;
  report_configuration: ReportConfiguration;
  last_report_uuid?: string;
  followed: boolean;
  phenotypes_grouped: any[];
  referral: AnalysisReferral;
  snp_annotation_done: boolean;
  sv_annotation_done: boolean;
  samples: Sample[];
  virtual_samples: AnalysisSampleDetails[];
  num_samples_in_repository?: number;
  family_samples_relation?: FamilyMemberRelation[];
  custom_data: { [key: string]: any };
  cohort_analyses?: {
    analysis_id: number;
    analysis_name: string;
  }[];
  access_list: string[];
  highest_annotation_version: number;
  analysis_files: AnalysisDetailsFiles[];
  versions: AnnotationVersions;
}

export interface AnalysisReferral {
  physician: string;
  institution: string;
  tel?: number;
}

export interface AnalysisReportReadyStatus {
  ready: boolean;
  non_assessed_variants_count: number;
}
