import { FeatureBit } from './feature-bits.model';

export interface Community {
  auth0_name: string;
  description: string;
  id: string;
  name: string;
  permitted_domains: string[];
}

export interface UserDetailsOrganizationConfig {
  analysisConfig?: {
    secondaryFindingsOptInDefault?: boolean;
    filterTreeConfig?: {
      hideTileLabels?: boolean;
    };
  };
  analysisProtocolsConfig?: {
    assignmentGroups?: string[];
  };
  interpretationConfig?: {
    useLegacyKb?: boolean;
  };
  userConfig?: {
    sessionTimeoutSec?: number;
    hideSignUp?: boolean;
  };
  annotationConfig?: {
    exclusionListsConfig?: {
      lists?: {
        name: string;
      }[];
    }
  };
}

export interface UserDetails {
  auth0_id: string;
  communities: Community[];
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  created_at: string;
  updated_at: string;
  name: string;
  titles?: string;
  organization: Community;
  roles: any[];
  org_roles: any[];
  feature_bits?: { [key in FeatureBit]?: boolean };
  is_in_multi_org_community: boolean;
  is_super_admin: boolean;
  user_sigs: string[];
  permissions: {
    allowed_actions: UserPermission[];
  };
  country?: string;
  org_config: UserDetailsOrganizationConfig;
}

export interface Credentials {
  name: string;
  password: string;
  email: string;
  connection: string;
}

export interface Auth0Metadata {
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  email: string;
  updated_at: Date;
  email_verified: boolean;
  user_id: { id: string };
  auth0_id: { id: string };
}

export interface UserMetadata {
  auth0_metadata: Auth0Metadata;
  organization_id: string;
}

export interface SignUpResponse {
  access_token: string;
  scope: string;
  expires_in: number;
  token_type: string;
  id_token: string;
  state?: string;
  mfa_token?: string;
  mfa_enrollment_uri?: string;
}

export interface SetInfoResponse {
  credentials: Credentials;
  user_metadata: UserMetadata;
}

export interface InviteUsersResponse {
  credentials: Credentials;
  user_metadata: UserMetadata;
}

export interface UserLocationData {
  city: string;
  country: string;
  region?: string;
  loc?: string;
}

export interface InviteeDataResponse {
  uuid: string;
  org_id: string;
  org_name: string;
  email: string;
}

export interface TeamMember {
  name: string;
  email?: string;
  auth0_id?: string;
  given_name?: string;
  family_name?: string;
  created_at?: string;
  city?: string;
  country?: string;
  updated_at?: string;
  last_login?: string;
  logins_count?: number;
  communities?: any[];
  organization_roles?: any[];
  picture?: string;
  bi_user_data?: {
    user_id: string;
    username: string;
    organization: string;
    email: string;
    organization_id: string;
    all_fields: {
      'com.gnx.bi.events.UserData.user_id': string;
      'com.gnx.bi.events.UserData.username': string;
      'com.gnx.bi.events.UserData.email': string;
    };
  };
  email_user_id?: {
    id: string;
  };
  locale?: string;
  email_verified?: boolean;
  roles?: string[];
  org_roles?: string[];
  status?: string;
  loading?: boolean;
}

export const AUTH_USER_ROLES = [
  'Molecular geneticist',
  'Clinical geneticist',
  'Genetic counselor',
  'Cytogeneticist',
  'Medical doctor',
  'Bioinformatician',
  'Lab/clinic director',
  'Student',
  'Other',
];

export enum UserPermission {
  ViewCase = 'view_case',
  EditCaseDetails = 'edit_case_details',
  WriteReportDraft = 'write_report_draft',
  ApproveReport = 'approve_report',
  AddEvidence = 'add_evidence',
  AddToReport = 'add_to_report',
  RemoveFromReport = 'remove_from_report',
  AddToWorkbench = 'add_to_workbench',
  RemoveFromWorkbench = 'remove_from_workbench',
  AssignClinicalSignificance = 'assign_clinical_significance',
  Classify = 'classify',
  CurateGene = 'curate_gene',
  DeleteOrgAssessment = 'delete_org_assessment',
  SignReportPreliminary = 'sign_report_preliminary',
  ClassifyAsFinal = 'classify_as_final',
  ManageFilters = 'manage_filters',
  EditCaseDetailsMyCases = 'edit_case_details_my_cases',
  CreateCase = 'create_case',
  EditKB = 'edit_kb',
  AccessAllCases = 'access_all_cases',
  ProvideCaseAccess = 'provide_case_access',
  GeneCurationSaveDraft = 'gene_curation_save_draft',
  GeneCurationSaveFinal = 'gene_curation_save_final',
  GeneCurationSaveApproval = 'gene_curation_save_approval',
  EditVariantLists = 'edit_variant_lists'
}

export interface AzureConnectionResponse {
  connection_name: string;
  force_login?: boolean;
}
