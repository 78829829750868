<ng-content></ng-content>
<ng-container *ngIf="chips?.length">
  <div
    class="chip"
    *ngFor="let item of chips"
    [class.warning]="warningChips.includes(item)"
    [matTooltip]="warningChips.includes(item) ? warningTooltip : ''"
    matTooltipClass="blue-md-tooltip"
    [dndDraggable]="item"
    [dndDisableDragIf]="!dropZones?.length"
    [dndDropzone]="dropZones"
  >
    <img
      *ngIf="!disableRemove && !warningChips?.includes(item)"
      src="/assets/svg/x-white.svg"
      (click)="remove.emit(item)"
    />
    <img
      *ngIf="warningChips?.includes(item)"
      src="/assets/svg/x-black-turned.svg"
      (click)="remove.emit(item)"
      class="warning-x"
    />
    <div
      gnxTextOverflowEllipsis
      #chipText="gnxTextOverflowEllipsis"
      [matTooltip]="item"
      [matTooltipDisabled]="!chipText.isOverflowed"
      matTooltipClass="white-md-tooltip wide multi-line"
    >
      {{ item }}
    </div>
  </div>
  <a (click)="clearAll.emit()" *ngIf="showClearAll && chips?.length > 1">Clear all</a>
</ng-container>
