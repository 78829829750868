import { Component, forwardRef, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AuthModuleState } from '../../../auth0/store/reducers';
import { UserDetails } from '../../../auth0/models';
import { getUserDetails } from '../../../auth0/store/selectors';
import { Observable } from 'rxjs';
import { Location, NgIf, AsyncPipe } from '@angular/common';
import { AppModuleState } from '../../../../store/reducers';
import { GetUserVariantsCount } from '../../../../store/actions';
import { getUserVariantsCount } from '../../../../store/selectors';
import { UserUtils } from '../../../auth0/utils/user.utils';
import { OrganizationInfoComponent } from '../../components/organization-info/organization-info.component';
import { ProfileDetailsComponent } from '../../components/profile-details/profile-details.component';
import { MatTabGroup, MatTab, MatTabContent } from '@angular/material/tabs';
import { AvatarModule } from 'ngx-avatar-ivy';
import { DotsLoaderComponent } from '../../../../shared/components/dots-loader/dots-loader.component';
import { ClickCursorDirective } from '../../../../shared/directives/click-cursor.directive';
import { TopNavComponent } from '../../../../shared/containers/top-nav/top-nav.component';

@Component({
  selector: 'gnx-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss'],
  standalone: true,
  imports: [
    TopNavComponent,
    ClickCursorDirective,
    NgIf,
    DotsLoaderComponent,
    AvatarModule,
    MatTabGroup,
    MatTab,
    MatTabContent,
    ProfileDetailsComponent,
    OrganizationInfoComponent,
    AsyncPipe,
  ],
})
export class SettingsPageComponent implements OnInit {
  pageLoading = true;
  userDetails: UserDetails;
  isAdmin: boolean;
  variantsCount$: Observable<number>;

  constructor(
    private authStore$: Store<AuthModuleState>,
    private store$: Store<AppModuleState>,
    public location: Location,
  ) {}

  ngOnInit() {
    this.authStore$.pipe(select(getUserDetails)).subscribe((user) => {
      if (user) {
        this.userDetails = user;
        this.pageLoading = false;
        this.isAdmin = UserUtils.isUserAnAdmin(user);
      }
    });
    this.store$.dispatch(new GetUserVariantsCount());
    this.variantsCount$ = this.store$.pipe(select(getUserVariantsCount));
  }
}
