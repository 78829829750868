import { Component, Inject, OnInit } from '@angular/core';
import { parseAsVariantId, SnpVariant, SvVariant, VariantId, variantIdToString } from '../../store/models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppModuleState } from '../../store/reducers';
import { ArraySortPipe } from '../../shared/pipes/array-sort.pipe';
import { NgFor, NgIf } from '@angular/common';
import { ClickCursorDirective } from '../../shared/directives/click-cursor.directive';

interface ChooseVariantPopupData {
  variants: (SnpVariant | SvVariant)[];
  onChooseAction: any;
}

@Component({
  selector: 'gnx-choose-variant-popup',
  templateUrl: './choose-variant-popup.component.html',
  styleUrls: ['./choose-variant-popup.component.scss'],
  standalone: true,
  imports: [ClickCursorDirective, NgFor, NgIf, ArraySortPipe],
})
export class ChooseVariantPopupComponent implements OnInit {
  constructor(
    private store$: Store<AppModuleState>,
    public dialogRef: MatDialogRef<ChooseVariantPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChooseVariantPopupData,
  ) {}

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
  }

  onChoose(variant: SnpVariant | SvVariant) {
    this.store$.dispatch(new this.data.onChooseAction(parseAsVariantId(variant)));
    this.onClose();
  }

  getVariantString(option: SnpVariant | SvVariant): string {
    const variantId: VariantId = parseAsVariantId(option);
    return variantIdToString(variantId);
  }
}
