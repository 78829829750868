import { Ethnicity } from '../models';
import * as fromAction from './../actions/ethnicities.action';

export interface EthnicitiesState {
  data: Ethnicity[];
  loading: boolean;
  loaded: boolean;
}

const initialState: EthnicitiesState = {
  data: [],
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action: fromAction.EthnicitiesAction): EthnicitiesState {
  switch (action.type) {
    case fromAction.GET_ETHNICITY_OPTIONS: {
      return { ...state, loading: true, loaded: false, data: undefined };
    }
    case fromAction.GET_ETHNICITY_OPTIONS_SUCCESS: {
      return { ...state, loading: false, loaded: true, data: action.payload };
    }
    case fromAction.GET_ETHNICITY_OPTIONS_FAIL: {
      return { ...state, loading: false, loaded: false };
    }
  }
  return { ...state };
}

export const getEthnicityOptions = (state: EthnicitiesState) => state.data;
export const getEthnicityLoading = (state: EthnicitiesState) => state.loading;
export const getEthnicityLoaded = (state: EthnicitiesState) => state.loaded;
