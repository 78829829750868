import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarMode } from '../../../store/models/snackbar.model';
import { PandaToastComponent } from '../panda-toast/panda-toast.component';

export interface PandaToastSnackbarData {
  message: string;
  details?: string;
  mode: SnackbarMode;
}

@Component({
  selector: 'gnx-panda-toast-snackbar',
  template:
    '<gnx-panda-toast [message]="data.message" [details]="data.details" [mode]="data.mode" (close)="snackBarRef.dismissWithAction()"></gnx-panda-toast>',
  styleUrls: ['./panda-toast-snackbar.component.scss'],
  standalone: true,
  imports: [PandaToastComponent],
})
export class PandaToastSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: PandaToastSnackbarData,
    @Inject(MatSnackBarRef) public snackBarRef: MatSnackBarRef<PandaToastSnackbarComponent>,
  ) {}
}
