import * as fromAction from '../actions/cancer-type.actions';

export interface CancerTypeState {
  data: string;
  loaded: boolean;
  loading: boolean;
}

export const initialState: CancerTypeState = {
  data: undefined,
  loaded: false,
  loading: false,
};

export function reducer(state = initialState, action: fromAction.CancerTypeAction): CancerTypeState {
  switch (action.type) {
    case fromAction.LOAD_CANCER_TYPE:
      return {
        ...state,
        data: null,
        loaded: false,
        loading: true,
      };
    case fromAction.LOAD_CANCER_TYPE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loaded: true,
        loading: false,
      };
    case fromAction.LOAD_CANCER_TYPE_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
      };
    case fromAction.UPDATE_CANCER_TYPE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
}

export const getCancerType = (state: CancerTypeState) => state.data;
export const getCancerTypeLoaded = (state: CancerTypeState) => state.loaded;
export const getCancerTypeLoading = (state: CancerTypeState) => state.loading;
