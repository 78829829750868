import * as fromReducer from '../reducers/download-analysis-files.reducer';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { getWorkbenchModuleState, WorkbenchModuleState } from '../reducers';
import { AnalysisFileForDownload } from '../models';

const getDownloadAnalysisFilesState: MemoizedSelector<WorkbenchModuleState, fromReducer.DownloadAnalysisFilesState> =
  createSelector(getWorkbenchModuleState, (state: WorkbenchModuleState) => state.downloadAnalysisFiles);

export const getDownloadAnalysisFilesUrls: MemoizedSelector<
  WorkbenchModuleState,
  { [fileType: string]: AnalysisFileForDownload[] }
> = createSelector(getDownloadAnalysisFilesState, (state) => state.analysisFiles);

export const getDownloadAnalysisFilesLoaded: MemoizedSelector<WorkbenchModuleState, { [fileType: string]: boolean }> =
  createSelector(getDownloadAnalysisFilesState, (state) => state.loadedUrls);

export const getDownloadAnalysisFilesLoading: MemoizedSelector<WorkbenchModuleState, { [fileType: string]: boolean }> =
  createSelector(getDownloadAnalysisFilesState, (state) => state.loadingUrls);

export const getAnalysisFilesDownloadingState: MemoizedSelector<WorkbenchModuleState, { [fileType: string]: boolean }> =
  createSelector(getDownloadAnalysisFilesState, (state) => state.downloadingAnalysisFiles);

export const getFamilyCarrierDownloadingState: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getDownloadAnalysisFilesState,
  (state) => state.downloadingFamilyCarrier,
);

export const getControlSampleReportLoading: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getDownloadAnalysisFilesState,
  (state) => state.exportControlSampleReportLoading,
);

export const getControlSampleReportReady: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getDownloadAnalysisFilesState,
  (state) => state.exportControlSampleReportReady,
);

export const getControlSampleReportPath: MemoizedSelector<WorkbenchModuleState, string> = createSelector(
  getDownloadAnalysisFilesState,
  (state) => state.exportControlSampleReportPath,
);

export const getControlSampleReportError: MemoizedSelector<WorkbenchModuleState, any> = createSelector(
  getDownloadAnalysisFilesState,
  (state) => state.exportControlSampleReportError,
);

export const getDownloadingAnalysisSampleFile: MemoizedSelector<WorkbenchModuleState, { [key: string]: boolean }> = createSelector(
  getDownloadAnalysisFilesState,
  (state) => state.analysisSampleFileDownloading
);
