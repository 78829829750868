import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Phenotype } from '../models';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GenesService {
  constructor(private http: HttpClient) {}

  searchGenes(str: string): Observable<Phenotype[]> {
    return this.http.get<Phenotype[]>(`${environment.beBaseURL}/api/api/gene/search?str=${str}`);
  }
}
