import { createAction } from '../../../../../../../../node_modules/@ngrx/store';
import { props } from '@ngrx/store';
import { AnalysisResult, VariantType } from '../../../analysis-variant/models';
import { VariantList } from '../models';
import { AnalysisType } from '../../../../../../store';

export const loadVariantLists = createAction(
  '[Analysis] Load variant lists',
  props<{
    analysisId: number;
    analysisType: AnalysisType;
    variantType: VariantType.SNP | VariantType.SV;
    referenceVersion: 'HG19' | 'HG38';
  }>(),
);
export const loadVariantListsSuccess = createAction(
  '[Analysis] Load variant lists success',
  props<{ data: VariantList[] }>(),
);
export const loadVariantListsFail = createAction('[Analysis] Load variant lists fail', props<{ error: any }>());

export const editVariantLists = createAction(
  '[Analysis] Edit variant lists',
  props<{
    analysisId: number;
    analysisType: AnalysisType;
    variantType: VariantType.SNP | VariantType.SV;
    variants: AnalysisResult[];
    referenceVersion: 'HG19' | 'HG38';
  }>(),
);

export const addToVariantLists = createAction(
  '[Analysis] Add variants to lists',
  props<{
    analysisId: number;
    referenceVersion: 'HG19' | 'HG38';
    variantType: VariantType.SNP | VariantType.SV;
    variants: AnalysisResult[];
    variantLists: string[];
    analysisType: AnalysisType;
    markNotRelevant: boolean;
    addToWorkbench: boolean;
  }>(),
);
export const addToVariantListsSuccess = createAction(
  '[Analysis] Add variants to lists success',
  props<{
    analysisId: number;
    referenceVersion: 'HG19' | 'HG38';
    variantType: VariantType.SNP | VariantType.SV;
    variants: AnalysisResult[];
    analysisType: AnalysisType;
    markNotRelevant: boolean;
    addToWorkbench: boolean;
  }>(),
);
export const addToVariantListsFail = createAction('[Analysis] Add variants to lists fail', props<{ error: any }>());

export const removeFromVariantLists = createAction(
  '[Analysis] Remove variants from lists',
  props<{
    analysisId: number;
    referenceVersion: 'HG19' | 'HG38';
    variantType: VariantType.SNP | VariantType.SV;
    variants: AnalysisResult[];
    analysisType: AnalysisType;
  }>(),
);
export const removeFromVariantListsSuccess = createAction('[Analysis] Remove variants from lists success');
export const removeFromVariantListsFail = createAction(
  '[Analysis] Remove variants from lists fail',
  props<{ error: any }>(),
);
