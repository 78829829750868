import { Action } from '@ngrx/store';
import {
  VariantLabel,
  VariantLabelData,
  VariantLabelFields,
  VariantLabelModel,
  VariantType,
} from '../../../analysis-variant/models';

export const ADD_REMOVE_LABEL = '[Variants] Add remove label';
export const ADD_REMOVE_LABEL_SUCCESS = '[Variants] Add remove label success';
export const ADD_REMOVE_LABEL_FAIL = '[Variants] Add remove label fail';

export class AddRemoveLabel implements Action {
  readonly type = ADD_REMOVE_LABEL;

  constructor(
    public label: VariantLabelData,
    public variantId: string,
    public variantType: VariantType,
    public remove: boolean,
    public showProgressOnVariantTile: boolean,
    public comment?: string,
    public fields?: VariantLabelFields[],
  ) {}
}

export class AddRemoveLabelSuccess implements Action {
  readonly type = ADD_REMOVE_LABEL_SUCCESS;

  constructor(
    public payload: {
      var_id: string;
      analysis_id: string;
      labels: VariantLabel[];
    },
    public showProgressOnVariantTile: boolean,
    public variantId: string,
    public remove: boolean,
  ) {}
}

export class AddRemoveLabelFail implements Action {
  readonly type = ADD_REMOVE_LABEL_FAIL;

  constructor(
    public err: any,
    public showProgressOnVariantTile: boolean,
    public variantId: string,
  ) {}
}

export type VariantLabelsAction = AddRemoveLabel | AddRemoveLabelSuccess | AddRemoveLabelFail;
