import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, filter, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as fromAppStore from '../../../store';
import {
  AnalysisType,
  AppModuleState,
  CancerTypeOption,
  CancerTypes,
  ClearSearchVariantError,
  Ethnicity,
  getCancerTypes,
  GetCancerTypes,
  getCancerTypesLoaded,
  getCancerTypesLoading,
  GetCancerTypesSuccess,
  GetEthnicityOptions,
  getPhenotypes,
  GetPhenotypes,
  getPhenotypesLoaded,
  getPhenotypesLoading,
  getSearchHistory,
  getVariantIdType,
  Phenotype,
  SearchContext,
  searchEthnicityOptions,
  SearchHistory,
  SearchHistoryResponse,
  SearchHistorySuccess,
  VariantId,
  VariantIdTypes,
} from '../../../store';
import { Observable, Subject } from 'rxjs';
import {
  CONSANGUINITY_OPTIONS,
  INHERITANCE_OPTIONS,
  MALE_X_ZYGOSITY_OPTIONS,
  SEX_OPTIONS,
  ZYGOSITY_OPTIONS,
} from '../../consts/case-context.consts';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { addHighlight } from '../../utils/utils';
import { AuthModuleState, getFeatureBits } from '../../../modules/auth0/store';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { MomentModule } from 'ngx-moment';
import { DotsLoaderComponent } from '../../components/dots-loader/dots-loader.component';
import { PhenotypeOptionComponent } from '../../components/phenotype-option/phenotype-option.component';
import { MatOption } from '@angular/material/core';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { ClickCursorDirective } from '../../directives/click-cursor.directive';
import { MatTooltip } from '@angular/material/tooltip';
import { AsyncPipe, DatePipe, NgClass, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { ClassificationMetadataService } from '../../../store/services/classification-metadata/classification-metadata.service';
import { ClassificationType } from '../../../store/services/classification-metadata/classification-metadata.model';

interface Question {
  name: string;
  value: any;
  isExcluded?: () => boolean;
  isHidden?: () => boolean;
}

@Component({
  selector: 'gnx-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
  animations: [
    trigger('grow', [
      state('inOut', style({ maxHeight: '200px' })),
      state('inOutLarge', style({ maxHeight: '500px' })),
      transition(':leave', animate(300, style({ maxHeight: '0' }))),
      transition(':enter', [style({ maxHeight: '0' }), animate(300)]),
    ]),
  ],
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    MatTooltip,
    ClickCursorDirective,
    MatRadioGroup,
    NgFor,
    MatRadioButton,
    MatAutocompleteTrigger,
    MatAutocomplete,
    MatOption,
    NgClass,
    PhenotypeOptionComponent,
    DotsLoaderComponent,
    AsyncPipe,
    TitleCasePipe,
    DatePipe,
    MomentModule,
    SafeHtmlPipe,
  ],
})
export class QuestionnaireComponent implements OnInit, OnDestroy {
  addHighlight = addHighlight;
  getVariantIdType = getVariantIdType;
  VariantIdTypes = VariantIdTypes;

  SEXES = SEX_OPTIONS;
  ZYGOSITIES = ZYGOSITY_OPTIONS;
  MALE_X_ZYGOSITIES = MALE_X_ZYGOSITY_OPTIONS;
  INHERITANCES = INHERITANCE_OPTIONS;
  CONSANGUINITY = CONSANGUINITY_OPTIONS;

  AVAILABLE_QUESTIONS: Question[] = [
    {
      name: 'zygosity',
      value: undefined,
      isExcluded: this.isZygosityExcluded.bind(this),
      isHidden: this.isZygosityHidden.bind(this),
    },
    { name: 'phenotypes', value: [], isExcluded: this.isPhenotypesExcluded.bind(this) },
    { name: 'cancer_type', value: undefined, isExcluded: this.isCancerTypeExcluded.bind(this) },
    { name: 'ethnicity', value: [] },
    { name: 'consanguinity', value: undefined, isExcluded: this.isConsanguinityExcluded.bind(this) },
    { name: 'family_inheritance_status', value: undefined, isExcluded: this.isInheritanceExcluded.bind(this) },
    { name: 'reported_classification', value: undefined },
    { name: 'sex', value: undefined },
  ];

  @Input() searchControl: FormControl;
  @Input() closeable: boolean;
  @Output() contextChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() search: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChildren('q') questionsRef: QueryList<ElementRef>;

  phenotypes$: Observable<Phenotype[]>;
  phenotypesLoading$: Observable<boolean>;
  phenotypesLoaded$: Observable<boolean>;
  cancerTypes$: Observable<CancerTypes>;
  cancerTypesLoading$: Observable<boolean>;
  cancerTypesLoaded$: Observable<boolean>;
  ethnicities$: Observable<Ethnicity[]>;
  searchHistory$: Observable<SearchHistoryResponse>;

  destroy$: Subject<void> = new Subject<void>();

  phenotypeSearchControl: FormControl;
  cancerTypeSearchControl: FormControl;
  ethnicitySearchControl: FormControl;
  caseFormGroup: FormGroup;
  variantId: VariantId;
  featureBits: { [key: string]: boolean };
  questions: number;
  skips: number;

  constructor(
    private store$: Store<AppModuleState>,
    private authStore$: Store<AuthModuleState>,
    private fb: FormBuilder,
    private classificationService: ClassificationMetadataService,
  ) {}

  ngOnInit(): void {
    this.questions = 0;
    this.skips = 0;
    this.phenotypeSearchControl = this.fb.control('');
    this.cancerTypeSearchControl = this.fb.control('');
    this.ethnicitySearchControl = this.fb.control('');

    this.phenotypes$ = this.store$.pipe(select(getPhenotypes));
    this.phenotypesLoading$ = this.store$.pipe(select(getPhenotypesLoading));
    this.phenotypesLoaded$ = this.store$.pipe(select(getPhenotypesLoaded));
    this.cancerTypes$ = this.store$.pipe(select(getCancerTypes));
    this.cancerTypesLoading$ = this.store$.pipe(select(getCancerTypesLoading));
    this.cancerTypesLoaded$ = this.store$.pipe(select(getCancerTypesLoaded));
    this.searchHistory$ = this.store$.pipe(select(getSearchHistory));
    this.authStore$
      .pipe(select(getFeatureBits), takeUntil(this.destroy$))
      .subscribe((featureBits) => (this.featureBits = featureBits));

    this.searchControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.setInitialZygosityIfX0OrX1.bind(this));
    this.searchControl.valueChanges.pipe(debounceTime(1000), takeUntil(this.destroy$)).subscribe((value: string) => {
      if (!this.questions) {
        this.insertQuestion();
      }
      this.setInitialZygosityIfX0OrX1();
      if (value) {
        if (this.questions > 1) {
          this.search.emit(false);
        }
      } else {
        this.onClose();
      }
    });
    this.store$
      .pipe(
        select(fromAppStore.getSearchResult),
        takeUntil(this.destroy$),
        filter((value) => !!value),
      )
      .subscribe((value) => {
        this.variantId = value;

        this.setInitialZygosityIfX0OrX1();
        this.setInitialHemizygoteIfMaleX();

        this.store$.dispatch(new SearchHistory(this.searchControl.value));
      });
    this.ethnicities$ = this.ethnicitySearchControl.valueChanges.pipe(
      debounceTime(500),
      switchMap((search) =>
        this.store$.pipe(
          select(searchEthnicityOptions, { searchTerm: search }),
          tap(() => {
            this.caseFormGroup.get('ethnicity').markAsDirty();
            this.caseFormGroup.updateValueAndValidity();
          }),
        ),
      ),
    );
    this.phenotypeSearchControl.valueChanges.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe((search) => {
      this.store$.dispatch(new GetPhenotypes(search, ['phenotypes']));
      this.caseFormGroup.get('phenotypes').markAsDirty();
      this.caseFormGroup.updateValueAndValidity();
    });
    this.cancerTypeSearchControl.valueChanges.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe((search) => {
      if (search?.length >= 3) {
        this.store$.dispatch(new GetCancerTypes(search));
        this.caseFormGroup.get('cancer_type').markAsDirty();
        this.caseFormGroup.updateValueAndValidity();
      } else {
        this.store$.dispatch(new GetCancerTypesSuccess(undefined, ''));
      }
    });
    this.createCaseFormGroup();
    this.store$.dispatch(new GetEthnicityOptions());
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onClose() {
    this.questions = 0;
    this.skips = 0;
    this.caseFormGroup.reset(
      { zygosity: this.caseFormGroup.value.zygosity === 'TUMOR' ? 'TUMOR' : undefined },
      { emitEvent: false },
    );
    this.clearHistory();
    this.store$.dispatch(new ClearSearchVariantError());
  }

  setIsTumor(value: boolean) {
    this.caseFormGroup.get('zygosity').markAsDirty();
    this.caseFormGroup.get('zygosity').setValue(value ? 'TUMOR' : undefined);
    this.questions = Math.min(this.questions, this.visibleQuestions.length);
    this.AVAILABLE_QUESTIONS.forEach((question) => {
      if (question.isExcluded && question.isExcluded()) {
        this.caseFormGroup.get(question.name).setValue(question.value);
      }
    });
    if (this.questions && this.searchControl.value) {
      this.search.emit(false);
    }
  }

  isZygosityExcluded(): boolean {
    return (
      (this.variantId && getVariantIdType(this.variantId) === VariantIdTypes.ROH) ||
      (this.variantId?.chr?.toLowerCase() === 'chry' && this.caseFormGroup.get('sex')?.value === 'MALE')
    );
  }

  isZygosityHidden(): boolean {
    return this.caseFormGroup?.get('zygosity')?.value === 'TUMOR';
  }

  isPhenotypesExcluded(): boolean {
    return this.caseFormGroup.get('zygosity')?.value === 'TUMOR';
  }

  isCancerTypeExcluded(): boolean {
    return this.caseFormGroup.get('zygosity')?.value !== 'TUMOR';
  }

  isInheritanceExcluded(): boolean {
    return (
      (this.variantId && getVariantIdType(this.variantId) === VariantIdTypes.ROH) ||
      this.caseFormGroup.get('zygosity')?.value === 'TUMOR'
    );
  }

  isConsanguinityExcluded(): boolean {
    return (
      (this.variantId && getVariantIdType(this.variantId) !== VariantIdTypes.ROH) ||
      this.caseFormGroup.get('zygosity')?.value === 'TUMOR'
    );
  }

  onPhenotypeSelected(phenotype: Phenotype) {
    const phenotypes: string[] = this.caseFormGroup.get('phenotypes').value || [];
    if (!phenotypes?.includes(phenotype.name)) {
      this.caseFormGroup.get('phenotypes').setValue([...phenotypes, phenotype.name]);
    }
    this.scrollToEnd();
  }

  onPhenotypeRemove(phenotype: string) {
    const phenotypes: string[] = this.caseFormGroup.get('phenotypes').value;
    this.caseFormGroup.get('phenotypes').setValue(phenotypes.filter((value) => value !== phenotype));
  }

  onCancerTypeSelected(cancerType: CancerTypeOption) {
    this.caseFormGroup.get('cancer_type').setValue(cancerType.primaryName);
    this.cancerTypeSearchControl.setValue(cancerType.primaryName, { emitEvent: false });
    this.scrollToEnd();
  }

  onCancerTypeRemove() {
    this.caseFormGroup.get('cancer_type').setValue(undefined);
  }

  onEthnicitySelected(ethnicity: Ethnicity) {
    const ethnicities: string[] = this.caseFormGroup.get('ethnicity').value || [];
    if (!ethnicities?.includes(ethnicity.name)) {
      this.caseFormGroup.get('ethnicity').setValue([...ethnicities, ethnicity.name]);
    }
    this.scrollToEnd();
  }

  onEthnicityRemove(ethnicity: string) {
    const ethnicities: string[] = this.caseFormGroup.get('ethnicity').value;
    this.caseFormGroup.get('ethnicity').setValue(ethnicities.filter((value) => value !== ethnicity));
  }

  onSkipQuestion() {
    this.skips++;
    this.insertQuestion();
  }

  insertQuestion() {
    if (this.questions === 1) {
      this.search.emit(false);
    }
    this.questions = Math.min(this.questions + 1, this.visibleQuestions.length);
    this.scrollToEnd();
  }

  runHistory(searchContext: SearchContext) {
    this.contextChange.emit(searchContext);
    this.search.emit(true);
  }

  clearHistory() {
    this.store$.dispatch(new SearchHistorySuccess({}));
  }

  getQuestionVisible(name: string): boolean {
    const visibleQs = this.visibleQuestions;
    const len: number = Math.min(this.questions, visibleQs.length);
    for (let i = 0; i < len; i++) {
      if (visibleQs[i].name === name) {
        return true;
      }
    }
    return false;
  }

  get visibleQuestions(): Question[] {
    return this.AVAILABLE_QUESTIONS.filter(
      (question) => (!question.isExcluded || !question.isExcluded()) && (!question.isHidden || !question.isHidden()),
    );
  }

  private createCaseFormGroup() {
    if (this.caseFormGroup) {
      return;
    }
    this.caseFormGroup = this.fb.group(
      this.AVAILABLE_QUESTIONS.reduce((acc, crt) => {
        return {
          ...acc,
          [crt.name]: this.fb.control(crt.value),
        };
      }, {}),
    );
    this.caseFormGroup.valueChanges.pipe(startWith({}), takeUntil(this.destroy$)).subscribe((value) => {
      this.contextChange.emit(value);
      this.setInitialZygosityIfX0OrX1();
      this.setInitialHemizygoteIfMaleX();

      const visibleQuestionsFilled: number =
        this.visibleQuestions.filter((item) => this.caseFormGroup.get(item.name).value?.length).length + this.skips;

      if (this.questions && this.questions <= visibleQuestionsFilled) {
        this.insertQuestion();
      }
    });
  }

  get zygosityOptions() {
    return this.variantId?.chr.toLowerCase() === 'chrx' && this.caseFormGroup.get('sex')?.value === 'MALE'
      ? this.MALE_X_ZYGOSITIES
      : this.ZYGOSITIES;
  }

  get classificationOptions() {
    return this.caseFormGroup.get('zygosity')?.value === 'TUMOR'
      ? this.classificationService.getSomaticClassifications(ClassificationType.Community)
      : this.classificationService.getGermlineClassifications(ClassificationType.VariantFromReport);
  }

  get f() {
    return this.caseFormGroup.controls;
  }

  setInitialHemizygoteIfMaleX() {
    if (
      this.variantId?.chr?.toLowerCase() === 'chrx' &&
      this.caseFormGroup.get('sex')?.value === 'MALE' &&
      this.caseFormGroup.get('zygosity')?.pristine &&
      this.caseFormGroup.get('zygosity')?.value !== 'HET'
    ) {
      this.caseFormGroup.patchValue({ zygosity: 'HEM' });
    }
  }

  setInitialZygosityIfX0OrX1() {
    const zygosity: string = this.searchControl.value.endsWith('x1')
      ? 'HET'
      : this.searchControl.value.endsWith('x0')
        ? 'HOM'
        : undefined;
    if (this.caseFormGroup?.get('zygosity')?.pristine && !this.caseFormGroup.get('zygosity')?.value) {
      this.caseFormGroup.patchValue({ zygosity }, { emitEvent: false });
    } else if (!this.caseFormGroup?.get('zygosity')) {
      this.contextChange.emit({
        ...(this.caseFormGroup?.value || {}),
        zygosity,
      });
    }
  }

  private scrollToEnd() {
    setTimeout(() => {
      const questionElement = this.questionsRef.last?.nativeElement as HTMLElement;
      questionElement?.scrollIntoView({ behavior: 'smooth' });
    }, 300);
  }
}
