import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { getAnalysisDetails, getAnalysisId, getParams, getVariantEvidencesValueState } from '../selectors';
import { AppModuleState } from '../reducers';
import { AnalysisDetails } from '../models';
import { JsonService } from '../../modules/analysis/modules/shared/services/json.service';
import { MatDialog } from '@angular/material/dialog';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import * as fromActions from '../actions/variant-evidences-selection.action';
import * as fromWBActions from '../../modules/analysis/modules/workbench/store/actions';
import { VariantEvidencesSelectionService } from '../services';
import { withLatestFrom } from 'rxjs/operators';
import { VariantEvidencesValueState } from '../reducers/variant-evidences-selection.reducer';
import { getSearchConfig, VariantPageState } from '../../modules/variant-page/modules/variant-page/store';
import {
  SearchConfig,
  variantSearchTermToClassificationType,
} from '../../modules/variant-page/modules/variant-page/models';
import { Params } from '@angular/router';

@Injectable()
export class VariantEvidencesSelectionEffect {
  constructor(
    private actions$: Actions,
    private rootStore$: Store<AppModuleState>,
    private variantStore$: Store<VariantPageState>,
    private jsonService: JsonService,
    private dialog: MatDialog,
    private service: VariantEvidencesSelectionService,
  ) {}

  getVariantEvidencesOptions$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.GET_VARIANT_EVIDENCES_OPTIONS),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisDetails))),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisId))),
      withLatestFrom(this.rootStore$.pipe(select(getParams))),
      switchMap(
        ([[[action, analysisDetails], analysisId], params]: [
          [[fromActions.GetVariantEvidencesOptions, AnalysisDetails], number],
          Params,
        ]) => {
          let reqParams = [];
          if (analysisDetails.analysis_type) {
            const annotationVersion =
              analysisDetails.main_sample?.annotation_version ||
              analysisDetails.main_sample?.sv_annotation_version ||
              analysisDetails.main_sample?.rna_annotation_version;
            reqParams = [analysisDetails.analysis_type, annotationVersion, analysisId, analysisDetails.assay?.uuid];
          } else {
            reqParams = [variantSearchTermToClassificationType(params.variantType)];
          }
          return this.service.getVariantEvidencesOptions(...reqParams).pipe(
            map((results) => new fromActions.GetVariantEvidencesOptionsSuccess(results)),
            catchError((error) => of(new fromActions.GetVariantEvidencesOptionsFail(error))),
          );
        },
      ),
    ),
  );

  saveEvidencesForVariant$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.SAVE_EVIDENCES_FOR_VARIANT),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisDetails))),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisId))),
      withLatestFrom(this.rootStore$.pipe(select(getVariantEvidencesValueState))),
      withLatestFrom(this.variantStore$.pipe(select(getSearchConfig))),
      switchMap(
        ([[[[action, analysisDetails], analysisId], value], caseContext]: [
          [[[fromActions.SaveEvidencesForVariant, AnalysisDetails], number], VariantEvidencesValueState],
          SearchConfig,
        ]) => {
          const annotationVersion =
            analysisDetails.main_sample.annotation_version ||
            analysisDetails.main_sample.sv_annotation_version ||
            analysisDetails.main_sample.rna_annotation_version;
          return this.service
            .saveEvidencesForVariant(
              analysisDetails.analysis_type,
              analysisDetails.assay.uuid,
              value,
              caseContext,
              action.varIdObj,
              analysisId,
            )
            .pipe(
              switchMap((results) => [
                new fromWBActions.CloseNewEvidencePopup(),
                new fromWBActions.ReloadAnalysisVariants(),
              ]),
              catchError((error) => of(new fromActions.GetVariantEvidencesOptionsFail(error))),
            );
        },
      ),
    ),
  );
}
