import { Action, createAction, props } from '@ngrx/store';
import { VariantAIInterpretationPayload, VariantInterpretation } from '../../models';
import { SnpVariantId, VariantId } from '../../../variant-page/models';
import { VariantPopupAppType } from '../../../../../analysis/modules/analysis-variant/models';
import { InterpretationType } from '../../containers/interpretation/interpretation.data';

export const LOAD_VARIANT_INTERPRETATION = '[Interpretation] Load variant interpretation';
export const LOAD_VARIANT_INTERPRETATION_SUCCESS = '[Interpretation] Load variant interpretation Success';
export const LOAD_VARIANT_INTERPRETATION_FAIL = '[Interpretation] Load variant interpretation Fail';

export class LoadVariantInterpretation implements Action {
  readonly type = LOAD_VARIANT_INTERPRETATION;

  constructor(
    public variantId: VariantId,
    public popupAppType?: VariantPopupAppType,
    public compoundVariantId?: VariantId,
    public conditionId?: string,
    public allowedPoints?: number,
    public vciEnabled?: boolean,
  ) {}
}

export class LoadVariantInterpretationSuccess implements Action {
  readonly type = LOAD_VARIANT_INTERPRETATION_SUCCESS;

  constructor(
    public variantId: VariantId,
    public data: VariantInterpretation,
  ) {}
}

export class LoadVariantInterpretationFail implements Action {
  readonly type = LOAD_VARIANT_INTERPRETATION_FAIL;

  constructor(public error: any) {}
}

export const LOAD_AI_SUGGESTION = '[Interpretation] Load ai suggestion';
export const LOAD_AI_SUGGESTION_SUCCESS = '[Interpretation] Load ai suggestion Success';
export const LOAD_AI_SUGGESTION_FAIL = '[Interpretation] Load ai suggestion Fail';

export class LoadAISuggestion implements Action {
  readonly type = LOAD_AI_SUGGESTION;

  constructor(
    public payload: VariantAIInterpretationPayload,
    public interpretationType: InterpretationType,
  ) {}
}

export class LoadAISuggestionSuccess implements Action {
  readonly type = LOAD_AI_SUGGESTION_SUCCESS;

  constructor(
    public suggestion: string,
    public interpretationType: InterpretationType,
  ) {}
}

export class LoadAISuggestionFail implements Action {
  readonly type = LOAD_AI_SUGGESTION_FAIL;

  constructor(public error: any) {}
}

export const UPDATE_VARIANT_INTERPRETATION = '[Interpretation] Update variant interpretation';

export class UpdateVariantInterpretation implements Action {
  readonly type = UPDATE_VARIANT_INTERPRETATION;

  constructor(public data: VariantInterpretation) {}
}

export const REVERT_VARIANT_INTERPRETATION = '[Interpretation] Revert variant interpretation';

export class RevertVariantInterpretation implements Action {
  readonly type = REVERT_VARIANT_INTERPRETATION;

  constructor(public interpretationType: InterpretationType) {}
}

export const SAVE_VARIANT_INTERPRETATION = '[Interpretation] Save variant interpretation';
export const SAVE_VARIANT_INTERPRETATION_RESET = '[Interpretation] Save variant interpretation reset';
export const SAVE_VARIANT_INTERPRETATION_SUCCESS = '[Interpretation] Save variant interpretation Success';
export const SAVE_VARIANT_INTERPRETATION_FAIL = '[Interpretation] Save variant interpretation Fail';
export const CANCEL_SAVE_VARIANT_INTERPRETATION = '[Interpretation] Cancel Save variant interpretation';

export class SaveVariantInterpretation implements Action {
  readonly type = SAVE_VARIANT_INTERPRETATION;

  constructor(
    public variantId: VariantId,
    public interpretation: VariantInterpretation,
    public popupAppType: VariantPopupAppType,
    public compoundVariantId?: VariantId,
    public asDraft?: boolean,
    public vciEnabled?: boolean,
    public avoidWarning?: boolean,
    public avoidReloadResults?: boolean,
    public avoidClosingWizard?: boolean,
  ) {}
}

export class SaveVariantInterpretationReset implements Action {
  readonly type = SAVE_VARIANT_INTERPRETATION_RESET;
}

export class CancelSaveVariantInterpretation implements Action {
  readonly type = CANCEL_SAVE_VARIANT_INTERPRETATION;
}

export class SaveVariantInterpretationSuccess implements Action {
  readonly type = SAVE_VARIANT_INTERPRETATION_SUCCESS;

  constructor(
    public variantId: VariantId,
    public data: VariantInterpretation,
    public avoidReloadResults?: boolean,
    public avoidClosingWizard?: boolean,
  ) {}
}

export class SaveVariantInterpretationFail implements Action {
  readonly type = SAVE_VARIANT_INTERPRETATION_FAIL;

  constructor(public error: any) {}
}

export const REGENERATE_VARIANT_INTERPRETATION = '[Interpretation] Regenerate variant interpretation';
export const REGENERATE_VARIANT_INTERPRETATION_SUCCESS = '[Interpretation] Regenerate variant interpretation Success';
export const REGENERATE_VARIANT_INTERPRETATION_FAIL = '[Interpretation] Regenerate variant interpretation Fail';

export class RegenerateVariantInterpretation implements Action {
  readonly type = REGENERATE_VARIANT_INTERPRETATION;

  constructor(
    public variantId: SnpVariantId,
    public acmgRules?: string[],
  ) {}
}

export class RegenerateVariantInterpretationSuccess implements Action {
  readonly type = REGENERATE_VARIANT_INTERPRETATION_SUCCESS;

  constructor(public data: VariantInterpretation) {}
}

export class RegenerateVariantInterpretationFail implements Action {
  readonly type = REGENERATE_VARIANT_INTERPRETATION_FAIL;

  constructor(public error: any) {}
}

export const CLEAR_VARIANT_INTERPRETATION = '[Interpretation] Clear variant interpretation';

export class ClearVariantInterpretation implements Action {
  readonly type = CLEAR_VARIANT_INTERPRETATION;
}

export const GET_INHERITANCE_MODEL = '[Interpretation] Get inheritance model';
export const GET_INHERITANCE_MODEL_SUCCESS = '[Interpretation] Get inheritance model Success';
export const GET_INHERITANCE_MODEL_FAIL = '[Interpretation] Get inheritance model Fail';

export class GetInheritanceModel implements Action {
  readonly type = GET_INHERITANCE_MODEL;

  constructor(public variantId: SnpVariantId) {}
}

export class GetInheritanceModelSuccess implements Action {
  readonly type = GET_INHERITANCE_MODEL_SUCCESS;

  constructor(public data: VariantInterpretation) {}
}

export class GetInheritanceModelFail implements Action {
  readonly type = GET_INHERITANCE_MODEL_FAIL;

  constructor(public error: any) {}
}

export const APPROVE_SUGGESTED_VARIANT_CLASSIFICATION = '[Analysis] Approve suggested variant classification';
export const APPROVE_SUGGESTED_VARIANT_CLASSIFICATION_SUCCESS =
  '[Analysis] Approve suggested variant classification success';
export const APPROVE_SUGGESTED_VARIANT_CLASSIFICATION_ERROR =
  '[Analysis] Approve suggested variant classification error';

export class ApproveSuggestedVariantClassification implements Action {
  readonly type = APPROVE_SUGGESTED_VARIANT_CLASSIFICATION;

  constructor(
    public variantId: VariantId,
    public interpretation: VariantInterpretation,
    public popupAppType: VariantPopupAppType,
    public compoundVariantId?: VariantId,
  ) {}
}

export class ApproveSuggestedVariantClassificationSuccess implements Action {
  readonly type = APPROVE_SUGGESTED_VARIANT_CLASSIFICATION_SUCCESS;

  constructor(
    public variantId: VariantId,
    public data: VariantInterpretation,
  ) {}
}

export class ApproveSuggestedVariantClassificationError implements Action {
  readonly type = APPROVE_SUGGESTED_VARIANT_CLASSIFICATION_ERROR;

  constructor(public error: any) {}
}

export type VariantInterpretationAction =
  | LoadVariantInterpretation
  | LoadVariantInterpretationSuccess
  | LoadVariantInterpretationFail
  | LoadAISuggestion
  | LoadAISuggestionSuccess
  | LoadAISuggestionFail
  | UpdateVariantInterpretation
  | RevertVariantInterpretation
  | SaveVariantInterpretation
  | CancelSaveVariantInterpretation
  | SaveVariantInterpretationReset
  | SaveVariantInterpretationSuccess
  | SaveVariantInterpretationFail
  | RegenerateVariantInterpretation
  | RegenerateVariantInterpretationSuccess
  | RegenerateVariantInterpretationFail
  | ClearVariantInterpretation
  | GetInheritanceModel
  | GetInheritanceModelSuccess
  | GetInheritanceModelFail
  | ApproveSuggestedVariantClassification;
