import { Action } from '@ngrx/store';
import { VariantEvidenceOptionModel } from '../models/variant-evidences-selection.nodel';
import { VariantEvidencesValueState } from '../reducers/variant-evidences-selection.reducer';

export const GET_VARIANT_EVIDENCES_OPTIONS = '[Variant Evidences] Get Variant Evidences Options';
export const GET_VARIANT_EVIDENCES_OPTIONS_SUCCESS = '[Variant Evidences] Get Variant Evidences Options Success';
export const GET_VARIANT_EVIDENCES_OPTIONS_FAIL = '[Variant Evidences] Get Variant Evidences Options Fail';

export class GetVariantEvidencesOptions implements Action {
  readonly type = GET_VARIANT_EVIDENCES_OPTIONS;
}

export class GetVariantEvidencesOptionsSuccess implements Action {
  readonly type = GET_VARIANT_EVIDENCES_OPTIONS_SUCCESS;

  constructor(public payload: { categories: VariantEvidenceOptionModel[] }) {}
}

export class GetVariantEvidencesOptionsFail implements Action {
  readonly type = GET_VARIANT_EVIDENCES_OPTIONS_FAIL;

  constructor(public error: any) {}
}

export const SET_VARIANT_EVIDENCES_VALUES = '[Variant Evidences] Set Variant Evidences Values';
export const SAVE_EVIDENCES_FOR_VARIANT = '[Variant Evidences] Save Evidences For Variant';
export const CLEAN_VARIANT_EVIDENCES_VALUES = '[Variant Evidences] Clean Variant Evidences Values';

export class SetVariantEvidencesValues implements Action {
  readonly type = SET_VARIANT_EVIDENCES_VALUES;

  constructor(public payload: VariantEvidencesValueState) {}
}

export class SaveEvidencesForVariant implements Action {
  readonly type = SAVE_EVIDENCES_FOR_VARIANT;

  constructor(public varIdObj: any) {}
}

export type VariantEvidencesAction =
  | GetVariantEvidencesOptions
  | GetVariantEvidencesOptionsSuccess
  | GetVariantEvidencesOptionsFail
  | SetVariantEvidencesValues;
