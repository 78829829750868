import { getWorkbenchModuleState, WorkbenchModuleState } from '../reducers';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { VariantListsState } from '../reducers/variant-list.reducer';
import { VariantList } from '../models';

const getVariantListState: MemoizedSelector<WorkbenchModuleState, VariantListsState> = createSelector(
  getWorkbenchModuleState,
  (state) => state.variantLists,
);


export const getVariantLists: MemoizedSelector<WorkbenchModuleState, VariantList[]> = createSelector(
  getVariantListState,
  (state) => state.lists,
);

export const getVariantListsLoading: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getVariantListState,
  (state) => state.loading,
);

export const getVariantListsLoaded: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getVariantListState,
  (state) => state.loaded,
);

export const getVariantListsErrorLoading: MemoizedSelector<WorkbenchModuleState, any> = createSelector(
  getVariantListState,
  (state) => state.errorLoading,
);
