export interface SigTotalCounts {
  suggested?: number;
  classified?: number;
  curated_articles?: number;
  suggested_genes?: number;
  curated_genes?: number;
}

export interface SigTabCounts {
  num_variants?: number;
  num_genes?: number;
}
