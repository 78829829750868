<div class="close-btn" *ngIf="!data.hideCancelBtn" (click)="onClose()">
  <img src="/assets/svg/esc-blk.svg" />
</div>
<div *ngIf="!data.hideTopSection" class="top-section">
  <img src="/assets/svg/v-red.svg" />
</div>
<div class="bottom-section">
  <div class="title">
    {{ data.title || 'Are you sure?' }}
  </div>
  <div class="subtitle" [innerHTML]="data.description || 'This action cannot be undone'"></div>
  @if (data?.showCheckbox) {
    <gnx-checkbox [formControl]="checkboxCtrl" gnxFlex justify="center">
      {{ data.checkboxLabel || "don't show again" }}
    </gnx-checkbox>
  }
  <div class="buttons" *ngIf="!data.reverseButtonsOrder">
    <button
      class="cancel-btn"
      *ngIf="!data.hideCancelBtn"
      (click)="data.cancelButtonCallback ? data.cancelButtonCallback() : defaultNoButtonCallback()"
      mat-dialog-close
    >
      {{ data.cancelButtonLabel || 'NO' }}
    </button>
    <button
      class="ok-btn"
      [class.disabled]="data.checkboxRequired && !checkboxCtrl.value"
      (click)="data.yesButtonCallback ? data.yesButtonCallback(checkboxCtrl.value) : defaultYesButtonCallback()"
      mat-dialog-close
    >
      {{ data.yesButtonLabel || 'YES' }}
    </button>
  </div>

  <div class="buttons" *ngIf="data.reverseButtonsOrder">
    <button
      class="cancel-btn"
      (click)="data.yesButtonCallback ? data.yesButtonCallback(checkboxCtrl.value) : defaultYesButtonCallback()"
      mat-dialog-close
    >
      {{ data.yesButtonLabel || 'YES' }}
    </button>
    <button
      class="ok-btn"
      *ngIf="!data.hideCancelBtn"
      (click)="data.cancelButtonCallback ? data.cancelButtonCallback() : null"
      mat-dialog-close
    >
      {{ data.cancelButtonLabel || 'NO' }}
    </button>
  </div>
</div>
