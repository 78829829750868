import { MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY, MatTooltipDefaultOptions } from '@angular/material/tooltip';

export const appTooltipDefaults: MatTooltipDefaultOptions = {
  ...MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY(),
  position: 'above',
  showDelay: 200,
};

export const disableInteractivityTooltipDefaults: MatTooltipDefaultOptions = {
  ...appTooltipDefaults,
  disableTooltipInteractivity: true,
};
