import { Action } from '@ngrx/store';
import { VariantType } from '../../../analysis-variant/models';
import { AnalysisType } from '../../../../../../store/models';

export const LOAD_LAST_USED_FILTER_SUCCESS = '[Filters] Load last used filter success';
export const LOAD_LAST_USED_FILTER_FAIL = '[Filters] Load last used filter fail';
export const CLEAR_LAST_USED_FILTER = '[Filters] Clear last used filter';

export class LoadLastUsedFilterSuccess implements Action {
  readonly type = LOAD_LAST_USED_FILTER_SUCCESS;

  constructor() {}
}

export class LoadLastUsedFilterFail implements Action {
  readonly type = LOAD_LAST_USED_FILTER_FAIL;

  constructor(public error: any) {}
}

export class ClearLastUsedFilter implements Action {
  readonly type = CLEAR_LAST_USED_FILTER;
}

export const LOAD_LAST_USED_FILTER_AND_STRUCTURE = '[Filters] load last used filter and filters structure';

export class LoadLastUsedFilterAndStructure implements Action {
  readonly type = LOAD_LAST_USED_FILTER_AND_STRUCTURE;

  constructor(
    public analysisId: number,
    public analysisType: AnalysisType,
    public variantType: VariantType,
  ) {}
}

export type LastUsedFilterAction =
  | LoadLastUsedFilterSuccess
  | LoadLastUsedFilterFail
  | LoadLastUsedFilterAndStructure
  | ClearLastUsedFilter;
