import { createAction, props } from '@ngrx/store';
import { SvVariantId } from '../../../variant-page/models';

export const GetPriorityGenes = createAction('[Publications] Get priority genes', props<{ variantId: SvVariantId }>());
export const GetPriorityGenesSuccess = createAction(
  '[Publications] Get priority genes success',
  props<{ genes: string[] }>(),
);
export const GetPriorityGenesFail = createAction('[Publications] Get priority genes fail', props<{ error: any }>());
export const ClearPriorityGenes = createAction('[Publications] Clear priority genes');
