import { getWorkbenchModuleState, WorkbenchModuleState } from '../reducers';
import { ECSResult } from '../../../analysis-variant/models';
import { ECSAnalysisResultsState } from '../reducers/ecs-analysis-results.reducer';
import { createSelector, MemoizedSelector } from '@ngrx/store';

const getECSAnalysisResultState: MemoizedSelector<WorkbenchModuleState, ECSAnalysisResultsState> = createSelector(
  getWorkbenchModuleState,
  (state: WorkbenchModuleState) => state.ecsAnalysisResults,
);

export const getECSAnalysisResults: MemoizedSelector<WorkbenchModuleState, ECSResult[]> = createSelector(
  getECSAnalysisResultState,
  (state: ECSAnalysisResultsState) => state.data,
);

export const getECSAnalysisResultLoaded: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getECSAnalysisResultState,
  (state: ECSAnalysisResultsState) => state.loaded,
);

export const getECSAnalysisResultLoading: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getECSAnalysisResultState,
  (state: ECSAnalysisResultsState) => state.loading,
);

export const getECSAnalysisFilteredResults: MemoizedSelector<WorkbenchModuleState, ECSResult[]> = createSelector(
  getECSAnalysisResults,
  (results) => results.filter((result) => result.variants || result.disease?.always_report),
);
