import { ActionReducerMap, createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromVariantDetails from './variant-details.reducer';
import * as fromConfig from './config.reducer';
import * as fromContact from './contact.reducer';
import * as fromBanner from './app-banner.reducer';

export interface VariantPageState {
  variantDetails: fromVariantDetails.VariantDetailsState;
  config: fromConfig.ConfigState;
  contact: fromContact.ContactState;
  banner: fromBanner.AppBannerState;
}

export const reducers: ActionReducerMap<VariantPageState> = {
  variantDetails: fromVariantDetails.reducer,
  config: fromConfig.reducer,
  contact: fromContact.reducer,
  banner: fromBanner.reducer,
};

export const getVariantPageState: MemoizedSelector<any, VariantPageState> = createSelector(
  (state) => state.variantPage,
  (value) => value,
);
