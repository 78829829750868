import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import * as fromActions from '../actions';
import * as fromReducers from '../reducers';
import { AnalysisDetails } from '../models';
import { AppService } from '../services';
import { getAnalysisDetails } from '../selectors';
import * as fromInterpretationReducer from '../../modules/variant-page/modules/interpretation/store/reducers';

@Injectable()
export class AnalysisDetailsEffects {
  constructor(
    private actions: Actions,
    private rootStore$: Store<fromReducers.AppModuleState>,
    private interpretationStore$: Store<fromInterpretationReducer.VariantInterpretationState>,
    private appService: AppService,
  ) {}

  loadAnalysisDetails$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.LOAD_ANALYSIS_DETAILS),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisDetails))),
      filter(
        ([action, analysisDetails]: [fromActions.LoadAnalysisDetails, AnalysisDetails]) =>
          action.analysisId !== analysisDetails.id,
      ),
      switchMap(([action]: [fromActions.LoadAnalysisDetails, AnalysisDetails]) =>
        this.appService.getAnalysisDetails(action.analysisId).pipe(
          switchMap((data) => [new fromActions.LoadAnalysisDetailsSuccess(data)]),
          catchError((error) => of(new fromActions.LoadAnalysisDetailsFail(error.message))),
        ),
      ),
    ),
  );

  warmAnalysis$: Observable<any> = createEffect(
    () =>
      this.actions.pipe(
        ofType(fromActions.WARM_ANALYSIS),
        switchMap((action: fromActions.WarmAnalysis) => this.appService.warmAnalysis(action.analysisId)),
      ),
    { dispatch: false },
  );

  reloadAnalysisDetails$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.RELOAD_ANALYSIS_DETAILS),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisDetails))),
      switchMap(([, analysisDetails]) =>
        this.appService.getAnalysisDetails(analysisDetails.id).pipe(
          switchMap((data) => [new fromActions.LoadAnalysisDetailsSuccess(data)]),
          catchError((error) => of(new fromActions.LoadAnalysisDetailsFail(error.message))),
        ),
      ),
    ),
  );

  updateAnalysisStatus$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.UPDATE_ANALYSIS_STATUS),
      switchMap((action: fromActions.UpdateAnalysisStatus) =>
        this.appService.updateAnalysisDetails(action.analysisId, { status: action.status }).pipe(
          switchMap((analysis: AnalysisDetails) => [new fromActions.UpdateAnalysisStatusSuccess(analysis)]),
          catchError((error) => of(new fromActions.UpdateAnalysisStatusFail(error))),
        ),
      ),
    ),
  );

  updateAnalysisFollow$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.UPDATE_ANALYSIS_FOLLOW),
      switchMap((action: fromActions.UpdateAnalysisFollow) =>
        this.appService.updateAnalysisDetails(action.analysisId, { followed: action.value }).pipe(
          switchMap((analysis: AnalysisDetails) => [new fromActions.UpdateAnalysisFollowSuccess(analysis)]),
          catchError((error) => of(new fromActions.UpdateAnalysisFollowFail(error))),
        ),
      ),
    ),
  );

  updateAnalysisAssignee$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.UPDATE_ANALYSIS_ASSIGNEE),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisDetails))),
      switchMap(([action, analysisDetails]: [fromActions.UpdateAnalysisAssignee, AnalysisDetails]) => {
        const body = action.payload.assigned_user_email
          ? { assigned_user_email: action.payload.assigned_user_email }
          : { assigned_user: null };
        return this.appService.updateAnalysisDetails(action.payload.analysisId || analysisDetails.id, body).pipe(
          switchMap((analysis: AnalysisDetails) => [new fromActions.UpdateAnalysisAssigneeSuccess(analysis)]),
          catchError((error) => of(new fromActions.UpdateAnalysisAssigneeFail(error))),
        );
      }),
    ),
  );
}
