import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import * as fromAction from './../actions';
import { WorkbenchService } from '../services/workbench.service';
import { VariantInterpretationType } from '../../../analysis-variant/models';
import { SetTreatmentIncludeInReportFail } from './../actions';

@Injectable()
export class CaseSummaryEffect {
  constructor(
    private actions$: Actions,
    private service: WorkbenchService,
  ) {}

  loadCaseSummary$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_CASE_SUMMARY, fromAction.TAKE_BULK_VARIANT_ACTION_SUCCESS),
      filter(
        (action: fromAction.LoadCaseSummary | fromAction.TakeBulkVariantActionSuccess) =>
          action instanceof fromAction.LoadCaseSummary || action.actionType === VariantInterpretationType.in_report,
      ),
      switchMap((action: fromAction.LoadCaseSummary | fromAction.TakeBulkVariantActionSuccess) =>
        this.service.getAnalysisCaseSummary(action.analysisId).pipe(
          switchMap((result) => [new fromAction.LoadCaseSummarySuccess(action.analysisId, result)]),
          catchError((err) => of(new fromAction.LoadCaseSummaryFail(action.analysisId, err))),
        ),
      ),
    ),
  );

  setTreatmentInReport$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.SET_TREATMENT_INCLUDE_IN_REPORT),
      switchMap((action: fromAction.SetTreatmentIncludeInReport) =>
        this.service.setTreatmentInReport(action.analysisId, action.treatmentId, action.inReport).pipe(
          map((result) =>
            result?.success
              ? new fromAction.SetTreatmentIncludeInReportSuccess(
                  action.analysisId,
                  action.treatmentId,
                  action.inReport,
                )
              : new fromAction.SetTreatmentIncludeInReportFail(action.analysisId, action.treatmentId, result),
          ),
          catchError((error) => of(new SetTreatmentIncludeInReportFail(action.analysisId, action.treatmentId, error))),
        ),
      ),
    ),
  );
}
