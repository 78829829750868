import { ActionReducerMap, createFeatureSelector, MemoizedSelector } from '@ngrx/store';

import * as fromHeader from './interpretation-header.reducer';
import * as fromInterpretation from './variant-interpretation.reducer';
import * as fromInterpretationHistory from './interpretation-history.reducer';
import * as fromInterpretationCustomFields from './interpretation-custom-fields.reducer';
import * as fromCuration from './variant-curation.reducer';

export interface VariantInterpretationState {
  header: fromHeader.InterpretationHeaderState;
  interpretation: fromInterpretation.VariantInterpretationState;
  history: fromInterpretationHistory.InterpretationHistoryState;
  interpretationCustomFields: fromInterpretationCustomFields.InterpretationCustomFieldsState;
  curation: fromCuration.VariantCurationState;
}

export const reducers: ActionReducerMap<VariantInterpretationState> = {
  header: fromHeader.reducer,
  interpretation: fromInterpretation.reducer,
  history: fromInterpretationHistory.reducer,
  interpretationCustomFields: fromInterpretationCustomFields.reducer,
  curation: fromCuration.reducer,
};

export const getVariantInterpretationState: MemoizedSelector<VariantInterpretationState, any> =
  createFeatureSelector<VariantInterpretationState>('interpretation');
