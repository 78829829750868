import * as fromAction from '../actions/raffle.action';

export interface RaffleVariantState {
  isRaffleVariant: boolean;
}

const initialState: RaffleVariantState = {
  isRaffleVariant: false,
};

export function reducer(state = initialState, action: fromAction.RaffleVariantAction): RaffleVariantState {
  switch (action.type) {
    case fromAction.GET_IS_RAFFLE_VARIANT_STATE_SUCCESS: {
      return {
        ...state,
        isRaffleVariant: action.result,
      };
    }
  }

  return state;
}

export const getIsRaffleVariant = (state: RaffleVariantState) => state.isRaffleVariant;
