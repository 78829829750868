import { Params } from '@angular/router';
import { FilterGroup } from '../../../shared/models';
import { PositionQuery, RangeFilterQuery, StartEndQuery } from '../../models/variants-query.model';
import { FilterStructure } from '../../../shared/models/filter-structure.model';
import { TreeFilter } from '../../../shared/models/tree-filter';

export function parseStartPositionParams(queryParams: Params, filters: FilterStructure[]): PositionQuery {
  if (queryParamsIncludeOneOfFilters(queryParams, filters)) {
    const position = (parseQueryParam(queryParams, filters, 'positions') || {}) as StartEndQuery;
    return {
      chr: parseQueryParam(queryParams, filters, 'chr') as string,
      ...position,
    };
  }
  return undefined;
}

export function parseFilterGroupIfUsed(queryParams: Params, filters: FilterStructure[]) {
  if (queryParamsIncludeOneOfFilters(queryParams, filters)) {
    return parseQueryParamList(queryParams, filters);
  } else {
    return undefined;
  }
}

export function parseQueryParamList(queryParams: Params, filters: FilterStructure[]) {
  const relevantParams = Object.keys(queryParams).filter((param) => filters.map((f) => f.id).includes(param));
  return relevantParams.reduce(
    (prev, cur) => ({
      ...prev,
      [cur]: parseQueryParam(queryParams, filters, cur),
    }),
    {},
  );
}

export function parseQueryParam(
  queryParams: Params,
  filters: FilterStructure[],
  filterId: string,
): string | string[] | RangeFilterQuery | number | number[] | StartEndQuery {
  const filterStructure = filters.find((f) => f.id === filterId);
  const value: string | string[] = queryParams[filterId];
  if (!value || !filterStructure) {
    return undefined;
  }

  const customParamResult = parseCustomQueryParam(value, filterStructure);
  if (customParamResult) {
    return customParamResult;
  }

  switch (filterStructure?.type) {
    case 'multi-range': {
      return parseRangeParams(getParamAsList(value));
    }
    case 'range':
    case 'simpleRange': {
      return parseStartEndParams(getParamAsSingle(value));
    }
    case 'selection':
    case 'selection-large-list': {
      return parseListParams(value, filterStructure);
    }
    case 'tree': {
      return parseTreeParams(value, filterStructure as TreeFilter);
    }
    case 'dropdown':
    case 'text':
    case 'string':
    case 'horizontal-single-selection':
      return getParamAsSingle(value);
    default:
      return value;
  }
}

function parseCustomQueryParam(value: string | string[], filterStructure: FilterStructure) {
  switch (filterStructure.id) {
    // confidence is a range filter for UI purposes but actually a selection list
    case 'confidence': {
      return getParamAsList(value).map((x) => parseInt(x.split('__')[0], 10));
    }
    default: {
      return undefined;
    }
  }
}

function parseRangeParams(value: string[]): RangeFilterQuery {
  return {
    accept_null: value.includes('null__null'),
    ranges: value
      .filter((x) => x !== 'null__null')
      .map((x) => {
        const range = x.split('__');
        return {
          start: range[0] !== 'null' ? parseFloat(range[0]) : undefined,
          end: range[1] !== 'null' ? parseFloat(range[1]) : undefined,
        };
      }),
  };
}

function parseStartEndParams(value: string): StartEndQuery {
  if (!value) {
    return undefined;
  }

  const range = value.split('__');
  return {
    start: range[0] !== 'null' ? parseInt(range[0], 10) : undefined,
    end: range[1] !== 'null' ? parseInt(range[1], 10) : undefined,
  };
}

function parseTreeParams(value: string | string[], filterStructure: TreeFilter): string[] | number[] {
  const uniqueFilter = (x: string, index: number, array: string[]) => array.indexOf(x) === index;

  const leafOptions = filterStructure.options.filter((x) => x.leaf).map((x) => x.value.toString());
  const relevantValues = getParamAsList(value)
    .filter((x) => leafOptions.includes(x))
    .filter(uniqueFilter);

  return parseListParams(relevantValues, filterStructure);
}

function parseListParams(value: string | string[], filterStructure: FilterStructure): string[] | number[] {
  return filterStructure.option_type === 'int'
    ? getParamAsList(value).map((x) => parseInt(x, 10))
    : getParamAsList(value);
}

export function getParamAsSingle(param: string | string[]): string {
  return typeof param === 'string' ? param : param[0];
}

export function getParamAsList(param: string | string[]): string[] {
  return typeof param === 'string' ? [param] : param;
}

export function queryParamsIncludeOneOfFilters(queryParams: Params, filters: FilterStructure[]): boolean {
  return Object.keys(queryParams).some((param) => filters?.map((f) => f.id)?.includes(param));
}

export function getGroupsFiltersFlatList(groups: FilterGroup[]): FilterStructure[] {
  return groups.map((group) => group.filters).reduce((prev, cur) => [...prev, ...cur], []);
}
