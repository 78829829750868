export interface ArticlesHighlightData {
  num_articles: number;
  scope: string;
}

export interface SuspectedGermlineHighlightData {}

export interface DrugResponsiveHighlightData {
  drug: string;
  disease: string;
  source: string;
}

export interface CosmicHighlightData {
  frequency: string;
}

export interface ClassificationHighlightData {
  classification: string;
}

export interface ReportedHighlightData {
  classification: string;
  source: string;
  num_papers: number;
}

export interface InheritanceModelHighlightData {
  inheritance_model: string;
  disease: string;
  inheritance_pattern: string;
}

export interface PhenotypesHighlightData {
  phenotypes: string[];
  unrelated_phenotypes: string[];
  disease: string;
  relation_strength: string;
}

export interface ClassificationSigHighlightData {
  classification: string;
  sigName: string;
}

export interface CuratedGeneHighlightData {
  geneSymbol: string;
  sigName: string;
}

export interface SuggestedGeneHighlightData {
  geneSymbol: string;
  sigName: string;
}

export interface GeneCurationHighlightData {
  variant_inheritance_model?: string;
  disease_inheritance_model?: string;
  disease?: string;
  gene_symbol?: string;
}

export interface HomologyRegionHighlightData {
  sequences: {
    sequence: string;
    percentage: number;
    pseudo_gene: string;
  }[];
}

export interface OmimConditionHighlightData {
  conditions: string[];
}

export interface GeneHighlightData {
  description: string;
}

export interface TextUserAnnotationHighlightData {
  description: string;
}

export interface EnigmaClinvarSubmissionHighlightData {
  clinvar_classification: string;
}

export interface EcsDiseaseHighlightData {
  disease: string;
}

export interface ClingenDosageSensitivityHighlightData {
  genes: string[];
  sensitivity_type: string;
}

export interface SvPanelGenesHighlightData {
  genes: string[];
  panel_names: string[];
}

export interface DragenMnvTagHighlightData {
  tag: string;
}

export interface VariantHighlight {
  type: VariantHighlightType;
  data?:
    | ArticlesHighlightData
    | SuspectedGermlineHighlightData
    | DrugResponsiveHighlightData
    | CosmicHighlightData
    | ClassificationHighlightData
    | ReportedHighlightData
    | InheritanceModelHighlightData
    | PhenotypesHighlightData
    | ClassificationSigHighlightData
    | CuratedGeneHighlightData
    | SuggestedGeneHighlightData
    | GeneCurationHighlightData
    | HomologyRegionHighlightData
    | OmimConditionHighlightData
    | GeneHighlightData
    | TextUserAnnotationHighlightData
    | EnigmaClinvarSubmissionHighlightData
    | EcsDiseaseHighlightData
    | ClingenDosageSensitivityHighlightData
    | SvPanelGenesHighlightData
    | DragenMnvTagHighlightData;
}

export enum VariantHighlightType {
  Classification = 'classification',
  Reported = 'reported',
  InheritanceModel = 'inheritance_model',
  Phenotypes = 'phenotypes',
  ClassificationSig = 'classification-sig',
  CuratedGeneSig = 'curated-sig',
  SuggestedGeneSig = 'suggested-sig',
  GeneCuration = 'gene_curation',
  SecondaryFinding = 'secondary_finding',
  HomologyRegion = 'homology_region',
  OmimCondition = 'omim_condition',
  Articles = 'articles',
  Cosmic = 'cosmic',
  SuspectedGermline = 'suspected_germline',
  DrugResponsive = 'drug_responsive',
  Gene = 'gene',
  TextUserAnnotation = 'text_user_annotation',
  EnigmaClinvarSubmissionHighlight = 'enigma_clinvar_submission_highlight',
  EcsDisease = 'ecs_disease',
  ClingenDosageSensitivity = 'clingen_dosage_sensitivity',
  NonCanonicalTranscript = 'non_canonical_transcript',
  MMR = 'mmr',
  SvPanelGenes = 'sv_panel_genes',
  SvPanelGenesHighlight = 'sv_panel_genes_highlight',
  DragenHomology = 'dragen_homology',
  DragenMosaic = 'dragen_mosaic',
  DragenMnvTag = 'dragen_mnv_tag',
}
