import { Component, Input, OnInit } from '@angular/core';
import { Citation } from '../../../store/models/citation.model';
import { MomentModule } from 'ngx-moment';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'gnx-citations-tile',
  templateUrl: './citations-tile.component.html',
  styleUrls: ['./citations-tile.component.scss'],
  standalone: true,
  imports: [MatTooltip, MomentModule],
})
export class CitationsTileComponent implements OnInit {
  @Input() citation: Citation;

  constructor() {}

  ngOnInit(): void {}
}
