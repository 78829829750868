import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, map, mergeMap, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { JsonService } from '../../../shared/services/json.service';
import * as fromActions from '../actions/export-as-excel.action';
import { ExportAsExcelDialogComponent } from '../../components/export-as-excel-dialog/export-as-excel-dialog.component';
import { VariantsState } from '../reducers';
import { parseDbParams, toQueryParams } from '../../../shared/utils/parse.utils';
import { OpenSnackbar } from '../../../../../../store/actions/snackbar.actions';
import { SnackbarMode } from '../../../../../../store/models/snackbar.model';
import {
  ExportAsExcelModel,
  MAX_VARIANTS_EXPORT_EXCEL_EMAIL,
  MAX_VARIANTS_EXPORT_EXCEL_EMAIL_TUMOR,
} from '../../models/export-as-excel.model';
import { AnalysisDetails, AnalysisType, OpenSaveFileLinkWindow, OpenSaveFileWindow } from '../../../../../../store';
import { DatePipe } from '@angular/common';
import { getAnalysisVariantsCount } from '../../../workbench/store/selectors';
import { AppModuleState } from '../../../../../../store/reducers';
import { getAnalysisDetails, getAnalysisId, getParams, getQueryParams } from '../../../../../../store/selectors';
import { FiltersService, FiltersState, getFlattenedFiltersStructureEntities } from '../../../filters/store';

@Injectable()
export class ExportAsExcelEffect {
  constructor(
    private actions$: Actions,
    private store$: Store<VariantsState>,
    private rootStore$: Store<AppModuleState>,
    private filtersStore$: Store<FiltersState>,
    private service: JsonService,
    private dialog: MatDialog,
    private filterService: FiltersService,
  ) {}

  openExportExcelDialog$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.OPEN_EXPORT_EXCEL_DIALOG),
        tap(() =>
          this.dialog.open(ExportAsExcelDialogComponent, {
            width: '500px',
            position: { top: '30px' },
            panelClass: 'paddingless-dialog',
          }),
        ),
      ),
    { dispatch: false },
  );

  getVariantsExcel$: Observable<fromActions.GetVariantsExcelSuccess | fromActions.GetVariantsExcelFail> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.GET_VARIANTS_EXCEL),
        withLatestFrom(
          this.rootStore$.pipe(select(getQueryParams)),
          this.rootStore$.pipe(select(getAnalysisId)),
          this.store$.pipe(select(getAnalysisVariantsCount)),
          this.rootStore$.pipe(select(getParams)),
          this.rootStore$.pipe(select(getAnalysisDetails)),
          this.filterService.getActivePredefinedFilters$().pipe(startWith([])),
          this.filterService.getActivePredefinedFiltersTreeIds$().pipe(startWith([])),
          this.filtersStore$.pipe(select(getFlattenedFiltersStructureEntities)),
        ),
        mergeMap(
          ([
            action,
            params,
            analysisId,
            count,
            { variantType },
            analysis,
            selectedQuickFilters,
            selectedTreeIds,
            filtersStructure,
          ]) => {
            params = {
              selected_quick_filter_id: selectedQuickFilters,
              selected_filter_tree_id: selectedTreeIds,
              ...toQueryParams(parseDbParams(params, filtersStructure)),
              page_size: count.number_of_results,
              curr_page: 1,
              csv: 1,
            };
            return this.service
              .getAnalysisVariantsExcel(
                params,
                analysisId,
                variantType,
                analysis.analysis_type === AnalysisType.tumor,
                analysis.analysis_type === AnalysisType.tumor
                  ? MAX_VARIANTS_EXPORT_EXCEL_EMAIL_TUMOR
                  : MAX_VARIANTS_EXPORT_EXCEL_EMAIL,
              )
              .pipe(
                delay(200),
                map((response) =>
                  'error_message' in response
                    ? new fromActions.GetVariantsExcelFail(response.error_message)
                    : new fromActions.GetVariantsExcelSuccess(response),
                ),
              );
          },
        ),
        catchError((error) => of(new fromActions.GetVariantsExcelFail(error))),
      ),
  );

  getVariantsExcelSuccess$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.GET_VARIANTS_EXCEL_SUCCESS),
      map((action: fromActions.GetVariantsExcelSuccess) => action.payload),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisDetails))),
      switchMap(([response, analysis]: [any | ExportAsExcelModel, AnalysisDetails]) => {
        if ('exportSucceeded' in response) {
          return response.exportSucceeded
            ? of(new fromActions.OpenExportExcelDialog())
            : of(new fromActions.GetVariantsExcelFail('Error exporting analysis variants'));
        } else {
          if (analysis.analysis_type === AnalysisType.tumor) {
            return of(new OpenSaveFileLinkWindow(response.url, 'variants-' + analysis.name + '.csv'));
          } else {
            return of(new OpenSaveFileWindow(response, 'variants-' + analysis.name + '.csv'));
          }
        }
      }),
      catchError((error) => of(new fromActions.GetVariantsExcelFail(error))),
    ),
  );

  getVariantsExcelFail$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.GET_VARIANTS_EXCEL_FAIL),
      switchMap(() => of(new OpenSnackbar('Error! Export analysis variants failed', SnackbarMode.Error))),
    ),
  );
}
