import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { from, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MultipartUpload, SingleUpload } from '../models/upload.model';
import * as JSZip from 'jszip';
import { map } from 'rxjs/operators';

// import { saveAs } from 'file-saver';

@Injectable()
export class FileService {
  constructor(private http: HttpClient) {}

  public getUploadUrls(fileName: string, partsCount: number): Observable<MultipartUpload> {
    return this.http.post<MultipartUpload>(`${environment.beBaseURL}/api/file/upload/multipart`, {
      file_name: fileName,
      num_of_parts: partsCount,
    });
  }

  public getUploadUrl(fileName: string): Observable<SingleUpload> {
    return this.http.post<SingleUpload>(`${environment.beBaseURL}/api/file/upload`, {
      file_name: fileName,
    });
  }

  public zipFiles(files: { name?: string; file: File }[], zipName: string): Observable<File> {
    const zipFile: JSZip = new JSZip();
    const hasRootInPath = !files.some((x) => x.name.split('/')?.[0] !== files[0].name.split('/')?.[0]);
    for (const file of files) {
      zipFile.file(hasRootInPath ? file.name.substring(file.name.indexOf('/') + 1) : file.name, file.file);
    }

    return from(zipFile.generateAsync({ type: 'blob', compression: 'DEFLATE', compressionOptions: { level: 6 } })).pipe(
      map((blobData: Blob) => {
        const b: any = blobData;
        b.lastModifiedDate = new Date();
        b.name = zipName;
        // saveAs(b as File, 'blob-file.zip');
        return b as File;
      }),
    );
  }
}
