import { Action } from '@ngrx/store';
import { NavigationEnd, Params } from '@angular/router';

export const SET_CURRENT_ROUTE = '[Root] Set Current Route';

export class SetCurrentRoute implements Action {
  readonly type = SET_CURRENT_ROUTE;

  constructor(public payload: NavigationEnd) {}
}

export const SET_PREVIOUS_ROUTE = '[Root] Set Previous Route';

export class SetPreviousRoute implements Action {
  readonly type = SET_PREVIOUS_ROUTE;

  constructor(public payload: NavigationEnd) {}
}

export const CLEAR_QUERY_PARAMS = '[Root] Clear query params';
export const UPDATE_QUERY_PARAMS = '[Root] Update query param';

export class UpdateQueryParams implements Action {
  readonly type = UPDATE_QUERY_PARAMS;

  constructor(
    public payload: Params,
    public rewrite?: boolean,
  ) {}
}

export class ClearQueryParams implements Action {
  readonly type = CLEAR_QUERY_PARAMS;

  constructor() {}
}

export type RouteAction = SetCurrentRoute | SetPreviousRoute | UpdateQueryParams | ClearQueryParams;
