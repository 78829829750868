import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as fromAppStore from '../../../store';
import { ClearSearchVariantError, getSearchTerm, SearchVariant, VariantId } from '../../../store';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { filter, take, takeUntil } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';
import { SearchConfig } from '../../../modules/variant-page/modules/variant-page/models';
import { AuthModuleState } from '../../../modules/auth0/store/reducers';
import { QuestionnaireComponent } from '../../containers/questionnaire/questionnaire.component';
import { I18nPipe } from '../../i18n/i18n.pipe';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { DotsLoaderComponent } from '../dots-loader/dots-loader.component';
import { ClickCursorDirective } from '../../directives/click-cursor.directive';
import { RtlDirective } from '../../i18n/rtl.directive';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'gnx-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    RtlDirective,
    NgClass,
    ClickCursorDirective,
    DotsLoaderComponent,
    ReactiveFormsModule,
    MatSelect,
    MatOption,
    QuestionnaireComponent,
    AsyncPipe,
    I18nPipe,
  ],
})
export class SearchBarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('questionnaire') questionnaireRef: QuestionnaireComponent;

  public searching$: Observable<boolean>;
  public searchDone$: Observable<boolean>;
  public searchError$: Observable<string>;
  public searchResult$: Observable<VariantId>;

  destroy$: Subject<void> = new Subject<void>();
  searchControl: FormControl;
  searchContext: SearchConfig;

  constructor(
    private store$: Store<fromAppStore.AppModuleState>,
    private authStore$: Store<AuthModuleState>,
    private fb: FormBuilder,
    private router: Router,
  ) {}

  ngOnInit() {
    this.searchControl = this.fb.control('');

    this.store$
      .pipe(select(getSearchTerm), take(1))
      .subscribe((term) => this.searchControl.setValue(term, { emitEvent: false }));
    this.searching$ = this.store$.pipe(select(fromAppStore.getSearching));
    this.searchDone$ = this.store$.pipe(select(fromAppStore.getSearchDone));
    this.searchError$ = this.store$.pipe(select(fromAppStore.getSearchError));
    this.searchResult$ = this.store$.pipe(select(fromAppStore.getSearchResult));

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationStart),
        takeUntil(this.destroy$),
      )
      .subscribe((e) => {
        this.searchContext = this.searchContext?.zygosity === 'TUMOR' ? { zygosity: 'TUMOR' } : null;
        const currentValue = this.searchControl.value;
        this.searchControl.setValue('');
        this.searchControl.setValue(currentValue, { emitEvent: false });
        this.searchControl.markAsPristine();
      });
  }

  ngAfterViewInit() {
    this.questionnaireRef.setIsTumor(this.isTumor);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  get hgValue(): string {
    return localStorage.getItem('lastHGValue') || 'hg19';
  }

  set hgValue(value: string) {
    localStorage.setItem('lastHGValue', value);
    if (this.searchControl.pristine) {
      this.onSearch();
    }
  }

  get isTumor(): boolean {
    return localStorage.getItem('isTumorSearch') === 'true';
  }

  set isTumor(value: boolean) {
    localStorage.setItem('isTumorSearch', `${value}`);
    this.questionnaireRef.setIsTumor(value);
    if (this.searchControl.pristine) {
      this.onSearch();
    }
  }

  onSearch(redirect = true) {
    if (this.searchControl.value) {
      setTimeout(() => {
        this.store$.dispatch(
          new SearchVariant(this.searchControl.value, redirect, this.hgValue, {
            ...this.searchContext,
            search_term: this.searchControl.value,
          }),
        );
      });
    } else {
      this.onEscape();
    }
  }

  onEscape() {
    this.searchControl.setValue('', { emitEvent: false });
    this.searchContext = this.searchContext?.zygosity === 'TUMOR' ? { zygosity: 'TUMOR' } : null;
    this.store$.dispatch(new ClearSearchVariantError());
  }

  onContextChange(context: SearchConfig) {
    this.searchContext = context;
  }

  onPaste() {
    setTimeout(() => {
      this.searchControl.markAsDirty();
      this.searchControl.updateValueAndValidity();
    });
  }
}
