// inspired by this blog post - https://medium.com/@amcdnl/reactive-angular-forms-with-ngrx-533a2f28c127
// connects a reactive form to some data object, useful for connecting with ngrx store.
// note though it could of course only support defined structure (and thus no FormArray for example)

import { Directive, Input } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[gnxConnectForm]',
  exportAs: 'gnxConnectForm',
  standalone: true,
})
export class ConnectFormDirective {
  @Input('gnxConnectForm')
  set data(val: any) {
    if (val) {
      this.formGroupDirective.form.patchValue(val);
      // this.formGroupDirective.form.markAsPristine();
    }
  }

  constructor(private formGroupDirective: FormGroupDirective) {}
}
