<div class="dialog-header-container">
  <div class="small-header-label">NOT RELEVANT</div>
  <div class="large-header-label">Choose Reasoning</div>
  <img class="close-btn" src="assets/svg/x-icon.svg" width="14" height="14" (click)="onClose()" />
</div>
<mat-dialog-content class="dialog-body" [formGroup]="form">
  <div class="input-and-label">
    <label> Reasoning </label>
    <mat-select
      formControlName="reasons"
      panelClass="publications-sort-select-panel"
      placeholder="Choose"
      multiple="true"
    >
      <mat-option *ngFor="let option of data.reasoningOptions" [value]="option">{{ option }}</mat-option>
    </mat-select>
  </div>
  <div class="input-and-label">
    <label> Details </label>
    <textarea formControlName="details" rows="3" placeholder="Add more information"> </textarea>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <a (click)="onClose()">Cancel</a>
  <button cdkFocusInitial [mat-dialog-close]="form.value" [disabled]="form.invalid">Apply</button>
</mat-dialog-actions>
