import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { VariantEvidenceOptionModel } from '../models/variant-evidences-selection.nodel';
import { AnalysisType } from '../models';
import { SearchConfig, variantIdToBody2 } from '../../modules/variant-page/modules/variant-page/models';

@Injectable()
export class VariantEvidencesSelectionService {
  constructor(private http: HttpClient) {}

  getVariantEvidencesOptions(
    analysisType?: AnalysisType,
    annotationVersion?: number,
    analysisId?: number,
    assayId?: string,
  ): Observable<{
    categories: VariantEvidenceOptionModel[];
  }> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const reqParams: any = {
      analysis_type: analysisType,
    };
    if (analysisType) {
      if (annotationVersion) {
        reqParams.annotations_version = annotationVersion;
      }
      if (analysisId) {
        reqParams.analysis_id = analysisId;
      }
      if (assayId) {
        reqParams.assay_id = assayId;
      }
    }
    const params = new HttpParams({
      fromObject: {
        ...reqParams,
      },
    });

    return this.http.get(`${environment.beBaseURL}/api/interpretation/evidence/options`, {
      headers,
      params,
    }) as Observable<{ categories: VariantEvidenceOptionModel[] }>;
  }

  saveEvidencesForVariant(
    analysisType: AnalysisType,
    assayId: string,
    values,
    caseContext: SearchConfig,
    varIdObj,
    analysisId: number,
  ): Observable<{
    categories: VariantEvidenceOptionModel[];
  }> {
    return this.http.put(`${environment.beBaseURL}/api/org/interpretation/evidence`, {
      // analysis_id: analysisId?.toString(),
      evidence: {
        categories: values.variantEvidenceValues,
        details: values.details,
      },
      assay_id: assayId,
      analysis_type: analysisType,
      analysis_id: analysisId,
      ...variantIdToBody2(varIdObj, false, true),
      case_context: { ...caseContext },
    }) as Observable<{ categories: VariantEvidenceOptionModel[] }>;
  }
}
