import { Analysis, DataModel } from '../models';
import { Action } from '@ngrx/store';

export const LOAD_ANALYSES = '[Analyses] Load analyses';
export const LOAD_ANALYSES_NEXT_PAGE = '[Analyses] Load analyses next page';
export const LOAD_ANALYSES_FAIL = '[Analyses] Load analyses fail';
export const LOAD_ANALYSES_SUCCESS = '[Analyses] Load analyses success';

export class LoadAnalyses implements Action {
  readonly type = LOAD_ANALYSES;

  constructor(
    public ordering: string,
    public search: string,
    public assignedTo: string[],
    public status: string[],
    public assay: string[],
    public customLabel: string[],
    public activeStep: string[],
    public polling?: boolean,
    public customData?: { [key: string]: any },
  ) {}
}

export class LoadAnalysesNextPage implements Action {
  readonly type = LOAD_ANALYSES_NEXT_PAGE;
}

export class LoadAnalysesFail implements Action {
  readonly type = LOAD_ANALYSES_FAIL;

  constructor(public payload: any) {}
}

export class LoadAnalysesSuccess implements Action {
  readonly type = LOAD_ANALYSES_SUCCESS;

  constructor(public payload: DataModel<Analysis>) {}
}

export const START_ANALYSIS_PROGRESS_POLLING = '[Analyses] Start analysis progress polling';
export const STOP_ANALYSIS_PROGRESS_POLLING = '[Analyses] Stop analysis progress polling';
export const ANALYSIS_PROGRESS_POLLING_SUCCESS = '[Analyses] Analysis progress polling success';

export class StartAnalysisProgressPolling implements Action {
  readonly type = START_ANALYSIS_PROGRESS_POLLING;

  constructor() {}
}

export class StopAnalysisProgressPolling implements Action {
  readonly type = STOP_ANALYSIS_PROGRESS_POLLING;

  constructor() {}
}

export class ProgressPollingSuccess implements Action {
  readonly type = ANALYSIS_PROGRESS_POLLING_SUCCESS;

  constructor(public results: Analysis[]) {}
}

export type AnalysisListAction =
  | LoadAnalyses
  | LoadAnalysesSuccess
  | LoadAnalysesFail
  | LoadAnalysesNextPage
  | ProgressPollingSuccess;
