import { Injectable } from '@angular/core';
import * as fromModels from '../models';
import {
  AllSomaticBiomarkerResponse,
  AnalysisAdditionalInformationGermlineBiomarker,
  AnalysisAdditionalInformationTypesResponse,
  AnalysisFileForDownload,
  AnalysisGermlineBiomarkerType,
  BatchQcExportResponse,
  BioMarkerIrrelevantData,
  CaseFilterData,
  CaseFilterType,
  CaseProtocol,
  CaseProtocolStep,
  CaseSummary,
  ExportFamilyCarrierTests,
  ResultsFile,
  SomaticBiomarkerUserData,
  UpdateBinOrderingRequest,
  VariantList,
  VariantType as FilterVariantType
} from '../models';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  AnalysisResult,
  analysisResultToVariantPageVariantId,
  AnalysisSingleVariant,
  ECSResult,
  VariantLabel,
  VariantType
} from '../../../analysis-variant/models';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../../../environments/environment';
import { SearchConfig } from '../../../../../variant-page/modules/variant-page/models';
import { SomaticVariantInterpretationUpdate } from '../models/variant-interpretation.model';
import { AnalysisDetails, AnalysisType, SnpVariantId, SvVariantId } from '../../../../../../store';
import { CaseHistoryRecord } from '../../../../models';
import { Linkout } from '../../../../../../store/models/variant-linkouts.model';

@Injectable({ providedIn: 'root' })
export class WorkbenchService {
  constructor(private http: HttpClient) {}

  getConfig(analysisId: number): Observable<fromModels.WorkbenchConfig> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.beBaseURL}/api/api/analysis/workbench/${analysisId}/config`;

    return this.http.get<fromModels.WorkbenchConfig>(url, { headers });
  }

  getAnalysisResults(
    analysisId: number,
    phenotypes: string[],
    cancerType?: string,
    panels?: string[],
  ): Observable<AnalysisResult[] | ECSResult[]> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.beBaseURL}/api/api/analysis/workbench/${analysisId}/variants`;
    // const url = `assets/json/workbench-module/workbench-results.json`;
    // const url = `assets/json/workbench-module/workbench-somatic-results.json`;
    const params: any = { ...(!!cancerType ? { cancer_type: cancerType } : { phenotypes }) };
    params.kb_panels = panels;
    return this.http.get<AnalysisResult[]>(url, { headers, params });
  }

  getPatientInfo(analysisId: number): Observable<SearchConfig> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.beBaseURL}/api/analysis/case_details?analysis_id=${analysisId}`;

    return this.http.get<SearchConfig>(url, { headers });
  }

  getCaseLinkouts(analysisId: number): Observable<Linkout[]> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.beBaseURL}/api/analysis/linkouts?analysis_id=${analysisId}`;

    return this.http.get<{ linkouts: Linkout[] }>(url, { headers }).pipe(map((result) => result.linkouts));
  }

  getPedigreeUrl(analysisId: number): Observable<{ url: string }> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.beBaseURL}/api/analysis/pedigree_tree?analysis_id=${analysisId}`;

    return this.http.get<{ url: string }>(url, { headers });
  }

  editPatientInfo(analysisId: number, caseContext: SearchConfig, customData?: any): Observable<SearchConfig> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.beBaseURL}/api/analysis/case_details`;

    return this.http.post<SearchConfig>(
      url,
      {
        analysis_id: analysisId,
        case_context: caseContext,
        custom_data: customData,
      },
      { headers },
    );
  }

  getQualityControl(analysisId: number): Observable<fromModels.SampleQC[]> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.beBaseURL}/api/analysis/${analysisId}/qc`;
    // const url = 'assets/json/workbench-module/quality-control-single.json';

    return this.http.get<fromModels.SampleQC[]>(url, { headers });
  }

  exportQualityControl(analysisId: number): Observable<any> {
    const url = `${environment.beBaseURL}/api/analysis/${analysisId}/qc/txt`;
    return this.http.get(url, { responseType: 'blob' });
  }

  exportFilteredVariants(analysisId: number): Observable<any> {
    const url = `${environment.beBaseURL}/api/export/analysis/quick_filters`;
    const body = {
      analysis_id: analysisId,
    };
    return this.http.post(url, body);
  }

  checkExportQCBatch(analysisId: number, batchId: number): Observable<BatchQcExportResponse> {
    const url = `${environment.beBaseURL}/api/analysis/batch/qc_file`;
    const params = new HttpParams().set('analysis_id', analysisId.toString()).set('batch_id', batchId.toString());
    return this.http.get<BatchQcExportResponse>(url, { params });
  }

  checkExportControlSampleReport(analysisId: number): Observable<BatchQcExportResponse> {
    const url = `${environment.beBaseURL}/api/analysis/control/qc_file`;
    const params = new HttpParams().set('analysis_id', analysisId.toString());
    return this.http.get<BatchQcExportResponse>(url, { params });
  }

  checkExportQcSnpProfileResults(analysisId: number): Observable<BatchQcExportResponse> {
    const url = `${environment.beBaseURL}/api/analysis/tumor_snp_profiling/qc_file`;
    const params = new HttpParams().set('analysis_id', analysisId.toString());
    return this.http.get<BatchQcExportResponse>(url, { params });
  }

  // getFamilyMembers(analysisId: number): Observable<fromModels.FamilyMembersResponse> {
  //   const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
  //   const url = this.serviceConfig.getFamilyMembersUrl.replace(':analysisId', analysisId);
  //
  //   return this.http.get<fromModels.FamilyMembersResponse>(url, {headers});
  // }

  getReferralNotes(analysisId: number): Observable<fromModels.ReferralNotes> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.beBaseURL}assets/json/workbench-module/referral-notes.json`;

    return this.http.get<fromModels.ReferralNotes>(url, { headers });
  }

  getAnalysisFileDownloadUrls(analysisId: number, fileType: string): Observable<AnalysisFileForDownload[]> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.beBaseURL}/api/api/analysis/${analysisId}/${fileType}/urls`;

    return this.http
      .get<AnalysisFileForDownload[]>(url, { headers })
      .pipe(map((files) => files.filter((item, i) => files.findIndex((t) => t.url === item.url) === i)));
  }

  getAnalysisSampleFileDownloadUrl(sampleId: number, fileId: string, fileType: string): Observable<{ download_link: string }> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.beBaseURL}/api/api/sample/${sampleId}/sample_file_download_url/`;

    const params = new HttpParams().set('file_id', fileId).set('file_type', fileType);

    return this.http.get<{ download_link: string }>(url, { headers, params });
  }

  getExportResultsUrlsSomatic(
    analysisId: number,
    disease?: string,
    assayId?: string,
    annotationConfig?: {
      snp_annotation_version?: number;
      sv_annotation_version?: number;
      rna_annotation_version?: number;
    },
  ): Observable<ResultsFile> {
    const url = `${environment.beBaseURL}/api/export/somatic/workbench_report`;
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<ResultsFile>(url, {
      analysis_id: analysisId,
      ...annotationConfig,
      disease,
      assay_id: assayId,
    });
  }

  getExportResultsUrlsGermline(
    analysisId: number,
    sampleIds: string[],
    phenotypes?: string[],
    assayId?: string,
    annotationConfig?: { snp_annotation_version?: number; sv_annotation_version?: number },
    caseContext?: SearchConfig,
  ): Observable<ResultsFile> {
    const url = `${environment.beBaseURL}/api/export/germline/workbench`;
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<ResultsFile>(url, {
      analysis_id: analysisId,
      sample_ids: sampleIds,
      ...annotationConfig,
      phenotypes,
      assay_id: assayId,
      case_context: caseContext,
    });
  }

  getExportMaccabiAnalysisSummaryUrls(analysisId: number): Observable<ResultsFile> {
    const url = `${environment.beBaseURL}/api/plugins/maccabi_analysis_summary?analysis=${analysisId}`;
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get<ResultsFile>(url, { headers });
  }

  getExportLabeledVariantsUrls(analysisId: number, label: VariantLabel): Observable<ResultsFile> {
    const url = `${environment.beBaseURL}/api/export/labeled_variants`;

    return this.http.post<ResultsFile>(url, {
      analysis_id: analysisId,
      label_to_export: label,
    });
  }

  getFamilyCarrierTestDownloadUrl(body: ExportFamilyCarrierTests): Observable<string> {
    const url = `${environment.beBaseURL}/api/analysis/family/carrier_screening`;

    return this.http.post(url, body, { responseType: 'text' });
  }

  getClinicalSignificanceGroups(
    analysis: AnalysisDetails,
  ): Observable<{ section_name: string; section_display_name: string }[]> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.beBaseURL}/api/clinical_significance/sections`;
    let params = new HttpParams().set('analysis_id', analysis.id);
    if (analysis.assay?.uuid) {
      params = params.set('assay_id', analysis.assay.uuid);
    }

    return this.http
      .get<{ sections: { section_name: string; section_display_name: string }[] }>(url, { headers, params })
      .pipe(map(({ sections }) => sections));
  }

  getClinicalSignificanceReasoningOptions(assayId: string): Observable<string[]> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.beBaseURL}/api/clinical_significance/get_reasoning_options`;

    const params: HttpParams = new HttpParams().set('assay_id', assayId);

    return this.http
      .get<{ reasoning_options: string[] }>(url, { headers, params })
      .pipe(map(({ reasoning_options }) => reasoning_options));
  }

  setVariantClinicalSignificance(
    varType: VariantType,
    varId: string,
    analysisId: number,
    significance: string,
    reasonings?: string[],
    textInfo?: string,
  ): Observable<any> {
    let url: string;
    let body: object;

    if (varType === VariantType.Compound) {
      const varIds = varId.split('_');
      url = `${environment.beBaseURL}/api/clinical_significance/set_compound_variant`;
      body = {
        first_var_id: varIds[0],
        second_var_id: varIds[1],
        analysis_id: analysisId.toString(),
        clinical_significance: significance,
      };
    } else {
      url = `${environment.beBaseURL}/api/clinical_significance/set_variant`;
      body = { var_id: varId, analysis_id: analysisId.toString(), clinical_significance: significance };
    }

    if (reasonings) {
      body = { ...body, reasonings };
    }
    if (textInfo) {
      body = { ...body, text_info: textInfo };
    }

    return this.http.post(url, body);
  }

  clearVariantClinicalSignificance(varType: VariantType, varId: string, analysisId: number): Observable<any> {
    let url: string;
    let body: object;

    if (varType === VariantType.Compound) {
      const varIds = varId.split('_');
      url = `${environment.beBaseURL}/api/clinical_significance/clear_compound_variant`;
      body = { first_var_id: varIds[0], second_var_id: varIds[1], analysis_id: analysisId.toString() };
    } else {
      url = `${environment.beBaseURL}/api/clinical_significance/clear_variant`;
      body = { var_id: varId, analysis_id: analysisId.toString() };
    }

    return this.http.post(url, body);
  }

  updateClinicalSignificanceOrdering(
    analysisId: number,
    significanceOrderedLists: UpdateBinOrderingRequest[],
  ): Observable<any> {
    const url = `${environment.beBaseURL}/api/workbench/bins/update_ordering`;
    const body = {
      bins_ordering_to_update: significanceOrderedLists.filter((x) => x.bin_name),
      analysis_id: analysisId,
    };

    return this.http.post(url, body);
  }

  getSomaticBiomarkerData(analysisId: number, diseases: string[]): Observable<AllSomaticBiomarkerResponse> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.beBaseURL}/api/somatic/sample/biomarkers/evidences`;
    const params: HttpParams = new HttpParams({
      fromObject: {
        analysis_id: analysisId,
        disease: diseases.filter((d) => !!d),
      },
    });

    return this.http.get<AllSomaticBiomarkerResponse>(url, { headers, params });
  }

  includeInReportSomatic(payload: SomaticVariantInterpretationUpdate) {
    const url = `${environment.beBaseURL}/api/somatic/variant/interpretation`;

    return this.http.patch(url, payload);
  }

  getSomaticBiomarkerUserData(analysisId: number): Observable<SomaticBiomarkerUserData[]> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.beBaseURL}/api/somatic/biomarkers/user_interpretation`;
    const params = new HttpParams().set('analysis_id', analysisId.toString());

    return this.http
      .get<{ biomarkers_data: SomaticBiomarkerUserData[] }>(url, { headers, params })
      .pipe(map((data) => data?.biomarkers_data || []));
  }

  updateSomaticBiomarkerUserData(analysisId: number, data: SomaticBiomarkerUserData): Observable<{ success: boolean }> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const url = data.somatic_classification
      ? `${environment.beBaseURL}/api/somatic/biomarkers/user_interpretation`
      : `${environment.beBaseURL}/api/somatic/biomarkers/report_exclusion`;

    const body = {
      analysis_id: analysisId,
      ...data,
    };

    return this.http.post<{ success: boolean }>(url, body, { headers });
  }

  upsertBiomarker(
    analysisId: number,
    biomarker: string,
    score: number,
    level: string,
  ): Observable<AllSomaticBiomarkerResponse> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.beBaseURL}/api/somatic/analysis/biomarker/edit`;

    const body = {
      analysis_id: analysisId,
      biomarker_type: biomarker,
      biomarker_score: score,
      biomarker_level: level,
    };

    return this.http.post<AllSomaticBiomarkerResponse>(url, body, { headers });
  }

  getCaseFilterData(): Observable<CaseFilterData> {
    const mockData = {
      [CaseFilterType.Default]: {
        [FilterVariantType.Gain]: {
          markerCount: 50,
          size: 400,
        },
        [FilterVariantType.Loss]: {
          markerCount: 50,
          size: 400,
        },
        [FilterVariantType.LOH]: {
          markerCount: 0,
          size: 0,
        },
      },
      [CaseFilterType.HighDetection]: {
        [FilterVariantType.Gain]: {
          markerCount: 50,
          size: 100,
        },
        [FilterVariantType.Loss]: {
          markerCount: 50,
          size: 100,
        },
        [FilterVariantType.LOH]: {
          markerCount: 0,
          size: 0,
        },
      },
    };
    return of(mockData);
  }

  saveCaseFilterData(data: CaseFilterData): Observable<CaseFilterData> {
    return of(data);
  }

  getAnalysisAdditionalInformationTypes(analysisId: number): Observable<AnalysisAdditionalInformationTypesResponse> {
    // const url = `assets/json/workbench-module/additional-information-types.json`;
    const url = `${environment.beBaseURL}/api/analysis/information/types`;
    const params = new HttpParams().set('analysis_id', analysisId.toString());
    return this.http.get<AnalysisAdditionalInformationTypesResponse>(url, { params });
  }

  getAnalysisAdditionalInformationGermlineBiomarkers(
    analysisId: number,
    biomarkerTypes: AnalysisGermlineBiomarkerType[],
  ): Observable<AnalysisAdditionalInformationGermlineBiomarker[]> {
    // const url = 'assets/json/upd.json';
    // const url = `assets/json/workbench-module/germline-biomarkers.json`;
    const url = `${environment.beBaseURL}/api/analysis/germline/biomarkers`;
    const params = new HttpParams({ fromObject: { analysis_id: analysisId, biomarker_type: biomarkerTypes } });
    return this.http
      .get<{ biomarkers: AnalysisAdditionalInformationGermlineBiomarker[] }>(url, { params })
      .pipe(map(({ biomarkers }) => biomarkers));
  }

  changeGermlineBiomarkerInReport(
    analysisId: number,
    biomarkerId: string,
    inReport: boolean,
  ): Observable<{
    success: boolean;
  }> {
    const url = `${environment.beBaseURL}/api/analysis/germline/biomarkers/report`;
    const body = {
      biomarker_id: biomarkerId,
      analysis_id: analysisId.toString(),
      include_in_report: inReport,
    };
    return this.http.post<{ success: boolean }>(url, body);
  }

  changeGermlineBiomarkerIrrelevant(
    analysisId: number,
    biomarkerId: string,
    irrelevantData: BioMarkerIrrelevantData,
  ): Observable<{ success: boolean }> {
    const url = `${environment.beBaseURL}/api/analysis/germline/biomarkers/irrelevant`;
    const body = {
      biomarker_id: biomarkerId,
      analysis_id: analysisId.toString(),
      irrelevant_data: irrelevantData,
    };
    return this.http.post<{ success: boolean }>(url, body);
  }

  getAnalysisCaseSummary(analysisId: number): Observable<CaseSummary> {
    // const url = `assets/json/upd.json`;
    const url = `${environment.beBaseURL}/api/case/summary`;
    const params = new HttpParams().set('analysis_id', analysisId.toString());
    return this.http.get<CaseSummary>(url, { params });
  }

  getAnalysisProtocols(analysisId: number): Observable<CaseProtocol[]> {
    const url = `${environment.beBaseURL}/api/analysis/protocols`;
    const params = new HttpParams().set('analysis_id', analysisId.toString());
    return this.http.get<CaseProtocol[]>(url, { params });
  }

  updateAnalysisProtocols(analysisId: number, protocols: CaseProtocol[]): Observable<CaseProtocol[]> {
    const url = `${environment.beBaseURL}/api/analysis/protocols`;
    const body = {
      analysis_id: analysisId,
      protocols,
    };
    return this.http.put<CaseProtocol[]>(url, body);
  }

  getProtocolStepOptions(): Observable<CaseProtocolStep[]> {
    const url = `${environment.beBaseURL}/api/analysis/protocols/analysis_by_steps_with_status/options`;
    return this.http.get<CaseProtocolStep[]>(url);
  }

  getCaseHistory(analysisId: number): Observable<CaseHistoryRecord[]> {
    const url = `${environment.beBaseURL}/api/analysis/activity`;
    const body = {
      analysis_id: analysisId,
    };
    return this.http.post<{ activity: CaseHistoryRecord[] }>(url, body).pipe(map(({ activity }) => activity));
  }

  setTreatmentInReport(analysisId: number, treatmentId: string, inReport: boolean): Observable<{ success: boolean }> {
    const url = `${environment.beBaseURL}/api/case/treatment/in_report`;
    const body = {
      analysis_id: analysisId,
      treatment_id: treatmentId,
      in_report: inReport
    };
    return this.http.post<{ success: boolean }>(url, body);
  }

  getVariantLists(
    analysisId: number,
    analysisType: AnalysisType,
    variantType: VariantType.SNP | VariantType.SV,
    referenceVersion: 'HG19' | 'HG38'
  ): Observable<VariantList[]> {
    const url = `${environment.beBaseURL}/api/analysis/exclusion_lists`;
    const params = new HttpParams()
      .set('analysis_id', analysisId.toString())
      .set('list_variant_type', variantType)
      .set('analysis_type', analysisType === AnalysisType.tumor ? 'SOMATIC' : 'GERMLINE')
      .set('reference_version', referenceVersion);
    return this.http
      .get<{ exclusion_lists: VariantList[] }>(url, { params })
      .pipe(map(({ exclusion_lists }) => exclusion_lists));
  }

  addToVariantLists(
    analysisId: number,
    analysisType: AnalysisType,
    variantType: VariantType.SNP | VariantType.SV,
    variants: AnalysisResult[],
    referenceVersion: 'HG19' | 'HG38',
    variantLists: string[],
  ): Observable<boolean> {
    const url = `${environment.beBaseURL}/api/variant_list/${variantType}/add_variants`;
    const body = {
      variants: variants.map((variant) => {
        const variantId = analysisResultToVariantPageVariantId(
          (variant as AnalysisSingleVariant).data,
          referenceVersion,
          undefined,
          analysisId,
          analysisType,
          variantType,
        );
        return variantType === VariantType.SNP ? {
          chr: variantId.chr,
          pos: (variantId as SnpVariantId).pos,
          ref: (variantId as SnpVariantId).ref,
          alt: (variantId as SnpVariantId).alt,
        } : {
          chr: variantId.chr,
          start: (variantId as SvVariantId).start_pos,
          end: (variantId as SvVariantId).end_pos,
        };
      }),
      variant_list_names: variantLists,
      reference_version: referenceVersion,
      analysis_type: analysisType === AnalysisType.tumor ? 'SOMATIC' : 'GERMLINE'
    };
    return this.http.post<boolean>(url, body);
  }

  removeFromVariantLists(
    analysisId: number,
    analysisType: AnalysisType,
    variantType: VariantType.SNP | VariantType.SV,
    variants: AnalysisResult[],
    referenceVersion: 'HG19' | 'HG38',
  ): Observable<boolean> {
    const url = `${environment.beBaseURL}/api/variant_list/${variantType}/remove_variants`;
    const body = {
      variants: variants.map((variant) => {
        const variantId = analysisResultToVariantPageVariantId(
          (variant as AnalysisSingleVariant).data,
          referenceVersion,
          undefined,
          analysisId,
          analysisType,
          variantType,
        );
        return variantType === VariantType.SNP ? {
          chr: variantId.chr,
          pos: (variantId as SnpVariantId).pos,
          ref: (variantId as SnpVariantId).ref,
          alt: (variantId as SnpVariantId).alt,
        } : {
          chr: variantId.chr,
          start: (variantId as SvVariantId).start_pos,
          end: (variantId as SvVariantId).end_pos,
        };
      }),
      variant_list_names: [],
      reference_version: referenceVersion,
      analysis_type: analysisType === AnalysisType.tumor ? 'SOMATIC' : 'GERMLINE'
    };
    return this.http.post<{ success: boolean }>(url, body).pipe(map(({ success }) => success));
  }
}
