import { createAction, props } from '@ngrx/store';
import { FilterTree, FilterTreeInfo, FilterTreeLabels, FilterTreeMetadata, FilterTreeOptions } from '../models/filter-tree.model';
import { VariantType } from '../../../analysis-variant/models';
import { AnalysisType } from '../../../../../../store';

export const loadFilterTreeOptions = createAction(
  '[Filters] Load Filter Tree Options',
  props<{ analysisType: AnalysisType; variantType: VariantType }>(),
);
export const loadFilterTreeOptionsSuccess = createAction(
  '[Filters] Load Filter Tree Options success',
  props<{ payload: FilterTreeOptions }>(),
);
export const loadFilterTreeOptionsFail = createAction(
  '[Filters] Load Filter Tree Options fail',
  props<{ error: any }>(),
);

export const loadFilterTreeLabels = createAction(
  '[Filters] Load Filter Tree Labels',
  props<{ analysisId: number; analysisType: AnalysisType; variantType: VariantType }>(),
);
export const loadFilterTreeLabelsSuccess = createAction(
  '[Filters] Load Filter Tree Labels success',
  props<{ payload: FilterTreeLabels }>(),
);
export const loadFilterTreeLabelsFail = createAction('[Filters] Load Filter Tree Labels fail', props<{ error: any }>());

export const loadFilterTreeDefinition = createAction(
  '[Filters] Load Filter Tree Definition',
  props<{ filterTreeId: string; analysisType: AnalysisType; variantType: VariantType }>(),
);

export const loadFilterDefinitionWithNewFilter = createAction(
  '[Filters] Load Filter Tree Definition With New Filter',
);

export const loadFilterTreeDefinitionSuccess = createAction(
  '[Filters] Load Filter Tree Definition success',
  props<{ payload: FilterTree }>(),
);
export const loadFilterTreeDefinitionFail = createAction(
  '[Filters] Load Filter Tree Definition fail',
  props<{ error: any }>(),
);

export const loadFilterTrees = createAction(
  '[Filters] Load Filter Trees'
);
export const loadFilterTreesSuccess = createAction(
  '[Filters] Load Filter Trees success',
  props<{ payload: FilterTreeInfo[] }>(),
);
export const loadFilterTreesFail = createAction('[Filters] Load Filter Trees fail', props<{ error: any }>());

export const createFilterTree = createAction(
  '[Filters] Create Filter Tree'
);

export const createFilterTreeSuccess = createAction(
  '[Filters] Create Filter Tree Success',
  props<{ tree: FilterTree; }>(),
);

export const createFilterTreeFail = createAction(
  '[Filters] Create Filter Tree Success'
);

export const saveNewFilterTree = createAction(
  '[Filters] Save New Filter Tree',
  props<{ tree: FilterTree; analysisType: AnalysisType; variantType: VariantType }>(),
);
export const saveNewFilterTreeSuccess = createAction(
  '[Filters] Save New Filter Tree success',
  props<{ tree_id: string, analysisType: AnalysisType; variantType: VariantType }>(),
);
export const saveNewFilterTreeFail = createAction('[Filters] Save New Filter Tree fail', props<{ error: any }>());

export const markFilterTreeVerified = createAction(
  '[Filters] Mark Filter Tree verified',
  props<{ filterTreeId: string; analysisId: number; value: boolean }>(),
);

export const editFilterTree = createAction(
  '[Filters] Edit Filter Tree',
  props<{ treeInfo: FilterTreeInfo }>()
);

export const editFilterTreeSuccess = createAction(
  '[Filters] Edit Filter Tree Success',
  props<{ tree: FilterTree; }>(),
);

export const editFilterTreeAbort = createAction(
  '[Filters] Edit Filter Tree Success',
);

export const saveUpdatedFilterTree = createAction(
  '[Filters] Save Updated Filter Tree',
  props<{ tree: FilterTree; analysisType: AnalysisType; variantType: VariantType }>(),
);
export const saveUpdatedFilterTreeSuccess = createAction(
  '[Filters] Save Updated Filter Tree success',
  props<{ tree: FilterTreeInfo, analysisType: AnalysisType; variantType: VariantType }>(),
);
export const saveUpdatedFilterTreeFail = createAction('[Filters] Save Updated Filter Tree fail', props<{ tree_id: string, error: any }>());

export const markFilterTreeVerifiedSuccess = createAction(
  '[Filters] Mark Filter Tree verified success',
  props<{ filterTreeId: string; analysisId: number; value: boolean }>(),
);

export const markFilterTreeVerifiedFail = createAction(
  '[Filters] Mark Filter Tree verified fail',
  props<{ filterTreeId: string; analysisId: number; error: any }>(),
);

export const deleteFilterTree = createAction(
  '[Filters] Delete Filter Tree',
  props<{ filterTreeId: string; analysisType: AnalysisType; variantType: VariantType }>()
);

export const deleteFilterTreeSuccess = createAction(
  '[Filters] Delete Filter Tree success',
  props<{ filterTreeId: string; analysisType: AnalysisType; variantType: VariantType }>()
);

export const deleteFilterTreeFail = createAction(
  '[Filters] Delete Filter Tree fail',
  props<{ filterTreeId: string; analysisType: AnalysisType; variantType: VariantType; error: any }>()
);

export const deleteFilterTreeAbort = createAction(
  '[Filters] Delete Filter Tree abort',
  props<{ filterTreeId: string; analysisType: AnalysisType; variantType: VariantType; }>()
);

export const cloneFilterTree = createAction(
  '[Filters] Clone Filter Tree',
  props<{ filterTreeId: string; analysisType: AnalysisType; variantType: VariantType; }>()
);
