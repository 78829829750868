import { Action } from '@ngrx/store';
import { AnalysisType } from '../../../../../../store/models';
import { VariantType } from '../../../analysis-variant/models';

export const LOAD_DEFAULT_FILTER = '[Filters] Load default filter';
export const LOAD_DEFAULT_FILTER_SUCCESS = '[Filters] Load default filter Success';
export const LOAD_DEFAULT_FILTER_FAIL = '[Filters] Load default filter fail';

export class LoadDefaultFilter implements Action {
  readonly type = LOAD_DEFAULT_FILTER;

  constructor(
    public analysisId?: number,
    public analysisType?: AnalysisType,
    public variantType?: VariantType,
  ) {}
}

export class LoadDefaultFilterSuccess implements Action {
  readonly type = LOAD_DEFAULT_FILTER_SUCCESS;

  constructor(public defaultFilter: any) {}
}

export class LoadDefaultFilterFail implements Action {
  readonly type = LOAD_DEFAULT_FILTER_FAIL;

  constructor(public error: any) {}
}

export type DefaultFilterAction = LoadDefaultFilter | LoadDefaultFilterSuccess | LoadDefaultFilterFail;
