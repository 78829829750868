import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { RichTemplateActions } from '../actions';
import { AppService } from '../services';

@Injectable()
export class RichTemplateEffects {
  constructor(
    private actions$: Actions,
    private service: AppService,
  ) {}

  loadRichTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RichTemplateActions.loadRichTemplates),
      mergeMap((action) =>
        this.service.getRichTemplates(action.section).pipe(
          map((data) => RichTemplateActions.loadRichTemplatesSuccess({ data })),
          catchError((error) => of(RichTemplateActions.loadRichTemplatesFailure({ error }))),
        ),
      ),
    );
  });
}
