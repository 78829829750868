import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthApiService } from '../../../modules/auth0/services/auth-api.service';

@Injectable()
export class HomePageInitParamsResolver {
  constructor(private authApiService: AuthApiService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.fragment?.length) {
      const queryParams = JSON.parse(
        '{"' + route.fragment.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === '' ? value : decodeURIComponent(value);
        },
      );
      if (queryParams.access_token) {
        const authData = this.authApiService.loginWithAzureToken(queryParams);
        const bc = new BroadcastChannel('azure-auth-popup-channel');
        bc.postMessage(authData);
        bc.close();
      }
    }
  }
}
