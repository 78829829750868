import { FilterSelection } from '../models/filter-selection.model';
import { ValueRange } from '../models/value-range';
import { Params } from '@angular/router';
import { FilterStructure } from '../models/filter-structure.model';

export const parseQueryParams = (queryParams: Params, structure: { [id: string]: FilterStructure }): { [id: string]: FilterSelection } => {
  const parsedParams: { [id: string]: FilterSelection } = {};
  for (const key of Object.keys(queryParams)) {
    parsedParams[key] = { id: key, value: parseQueryParam(queryParams[key], structure[key]) };
  }
  return parsedParams;
};

const parseQueryParam = (queryParamValue: any, filterDef: FilterStructure) => {
  if (isRangeArray(queryParamValue)) {
    return queryParamValue.map((value) => {
      return getValueRangeFromString(value);
    });
  }
  if (isRange(queryParamValue)) {
    return [getValueRangeFromString(queryParamValue)];
  }
  if (isArray(queryParamValue)) {
    return queryParamValue.map((value) => {
      return getValueFromString(value, filterDef);
    });
  }
  return [getValueFromString(queryParamValue, filterDef)];
};

const getValueFromString = (value: any, filterDef: FilterStructure) => {
  if (filterDef?.option_type !== 'string' && !isNaN(Number(value)) && !(!!value === value)) {
    return Number(value);
  }
  return value;
};

const getValueRangeFromString = (value): ValueRange => {
  const valueArr: string[] = value.split('__');
  return new ValueRange(Number(valueArr[0]), Number(valueArr[1]));
};

const isArray = (value): boolean => {
  return value instanceof Array;
};

const isRangeArray = (value): boolean => {
  return value instanceof Array && value.length > 0 && isRange(value[0]);
};

const isRange = (value): boolean => {
  return typeof value === 'string' && value.indexOf('__') >= 0;
};

export const parseDbParams = (params, structure: { [id: string]: FilterStructure }): FilterSelection[] => {
  const parsedParams: FilterSelection[] = [];
  for (const key of Object.keys(params)) {
    parsedParams.push({ id: key, value: parseParam(params[key], structure?.[key]) });
  }
  return parsedParams;
};

const parseParam = (paramValue: any, filterDef: FilterStructure) => {
  if (!paramValue) {
    return [];
  }
  if (isRangeObjectArray(paramValue)) {
    return paramValue.map((value: any) => {
      return getValueRangeFromObject(value);
    });
  }
  if (isRangeObject(paramValue)) {
    return [getValueRangeFromObject(paramValue)];
  }
  if (isArray(paramValue)) {
    return paramValue.map((value) => {
      return getValueFromString(value, filterDef);
    });
  }
  return [getValueFromString(paramValue, filterDef)];
};

const getValueRangeFromObject = (value): ValueRange => {
  return new ValueRange(value.f, value.t);
};

const isRangeObjectArray = (value): boolean => {
  return value instanceof Array && value.length > 0 && isRangeObject(value[0]);
};

const isRangeObject = (value): boolean => {
  return value.hasOwnProperty('f') && value.hasOwnProperty('t');
};

export const toQueryParams = (queryObject) => {
  return Object.keys(queryObject)
    .map((key) => queryObject[key])
    .reduce((params: { [id: string]: string }, filter: FilterSelection) => {
      return {
        ...params,
        [filter.id]: filter.value,
      };
    }, {});
};
