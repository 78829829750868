import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DotsLoaderComponent } from './components/dots-loader/dots-loader.component';
import { ArraySortPipe } from './pipes/array-sort.pipe';
import { TextOverflowEllipsisDirective } from './directives/text-overflow-ellipsis.directive';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { UnsupportedTranscriptDialogComponent } from './containers/unsupported-transcript-dialog/unsupported-transcript-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ConnectFormDirective } from './directives/connect-form.directive';
import { InlineSVGModule } from 'ng-inline-svg';
import { ChipsComponent } from './components/chips/chips.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MomentModule } from 'ngx-moment';
import { AnalysisService } from './services/analysis.service';
import { FileService } from './services/file.service';
import { FeedbackService } from './services/feedback.service';
import { PhenotypeOptionComponent } from './components/phenotype-option/phenotype-option.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { PrivacySidePanelComponent } from './components/privacy-side-panel/privacy-side-panel.component';
import { PrivacyLabelComponent } from './components/privacy-label/privacy-label.component';
import { BreadcrumbComponent } from './containers/breadcrumb/breadcrumb.component';
import { DownloadFileService } from './services/download-file.service';
import { LogService } from './services/log.service';
import { VariantTypeToggleComponent } from './components/variant-type-toggle/variant-type-toggle.component';
import { MatSelectModule } from '@angular/material/select';
import { ElementPermissionResolverDirective } from './directives/element-permission-resolver.directive';
import { FranklinChipComponent } from './components/franklin-chip/franklin-chip.component';
import { StripHtmlWithUnicodePipe } from './pipes/strip-html.pipe';
import { I18nModule } from './i18n/i18n.module';
import { FroalaEditorDirective } from './directives/froala-editor.directive';
import { ReverseIterablePipe } from './pipes/reverse-iterable.pipe';
import { ButtonComponent } from './components/button/button.component';
import { SmartCheckboxComponent } from './components/smart-checkbox/smart-checkbox.component';
import { AnalysisSearchBarComponent } from './components/analysis-search-bar/analysis-search-bar.component';
import { HighlightPipe } from './pipes/highlight.pipe';
import { ShimmerComponent } from './components/shimmer/shimmer.component';
import { FlexComponent } from './components/flex/flex.component';
import { FlexItemComponent } from './components/flex-item/flex-item.component';
import { TextComponent } from './components/text/text.component';
import { BlockComponent } from './components/block/block.component';
import { ExternalLinkComponent } from './components/external-link/external-link.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ExcludeCheckboxIconComponent } from './exclude-checkbox-icon/exclude-checkbox-icon.component';
import { SelectDirective } from './directives/select.directive';
import { InputDirective } from './directives/input.directive';
import { LabelDirective } from './directives/label.directive';
import { AnchorDirective } from './directives/anchor.directive';
import { BlockDirective } from './directives/block.directive';
import { FlexDirective } from './directives/flex.directive';
import { FlexItemDirective } from './directives/flex-item.directive';
import { TextDirective } from './directives/text.directive';
import { DndModule } from 'ngx-drag-drop';
import { GridDirective } from './directives/grid-directive';
import { TextAreaDirective } from './directives/text-area.directive';
import { GridItemDirective } from './directives/grid-item.directive';
import { ClickCursorDirective } from './directives/click-cursor.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MatTooltipModule,
    MatDialogModule,
    InlineSVGModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatAutocompleteModule,
    MomentModule,
    MatSelectModule,
    I18nModule,
    MatTabsModule,
    DndModule,
    CheckboxComponent,
    DotsLoaderComponent,
    ArraySortPipe,
    SafeHtmlPipe,
    StripHtmlWithUnicodePipe,
    TextOverflowEllipsisDirective,
    ConnectFormDirective,
    SearchBarComponent,
    AnalysisSearchBarComponent,
    UnsupportedTranscriptDialogComponent,
    ChipsComponent,
    PhenotypeOptionComponent,
    PrivacySidePanelComponent,
    PrivacyLabelComponent,
    BreadcrumbComponent,
    VariantTypeToggleComponent,
    ElementPermissionResolverDirective,
    FranklinChipComponent,
    FroalaEditorDirective,
    ReverseIterablePipe,
    ButtonComponent,
    SmartCheckboxComponent,
    HighlightPipe,
    ShimmerComponent,
    FlexComponent,
    FlexItemComponent,
    TextComponent,
    BlockComponent,
    ExternalLinkComponent,
    TabsComponent,
    ExcludeCheckboxIconComponent,
    SelectDirective,
    InputDirective,
    LabelDirective,
    AnchorDirective,
    BlockDirective,
    FlexDirective,
    FlexItemDirective,
    TextDirective,
    GridDirective,
    TextAreaDirective,
    GridItemDirective,
    ClickCursorDirective,
  ],
  exports: [
    CheckboxComponent,
    DotsLoaderComponent,
    RouterModule,
    ArraySortPipe,
    SafeHtmlPipe,
    StripHtmlWithUnicodePipe,
    TextOverflowEllipsisDirective,
    ConnectFormDirective,
    SearchBarComponent,
    AnalysisSearchBarComponent,
    ChipsComponent,
    MatDialogModule,
    PhenotypeOptionComponent,
    PrivacySidePanelComponent,
    PrivacyLabelComponent,
    BreadcrumbComponent,
    VariantTypeToggleComponent,
    ElementPermissionResolverDirective,
    FranklinChipComponent,
    I18nModule,
    FroalaEditorDirective,
    ReverseIterablePipe,
    ButtonComponent,
    SmartCheckboxComponent,
    ShimmerComponent,
    FlexItemComponent,
    FlexComponent,
    TextComponent,
    BlockComponent,
    ExternalLinkComponent,
    TabsComponent,
    ExcludeCheckboxIconComponent,
    SelectDirective,
    InputDirective,
    LabelDirective,
    AnchorDirective,
    BlockDirective,
    FlexDirective,
    FlexItemDirective,
    TextDirective,
    GridDirective,
    TextAreaDirective,
    GridItemDirective,
    ClickCursorDirective,
  ],
  providers: [AnalysisService, FileService, FeedbackService, DownloadFileService, LogService],
})
export class SharedModule {}
