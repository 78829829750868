import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OPEN_SNACKBAR, OpenSnackbar } from '../actions/snackbar.actions';
import {
  PandaToastSnackbarComponent,
  PandaToastSnackbarData,
} from '../../shared-lazy/components/panda-toast-snackbar/panda-toast-snackbar.component';

@Injectable()
export class SnackbarEffect {
  constructor(
    private actions$: Actions,
    private _snackBar: MatSnackBar,
  ) {}

  snackbarEffect$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OPEN_SNACKBAR),
        tap((action: OpenSnackbar) => {
          this._snackBar.openFromComponent<PandaToastSnackbarComponent, PandaToastSnackbarData>(
            PandaToastSnackbarComponent,
            {
              duration: action.duration || 7000,
              verticalPosition: 'top',
              panelClass: 'clean-snackbar',
              data: {
                message: action.msg,
                mode: action.mode,
                details: action.details,
              },
            },
          );
        }),
      ),
    { dispatch: false },
  );
}
