import { SvResult, VariantPosition } from './sv-result.model';
import { SnpChange, SnpResult } from './snp-result.model';
import { VariantType } from './variant-type.model';
import { AnalysisType } from '../../../../../store/models/analysis-type.model';
import { AnalysisDetails } from '../../../../../store/models/analysis-details.model';
import { AnalysisCompoundVariant, AnalysisResult, AnalysisSingleVariant } from './analysis-result.model';
import { RnaMarkerType, SomaticSvResult } from './somatic-sv-result.model';
import { LohResult } from './loh-result.model';
import { LoadVariantPopup } from '../../../../../store';
import { VariantPopupHeaderConfig } from '../../../../variant-page/modules/interpretation/models';

export enum VariantPopupArea {
  Gene = 'gene',
  SequenceBrowser = 'sequence-browser',
  Frequencies = 'frequencies',
  Transcripts = 'transcripts',
  Predictions = 'predictions',
  Conditions = 'conditions',
  Evidence = 'evidence',
  Publications = 'publications',
  ACMGClassification = 'acmg-classification',
  ACMGClassificationSV = 'acmg-classification-sv',
  OncogenicClassification = 'oncogenic-classification',
  UserClassification = 'user-classification',
  InternalFrequency = 'internal-frequency',
  CommunityFrequency = 'community-frequency',
  Confidence = 'confidence',
  CustomAnnotation = 'custom-annotation',
  GenesAndRegions = 'genes-regions',
  Occurrences = 'occurrences',
  SvConfidence = 'sv-confidence',
  InternalOccurrence = 'internal-occurrence',
  SomaticClinicalEvidence = 'somatic-clinical-evidence',
  Compound = 'compound',
  References = 'references',
  FamilyZygosity = 'family-zygosity',
  CompoundFamilies = 'compound-families',
  AddSomaticEvidence = 'add-somatic-evidence',
  GeneAssessment = 'gene-assessment',
  GeneCuration = 'gene-assessment',
  InterpretationHistory = 'interpretation-history',
  CommunityClassification = 'community-classification',
  RohSummary = 'roh-summary',
  RepeatAssessment = 'repeat-assessment',
}

export enum VariantPopupAppType {
  Analysis = 'analysis',
  Discovery = 'discovery',
  SIG = 'sig',
  KnowledgeBase = 'knowledge_base',
}

export interface VariantPopupVariantDetails {
  variantType: VariantType;
  isManuallyCalled?: boolean;
  varId?: string;
  position?: VariantPosition;
  snpChange?: SnpChange;
  svType?: string;
  rnaType?: RnaMarkerType;
  svLength?: number;
  zygosity?: string;
  annotationVersion?: number;
  ci_pos?: string;
  ci_end?: string;
  breakendMate?: {
    position: VariantPosition;
  };
  rohRegions?: VariantPosition[];
  repeatData?: {
    repeatCopyNumber: number;
    repeatUnit: string;
  };
  compoundIndex?: number;
  relation?: string;
}

export interface VariantPopupSelection {
  variant: VariantPopupVariantDetails;
  compoundVariant?: VariantPopupVariantDetails;
  area?: VariantPopupArea;
  analysisId?: number;
  discoveryAnalysisId?: string;
  sigId?: string;
  app_type?: VariantPopupAppType;
  analysis_type?: AnalysisType;
  referenceGenome?: string;
  headerConfig?: VariantPopupHeaderConfig;
}

export function variantDataToVariantPopupData(
  variant: AnalysisSingleVariant,
  analysis: AnalysisDetails,
  compoundIndex?: number,
): VariantPopupVariantDetails {
  if (variant.type === VariantType.SV || variant.type === VariantType.RNA) {
    if (analysis?.analysis_type === AnalysisType.tumor) {
      const variantData = variant.data as SomaticSvResult;
      const variantType = variantData.rna_type ? VariantType.RNA : variant.type || VariantType.SV;

      const result: VariantPopupVariantDetails = {
        variantType,
        varId: variantData.var_id,
        position: {
          chromosome: variantData.chr,
          start: variantData.start_pos,
          end: variantData.end_pos,
        },
        svType: variantData.variation_type.readable_name_short,
        rnaType: variantData.rna_type,
        svLength: variantData.sv_length,
        ci_pos: `${variantData.cipos?.first},${variantData.cipos?.second}`,
        ci_end: `${variantData.ciend?.first},${variantData.ciend?.second}`,
        isManuallyCalled: variantData.is_manually_called,
        annotationVersion:
          (!!variantData.rna_type
            ? analysis.main_sample.rna_annotation_version
            : analysis.main_sample.sv_annotation_version) || variant.latest_annotated_version,
        relation: (variant.data as SnpResult).relation,
        compoundIndex,
      };

      if (variantData.breakend_mates?.length > 0) {
        const mate = variantData.breakend_mates[0];
        result.breakendMate = {
          position: { ...mate.position },
        };
        result.annotationVersion =
          Math.max(analysis.main_sample?.rna_annotation_version, analysis.main_sample?.sv_annotation_version) ||
          variant.latest_annotated_version;
      }

      return result;
    } else {
      const variantData = variant.data as SvResult;
      const annotationVersion =
        (variantData.is_manually_called
          ? analysis?.main_sample?.manual_variants_annotation_version
          : analysis?.main_sample?.sv_annotation_version) || variant.latest_annotated_version;
      const ci_pos = variantData.variant_data.cipos
        ? `${variantData.variant_data.cipos.first},${variantData.variant_data.cipos.second}`
        : '0,0';
      const ci_end = variantData.variant_data.ciend
        ? `${variantData.variant_data.ciend.first},${variantData.variant_data.ciend.second}`
        : '0,0';

      return {
        variantType: VariantType.SV,
        varId: variantData.variant_data.var_id,
        position: {
          chromosome: variantData.variant_data.position.chromosome,
          start: variantData.variant_data.position.start,
          end: variantData.variant_data.position.end,
        },
        svType: variantData.variant_data.variation_type.readable_name_short,
        zygosity: variantData?.sample_data?.zygosity?.name,
        ci_pos,
        ci_end,
        annotationVersion,
        isManuallyCalled: variantData.is_manually_called,
        repeatData:
          variantData.variant_data.variation_type.readable_name_short === 'REPEAT'
            ? {
                repeatUnit: variantData.repeat_data.variant.repeat_nucleotides,
                repeatCopyNumber: variantData.repeat_data.variant.repeats_copy_number,
              }
            : null,
        relation: variantData.relation,
        compoundIndex,
      };
    }
  } else if (variant.type === VariantType.SNP) {
    const variantData = variant.data as SnpResult;
    const annotationVersion =
      (variantData.is_manually_called
        ? analysis?.main_sample?.manual_variants_annotation_version
        : analysis?.main_sample?.annotation_version) || variant.latest_annotated_version;
    return {
      variantType: VariantType.SNP,
      varId: variantData.var_id,
      position: {
        chromosome: variantData.chr,
        start: variantData.start_pos,
        end: variantData.end_pos,
      },
      snpChange: {
        ref: variantData.ref,
        obs: variantData.obs,
      },
      annotationVersion,
      isManuallyCalled: variantData.is_manually_called,
      relation: variantData.relation,
      compoundIndex,
    };
  } else if (variant.type === VariantType.LOH) {
    const variantData = variant.data as LohResult;
    return {
      variantType: VariantType.LOH,
      varId: variantData.loh_data.id,
      rohRegions: [
        {
          chromosome: variantData.loh_data.chrom,
          start: variantData.loh_data.start,
          end: variantData.loh_data.end,
        },
      ],
      annotationVersion: analysis?.main_sample?.loh_annotation_version || variant.latest_annotated_version,
      isManuallyCalled: variantData.is_manually_called,
      compoundIndex,
    };
  }
}

export const getLoadVariantPopupAction = (
  results: AnalysisResult[],
  selection: VariantPopupSelection,
  analysisDetails: AnalysisDetails,
  appType: VariantPopupAppType,
): LoadVariantPopup => {
  let selectionIndex: number;
  const selections: VariantPopupSelection[] = [];
  results.forEach((result) => {
    const compoundResult: AnalysisCompoundVariant =
      result.type === VariantType.Compound ? (result as AnalysisCompoundVariant) : undefined;
    const primaryResult: AnalysisSingleVariant = compoundResult
      ? compoundResult.data.variants[0]
      : (result as AnalysisSingleVariant);
    const pairResult: AnalysisSingleVariant = compoundResult ? compoundResult.data.variants[1] : undefined;
    const variant: VariantPopupVariantDetails = variantDataToVariantPopupData(primaryResult, analysisDetails, 0);
    const compoundVariant: VariantPopupVariantDetails = pairResult
      ? variantDataToVariantPopupData(pairResult, analysisDetails, 1)
      : undefined;

    selections.push({
      ...selection,
      variant,
      compoundVariant,
      app_type: appType,
    });

    if (
      compoundVariant &&
      selection.compoundVariant &&
      variant.varId === selection.variant.varId &&
      compoundVariant.varId === selection.compoundVariant.varId
    ) {
      selectionIndex = selections.length - 1;
    } else if (
      compoundVariant &&
      selection.compoundVariant &&
      variant.varId === selection.compoundVariant.varId &&
      compoundVariant.varId === selection.variant.varId
    ) {
      selectionIndex = selections.length;
    } else if (!compoundVariant && variant.varId === selection.variant.varId) {
      selectionIndex = selections.length - 1;
    }

    if (compoundVariant) {
      selections.push({
        ...selection,
        variant: compoundVariant,
        compoundVariant: variant,
        app_type: appType,
      });
    }
  });
  return new LoadVariantPopup(selections, selectionIndex);
};
