import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import * as fromAction from '../actions';
import { Injectable } from '@angular/core';
import { WorkbenchService } from '../services/workbench.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { WorkbenchModuleState } from '../reducers';
import { MatDialog } from '@angular/material/dialog';
import { withLatestFrom } from 'rxjs';
import {
  AnalysisDetails,
  AnalysisType,
  AppModuleState,
  getAnalysisDetails,
  getAnalysisId,
  OpenSaveFileLinkWindow,
} from '../../../../../../store';
import { getPatientInfoResponse } from '../selectors';
import { SearchConfig } from '../../../../../variant-page/modules/variant-page/models';

@Injectable()
export class ExportResultsEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<WorkbenchModuleState>,
    private rootStore$: Store<AppModuleState>,
    private service: WorkbenchService,
    private dialog: MatDialog,
  ) {}

  getExportResultsUrlSomatic: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GET_EXPORT_RESULTS_URL),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisDetails))),
      filter(([, analysis]) => analysis.analysis_type === AnalysisType.tumor),
      switchMap(([action, analysis]: [fromAction.GetExportResultsUrl, AnalysisDetails]) => {
        return this.service
          .getExportResultsUrlsSomatic(action.analysisId, analysis.disease, analysis.assay.uuid, {
            snp_annotation_version: analysis.main_sample.annotation_version,
            sv_annotation_version: analysis.main_sample.sv_annotation_version,
            rna_annotation_version: analysis.main_sample.rna_annotation_version,
          })
          .pipe(
            map((result) => new fromAction.GetExportResultsUrlSuccess(result)),
            catchError((err) => of(new fromAction.GetExportResultsUrlFail(err))),
          );
      }),
    ),
  );

  getExportResultsUrlGermline: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GET_EXPORT_RESULTS_URL),
      withLatestFrom(
        this.rootStore$.pipe(select(getAnalysisDetails)),
        this.store$.pipe(select(getPatientInfoResponse)),
      ),
      filter(([, analysis, context]) =>
        [AnalysisType.sample, AnalysisType.family, AnalysisType.overgrowth].includes(analysis.analysis_type),
      ),
      switchMap(([action, analysis, context]: [fromAction.GetExportResultsUrl, AnalysisDetails, SearchConfig]) => {
        return this.service
          .getExportResultsUrlsGermline(
            action.analysisId,
            [...analysis.samples.map((x) => x.s3_id), ...analysis.virtual_samples.map((x) => x.s3_id)],
            analysis.phenotypes,
            analysis.assay.uuid,
            {
              snp_annotation_version: analysis.main_sample.annotation_version,
              sv_annotation_version: analysis.main_sample.sv_annotation_version,
            },
            context,
          )
          .pipe(
            map((result) => new fromAction.GetExportResultsUrlSuccess(result)),
            catchError((err) => of(new fromAction.GetExportResultsUrlFail(err))),
          );
      }),
    ),
  );

  getExportLabeledVariantsUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GET_EXPORT_LABELED_VARIANTS_URL),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisId))),
      switchMap(([action, analysisId]: [fromAction.GetExportLabeledVariantsUrl, number]) =>
        this.service.getExportLabeledVariantsUrls(action.analysisId, action.label).pipe(
          map((result) => new fromAction.GetExportResultsUrlSuccess(result)),
          catchError((err) => of(new fromAction.GetExportResultsUrlFail(err))),
        ),
      ),
    ),
  );

  getExportMaccabiAnalysisSummaryUrls = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GET_EXPORT_MACCABI_SUMMARY_URL),
      switchMap((action: fromAction.GetExportMaccabiSummaryUrl) =>
        this.service.getExportMaccabiAnalysisSummaryUrls(action.analysisId).pipe(
          map((result) => new fromAction.GetExportResultsUrlSuccess(result)),
          catchError((err) => of(new fromAction.GetExportResultsUrlFail(err))),
        ),
      ),
    ),
  );

  getExportResultsUrlSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GET_EXPORT_RESULTS_URL_SUCCESS),
      map((action: fromAction.GetExportResultsUrlSuccess) => new OpenSaveFileLinkWindow(action.result.s3_path, '')),
    ),
  );
}
