import { Action } from '@ngrx/store';

import {
  ClassificationResult,
  ClassificationRuleOption,
  Questionnaire,
  QuestionnaireFormAnswers,
  ReclassificationData,
  ReclassificationRule,
} from '../../models';

export const SET_RECLASSIFICATION_DATA = '[Classification] Set Reclassification Data';
export const ADD_RECLASSIFICATION_RULE = '[Classification] Add Reclassification Rule';
export const REVERT_RECLASSIFICATION_DATA = '[Classification] Revert Reclassification Data';

export const GET_RECLASSIFICATION = '[Classification] Get Reclassification';
export const GET_RECLASSIFICATION_SUCCESS = '[Classification] Get Reclassification Success';
export const GET_RECLASSIFICATION_FAILURE = '[Classification] Get Reclassification Failure';
export const SET_RECLASSIFICATION_RESULT = '[Classification] Set Reclassification Result';

export const APPLY_RECLASSIFICATION = '[Classification] Apply Reclassification';
export const APPLY_RECLASSIFICATION_SUCCESS = '[Classification] Apply Reclassification Success';

export const CLEAR_RECLASSIFICATION_DATA = '[Classification] Clear Reclassification Data';
export const CLEAR_RECLASSIFICATION_RESULT = '[Classification] Clear Reclassification Result';

export const SELECT_MANUAL_CATEGORY = '[Classification] Select Manual Category';
export const SELECT_MANUAL_RULE = '[Classification] Select Manual Rule';

export const QUESTIONNAIRE_SAVE = '[Classification] Save Questionnaire Answers';

export class AddReclassificationRule implements Action {
  readonly type = ADD_RECLASSIFICATION_RULE;

  constructor(public ruleData: ReclassificationRule) {}
}

export class SetReclassificationData implements Action {
  readonly type = SET_RECLASSIFICATION_DATA;

  constructor(public reclassificationData: ReclassificationData) {}
}

export class GetReclassification implements Action {
  readonly type = GET_RECLASSIFICATION;
}

export class GetReclassificationSuccess implements Action {
  readonly type = GET_RECLASSIFICATION_SUCCESS;

  constructor(public reclassificationResult: ClassificationResult) {}
}

export class GetReclassificationFailure implements Action {
  readonly type = GET_RECLASSIFICATION_FAILURE;

  constructor(public error: any) {}
}

export class SetReclassificationResult implements Action {
  readonly type = SET_RECLASSIFICATION_RESULT;

  constructor(public result: ClassificationResult) {}
}

export class ApplyReclassification implements Action {
  readonly type = APPLY_RECLASSIFICATION;
}

export class ApplyReclassificationSuccess implements Action {
  readonly type = APPLY_RECLASSIFICATION_SUCCESS;
}

export class RevertReclassificationData implements Action {
  readonly type = REVERT_RECLASSIFICATION_DATA;
}

export class ClearReclassificationData implements Action {
  readonly type = CLEAR_RECLASSIFICATION_DATA;
}

export class ClearReclassificationResult implements Action {
  readonly type = CLEAR_RECLASSIFICATION_RESULT;
}

export class SelectManualCategory implements Action {
  readonly type = SELECT_MANUAL_CATEGORY;

  constructor(public category: Questionnaire) {}
}

export class SelectManualRule implements Action {
  readonly type = SELECT_MANUAL_RULE;

  constructor(public rule: ClassificationRuleOption) {}
}

export class QuestionnaireSave implements Action {
  readonly type = QUESTIONNAIRE_SAVE;

  constructor(public formAnswers: QuestionnaireFormAnswers) {}
}

export type ReclassificationActions =
  | AddReclassificationRule
  | GetReclassification
  | GetReclassificationSuccess
  | GetReclassificationFailure
  | ApplyReclassification
  | RevertReclassificationData
  | SetReclassificationData
  | ClearReclassificationData
  | ClearReclassificationResult
  | SetReclassificationResult
  | SelectManualRule
  | SelectManualCategory
  | QuestionnaireSave;
