import { variantIdToString } from '../../models';

import * as fromAction from '../actions';
import { variantPageStateIdToString } from '../../../../store/models/state-id.model';
import { ContactNetworkData } from '../../models/contact.model';

export interface ContactState {
  contactedMembers: { [key: string]: string[] };
  contactingMembers: { [key: string]: string };
  contactingNetwork: boolean;
  contactedNetwork: boolean;
  contactNetworkData: ContactNetworkData;
}

export const initialState: ContactState = {
  contactedMembers: {},
  contactingMembers: {},
  contactingNetwork: false,
  contactedNetwork: false,
  contactNetworkData: undefined,
};

export function reducer(state = initialState, action: fromAction.ContactAction): ContactState {
  switch (action.type) {
    case fromAction.CONTACT_MEMBERS: {
      return {
        ...state,
        contactingMembers: {
          [variantPageStateIdToString(action.contextVariant, action.gene)]:
            action.postId || action.sampleId || action.sigId || variantIdToString(action.subjectVariant),
        },
      };
    }
    case fromAction.CONTACT_MEMBERS_SUCCESS: {
      return {
        ...state,
        contactedMembers: {
          ...(state.contactedMembers || {}),
          [variantPageStateIdToString(action.contextVariant, action.gene)]: [
            ...((state.contactedMembers || {})[variantPageStateIdToString(action.contextVariant, action.gene)] || []),
            action.postId || action.sampleId || action.sigId || variantIdToString(action.subjectVariant),
          ],
        },
        contactingMembers: {},
      };
    }
    case fromAction.CONTACT_MEMBERS_FAIL: {
      return {
        ...state,
        contactingMembers: {},
      };
    }
    case fromAction.CONTACT_NETWORK: {
      return {
        ...state,
        contactingNetwork: true,
        contactedNetwork: false,
      };
    }
    case fromAction.CONTACT_NETWORK_SUCCESS: {
      return {
        ...state,
        contactingNetwork: false,
        contactedNetwork: true,
      };
    }
    case fromAction.CONTACT_NETWORK_FAIL: {
      return {
        ...state,
        contactingNetwork: false,
        contactedNetwork: false,
      };
    }
    case fromAction.GET_CONTACT_NETWORK_DATA: {
      return {
        ...state,
        contactNetworkData: null,
      };
    }
    case fromAction.GET_CONTACT_NETWORK_DATA_SUCCESS: {
      return {
        ...state,
        contactNetworkData: action.data,
      };
    }
  }

  return state;
}

export const getContactedMembers = (state: ContactState) => state.contactedMembers;
export const getContactingMembers = (state: ContactState) => state.contactingMembers;
export const getContactedNetwork = (state: ContactState) => state.contactedNetwork;
export const getContactingNetwork = (state: ContactState) => state.contactingNetwork;
export const getContactNetworkData = (state: ContactState) => state.contactNetworkData;
