import { Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as fromAction from '../actions/clinvar.action';

import { AssessmentsDataService } from '../../services/assessments-data.service';
import {
  SubmitSuccessComponent,
  SubmitSuccessDialogData,
} from '../../../../../../components/submit-success/submit-success.component';

@Injectable()
export class ClinvarEffect {
  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
    private service: AssessmentsDataService,
  ) {}

  clinvarSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.SUBMIT_TO_CLINVAR),
      switchMap((action: fromAction.SubmitToClinvar) =>
        this.service.submitToClinvar(action.payload).pipe(
          switchMap(() => [new fromAction.SubmitToClinvarSuccess()]),
          catchError(() => of(new fromAction.SubmitToClinvarFail())),
        ),
      ),
    ),
  );

  clinvarSubmitSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAction.SUBMIT_TO_CLINVAR_SUCCESS),
        tap(() =>
          this.dialog.open(SubmitSuccessComponent, {
            width: '570px',
            backdropClass: 'popup-backdrop',
            panelClass: 'popup-panel',
            hasBackdrop: true,
            data: { description: 'We will inform you when the variants will be submitted.' } as SubmitSuccessDialogData,
          }),
        ),
      ),
    { dispatch: false },
  );
}
