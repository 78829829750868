import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as fromAction from '../actions';
import { Injectable } from '@angular/core';
import { WorkbenchService } from '../services/workbench.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { DatePipe } from '@angular/common';
import { select, Store } from '@ngrx/store';

import { AnalysisDetails } from '../../../../../../store/models';

import { AppModuleState } from '../../../../../../store/reducers';
import { getAnalysisDetails } from '../../../../../../store/selectors';
import { OpenSaveFileLinkWindow, OpenSaveFileWindow } from '../../../../../../store';

@Injectable()
export class QualityControlEffects {
  constructor(
    private actions$: Actions,
    private service: WorkbenchService,
    private datePipe: DatePipe,
    private rootStore$: Store<AppModuleState>,
  ) {}

  loadQualityControl$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_QUALITY_CONTROL),
      switchMap((action: fromAction.LoadQualityControl) =>
        this.service.getQualityControl(action.analysisId).pipe(
          map((result) => new fromAction.LoadQualityControlSuccess(action.analysisId, result)),
          catchError((err) => of(new fromAction.LoadQualityControlFail(action.analysisId, err))),
        ),
      ),
    ),
  );

  exportQualityControl$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.EXPORT_QUALITY_CONTROL),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisDetails))),
      switchMap(([action, analysis]: [fromAction.ExportQualityControl, AnalysisDetails]) =>
        this.service.exportQualityControl(action.analysisId).pipe(
          map(
            (response) =>
              new OpenSaveFileWindow(
                response,
                `${analysis.name} qc-report [${this.datePipe.transform(new Date(), 'M-d-y')}].txt`,
              ),
          ),
          catchError((err) => of(new fromAction.ExportQualityControlFail(err))),
        ),
      ),
    ),
  );

  checkExportQCBatch: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.CHECK_QC_BATCH_EXPORT),
      switchMap((action: fromAction.CheckQCBatchExport) =>
        this.service.checkExportQCBatch(action.analysisId, action.batchId).pipe(
          map((response) => new fromAction.CheckQCBatchExportSuccess(response)),
          catchError((err) => of(new fromAction.CheckQCBatchExportFail(err))),
        ),
      ),
    ),
  );

  checkExportSnpProfileResults: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.CHECK_QC_SNP_PROFILE_RESULTS_EXPORT),
      switchMap((action: fromAction.CheckQCSnpProfileResultsExport) =>
        this.service.checkExportQcSnpProfileResults(action.analysisId).pipe(
          map((response) => new fromAction.CheckQCSnpProfileResultsExportSuccess(response)),
          catchError((err) => of(new fromAction.CheckQCSnpProfileResultsExportFail(err))),
        ),
      ),
    ),
  );

  exportQCBatch: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.EXPORT_QC_BATCH_REPORT),
      map(
        (action: fromAction.ExportQCBatchReport) =>
          new OpenSaveFileLinkWindow(
            action.path,
            `batch qc-report [${this.datePipe.transform(new Date(), 'M-d-y')}].txt`,
          ),
      ),
    ),
  );

  exportSnpProfileResults: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.EXPORT_QC_SNP_PROFILE_RESULTS),
      map(
        (action: fromAction.ExportQCSnpProfileResults) =>
          new OpenSaveFileLinkWindow(
            action.path,
            `snp profile results [${this.datePipe.transform(new Date(), 'M-d-y')}].txt`,
          ),
      ),
    ),
  );
}
