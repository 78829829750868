import {
  CLEAR_GENE_PHENOTYPES,
  CLEAR_PHENOTYPES,
  GET_GENE_PHENOTYPES,
  GET_GENE_PHENOTYPES_SUCCESS,
  GET_PHENOTYPES,
  GET_PHENOTYPES_FAIL,
  GET_PHENOTYPES_SUCCESS,
  PhenotypesAction,
} from '../actions';
import { ERROR_PHENOTYPE, GenePhenotypesResponse, Phenotype } from '../models';

export interface PhenotypesState {
  phenotypes: Phenotype[];
  genePhenotypes: GenePhenotypesResponse;
  loading: boolean;
  loaded: boolean;
  genePhenotypesLoading: boolean;
  genePhenotypesLoaded: boolean;
  error?: any;
}

const phenotypesInitialState: PhenotypesState = {
  phenotypes: [],
  genePhenotypes: undefined,
  loading: false,
  loaded: false,
  genePhenotypesLoading: false,
  genePhenotypesLoaded: false,
};

export function phenotypesReducer(state = phenotypesInitialState, action: PhenotypesAction): PhenotypesState {
  switch (action.type) {
    case GET_PHENOTYPES: {
      return {
        ...state,
        phenotypes: [],
        loading: true,
        loaded: false,
        error: undefined,
      };
    }
    case GET_PHENOTYPES_SUCCESS: {
      return {
        ...state,
        phenotypes: action.payload?.length || !action.searchTerm ? action.payload : [ERROR_PHENOTYPE],
        loading: false,
        loaded: true,
      };
    }
    case GET_GENE_PHENOTYPES: {
      return {
        ...state,
        genePhenotypes: undefined,
        genePhenotypesLoading: true,
        genePhenotypesLoaded: false,
        error: undefined,
      };
    }
    case GET_GENE_PHENOTYPES_SUCCESS: {
      return {
        ...state,
        genePhenotypes:
          action.payload.associated_to_gene?.length || action.payload.all_conditions?.length
            ? action.payload
            : { error: ERROR_PHENOTYPE },
        genePhenotypesLoading: false,
        genePhenotypesLoaded: true,
      };
    }
    case GET_PHENOTYPES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    }
    case CLEAR_PHENOTYPES: {
      return {
        ...state,
        phenotypes: [],
      };
    }
    case CLEAR_GENE_PHENOTYPES: {
      return {
        ...state,
        genePhenotypes: {},
      };
    }
  }
  return state;
}

export const getPhenotypeSuggestions = (state: PhenotypesState) => state.phenotypes;
export const getPhenotypesLoading = (state: PhenotypesState) => state.loading;
export const getPhenotypesLoaded = (state: PhenotypesState) => state.loaded;
export const getGenePhenotypeSuggestions = (state: PhenotypesState) => state.genePhenotypes;
export const getGenePhenotypesLoading = (state: PhenotypesState) => state.genePhenotypesLoading;
export const getGenePhenotypesLoaded = (state: PhenotypesState) => state.genePhenotypesLoaded;
export const getPhenotypesError = (state: PhenotypesState) => state.error;
