import { Component, OnInit } from '@angular/core';

import { Language, languages } from '../languages.model';
import { I18nService } from '../i18n.service';
import { NgClass, NgFor } from '@angular/common';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'gnx-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  standalone: true,
  imports: [MatMenuTrigger, MatMenu, NgFor, MatMenuItem, NgClass],
})
export class LanguageSelectorComponent implements OnInit {
  public languages: Language[];
  public currentLanguage: Language;

  constructor(private i18n: I18nService) {}

  ngOnInit() {
    this.languages = languages;
    this.currentLanguage = this.i18n.getLanguage();
  }

  setLanguage(language: Language) {
    this.currentLanguage = language;
    this.i18n.setLanguage(language);
  }
}
