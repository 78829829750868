import { Injectable } from '@angular/core';
import {
  BackendQueryParams,
  BackendQueryParamsSnakeCase,
  BEQueryParamsFollowedVariants,
  BEQueryParamsInterpretation,
  BEQueryParamsOrganization,
  BEQueryParamsSortDirection,
  BEQueryParamsSorting,
  BEQueryParamsSvVariantData,
  BEQueryParamsVariantData,
  BEQueryParamsVariantType,
  GenesBackendQueryParams,
  GenesBackendQueryParamsSnakeCase,
  QueryParams,
} from '../modules/variants/models';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsService {
  private static getParamAsList(param: string | string[]): string[] {
    return typeof param === 'string' ? [param] : param;
  }

  private extractOrganizationQuery(params: QueryParams): BEQueryParamsOrganization {
    return params.organization
      ? { organizationIds: QueryParamsService.getParamAsList(params.organization), rule: params.organization_rule }
      : {};
  }

  private extractInterpretationQuery(params: QueryParams, userId?: string): BEQueryParamsInterpretation {
    const result: BEQueryParamsInterpretation = {};

    if (params.classification) {
      result.assessment = QueryParamsService.getParamAsList(params.classification);
    }
    if (params.inheritance) {
      result.inheritanceModel = QueryParamsService.getParamAsList(params.inheritance);
    }
    if (params.shared_by_me) {
      result.userId = userId;
    }
    if (params.hide_suggested) {
      result.suggested = params.hide_suggested !== 'true';
    }
    if (params.somatic_classification) {
      result.somaticAssessments = QueryParamsService.getParamAsList(params.somatic_classification);
    }
    if (params.assignee) {
      result.assignee = QueryParamsService.getParamAsList(params.assignee);
    }

    return result;
  }

  private extractFollowedQuery(params: QueryParams, userId?: string): BEQueryParamsFollowedVariants {
    const result: BEQueryParamsFollowedVariants = {};

    if (params.followed) {
      result.userId = userId;
    }
    return result;
  }

  private extractVariantType(tab: string | string[]): BEQueryParamsVariantType {
    return tab
      ? { generalVariantType: Array.isArray(tab) ? tab.map((x) => x.toUpperCase()) : [tab.toUpperCase()] }
      : undefined;
  }

  private extractSortingQuery(params: QueryParams): BEQueryParamsSorting {
    let result: BEQueryParamsSorting;

    if (params.sorting) {
      result = {
        sortBy: params.sorting.includes('-') ? params.sorting.slice(1) : params.sorting,
        direction: params.sorting.includes('-') ? BEQueryParamsSortDirection.DECR : BEQueryParamsSortDirection.INCR,
      };
    }

    return result;
  }

  private extractVariantDataQuery(params: QueryParams): BEQueryParamsVariantData {
    const result: BEQueryParamsVariantData = {};

    if (params.variant_type) {
      result.variantType = QueryParamsService.getParamAsList(params.variant_type);
      result.variant_type = result.variantType;
    }
    if (params.region) {
      result.region = QueryParamsService.getParamAsList(params.region);
    }
    if (params.frequency) {
      const frequency = QueryParamsService.getParamAsList(params.frequency);

      result.frequency = {
        allowNotAvailable: true,
        allow_not_available: true,
        intervals: frequency.map((freq) => {
          const range = freq.split('-');
          return {
            from: parseFloat(range[0]) * 100,
            to: parseFloat(range[1]) * 100,
            intervalType: 'TWO_SIDED_BOUNDED_INTERVAL',
            interval_type: 'TWO_SIDED_BOUNDED_INTERVAL',
          };
        }),
      };
    }

    return result;
  }

  private extractSvVariantDataQuery(params: QueryParams): BEQueryParamsSvVariantData {
    const result: BEQueryParamsSvVariantData = {};

    if (params.variant_type) {
      result.svType = QueryParamsService.getParamAsList(params.variant_type);
    }
    if (params.region) {
      result.region = QueryParamsService.getParamAsList(params.region);
    }

    return result;
  }

  public convertToBackendFormat(
    tab: string | string[],
    params: Params,
    userId?: string,
    pageSize?: number,
    currentPage?: number,
    snakeCase?: boolean,
    ignoreQueryParams?: boolean,
  ): BackendQueryParams | BackendQueryParamsSnakeCase {
    let resultSnakeCase: BackendQueryParamsSnakeCase | BackendQueryParams;

    const result: BackendQueryParams = {
      organizationQuery: ignoreQueryParams ? undefined : this.extractOrganizationQuery(params as QueryParams),
      interpretationQuery: ignoreQueryParams
        ? undefined
        : this.extractInterpretationQuery(params as QueryParams, userId),
      variantDataQuery: ignoreQueryParams
        ? undefined
        : typeof tab === 'string' && (tab as string).toUpperCase() === 'SNP'
          ? this.extractVariantDataQuery(params as QueryParams)
          : undefined,
      svVariantDataQuery: ignoreQueryParams
        ? undefined
        : typeof tab === 'string' && (tab as string).toUpperCase() === 'SV'
          ? this.extractSvVariantDataQuery(params as QueryParams)
          : undefined,
      followedVariantsQuery: ignoreQueryParams ? undefined : this.extractFollowedQuery(params as QueryParams, userId),
      variantTypeQuery: this.extractVariantType(tab),
      sorting: ignoreQueryParams ? undefined : this.extractSortingQuery(params as QueryParams),
      searchText: ignoreQueryParams
        ? undefined
        : (params as QueryParams).search
          ? (params as QueryParams).search.split(',')
          : [],
    };

    if (currentPage && pageSize) {
      result.fromIndex = (currentPage - 1) * pageSize;
      result.toIndex = currentPage * pageSize;
    }

    if (snakeCase) {
      resultSnakeCase = {
        community_id: result.communityId,
        organization_query: {
          organization_ids: result.organizationQuery?.organizationIds,
          rule: result.organizationQuery?.rule,
        },
        interpretation_query: {
          assessment: result.interpretationQuery?.assessment,
          inheritance_model: result.interpretationQuery?.inheritanceModel,
          user_id: result.interpretationQuery?.userId,
        },
        variant_data_query: {
          variant_type: result.variantDataQuery?.variantType,
          region: result.variantDataQuery?.region,
          frequency: result.variantDataQuery?.frequency,
        },
        sv_variant_data_query: {
          sv_type: result.svVariantDataQuery?.svType,
          region: result.svVariantDataQuery?.region,
        },
        followed_variants_query: {
          user_id: result.followedVariantsQuery?.userId,
        },
        variant_type_query: {
          general_variant_type: result.variantTypeQuery?.generalVariantType,
        },
        sorting: {
          sort_by: result.sorting?.sortBy,
          direction: result.sorting?.direction,
        },
        from_index: result.fromIndex,
        to_index: result.toIndex,
        search_text: result.searchText,
      };
    }

    return snakeCase ? resultSnakeCase : result;
  }

  public convertToGenesBackendFormat(
    tab: string | string[],
    params: Params,
    userId?: string,
    pageSize?: number,
    currentPage?: number,
    snakeCase?: boolean,
    ignoreQueryParams?: boolean,
  ): GenesBackendQueryParams {
    let resultSnakeCase: GenesBackendQueryParamsSnakeCase | GenesBackendQueryParams;

    const result: GenesBackendQueryParams = {
      sorting: ignoreQueryParams ? undefined : this.extractSortingQuery(params as QueryParams),
      searchText: ignoreQueryParams
        ? undefined
        : (params as QueryParams).search
          ? [(params as QueryParams).search]
          : [],
      geneSymbolQuery: params.search?.length
        ? {
            names:
              params.search
                ?.split(',')
                .map((v) => v.trim())
                .filter((item) => !!item) || [],
          }
        : null,
      curationQuery: params.characterization
        ? {
            characterizationStatus: Array.isArray(params.characterization)
              ? params.characterization
              : [params.characterization],
          }
        : undefined,
    };

    if (currentPage && pageSize) {
      result.fromIndex = (currentPage - 1) * pageSize;
      result.toIndex = currentPage * pageSize;
    } else {
      result.fromIndex = 0;
      result.toIndex = pageSize || 20;
    }

    if (snakeCase) {
      resultSnakeCase = {
        sorting: {
          sort_by: result.sorting?.sortBy,
          direction: result.sorting?.direction,
        },
        from_index: result.fromIndex,
        to_index: result.toIndex,
        search_text: result.searchText,
        search_terms: result.geneSymbolQuery?.names || [],
        curation_query: params.characterization
          ? {
              characterization_status: Array.isArray(params.characterization)
                ? params.characterization
                : [params.characterization],
            }
          : undefined,
      };
    }

    return resultSnakeCase || result;
  }
}
