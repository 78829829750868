import * as fromAction from '../actions';

export interface LastUsedFilterState {
  loaded: boolean;
  loading: boolean;
}

export const initialState: LastUsedFilterState = {
  loaded: false,
  loading: false,
};

export function reducer(state = initialState, action: fromAction.LastUsedFilterAction): LastUsedFilterState {
  switch (action.type) {
    case fromAction.LOAD_LAST_USED_FILTER_AND_STRUCTURE: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case fromAction.LOAD_LAST_USED_FILTER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }

    case fromAction.LOAD_LAST_USED_FILTER_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case fromAction.CLEAR_LAST_USED_FILTER: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }

  return state;
}

export const getLastUsedFilterLoaded = (state: LastUsedFilterState) => state.loaded;
export const getLastUsedFilterLoading = (state: LastUsedFilterState) => state.loading;
