import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ClearVariantPageCachedData } from '../actions/variant-page-data.action';
import { SEARCH_VARIANT_SUCCESS } from '../../../../store';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class VariantPageDataEffect {
  constructor(private actions$: Actions) {}

  clearCachedDataAfterSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SEARCH_VARIANT_SUCCESS),
      map(() => new ClearVariantPageCachedData()),
    ),
  );
}
