import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'striphtml',
  standalone: true,
})
export class StripHtmlWithUnicodePipe implements PipeTransform {
  transform(html: any): any {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }
}
