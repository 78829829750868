import { Action } from '@ngrx/store';
import { DynamicInputField } from '../../../../../../shared/models';
import { VariantId } from '../../../variant-page/models';

export const GET_INTERPRETATION_CUSTOM_FIELDS = '[Create Interpretation] Get custom fields';
export const GET_INTERPRETATION_CUSTOM_FIELDS_SUCCESS = '[Create Interpretation] Get custom fields success';
export const GET_INTERPRETATION_CUSTOM_FIELDS_FAIL = '[Create Interpretation] Get custom fields fail';

export class GetInterpretationCustomFields implements Action {
  readonly type = GET_INTERPRETATION_CUSTOM_FIELDS;

  constructor(public variantId: VariantId) {}
}

export class GetInterpretationCustomFieldsSuccess implements Action {
  readonly type = GET_INTERPRETATION_CUSTOM_FIELDS_SUCCESS;

  constructor(public fields: DynamicInputField[]) {}
}

export class GetInterpretationCustomFieldsFail implements Action {
  readonly type = GET_INTERPRETATION_CUSTOM_FIELDS_FAIL;

  constructor(public error: any) {}
}

export type InterpretationCustomFieldsAction =
  | GetInterpretationCustomFields
  | GetInterpretationCustomFieldsSuccess
  | GetInterpretationCustomFieldsFail;
