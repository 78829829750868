<div mat-dialog-title class="gnx-dialog-header">
  <div class="gnx-dialog-close-btn" (click)="onClose()">&#x2715;</div>
</div>
<div class="gnx-dialog-body text-center">
  <img alt="email" class="email-icon" src="/assets/svg/email.svg" />
  <div class="text main-message">Your variants are on the way!</div>
  <div class="text sub-message">The exported file will be emailed to you in a few minutes.</div>
  <div class="gnx-dialog-actions">
    <button (click)="onClose()" class="gnx-dialog-actions-primary">OK</button>
  </div>
</div>
