import { UserPermission } from '../../../../auth0/models';
import { InterpretationType } from '../../interpretation/containers/interpretation/interpretation.data';

export enum MaxiApplicationType {
  ConflictResolve = 'conflict-resolve',
  SequenceBrowser = 'sequence-browser',
}

export enum MacroApplicationType {
  Interpretation = 'interpretation',
  AddSomaticEvidence = 'add-somatic-evidence',
  ContactNetwork = 'contact-network',
}

export enum ApplicationType {
  CommunityClassification = 'community-classification',
  CommunityClassificationDemoApp = 'community-classification-demo-app',
  ACMGClassification = 'acmg-classification',
  ACMGClassificationSV = 'acmg-classification-sv',
  OncogenicClassification = 'oncogenic-classification',
  AssessmentTools = 'assessment-tools',
  Publications = 'publications',
  ClinicalTrials = 'clinical-trials',
  ClinicalEvidence = 'clinical-evidence',
  Interpretation = 'interpretation',
  InterpretationHistory = 'interpretation-history',
  Conditions = 'conditions',
  GenesAndRegions = 'genes-regions',
  SimilarCases = 'similar-cases',
  GeneAssessment = 'gene-assessment',
  CuratedGeneData = 'curated-gene-data',
  AddSomaticEvidence = 'add-somatic-evidence',
  ContactNetwork = 'contact-network',
  Confidence = 'confidence',
  GeneStructure = 'gene-structure',
}

export type ApplicationTypeEnumValues =
  | ApplicationType.CommunityClassification
  | ApplicationType.CommunityClassificationDemoApp
  | ApplicationType.ACMGClassification
  | ApplicationType.ACMGClassificationSV
  | ApplicationType.OncogenicClassification
  | ApplicationType.AssessmentTools
  | ApplicationType.Publications
  | ApplicationType.ClinicalTrials
  | ApplicationType.ClinicalEvidence
  | ApplicationType.Interpretation
  | ApplicationType.InterpretationHistory
  | ApplicationType.Conditions
  | ApplicationType.GenesAndRegions
  | ApplicationType.SimilarCases
  | ApplicationType.GeneAssessment
  | ApplicationType.CuratedGeneData
  | ApplicationType.ContactNetwork;

export enum MiniApplicationType {
  Feed = 'feed',
  Discussion = 'discussion',
  Note = 'note',
  CaseDetails = 'case-details',
  GenePanels = 'gene-panels',
  EditVariant = 'edit-variant',
  Interpretation = 'interpretation',
  GeneCuration = 'gene-curation',
}

export interface MiniApplicationConfig {
  interpretation?: {
    interpretationType: InterpretationType;
  };
}

export interface ApplicationMetadataInterface {
  description?: string;
  icon?: string;
  iconSet?: string;
  iconHover?: string;
  class?: string;
  permission?: UserPermission;
}

export const AvailableApplicationsMetadata: { [key: string]: ApplicationMetadataInterface } = {
  [ApplicationType.CommunityClassification]: {
    icon: '/assets/svg/flag-link.svg',
    iconHover: '/assets/svg/flag-link-hover.svg',
    class: 'community',
  },
  [ApplicationType.CommunityClassificationDemoApp]: {
    icon: '/assets/svg/flag-link.svg',
    iconHover: '/assets/svg/flag-link-hover.svg',
    class: 'community',
  },
  [ApplicationType.ACMGClassification]: {
    icon: '/assets/svg/franklin-logo.svg',
    iconHover: '/assets/svg/franklin-logo.svg',
    class: 'acmg',
  },
  [ApplicationType.ACMGClassificationSV]: {
    icon: '/assets/svg/franklin-logo.svg',
    iconHover: '/assets/svg/franklin-logo.svg',
    class: 'acmg-sv',
  },
  [ApplicationType.OncogenicClassification]: {
    icon: '/assets/svg/franklin-logo.svg',
    iconHover: '/assets/svg/franklin-logo.svg',
    class: 'oncogenic',
  },
  [ApplicationType.GenesAndRegions]: {
    icon: '/assets/svg/dna.svg',
    iconHover: '/assets/svg/dna-hover.svg',
    class: 'genes-regions',
  },
  [ApplicationType.AssessmentTools]: {
    icon: '/assets/svg/graph-icon.svg',
    iconHover: '/assets/svg/graph-icon-hover.svg',
    class: 'tools',
  },
  [ApplicationType.Publications]: {
    icon: '/assets/svg/publications.svg',
    iconHover: '/assets/svg/publications-hover.svg',
    class: 'publications',
  },
  [ApplicationType.ClinicalTrials]: {
    icon: '/assets/svg/trials.svg',
    iconHover: '/assets/svg/trials-hover.svg',
    class: 'clinical-trials',
  },
  [ApplicationType.ClinicalEvidence]: {
    icon: '/assets/svg/Binocular.svg',
    iconHover: '/assets/svg/Binocular-hover.svg',
    class: 'clinical-evidence',
  },
  [ApplicationType.InterpretationHistory]: {
    icon: '/assets/svg/interpretation-history-app-dark.svg',
    iconHover: '/assets/svg/interpretation-history-app.svg',
    class: 'interpretation-history',
  },
  [ApplicationType.Conditions]: {
    icon: '/assets/svg/disease-associated.svg',
    iconHover: '/assets/svg/disease-associated-hover.svg',
    class: 'diseases',
  },
  [ApplicationType.SimilarCases]: {
    icon: '/assets/svg/similar.svg',
    iconHover: '/assets/svg/similar-hover.svg',
    class: 'similar-cases',
  },
  [ApplicationType.GeneAssessment]: {
    icon: '/assets/svg/gene-assessment.svg',
    iconHover: '/assets/svg/gene-assessment-hover.svg',
    class: 'gene-assessment',
  },
  [ApplicationType.CuratedGeneData]: {
    icon: '/assets/svg/curation.svg',
    iconHover: '/assets/svg/curation-hover.svg',
    class: 'curated-gene-data',
  },
  [ApplicationType.GeneStructure]: {
    icon: '/assets/svg/flag-link.svg',
    iconHover: '/assets/svg/flag-link-hover.svg',
    class: 'gene-structure',
  },
  [ApplicationType.Confidence]: {
    icon: '/assets/svg/confidence-black.svg',
    iconHover: '/assets/svg/confidence.svg',
    class: 'confidence',
  },
};

export const AvailableMiniApplicationsMetadata: { [key: string]: ApplicationMetadataInterface } = {
  [MiniApplicationType.Feed]: {
    icon: '/assets/png/panda-icon-head.png',
    iconSet: 'assets/png/panda-icon-head@2x.png 2x, assets/png/panda-icon-head@3x.png 3x',
  },
  [MiniApplicationType.Discussion]: {
    icon: '/assets/svg/discussion.svg',
  },
  [MiniApplicationType.Note]: {
    icon: '/assets/svg/ribbon.svg',
  },
  [MiniApplicationType.CaseDetails]: {
    icon: '/assets/svg/case-details.svg',
  },
  [MiniApplicationType.GenePanels]: {
    icon: '/assets/svg/gene-panels.svg',
  },
  [MiniApplicationType.Interpretation]: {
    icon: '/assets/svg/interpretation-bright-left.svg',
    permission: UserPermission.Classify,
  },
  [MiniApplicationType.GeneCuration]: {
    icon: '/assets/svg/interpretation-bright-left.svg',
    permission: UserPermission.CurateGene,
  },
};

export const DemoApplications: ApplicationType[] = [ApplicationType.CommunityClassificationDemoApp];

export enum AssessmentToolsComponent {
  VariantSummary = 'variant-summary',
  SvSummary = 'sv-summary',
  RepeatAssessment = 'repeat-assessment',
  Occurrences = 'occurrences',
  Frequencies = 'frequencies',
  Predictions = 'predictions',
  Evidence = 'evidence',
  SequenceBrowser = 'sequence-browser',
  FamilyZygosity = 'family-zygosity',
  FamilyZygositySv = 'family-zygosity-sv',
  InternalFrequency = 'internal-frequency',
  CommunityFrequency = 'community-frequency',
  CommunityFrequencyDetailed = 'community-frequency-detailed',
  InternalOccurrence = 'internal-occurrence',
  SomaticFrequency = 'somatic-frequency',
  SomaticConfidence = 'somatic-confidence',
  Transcripts = 'transcripts',
  Compound = 'compound',
  CompoundFamilies = 'compound-families',
  GenePanels = 'gene-panels',
  GeneSummary = 'gene-summary',
  GeneCoverage = 'gene-coverage',
  CustomAnnotation = 'custom-annotation',
  RegionViewer = 'region-viewer',
  SvRegionViewer = 'sv-region-viewer',
  FusionRegionViewer = 'fusion-region-viewer',
  SvConfidence = 'sv-confidence',
  References = 'references',
  RohSummary = 'roh-summary',
  VariantPriority = 'variant-priority',
  OrgSimilarVariants = 'org-similar-variants',
  CommSimilarVariants = 'comm-similar-variants',
  GeneBurden = 'gene-burden',
}

export enum GeneAssessmentComponent {
  GeneSummary = 'gene-summary',
  CuratedVariantsDistribution = 'curated-variants-distribution',
  ClinicalTrialsReach = 'clinical-trials-reach',
  GenePathogenicity = 'gene-pathogenicity',
  GeneExpression = 'gene-expression',
  MatchMaker = 'matchmaker',
  GeneGwas = 'gene-gwas',
  GeneClinvar = 'gene-clinvar',
}

export enum CuratedGeneDataComponent {
  Links = 'LINKS',
  CuratedConditions = 'CURATED_CONDITIONS',
  GeneSummary = 'GENE_SUMMARY',
  GeneDetails = 'GENE_DETAILS',
  Reporting = 'REPORTING',
  GeneAttachments = 'GENE_ATTACHMENTS',
  References = 'REFERENCES',
}

export enum CuratedConditionDataComponent {
  Links = 'LINKS',
  AcmgCodes = 'ACMG_CODES',
  ConditionSummary = 'CONDITION_SUMMARY',
  GeneDiseaseValidity = 'GENE_DISEASE_VALIDITY',
  MechanismOfDisease = 'MECHANISM_OF_DISEASE',
  Clinical = 'CLINICAL',
  ConditionAttachments = 'CONDITION_ATTACHMENTS',
}

export enum GeneStructureComponent {
  GeneViz = 'GENE_VIZ',
  Transcripts = 'TRANSCRIPTS',
  Exons = 'EXONS',
  GeneImages = 'GENE-IMAGES',
  GeneVariants = 'GENE-VARIANTS',
}

export type CurationMiniAppSection = CuratedGeneDataComponent | CuratedConditionDataComponent | string;
