import { CustomLabel } from '../models';
import { Action } from '@ngrx/store';

export const GET_CUSTOM_LABELS = '[Analyses] Get custom labels';
export const GET_CUSTOM_LABELS_FAIL = '[Analyses] Get custom labels fail';
export const GET_CUSTOM_LABELS_SUCCESS = '[Analyses] Get custom labels success';

export class GetCustomLabels implements Action {
  readonly type = GET_CUSTOM_LABELS;

  constructor() {}
}

export class GetCustomLabelsFail implements Action {
  readonly type = GET_CUSTOM_LABELS_FAIL;

  constructor(public err: any) {}
}

export class GetCustomLabelsSuccess implements Action {
  readonly type = GET_CUSTOM_LABELS_SUCCESS;

  constructor(public payload: CustomLabel[]) {}
}

export type AnalysisLabelsAction = GetCustomLabels | GetCustomLabelsSuccess | GetCustomLabelsFail;
