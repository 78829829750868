import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[gnxTextOverflowEllipsis]',
  exportAs: 'gnxTextOverflowEllipsis',
  standalone: true,
})
export class TextOverflowEllipsisDirective implements AfterViewInit {
  @Input() disabled: boolean;
  isOverflowed: boolean;
  tooltipText: string;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    if (!this.disabled) {
      this.renderer.setStyle(this.element.nativeElement, 'text-overflow', 'ellipsis');
      this.renderer.setStyle(this.element.nativeElement, 'overflow', 'hidden');
    }
    this.renderer.setStyle(this.element.nativeElement, 'white-space', 'nowrap');

    setTimeout(() => {
      this.isOverflowed = this.element.nativeElement.scrollWidth > this.element.nativeElement.clientWidth;
      this.tooltipText = this.isOverflowed ? this.element.nativeElement.innerText : '';

      this.cd.detectChanges();
    });
  }
}
