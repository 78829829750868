import { createReducer, on } from '@ngrx/store';
import { RichTemplateActions } from '../actions';

export const richTemplateFeatureKey = 'richTemplate';

export interface State {
  data?: Record<string, string>;
  loading?: boolean;
  loaded?: boolean;
}

export const initialState: State = {};

export const reducer = createReducer(
  initialState,
  on(RichTemplateActions.loadRichTemplates, (state) => ({ ...state, loading: true, loaded: false })),
  on(RichTemplateActions.loadRichTemplatesSuccess, (state, action) => ({
    ...state,
    data: {
      ...state.data,
      ...action.data.templates.reduce((acc, crt) => ({ ...acc, [crt.field_id]: crt.template }), {}),
    },
    loading: false,
    loaded: true,
  })),
  on(RichTemplateActions.loadRichTemplatesFailure, (state, action) => ({ ...state, loading: false, loaded: false })),
);
