import { HttpParams } from '@angular/common/http';
import { AnalysisType } from '../../../../../store';
import { RnaMarkerType } from '../../../../analysis/modules/analysis-variant/models';
import { CustomHttpParamEncoder } from '../../../../../shared/utils/http-param-encoder';

export enum VariantIdTypes {
  SNP = 'snp',
  SNP_TUMOR = 'snpTumor',
  SV = 'sv',
  SV_TUMOR = 'svTumor',
  ROH = 'roh',
  RNA = 'rna',
}

export enum VariantReferenceType {
  HG38 = 'hg38',
  HG19 = 'hg19',
}

export type VariantId = SnpVariantId | SvVariantId | RohVariantId;

interface VariantIdAnalysisData {
  referenceGenome?: string;
  annotationVersion?: number;
  analysisId?: number;
  analysisType?: AnalysisType;
  assayId?: string;
  compoundIndex?: number;
  relation?: string;
  variantIdType?: VariantIdTypes;
  discoveryAnalysisId?: string;
}

export interface SnpVariantId extends VariantIdAnalysisData {
  chr: string;
  pos: number;
  ref: string;
  alt: string;
  hashId?: string;
  isManuallyCalled?: boolean;
}

export interface SvVariantId extends VariantIdAnalysisData {
  chr: string;
  start_pos: number;
  end_pos: number;
  sv_type: string;
  ci_pos?: string;
  ci_end?: string;
  mate?: {
    chr: string;
    start_pos: number;
    end_pos: number;
  };
  hashId?: string;
  rna_type?: RnaMarkerType;
  sv_length?: number;
  isManuallyCalled?: boolean;
  repeatData?: {
    repeatCopyNumber: number;
    repeatUnit: string;
  };
}

export interface RohVariantId extends VariantIdAnalysisData {
  chr: string;
  start_pos: number;
  end_pos: number;
  roh_regions: RohRegion[];
  hashId?: string;
  isManuallyCalled?: boolean;
}

export interface GeneId extends VariantIdAnalysisData {
  gene: string;
}

export interface RohRegion {
  chrom: string;
  start_pos: number;
  end_pos: number;
}

export function getVariantIdType(variantId: VariantId): VariantIdTypes {
  return 'sv_type' in variantId
    ? VariantIdTypes.SV
    : 'start_pos' in variantId
      ? VariantIdTypes.ROH
      : VariantIdTypes.SNP;
}

export function getSearchTermFromVariantId(variantId: VariantId): string {
  return 'sv_type' in variantId
    ? `${variantId.sv_type}:${variantId.chr}:${variantId.start_pos}-${variantId.end_pos}`
    : 'roh_regions' in variantId
      ? variantId.roh_regions.map((region) => `${region.chrom}:${region.start_pos}-${region.end_pos}`).join(' ')
      : `${variantId.chr}:${variantId.pos}-${variantId.ref}-${variantId.alt}`;
}

export function variantIdToString(variantId: VariantId, expandROHRegion: boolean = false): string {
  switch (getVariantIdType(variantId)) {
    case VariantIdTypes.SV:
      const svVariantID = variantId as SvVariantId;
      return `${svVariantID.chr}:${svVariantID.start_pos}-${svVariantID.end_pos}?${svVariantID.sv_type}`;
    case VariantIdTypes.SNP:
      const snpVariantID = variantId as SnpVariantId;
      return `${snpVariantID.chr}:${snpVariantID.pos} ${snpVariantID.ref}>${snpVariantID.alt}`;
    case VariantIdTypes.ROH:
      const rohVariantID = variantId as RohVariantId;
      if (rohVariantID.roh_regions.length > 0 && expandROHRegion) {
        return rohVariantID.roh_regions
          .map((region) => `${region.chrom}:${region.start_pos}-${region.end_pos}`)
          .join(` ;`);
      } else {
        return `${rohVariantID.chr}:${rohVariantID.start_pos}-${rohVariantID.end_pos}`;
      }
  }
}

export function geneIdToString(geneId: GeneId): string {
  return `${geneId?.referenceGenome}:${geneId?.gene}`;
}

export function variantWithChromToVariantId({ chrom, pos, ref, alt }): VariantId {
  return {
    chr: chrom,
    pos,
    ref,
    alt,
  };
}

export function analysisVariantIdToString(variantId: VariantId): string {
  return `${variantId.analysisId ? variantId.analysisId : 0}_${variantIdToString(variantId)}`;
}

export function variantIdToBody4(variantId: VariantId, compoundVariantId: VariantId) {
  return {
    chr: variantId.chr.startsWith('chr') ? variantId.chr : 'chr' + variantId.chr,
    variant_id: variantId.hashId,
    reference_version: variantId.referenceGenome,
    var_type: !variantId.hasOwnProperty('sv_type')
      ? 'snp'
      : (variantId as SvVariantId).sv_type.toLowerCase() === 'rna'
        ? 'rna'
        : 'sv',
    sv_type: variantId.hasOwnProperty('sv_type') ? (variantId as SvVariantId).sv_type : undefined,
    pair_variant: compoundVariantId
      ? {
          variant_id: compoundVariantId.hashId,
          sv_type: compoundVariantId.hasOwnProperty('sv_type') ? (compoundVariantId as SvVariantId).sv_type : undefined,
          relation: compoundVariantId.relation,
        }
      : undefined,
    relation: variantId.relation,
  };
}

export function variantIdToHttpParamsObject(variantId: VariantId, supportFusion = false, isCompound = false): any {
  const svVariantId = variantId as SvVariantId;
  const mate = svVariantId.rna_type === RnaMarkerType.EXPRESSION_IMBALANCE ? svVariantId : svVariantId.mate;
  const prefix = isCompound ? 'compound_' : '';
  let result =
    'sv_type' in variantId
      ? {
          [`${prefix}chr`]: variantId.chr.startsWith('chr') ? variantId.chr : 'chr' + variantId.chr,
          [`${prefix}start_pos`]: variantId.start_pos.toString(),
          [`${prefix}end_pos`]: variantId.end_pos.toString(),
          [`${prefix}sv_type`]: variantId.sv_type === 'FUSION' && !supportFusion ? 'BREAKEND' : variantId.sv_type,
          [`${prefix}ci_pos`]: variantId.ci_pos ? variantId.ci_pos : '',
          [`${prefix}ci_end`]: variantId.ci_end ? variantId.ci_end : '',
          [`${prefix}mate_chr`]: mate ? mate.chr : null,
          [`${prefix}mate_start`]: mate ? mate.start_pos.toString() : null,
          [`${prefix}mate_end`]: mate ? mate.end_pos.toString() : null,
          [`${prefix}variant_id`]: variantId.hashId,
          [`${prefix}var_id`]: variantId.hashId,
          [`${prefix}var_type`]: variantId.rna_type ? 'rna' : 'sv',
          [`${prefix}version`]: variantId.annotationVersion ? variantId.annotationVersion.toString() : '',
          [`${prefix}repeat_unit`]: variantId.repeatData?.repeatUnit,
          [`${prefix}repeat_copy_number`]: variantId.repeatData?.repeatCopyNumber,
          analysis_id: variantId.analysisId ? variantId.analysisId.toString() : '',
        }
      : 'start_pos' in variantId
        ? {
            [`${prefix}loh_id`]: variantId.hashId,
            [`${prefix}chr`]: variantId.chr,
            [`${prefix}start_pos`]: variantId.start_pos.toString(),
            [`${prefix}end_pos`]: variantId.end_pos.toString(),
            [`${prefix}roh_regions`]: variantId.roh_regions.map(
              (value) => `${value.chrom}-${value.start_pos}-${value.end_pos}`,
            ),
          }
        : {
            [`${prefix}chr`]: variantId.chr.startsWith('chr') ? variantId.chr : 'chr' + variantId.chr,
            [`${prefix}pos`]: variantId.pos.toString(),
            [`${prefix}ref`]: variantId.ref,
            [`${prefix}alt`]: variantId.alt,
            [`${prefix}variant_id`]: variantId.hashId,
            [`${prefix}var_id`]: variantId.hashId,
            [`${prefix}version`]: variantId.annotationVersion ? variantId.annotationVersion.toString() : '',
            analysis_id: variantId.analysisId ? variantId.analysisId.toString() : '',
          };

  if (variantId.referenceGenome) {
    result = {
      ...result,
      reference_version: variantId.referenceGenome,
    };
  }

  if ((variantId as SvVariantId).rna_type) {
    result = {
      ...result,
      [`${prefix}sv_length`]: (variantId as SvVariantId).sv_length?.toString(),
    };
    if (
      [RnaMarkerType.ALTERNATIVE_SPLICING, RnaMarkerType.EXPRESSION_IMBALANCE].includes(
        (variantId as SvVariantId).rna_type,
      )
    ) {
      result = {
        ...result,
        [`${prefix}sv_type`]: (variantId as SvVariantId).rna_type,
      };
    }
  }
  return result;
}

export function variantIdToHttpParams(
  variantId: VariantId,
  supportFusion = false,
  compoundVariantId?: VariantId,
): HttpParams {
  let payload = variantIdToHttpParamsObject(variantId, supportFusion, false);
  if (!!compoundVariantId) {
    {
      payload = {
        ...payload,
        ...variantIdToHttpParamsObject(compoundVariantId, supportFusion, true),
      };
    }
  }
  const sanitizedPayload = Object.keys(payload)
    .filter((key) => payload[key] !== null && payload[key] !== undefined)
    .reduce((prev, cur) => ({ ...prev, [cur]: payload[cur] }), {});
  return new HttpParams({
    encoder: new CustomHttpParamEncoder(),
    fromObject: sanitizedPayload,
  });
}

export function variantIdToBody(variantId: VariantId): any {
  let result: any = 'sv_type' in variantId
    ? {
        variant: {
          chrom: variantId.chr.replace('chr', ''),
          start: variantId.start_pos,
          end: variantId.end_pos,
          sv_type: variantId.sv_type === 'FUSION' ? 'BREAKEND' : variantId.sv_type,
          ci_pos: {
            start: 0,
            end: 0,
          },
          ci_end: {
            start: 0,
            end: 0,
          },
          bnd_mate: variantId.mate
            ? {
                mate_chr: variantId.mate.chr,
                mate_start: variantId.mate.start_pos,
                mate_end: variantId.mate.end_pos,
              }
            : undefined,
          reference_version: variantId.referenceGenome,
        },
        version: variantId.annotationVersion,
        is_versioned_request: !!variantId.annotationVersion,
      }
    : 'start_pos' in variantId
      ? {
          variant: {
            chrom: variantId.chr.replace('chr', ''),
            start: variantId.start_pos,
            end: variantId.end_pos,
            reference_version: variantId.referenceGenome,
          },
          version: variantId.annotationVersion,
          is_versioned_request: !!variantId.annotationVersion,
          roh_regions: variantId.roh_regions.map((value) => `${value.chrom}-${value.start_pos}-${value.end_pos}`),
        }
      : {
          variant: {
            chrom: variantId.chr.replace('chr', ''),
            alt: variantId.alt,
            pos: variantId.pos,
            ref: variantId.ref,
            reference_version: variantId.referenceGenome,
          },
          version: variantId.annotationVersion,
          is_versioned_request: !!variantId.annotationVersion,
        };

  if (variantId.analysisId) {
    result = {
      ...result,
      analysis_id: variantId.analysisId
    };
  }

  return result;
}

export function variantIdToHttpParamsToBody(
  variantId: VariantId,
  supportFusion = false,
  compoundVariantId?: VariantId,
): any {
  const params = variantIdToHttpParams(variantId, supportFusion, compoundVariantId);
  return params.keys().reduce(
    (prev, cur) => ({
      ...prev,
      [cur]: params.get(cur),
    }),
    {},
  );
}

export function variantIdToBody2(
  variantId: VariantId,
  expandROHRegion: boolean = false,
  supportFusion = false,
  isCompound = false,
): any {
  const compoundPrefix = isCompound ? 'compound_' : '';

  switch (getVariantIdType(variantId)) {
    case VariantIdTypes.SV:
      const svVariantID = variantId as SvVariantId;

      const rnaType =
        svVariantID.rna_type !== RnaMarkerType.ALTERNATIVE_SPLICING &&
        svVariantID.rna_type !== RnaMarkerType.EXPRESSION_IMBALANCE
          ? svVariantID.sv_type
          : svVariantID.rna_type;

      if (svVariantID.rna_type) {
        return {
          [`${compoundPrefix}rna_variant`]: {
            chrom: svVariantID.chr,
            rna_type: rnaType,
            start_pos: svVariantID.start_pos.toString(),
            sv_length: svVariantID.sv_length,
            ci_pos: svVariantID.ci_pos,
            ci_end: svVariantID.ci_end,
          },
        };
      } else {
        const mate = svVariantID.rna_type === RnaMarkerType.EXPRESSION_IMBALANCE ? svVariantID : svVariantID.mate;
        return {
          [`${compoundPrefix}sv_variant`]: {
            chrom: svVariantID.chr,
            start_pos: svVariantID.start_pos.toString(),
            end_pos: svVariantID.end_pos.toString(),
            sv_type: svVariantID.sv_type === 'FUSION' && !supportFusion ? 'BREAKEND' : svVariantID.sv_type,
            reference_version: variantId.referenceGenome,
            bnd_mate: mate
              ? {
                  mate_chr: mate.chr,
                  mate_start: mate.start_pos,
                  mate_end: mate.end_pos,
                }
              : null,
            repeat_data: svVariantID.repeatData
              ? {
                  repeat_unit: svVariantID.repeatData.repeatUnit,
                  repeat_copy_number: svVariantID.repeatData.repeatCopyNumber,
                }
              : null,
          },
          variant_id: variantId.hashId,
          version: variantId.annotationVersion,
          is_versioned_request: !!variantId.annotationVersion,
        };
      }
    case VariantIdTypes.SNP:
      const snpVariantID = variantId as SnpVariantId;
      return {
        [`${compoundPrefix}variant`]: {
          chrom: snpVariantID.chr,
          alt: snpVariantID.alt,
          pos: snpVariantID.pos,
          ref: snpVariantID.ref,
          reference_version: variantId.referenceGenome,
        },
        version: variantId.annotationVersion,
        is_versioned_request: !!variantId.annotationVersion,
        notify_franklin_on_update: false,
      };
    case VariantIdTypes.ROH:
      const rohVariantID = variantId as RohVariantId;
      return {
        [`${compoundPrefix}loh`]: {
          chrom: rohVariantID.chr,
          start_pos: rohVariantID.start_pos,
          end_pos: rohVariantID.end_pos,
          reference_version: variantId.referenceGenome,
        },
        version: variantId.annotationVersion,
        is_versioned_request: !!variantId.annotationVersion,
      };
  }
}

export function variantIdToBody3(variantId: VariantId, supportFusion = false): any {
  switch (getVariantIdType(variantId)) {
    case VariantIdTypes.SV:
      const svVariantID = variantId as SvVariantId;
      const mate =
        svVariantID.rna_type === RnaMarkerType.EXPRESSION_IMBALANCE
          ? {
              mate_chr: svVariantID.chr,
              mate_start: svVariantID.start_pos,
              mate_end: svVariantID.end_pos,
            }
          : !!svVariantID.mate
            ? {
                mate_chr: svVariantID.mate.chr,
                mate_start: svVariantID.mate.start_pos,
                mate_end: svVariantID.mate.end_pos,
              }
            : null;

      return {
        chrom: svVariantID.chr,
        start_pos: svVariantID.start_pos.toString(),
        end_pos: svVariantID.end_pos.toString(),
        sv_type: svVariantID.sv_type === 'FUSION' && !supportFusion ? 'BREAKEND' : svVariantID.sv_type,
        reference_version: variantId.referenceGenome,
        bnd_mate: mate,
      };
    case VariantIdTypes.SNP:
      const snpVariantID = variantId as SnpVariantId;
      return {
        chrom: snpVariantID.chr,
        alt: snpVariantID.alt,
        pos: snpVariantID.pos,
        ref: snpVariantID.ref,
        reference_version: variantId.referenceGenome,
        version: snpVariantID.annotationVersion,
        analysis_id: snpVariantID.analysisId,
      };
    case VariantIdTypes.ROH:
      const rohVariantID = variantId as RohVariantId;
      return {
        chrom: rohVariantID.chr,
        start: rohVariantID.start_pos,
        end: rohVariantID.end_pos,
      };
  }
}

export function variantIdsToBody(variantIds: VariantId[]): any {
  return {
    variants: variantIds.map((variantId) => {
      return 'sv_type' in variantId
        ? {
            chrom: variantId.chr.replace('chr', ''),
            start: variantId.start_pos,
            end: variantId.end_pos,
            sv_type: variantId.sv_type,
            reference_version: variantId.referenceGenome,
            ci_pos: {
              start: 0,
              end: 0,
            },
            ci_end: {
              start: 0,
              end: 0,
            },
          }
        : 'start_pos' in variantId
          ? {
              chrom: variantId.chr.replace('chr', ''),
              start: variantId.start_pos,
              end: variantId.end_pos,
            }
          : {
              chrom: variantId.chr.replace('chr', ''),
              alt: variantId.alt,
              pos: variantId.pos,
              ref: variantId.ref,
            };
    }),
    version: variantIds[0].annotationVersion ? variantIds[0].annotationVersion : '12.0',
    is_versioned_request: !!variantIds[0].annotationVersion,
  };
}

export function variantIdEquals(first: VariantId, second: VariantId): boolean {
  if (!first || !second) {
    return false;
  }
  if ('sv_type' in first !== 'sv_type' in second) {
    return false;
  }
  if ('sv_type' in first && 'sv_type' in second) {
    return (
      first.start_pos === second.start_pos &&
      first.end_pos === second.end_pos &&
      first.sv_type === second.sv_type &&
      first.chr === second.chr
    );
  } else if ('start_pos' in first && 'start_pos' in second) {
    return first.start_pos === second.start_pos && first.end_pos === second.end_pos && first.chr === second.chr;
  } else if (!('sv_type' in first) && !('sv_type' in second) && !('start_pos' in first) && !('start_pos' in second)) {
    return first.pos === second.pos && first.ref === second.ref && first.alt === second.alt && first.chr === second.chr;
  }
}

export function variantIdStart(variantId: VariantId): number {
  return 'sv_type' in variantId ? variantId.start_pos : 'start_pos' in variantId ? variantId.start_pos : variantId.pos;
}

export function variantIdEnd(variantId: VariantId): number {
  return 'sv_type' in variantId ? variantId.end_pos : 'start_pos' in variantId ? variantId.end_pos : variantId.pos;
}

export function variantSearchTermToClassificationType(searchTerm: VariantIdTypes): 'GERMLINE' | 'TUMOR' {
  if ([VariantIdTypes.SNP, VariantIdTypes.SV, VariantIdTypes.ROH].includes(searchTerm)) {
    return 'GERMLINE';
  } else if ([VariantIdTypes.SNP_TUMOR, VariantIdTypes.SV_TUMOR].includes(searchTerm)) {
    return 'TUMOR';
  }
}
