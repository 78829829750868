import { Action } from '@ngrx/store';
import { SampleFileForUpload } from '../../models';

export const SUBMIT_SAMPLE_SHEET = '[Create Case] Submit sample sheet';
export const SUBMIT_SAMPLE_SHEET_SUCCESS = '[Create Case] Submit sample sheet success';
export const SUBMIT_SAMPLE_SHEET_FAIL = '[Create Case] Submit sample sheet fail';

export class SubmitSampleSheet implements Action {
  readonly type = SUBMIT_SAMPLE_SHEET;

  constructor(public sheetFile: SampleFileForUpload) {}
}

export class SubmitSampleSheetSuccess implements Action {
  readonly type = SUBMIT_SAMPLE_SHEET_SUCCESS;

  constructor() {}
}

export class SubmitSampleSheetFail implements Action {
  readonly type = SUBMIT_SAMPLE_SHEET_FAIL;

  constructor(
    public error: string,
    public errorDetails?: string,
  ) {}
}

export type CasesFromSampleSheetAction = SubmitSampleSheet | SubmitSampleSheetSuccess | SubmitSampleSheetFail;
