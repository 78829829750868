import { VariantHighlight } from './variant-highlights.model';
import { VariantWarnings } from './variant-warnings.model';
import { InterpretationTag, LatestComment } from './snp-result.model';
import { BaseVariant } from './analysis-result.model';

export interface SomaticEvidence {
  description: string;
  drug_type?: string;
  evidence_direction: number;
  evidence_disease: string;
  evidence_rating?: number;
  evidence_source: string;
  evidence_type?: string;
  evidence_url: string;
  evidence_url_label: string;
  evidence_level?: string;
  level: string;
  name?: string;
  outcome: boolean;
  positive: boolean;
  pubmed_id?: number;
  pubmed_ids?: number[];
  evidence_genes?: string[];
  responsive: boolean;
  trade_name?: string;
  tags?: string[];
  submission_date: number;
  submitter_email: string;
  submitter_name: string;
  submitter_repo: string;
  is_manually_called?: boolean;
  is_user_evidence?: boolean;
  drugs_data: {
    drug_type: string;
    trade_name: string;
    name: string;
  }[];
}

export interface SomaticSnpResult extends BaseVariant {
  gene: string;
  hgvs_p: string;
  hgvs_p_single_letter: string;
  exon: number;
  hgvs_c: string;
  chr: string;
  start_pos: number;
  end_pos: number;
  therapeutic_count: number;
  diagnostic_count: number;
  prognostic_count: number;
  vaf: number;
  depth: number;
  internal_percent: number;
  sample_count: number;
  confidence: number;
  quality: number;
  confidence_text: string;
  cosmic_ids: {
    cosmic_id: string;
    cosmic_url: string;
  }[];
  cosmic_frequencies: Record<string, number | null>;
  evidences: Record<'diagnostic' | 'prognostic' | 'therapeutic', { level: string }[][]>;
  var_id: string;
  classification: number;
  germline_classification: number;
  region: string;
  effect: string;
  region_value: number;
  classification_flag?: number;
  other_classification_flags?: number[];
  germline_classification_flag?: number;
  oncogenic_classification_flag?: number;
  other_germline_flags?: number[];
  other_oncogenic_flags?: number[];
  mark_as_clinically_irrelevant: boolean;
  include_in_report: boolean;
  in_workbench: boolean;
  ref: string;
  obs: string;
  highlights: VariantHighlight[];
  warnings: VariantWarnings[];
  latest_comment: LatestComment;
  sub_level_classification: string;
  transcript: string;
  latest_flag_for_disease: number;
  latest_sub_classification_for_disease: string;
  draft_classification_flag?: number;
  draft_sub_classification?: string;
  draft_germline_classification_flag?: number;
  draft_oncogenic_classification_flag?: number;
  aggregated_frequency: number;
  interpretation_tags: InterpretationTag[];
}

export interface SomaticSnpResultDetails {
  classification: number;
  var_id: string;
  polyphen2: number;
  hgvs_p: string;
  franklin_link: string;
  quality: number;
  polyphen2_pred: string;
  depth: number;
  mt: number;
  internal_percent: number;
  chr: string;
  evidences: {
    therapeutic: SomaticEvidence[][];
    prognostic: SomaticEvidence[][];
    diagnostic: SomaticEvidence[][];
  };
  start_pos: number;
  gene: string;
  end_pos: number;
  ref: string;
  sift_pred: string;
  aggregated_frequency: number;
  frequencies: {
    [key: string]: {
      af: number;
    };
  };
  germline_classification: number;
  cv_accession: any[];
  depth_obs: number;
  transcript: string;
  classification_flag?: any;
  depth_ref: number;
  sift: number;
  cosmic_ids: {
    cosmic_id: string;
    cosmic_url: string;
  }[];
  mt_pred: string;
  twinsuk_af?: any;
  fathmm_mkl_coding_score?: number;
  fathmm_mkl_coding_desc?: string;
  obs: string;
  hgvs_c: string;
  matched_normal: {
    match_success: string;
    normal_variant: {
      vaf: number;
      confidence_text: string;
      quality: number;
      depth: number;
      ref: string;
      depth_ref: number;
      obs: string;
      depth_obs: number;
    };
  };
  custom_user_annotations: {
    annotation_name: string;
    annotation_value: string;
  }[];
}
