import { VariantAssessment, VariantAssessmentsByCondition } from '../../models';

import * as fromAction from '../actions/assessments.action';

export interface AssessmentsState {
  assessments?: VariantAssessment[];
  assessmentsByCondition?: VariantAssessmentsByCondition[];
  loading: boolean;
  loaded: boolean;
  deleting: boolean;
  deleted: boolean;
}

const initialState: AssessmentsState = {
  assessments: null,
  assessmentsByCondition: [],
  loading: false,
  loaded: false,
  deleting: false,
  deleted: false,
};

export function reducer(state = initialState, action: fromAction.AssessmentsActions): AssessmentsState {
  switch (action.type) {
    case fromAction.LOAD_JOINT_VARIANT_ASSESSMENTS: {
      return {
        ...state,
        assessments: null,
        assessmentsByCondition: [],
        loading: true,
        loaded: false,
      };
    }
    case fromAction.LOAD_VARIANT_ASSESSMENTS_FAIL:
    case fromAction.LOAD_JOINT_VARIANT_ASSESSMENTS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case fromAction.DELETE_VARIANT_ASSESSMENT: {
      return {
        ...state,
        deleting: true,
        deleted: false,
      };
    }
    case fromAction.DELETE_VARIANT_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        assessments: state.assessments.filter(
          (x) => x.var_id !== action.varId && x.organization_id !== action.organization_id,
        ),
        deleting: false,
        deleted: true,
      };
    }
    case fromAction.DELETE_VARIANT_ASSESSMENT_FAIL: {
      return {
        ...state,
        deleting: false,
        deleted: false,
      };
    }
    case fromAction.LOAD_JOINT_VARIANT_ASSESSMENTS_SUCCESS: {
      return {
        ...state,
        assessmentsByCondition: action.assessmentsByCondition,
        loading: false,
        loaded: true,
      };
    }
  }

  return state;
}

export const getAssessmentsEntity = (state: AssessmentsState) => state.assessments;
export const getAssessmentsByConditionEntity = (state: AssessmentsState) => state.assessmentsByCondition;
export const getLoadingEntity = (state: AssessmentsState) => state.loading;
export const getLoadedEntity = (state: AssessmentsState) => state.loaded;
