import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  OPEN_SAVE_FILE_LINK_WINDOW,
  OPEN_SAVE_FILE_WINDOW,
  OpenSaveFileLinkWindow,
  OpenSaveFileWindow,
} from '../actions/save-file-window.action';
import { DownloadFileService } from '../../shared/services/download-file.service';

@Injectable()
export class SaveFileWindowEffect {
  constructor(
    private actions$: Actions,
    private downloadFileService: DownloadFileService,
  ) {}

  openSaveFileWindow$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OPEN_SAVE_FILE_WINDOW),
        tap((action: OpenSaveFileWindow) => {
          this.downloadFileService.downloadFile(action.data, action.name);
        }),
      ),
    { dispatch: false },
  );

  openSaveFileLinkWindow$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OPEN_SAVE_FILE_LINK_WINDOW),
        tap((action: OpenSaveFileLinkWindow) => {
          const downloadLink = document.createElement('a');
          downloadLink.href = new URL(action.link).href;
          downloadLink.target = '_blank';
          downloadLink.download = action.name;
          downloadLink.setAttribute('download', action.name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }),
      ),
    { dispatch: false },
  );
}
