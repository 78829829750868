import { FiltersStructureEffects } from './filters-structure.effect';
import { FilterTreeEffect } from './filter-tree.effects';
import { LastUsedFilterEffects } from './last-used-filter.effect';
import { DefaultFilterEffects } from './default-filter.effect';
import { PredefinedFiltersEffect } from './predefined-filters.effect';
import { OpenFilterDialogEffect } from './open-filter-dialog.effect';

export * from './filters-structure.effect';
export * from './filter-tree.effects';
export * from './last-used-filter.effect';
export * from './default-filter.effect';

export const filterEffects = [
  FiltersStructureEffects,
  FilterTreeEffect,
  LastUsedFilterEffects,
  DefaultFilterEffects,
  PredefinedFiltersEffect,
  OpenFilterDialogEffect,
];
