import * as Immutable from 'immutable';
import { SexType } from './create-case-options.model';
import { Sample } from './family-sample.model';
import { SampleFileForUpload } from './create-case.model';
import { AnalysisLean } from '../../analysis/modules/analyses/store';

export enum PedigreeType {
  PROBAND = 'proband',
  FATHER = 'father',
  MOTHER = 'mother',
  SIBLING = 'sibling',
  FATHER_RELATIVES = 'father relative',
  MOTHER_RELATIVES = 'mother relative',
}

export const MULTIPLE_TYPES = [PedigreeType.SIBLING, PedigreeType.MOTHER_RELATIVES, PedigreeType.FATHER_RELATIVES];

export const pedigreeToSexMap = Immutable.Map<PedigreeType, SexType>()
  .set(PedigreeType.MOTHER, SexType.FEMALE)
  .set(PedigreeType.FATHER, SexType.MALE);

export type PedigreeNodeId = string;

export interface SampleWithMetadata {
  sample: Sample;
  isAffected: boolean;
  sex: SexType;
}

export interface PedigreeNode {
  fatherNodeId: PedigreeNodeId | null;
  motherNodeId: PedigreeNodeId | null;

  type: PedigreeType;
  associatedSingleAnalysis: SampleWithMetadata | null;
}

export class PedigreeTree {
  private tree: Immutable.Map<PedigreeNodeId, PedigreeNode> = Immutable.Map();

  private get nextId() {
    const highest = this.tree
      .keySeq()
      .toArray()
      .map((key) => {
        const regex = /([a-zA-Z _-]+)(\d*)/g;
        const result = regex.exec(key);
        return parseInt(result[2], 10) || 1;
      })
      .reduce((prev, next) => (next > prev ? next : prev), 0);

    return highest;
  }

  public addProband(): PedigreeTree {
    const id = PedigreeType.PROBAND;
    const fatherId = this.getId(PedigreeType.FATHER, PedigreeType.PROBAND);
    const motherId = this.getId(PedigreeType.MOTHER, PedigreeType.PROBAND);
    return this.addNode(id, PedigreeType.PROBAND, fatherId, motherId);
  }

  public addParent(typeOfParent: PedigreeType.MOTHER | PedigreeType.FATHER, parentOf: PedigreeType): PedigreeTree {
    const id = this.getId(typeOfParent, parentOf);
    const fatherId = this.getId(PedigreeType.FATHER, typeOfParent);
    const motherId = this.getId(PedigreeType.MOTHER, typeOfParent);
    return this.addNode(id, typeOfParent, fatherId, motherId);
  }

  public addSibling(siblingOf: PedigreeType): PedigreeTree {
    const id = this.getId(PedigreeType.SIBLING, siblingOf);
    const fatherId = this.getId(PedigreeType.FATHER, siblingOf);
    const motherId = this.getId(PedigreeType.MOTHER, siblingOf);
    return this.addNode(id, PedigreeType.SIBLING, fatherId, motherId);
  }

  public addRelative(relativeType: PedigreeType): PedigreeTree {
    const id = this.getId(relativeType);
    return this.addNode(id, relativeType, null, null);
  }

  public getProbandId(): PedigreeNodeId {
    return PedigreeType.PROBAND;
  }

  public getSiblingsOf(nodeId: PedigreeNodeId): { id?: PedigreeNodeId; node?: PedigreeNode }[] {
    const node = this.tree.get(nodeId);
    if (!node) {
      throw new Error(`node with id '${nodeId}' does not exist`);
    }
    return this.tree
      .filter(
        (item) =>
          !!(
            item &&
            item.type === PedigreeType.SIBLING &&
            item.motherNodeId === node.motherNodeId &&
            item.fatherNodeId === node.fatherNodeId
          ),
      )
      .map((item?: PedigreeNode, id?: string) => ({ id, node: item }))
      .toArray();
  }

  public getByType(type: PedigreeType): { id?: PedigreeNodeId; node?: PedigreeNode }[] {
    return this.tree
      .filter((item) => !!(item && item.type === type))
      .map((item?: PedigreeNode, id?: string) => ({ id, node: item }))
      .toArray();
  }

  public get(id: PedigreeNodeId): PedigreeNode {
    return this.tree.get(id);
  }

  public set(id: PedigreeNodeId, node: PedigreeNode): PedigreeTree {
    const newTree = new PedigreeTree();
    newTree.tree = this.tree.set(id, node);
    return newTree;
  }

  public asMap(): Immutable.Map<PedigreeNodeId, PedigreeNode> {
    return this.tree;
  }

  public removeNode(id: PedigreeNodeId): PedigreeTree {
    const newTree = new PedigreeTree();
    newTree.tree = this.tree.delete(id);
    return newTree;
  }

  private addNode(
    id: PedigreeNodeId,
    type: PedigreeType,
    fatherNodeId: PedigreeNodeId | null,
    motherNodeId: PedigreeNodeId | null,
  ): PedigreeTree {
    const pedigreeNode: PedigreeNode = {
      type,
      fatherNodeId,
      motherNodeId,
      associatedSingleAnalysis: null,
    };

    const newTree = new PedigreeTree();
    newTree.tree = this.tree.set(id, pedigreeNode);

    return newTree;
  }

  public getId(type: PedigreeType, relatedToType?: PedigreeType) {
    let id = (relatedToType || '') + type;
    if (MULTIPLE_TYPES.includes(type)) {
      id = `${id}${this.nextId + 1}`;
    }
    return id;
  }

  public get associatedSamples(): SampleWithMetadata[] {
    return this.tree
      .toArray()
      .map((sample) => sample.associatedSingleAnalysis)
      .filter((sample) => !!sample);
  }

  public toObject() {
    return this.tree.toObject();
  }
}

export function createSampleWithMetadata(node: PedigreeNode, sample: Sample, sex: SexType): SampleWithMetadata {
  return {
    sample,
    isAffected: node.type === PedigreeType.PROBAND,
    sex: sex ? sex : pedigreeToSexMap.get(node.type) || SexType.UNKNOWN,
  };
}

export function createSampleFromFile(
  pedigreeNode: PedigreeNode,
  newSample: SampleFileForUpload | AnalysisLean | Sample,
  sex: SexType,
): SampleWithMetadata {
  const sample = new Sample();
  sample.name = !newSample.hasOwnProperty('id')
    ? newSample.hasOwnProperty('fileAutoId')
      ? (newSample as SampleFileForUpload).name
      : (newSample as SampleFileForUpload).name
    : (newSample as AnalysisLean).name;
  sample.id = (newSample as AnalysisLean).id || (newSample as SampleFileForUpload).fileAutoId;
  sample.sampleWithoutFile = (newSample as Sample).sampleWithoutFile;
  sample.annotationVersions = (newSample as AnalysisLean).versions;
  return createSampleWithMetadata(pedigreeNode, sample, sex);
}
