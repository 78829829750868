import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'gnx-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class TextComponent {
  @Input() size: string;
  @Input() lineHeight: string;
  @Input() color: string;
  @Input() fontWeight: string;
  @Input() direction: 'ltr' | 'rtl';
  @Input() align: 'left' | 'center' | 'right' | 'justify';
  @Input() display: 'inline' | 'inline-flex' | 'inline-block' | 'flex' | 'block';

  @HostBinding('style')
  get styles(): Partial<CSSStyleDeclaration> {
    return {
      display: this.display,
      direction: this.direction,
      textAlign: this.align,
      lineHeight: this.lineHeight,
      fontSize: this.size,
      color: this.color,
      fontWeight: this.fontWeight,
    };
  }
}
