<div class="search-container" matAutocompleteOrigin #origin="matAutocompleteOrigin">
  <div
    class="search-icon-container"
    *ngIf="!(searching$ | async)"
    [ngClass]="{ disabled: !searchControl.value }"
    gnxRtl
  >
    <img src="/assets/svg/analyze-tool.svg" class="search-icon" (click)="onSearch()" />
  </div>
  <gnx-dots-loader *ngIf="searching$ | async"></gnx-dots-loader>
  <input
    class="search-input"
    placeholder="{{ 'SEARCH_ANY_CASE' | i18n }}"
    [matAutocomplete]="casesAutocomplete"
    [matAutocompleteConnectedTo]="origin"
    [formControl]="searchControl"
    (keyup.esc)="onEscape()"
    (keydown.enter)="onSearch()"
  />
  <div class="search-error" *ngIf="((searchDone$ | async) && !(analyses$ | async)) || (searchError$ | async)">
    {{ searchError$ | async }}
  </div>
</div>
<mat-autocomplete #casesAutocomplete="matAutocomplete" panelWidth="600px" class="cases-autocomplete">
  <mat-option
    class="search-option"
    *ngFor="let analysis of analyses$ | async"
    [ngClass]="{ error: !analysis.id }"
    [value]="analysis.name"
    (onSelectionChange)="onSelect(analysis.id)"
  >
    <div *ngIf="analysis.id">
      <div
        class="analysis-option-name"
        [innerHTML]="analysis.name | highlight: searchControl.value : 'green-highlight bold-highlight' | safeHtml"
      ></div>
      <div class="analysis-option-creation">Created on: {{ analysis.created_at | date }}</div>
    </div>
    <div *ngIf="!analysis.id" class="analysis-option-error">
      <div class="analysis-option-error-title">No results found</div>
      <div>Try adjusting your search and check spelling</div>
    </div>
  </mat-option>
</mat-autocomplete>
