import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import { AuthModuleState, getUserDetails, SetRedirectAfterAuth } from './../store';
import { AuthService } from '../services/auth.service';
import { AnalysisToolComponent } from '../../analysis/containers/analysis-tool/analysis-tool.component';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  constructor(
    private store$: Store<AuthModuleState>,
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return combineLatest([this.authService.getToken(), this.store$.pipe(select(getUserDetails))]).pipe(
      filter(([token, userDetails]) => !token || !!userDetails),
      map(([token, userDetails]) => !!token && !!userDetails),
      tap((value) => {
        if (!value) {
          this.store$.dispatch(new SetRedirectAfterAuth(location.origin + state.url));
          route.parent.component === AnalysisToolComponent
            ? this.router.navigate(['/analysis-tool/join-cta'])
            : this.router.navigate(['/login']);
        }
      }),
      take(1),
    );
  }
}
