import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { Router } from '@angular/router';
import { loadWholeGenomePopup } from '../actions';

@Injectable()
export class WholeGenomePopupEffect {
  constructor(
    private actions$: Actions,
    private router: Router,
  ) {}

  loadWholeGenomePopup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadWholeGenomePopup),
        tap((action) =>
          this.router.navigate([{ outlets: { dialog: 'whole-genome/dialog' } }], {
            queryParamsHandling: 'preserve',
            state: action.config,
          }),
        ),
      ),
    { dispatch: false },
  );
}
