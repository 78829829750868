import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromReducer from '../reducers/export-as-excel.reducer';
import { getVariantsState } from '../reducers/index';

export const getExportAsExcelState: MemoizedSelector<any, any> = createSelector(
  getVariantsState,
  (state) => state.exportAsExcel,
);

export const getExportAsExcelLoading: MemoizedSelector<any, any> = createSelector(
  getExportAsExcelState,
  (state: fromReducer.ExportAsExcelState) => state.loading,
);

export const getExportAsExcelLoaded: MemoizedSelector<any, any> = createSelector(
  getExportAsExcelState,
  (state: fromReducer.ExportAsExcelState) => state.loaded,
);
