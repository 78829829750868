import { VariantType } from './variant-type.model';
import { SnpResult } from './snp-result.model';
import { SvResult } from './sv-result.model';
import { SomaticSnpResult } from './somatic-snp-result.model';
import { SomaticSvResult } from './somatic-sv-result.model';
import { OvergrowthSnpResult } from './overgrowth-snp.result.model';
import { VariantHighlight } from './variant-highlights.model';
import {
  SnpVariantId,
  SvVariantId,
  VariantId,
  VariantIdTypes,
} from '../../../../variant-page/modules/variant-page/models';
import { AnalysisType } from '../../../../../store/models';
import { LohResult } from './loh-result.model';
import { COMPOUND_ID_SEPARATOR } from '../../workbench/utils/analysis-result.utils';

export const sortInheritance = (a: string, b: string) => {
  const weights = {
    AR: 2,
    AD: 1,
  };
  return (weights[b] || 0) - (weights[a] || 0);
};

export interface IgvLink {
  loaded: boolean;
  loading: boolean;
  link?: string;
  custom_link?: string;
}

export interface BaseVariant {
  // igvLink: IgvLink;
}

export interface AnalysisSingleVariant {
  type: VariantType;
  data: AnalysisResultSingleData;
  is_classified?: boolean;
  latest_annotated_version?: number; // this is currently used in discovery where each variant can have diff version
  reference_version?: string; // this is currently filled in discovery, cuz in case ref version is from sample
}

export interface AnalysisCompoundVariant {
  type: VariantType;
  gene?: string;
  highlights: VariantHighlight[];
  data: {
    variants: AnalysisSingleVariant[];
    in_workbench?: boolean;
    in_report?: boolean;
    irrelevant?: boolean;
  };
  reference_version?: string;
}

export type AnalysisResult = AnalysisSingleVariant | AnalysisCompoundVariant;
export type AnalysisResultSingleData =
  | SnpResult
  | SvResult
  | SomaticSnpResult
  | SomaticSvResult
  | OvergrowthSnpResult
  | LohResult;

export function getAnalysisResultVariantId(result: AnalysisResult): string | undefined {
  return result.type === VariantType.Compound
    ? (result as AnalysisCompoundVariant).data.variants
        .map((v) => getAnalysisResultSingleDataVariantId(v.type, v.data))
        .sort()
        .join(COMPOUND_ID_SEPARATOR)
    : getAnalysisResultSingleDataVariantId(result.type, (result as AnalysisSingleVariant).data);
}

export function getAnalysisResultSingleDataVariantId(variantType: VariantType, data: AnalysisResultSingleData) {
  return variantType === VariantType.SNP || variantType === VariantType.RNA
    ? (data as SnpResult).var_id
    : variantType === VariantType.SV
      ? (data as SvResult).variant_data?.var_id || (data as SomaticSvResult).var_id
      : variantType === VariantType.LOH
        ? (data as LohResult).loh_data.id
        : undefined;
}

export function getAnalysisResultClinclaSignificanceSelection(result: AnalysisResult): {
  varType: VariantType;
  varId: string;
  compoundVarId?: string;
} {
  const varId =
    result.type === VariantType.Compound
      ? getAnalysisResultVariantId((result as AnalysisCompoundVariant).data.variants[0])
      : getAnalysisResultVariantId(result);
  const compoundVarId =
    result.type === VariantType.Compound
      ? getAnalysisResultVariantId((result as AnalysisCompoundVariant).data.variants[1])
      : undefined;

  return {
    varType: result.type,
    varId,
    compoundVarId,
  };
}

export function analysisResultToVariantPageVariantId(
  variant: AnalysisResultSingleData,
  referenceGenome: string,
  annotationVersion: number,
  analysisId: number,
  analysisType: AnalysisType,
  variantType: VariantType,
): VariantId {
  switch (variantType) {
    case VariantType.SNP: {
      const data = variant as SnpResult | SomaticSnpResult;
      const result: SnpVariantId = {
        chr: data.chr,
        pos: data.start_pos,
        ref: data.ref,
        alt: data.obs,
        hashId: data.var_id,
        referenceGenome,
        annotationVersion,
        analysisId,
        analysisType,
        variantIdType: analysisType !== AnalysisType.tumor ? VariantIdTypes.SNP_TUMOR : VariantIdTypes.SNP,
      };
      if (analysisType !== AnalysisType.tumor) {
        result.isManuallyCalled = (data as SnpResult).is_manually_called;
      }
      return result;
    }
    case VariantType.SV: {
      if (analysisType === AnalysisType.sample) {
        const data = variant as SvResult;
        const result: SvVariantId = {
          chr: data.variant_data.position.chromosome,
          start_pos: data.variant_data.position.start,
          end_pos: data.variant_data.position.end,
          sv_type: data.variant_data.variation_type.name,
          ci_pos: `${data.variant_data.cipos.first},${data.variant_data.cipos.second}`,
          ci_end: `${data.variant_data.ciend.first},${data.variant_data.ciend.second}`,
          hashId: data.variant_data.var_id,
          referenceGenome,
          annotationVersion,
          analysisId,
          analysisType,
          sv_length: data.variant_data.sv_length,
          isManuallyCalled: data.is_manually_called,
          variantIdType: VariantIdTypes.SV,
        };
        return result;
      } else {
        // Somatic
        const data = variant as SomaticSvResult;
        const result: SvVariantId = {
          chr: data.chr,
          start_pos: data.start_pos,
          end_pos: data.end_pos,
          sv_type: data.variation_type.name,
          ci_pos: `${data.cipos.first},${data.cipos.second}`,
          ci_end: `${data.ciend.first},${data.ciend.second}`,
          hashId: data.var_id,
          referenceGenome,
          annotationVersion,
          analysisId,
          analysisType,
          sv_length: data.sv_length,
          isManuallyCalled: data.is_manually_called,
          variantIdType: VariantIdTypes.SV_TUMOR,
          mate: data.breakend_mates?.length
            ? {
                chr: data.breakend_mates[0].position.chromosome,
                start_pos: data.breakend_mates[0].position.start,
                end_pos: data.breakend_mates[0].position.end,
              }
            : undefined,
        };
        return result;
      }
    }
  }
}
