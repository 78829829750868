import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromReducer from '../reducers/follow-variant.reducer';
import { getAppModuleState, VariantPageModuleState } from '../reducers';

export const getFollowVariantState: MemoizedSelector<VariantPageModuleState, fromReducer.FollowVariantState> =
  createSelector(getAppModuleState, (state: VariantPageModuleState) => state.followVariant);

export const getFollowVariantLoading: MemoizedSelector<VariantPageModuleState, boolean> = createSelector(
  getFollowVariantState,
  fromReducer.getLoading,
);

export const getFollowVariantLoaded: MemoizedSelector<VariantPageModuleState, boolean> = createSelector(
  getFollowVariantState,
  fromReducer.getLoaded,
);

export const getIsVariantFollowed: MemoizedSelector<VariantPageModuleState, boolean> = createSelector(
  getFollowVariantState,
  fromReducer.getFollowed,
);
