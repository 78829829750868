import { createSelector, MemoizedSelector } from '@ngrx/store';
import { InterpretationCustomFieldsState } from '../reducers/interpretation-custom-fields.reducer';
import { DynamicInputField } from '../../../../../../shared/models';
import { getVariantInterpretationState, VariantInterpretationState } from '../reducers';

const getInterpretationCustomFieldsState: MemoizedSelector<
  VariantInterpretationState,
  InterpretationCustomFieldsState
> = createSelector(
  getVariantInterpretationState,
  (state: VariantInterpretationState) => state.interpretationCustomFields,
);

export const getInterpretationCustomFields: MemoizedSelector<VariantInterpretationState, DynamicInputField[]> =
  createSelector(getInterpretationCustomFieldsState, (state: InterpretationCustomFieldsState) => state.fields);
