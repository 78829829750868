import { createSelector, MemoizedSelector, MemoizedSelectorWithProps } from '@ngrx/store';
import * as fromReducer from '../reducers/panels.reducer';
import { PanelsState } from '../reducers/panels.reducer';
import { AppModuleState, getAppModuleState } from '../reducers';
import { PanelSelectionItem, PanelSuggestion } from '../models';

const getPanelsState: MemoizedSelector<AppModuleState, PanelsState> = createSelector(
  getAppModuleState,
  (state: AppModuleState) => state.panelList,
);

export const getPanels: MemoizedSelector<AppModuleState, PanelSuggestion[]> = createSelector(
  getPanelsState,
  fromReducer.getPanels,
);

export const getPanelSuggestions: MemoizedSelector<AppModuleState, PanelSelectionItem[]> = createSelector(
  getPanelsState,
  fromReducer.getPanelSuggestions,
);

export const getPanelSuggestionsWithCasePanels: MemoizedSelector<AppModuleState, PanelSelectionItem[]> = createSelector(
  getPanelsState,
  (state) => mergeCasePanelsWithSuggestions(state.panelSuggestions, state.casePanelSuggestions),
);

export const getPanelGenes: MemoizedSelectorWithProps<AppModuleState, { panelId: string }, any[]> = createSelector(
  getPanelsState,
  fromReducer.getPanelGenes,
);

export const getPanelsLoading: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getPanelsState,
  fromReducer.getPanelsLoading,
);

export const getPanelsLoaded: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getPanelsState,
  fromReducer.getPanelsLoaded,
);

export const getPanelsError: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getPanelsState,
  fromReducer.getPanelsError,
);

export const getAllPanelsWithVersions: MemoizedSelector<AppModuleState, PanelSelectionItem[]> = createSelector(
  getPanelsState,
  (state) => state.allPanelsWithVersions,
);

export const getAllPanelsWithVersionsLoaded: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getPanelsState,
  (state) => state.allPanelsWithVersionsLoaded,
);

const mergeCasePanelsWithSuggestions = (suggestions: PanelSelectionItem[], casePanels: PanelSelectionItem[]) =>
  (suggestions || []).map(
    (suggestion) => casePanels.find((casePanel) => casePanel.latest_version_panel_id === suggestion.id) || suggestion,
  );
