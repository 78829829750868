import { VariantList } from '../models';
import { createReducer, on } from '@ngrx/store';

import * as fromAction from './../actions/variant-list.action';

export interface VariantListsState {
  lists: VariantList[];
  loading: boolean;
  loaded: boolean;
  errorLoading: any;
}

const initialState: VariantListsState = {
  lists: [],
  loading: false,
  loaded: false,
  errorLoading: null,
};

export const reducer = createReducer(
  initialState,
  on(fromAction.loadVariantLists, (state) => ({
    ...state,
    lists: [],
    loading: true,
    loaded: false,
    errorLoading: null,
  })),
  on(fromAction.loadVariantListsSuccess, (state, { data }) => ({
    ...state,
    lists: data,
    loading: false,
    loaded: true,
    errorLoading: null,
  })),
  on(fromAction.loadVariantListsFail, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: false,
    errorLoading: error,
  }))
);
