import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gnx-privacy-side-panel',
  templateUrl: './privacy-side-panel.component.html',
  styleUrls: ['./privacy-side-panel.component.scss'],
  standalone: true,
})
export class PrivacySidePanelComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
