import { Action } from '@ngrx/store';

export const SUBMIT_TO_CLINVAR = '[Variant Page] Submit to clinvar';
export const SUBMIT_TO_CLINVAR_SUCCESS = '[Variant Page] Submit to clinvar success';
export const SUBMIT_TO_CLINVAR_FAIL = '[Variant Page] Submit to clinvar fail';

export class SubmitToClinvar implements Action {
  readonly type = SUBMIT_TO_CLINVAR;

  constructor(public payload: string) {}
}

export class SubmitToClinvarSuccess implements Action {
  readonly type = SUBMIT_TO_CLINVAR_SUCCESS;
}

export class SubmitToClinvarFail implements Action {
  readonly type = SUBMIT_TO_CLINVAR_FAIL;
}

export type ClinvarAction = SubmitToClinvar | SubmitToClinvarSuccess | SubmitToClinvarFail;
