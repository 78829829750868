export interface AnalysisFilters {
  search?: string;
  assignee?: string[];
  status?: string[];
  assay?: string[];
  customLabel?: string[];
  activeStep?: string[];
  sort?: string;
  sortDir?: string;
  viewMode?: 'cases' | 'batch';
}
