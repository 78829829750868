import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageComponent } from './containers/home-page/home-page.component';
import { SearchComponent } from './components/search/search.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CitationsPageComponent } from './containers/citations-page/citations-page.component';
import { CitationsTileComponent } from './components/citations-tile/citations-tile.component';
import { MomentModule } from 'ngx-moment';
import { MatSelectModule } from '@angular/material/select';
import { HomePageInitParamsResolver } from './containers/home-page/home-page-init-params.resolver';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatTooltipModule,
    MomentModule,
    MatSelectModule,
    HomePageComponent,
    SearchComponent,
    CitationsPageComponent,
    CitationsTileComponent,
  ],
  providers: [HomePageInitParamsResolver],
})
export class HomePageModule {}
