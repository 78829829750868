import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import * as fromDiscussionStore from '../../../discussion/store';
import { DiscussionModuleState } from '../../../discussion/store';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  SET_SELECTED_APP,
  SET_VARIANT_POPUP_OPEN,
  SetSelectedApp,
  SetSelectedAppSuccess,
  SetVariantPopupOpen,
} from '../actions';
import * as fromWBActions from '../../../../../analysis/modules/workbench/store/actions';

@Injectable()
export class ConfigEffect {
  constructor(
    private actions$: Actions,
    private discussionStore$: Store<DiscussionModuleState>,
  ) {}

  setVariantPopupState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_VARIANT_POPUP_OPEN),
      withLatestFrom(this.discussionStore$.pipe(select(fromDiscussionStore.getDiscussionsChangedState))),
      switchMap(([action, discussionsChangedState]: [SetVariantPopupOpen, boolean]) => {
        if (!action.open && discussionsChangedState) {
          return of(new fromWBActions.ReloadAnalysisVariants());
        } else {
          return [];
        }
      }),
    ),
  );

  setSelectedApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_SELECTED_APP),
      switchMap((action: SetSelectedApp) => {
        return of(new SetSelectedAppSuccess(action.app));
      }),
    ),
  );
}
