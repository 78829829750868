import { RohVariantId, SnpVariantId, SvVariantId, VariantId } from '../models';
import { SnpVariantDetails } from '../../modules/variant-page/modules/variant-page/models';

export interface VariantSearchResponse {
  response_type: 'SV_VARIANT' | 'SNP_VARIANT' | 'GENE';
  sv_variants: SvVariant[];
  snp_variants: SnpVariant[];
  roh_regions: RohVariant[];
  best_variant_option: {
    warnings: SearchWarning[];
  };
  gene?: string;
}

export interface VariantMultiSearchResponse {
  single_variant_response?: VariantSearchResponse;
  multiple_snp_variants_response?: {
    parsed_variants: SnpVariantDetails[];
    failed_input: string[];
  };
  errors?: string[];
}

export interface SvVariant {
  chrom: string;
  start_pos: number;
  end_pos: number;
  sv_type: string;
  transcripts?: string[];
  canonical_tanscript?: string;
  reference_version?: string;
  copy_number?: number;
}

export interface SnpVariant {
  chrom: string;
  pos: number;
  ref: string;
  alt: string;
  transcripts?: string[];
  canonical_tanscript?: string;
  reference_version?: string;
}

export interface RohVariant {
  chrom: string;
  start_pos: number;
  end_pos: number;
}

export interface CaseContext {
  birth_date?: number;
  description?: string;
  sex?: string;
  phenotypes?: string[];
  ethnicity?: string[];
  zygosity?: string;
  family_inheritance_status?: string;
  reported_classification?: string;
  consanguinity?: string;
  search_term?: string;
}

export interface SearchContext {
  search_session_id: string;
  search_input_text: string;
  search_timestamp: number;
  snp_variant: SnpVariant;
  sv_variant: SvVariant;
  roh_variant: RohVariant[];
  case_context: CaseContext;
}

export interface SearchHistoryResponse {
  search_context?: SearchContext;
}

export enum SearchWarningType {
  UNSUPPORTED_TRANSCRIPT = 'UNSUPPORTED_TRANSCRIPT',
}

export interface UnsupportedTranscriptWarning {
  unsupported_transcript: string;
  supported_transcript: string;
  warning_type: SearchWarningType.UNSUPPORTED_TRANSCRIPT;
}

export type SearchWarning = UnsupportedTranscriptWarning;

export function parseAsVariantId(variant: SvVariant | SnpVariant | RohVariant[]): VariantId {
  return 'sv_type' in variant
    ? ({
        chr: variant.chrom,
        start_pos: variant.start_pos,
        end_pos: variant.end_pos,
        sv_type: variant.sv_type,
        referenceGenome: variant.reference_version,
        copy_number: variant.copy_number,
      } as SvVariantId)
    : 'ref' in variant
      ? ({
          chr: variant.chrom,
          pos: variant.pos,
          ref: variant.ref,
          alt: variant.alt,
          referenceGenome: variant.reference_version,
        } as SnpVariantId)
      : ({
          chr: variant[0].chrom,
          start_pos: variant[0].start_pos,
          end_pos: variant[0].end_pos,
          roh_regions: variant,
        } as RohVariantId);
}
