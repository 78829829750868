import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { AnalysisType } from '../../../../../../store/models';
import { PredefinedFilter } from '../models/predefined-filter.model';
import { FilterGroup, SelectOption } from '../../../shared/models';
import { VariantType } from '../../../analysis-variant/models';
import { environment } from '../../../../../../../environments/environment';
import { select, Store } from '@ngrx/store';
import * as fromRootStore from '../../../../../../store';
import { AppModuleState, getQueryParams } from '../../../../../../store';
import { filter, map, switchMap, take } from 'rxjs/operators';
import * as fromFiltersStore from '../reducers';
import { getFilterTreeLabels, getFilterTreeLabelsLoaded, getFlattenedFiltersStructureEntities } from '../selectors';
import { Params } from '@angular/router';
import { HIGHLIGHT_IGNORED_FILTERS } from '../../consts/filter.const';
import { isEqual as _isEqual } from 'lodash';
import { DiscoveryAnalysisType } from '../../../../../discovery/store/models/discovery.model';
import {
  FilterTree,
  FilterTreeInfo,
  FilterTreeLabels,
  FilterTreeOptions,
  UpdateQuickFiltersRequest,
} from '../models/filter-tree.model';
import { FilterStructure } from '../../../shared/models/filter-structure.model';
import { StringFilter } from '../../../shared/models/string-filter';

@Injectable()
export class FiltersService {
  constructor(
    private http: HttpClient,
    private rootStore$: Store<AppModuleState>,
    private store$: Store<fromFiltersStore.FiltersState>,
  ) {}

  getFilterTreeOptions(analysisType: AnalysisType, variantType: VariantType): Observable<FilterTreeOptions> {
    const variantTypeParam: string = variantType ? `/${variantType}` : '';
    const url = `${environment.beBaseURL}/api/filter_tree/${analysisType}${variantTypeParam}/filters_metadata`;
    return this.http.post<FilterTreeOptions>(url, {});
  }

  getFilterTreeLabels(
    analysisId: number,
    analysisType: AnalysisType,
    variantType: VariantType,
  ): Observable<FilterTreeLabels> {
    const variantTypeParam: string = variantType ? `/${variantType}` : '';
    const url = `${environment.beBaseURL}/api/filter_tree/${analysisType}${variantTypeParam}/get_calculated_labels`;
    return this.http.post<FilterTreeLabels>(url, { analysis_id: analysisId });
  }

  getFilterTreeDefinition(
    treeId: string,
    analysisType: AnalysisType,
    variantType: VariantType,
  ): Observable<FilterTree> {
    // const url = '/assets/json/filter-tree-definition.json';
    // return this.http.get<FilterTree>(url);

    const variantTypeParam: string = variantType ? `/${variantType}` : '';
    const url = `${environment.beBaseURL}/api/filter_tree/${analysisType}${variantTypeParam}/get_tree_definition`;
    return this.http.post<FilterTree>(url, { tree_id: treeId });
  }

  getFilterTree(
    analysisId: number,
    treeId: string,
    analysisType: AnalysisType,
    variantType: VariantType,
  ): Observable<FilterTree> {
    const variantTypeParam: string = variantType ? `/${variantType}` : '';
    const url = `${environment.beBaseURL}/api/filter_tree/${analysisType}${variantTypeParam}/view_tree`;
    return this.http.post<FilterTree>(url, { tree_id: treeId, analysis_id: analysisId });
  }

  getFilterTrees(): Observable<FilterTreeInfo[]> {
    const url = `${environment.beBaseURL}/api/filter_tree/common/get_all_trees`;
    return this.http.post<{ trees_metadata: FilterTreeInfo[] }>(url, {}).pipe(map((result) => result.trees_metadata));
  }

  createFilterTree(
    tree: FilterTree,
    analysisType: AnalysisType,
    variantType: VariantType,
  ): Observable<{ tree_id: string }> {
    const variantTypeParam: string = variantType ? `/${variantType}` : '';
    const url = `${environment.beBaseURL}/api/filter_tree/${analysisType}${variantTypeParam}/create_new_tree`;
    return this.http.post<{ tree_id: string }>(url, tree);
  }

  updateFilterTree(tree: FilterTree, analysisType: AnalysisType, variantType: VariantType): Observable<FilterTreeInfo> {
    const variantTypeParam: string = variantType ? `/${variantType}` : '';
    const url = `${environment.beBaseURL}/api/filter_tree/${analysisType}${variantTypeParam}/update_metadata`;
    const body = {
      tree_id: tree.tree.tree_id,
      name: tree.tree.name,
      description: tree.tree.description || '',
      assays: tree.tree_assays,
      use_phenotypes_as_filter: tree.tree.use_phenotypes_as_filter,
    };
    return this.http.post<{ updated_tree: FilterTreeInfo }>(url, body).pipe(map((result) => result.updated_tree));
  }

  markFilterTreeVerified(filterTreeId: string, analysisId: number, value: boolean) {
    const url = `${environment.beBaseURL}/api/filter_tree/mark_label_check_status`;
    const body = {
      analysis_id: analysisId,
      labels: [filterTreeId],
      is_checked: value,
    };

    return this.http.post(url, body);
  }

  deleteFilterTree(filterTreeId: string, analysisType: AnalysisType, variantType: VariantType) {
    const variantTypeParam: string = variantType ? `/${variantType}` : '';
    const url = `${environment.beBaseURL}/api/filter_tree/${analysisType}${variantTypeParam}/delete`;
    const body = {
      tree_id: filterTreeId,
    };
    return this.http.post<{ tree_id: string }>(url, body);
  }

  getFiltersStructure(
    analysisId: number,
    analysisType: AnalysisType,
    variantType = VariantType.All,
    discoveryAnalysisType?: DiscoveryAnalysisType,
  ): Observable<FilterGroup[]> {
    const url = `${environment.beBaseURL}/api/api/analysis-filters/options/${analysisType}/${variantType}`;
    let params: Params;

    if (analysisId) {
      params = { analysis_id: analysisId };
    } else if (discoveryAnalysisType) {
      params = { discovery_analysis_type: discoveryAnalysisType };
    }

    return this.http.get<FilterGroup[]>(url, { params });
  }

  getDefaultFilter(analysisType: AnalysisType, analysisId: number, variantType = VariantType.All): Observable<any> {
    const url = `${environment.beBaseURL}/api/api/analysis-filters/default/${analysisType}/${variantType}${
      analysisId ? `/${analysisId}` : ''
    }`;

    const headers: HttpHeaders = new HttpHeaders();
    headers.set('Content-Type', 'application/json');

    return this.http.get(url);
  }

  getLastUsedFilter(analysisType: AnalysisType, analysisId: number, variantType = VariantType.All): Observable<any> {
    const url = `${environment.beBaseURL}/api/api/analysis-filters/last-used/${analysisType}/${variantType}${
      analysisId ? `/${analysisId}` : ''
    }`;

    const headers: HttpHeaders = new HttpHeaders();
    headers.set('Content-Type', 'application/json');

    return this.http.get(url);
  }

  getSavedFilters(
    analysisId: number,
    analysisType: string,
    variantType = VariantType.All,
  ): Observable<PredefinedFilter[]> {
    let params = new HttpParams();
    if (analysisId) {
      params = params.set('analysis_id', analysisId?.toString());
    }
    const url = `${environment.beBaseURL}/api/api/analysis-filters/${analysisType}/${variantType}`;
    // const url = '/assets/json/predefined-filters.json';
    return this.http.get<PredefinedFilter[]>(url, { params });
  }

  updateSavedFilters(analysisId: number, filters: PredefinedFilter[]): Observable<PredefinedFilter[]> {
    const params = new HttpParams().set('analysis_id', analysisId.toString());
    return this.http.patch<PredefinedFilter[]>(`${environment.beBaseURL}/api/api/analysis-filters/bulk`, filters, {
      params,
    });
  }

  updateQuickFiltersMetadata(
    analysisType: AnalysisType,
    variantType: VariantType,
    payload: UpdateQuickFiltersRequest,
  ): Observable<boolean> {
    const variantTypeParam: string = variantType ? `/${variantType}` : '';
    const url = `${environment.beBaseURL}/api/filter_tree/${analysisType}${variantTypeParam}/update_labels_metadata`;
    return this.http.post<{ success: boolean }>(url, payload).pipe(map((result) => !!result.success));
  }

  createSavedFilter(
    analysisId: number,
    analysisType: string,
    variantType: VariantType,
    filter: PredefinedFilter,
  ): Observable<PredefinedFilter> {
    let params = new HttpParams();
    if (analysisId) {
      params = params.set('analysis_id', analysisId?.toString());
    }
    const url = `${environment.beBaseURL}/api/api/analysis-filters/${analysisType}/${variantType}`;
    return this.http.post<PredefinedFilter>(url, filter, { params });
  }

  deleteSavedFilter(filterId: number): Observable<any> {
    return this.http.delete(`${environment.beBaseURL}/api/api/analysis-filters/${filterId}`);
  }

  generateOtherPanelsFilterOptions(otherPanels: string[]): Observable<SelectOption[]> {
    // this is just safety, it's best if the panels are preloaded in the guard
    this.rootStore$
      .pipe(
        select(fromRootStore.getAllPanelsWithVersionsLoaded),
        take(1),
        filter((loaded) => !loaded),
      )
      .subscribe(() => this.rootStore$.dispatch(new fromRootStore.GetPanelsWithAllVersions()));

    return this.rootStore$.pipe(
      select(fromRootStore.getAllPanelsWithVersionsLoaded),
      filter((loaded) => loaded),
      take(1),
      switchMap(() => {
        return this.rootStore$.pipe(select(fromRootStore.getAllPanelsWithVersions), take(1)).pipe(
          map((res) => {
            const otherPanelsFilterOptions: SelectOption[] = otherPanels.map((panel) => {
              const panelData = res.find((x) => x.id === panel || `-${x.id}` === panel);
              return panelData
                ? {
                    name: panelData.name,
                    value: panelData.id,
                    selected: !panel.startsWith('-'),
                    selected_exclude: panel.startsWith('-'),
                    version: panelData.version,
                    latest_version: panelData.latest_version_panel_id,
                    public: panelData.public,
                  }
                : {
                    name: 'UNABLE TO LOCATE PANEL',
                    value: panel,
                    selected: true,
                  };
            });

            return otherPanelsFilterOptions;
          }),
        );
      }),
    );
  }

  getActivePredefinedFilters$(): Observable<string[]> {
    return combineLatest([
      this.store$.pipe(select(getFilterTreeLabelsLoaded)),
      this.store$.pipe(select(getFilterTreeLabels)),
      this.rootStore$.pipe(select(getQueryParams)),
      this.store$.pipe(select(getFlattenedFiltersStructureEntities))
    ]).pipe(
      filter(([filtersLoaded]) => filtersLoaded),
      map(([, labels, queryParams, filterStructure]) => {
        const normalizedQueryParams = this.normalizeFilter(queryParams, filterStructure);

        const presetIds = this.getPresetIds(labels, normalizedQueryParams, filterStructure);
        if (presetIds?.length) {
          return presetIds;
        }

        return this.getLabelIds(labels, normalizedQueryParams);
      })
    );
  }

  private getPresetIds(labels: FilterTreeLabels, normalizedQueryParams: any, filterStructure: any): string[] {
    return labels?.gv_labels
      .filter(preset => this.compareFilters(
        this.normalizeFilter(JSON.parse(preset.raw_filter_value), filterStructure),
        normalizedQueryParams,
        filterStructure
      ))
      .map(preset => preset.label_id);
  }

  private getLabelIds(labels: FilterTreeLabels, normalizedQueryParams: any): string[] {
    if (labels?.labels.length || labels?.quick_filter_labels.length) {
      return [...labels.labels, ...labels.quick_filter_labels]
        .filter(label => label.label_id === normalizedQueryParams?.selected_label_id?.[0])
        .map(label => label.label_id);
    }
    return [];
  }

  getActivePredefinedFiltersTreeIds$(): Observable<string[]> {
    const filterTreeLabels$ = this.store$.pipe(select(getFilterTreeLabels));
    const queryParams$ = this.rootStore$.pipe(select(getQueryParams));
    const filtersLoaded$ = this.store$.pipe(select(getFilterTreeLabelsLoaded));

    return combineLatest([filtersLoaded$, filterTreeLabels$, queryParams$]).pipe(
      filter(([filtersLoaded]: [boolean, FilterTreeLabels, Params]) => filtersLoaded),
      map(([, labels, queryParams]: [boolean, FilterTreeLabels, Params]) => {
        if (labels?.labels.length) {
          return labels.labels
            .filter((label) => label.label_id === queryParams.selected_label_id)
            .map((label) => label.containing_trees?.[0]?.tree_id);
        }
        return [];
      }),
    );
  }

  normalizeFilter(data, filterStructure: { [id: string]: FilterStructure }, ignorePanels = false): any {
    const PANEL_IGNORED_FILTERS = ignorePanels ? ['panel', 'kb_panels', 'other_panels'] : [];

    const isDefaultDropdown = (value: string, def: FilterStructure): boolean => {
      if (def?.type !== 'string') {
        return false;
      }
      if (!value?.length) {
        return false;
      }
      const stringVal = typeof value === 'string' ? value : value[0];
      return (def as StringFilter)?.options
        ?.filter((x) => !!x.selected)
        ?.map((x) => x.value)
        ?.includes(stringVal);
    };

    return Object.keys(data)
      .filter(
        (key) =>
          !HIGHLIGHT_IGNORED_FILTERS.includes(key) &&
          !PANEL_IGNORED_FILTERS.includes(key) &&
          !!data[key].length &&
          !isDefaultDropdown(data[key], filterStructure[key]),
      )
      .reduce(
        (acc, cur) => ({
          ...acc,
          [cur]: [...(typeof data[cur] === 'string' ? [data[cur]] : data[cur])].sort(),
        }),
        {},
      );
  }

  private compareFilters(
    quickFilter: { [key: string]: any[] },
    curFilter: { [key: string]: any[] },
    filterStructure: { [id: string]: FilterStructure },
  ) {
    const quickFilterPanels = [...new Set([...(quickFilter.kb_panels || []), ...(quickFilter.other_panels || [])])];
    const curOtherPanels = [...new Set([...(curFilter.other_panels || [])])];

    return (
      _isEqual(quickFilterPanels.sort(), curOtherPanels.sort()) &&
      _isEqual(
        this.normalizeFilter(quickFilter, filterStructure, true),
        this.normalizeFilter(curFilter, filterStructure, true),
      )
    );
  }
}
