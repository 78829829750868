import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import * as fromActions from '../actions';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppModuleState, getAnalysisId } from '../../../../../../store';
import { VariantCommentsService } from '../../services/variant-comments.service';
import { VariantInterpretation } from '../../../analysis-variant/models';

@Injectable()
export class VariantsCommentsEffects {
  constructor(
    private actions$: Actions,
    private rootStore$: Store<AppModuleState>,
    private variantCommentsService: VariantCommentsService,
  ) {}

  addCommentToVariant: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.ADD_COMMENT_TO_VARIANT),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisId))),
      switchMap(([action, analysisId]: [fromActions.AddCommentToVariant, number]) => {
        return this.variantCommentsService
          .addCommentToVariant(analysisId, action.varId, action.comment, action.isPrivate)
          .pipe(switchMap((data: VariantInterpretation) => []));
      }),
    ),
  );
}
