import { Injectable } from '@angular/core';
import { AppService } from '../services';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as fromAction from '../actions/organization-config.action';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromAuthStore from '../../modules/auth0/store';

@Injectable()
export class OrganizationConfigEffect {
  constructor(
    private actions$: Actions,
    private service: AppService,
  ) {}

  loadOrganizationConfigAfterLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthStore.LOAD_USER_DETAILS_SUCCESS),
      map(() => fromAction.loadOrganizationConfig()),
    ),
  );

  loadOrganizationConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadOrganizationConfig),
      switchMap((action) =>
        this.service.getOrganizationConfig().pipe(
          map((data) => fromAction.loadOrganizationConfigSuccess({ data })),
          catchError((error) => of(fromAction.loadOrganizationConfigFail({ error }))),
        ),
      ),
    ),
  );
}
