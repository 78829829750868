import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as fromAction from './../actions/custom-status.action';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AnalysisService } from '../../modules/analyses/services/analysis.service';

@Injectable()
export class CustomStatusEffect {
  constructor(
    private actions$: Actions,
    private analysisService: AnalysisService,
  ) {}

  loadCustomStatusOptions$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_CUSTOM_STATUS_OPTIONS),
      switchMap(() =>
        this.analysisService.getCustomStatusOptions().pipe(
          map((results) => new fromAction.LoadCustomStatusOptionsSuccess(results)),
          catchError((error) => of(new fromAction.LoadCustomStatusOptionsFail(error))),
        ),
      ),
    ),
  );
}
