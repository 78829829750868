import { CaseHistoryRecord } from '../../models';
import { createReducer, on } from '@ngrx/store';

import * as fromAction from './../actions/case-history.action';

export interface CaseHistoryState {
  loading: boolean;
  loaded: boolean;
  data: CaseHistoryRecord[];
}

const initialState: CaseHistoryState = {
  loading: false,
  loaded: false,
  data: [],
};

export const reducer = createReducer(
  initialState,
  on(fromAction.loadCaseHistory, (state, { analysisId }) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(fromAction.loadCaseHistorySuccess, (state, { data }) => ({
    ...state,
    loading: false,
    loaded: true,
    data,
  })),
  on(fromAction.loadCaseHistoryFail, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: false,
  })),
);
