<div class="dialog-header">
  <div *ngIf="data.manual" class="subtitle">NEW CASE</div>
  <div *ngIf="data.manual" class="title">Choose Case Name</div>
  <div *ngIf="!data.manual" class="title">Do you want to save this search as a case?</div>
  <div *ngIf="!data.manual" class="subtitle">This way you can later access it under “My Cases” page.</div>
  <img src="assets/svg/esc-with-txt.svg" width="35" height="32" (click)="onClose(true)" />
</div>
<mat-dialog-content>
  <div *ngIf="!data.manual" class="variant-name">Case Variant: {{ data.variantName }}</div>
  <form>
    <div class="input-container">
      <div>
        <label
          *ngIf="nameFormControl.valid || nameFormControl.untouched || checking"
          for="case-name"
          [ngClass]="{ red: nameFormControl.invalid && nameFormControl.touched }"
        >
          Case Name
        </label>
        <span class="red" *ngIf="nameFormControl.invalid && nameFormControl.touched && !checking">
          {{ nameFormControl.errors?.required ? 'Case Name is required' : '' }}
          {{ nameFormControl.errors?.notAvailable ? 'Case Name already exists' : '' }}
        </span>
      </div>
      <input
        id="case-name"
        [formControl]="nameFormControl"
        placeholder="Case Name"
        [ngClass]="{ red: nameFormControl.invalid && nameFormControl.touched }"
      />
      <gnx-privacy-label></gnx-privacy-label>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <gnx-checkbox *ngIf="!data.manual" (checkedChange)="onCheckedChange($event)">Don't show this again</gnx-checkbox>
  <div>{{ saveError }}</div>
  <a *ngIf="data.manual" (click)="onClose(true)">Cancel</a>
  <button *ngIf="!data.manual" (click)="onClose()">Don't save</button>
  <button [disabled]="nameFormControl.invalid" (click)="onSave()">
    <span *ngIf="!(caseSaving$ | async)">Save as case</span>
    <gnx-dots-loader *ngIf="caseSaving$ | async"></gnx-dots-loader>
  </button>
</mat-dialog-actions>
