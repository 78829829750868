import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppModuleState, ClearSearchVariantError, LazyLoadModule } from '../../../store';
import * as fromAuthStore from '../../../modules/auth0/store';
import { Observable, Subject } from 'rxjs';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterEvent } from '@angular/router';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { MiniApps } from '../../../shared/models/mini-app.model';
import { UserDetails, UserPermission } from '../../../modules/auth0/models';
import { MatDialog } from '@angular/material/dialog';
import { FreemiumRegistrationFormPopupComponent } from '../../../modules/variant-page/components/freemium-registration-form-popup/freemium-registration-form-popup.component';
import { OpenRegistrationPopup } from '../../../modules/variant-page/store/actions';
import { getIsLoggedIn } from '../../../modules/auth0/store';
import { environment } from '../../../../environments/environment';
import { ClickCursorDirective } from '../../../shared/directives/click-cursor.directive';
import { MatTooltip } from '@angular/material/tooltip';
import { ElementPermissionResolverDirective } from '../../../shared/directives/element-permission-resolver.directive';
import { SearchComponent } from '../../components/search/search.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { TopNavComponent } from '../../../shared/containers/top-nav/top-nav.component';

@Component({
  selector: 'gnx-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  standalone: true,
  imports: [
    TopNavComponent,
    NgIf,
    SearchComponent,
    ElementPermissionResolverDirective,
    MatTooltip,
    ClickCursorDirective,
    AsyncPipe,
  ],
})
export class HomePageComponent implements OnInit, OnDestroy {
  UserPermission = UserPermission;
  MiniApps = MiniApps;

  userDetails$: Observable<UserDetails>;
  destroy$: Subject<void> = new Subject();

  showSearch$: Observable<boolean>;

  constructor(
    private store$: Store<AppModuleState>,
    private authStore$: Store<fromAuthStore.AuthModuleState>,
    private router: Router,
    private matDialog: MatDialog,
  ) {}

  ngOnInit() {
    this.userDetails$ = this.authStore$.pipe(select(fromAuthStore.getUserDetails));
    this.matDialog.afterOpened
      .pipe(
        filter((dialog) => dialog.componentInstance instanceof FreemiumRegistrationFormPopupComponent),
        switchMap((dialog) => dialog.afterClosed()),
        switchMap((result) => this.userDetails$),
        filter((userDetails) => !!userDetails?.organization),
        take(1),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        if (localStorage.getItem('open-report')) {
          return this.createCaseFromReport();
        }
        if (localStorage.getItem('open-cma')) {
          return this.createCaseFromCMA();
        }
        if (localStorage.getItem('open-vcf')) {
          return this.createCaseFromVCF();
        }
      });

    this.showSearch$ = this.authStore$
      .pipe(select(getIsLoggedIn))
      .pipe(map((isLoggedIn) => !environment.hideSearchForNonLoggedIn || isLoggedIn));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.store$.dispatch(new ClearSearchVariantError());
  }

  createCaseFromVCF() {
    this.userDetails$.pipe(take(1)).subscribe((userDetails) => {
      if (userDetails?.organization) {
        localStorage.removeItem('open-vcf');
        return this.router.navigate([{ outlets: { dialog: 'new-case' } }]);
      }
      localStorage.setItem('open-vcf', 'true');
      return this.store$.dispatch(
        new LazyLoadModule(
          () => import('../../../modules/variant-page/variant-page.module').then((m) => m.VariantPageModule),
          new OpenRegistrationPopup(null, 'vcf'),
        ),
      );
    });
  }

  createCaseFromReport() {
    this.userDetails$.pipe(take(1)).subscribe((userDetails) => {
      if (userDetails?.organization) {
        localStorage.removeItem('open-report');
        return this.router.navigate([{ outlets: { dialog: 'new-case/multiple' } }]);
      }
      localStorage.setItem('open-report', 'true');
      return this.authStore$.dispatch(
        new LazyLoadModule(
          () => import('../../../modules/variant-page/variant-page.module').then((m) => m.VariantPageModule),
          new OpenRegistrationPopup(null, 'report'),
        ),
      );
    });
  }

  createCaseFromCMA() {
    this.userDetails$.pipe(take(1)).subscribe((userDetails) => {
      if (userDetails?.organization) {
        localStorage.removeItem('open-cma');
        return this.router.navigate([{ outlets: { dialog: 'new-case/cma' } }]);
      }
      localStorage.setItem('open-cma', 'true');
      return this.authStore$.dispatch(
        new LazyLoadModule(
          () => import('../../../modules/variant-page/variant-page.module').then((m) => m.VariantPageModule),
          new OpenRegistrationPopup(null, 'report'),
        ),
      );
    });
  }
}
