import { Action } from '@ngrx/store';
import { PredefinedFilter } from '../models/predefined-filter.model';
import { VariantType } from '../../../analysis-variant/models';
import { AnalysisDetails, AnalysisType } from '../../../../../../store';
import { UpdateQuickFiltersRequest } from '../models/filter-tree.model';

export const UPDATE_GV_SAVED_FILTERS = '[Filters] Update geneview saved filters';
export const UPDATE_GV_SAVED_FILTERS_SUCCESS = '[Filters] Update geneview saved filters success';
export const UPDATE_GV_SAVED_FILTERS_FAIL = '[Filters] Update geneview saved filters fail';

export class UpdateGVSavedFilters implements Action {
  readonly type = UPDATE_GV_SAVED_FILTERS;

  constructor(
    public analysisId: number,
    public filters: PredefinedFilter[],
  ) {}
}

export class UpdateGVSavedFiltersSuccess implements Action {
  readonly type = UPDATE_GV_SAVED_FILTERS_SUCCESS;

  constructor(public filters: PredefinedFilter[]) {}
}

export class UpdateGVSavedFiltersFail implements Action {
  readonly type = UPDATE_GV_SAVED_FILTERS_FAIL;

  constructor(public err: any) {}
}

export const CREATE_SAVED_FILTER = '[Filters] Create saved filter';
export const CREATE_SAVED_FILTER_SUCCESS = '[Filters] Create saved filter success';
export const CREATE_SAVED_FILTER_FAIL = '[Filters] Create saved filter fail';

export class CreateSavedFilter implements Action {
  readonly type = CREATE_SAVED_FILTER;

  constructor(
    public analysisId: number,
    public analysisType: string,
    public variantType: VariantType,
    public filter: PredefinedFilter,
  ) {}
}

export class CreateSavedFiltersSuccess implements Action {
  readonly type = CREATE_SAVED_FILTER_SUCCESS;

  constructor(
    public analysisId: number,
    public analysisType: string,
    public variantType: VariantType,
    public savedFilter: PredefinedFilter,
  ) {}
}

export class CreateSavedFiltersFail implements Action {
  readonly type = CREATE_SAVED_FILTER_FAIL;

  constructor(public err: any) {}
}

export const DELETE_SAVED_FILTER = '[Filters] Delete saved filter';
export const DELETE_SAVED_FILTER_SUCCESS = '[Filters] Delete saved filter success';
export const DELETE_SAVED_FILTER_FAIL = '[Filters] Delete saved filter fail';

export class DeleteSavedFilter implements Action {
  readonly type = DELETE_SAVED_FILTER;

  constructor(public filterId: number) {}
}

export class DeleteSavedFilterSuccess implements Action {
  readonly type = DELETE_SAVED_FILTER_SUCCESS;

  constructor(public filterId: number) {}
}

export class DeleteSavedFilterFail implements Action {
  readonly type = DELETE_SAVED_FILTER_FAIL;

  constructor(public error: any) {}
}

export const APPLY_QUICK_FILTER = '[Filters] Apply quick filter';

export class ApplyQuickFilter implements Action {
  readonly type = APPLY_QUICK_FILTER;

  constructor(
    public filterContent: { [key: string]: string[] },
    public analysis: AnalysisDetails,
  ) {}
}

export const UPDATE_QUICK_FILTERS_METADATA = '[Filters] Update quick filters metadata';
export const UPDATE_QUICK_FILTERS_METADATA_SUCCESS = '[Filters] Update quick filters metadata success';
export const UPDATE_QUICK_FILTERS_METADATA_FAIL = '[Filters] Update quick filters metadata fail';

export class UpdateQuickFiltersMetadata implements Action {
  readonly type = UPDATE_QUICK_FILTERS_METADATA;
  constructor(public analysisType: AnalysisType, public variantType: VariantType, public payload: UpdateQuickFiltersRequest) {}
}

export class UpdateQuickFiltersMetadataSuccess implements Action {
  readonly type = UPDATE_QUICK_FILTERS_METADATA_SUCCESS;

  constructor(public payload: UpdateQuickFiltersRequest) {}
}

export class UpdateQuickFiltersMetadataFail implements Action {
  readonly type = UPDATE_QUICK_FILTERS_METADATA_FAIL;
  constructor(public error: any) {}
}

export type PredefinedFiltersAction =
  | UpdateGVSavedFilters
  | UpdateGVSavedFiltersSuccess
  | UpdateGVSavedFiltersFail
  | CreateSavedFilter
  | CreateSavedFiltersSuccess
  | CreateSavedFiltersFail
  | DeleteSavedFilter
  | DeleteSavedFilterSuccess
  | DeleteSavedFilterFail
  | UpdateQuickFiltersMetadata
  | UpdateQuickFiltersMetadataSuccess
  | UpdateQuickFiltersMetadataFail;
