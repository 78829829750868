import { Component, forwardRef, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AuthModuleState, getIsLoggedIn } from '../../modules/auth0/store';
import { NgIf, AsyncPipe } from '@angular/common';
import { TopNavComponent } from '../../shared/containers/top-nav/top-nav.component';

@Component({
  selector: 'gnx-about-franklin',
  templateUrl: './about-franklin.component.html',
  styleUrls: ['./about-franklin.component.scss'],
  standalone: true,
  imports: [TopNavComponent, NgIf, AsyncPipe],
})
export class AboutFranklinComponent implements OnInit {
  public isLoggedIn$: Observable<boolean>;

  constructor(private authStore$: Store<AuthModuleState>) {}

  ngOnInit(): void {
    this.isLoggedIn$ = this.authStore$.pipe(select(getIsLoggedIn));
  }
}
