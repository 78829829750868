import { Action } from '@ngrx/store';
import { Ethnicity } from '../models';

export const GET_ETHNICITY_OPTIONS = '[Root] Get ethnicity options';
export const GET_ETHNICITY_OPTIONS_SUCCESS = '[Root] Get ethnicity options success';
export const GET_ETHNICITY_OPTIONS_FAIL = '[Root] Get ethnicity options fail';

export class GetEthnicityOptions implements Action {
  readonly type = GET_ETHNICITY_OPTIONS;
}

export class GetEthnicityOptionsSuccess implements Action {
  readonly type = GET_ETHNICITY_OPTIONS_SUCCESS;

  constructor(public payload: Ethnicity[]) {}
}

export class GetEthnicityOptionsFail implements Action {
  readonly type = GET_ETHNICITY_OPTIONS_FAIL;

  constructor(public err: any) {}
}

export type EthnicitiesAction = GetEthnicityOptions | GetEthnicityOptionsSuccess | GetEthnicityOptionsFail;
