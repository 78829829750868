import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as fromAction from './../actions/sig-variants.action';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { SigService } from '../services';
import { SIG_TAB_PAGE_SIZE, SigTabCounts } from '../../models';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppModuleState } from '../../../../store/reducers';
import { getQueryParams } from '../../../../store/selectors';
import { AnalysisResult } from '../../../analysis/modules/analysis-variant/models';
import { SigState } from '../reducers';
import { getSigClassifiedVariantsCurrentPage, getSigDetails, getSigSuggestedVariantsCurrentPage } from '../selectors';
import {
  LoadVariantInterpretationFail,
  LoadVariantInterpretationSuccess,
  SaveVariantInterpretationFail,
  SaveVariantInterpretationSuccess,
} from '../../../variant-page/modules/interpretation/store/actions';
import { VariantCategoryTab } from '../../models/sig-variant.model';

@Injectable()
export class SigVariantsEffect {
  constructor(
    private actions$: Actions,
    private sigService: SigService,
    private rootStore$: Store<AppModuleState>,
    private store$: Store<SigState>,
  ) {}

  loadSuggestedVariantsAndCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadSuggestedVariantsAndCount),
      switchMap(() => [fromAction.loadSuggestedVariantsCount(), fromAction.loadSuggestedVariants()]),
    ),
  );

  loadSuggestedVariantsCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadSuggestedVariantsCount),
      withLatestFrom(
        this.rootStore$.pipe(select(getQueryParams)),
        this.store$.pipe(select(getSigDetails)),
        this.store$.pipe(select(getSigSuggestedVariantsCurrentPage)),
      ),
      switchMap(([action, params, sig, curPage]) =>
        this.sigService
          .getSuggestedVariantsCount(sig.sig_id, sig.annotation_version, curPage || 1, SIG_TAB_PAGE_SIZE, params)
          .pipe(
            map((countResponse: SigTabCounts) =>
              fromAction.loadSuggestedVariantsCountSuccess({ payload: countResponse }),
            ),
            catchError((error) => of(fromAction.loadSuggestedVariantsCountFail({ error }))),
          ),
      ),
    ),
  );

  loadSuggestedVariants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadSuggestedVariants),
      withLatestFrom(this.rootStore$.pipe(select(getQueryParams)), this.store$.pipe(select(getSigDetails))),
      switchMap(([action, params, sig]) =>
        this.sigService.getSuggestedVariants(sig.sig_id, sig.annotation_version, 1, SIG_TAB_PAGE_SIZE, params).pipe(
          map((response: AnalysisResult[]) => fromAction.loadSuggestedVariantsSuccess({ payload: response })),
          catchError((error) => of(fromAction.loadSuggestedVariantsFail({ error }))),
        ),
      ),
    ),
  );

  loadMoreSuggestedVariants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadMoreSuggestedVariants),
      withLatestFrom(
        this.rootStore$.pipe(select(getQueryParams)),
        this.store$.pipe(select(getSigDetails)),
        this.store$.pipe(select(getSigSuggestedVariantsCurrentPage)),
      ),
      switchMap(([action, params, sig, curPage]) =>
        this.sigService
          .getSuggestedVariants(sig.sig_id, sig.annotation_version, curPage, SIG_TAB_PAGE_SIZE, params)
          .pipe(
            map((response: AnalysisResult[]) => fromAction.loadSuggestedVariantsSuccess({ payload: response })),
            catchError((error) => of(fromAction.loadSuggestedVariantsFail({ error }))),
          ),
      ),
    ),
  );

  loadClassifiedVariants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadClassifiedVariants),
      withLatestFrom(this.rootStore$.pipe(select(getQueryParams)), this.store$.pipe(select(getSigDetails))),
      switchMap(([action, params, sig]) =>
        this.sigService.getClassifiedVariants(sig.sig_id, sig.annotation_version, 1, SIG_TAB_PAGE_SIZE, params).pipe(
          map((response: any) => fromAction.loadClassifiedVariantsSuccess(response)),
          catchError((error) => of(fromAction.loadClassifiedVariantsFail({ error }))),
        ),
      ),
    ),
  );

  loadMoreClassifiedVariants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadMoreClassifiedVariants),
      withLatestFrom(
        this.rootStore$.pipe(select(getQueryParams)),
        this.store$.pipe(select(getSigDetails)),
        this.store$.pipe(select(getSigClassifiedVariantsCurrentPage)),
      ),
      switchMap(([action, params, sig, curPage]) =>
        this.sigService
          .getClassifiedVariants(sig.sig_id, sig.annotation_version, curPage, SIG_TAB_PAGE_SIZE, params)
          .pipe(
            map((response: any) => fromAction.loadClassifiedVariantsSuccess(response)),
            catchError((error) => of(fromAction.loadClassifiedVariantsFail({ error }))),
          ),
      ),
    ),
  );

  classifyVariant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.classifySigVariant),
      withLatestFrom(this.store$.pipe(select(getSigDetails)), this.rootStore$.pipe(select(getQueryParams))),
      switchMap(([action, sig, qParams]) =>
        this.sigService.classifyVariant(action.variantId, action.interpretation, action.caseContext, sig.sig_id).pipe(
          mergeMap((result) => [
            fromAction.classifySigVariantSuccess(),
            new SaveVariantInterpretationSuccess(action.variantId, result),
            qParams.category === VariantCategoryTab[VariantCategoryTab.suggested]
              ? fromAction.loadSuggestedVariantsAndCount()
              : fromAction.loadClassifiedVariants(),
          ]),
          catchError((err) => of(fromAction.classifySigVariantFail({ err }), new SaveVariantInterpretationFail(err))),
        ),
      ),
    ),
  );

  loadVariantClassification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadSigVariantClassification),
      withLatestFrom(this.store$.pipe(select(getSigDetails))),
      switchMap(([action, sig]) =>
        this.sigService.getVariantClassification(action.variantId, sig.sig_id).pipe(
          map((res) => new LoadVariantInterpretationSuccess(action.variantId, res)),
          catchError((err) => of(new LoadVariantInterpretationFail(err))),
        ),
      ),
    ),
  );
}
