import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as fromAction from '../actions/filter-tree.actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { FiltersService } from '../services';
import { of } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  FilterTreeSaveDialogComponent,
  FilterTreeSaveDialogData,
  FilterTreeSaveDialogResult,
} from '../../../../../assets-page/modules/filter-tree/components/filter-tree-save-dialog/filter-tree-save-dialog.component';
import { FilterTree, filterTreeInfoToFilterTree } from '../models/filter-tree.model';
import { VariantType } from '../../../analysis-variant/models';
import {
  WarningDialogComponent,
  WarningDialogData,
} from '../../../../../../components/warning-dialog/warning-dialog.component';
import { OpenSnackbar } from '../../../../../../store';
import { SnackbarMode } from '../../../../../../store/models/snackbar.model';

@Injectable()
export class FilterTreeEffect {
  constructor(
    private actions$: Actions,
    private service: FiltersService,
    private matDialog: MatDialog,
    private router: Router,
  ) {}

  loadFilterTree$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadFilterTreeDefinition),
      switchMap((action) =>
        this.service.getFilterTreeDefinition(action.filterTreeId, action.analysisType, action.variantType).pipe(
          map((results) => fromAction.loadFilterTreeDefinitionSuccess({ payload: results })),
          catchError((error) => of(fromAction.loadFilterTreeDefinitionFail({ error }))),
        ),
      ),
    ),
  );

  loadFilterTrees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadFilterTrees),
      switchMap((action) =>
        this.service.getFilterTrees().pipe(
          map((results) => fromAction.loadFilterTreesSuccess({ payload: results })),
          catchError((error) => of(fromAction.loadFilterTreesFail({ error }))),
        ),
      ),
    ),
  );

  loadFilterTreeOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadFilterTreeOptions),
      switchMap((action) =>
        this.service.getFilterTreeOptions(action.analysisType, action.variantType).pipe(
          map((results) => fromAction.loadFilterTreeOptionsSuccess({ payload: results })),
          catchError((error) => of(fromAction.loadFilterTreeOptionsFail({ error }))),
        ),
      ),
    ),
  );

  loadFilterTreeLabels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadFilterTreeLabels),
      switchMap((action) =>
        this.service.getFilterTreeLabels(action.analysisId, action.analysisType, action.variantType).pipe(
          map((results) => fromAction.loadFilterTreeLabelsSuccess({ payload: results })),
          catchError((error) => of(fromAction.loadFilterTreeLabelsFail({ error }))),
        ),
      ),
    ),
  );

  loadFilterTreeLabelsFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.loadFilterTreeLabelsFail),
      switchMap(() => of(new OpenSnackbar('Error Loading tree quick filters', SnackbarMode.Error))),
    ),
  );

  markFilterTreeVerified$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.markFilterTreeVerified),
      switchMap((action) =>
        this.service.markFilterTreeVerified(action.filterTreeId, action.analysisId, action.value).pipe(
          map((results) =>
            fromAction.markFilterTreeVerifiedSuccess({
              filterTreeId: action.filterTreeId,
              analysisId: action.analysisId,
              value: action.value,
            }),
          ),
          catchError((error) =>
            of(
              fromAction.markFilterTreeVerifiedFail({
                filterTreeId: action.filterTreeId,
                analysisId: action.analysisId,
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  createFilterTree$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.createFilterTree),
      switchMap((action) =>
        this.openDialog()
          .afterClosed()
          .pipe(
            map((result) => {
              if (result?.result) {
                const variantTypeParam: string = result.filterTree.tree.variant_type
                  ? `/${result.filterTree.tree.variant_type}`
                  : '';
                this.router.navigate([
                  `clinical-db/assets/filter-trees/${result.filterTree.tree.case_type}${variantTypeParam}/new`,
                ]);
                return fromAction.createFilterTreeSuccess({ tree: result.filterTree });
              } else {
                return fromAction.createFilterTreeFail();
              }
            }),
          ),
      ),
    ),
  );

  cloneFilterTree$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.cloneFilterTree),
      switchMap((action) =>
        this.service.getFilterTreeDefinition(action.filterTreeId, action.analysisType, action.variantType).pipe(
          switchMap((originalTree: FilterTree) => {
            const newTree: FilterTree = {
              ...originalTree,
              tree: {
                ...originalTree.tree,
                name: `${originalTree.tree.name} Clone`,
                tree_id: undefined,
                case_type: action.analysisType,
                variant_type: action.variantType,
              },
              tree_assays: originalTree.tree.metadata.assays,
            };
            return this.openDialog(newTree)
              .afterClosed()
              .pipe(
                map((result) => {
                  if (result?.result) {
                    const variantTypeParam: string = result.filterTree.tree.variant_type
                      ? `/${result.filterTree.tree.variant_type}`
                      : '';
                    this.router.navigate([
                      `clinical-db/assets/filter-trees/${result.filterTree.tree.case_type}${variantTypeParam}/new`,
                    ]);
                    const payload: FilterTree = {
                      ...originalTree,
                      ...result.filterTree,
                      tree: {
                        ...originalTree.tree,
                        ...result.filterTree.tree,
                      },
                    };
                    return fromAction.createFilterTreeSuccess({ tree: payload });
                  } else {
                    return fromAction.createFilterTreeFail();
                  }
                }),
              );
          }),
        ),
      ),
    ),
  );

  saveNewFilterTree$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.saveNewFilterTree),
      switchMap((action) =>
        this.service.createFilterTree(action.tree, action.analysisType, action.variantType).pipe(
          map((results) =>
            fromAction.saveNewFilterTreeSuccess({
              tree_id: results?.tree_id,
              analysisType: action.analysisType,
              variantType: action.variantType,
            }),
          ),
          catchError((error) => of(fromAction.saveNewFilterTreeFail({ error }))),
        ),
      ),
    ),
  );

  saveNewFilterTreeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAction.saveNewFilterTreeSuccess),
        tap((action) => {
          const variantTypeParam: string = action.variantType ? `/${action.variantType}` : '';
          this.router.navigate([
            `clinical-db/assets/filter-trees/${action.analysisType}${variantTypeParam}/${action.tree_id}`,
          ]);
        }),
      ),
    { dispatch: false },
  );

  editFilterTree$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.editFilterTree),
      switchMap((action) =>
        this.openDialog(filterTreeInfoToFilterTree(action.treeInfo), action.treeInfo.is_delete_allowed)
          .afterClosed()
          .pipe(
            map((result) => {
              if (result?.result) {
                return fromAction.saveUpdatedFilterTree({
                  tree: result.filterTree,
                  variantType: result.filterTree.tree.variant_type.toLowerCase() as VariantType,
                  analysisType: result.filterTree.tree.case_type,
                });
              } else {
                return fromAction.editFilterTreeAbort();
              }
            }),
          ),
      ),
    ),
  );

  saveUpdatedFilterTree$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.saveUpdatedFilterTree),
      switchMap((action) =>
        this.service.updateFilterTree(action.tree, action.analysisType, action.variantType).pipe(
          map((result) =>
            fromAction.saveUpdatedFilterTreeSuccess({
              tree: result,
              analysisType: action.analysisType,
              variantType: action.variantType,
            }),
          ),
          catchError((error) => of(fromAction.saveUpdatedFilterTreeFail({ tree_id: action.tree.tree.tree_id, error }))),
        ),
      ),
    ),
  );

  deleteFilterTree$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.deleteFilterTree),
      switchMap((action) => {
        const confirmDeleteDialog = this.matDialog.open(WarningDialogComponent, {
          width: '570px',
          height: '350px',
          backdropClass: 'popup-backdrop',
          panelClass: 'popup-panel',
          hasBackdrop: true,
          data: {
            description: 'This action will permanently remove this filter tree from your organization',
            yesButtonLabel: 'Delete',
            cancelButtonLabel: 'Cancel',
          } as WarningDialogData,
        });
        return confirmDeleteDialog.afterClosed().pipe(
          switchMap((answer) => {
            if (answer) {
              return this.service.deleteFilterTree(action.filterTreeId, action.analysisType, action.variantType).pipe(
                map((result) =>
                  fromAction.deleteFilterTreeSuccess({
                    filterTreeId: action.filterTreeId,
                    analysisType: action.analysisType,
                    variantType: action.variantType,
                  }),
                ),
              );
            } else {
              return of(
                fromAction.deleteFilterTreeAbort({
                  filterTreeId: action.filterTreeId,
                  analysisType: action.analysisType,
                  variantType: action.variantType,
                }),
              );
            }
          }),
          catchError((error) =>
            of(
              fromAction.deleteFilterTreeFail({
                filterTreeId: action.filterTreeId,
                analysisType: action.analysisType,
                variantType: action.variantType,
                error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  deleteFilterTreeFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.deleteFilterTreeFail),
      switchMap(() => of(new OpenSnackbar('Error! Filter tree deletion failed', SnackbarMode.Error))),
    ),
  );

  openDialog(
    filterTree?: FilterTree,
    isDeleteAllowed?: boolean,
  ): MatDialogRef<FilterTreeSaveDialogComponent, FilterTreeSaveDialogResult> {
    return this.matDialog.open<FilterTreeSaveDialogComponent, FilterTreeSaveDialogData, FilterTreeSaveDialogResult>(
      FilterTreeSaveDialogComponent,
      {
        disableClose: true,
        width: '780px',
        height: '730px',
        panelClass: ['grey-bg-modal', 'paddingless-dialog'],
        data: filterTree
          ? {
              filterTree,
              isDeleteAllowed,
            }
          : undefined,
      },
    );
  }
}
