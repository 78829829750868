import { Component, ElementRef, HostBinding, Input, OnDestroy } from '@angular/core';
import { NgIf } from '@angular/common';

export type ButtonSize = 'h32' | 'h40' | 'h42' | 'h54';
export type ButtonType = 'border' | 'light' | 'flat' | 'gradient' | 'blue' | 'red';

@Component({
  selector: 'gnx-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class ButtonComponent implements OnDestroy {
  @Input() icon: string;
  @Input() @HostBinding('class.disabled') disabled: boolean;
  @Input() @HostBinding('class.padded') padded: boolean;
  @Input() size: ButtonSize = 'h32';
  @Input() type: ButtonType = 'border';

  @HostBinding('class.h32') get h32() {
    return this.size === 'h32';
  }

  @HostBinding('class.h40') get h40() {
    return this.size === 'h40';
  }

  @HostBinding('class.h42') get h42() {
    return this.size === 'h42';
  }

  @HostBinding('class.h54') get h54() {
    return this.size === 'h54';
  }

  @HostBinding('class.border') get border() {
    return this.type === 'border';
  }

  @HostBinding('class.light') get light() {
    return this.type === 'light';
  }

  @HostBinding('class.flat') get flat() {
    return this.type === 'flat';
  }

  @HostBinding('class.blue') get blue() {
    return this.type === 'blue';
  }

  @HostBinding('class.red') get red() {
    return this.type === 'red';
  }

  @HostBinding('class.gradient') get gradient() {
    return this.type === 'gradient';
  }

  constructor(private elementRef: ElementRef) {
    this.elementRef.nativeElement.addEventListener('mouseenter', this.captureClick.bind(this), true);
    this.elementRef.nativeElement.addEventListener('click', this.captureClick.bind(this), true);
  }

  ngOnDestroy() {
    this.elementRef.nativeElement.removeEventListener('mouseenter', this.captureClick.bind(this), true);
    this.elementRef.nativeElement.removeEventListener('click', this.captureClick.bind(this), true);
  }

  captureClick(event: MouseEvent) {
    if (this.disabled) {
      event.stopPropagation();
      event.preventDefault();
      event.stopImmediatePropagation();
      return false;
    }
    return true;
  }
}
