import {
  ApplicationType,
  AssessmentToolsComponent,
  CuratedConditionDataComponent,
  CuratedGeneDataComponent,
  GeneAssessmentComponent,
  GeneStructureComponent,
  MaxiApplicationType,
  MiniApplicationConfig,
  MiniApplicationType
} from './application.model';
import { VariantPopupAppType } from '../../../../analysis/modules/analysis-variant/models';
import { CurationsSource } from '../../gene-assessment/models/curated-clinical-info.model';
import { VariantPopupHeaderConfig } from '../../interpretation/models';

export interface ApplicationConfig {
  components: any[];
}

export interface AssessmentToolsApplicationConfig extends ApplicationConfig {
  components: AssessmentToolsComponent[];
  selected?: AssessmentToolsComponent;
}

export interface PublicationConfig {
  enableAddPublication?: boolean;
  showCurationPopupLink?: boolean;
  hideScopeFilter?: boolean;
  scope?: string;
  selected?: string;
  gene?: string;
}

export interface ConditionsConfig {
  gene?: string;
  sources?: string[];
  selected?: string;
  showCasePhenotypes?: boolean;
  disableConditionDetails?: boolean;
  hideCurateButton?: boolean;
}

export interface FeedConfig {
  hideLinkPublication?: boolean;
  hidePostNote?: boolean;
  hideShareClassification?: boolean;
}

export interface InterpretationConfig {
  hideSaveChoices?: boolean;
  hideAcmgRules?: boolean;
  hideRegenerate?: boolean;
  useClassificationLabel?: boolean;
}

export interface GeneAssessmentConfig {
  components: GeneAssessmentComponent[];
  selected?: GeneAssessmentComponent;
  hideGeneHeader?: boolean;
}

export interface CuratedGeneDataConfig {
  components: CuratedGeneDataComponent[];
  selected?: CuratedGeneDataComponent;
  hideGeneHeader?: boolean;
  hideCurateButton?: boolean;
}

export interface GeneStructureConfig {
  components: GeneStructureComponent[];
  selected?: GeneStructureComponent;
  hideGeneHeader?: boolean;
}

export interface GeneCurationConfig {
  selectedMiniAppSection?: CuratedGeneDataComponent | CuratedConditionDataComponent;
  selectedCurationConfig?: {
    curationsSource: CurationsSource;
    sigId: string;
  };
}

export interface ApplicationsConfig {
  [ApplicationType.AssessmentTools]?: AssessmentToolsApplicationConfig;
  [ApplicationType.Publications]?: PublicationConfig;
  [ApplicationType.Conditions]?: ConditionsConfig;
  [ApplicationType.Interpretation]?: InterpretationConfig;
  [ApplicationType.GeneAssessment]?: GeneAssessmentConfig;
  [MiniApplicationType.Feed]?: FeedConfig;
  [MiniApplicationType.GeneCuration]?: GeneCurationConfig;
  [ApplicationType.CuratedGeneData]?: CuratedGeneDataConfig;
  [ApplicationType.GeneStructure]?: GeneStructureConfig;
}

export interface VariantPageConfig {
  headerConfig?: VariantPopupHeaderConfig;
  applications?: ApplicationType[];
  applicationsConfig?: ApplicationsConfig;
  miniApplications?: MiniApplicationType[];
  maxiApplications?: MaxiApplicationType[];
  defaultApplication?: ApplicationType;
  selectedApplication?: ApplicationType;
  selectedMiniApplication?: MiniApplicationType;
  selectedMaxiApplication?: MaxiApplicationType;
  applyUrlNavigation?: boolean;
  popupAppType?: VariantPopupAppType;
  miniApplicationsConfig?: MiniApplicationConfig;
}
