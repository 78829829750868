<div class="dialog-header mat-dialog-content" mat-dialog-title>
  <div class="back-btn"></div>
  <div class="title-container">
    <div class="title">Variant notes</div>
    <div class="sub-title">New Comment</div>
  </div>
  <img class="close-icon" src="/assets/svg/esc-with-txt.svg" (click)="onClose()" />
</div>
<div class="dialog-content">
  <div class="form">
    <div class="form-control"></div>
    <div class="form-control">
      <div class="question-container small-margin">
        <label for="commentForm">Comment</label>
        <textarea
          id="commentForm"
          cdkTextareaAutosize
          [cdkAutosizeMinRows]="1"
          [cdkAutosizeMaxRows]="3"
          [(ngModel)]="commentText"
        ></textarea>
      </div>
      <mat-checkbox [checked]="isPrivate" [(ngModel)]="isPrivate"> Keep this private </mat-checkbox>
    </div>
  </div>
</div>

<mat-dialog-actions class="dialog-footer interpretation-actions">
  <a (click)="onClose()">Cancel</a>
  <button class="submit-btn" (click)="saveChanges()">Add</button>
</mat-dialog-actions>
