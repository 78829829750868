import { Action } from '@ngrx/store';
import {
  AnalysisAdditionalInformationGermlineBiomarker,
  AnalysisGermlineBiomarkerType,
  AnalysisAdditionalInformationTypesResponse,
  BioMarkerIrrelevantData,
} from '../models';

export const LOAD_ANALYSIS_ADDITIONAL_INFORMATION_TYPES = '[Analysis] Load analysis additional information types';
export const LOAD_ANALYSIS_ADDITIONAL_INFORMATION_TYPES_SUCCESS =
  '[Analysis] Load analysis additional information types success';
export const LOAD_ANALYSIS_ADDITIONAL_INFORMATION_TYPES_FAIL =
  '[Analysis] Load analysis additional information types fail';

export class LoadAnalysisAdditionalInformationTypes implements Action {
  readonly type = LOAD_ANALYSIS_ADDITIONAL_INFORMATION_TYPES;

  constructor(public analysisId: number) {}
}

export class LoadAnalysisAdditionalInformationTypesSuccess implements Action {
  readonly type = LOAD_ANALYSIS_ADDITIONAL_INFORMATION_TYPES_SUCCESS;

  constructor(
    public analysisId: number,
    public payload: AnalysisAdditionalInformationTypesResponse,
  ) {}
}

export class LoadAnalysisAdditionalInformationTypesFail implements Action {
  readonly type = LOAD_ANALYSIS_ADDITIONAL_INFORMATION_TYPES_FAIL;

  constructor(public error: any) {}
}

export const LOAD_ANALYSIS_GERMLINE_BIOMARKERS = '[Analysis] Load analysis germline biomarkers';
export const LOAD_ANALYSIS_GERMLINE_BIOMARKERS_SUCCESS = '[Analysis] Load analysis germline biomarkers success';
export const LOAD_ANALYSIS_GERMLINE_BIOMARKERS_FAIL = '[Analysis] Load analysis germline biomarkers fail';

export class LoadAnalysisGermlineBiomarkers implements Action {
  readonly type = LOAD_ANALYSIS_GERMLINE_BIOMARKERS;

  constructor(
    public analysisId: number,
    public markerTypes: AnalysisGermlineBiomarkerType[],
  ) {}
}

export class LoadAnalysisInformationGermlineBiomarkersSuccess implements Action {
  readonly type = LOAD_ANALYSIS_GERMLINE_BIOMARKERS_SUCCESS;

  constructor(public data: AnalysisAdditionalInformationGermlineBiomarker[]) {}
}

export class LoadAnalysisInformationGermlineBiomarkersFail implements Action {
  readonly type = LOAD_ANALYSIS_GERMLINE_BIOMARKERS_FAIL;

  constructor(public error: any) {}
}

export const CHANGE_GERMLINE_BIOMARKER_IN_REPORT = '[Analysis] Change germline biomarker in report';
export const CHANGE_GERMLINE_BIOMARKER_IN_REPORT_SUCCESS = '[Analysis] Change germline biomarker in report success';
export const CHANGE_GERMLINE_BIOMARKER_IN_REPORT_FAIL = '[Analysis] Change germline biomarker in report fail';

export class ChangeGermlineBiomarkerInReport implements Action {
  readonly type = CHANGE_GERMLINE_BIOMARKER_IN_REPORT;

  constructor(
    public analysisId: number,
    public biomarkerId: string,
    public value: boolean,
  ) {}
}

export class ChangeGermlineBiomarkerInReportSuccess implements Action {
  readonly type = CHANGE_GERMLINE_BIOMARKER_IN_REPORT_SUCCESS;

  constructor(
    public analysisId: number,
    public biomarkerId: string,
    public value: boolean,
  ) {}
}

export class ChangeGermlineBiomarkerInReportFail implements Action {
  readonly type = CHANGE_GERMLINE_BIOMARKER_IN_REPORT_FAIL;

  constructor(
    public analysisId: number,
    public biomarkerId: string,
    public error: any,
  ) {}
}

export const CHANGE_GERMLINE_BIOMARKER_NOT_RELEVANT = '[Analysis] Change germline biomarker not relevant';
export const CHANGE_GERMLINE_BIOMARKER_NOT_RELEVANT_SUCCESS = '[Analysis] Change germline biomarker not relevant success';
export const CHANGE_GERMLINE_BIOMARKER_NOT_RELEVANT_FAIL = '[Analysis] Change germline biomarker not relevant fail';

export class ChangeGermlineBiomarkerNotRelevant implements Action {
  readonly type = CHANGE_GERMLINE_BIOMARKER_NOT_RELEVANT;
  constructor(
    public analysisId: number,
    public biomarkerId: string,
    public irrelevantData?: BioMarkerIrrelevantData,
  ) {}
}

export class ChangeGermlineBiomarkerNotRelevantSuccess implements Action {
  readonly type = CHANGE_GERMLINE_BIOMARKER_NOT_RELEVANT_SUCCESS;
  constructor(
    public analysisId: number,
    public biomarkerId: string,
    public irrelevantData?: BioMarkerIrrelevantData,
  ) {}
}

export class ChangeGermlineBiomarkerNotRelevantFail implements Action {
  readonly type = CHANGE_GERMLINE_BIOMARKER_NOT_RELEVANT_FAIL;

  constructor(
    public analysisId: number,
    public biomarkerId: string,
    public error: any,
  ) {}
}

export type AnalysisAdditionalInformationAction =
  | LoadAnalysisAdditionalInformationTypes
  | LoadAnalysisAdditionalInformationTypesSuccess
  | LoadAnalysisAdditionalInformationTypesFail
  | LoadAnalysisGermlineBiomarkers
  | LoadAnalysisInformationGermlineBiomarkersSuccess
  | LoadAnalysisInformationGermlineBiomarkersFail
  | ChangeGermlineBiomarkerInReport
  | ChangeGermlineBiomarkerInReportSuccess
  | ChangeGermlineBiomarkerInReportFail
  | ChangeGermlineBiomarkerNotRelevant
  | ChangeGermlineBiomarkerNotRelevantSuccess
  | ChangeGermlineBiomarkerNotRelevantFail;
