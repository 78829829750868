import * as fromAction from './../actions/custom-status.action';
import { AnalysisCustomWorkflowStatus } from '../../models';

export interface CustomStatusState {
  data: AnalysisCustomWorkflowStatus[];
  loading: boolean;
  loaded: boolean;
}

const initialState: CustomStatusState = {
  data: [],
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action: fromAction.CustomStatusAction): CustomStatusState {
  switch (action.type) {
    case fromAction.LOAD_CUSTOM_STATUS_OPTIONS: {
      return {
        ...state,
        data: [],
        loading: true,
        loaded: false,
      };
    }
    case fromAction.LOAD_CUSTOM_STATUS_OPTIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
      };
    }
    case fromAction.LOAD_CUSTOM_STATUS_OPTIONS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
  }
  return { ...state };
}
