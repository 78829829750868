import { createReducer, on } from '@ngrx/store';

import { SigSuggestedGene, SigTabCounts } from '../../models';
import * as fromAction from './../actions/sig-genes.action';
import { GeneCurations } from '../../../variant-page/modules/gene-curation/models';

export interface SigGenesState {
  curatedGenes: GeneCurations[];
  loadingCurated: boolean;
  loadedCurated: boolean;
  suggestedGenes: SigSuggestedGene[];
  loadingSuggested: boolean;
  loadedSuggested: boolean;
  categoriesCount: { [category: string]: SigTabCounts };
  categoriesFilters: { [category: string]: any };
  currentPage: {
    curated: number;
    suggested: number;
  };
  markIrrelevantLoading: { [gene: string]: boolean };
}

const initialState: SigGenesState = {
  curatedGenes: [],
  loadedCurated: undefined,
  loadingCurated: undefined,
  suggestedGenes: [],
  loadingSuggested: undefined,
  loadedSuggested: undefined,
  categoriesCount: {},
  categoriesFilters: {},
  currentPage: {
    curated: 1,
    suggested: 1,
  },
  markIrrelevantLoading: {},
};

export const reducer = createReducer(
  initialState,
  on(fromAction.loadCuratedGenes, (state) => ({
    ...state,
    curatedGenes: [],
    loadingCurated: true,
    loadedCurated: false,
    currentPage: { ...state.currentPage, curated: 1 },
  })),
  on(fromAction.loadCuratedGenesSuccess, (state, payload) => ({
    ...state,
    curatedGenes: [...state.curatedGenes, ...payload.genes],
    categoriesCount: { ...state.categoriesCount, curated: { num_genes: payload.count } },
    loadingCurated: false,
    loadedCurated: true,
  })),
  on(fromAction.loadCuratedGenesFail, (state, { error }) => ({
    ...state,
    loadingCurated: false,
    loadedCurated: false,
    currentPage: {
      ...state.currentPage,
      curated: state.currentPage.curated === 1 ? state.currentPage.curated : state.currentPage.curated - 1,
    },
  })),
  on(fromAction.loadMoreCuratedGenes, (state) => ({
    ...state,
    loadingCurated: true,
    currentPage: {
      ...state.currentPage,
      curated: state.currentPage.curated + 1,
    },
  })),
  on(fromAction.loadSuggestedGenes, (state) => ({
    ...state,
    suggestedGenes: [],
    loadingSuggested: true,
    loadedSuggested: false,
    currentPage: { ...state.currentPage, suggested: 1 },
  })),
  on(fromAction.loadSuggestedGenesSuccess, (state, payload) => ({
    ...state,
    suggestedGenes: [...state.suggestedGenes, ...payload.genes],
    categoriesCount: { ...state.categoriesCount, suggested: { num_genes: payload.count } },
    loadingSuggested: false,
    loadedSuggested: true,
  })),
  on(fromAction.loadSuggestedGenesFail, (state, { error }) => ({
    ...state,
    loadingSuggested: false,
    loadedSuggested: false,
    currentPage: {
      ...state.currentPage,
      suggested: state.currentPage.suggested === 1 ? state.currentPage.suggested : state.currentPage.suggested - 1,
    },
  })),
  on(fromAction.loadMoreSuggestedGenes, (state) => ({
    ...state,
    loadingSuggested: true,
    currentPage: {
      ...state.currentPage,
      suggested: state.currentPage.suggested + 1,
    },
  })),
  on(fromAction.SaveGenesCategoryFilters, (state, payload) => ({
    ...state,
    categoriesFilters: {
      ...state.categoriesFilters,
      [payload.category]: payload.queryParams,
    },
  })),
  on(fromAction.setGeneIrrelevant, (state, payload) => ({
    ...state,
    markIrrelevantLoading: {
      ...state.markIrrelevantLoading,
      [payload.gene]: true,
    },
  })),
  on(fromAction.setGeneIrrelevantFail, (state, payload) => ({
    ...state,
    markIrrelevantLoading: {
      ...state.markIrrelevantLoading,
      [payload.gene]: false,
    },
  })),
  on(fromAction.setGeneIrrelevantSuccess, (state, payload) => {
    const suggestedGenes = [...state.suggestedGenes].filter((gene) => gene.gene_symbol !== payload.gene);
    return {
      ...state,
      markIrrelevantLoading: {
        ...state.markIrrelevantLoading,
        [payload.gene]: false,
      },
      suggestedGenes,
    };
  }),
);
