export const isMobile = (): boolean => {
  return !!(
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  );
};

export const arrayEquals = (a: any[], b: any[]) => {
  if (a === b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  if (a.length !== b.length) {
    return false;
  }

  const a1 = [...a].sort();
  const b1 = [...b].sort();

  for (let i = 0; i < a1.length; ++i) {
    if (a1[i] !== b1[i]) {
      return false;
    }
  }
  return true;
};

export const addHighlight = (value: string, highlighted: string) => {
  if (!value || !highlighted) {
    return value;
  }
  const highlightRegex = new RegExp('(' + highlighted + ')', 'gi');
  return value.replace(highlightRegex, '<span class="highlight">$1</span>');
};

export const capitalize = (value: string) => {
  return value[0].toUpperCase() + value.substring(1);
};

type GroupByKey<T> = keyof T;

export const groupToMap = <T, K extends GroupByKey<T>>(array: T[], key: K): Record<T[K] & PropertyKey, T[]> => {
  return array.reduce(
    (result, item) => {
      const groupKey = item[key] as T[K] & PropertyKey; // Ensure the key is a valid property
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(item);
      return result;
    },
    {} as Record<T[K] & PropertyKey, T[]>,
  );
};

export const groupToList = <T, K extends GroupByKey<T>>(array: T[], key: K): { key: T[K]; value: T[] }[] => {
  const grouped = array.reduce(
    (result, item) => {
      const groupKey = item[key] as T[K] & PropertyKey;
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(item);
      return result;
    },
    {} as Record<T[K] & PropertyKey, T[]>,
  );

  // Convert the grouped result to an array of { key, value } objects
  return Object.entries(grouped).map(([groupKey, value]): { key: T[K]; value: T[] } => ({
    key: groupKey as T[K], // Cast back to T[K]
    value: value as T[],
  }));
};
