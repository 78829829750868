import { Component, Inject, OnInit } from '@angular/core';
import { AnalysisFileForDownload } from '../../store/models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgFor } from '@angular/common';
import { ClickCursorDirective } from '../../../../../../shared/directives/click-cursor.directive';

export interface DownloadAnalysisFilesPopupData {
  fileType: string;
  files: AnalysisFileForDownload[];
}

@Component({
  selector: 'gnx-download-analysis-files-popup',
  templateUrl: './download-analysis-files-popup.component.html',
  styleUrls: ['./download-analysis-files-popup.component.scss'],
  standalone: true,
  imports: [ClickCursorDirective, NgFor],
})
export class DownloadAnalysisFilesPopupComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DownloadAnalysisFilesPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DownloadAnalysisFilesPopupData,
  ) {}

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
  }
}
