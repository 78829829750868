import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

export interface LogMessage {
  context?: {
    user?: string;
    org?: string;
    analysis?: string;
  };
  message?: string;
  event_title?: string;
}

@Injectable()
export class LogService {
  constructor(private http: HttpClient) {}

  logError(params: LogMessage): Observable<any> {
    return this.http.post(`${environment.beBaseURL}/api/log_frontend_event`, params);
  }

  logInfo(params: LogMessage): Observable<any> {
    return this.http.post(`${environment.beBaseURL}/api/log_frontend_event`, params);
  }
}
