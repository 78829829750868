import * as fromCustomStatus from './custom-status.reducer';
import * as fromCaseHistory from './case-history.reducer';
import { ActionReducerMap, createFeatureSelector, MemoizedSelector } from '@ngrx/store';

export interface AnalysisToolsState {
  customStatusOptions: fromCustomStatus.CustomStatusState;
  caseHistory: fromCaseHistory.CaseHistoryState;
}

export const analysisToolReducers: ActionReducerMap<AnalysisToolsState> = {
  customStatusOptions: fromCustomStatus.reducer,
  caseHistory: fromCaseHistory.reducer,
};

export const getAnalysisToolsState: MemoizedSelector<AnalysisToolsState, AnalysisToolsState> =
  createFeatureSelector<AnalysisToolsState>('analysis-tools');
