import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AppModuleState, getAppModuleState } from '../reducers';
import { AssaysState } from '../reducers/assays.reducer';

import { BriefAssayListEntity, ExtendedAssayListEntity } from '../models/assay.model';

const getAssaysState: MemoizedSelector<AppModuleState, AssaysState> = createSelector(
  getAppModuleState,
  (state) => state.assays,
);

export const getAssayBriefListAll: MemoizedSelector<AppModuleState, BriefAssayListEntity[]> = createSelector(
  getAssaysState,
  (state) => [...state.assayBriefList].sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)),
);

export const getAssayBriefList: MemoizedSelector<AppModuleState, BriefAssayListEntity[]> = createSelector(
  getAssayBriefListAll,
  (assays) => assays.filter((assay) => !assay.public),
);

export const getAssayBriefListLoading: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getAssaysState,
  (state) => state.assayBriefListLoading,
);

export const getAssayBriefListLoaded: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getAssaysState,
  (state) => state.assayBriefListLoaded,
);

export const getAssaysExtendedListAll: MemoizedSelector<AppModuleState, ExtendedAssayListEntity[]> = createSelector(
  getAssaysState,
  (state) => state.assayExtendedList,
);

export const getAssaysExtendedList: MemoizedSelector<AppModuleState, ExtendedAssayListEntity[]> = createSelector(
  getAssaysExtendedListAll,
  (assays) => assays.filter((assay) => !assay.public),
);

export const getAssaysExtendedListLoaded: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getAssaysState,
  (state) => state.assayExtendedListLoaded,
);

export const getAssaysExtendedListLoading: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getAssaysState,
  (state) => state.assayExtendedListLoading,
);

export const getAssaysInUse: MemoizedSelector<AppModuleState, string[]> = createSelector(
  getAssaysState,
  (state) => state.assaysInUse,
);

export const getAssaysInUseLoading: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getAssaysState,
  (state) => state.assaysInUseLoading,
);

export const getAssaysInUseLoaded: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getAssaysState,
  (state) => state.assaysInUseLoaded,
);
