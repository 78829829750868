import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import {
  ApplyQuickFilter,
  DeleteSavedFilter,
  FiltersState,
  getFilterTreeLabelsLoading,
  getFilterTreeLabelsLoadingError,
  getUnifiedQuickFiltersAndTreeLabels,
  UpdateQuickFiltersMetadata,
} from '../../store';
import {
  PredefinedFilter,
  PredefinedFilterType,
} from '../../store/models/predefined-filter.model';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { LoadFilterDialogData, SaveFilterDialogResponse } from '../../store/models/dialog-data';
import {
  WarningDialogComponent,
  WarningDialogData,
} from '../../../../../../components/warning-dialog/warning-dialog.component';
import { AuthModuleState, getFeatureBits, getIsUserOrgAdmin } from '../../../../../auth0/store';
import { Observable } from 'rxjs';
import { AnalysisType } from '../../../../../../store';
import { VariantType } from '../../../analysis-variant/models';
import { SaveFilterDialogComponent } from '../save-filter-dialog/save-filter-dialog.component';
import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { getParamAsList } from '../../../analysis-variant/utils/variant-query/variant-query-map.utils';
import { map } from 'rxjs/operators';
import { FeatureBit } from '../../../../../auth0/models';
import { CheckboxComponent } from '../../../../../../shared/components/checkbox/checkbox.component';
import { MatTooltip } from '@angular/material/tooltip';
import { DotsLoaderComponent } from '../../../../../../shared/components/dots-loader/dots-loader.component';
import { TextDirective } from '../../../../../../shared/directives/text.directive';
import { AnchorDirective } from '../../../../../../shared/directives/anchor.directive';
import { AsyncPipe, DatePipe, NgFor, NgIf } from '@angular/common';
import { FlexItemDirective } from '../../../../../../shared/directives/flex-item.directive';
import { ClickCursorDirective } from '../../../../../../shared/directives/click-cursor.directive';
import Timeout = NodeJS.Timeout;
import { FilterTreeLabel, UIFilterModel, UpdateQuickFiltersRequest } from '../../store/models/filter-tree.model';

@Component({
  selector: 'gnx-load-filter-dialog',
  templateUrl: './load-filter-dialog.component.html',
  styleUrls: ['./load-filter-dialog.component.scss'],
  standalone: true,
  imports: [
    ClickCursorDirective,
    MatDialogContent,
    ReactiveFormsModule,
    FlexItemDirective,
    NgIf,
    AnchorDirective,
    TextDirective,
    CdkDropList,
    DotsLoaderComponent,
    NgFor,
    CdkDrag,
    MatTooltip,
    CdkDragHandle,
    CheckboxComponent,
    AsyncPipe,
    DatePipe,
  ],
})
export class LoadFilterDialogComponent implements OnInit, AfterViewInit {
  predefinedFilters: UIFilterModel[];
  searchResult: UIFilterModel[];
  searchControl: FormControl;
  isOrgAdmin$: Observable<boolean>;
  loading$: Observable<boolean>;
  loadingError$: Observable<boolean>;

  PreDefinedFilterType = PredefinedFilterType;

  @ViewChild('searchInput') searchInputRef: ElementRef;

  inMerge = false;
  mergeAllowed = false;
  mergedFilters: string[] = [];

  debounceUpdateTimout: Timeout;

  constructor(
    private dialogRef: MatDialogRef<LoadFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LoadFilterDialogData,
    private store$: Store<FiltersState>,
    private authStore$: Store<AuthModuleState>,
    private fb: FormBuilder,
    private matDialog: MatDialog,
  ) {}

  ngOnInit() {
    this.mergeAllowed =
      (this.data.analysis.analysis_type === AnalysisType.sample ||
        this.data.analysis.analysis_type === AnalysisType.family) &&
      this.data.variantType === VariantType.SNP;
    this.searchControl = this.fb.control('');
    this.inMerge = !!this.data.inMergeMode;

    this.searchControl.valueChanges.subscribe((value: string) => this.searchFilters(value));

    this.store$.pipe(select(getUnifiedQuickFiltersAndTreeLabels)).subscribe((result) => {
      this.predefinedFilters = [...result];
      this.searchFilters(this.searchControl.value);
    });

    this.isOrgAdmin$ = this.authStore$.pipe(select(getIsUserOrgAdmin));

    this.loading$ = this.store$.pipe(select(getFilterTreeLabelsLoading));
    this.loadingError$ = this.store$.pipe(
      select(getFilterTreeLabelsLoadingError),
      map((error) => !!error),
    );
  }

  searchFilters(searchText: string) {
    this.searchResult = searchText
      ? this.predefinedFilters.filter((option) => option.name.toLowerCase().includes(searchText.toLowerCase()))
      : this.predefinedFilters;
  }

  ngAfterViewInit() {
    this.searchInputRef.nativeElement.focus();
  }

  trackByFunction(index: number, item: UIFilterModel) {
    return item.hasOwnProperty('id') ? item.id : index;
  }

  onChangeVisible(preset: UIFilterModel, value: boolean) {
    this.predefinedFilters = this.predefinedFilters.map((item: UIFilterModel) => ({
      ...item,
      quick_filter: item.id === preset.id ? value : item.quick_filter,
    }));
    this.searchFilters(this.searchControl.value);

    clearTimeout(this.debounceUpdateTimout);
    this.debounceUpdateTimout = setTimeout(() => this.onApply(), 500);
  }

  onClose() {
    this.dialogRef.close();
  }

  onDrop(event: CdkDragDrop<PredefinedFilter>) {
    if (event.currentIndex === event.previousIndex) {
      return;
    }

    moveItemInArray(
      this.predefinedFilters,
      this.predefinedFilters.findIndex((x) => x.id === event.item.data.id),
      this.predefinedFilters.findIndex((x) => x.id === this.searchResult[event.currentIndex].id),
    );
    this.onApply();
  }

  onApply() {
    const sorted = [...this.predefinedFilters].map((item: UIFilterModel, index: number) => ({...item, sort_value: index }));

    const payload: UpdateQuickFiltersRequest = {
      analysis_id: this.data.analysis.id.toString(),
      assay_id: this.data.analysis.assay?.uuid,
      tree_labels_metadata: sorted.filter((f) => f.isTreeLabel).map((f) => ({
        label_id: f.id,
        quick_filter: f.quick_filter,
        sort_value: f.sort_value,
      })),
      quick_filters_metadata: sorted.filter((f) => !f.isTreeLabel).map((f) => ({
        quick_filter_id: parseInt(f.id, 10),
        quick_filter: f.quick_filter,
        sort_value: f.sort_value,
      })),
    };

    this.store$.dispatch(
      new UpdateQuickFiltersMetadata(this.data.analysis.analysis_type, this.data.variantType, payload),
    );
  }

  onSelect(preset: UIFilterModel) {
    if (preset.isTreeLabel) {
      this.applyFilterTreeLabel(preset.filter_content);
    } else {
      this.applyGeneViewQuickFilter(preset);
    }

    this.onClose();
  }

  applyGeneViewQuickFilter(item: UIFilterModel) {
    this.store$.dispatch(new ApplyQuickFilter(item.filter_content, this.data.analysis));
  }

  applyFilterTreeLabel(filterContent: { [key: string]: string[] }) {
    this.store$.dispatch(
      new ApplyQuickFilter(
        filterContent,
        this.data.analysis,
      ),
    );
  }

  onDelete(event: Event, preset: UIFilterModel) {
    event.preventDefault();
    event.stopImmediatePropagation();

    this.matDialog.open(WarningDialogComponent, {
      width: '570px',
      height: '350px',
      backdropClass: 'popup-backdrop',
      panelClass: 'popup-panel',
      hasBackdrop: true,
      data: {
        description: 'The filter will be removed from your organization',
        yesButtonLabel: 'Remove',
        cancelButtonLabel: 'Cancel',
        yesButtonCallback: () => {
          this.store$.dispatch(new DeleteSavedFilter(parseInt(preset.id, 10)));
        },
      } as WarningDialogData,
    });
  }

  addToMerge(filterId: string, add: boolean): void {
    this.mergedFilters = add ? [...this.mergedFilters, filterId] : this.mergedFilters.filter((f) => f !== filterId);
  }

  onCreateMerge() {
    const firstFilter = this.predefinedFilters.find((f) => !f.isTreeLabel && f.id === this.mergedFilters[0])
      ?.filter_content;
    let description = `Created by merging the filters: ${this.predefinedFilters
      .filter((f) => this.mergedFilters.includes(f.id))
      .map((f) => f.name)
      .join(', ')}`;

    description = description.length > 700 ? description.slice(0, 697) + '...' : description;

    const dialog = this.matDialog.open(SaveFilterDialogComponent, {
      width: '780px',
      height: '502px',
      position: { top: '30px' },
      autoFocus: false,
      panelClass: ['paddingless-dialog', 'grey-bg-modal'],
      data: {
        analysisId: this.data.analysis.id,
        analysisType: this.data.analysis.analysis_type,
        variantType: this.data.variantType,
        newFilterType: 'merged',
        description,
        newFilterData: {
          quick_filter: this.mergedFilters.map((f) => f.toString()),
          sort1: firstFilter?.sort1,
          sort1dir: firstFilter?.sort1dir,
        },
      },
    });

    dialog.afterClosed().subscribe((result: SaveFilterDialogResponse) => {
      if (result.success) {
        this.inMerge = false;
        this.mergedFilters = [];
      }
    });
  }

  isPresetWithCompoundInheritance(filterContent: { [key: string]: string[]} ): boolean {
    const inheritanceParam = filterContent?.inheritance;
    return (
      inheritanceParam && getParamAsList(inheritanceParam)?.some((x) => x.toString() === '4' || x.toString() === '8')
    );
  }

  isPresetPartOfMerge(preset: UIFilterModel) {
    return (
      !preset.isTreeLabel &&
      this.predefinedFilters.some(
        (f) => !f.isTreeLabel && f.filter_content.quick_filter?.includes(preset.id.toString()),
      )
    );
  }

  canPresetBeMerged(preset: UIFilterModel): { result: boolean; tooltip?: string; } {
    if (preset.isTreeLabel) {
      return {
        result: false,
        tooltip: 'Tree filters cannot be merged'
      };
    }
    if (preset.isMerged) {
      return {
        result: false,
        tooltip: 'Cannot use an already merged filter'
      };
    }
    if (this.isPresetWithCompoundInheritance(preset.filter_content)) {
      return {
        result: false,
        tooltip: 'Cannot use a compound filter'
      };
    }

    return {
      result: true
    };
  }
}
