import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {
  DELETE_VARIANT_ASSESSMENT_FAIL,
  LOAD_VARIANT_ASSESSMENTS_FAIL,
  SUBMIT_TO_CLINVAR_FAIL,
} from '../../../community-classification/store';
import { LOAD_VARIANT_DETAILS_FAIL, THROW_MISSING_CONFIG_ERROR } from '../actions';
import { GET_RECLASSIFICATION_FAILURE } from '../../../acmg-classification/store/actions';
import { ADD_PUBLICATION_FAIL, CREATE_EVIDENCE_FAIL } from '../../../publications/store/actions';
import { ErrorPopupComponent } from '../../../../../../components/error-popup/error-popup.component';

@Injectable()
export class ErrorEffect {
  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
  ) {}

  showErrorPopup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          GET_RECLASSIFICATION_FAILURE,
          SUBMIT_TO_CLINVAR_FAIL,
          LOAD_VARIANT_ASSESSMENTS_FAIL,
          LOAD_VARIANT_DETAILS_FAIL,
          DELETE_VARIANT_ASSESSMENT_FAIL,
          THROW_MISSING_CONFIG_ERROR,
          ADD_PUBLICATION_FAIL,
          CREATE_EVIDENCE_FAIL,
        ),
        tap(() =>
          this.dialog.open(ErrorPopupComponent, {
            width: '570px',
            height: '430px',
            backdropClass: 'popup-backdrop',
            panelClass: 'popup-panel',
            hasBackdrop: true,
          }),
        ),
      ),
    { dispatch: false },
  );
}
