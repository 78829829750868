import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
  standalone: true,
})
export class ArraySortPipe implements PipeTransform {
  constructor() {}

  transform<T>(arr: T[], property?: string, direction = 1): T[] {
    // direction : 1 | -1
    return [...arr].sort((a: T, b: T) => {
      if (property) {
        if (a.hasOwnProperty(property) && b.hasOwnProperty(property)) {
          return direction * (a[property] > b[property] ? -1 : 1);
        }
        if (!a.hasOwnProperty(property) && !b.hasOwnProperty(property)) {
          return direction;
        }
        if (a.hasOwnProperty(property)) {
          return -direction;
        }
        return direction;
      }
      return direction * (a > b ? 1 : -1);
    });
  }
}
