import { Action } from '@ngrx/store';
import { ReferralNotes } from '../models';

export const LOAD_REFERRAL_NOTES = '[Analysis] Load referral notess';
export const LOAD_REFERRAL_NOTES_SUCCESS = '[Analysis] Load referral notess success';
export const LOAD_REFERRAL_NOTES_FAIL = '[Analysis] Load referral notess fail';

export class LoadReferralNotes implements Action {
  readonly type = LOAD_REFERRAL_NOTES;

  constructor(public analysisId: number) {}
}

export class LoadReferralNotesSuccess implements Action {
  readonly type = LOAD_REFERRAL_NOTES_SUCCESS;

  constructor(
    public analysisId: number,
    public data: ReferralNotes,
  ) {}
}

export class LoadReferralNotesFail implements Action {
  readonly type = LOAD_REFERRAL_NOTES_FAIL;

  constructor(
    public analysisId: number,
    public error: any,
  ) {}
}

export type ReferralNotesAction = LoadReferralNotes | LoadReferralNotesSuccess | LoadReferralNotesFail;
