import { Action } from '@ngrx/store';
import { VariantPageConfig } from '../../../variant-page/modules/variant-page/models';
import { VariantPopupAppType } from '../../../analysis/modules/analysis-variant/models';
import { AnalysisType } from '../../../../store';

export const GET_VARIANT_PAGE_CONFIG = '[Variant Page Popup] Get Config';
export const GET_VARIANT_PAGE_CONFIG_SUCCESS = '[Variant Page Popup] Get Config Success';
export const GET_VARIANT_PAGE_CONFIG_FAIL = '[Variant Page Popup] Get Config Fail';

export class GetVariantPageConfig implements Action {
  readonly type = GET_VARIANT_PAGE_CONFIG;

  constructor(
    public analysisId: number,
    public variantType: string,
    public popupAppType: VariantPopupAppType,
    public svType?: string,
    public analysisType?: AnalysisType,
  ) {}
}

export class GetVariantPageConfigSuccess implements Action {
  readonly type = GET_VARIANT_PAGE_CONFIG_SUCCESS;

  constructor(
    public analysisId: number,
    public variantType: string,
    public payload: VariantPageConfig,
    public popupAppType: VariantPopupAppType,
  ) {}
}

export class GetVariantPageConfigFail implements Action {
  readonly type = GET_VARIANT_PAGE_CONFIG_FAIL;

  constructor(
    public analysisId: number,
    public variantType: string,
    public error: any,
  ) {}
}

export type VariantPageConfigAction = GetVariantPageConfig | GetVariantPageConfigSuccess | GetVariantPageConfigFail;
