export enum DynamicInputType {
  Text = 'text',
  Date = 'date',
  Select = 'select',
  SelectMulti = 'select-multi',
  Checkbox = 'checkbox',
  Radio = 'radio',
  FreeText = 'free-text',
  TextList = 'text-list',
  Genes = 'genes',
  RuleThreshold = 'rule-threshold',
}

export interface DynamicInputField {
  name: string;
  display_name: string;
  field_type: DynamicInputType;
  input_type?: string;
  value_type?: string;
  min_value?: number;
  max_value?: number;
  value?: any;
  placeholder?: string;
  pattern?: string;
  section?: string;
  options?: string[];
  hide_from_case_creation?: boolean;
  can_populate_from_panel?: boolean;
  loading?: boolean;
  error?: boolean;
}
