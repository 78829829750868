import { Action } from '@ngrx/store';
import { AnalysisCustomWorkflowStatus } from '../../models';

export const LOAD_CUSTOM_STATUS_OPTIONS = '[Analyses] Load custom status options';
export const LOAD_CUSTOM_STATUS_OPTIONS_SUCCESS = '[Analyses] Load custom status options success';
export const LOAD_CUSTOM_STATUS_OPTIONS_FAIL = '[Analyses] Load custom status options fail';

export class LoadCustomStatusOptions implements Action {
  readonly type = LOAD_CUSTOM_STATUS_OPTIONS;
}

export class LoadCustomStatusOptionsSuccess implements Action {
  readonly type = LOAD_CUSTOM_STATUS_OPTIONS_SUCCESS;

  constructor(public payload: AnalysisCustomWorkflowStatus[]) {}
}

export class LoadCustomStatusOptionsFail implements Action {
  readonly type = LOAD_CUSTOM_STATUS_OPTIONS_FAIL;

  constructor(public error: any) {}
}

export type CustomStatusAction = LoadCustomStatusOptions | LoadCustomStatusOptionsSuccess | LoadCustomStatusOptionsFail;
