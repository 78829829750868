import {
  CLEAR_GENE_PANELS,
  GenePanelsAction,
  GET_GENE_KB_PANELS_SUCCESS,
  GET_GENE_PANELS,
  GET_GENE_PANELS_FAIL,
  GET_GENE_PANELS_SUCCESS,
} from '../actions';
import { GenePanel } from '../models';
import { PanelData } from '../../knowledge-base/modules/panels/store';

export interface GenePanelsState {
  [gene: string]: {
    genePanelsResponse?: GenePanel[];
    geneKbPanelsResponse?: PanelData[];
    genePanelsLoading: boolean;
    genePanelsLoaded: boolean;
  };
}

const initialState: GenePanelsState = {};

export function reducer(state = initialState, action: GenePanelsAction): GenePanelsState {
  switch (action.type) {
    case GET_GENE_PANELS: {
      return {
        ...state,
        [action.gene]: {
          ...state[action.gene],
          genePanelsResponse: null,
          genePanelsLoading: true,
          genePanelsLoaded: false,
        },
      };
    }
    case GET_GENE_PANELS_SUCCESS: {
      return {
        ...state,
        [action.gene]: {
          ...state[action.gene],
          genePanelsResponse: action.payload as GenePanel[],
          genePanelsLoading: false,
          genePanelsLoaded: true,
        },
      };
    }
    case GET_GENE_KB_PANELS_SUCCESS: {
      return {
        ...state,
        [action.gene]: {
          ...state[action.gene],
          geneKbPanelsResponse: action.payload as PanelData[],
          genePanelsLoading: false,
          genePanelsLoaded: true,
        },
      };
    }
    case GET_GENE_PANELS_FAIL: {
      return {
        ...state,
        [action.gene]: {
          ...state[action.gene],
          genePanelsLoading: false,
          genePanelsLoaded: false,
        },
      };
    }
    case CLEAR_GENE_PANELS: {
      return { ...initialState };
    }
  }

  return state;
}

export const getGenePanelsResponse = (state: GenePanelsState, props) =>
  state[props.gene] ? state[props.gene].genePanelsResponse : [];
export const getGeneKbPanelsResponse = (state: GenePanelsState, props) =>
  state[props.gene] ? state[props.gene].geneKbPanelsResponse : [];
export const getGenePanelsLoading = (state: GenePanelsState, props) =>
  state[props.gene] ? state[props.gene].genePanelsLoading : true;
export const getGenePanelsLoaded = (state: GenePanelsState, props) =>
  state[props.gene] ? state[props.gene].genePanelsLoaded : false;
