import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogRef } from '@angular/material/dialog';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ButtonComponent } from '../../../../../../shared/components/button/button.component';
import { AnchorDirective } from '../../../../../../shared/directives/anchor.directive';
import { TextAreaDirective } from '../../../../../../shared/directives/text-area.directive';
import { InputDirective } from '../../../../../../shared/directives/input.directive';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { LabelDirective } from '../../../../../../shared/directives/label.directive';
import { FlexDirective } from '../../../../../../shared/directives/flex.directive';
import { ClickCursorDirective } from '../../../../../../shared/directives/click-cursor.directive';
import { TextDirective } from '../../../../../../shared/directives/text.directive';
import { FlexItemDirective } from '../../../../../../shared/directives/flex-item.directive';
import { BlockDirective } from '../../../../../../shared/directives/block.directive';
import {
  FILTER_TREE_CASE_TYPE_OPTIONS,
  FILTER_TREE_VARIANT_TYPE_OPTIONS,
  FilterTree,
} from '../../../../../analysis/modules/filters/store/models/filter-tree.model';
import { MatOption } from '@angular/material/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { SelectDirective } from '../../../../../../shared/directives/select.directive';
import { select, Store } from '@ngrx/store';
import * as fromRootStore from '../../../../../../store';
import {
  AnalysisType,
  AssayInputSource,
  GetAssaysExtendedList,
  getAssaysExtendedListLoaded,
  getAssaysExtendedListLoading,
} from '../../../../../../store';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { ExtendedAssayListEntity } from '../../../../../../store/models/assay.model';
import * as fromSharedModels from '../../../../../../shared/models';
import { AutoCompleteComponent } from '../../../../../../shared-lazy/components/auto-complete/auto-complete.component';
import { AutoCompleteWithChipsComponent } from '../../../../../../shared-lazy/components/auto-complete-with-chips/auto-complete-with-chips.component';
import {
  deleteFilterTree,
  FiltersState,
  getFilterTrees,
  getFilterTreesLoaded,
  getFilterTreesLoading,
  loadFilterTrees,
} from '../../../../../analysis/modules/filters/store';
import { DotsLoaderComponent } from '../../../../../../shared/components/dots-loader/dots-loader.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { VariantType } from '../../../../../analysis/modules/analysis-variant/models';
import { CheckboxComponent } from '../../../../../../shared/components/checkbox/checkbox.component';
import { AuthModuleState, getFeatureBits } from '../../../../../auth0/store';
import { FeatureBit } from '../../../../../auth0/models';

export interface FilterTreeSaveDialogResult {
  result: boolean;
  filterTree?: FilterTree;
}

export interface FilterTreeSaveDialogData {
  filterTree?: FilterTree;
  isDeleteAllowed?: boolean;
}

@Component({
  selector: 'gnx-filter-tree-save-dialog',
  templateUrl: './filter-tree-save-dialog.component.html',
  styleUrls: ['./filter-tree-save-dialog.component.scss'],
  standalone: true,
  imports: [
    BlockDirective,
    FlexItemDirective,
    TextDirective,
    ClickCursorDirective,
    ReactiveFormsModule,
    FlexDirective,
    LabelDirective,
    NgIf,
    InputDirective,
    TextAreaDirective,
    MatDialogActions,
    AnchorDirective,
    ButtonComponent,
    MatOption,
    MatSelect,
    NgForOf,
    SelectDirective,
    AsyncPipe,
    AutoCompleteComponent,
    AutoCompleteWithChipsComponent,
    DotsLoaderComponent,
    MatTooltipModule,
    CheckboxComponent,
  ],
})
export class FilterTreeSaveDialogComponent implements OnInit {
  FILTER_TREE_CASE_TYPE_OPTIONS = FILTER_TREE_CASE_TYPE_OPTIONS;
  FILTER_TREE_VARIANT_TYPE_OPTIONS = FILTER_TREE_VARIANT_TYPE_OPTIONS;

  formGroup: FormGroup;

  assays$: Observable<ExtendedAssayListEntity[]>;
  assaysAsOptions$: Observable<fromSharedModels.SelectOption[]>;
  assaysLoading$: Observable<boolean>;

  treeNames$: Observable<string[]>;
  treeNamesLoaded$: Observable<boolean>;

  isNew = false;
  isClone = false;
  cnvTreeDisabled = false;
  title: string;

  constructor(
    public dialogRef: MatDialogRef<FilterTreeSaveDialogComponent, FilterTreeSaveDialogResult>,
    @Inject(MAT_DIALOG_DATA)
    public data: FilterTreeSaveDialogData,
    private fb: FormBuilder,
    private rootStore$: Store<fromRootStore.AppModuleState>,
    private authStore$: Store<AuthModuleState>,
    private filterStore$: Store<FiltersState>,
  ) {}

  ngOnInit(): void {
    this.initUsedFilterTreeNames();

    this.formGroup = this.fb.group({
      tree: this.fb.group({
        name: this.fb.control(
          this.data?.filterTree?.tree?.name || '',
          [Validators.required],
          this.filterNameValidator.bind(this),
        ),
        tree_id: this.fb.control(this.data?.filterTree ? this.data?.filterTree?.tree?.tree_id ?? '' : ''),
        description: this.fb.control(this.data?.filterTree ? this.data?.filterTree?.tree?.description : null),
        case_type: this.fb.control(this.data?.filterTree ? this.data?.filterTree?.tree?.case_type : '', [
          Validators.required,
        ]),
        variant_type: this.fb.control(
          this.data?.filterTree ? this.data?.filterTree?.tree?.variant_type : VariantType.SNP,
        ),
        use_phenotypes_as_filter: this.fb.control(
          this.data?.filterTree ? this.data?.filterTree?.tree?.use_phenotypes_as_filter : false,
        ),
      }),
      tree_assays: this.fb.control(this.data?.filterTree?.tree_assays || []),
    });

    this.isNew = !this.data?.filterTree?.tree?.tree_id;
    this.isClone = !!this.data?.filterTree?.filter_definitions;
    this.title = this.isClone ? 'Create From Existing' : this.isNew ? 'Create New' : 'Edit Details';

    if (this.isClone) {
      this.formGroup.markAsDirty();
    }

    this.initAssays();

    this.authStore$.pipe(select(getFeatureBits), take(1)).subscribe((fb) => {
      if (!fb[FeatureBit.ShowSomaticTree]) {
        this.FILTER_TREE_CASE_TYPE_OPTIONS = FILTER_TREE_CASE_TYPE_OPTIONS.filter((type) => type.value !== 'tumor');
      }
      this.cnvTreeDisabled = !fb[FeatureBit.EnableCnvTree];
    });
  }

  initAssays(): void {
    combineLatest([
      this.rootStore$.pipe(select(getAssaysExtendedListLoading)),
      this.rootStore$.pipe(select(getAssaysExtendedListLoaded)),
    ])
      .pipe(take(1))
      .subscribe(([loading, loaded]) => {
        if (!loaded && !loading) {
          this.rootStore$.dispatch(new GetAssaysExtendedList('-created_at', ''));
        }
      });

    this.assays$ = combineLatest([
      this.rootStore$.pipe(select(fromRootStore.getAssaysExtendedList)),
      this.formGroup
        .get('tree.case_type')
        .valueChanges.pipe(startWith(this.data?.filterTree?.tree?.case_type || 'sample')),
    ]).pipe(
      map(([assays, caseType]: [ExtendedAssayListEntity[], string]) =>
        assays.filter(
          (assay) =>
            ((caseType !== 'tumor' && (assay.analysis_type === 'GERMLINE' || assay.analysis_type === 'ECS')) ||
              (caseType === 'tumor' && assay.analysis_type === 'SOMATIC')) &&
            assay.input_source !== AssayInputSource.SEARCH,
        ),
      ),
    );

    this.assaysAsOptions$ = this.assays$.pipe(
      map((assays) => assays.map((assay) => ({ label: assay.name, value: assay.uuid }))),
    );
    this.assaysLoading$ = this.rootStore$.pipe(select(getAssaysExtendedListLoading));
  }

  initUsedFilterTreeNames(): void {
    combineLatest([
      this.filterStore$.pipe(select(getFilterTreesLoading)),
      this.filterStore$.pipe(select(getFilterTreesLoaded)),
    ])
      .pipe(take(1))
      .subscribe(([loading, loaded]) => {
        if (!loaded && !loading) {
          this.filterStore$.dispatch(loadFilterTrees());
        }
      });

    this.treeNames$ = this.filterStore$.pipe(
      select(getFilterTrees),
      map((trees) => trees?.map((x) => x.name)),
    );
    this.treeNamesLoaded$ = this.filterStore$.pipe(select(getFilterTreesLoaded));
  }

  onCancel(): void {
    this.dialogRef.close({
      result: false,
    });
  }

  onSave(): void {
    this.dialogRef.close({
      result: true,
      filterTree: this.formGroup.value,
    });
  }

  onDelete(): void {
    const tree = this.data.filterTree;
    this.filterStore$.dispatch(
      deleteFilterTree({
        filterTreeId: tree.tree.tree_id,
        analysisType: tree.tree.case_type,
        variantType: tree.tree.variant_type as VariantType,
      }),
    );
    this.dialogRef.close();
  }

  onCaseTypeChange(event: MatSelectChange) {
    this.formGroup.patchValue({
      tree: { variant_type: event.value === AnalysisType.tumor ? '' : VariantType.SNP },
    });
  }

  filterNameValidator(ctrl: AbstractControl): Observable<ValidationErrors | null> {
    return this.treeNames$.pipe(
      take(1),
      map((filterNames) => filterNames?.filter((x) => this.isNew || x !== this.data.filterTree?.tree.name)),
      map((usedNames) =>
        usedNames?.map((x) => x.toLowerCase().trim())?.includes(ctrl.value?.toLowerCase().trim())
          ? { inUse: true }
          : null,
      ),
    );
  }

  get treeFormGroup(): FormGroup {
    return this.formGroup?.get('tree') as FormGroup;
  }
}
