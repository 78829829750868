import * as fromExportExcel from './export-as-excel.reducer';
import { ActionReducerMap, createFeatureSelector, MemoizedSelector } from '@ngrx/store';

export interface VariantsState {
  exportAsExcel: fromExportExcel.ExportAsExcelState;
}

export const variantsReducers: ActionReducerMap<VariantsState> = {
  exportAsExcel: fromExportExcel.reducer,
};

export const getVariantsState: MemoizedSelector<any, any> = createFeatureSelector<any>('variants');
