import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AuthModuleState } from '../../../modules/auth0/store/reducers';
import { getAuthErrorDescription, getSignUpInProgress } from '../../../modules/auth0/store/selectors';
import { AUTH_USER_ROLES, InviteeDataResponse } from '../../../modules/auth0/models';
import { InviteUsers, ResendEmail, SetInfo, SetSignUpStep, SignUp } from '../../../modules/auth0/store/actions';
import { emailDomainValidator } from '../../../modules/auth0/utils/email-domain-blacklist';
import { takeUntil, tap } from 'rxjs/operators';
import { Location, NgIf, NgClass, NgFor, AsyncPipe } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { RouterLink } from '@angular/router';
import { MatTooltip } from '@angular/material/tooltip';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { DotsLoaderComponent } from '../../../shared/components/dots-loader/dots-loader.component';
import { CheckboxComponent } from '../../../shared/components/checkbox/checkbox.component';
import { ClickCursorDirective } from '../../../shared/directives/click-cursor.directive';

@Component({
  selector: 'gnx-join-form',
  templateUrl: './join-form.component.html',
  styleUrls: ['./join-form.component.scss'],
  animations: [
    trigger('grow', [
      state('inOut', style({ flex: 1000 })),
      transition(':leave', animate(500, style({ flex: 0 }))),
      transition(':enter', [style({ flex: 0 }), animate(500)]),
    ]),
    trigger('appear', [
      state('inOut', style({ maxHeight: '72px' })),
      transition(':leave', animate(100, style({ maxHeight: 0 }))),
      transition(':enter', [style({ maxHeight: 0 }), animate(100)]),
    ]),
  ],
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    NgClass,
    ClickCursorDirective,
    CheckboxComponent,
    DotsLoaderComponent,
    MatSelect,
    NgFor,
    MatOption,
    MatTooltip,
    RouterLink,
    AsyncPipe,
  ],
})
export class JoinFormComponent implements OnInit, OnChanges, OnDestroy {
  ROLES = AUTH_USER_ROLES;

  @Input() step: number;
  @Input() manualInvite: boolean;
  @Input() @HostBinding('class.in-popup') inPopup: boolean;
  @Input() inviteeData: InviteeDataResponse;
  @Input() midway: boolean;
  @Output() signUpComplete: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('inputs') inputsRef: ElementRef;

  public joinFormGroup: FormGroup;
  public infoFormGroup: FormGroup;
  public teamFormGroup: FormGroup;
  public signUpInProgress$: Observable<boolean>;
  public errorDescription$: Observable<{
    login?: string;
    signUp?: string;
    reset?: string;
    setInfo?: string;
    changePassword?: string;
  }>;

  destroy$: Subject<void> = new Subject();
  visiblePassword: boolean;
  resendClicked: boolean;

  showErrors = false;

  constructor(
    private fb: FormBuilder,
    private authStore$: Store<AuthModuleState>,
    private location: Location,
    private ngxZendeskService: NgxZendeskWebwidgetService,
  ) {}

  ngOnInit() {
    this.joinFormGroup = this.fb.group({
      email: [
        '',
        [Validators.required, Validators.email, Validators.pattern(/^\S*[@]\S*[.]\S*$/), emailDomainValidator],
      ],
      password: ['', [Validators.required, Validators.minLength(8)]],
      acceptTerms: [false, Validators.requiredTrue],
    });
    this.infoFormGroup = this.fb.group({
      fullName: ['', [Validators.required, this.fullNameValidator.bind(this), this.notEmailValidator.bind(this)]],
      organization: ['', [Validators.required, Validators.maxLength(95), this.orgNameValidator.bind(this)]],
      role: ['', [Validators.required, this.roleValidator.bind(this)]],
      other: ['', [this.otherRoleValidator.bind(this)]],
    });
    this.teamFormGroup = this.fb.group({
      team: this.fb.array(new Array(3).fill(null).map(() => this.createTeamMemberGroup())),
    });

    if (this.inviteeData) {
      this.disablePrefilledFields();
    }

    this.signUpInProgress$ = this.authStore$.pipe(select(getSignUpInProgress), takeUntil(this.destroy$));
    this.errorDescription$ = this.authStore$.pipe(
      select(getAuthErrorDescription),
      takeUntil(this.destroy$),
      tap((err) => {
        this.infoFormGroup.get('organization').setErrors({ taken: (err && err.setInfo) || undefined });
      }),
    );
    this.signUpInProgress$.subscribe();
    this.errorDescription$.subscribe();

    this.joinFormGroup.valueChanges.subscribe(() => (this.showErrors = false));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inviteeData && changes.inviteeData.currentValue && this.joinFormGroup) {
      this.disablePrefilledFields();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  onJoinSubmit() {
    if (this.joinFormGroup.invalid) {
      this.showErrors = true;
      return;
    }
    this.authStore$.dispatch(
      new SignUp(
        this.joinFormGroup.get('email').value,
        this.joinFormGroup.get('password').value,
        this.inviteeData ? this.inviteeData.uuid : undefined,
        this.inPopup,
      ),
    );
  }

  onContactUs() {
    this.ngxZendeskService.zE('webWidget', 'open');
  }

  onResendEmail() {
    this.resendClicked = true;
    this.authStore$.dispatch(new ResendEmail());
  }

  onSkipInvite() {
    this.authStore$.dispatch(new SetSignUpStep(3));
  }

  onInfoSubmit() {
    if (this.infoFormGroup.invalid) {
      return;
    }
    this.authStore$.dispatch(
      new SetInfo(
        this.infoFormGroup.get('fullName').value,
        this.infoFormGroup.get(this.infoFormGroup.get('role').value === 'Other' ? 'other' : 'role').value,
        this.infoFormGroup.get('organization').value,
        this.inviteeData ? this.inviteeData.org_id : undefined,
        this.inPopup,
      ),
    );
  }

  onTeamSubmit() {
    this.authStore$.dispatch(
      new InviteUsers(
        this.t.team.value.filter((value) => !!value.email),
        this.inPopup,
      ),
    );
  }

  goBack() {
    this.location.back();
  }

  addInput() {
    this.team.push(this.createTeamMemberGroup());
    setTimeout(() => {
      this.inputsRef.nativeElement.scrollTo({
        top: this.inputsRef.nativeElement.scrollHeight,
        behavior: 'smooth',
      });
    }, 10);
  }

  get i() {
    return this.infoFormGroup.controls;
  }

  get t() {
    return this.teamFormGroup.controls;
  }

  get team(): FormArray {
    return this.teamFormGroup.get('team') as FormArray;
  }

  get teamEmails(): string {
    return (this.teamFormGroup.get('team') as FormArray).value
      .filter((value) => !!value.email)
      .map((value) => value.email)
      .join(', ');
  }

  getMemberEmailControl(index: number): AbstractControl {
    return (this.teamFormGroup.get('team') as FormArray).at(index).get('email');
  }

  get j() {
    return this.joinFormGroup.controls;
  }

  private disablePrefilledFields() {
    this.joinFormGroup.get('email').setValue(this.inviteeData.email);
    this.joinFormGroup.get('email').disable();
    this.infoFormGroup.get('organization').setValue(this.inviteeData.org_name);
    this.infoFormGroup.get('organization').disable();
  }

  private selfEmailValidator(control: FormControl) {
    const email = control.value.trim().toLowerCase();
    const selfEmail = this.j.email.value.trim().toLowerCase();
    if (selfEmail && email === selfEmail) {
      return {
        self: true,
      };
    }
    return null;
  }

  private roleValidator(control: FormControl) {
    if (!this.infoFormGroup) {
      return;
    }
    const role = control.value;
    if (role !== 'Other') {
      this.i.other.setErrors(null);
    } else if (!this.i.other.value) {
      this.i.other.setErrors(
        role !== 'Other'
          ? null
          : {
              required: true,
            },
      );
    }
    return null;
  }

  private otherRoleValidator(control: FormControl) {
    const role = control.value;
    if (!role && this.infoFormGroup && this.i.role.value === 'Other') {
      return {
        required: true,
      };
    }
    return null;
  }

  private fullNameValidator(control: FormControl) {
    const email = control.value.trim().toLowerCase();
    if (!email.includes(' ')) {
      return {
        fullName: true,
      };
    }
    return null;
  }

  private notEmailValidator(control: FormControl) {
    const email = control.value.trim().toLowerCase();
    if (email.includes('@')) {
      return {
        email: true,
      };
    }
    return null;
  }

  private orgNameValidator(control: FormControl) {
    const name = control.value;
    if (name.length && !name.match(/^[a-z]/i)) {
      return {
        nonAlphaStart: true,
      };
    }
    return null;
  }

  private createTeamMemberGroup(): FormGroup {
    return this.fb.group({
      email: this.fb.control('', [
        Validators.email,
        Validators.pattern(/^\S*[@]\S*[.]\S*$/),
        emailDomainValidator,
        this.selfEmailValidator.bind(this),
      ]),
      admin: this.fb.control(false),
    });
  }
}
