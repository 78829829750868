import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WorkbenchService } from '../services/workbench.service';
import { MatDialog } from '@angular/material/dialog';
import {
  addToVariantLists,
  addToVariantListsFail,
  addToVariantListsSuccess,
  ClearVariantsChecked,
  editVariantLists,
  loadVariantLists,
  loadVariantListsFail,
  loadVariantListsSuccess,
  removeFromVariantLists,
  removeFromVariantListsFail,
  removeFromVariantListsSuccess,
  TakeBulkVariantAction,
} from '../actions';
import {
  AddToVariantListsDialogComponent,
  AddToVariantListsDialogData,
} from '../../components/add-to-variant-lists-dialog/add-to-variant-lists-dialog.component';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { VariantInterpretationType } from '../../../analysis-variant/models';
import { AnalysisType, OpenSnackbar } from '../../../../../../store';
import { SnackbarMode } from '../../../../../../store/models/snackbar.model';

@Injectable()
export class VariantListEffect {
  constructor(
    private actions$: Actions,
    private service: WorkbenchService,
    private matDialog: MatDialog,
  ) {}

  loadVariantLists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadVariantLists),
      switchMap(({ analysisId, analysisType, variantType, referenceVersion }) =>
        this.service.getVariantLists(analysisId, analysisType, variantType, referenceVersion).pipe(
          map((result) => loadVariantListsSuccess({ data: result })),
          catchError((error) => of(loadVariantListsFail({ error }))),
        ),
      ),
    ),
  );

  editVariantLists$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(editVariantLists),
        map(({ analysisId, analysisType, variantType, variants, referenceVersion }) =>
          this.matDialog.open<AddToVariantListsDialogComponent, AddToVariantListsDialogData>(
            AddToVariantListsDialogComponent,
            {
              height: '696px',
              width: '780px',
              panelClass: 'paddingless-dialog',
              data: {
                analysisId,
                analysisType,
                variantType,
                variants,
                referenceVersion,
              },
            },
          ),
        ),
      ),
    { dispatch: false },
  );

  addToVariantLists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addToVariantLists),
      switchMap(
        ({
          analysisId,
          analysisType,
          variantType,
          variants,
          referenceVersion,
          variantLists,
          markNotRelevant,
          addToWorkbench,
        }) =>
          this.service
            .addToVariantLists(analysisId, analysisType, variantType, variants, referenceVersion, variantLists)
            .pipe(
              map(() =>
                addToVariantListsSuccess({
                  analysisId,
                  referenceVersion,
                  variantType,
                  variants,
                  analysisType,
                  markNotRelevant,
                  addToWorkbench,
                }),
              ),
              catchError((error) => of(addToVariantListsFail({ error }))),
            ),
      ),
    ),
  );

  addToVariantListsSuccessMarkIrrelevant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addToVariantListsSuccess),
      filter((action) => !!action.markNotRelevant),
      map(
        (action) =>
          new TakeBulkVariantAction(
            action.analysisId,
            action.analysisType,
            VariantInterpretationType.irrelevant,
            true,
            action.variants,
            action.analysisType === AnalysisType.tumor ? undefined : ['Other'],
          ),
      ),
    ),
  );

  addToVariantListsSuccessAddToWb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addToVariantListsSuccess),
      filter((action) => !!action.addToWorkbench),
      map(
        (action) =>
          new TakeBulkVariantAction(
            action.analysisId,
            action.analysisType,
            VariantInterpretationType.in_workbench,
            true,
            action.variants,
          ),
      ),
    ),
  );

  addToVariantListsFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addToVariantListsFail),
      map(() => new OpenSnackbar('Error saving to variant lists', SnackbarMode.Error)),
    ),
  );

  removeFromVariantLists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeFromVariantLists),
      switchMap(({ analysisId, analysisType, variantType, referenceVersion, variants }) =>
        this.service.removeFromVariantLists(analysisId, analysisType, variantType, variants, referenceVersion).pipe(
          map((success) =>
            success
              ? removeFromVariantListsSuccess()
              : removeFromVariantListsFail({ error: 'BE returned success != true' }),
          ),
          catchError((error) => of(removeFromVariantListsFail({ error }))),
        ),
      ),
    ),
  );

  removeFromVariantListFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeFromVariantListsFail),
      map(() => new OpenSnackbar('Error removing from variant lists', SnackbarMode.Error)),
    ),
  );

  removeFromVariantListSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeFromVariantListsSuccess),
      map(() => new OpenSnackbar('Variants removed from variant lists', SnackbarMode.SuccessNoPanda, undefined, 2000)),
    ),
  );

  addToVariantListsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addToVariantListsSuccess),
      map(() => new OpenSnackbar('Variants added to variant lists', SnackbarMode.SuccessNoPanda, undefined, 2000)),
    ),
  );

  clearCheckedVariants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addToVariantListsSuccess, removeFromVariantListsSuccess),
      map(() => new ClearVariantsChecked()),
    ),
  );
}
