<div *ngIf="questions && searchControl.value" class="case-container" [formGroup]="caseFormGroup" [@grow]="'inOutLarge'">
  <div *ngIf="(searchHistory$ | async)?.search_context as searchContext" [@grow]="'inOut'" class="case-history">
    <img src="assets/svg/history.svg" width="39" height="30" />
    <div class="case-history-data">
      <div>You’ve searched this variant before ({{ searchContext.search_timestamp | date }})</div>
      <strong class="case-history-details">
        <span *ngIf="searchContext.case_context?.birth_date">
          {{ searchContext.case_context.birth_date | amFromUtc | amTimeAgo: true }} old
        </span>
        <span *ngIf="searchContext.case_context?.sex">
          {{ searchContext.case_context.sex | titlecase }}
        </span>
        <span *ngIf="searchContext.case_context?.zygosity">
          {{ searchContext.case_context.zygosity | titlecase }}
        </span>
        <span *ngIf="searchContext.case_context?.ethnicity?.length">
          {{ searchContext.case_context.ethnicity.join(', ') | titlecase }}
        </span>
        <span
          #phenotypes
          *ngIf="searchContext.case_context?.phenotypes?.length"
          [matTooltip]="searchContext.case_context.phenotypes.join(', ')"
          [matTooltipDisabled]="phenotypes.scrollWidth <= phenotypes.clientWidth"
          matTooltipClass="blue-md-tooltip wide"
        >
          {{ searchContext.case_context.phenotypes.join(', ') }}
        </span>
      </strong>
    </div>
    <a (click)="runHistory(searchContext)">Run the same search</a>
    <img (click)="clearHistory()" src="assets/svg/x-blue.svg" width="14" height="14" />
  </div>
  <div class="case-content">
    <img *ngIf="closeable" src="assets/svg/close-blue.svg" (click)="onClose()" />
    <div class="add-details-label">Add your case details</div>
    <div class="case-data">
      <div #q *ngIf="getQuestionVisible('zygosity')" [@grow]="'inOut'" class="question-container">
        <div class="question-label">What is the variant zygosity?</div>
        <mat-radio-group class="question-data" formControlName="zygosity">
          <mat-radio-button *ngFor="let zygosity of zygosityOptions" class="icon-button" [value]="zygosity.value">
            {{ zygosity.name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div #q *ngIf="getQuestionVisible('phenotypes')" [@grow]="'inOut'" class="question-container">
        <div class="question-label">What are the patient phenotypes?</div>
        <div class="question-data">
          <input [matAutocomplete]="autocomplete" placeholder="HPO phenotypes" [formControl]="phenotypeSearchControl" />
          <mat-autocomplete #autocomplete>
            <mat-option
              *ngFor="let phenotype of phenotypes$ | async"
              [disabled]="phenotype.value === 'error'"
              [ngClass]="{ 'error-phenotype': phenotype.value === 'error' }"
              (onSelectionChange)="onPhenotypeSelected(phenotype)"
            >
              <gnx-phenotype-option [input]="phenotypeSearchControl.value" [phenotype]="phenotype">
              </gnx-phenotype-option>
            </mat-option>
          </mat-autocomplete>
          <gnx-dots-loader *ngIf="phenotypesLoading$ | async"></gnx-dots-loader>
        </div>
        <div class="chips">
          <div class="chip" *ngFor="let item of caseFormGroup.get('phenotypes').value">
            <img src="/assets/svg/x-white.svg" (click)="onPhenotypeRemove(item)" />
            {{ item }}
          </div>
        </div>
      </div>
      <div #q *ngIf="getQuestionVisible('cancer_type')" [@grow]="'inOut'" class="question-container">
        <div class="question-label">What is the cancer type?</div>
        <div *ngIf="!f.cancer_type.value" class="question-data">
          <input [matAutocomplete]="autocomplete" placeholder="Cancer types" [formControl]="cancerTypeSearchControl" />
          <mat-autocomplete #autocomplete>
            <mat-option
              *ngFor="let cancerType of (cancerTypes$ | async)?.exactMatch"
              (onSelectionChange)="onCancerTypeSelected(cancerType)"
              [disabled]="cancerType.matchedNames[0] === 'error'"
              [ngClass]="{ 'error-phenotype': cancerType.matchedNames[0] === 'error' }"
            >
              <div
                class="search-option"
                [ngClass]="{ error: cancerType.matchedNames[0] === 'error' }"
                [innerHTML]="addHighlight(cancerType.primaryName, cancerTypeSearchControl.value) | safeHtml"
                #geneOverflow
                [matTooltip]="cancerType.primaryName"
                [matTooltipDisabled]="geneOverflow.scrollWidth <= geneOverflow.clientWidth"
                matTooltipShowDelay="0"
                matTooltipHideDelay="200"
              ></div>
            </mat-option>
          </mat-autocomplete>
          <gnx-dots-loader *ngIf="cancerTypesLoading$ | async"></gnx-dots-loader>
        </div>
        <div *ngIf="f.cancer_type.value" class="chips bordered-chips">
          <div class="chip">
            <img src="/assets/svg/x-white.svg" (click)="onCancerTypeRemove()" />
            {{ f.cancer_type.value }}
          </div>
        </div>
      </div>
      <div #q *ngIf="getQuestionVisible('ethnicity')" [@grow]="'inOut'" class="question-container">
        <div class="question-label">What is the patient ethnicity?</div>
        <div class="question-data">
          <input
            [matAutocomplete]="ethnicity"
            placeholder="Choose patient ethnicity"
            [formControl]="ethnicitySearchControl"
          />
          <mat-autocomplete #ethnicity>
            <mat-option
              *ngFor="let ethnicity of ethnicities$ | async"
              [disabled]="ethnicity.id === 'error'"
              [class.error-phenotype]="ethnicity.id === 'error'"
              (onSelectionChange)="onEthnicitySelected(ethnicity)"
            >
              <span [class.error-msg]="ethnicity.id === 'error'">{{ ethnicity.name }}</span>
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="chips">
          <div class="chip" *ngFor="let item of caseFormGroup.get('ethnicity').value">
            <img src="/assets/svg/x-white.svg" (click)="onEthnicityRemove(item)" />
            {{ item }}
          </div>
        </div>
      </div>
      <div #q *ngIf="getQuestionVisible('consanguinity')" [@grow]="'inOut'" class="question-container">
        <div class="question-label">Is the family known to be consanguineous?</div>
        <mat-radio-group class="question-data" formControlName="consanguinity">
          <mat-radio-button
            *ngFor="let consanguinity of CONSANGUINITY"
            class="icon-button"
            [value]="consanguinity.value"
          >
            {{ consanguinity.name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div #q *ngIf="getQuestionVisible('family_inheritance_status')" [@grow]="'inOut'" class="question-container">
        <div class="question-label">Who did the patient inherit the variant from?</div>
        <mat-radio-group class="question-data" formControlName="family_inheritance_status">
          <mat-radio-button *ngFor="let inheritance of INHERITANCES" class="icon-button" [value]="inheritance.value">
            {{ inheritance.name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div #q *ngIf="getQuestionVisible('reported_classification')" [@grow]="'inOut'" class="question-container">
        <div class="question-label">Do you have the reported classification?</div>
        <mat-radio-group class="question-data" formControlName="reported_classification">
          <mat-radio-button
            *ngFor="let classification of classificationOptions"
            class="icon-button smaller"
            [value]="classification.key"
          >
            {{ classification.name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div #q *ngIf="getQuestionVisible('sex')" [@grow]="'inOut'" class="question-container">
        <div class="question-label">What is the patient sex?</div>
        <mat-radio-group class="question-data" formControlName="sex">
          <mat-radio-button *ngFor="let sex of SEXES" class="icon-button" [value]="sex.value">
            <img [src]="sex.icon" />
            {{ sex.name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="questions">
      <div>{{ questions }}/{{ visibleQuestions.length }} questions</div>
      <div class="progress-wrapper">
        <div class="progress-bar" [style.right.px]="150 - (150 / visibleQuestions.length) * questions"></div>
      </div>
    </div>
    <a *ngIf="questions < visibleQuestions.length" (click)="onSkipQuestion()">Skip question</a>
    <button (click)="search.emit(true)">Search</button>
  </div>
</div>
