import * as fromAction from './../actions/export-results.action';
import { ResultsFile } from '../models';

export interface ExportResultsState {
  file: ResultsFile;
  loading: boolean;
  loaded: boolean;
  exportingResult: boolean;
  error: any;
}

const initialState: ExportResultsState = {
  file: undefined,
  loading: false,
  loaded: false,
  exportingResult: false,
  error: undefined,
};

export function reducer(state = initialState, action: fromAction.ExportResultsAction): ExportResultsState {
  switch (action.type) {
    case fromAction.GET_EXPORT_RESULTS_URL: {
      return {
        ...state,
        file: undefined,
        loading: true,
        loaded: false,
        error: undefined,
      };
    }
    case fromAction.GET_EXPORT_RESULTS_URL_SUCCESS: {
      return {
        ...state,
        file: action.result,
        loading: false,
        loaded: true,
      };
    }
    case fromAction.GET_EXPORT_RESULTS_URL_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    }
  }

  return { ...state };
}

export const getExportResultsUrl = (state: ExportResultsState) => state.file;
export const getExportResultsUrlLoading = (state: ExportResultsState) => state.loading;
export const getExportResultsUrlLoaded = (state: ExportResultsState) => state.loaded;
export const getExportingResultsState = (state: ExportResultsState) => state.exportingResult;
