import {
  InterpretationCustomFieldsAction,
  GET_INTERPRETATION_CUSTOM_FIELDS,
  GET_INTERPRETATION_CUSTOM_FIELDS_SUCCESS,
} from '../actions';
import { DynamicInputField } from '../../../../../../shared/models';

export interface InterpretationCustomFieldsState {
  fields: DynamicInputField[];
}

export const initialState: InterpretationCustomFieldsState = {
  fields: [],
};

export const reducer = (
  state = initialState,
  action: InterpretationCustomFieldsAction,
): InterpretationCustomFieldsState => {
  switch (action.type) {
    case GET_INTERPRETATION_CUSTOM_FIELDS: {
      return {
        ...state,
        fields: [],
      };
    }
    case GET_INTERPRETATION_CUSTOM_FIELDS_SUCCESS: {
      return {
        ...state,
        fields: action.fields,
      };
    }
  }
  return state;
};
