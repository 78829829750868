export enum VariantFilterType {
  Search,
  IsShared,
  IsFollowed,
  Organization,
  Classification,
  Inheritance,
  VariantType,
  Region,
  GnomadFreq,
  Suggested,
  SomaticClassification,
  Assignee,
}
