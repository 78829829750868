import { BannerType } from '../../models';
import * as fromAction from './../actions/app-banner.action';

export interface AppBannerState {
  hiddenBanner?: {
    [key in BannerType]: boolean;
  };
}

const initialState: AppBannerState = {};

export function reducer(state = initialState, action: fromAction.AppBannerAction): AppBannerState {
  switch (action.type) {
    case fromAction.HIDE_BANNER: {
      return {
        ...state,
        hiddenBanner: {
          ...state.hiddenBanner,
          [action.bannerType]: true,
        },
      };
    }
  }
  return state;
}
