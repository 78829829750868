<div class="title">Profile Details</div>
<form [formGroup]="profileForm">
  <div class="input-container">
    <div>
      <label *ngIf="p.name.valid || p.name.untouched" for="name" [ngClass]="{ red: p.name.invalid && p.name.touched }">
        Full Name
      </label>
      <span class="red" *ngIf="p.name.invalid && p.name.touched">
        {{ p.name.errors?.required ? 'Please enter your name' : '' }}
        {{
          p.name.errors?.fullName && !p.name.errors?.required && !p.name.errors?.email
            ? 'Please enter your full name'
            : ''
        }}
        {{ p.name.errors?.email ? 'This looks like an email address, please enter your name' : '' }}
      </span>
    </div>
    <input id="name" formControlName="name" [ngClass]="{ red: p.name.invalid && p.name.touched }" />
  </div>

  <div class="input-container">
    <div>
      <label for="email">Email Address</label>
    </div>
    <input type="email" id="email" placeholder="YourName@Company.com" formControlName="email" />
  </div>
  <div class="input-container">
    <div>
      <label for="role" *ngIf="(p.role.valid || p.role.untouched) && p.other.valid"> Professional Role </label>
      <span class="red" *ngIf="(p.role.invalid && p.role.touched) || p.other.invalid">
        {{ p.role.errors?.required ? 'Please enter in your role' : '' }}
        {{ p.other.errors?.required ? 'Please enter in your role' : '' }}
      </span>
    </div>
    <div class="input" [ngClass]="{ red: (p.role.invalid && p.role.touched) || p.other.invalid }">
      <mat-select
        id="role"
        formControlName="role"
        panelClass="role-select-panel"
        placeholder="Please select your role in the organization"
        [ngClass]="{ red: (p.role.invalid && p.role.touched) || p.other.invalid }"
      >
        <mat-option *ngFor="let role of ROLES" [value]="role">{{ role }}</mat-option>
      </mat-select>
      <input
        [@grow]="'inOut'"
        *ngIf="p.role.value === 'Other'"
        placeholder="Please specify role"
        formControlName="other"
      />
    </div>
  </div>
</form>
<hr />
<div class="change-password">
  <div class="subheader">Password</div>
  <div>
    <div class="password-hint">
      <a [routerLink]="['/reset']" [queryParams]="{ step: 2 }">Change Password</a>
    </div>
  </div>
</div>

<div class="right-column">
  <button class="cancel-btn" [disabled]="!formChanged" (click)="onCancel()">Cancel</button>
  <button class="save-btn" [disabled]="!formChanged" (click)="onSubmit()">
    <span *ngIf="!(updateUserInProgress$ | async)">Save</span>
    <gnx-dots-loader *ngIf="updateUserInProgress$ | async"></gnx-dots-loader>
  </button>
</div>
