import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { GenesService } from '../services';

import * as fromAction from './../actions/genes.action';

@Injectable()
export class GenesEffects {
  constructor(
    private actions: Actions,
    private service: GenesService,
  ) {}

  searchGenes$ = createEffect(() =>
    this.actions.pipe(
      ofType(fromAction.SEARCH_GENES),
      switchMap((action: fromAction.SearchGenes) =>
        this.service.searchGenes(action.str).pipe(
          map((res) => new fromAction.SearchGenesSuccess(res)),
          catchError((err) => of(new fromAction.SearchGenesFail(err))),
        ),
      ),
    ),
  );
}
