import { Action } from '@ngrx/store';

export const MENU_NAVIGATE = '[Variants] Menu Navigate';
export const MENU_NAVIGATE_SUCCESS = '[Variants] Menu Navigate success';
export const MENU_NAVIGATE_FAIL = '[Variants] Menu Navigate fail';

export class MenuNavigate implements Action {
  readonly type = MENU_NAVIGATE;

  constructor(
    public payload: string,
    public params?: any,
  ) {}
}

export class MenuNavigateSuccess implements Action {
  readonly type = MENU_NAVIGATE_SUCCESS;

  constructor(
    public payload: string,
    public redirectUrl?: string,
  ) {}
}

export class MenuNavigateFail implements Action {
  readonly type = MENU_NAVIGATE_FAIL;

  constructor(
    public payload: string,
    public err: any,
  ) {}
}

export type MenuAction = MenuNavigate | MenuNavigateSuccess | MenuNavigateFail;
