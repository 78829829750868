import * as fromReducer from '../reducers/analysis-results.reducer';
import { AnalysisResultState } from '../reducers/analysis-results.reducer';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { getWorkbenchModuleState, WorkbenchModuleState } from '../reducers';
import { AnalysisResult, getAnalysisResultVariantId, IgvLink } from '../../../analysis-variant/models';
import { VariantsCount } from '../../../shared/models';

const getAnalysisResultState: MemoizedSelector<WorkbenchModuleState, AnalysisResultState> = createSelector(
  getWorkbenchModuleState,
  (state: WorkbenchModuleState) => state?.analysisResult,
);

export const getAnalysisResult: MemoizedSelector<WorkbenchModuleState, AnalysisResult[]> = createSelector(
  getAnalysisResultState,
  fromReducer.getAnalysisResultData,
);

export const getAnalysisResultAsEntities: MemoizedSelector<WorkbenchModuleState, { [key: string]: AnalysisResult }> =
  createSelector(getAnalysisResult, (results) =>
    results.reduce((prev, cur) => ({ ...prev, [getAnalysisResultVariantId(cur)]: cur }), {}),
  );

export const getAnalysisVariantsCount: MemoizedSelector<any, VariantsCount> = createSelector(
  getAnalysisResultState,
  fromReducer.getVariantsCount,
);

export const getAnalysisResultLoaded: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getAnalysisResultState,
  fromReducer.getAnalysisResultLoaded,
);

export const getAnalysisResultLoading: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getAnalysisResultState,
  fromReducer.getAnalysisResultLoading,
);

export const getAnalysisResultsLoadError: MemoizedSelector<WorkbenchModuleState, any> = createSelector(
  getAnalysisResultState,
  fromReducer.getAnalysisResultsLoadError,
);

export const getClinicalSignificanceGroups: MemoizedSelector<WorkbenchModuleState, string[]> = createSelector(
  getAnalysisResultState,
  fromReducer.getClinicalSignificanceGroups,
);

export const getClinicalSignificanceGroupsDisplayNames: MemoizedSelector<
  WorkbenchModuleState,
  { [section: string]: string }
> = createSelector(getAnalysisResultState, fromReducer.getClinicalSignificanceGroupsDisplayNames);

export const getClinicalSignificanceGroupsLoading: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getAnalysisResultState,
  fromReducer.getClinicalSignificanceGroupsLoading,
);

export const getClinicalSignificanceGroupsLoaded: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getAnalysisResultState,
  fromReducer.getClinicalSignificanceGroupsLoaded,
);

export const getSetVariantClinicalSignificanceInProgress: MemoizedSelector<
  WorkbenchModuleState,
  { [key: string]: boolean }
> = createSelector(getAnalysisResultState, fromReducer.getSetVariantClinicalSignificanceInProgress);

export const getVarIdsSortedByUiGroups: MemoizedSelector<
  WorkbenchModuleState,
  { group?: string; var_ids: string[] }[]
> = createSelector(getAnalysisResultState, (state) => state.varIdsUiSortedByGroup);

export const getClinicalSignificanceResults: MemoizedSelector<
  WorkbenchModuleState,
  { [key: string]: AnalysisResult[] }
> = createSelector(getVarIdsSortedByUiGroups, getAnalysisResultAsEntities, (groups, entities) =>
  groups
    .filter((g) => !!g.group)
    .reduce((prev, cur) => ({ ...prev, [cur.group]: cur.var_ids.map((varId) => entities[varId]) }), {}),
);

export const getClinicalSignificanceEmptyGroups: MemoizedSelector<WorkbenchModuleState, string[]> = createSelector(
  getClinicalSignificanceGroups,
  getClinicalSignificanceResults,
  (groups: string[], results: { [key: string]: AnalysisResult[] }) =>
    groups.filter((group) => !(results[group]?.length > 0)),
);

export const getNonClinicalSignificanceResults: MemoizedSelector<WorkbenchModuleState, AnalysisResult[]> =
  createSelector(getVarIdsSortedByUiGroups, getAnalysisResultAsEntities, (groups, entities) =>
    groups.find((g) => !g.group)?.var_ids.map((varId) => entities[varId]),
  );

export const getSortedResults: MemoizedSelector<WorkbenchModuleState, AnalysisResult[]> = createSelector(
  getVarIdsSortedByUiGroups,
  getAnalysisResultAsEntities,
  (groups, entities) =>
    groups.reduce((acc, crt) => {
      return [...acc, ...crt.var_ids.map((varId) => entities[varId])];
    }, []),
);

export const getClinicalSignificanceReasoningOptions: MemoizedSelector<WorkbenchModuleState, string[]> = createSelector(
  getAnalysisResultState,
  fromReducer.getClinicalSignificanceReasoningOptions,
);

export const getClinicalSignificanceReasoningOptionsLoading: MemoizedSelector<WorkbenchModuleState, boolean> =
  createSelector(getAnalysisResultState, fromReducer.getClinicalSignificanceReasoningOptionsLoading);

export const getClinicalSignificanceReasoningOptionsLoaded: MemoizedSelector<WorkbenchModuleState, boolean> =
  createSelector(getAnalysisResultState, fromReducer.getClinicalSignificanceReasoningOptionsLoaded);

const resultHasClinicalSignificance = (significance: string, result: any) => {
  return result?.data?.clinical_significance_data?.significance === significance;
};

export const getWbVariantsIgvLink: MemoizedSelector<any, { [id: string]: IgvLink }> = createSelector(
  getAnalysisResultState,
  (state) => state.desktopIgvLinks,
);
