import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { ClickCursorDirective } from '../../shared/directives/click-cursor.directive';
import { NgIf } from '@angular/common';
import { CheckboxComponent } from '../../shared/components/checkbox/checkbox.component';
import { FlexDirective } from '../../shared/directives/flex.directive';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

export interface WarningDialogData {
  title?: string;
  description?: string;
  yesButtonLabel?: string;
  cancelButtonLabel?: string;
  checkboxLabel?: string;
  hideTopSection?: boolean;
  yesButtonCallback?: (dontShow?: boolean) => void;
  cancelButtonCallback?: () => void;
  reverseButtonsOrder?: boolean;
  hideCancelBtn?: boolean;
  showCheckbox?: boolean;
  checkboxRequired?: boolean;
}

@Component({
  selector: 'gnx-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss'],
  standalone: true,
  imports: [NgIf, ClickCursorDirective, MatDialogClose, CheckboxComponent, FlexDirective, ReactiveFormsModule],
})
export class WarningDialogComponent implements OnInit {
  checkboxCtrl: FormControl;

  constructor(
    public dialogRef: MatDialogRef<WarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WarningDialogData,
  ) {}

  ngOnInit() {
    this.checkboxCtrl = new FormControl(false);
  }

  onClose() {
    this.dialogRef.close();
  }

  defaultYesButtonCallback() {
    this.dialogRef.close(true);
  }

  defaultNoButtonCallback() {
    this.dialogRef.close(false);
  }
}
