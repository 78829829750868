import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { JsonService } from '../../../shared/services/json.service';
import { select, Store } from '@ngrx/store';
import {
  AnalysisCompoundVariant,
  AnalysisResult,
  analysisResultToVariantPageVariantId,
  SnpResult,
  SvResult,
  VariantActionType,
  VariantActionValue,
  VariantInterpretation,
  VariantInterpretationType,
  VariantType,
} from '../../../analysis-variant/models';
import * as fromWBActions from '../actions';
import {
  ClearVariantClinicalSignificance,
  ReloadAnalysisVariants,
  SetVariantClinicalSignificance,
  TakeBulkVariantActionFail,
  TakeBulkVariantActionSuccess,
} from '../actions';
import { VariantsState } from '../../../variants/store';
import {
  AnalysisDetails,
  AnalysisType,
  AppModuleState,
  getAnalysisDetails,
  getAnalysisId,
} from '../../../../../../store';
import { NOT_RELEVANT_SIGNIFICANCE_GROUP } from '../models';
import { MatDialog } from '@angular/material/dialog';
import {
  ClinicalSignificanceReasonDialogComponent,
  ClinicalSignificanceReasonDialogData,
  ClinicalSignificanceReasonDialogResult,
} from '../../components/clinical-significance-reason-dialog/clinical-significance-reason-dialog.component';
import {
  getCheckedResults,
  getClinicalSignificanceReasoningOptions,
  getPatientInfoResponse,
  getVariantInterpretations,
} from '../selectors';
import { WorkbenchModuleState } from '../reducers';
import { WorkbenchService } from '../services/workbench.service';
import { SearchConfig } from '../../../../../variant-page/modules/variant-page/models';
import { InterpretationService } from '../../../../../variant-page/modules/interpretation/services/interpretation.service';
import { AddEvidenceToVariantDialogComponent } from '../../../variants/components/add-evidence-to-variant-dialog/add-evidence-to-variant-dialog.component';
import { AddCommentToVariantDialogComponent } from '../../../variants/components/add-comment-to-variant-dialog/add-comment-to-variant-dialog.component';
import { VariantCommentsService } from '../../../variants/services/variant-comments.service';
import { getCompoundVariantId, getVariantId } from '../../utils/analysis-result.utils';

const NOOP_IRRELEVANT_REASON = 'NOOP_IRRELEVANT_REASON';

@Injectable()
export class VariantInterpretationEffects {
  constructor(
    private actions$: Actions,
    private service: JsonService,
    private workbenchService: WorkbenchService,
    private store$: Store<WorkbenchModuleState>,
    private rootStore$: Store<VariantsState>,
    private appStore$: Store<AppModuleState>,
    private dialog: MatDialog,
    private interpretationService: InterpretationService,
    private variantCommentsService: VariantCommentsService,
  ) {}

  $takeBulkVariantActionDialog: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromWBActions.TAKE_BULK_VARIANT_ACTION),
      withLatestFrom(
        this.appStore$.pipe(select(getAnalysisDetails)),
        this.store$.pipe(select(getClinicalSignificanceReasoningOptions)),
      ),
      filter(([action, analysisDetails]: [fromWBActions.TakeBulkVariantAction, AnalysisDetails, string[]]) =>
        this.actionNeedsIrrelevantReasonDialog(action, analysisDetails.analysis_type),
      ),
      mergeMap(
        ([action, analysisDetails, reasoningOptions]: [
          fromWBActions.TakeBulkVariantAction,
          AnalysisDetails,
          string[],
        ]) => {
          const dialogRef = this.dialog.open<
            ClinicalSignificanceReasonDialogComponent,
            ClinicalSignificanceReasonDialogData,
            ClinicalSignificanceReasonDialogResult
          >(ClinicalSignificanceReasonDialogComponent, {
            height: '490px',
            width: '780px',
            panelClass: 'paddingless-dialog',
            data: {
              reasoningOptions,
            },
          });
          return dialogRef.afterClosed().pipe(
            filter((dialogResult) => !!dialogResult),
            map(
              (dialogResult) =>
                new fromWBActions.TakeBulkVariantAction(
                  analysisDetails.id,
                  analysisDetails.analysis_type,
                  action.actionType,
                  action.value,
                  action.analysisResults,
                  dialogResult.reasons || [NOOP_IRRELEVANT_REASON],
                  dialogResult.details,
                  action.clinicalSignificance,
                ),
            ),
          );
        },
      ),
    ),
  );

  $takeBulkVariantAction: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType<fromWBActions.TakeBulkVariantAction>(fromWBActions.TAKE_BULK_VARIANT_ACTION),
      withLatestFrom(
        this.appStore$.pipe(select(getAnalysisDetails)),
        this.store$.pipe(select(getCheckedResults)),
        this.store$.pipe(select(getVariantInterpretations)),
      ),
      filter(
        ([action, analysisDetails]) => !this.actionNeedsIrrelevantReasonDialog(action, analysisDetails.analysis_type),
      ),
      switchMap(([action, analysisDetails, checkedResults, interpretations]) => {
        const results: AnalysisResult[] = action.analysisResults?.length ? action.analysisResults : Array.from(checkedResults);
        const varIds: string[] = [];
        const compoundResults = [];
        const allVariantIds = [];
        const irrelevantReasons: string[] =
          action.irrelevantReasons?.[0] === NOOP_IRRELEVANT_REASON ? undefined : action.irrelevantReasons;

        results.forEach((result) => {
          if (result.type === VariantType.Compound) {
            const compoundResult: AnalysisCompoundVariant = result as AnalysisCompoundVariant;
            const compoundVarId = getVariantId(compoundResult.data.variants[0].data);
            const compoundPairVarId = getVariantId(compoundResult.data.variants[1].data);
            const compoundVarRelation = (compoundResult.data.variants[0].data as SnpResult | SvResult).relation;
            const compoundPairVarRelation = (compoundResult.data.variants[1].data as SnpResult | SvResult).relation;

            const varId: string = getVariantId(compoundResult.data);
            if (this.denyAction(action.actionType, interpretations[varId])) {
              return;
            }

            compoundResults.push({
              first_variant: { var_id: compoundVarId, relation: compoundVarRelation },
              second_variant: { var_id: compoundPairVarId, relation: compoundPairVarRelation },
            });
            allVariantIds.push(varId);
          } else {
            const varId: string = getVariantId(result.data);

            if (this.denyAction(action.actionType, interpretations[varId])) {
              return;
            }

            varIds.push(varId);
            allVariantIds.push(varId);
          }
        });

        const relevance =
          action.actionType === VariantActionValue.irrelevant
            ? {
                add: action.value,
                reasons: irrelevantReasons,
                reasoning_details: action.irrelevantReasonDetails,
              }
            : undefined;
        const workbench =
          action.actionType === VariantActionValue.in_workbench
            ? {
                add: action.value,
              }
            : undefined;
        const report =
          action.actionType === VariantActionValue.in_report
            ? {
                add: action.value,
                bin_name: action.clinicalSignificance,
              }
            : undefined;
        const significance =
          action.actionType === VariantActionValue.clinicalSignificance
            ? {
                add: action.value,
                bin_name: action.clinicalSignificance,
                reasons: irrelevantReasons,
                reasoning_details: action.irrelevantReasonDetails,
              }
            : undefined;

        return this.service
          .bulkUpdateVariantInterpretation(
            analysisDetails.id,
            varIds,
            compoundResults,
            relevance,
            workbench,
            report,
            significance,
          )
          .pipe(
            map(
              () =>
                new TakeBulkVariantActionSuccess(
                  action.analysisId,
                  action.analysisType,
                  action.actionType,
                  action.value,
                  results,
                  allVariantIds,
                  irrelevantReasons,
                  action.irrelevantReasonDetails,
                  action.clinicalSignificance,
                  action.clinicalSignificanceDropIndex,
                ),
            ),
            catchError((err) => of(new TakeBulkVariantActionFail(action.actionType, action.value, err))),
          );
      }),
    ),
  );

  $includeInReportGermline: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromWBActions.INCLUDE_IN_REPORT),
      withLatestFrom(this.appStore$.pipe(select(getAnalysisDetails))),
      filter(([, analysis]: [any, AnalysisDetails]) => analysis.analysis_type !== AnalysisType.tumor),
      mergeMap(([action, analysis]: [fromWBActions.IncludeInReport, AnalysisDetails]) =>
        this.service
          .updateVariantInterpretation(
            analysis.id,
            action.variantId,
            { in_report: action.value },
            action.variantType,
            action.compoundVariantId,
          )
          .pipe(
            map(
              (data: VariantInterpretation) =>
                new fromWBActions.IncludeInReportSuccess(
                  data.in_report,
                  action.variantId,
                  action.variantObject,
                  action.variantType,
                  action.compoundVariantId,
                ),
            ),
            catchError((err) =>
              of(new fromWBActions.IncludeInReportFail(action.variantId, err, action.compoundVariantId)),
            ),
          ),
      ),
    ),
  );

  $includeInReportSomatic: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromWBActions.INCLUDE_IN_REPORT),
      withLatestFrom(this.appStore$.pipe(select(getAnalysisDetails))),
      filter(([, analysis]: [any, AnalysisDetails]) => analysis.analysis_type === AnalysisType.tumor),
      mergeMap(([action, analysis]: [fromWBActions.IncludeInReport, AnalysisDetails]) =>
        this.workbenchService
          .includeInReportSomatic({
            analysis_id: analysis.id,
            assay_id: analysis.assay?.uuid,
            disease: analysis.disease,
            variants: [
              {
                var_id: action.variantId,
                variant_type: action.variantType,
                include_in_report: action.value,
              },
            ],
          })
          .pipe(
            map(
              () =>
                new fromWBActions.IncludeInReportSuccess(
                  action.value,
                  action.variantId,
                  action.variantObject,
                  action.variantType,
                  action.compoundVariantId,
                ),
            ),
            catchError((err) =>
              of(new fromWBActions.IncludeInReportFail(action.variantId, err, action.compoundVariantId)),
            ),
          ),
      ),
    ),
  );

  $acceptSuggestedClassificationAfterReport = createEffect(() =>
    this.actions$.pipe(
      ofType(fromWBActions.INCLUDE_IN_REPORT_SUCCESS),
      withLatestFrom(this.appStore$.pipe(select(getAnalysisDetails)), this.store$.pipe(select(getPatientInfoResponse))),
      filter(
        ([action, analysis]: [fromWBActions.IncludeInReportSuccess, AnalysisDetails, SearchConfig]) =>
          action.value && analysis.analysis_type === AnalysisType.tumor,
      ),
      switchMap(
        ([action, analysis, caseContext]: [fromWBActions.IncludeInReportSuccess, AnalysisDetails, SearchConfig]) =>
          this.interpretationService
            .approveSuggestedClassification(
              analysisResultToVariantPageVariantId(
                action.variantObject,
                analysis.main_sample.reference_genome_version,
                analysis.main_sample.annotation_version,
                analysis.id,
                analysis.analysis_type,
                action.variantType,
              ),
              caseContext,
            )
            .pipe(
              map(() => new ReloadAnalysisVariants()),
              catchError((error) => of(new fromWBActions.ApproveSuggestedClassificationFail(error))),
            ),
      ),
    ),
  );

  $markIrrelevant: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromWBActions.MARK_IRRELEVANT),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisId))),
      mergeMap(([action, analysisId]: [fromWBActions.MarkIrrelevant, number]) =>
        action.value && action.openReasonDialog
          ? [new fromWBActions.MarkIrrelevantDialog(action.variantId, action.variantType, action.pairVariantId)]
          : this.service
              .updateVariantInterpretation(
                analysisId,
                action.variantId,
                { irrelevant: action.value },
                action.variantType,
                action.pairVariantId,
              )
              .pipe(
                map(
                  (data: VariantInterpretation) =>
                    new fromWBActions.MarkIrrelevantSuccess(
                      action.variantId,
                      action.variantType,
                      data.irrelevant,
                      action.reasons,
                      undefined,
                      action.pairVariantId,
                    ),
                ),
                catchError((err) => of(new fromWBActions.MarkIrrelevantFail(action.variantId, err))),
              ),
      ),
    ),
  );

  markIrrelevantDialog$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromWBActions.MARK_IRRELEVANT_DIALOG),
      withLatestFrom(
        this.rootStore$.pipe(select(getAnalysisId)),
        this.store$.pipe(select(getClinicalSignificanceReasoningOptions)),
      ),
      switchMap(([action, analysisId, reasoningOptions]: [fromWBActions.MarkIrrelevantDialog, number, string[]]) => {
        const dialogRef = this.dialog.open<
          ClinicalSignificanceReasonDialogComponent,
          ClinicalSignificanceReasonDialogData,
          ClinicalSignificanceReasonDialogResult
        >(ClinicalSignificanceReasonDialogComponent, {
          height: '490px',
          width: '780px',
          panelClass: 'paddingless-dialog',
          data: {
            reasoningOptions,
          },
        });
        return dialogRef.afterClosed().pipe(
          switchMap((dialogData) =>
            dialogData
              ? this.service
                  .updateVariantInterpretation(
                    analysisId,
                    action.variantId,
                    { irrelevant: true },
                    action.variantType,
                    action.pairVariantId,
                  )
                  .pipe(
                    switchMap((data: VariantInterpretation) => [
                      new fromWBActions.MarkIrrelevantSuccess(
                        action.variantId,
                        action.variantType,
                        data.irrelevant,
                        dialogData.reasons,
                        dialogData.details,
                        action.pairVariantId,
                      ),
                    ]),
                    catchError((err) => of(new fromWBActions.MarkIrrelevantFail(action.variantId, err))),
                  )
              : [new fromWBActions.MarkIrrelevantFail(action.variantId, action.variantType)],
          ),
        );
      }),
    ),
  );

  $markIrrelevantSetClinicalSignificance$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromWBActions.MARK_IRRELEVANT_SUCCESS),
      withLatestFrom(this.appStore$.pipe(select(getAnalysisDetails))),
      filter(([, analysis]) => analysis.analysis_type !== AnalysisType.tumor),
      switchMap(([action, analysis]: [fromWBActions.MarkIrrelevantSuccess, AnalysisDetails]) => {
        const clinicalAction = action.value ? SetVariantClinicalSignificance : ClearVariantClinicalSignificance;
        return [
          new clinicalAction(
            analysis.analysis_type,
            action.pairVariantId ? VariantType.Compound : action.variantType,
            action.pairVariantId ? getCompoundVariantId(action.variantId, action.pairVariantId) : action.variantId,
            analysis.id,
            NOT_RELEVANT_SIGNIFICANCE_GROUP,
            action.reasons,
            action.details,
          ),
        ];
      }),
    ),
  );


  $showNewEvidencePopup: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromWBActions.SHOW_NEW_EVIDENCE_POPUP),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisId))),
      switchMap(([action, analysisId]: [fromWBActions.ShowNewEvidencePopup, number]) => {
        const dialogRef = this.dialog.open(AddEvidenceToVariantDialogComponent, {
          height: '520px',
          width: '780px',
          panelClass: 'paddingless-dialog',
          data: {
            varIdObj: action.varIdObj,
            interpretations: action.variantInterpretations,
          },
        });
        return dialogRef.afterClosed().pipe(switchMap((dialogData) => []));
      }),
    ),
  );

  $showAddCommentPopup: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromWBActions.SHOW_ADD_COMMENT_POPUP),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisId))),
      switchMap(([action, analysisId]: [fromWBActions.ShowAddCommentPopup, number]) => {
        const dialogRef = this.dialog.open(AddCommentToVariantDialogComponent, {
          height: '520px',
          width: '780px',
          panelClass: 'paddingless-dialog',
        });
        return dialogRef
          .afterClosed()
          .pipe(
            switchMap((dialogData: { comment: string; isPrivate: boolean }) =>
              dialogData
                ? this.variantCommentsService
                    .addCommentToVariant(analysisId, action.varId, dialogData.comment, dialogData.isPrivate)
                    .pipe(switchMap((data: VariantInterpretation) => [new fromWBActions.ReloadAnalysisVariants()]))
                : [],
            ),
          );
      }),
    ),
  );

  denyAction(actionType: VariantActionType, interpretation: VariantInterpretation): boolean {
    return (
      (actionType === VariantActionValue.irrelevant && interpretation[VariantInterpretationType.in_report]) ||
      (actionType === VariantActionValue.in_report && interpretation[VariantInterpretationType.irrelevant])
    );
  }

  actionNeedsIrrelevantReasonDialog(action: fromWBActions.TakeBulkVariantAction, analysisType: AnalysisType): boolean {
    const isSetIrrelevantAction: boolean = action.actionType === VariantActionValue.irrelevant && action.value;
    const isSetNotRelevantClinicalSignificanceAction: boolean =
      action.actionType === VariantActionValue.clinicalSignificance &&
      action.clinicalSignificance === NOT_RELEVANT_SIGNIFICANCE_GROUP;
    const missesIrrelevantReason: boolean = !action.irrelevantReasons?.length;
    const isTumorAnalysisType: boolean = analysisType === AnalysisType.tumor;

    return (
      (isSetIrrelevantAction || isSetNotRelevantClinicalSignificanceAction) &&
      missesIrrelevantReason &&
      !isTumorAnalysisType
    );
  }
}
