import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { IncludeInWorkbenchSuccess, UpdateSomaticVariantInterpretationSuccess } from '../../modules/workbench/store';
import { SomaticVariantInterpretation } from '../../modules/analysis-variant/models';
import { AnalysisDetails, AnalysisType } from '../../../../store/models';
import { AppModuleState } from '../../../../store/reducers';
import { getAnalysisDetails } from '../../../../store/selectors';

@Injectable()
export class VariantsEffects {
  constructor(
    private actions: Actions,
    private rootStore: Store<AppModuleState>,
  ) {}

  relaySomaticFlagUpdateFromPopupToVariants$ = createEffect(() =>
    this.actions.pipe(
      ofType('[Interpretation] Save variant interpretation Success'),
      withLatestFrom(this.rootStore.pipe(select(getAnalysisDetails))),
      filter(([, analysis]: [any, AnalysisDetails]) => analysis && analysis.analysis_type === AnalysisType.tumor),
      switchMap(([action]: [any, AnalysisDetails]) => {
        const varId = action.variantId.hashId ? action.variantId.hashId : '';
        const interpretation: SomaticVariantInterpretation = {
          include_in_report: action.data.in_report,
          mark_as_clinically_irrelevant: action.data.irrelevant,
          interpretation: action.data.text,
          history: [
            {
              classification: action.data.flag ? action.data.flag : 0,
            },
          ],
        };

        return [new UpdateSomaticVariantInterpretationSuccess(varId, interpretation)];
      }),
    ),
  );

  // @Effect()
  // relayGermlineFlagUpdateFromPopupToVariants$ = this.actions.pipe(
  //   ofType('[Interpretation] Save variant interpretation Success'),
  //   withLatestFrom(this.rootStore.pipe(select(getAnalysisDetails))),
  //   filter(
  //     ([, analysis]: [
  //       any,
  //       AnalysisDetails
  //     ]) => analysis && [AnalysisType.overgrowth, AnalysisType.sample, AnalysisType.family].includes(analysis.analysis_type)
  //   ),
  //   switchMap(
  //     ([action, analysis]: [
  //       any,
  //       AnalysisDetails
  //     ]) => {
  //       const varId = action.variantId.hashId ? action.variantId.hashId : '';
  //
  //       const interpretation: SnpInterpretation = {
  //         acmg_rules: action.data.acmg_rules ? action.data.acmg_rules : [],
  //         user_id: action.data.user ? parseInt(action.data.user, 10) : 0,
  //         text: action.data.text ? action.data.text : '',
  //         flag: action.data.flag ? action.data.flag : 0,
  //         analysis: action.data.analysis ? action.data.analysis : '',
  //         created_on: action.data.created_on ? action.data.created_on : '',
  //         updated_on: action.data.updated_on ? action.data.updated_on : '',
  //         interpretation_evidence: action.data.interpretation_evidence
  //       };
  //
  //       return [
  //         new UpdateGermlineSnpVariantInterpretationSuccess(
  //           analysis.id,
  //           varId,
  //           interpretation
  //         )
  //       ];
  //     }
  //   )
  // );

  // @Effect()
  // relayIncludeInReportFromPopupToVariants$ = this.actions.pipe(
  //   ofType('[Interpretation] Include in report success'),
  //   switchMap((action: any) => [
  //     new IncludeInReportSuccess(
  //       action.variantId.hashId ? action.variantId.hashId : '',
  //       action.value
  //     )
  //   ])
  // );

  relayIncludeInWorkbenchFromPopupToVariants$ = createEffect(() =>
    this.actions.pipe(
      ofType('[Interpretation] Include in workbench Success'),
      switchMap((action: any) => [
        new IncludeInWorkbenchSuccess(action.variantId.hashId ? action.variantId.hashId : '', action.value, ''),
      ]),
    ),
  );
}
