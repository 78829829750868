import { Action } from '@ngrx/store';
import { AnalyticsAction, AnalyticsEvent, extractEventFromType } from '../../../../store/actions/analytics.action';
import { VariantId, variantIdToString } from '../../../../store/models';

export const FOLLOW_VARIANT = '[Variants] Follow variant';
export const FOLLOW_VARIANT_SUCCESS = '[Variants] Follow variant success';
export const FOLLOW_VARIANT_FAIL = '[Variants] Follow variant fail';

export class FollowVariant implements Action {
  readonly type = FOLLOW_VARIANT;

  constructor(public varId: VariantId) {}
}

export class FollowVariantSuccess implements AnalyticsAction {
  readonly type = FOLLOW_VARIANT_SUCCESS;
  public analyticsEvent: AnalyticsEvent;

  constructor(
    public varId: VariantId,
    public result: boolean,
  ) {
    this.analyticsEvent = extractEventFromType(this.type);
    this.analyticsEvent.eventAction = `${result ? 'Started to' : 'Stopped to'} Follow a variant`;
    this.analyticsEvent.eventLabel = variantIdToString(varId);
  }
}

export class FollowVariantFail implements AnalyticsAction {
  readonly type = FOLLOW_VARIANT_FAIL;
  analyticsEvent: AnalyticsEvent;

  constructor(public error: any) {
    this.analyticsEvent = extractEventFromType(this.type);
    this.analyticsEvent.eventLabel = error;
  }
}

export const GET_VARIANT_FOLLOWED_STATE = '[Variants] Get variant followed state';
export const GET_VARIANT_FOLLOWED_STATE_SUCCESS = '[Variants] Get variant followed state success';
export const GET_VARIANT_FOLLOWED_STATE_FAIL = '[Variants] Get variant followed state fail';

export class GetVariantFollowedState implements Action {
  readonly type = GET_VARIANT_FOLLOWED_STATE;

  constructor(public varId: VariantId) {}
}

export class GetVariantFollowedStateSuccess implements Action {
  readonly type = GET_VARIANT_FOLLOWED_STATE_SUCCESS;

  constructor(
    public varId: string,
    public result: boolean,
  ) {}
}

export class GetVariantFollowedStateFail implements Action {
  readonly type = GET_VARIANT_FOLLOWED_STATE_FAIL;

  constructor(public error: any) {}
}

export const CLEAR_VARIANT_FOLLOWED_STATE = '[Variants] Clear variant followed state';

export class ClearVariantFollowedState implements Action {
  readonly type = CLEAR_VARIANT_FOLLOWED_STATE;

  constructor() {}
}

export type FollowVariantAction =
  | FollowVariant
  | FollowVariantSuccess
  | FollowVariantFail
  | GetVariantFollowedState
  | GetVariantFollowedStateSuccess
  | GetVariantFollowedStateFail
  | ClearVariantFollowedState;
