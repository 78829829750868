import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VariantCommentsService {
  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
  ) {}

  addCommentToVariant(analysisId: number, varId: string, comment: string, isPrivate = false): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders();
    headers.set('Content-Type', 'application/json');

    const params = {
      var_id: varId,
      analysis_id: analysisId,
      comment,
      is_private: isPrivate,
    };
    return this.http.post(`${environment.beBaseURL}/api/analysis/variant/comment`, { ...params }, { headers });
  }
}
