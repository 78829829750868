import * as fromAction from '../actions/interpretation-header.action';
import * as fromWbActions from '../../../../../analysis/modules/workbench/store/actions';

export interface InterpretationHeaderState {
  followVariantInProgress: boolean;
  followVariantError?: any;
}

const initialState: InterpretationHeaderState = {
  followVariantInProgress: false,
};

export function reducer(
  state = initialState,
  action: fromAction.InterpretationHeaderAction | fromWbActions.VariantInterpretationAction,
): InterpretationHeaderState {
  switch (action.type) {
    case fromAction.FOLLOW_VARIANT: {
      return {
        ...state,
        followVariantInProgress: true,
        followVariantError: '',
      };
    }
    case fromAction.FOLLOW_VARIANT_SUCCESS: {
      return {
        ...state,
        followVariantInProgress: false,
      };
    }
    case fromAction.FOLLOW_VARIANT_FAIL: {
      return {
        ...state,
        followVariantInProgress: false,
        followVariantError: action.error,
      };
    }
  }

  return state;
}

export const getFollowVariantInProgressEntity = (state: InterpretationHeaderState) => state.followVariantInProgress;
