import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: 'a[gnxAnchor]',
  standalone: true,
})
export class AnchorDirective {
  @HostBinding('class.gnx-anchor') enabled = true;
  @HostBinding('class.white') @Input() white: boolean;
  @HostBinding('class.disabled') @Input() disabled: boolean;

  constructor() {}

  @HostBinding('style.pointerEvents')
  get pointerEvents(): string {
    if (this.disabled) {
      return 'none';
    }
    return 'auto';
  }
}
