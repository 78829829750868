import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as fromAction from '../actions/raffle.action';
import { of } from 'rxjs';
import { VariantPageService } from '../services';

@Injectable()
export class RaffleVariantEffect {
  constructor(
    private actions$: Actions,
    private service: VariantPageService,
  ) {}

  getIsRaffleVariantState = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GET_IS_RAFFLE_VARIANT_STATE),
      switchMap((action: fromAction.GetIsRaffleVariantState) =>
        this.service.getIsRaffleVariantState(action.varId).pipe(
          map((result) => new fromAction.GetIsRaffleVariantStateSuccess(action.varId, result.success)),
          catchError((err) => of(new fromAction.GetIsRaffleVariantStateFail(err))),
        ),
      ),
    ),
  );
}
