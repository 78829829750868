import {
  PlatformGermlineClassificationBitmap,
  ProtoGermlineClassificationEnum,
  ProtoInterpretationAssessmentEnum,
} from '../../../shared/utils/classification.utils';

export enum ClassificationType {
  User,
  Genoox,
  Community,
  Somatic,
  VariantFromReport,
  External,
}

export enum ClassificationState {
  suggestedClassification = 'suggestedClassification',
  draftClassification = 'draftClassification',
  reviewClassification = 'reviewClassification',
}

export interface ClassificationMetadata {
  value?: number;
  key: string;
  label: string;
  name: string;
  shortName: string;
  colorClass: string;
  color?: string;
  shortLabel?: string;
  borderColor?: string;
  textColor?: string;
  alternateTextColor?: string;
  sourceTypes: ClassificationType[];
  parentClassificationKey?: string;
  class?: string;
  severity?: number;
  platformValue?: PlatformGermlineClassificationBitmap;
  protoValue?: ProtoGermlineClassificationEnum;
  protoInterpretationValue?: ProtoInterpretationAssessmentEnum;
  subTiers?: ClassificationMetadata[];
}

export type ClassificationMetadataMap = Partial<Record<string | number, ClassificationMetadata>>;

export enum PLATFORM_CLASSIFICATION_BITMAP {
  BENIGN = 1,
  SUSPECTED_BENIGN = 2,
  VUS = 4,
  VUS_PATHOGENIC_MODERATE = 8,
  VUS_PATHOGENIC_LOW = 16,
  VUS_BENIGN = 32,
  SUSPECTED_PATHOGENIC = 64,
  PATHOGENIC = 128,
}

export enum PLATFORM_USER_CLASSIFICATION_BITMAP {
  NONE = 0,
  PATHOGENIC = 1,
  SUSPECTED_PATHOGENIC = 2,
  BENIGN = 4,
  SUSPECTED_BENIGN = 8,
  FALSE = 16,
  VUS = 32,
}

export interface SubTiersConfig {
  [key: string]: {
    id: string;
    display: string;
    abbreviation?: string;
  }[];
}

export interface InterpretationCustomSubTiersConfig {
  germline?: SubTiersConfig;
  somatic?: SubTiersConfig;
  oncogenic?: SubTiersConfig;
}
