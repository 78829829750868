import { VariantDetailsEffect } from './variant-details.effect';
import { ErrorEffect } from './error.effect';
import { ContactEffect } from './contact.effect';
import { ConfigEffect } from './config.effect.';
import { AppBannerEffect } from './app-banner.effect';
import { RouteUpdateEffect } from './route-update.effect';

export const variantPageEffects = [
  VariantDetailsEffect,
  ErrorEffect,
  ContactEffect,
  ConfigEffect,
  AppBannerEffect,
  RouteUpdateEffect,
];
