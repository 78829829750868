import { Action } from '@ngrx/store';
import { VariantType } from '../../../analysis-variant/models';
import { AnalysisLean, AnalysisLeanListResponse } from '../../../analyses/store';

export const LOAD_SINGLE_ANALYSES = '[Shared] Load single analyses';
export const LOAD_SINGLE_ANALYSES_SUCCESS = '[Shared] Load single analyses success';
export const LOAD_SINGLE_ANALYSES_FAIL = '[Shared] Load single analyses fail';

export class LoadSingleAnalyses implements Action {
  readonly type = LOAD_SINGLE_ANALYSES;

  constructor(
    public search?: string,
    public variantType?: VariantType,
    public isReady?: boolean,
    public pageSize?: number,
  ) {}
}

export class LoadSingleAnalysesSuccess implements Action {
  readonly type = LOAD_SINGLE_ANALYSES_SUCCESS;

  constructor(public response: AnalysisLeanListResponse) {}
}

export class LoadSingleAnalysesFail implements Action {
  readonly type = LOAD_SINGLE_ANALYSES_FAIL;

  constructor(public error: any) {}
}

export const LOAD_MORE_SINGLE_ANALYSES = '[Shared] Load more single analyses';
export const LOAD_MORE_SINGLE_ANALYSES_SUCCESS = '[Shared] Load more single analyses success';
export const LOAD_MORE_SINGLE_ANALYSES_FAIL = '[Shared] Load more single analyses fail';

export class LoadMoreSingleAnalyses implements Action {
  readonly type = LOAD_MORE_SINGLE_ANALYSES;
}

export class LoadMoreSingleAnalysesSuccess implements Action {
  readonly type = LOAD_MORE_SINGLE_ANALYSES_SUCCESS;

  constructor(public response: AnalysisLeanListResponse) {}
}

export class LoadMoreSingleAnalysesFail implements Action {
  readonly type = LOAD_MORE_SINGLE_ANALYSES_FAIL;

  constructor(public error: any) {}
}

export const ADD_SELECTED_SINGLE_ANALYSIS = '[Shared] Add selected single analysis';
export const REMOVE_SELECTED_SINGLE_ANALYSIS = '[Shared] Remove selected single analysis';
export const CLEAR_SELECTED_SINGLE_ANALYSES = '[Shared] Clear selected single analyses';

export class AddSelectedSingleAnalysis implements Action {
  readonly type = ADD_SELECTED_SINGLE_ANALYSIS;

  constructor(public analysis: AnalysisLean) {}
}

export class RemoveSelectedSingleAnalysis implements Action {
  readonly type = REMOVE_SELECTED_SINGLE_ANALYSIS;

  constructor(public analysisId: number) {}
}

export class ClearSelectedSingleAnalyses implements Action {
  readonly type = CLEAR_SELECTED_SINGLE_ANALYSES;
}

export const LOAD_SELECTED_SINGLE_ANALYSIS = '[Shared] Load selected single analysis';
export const LOAD_SELECTED_SINGLE_ANALYSIS_FAIL = '[Shared] Load selected single analysis fail';

export class LoadSelectedSingleAnalysis implements Action {
  readonly type = LOAD_SELECTED_SINGLE_ANALYSIS;

  constructor(public analysisId: number) {}
}

export class LoadSelectedSingleAnalysisFail implements Action {
  readonly type = LOAD_SELECTED_SINGLE_ANALYSIS_FAIL;

  constructor(public error: any) {}
}

export type SingleAnalysesActions =
  | LoadSingleAnalyses
  | LoadSingleAnalysesFail
  | LoadSingleAnalysesSuccess
  | LoadMoreSingleAnalyses
  | LoadMoreSingleAnalysesSuccess
  | LoadMoreSingleAnalysesFail
  | AddSelectedSingleAnalysis
  | RemoveSelectedSingleAnalysis
  | ClearSelectedSingleAnalyses
  | LoadSelectedSingleAnalysis
  | LoadSelectedSingleAnalysisFail;
