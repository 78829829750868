export interface Phenotype {
  short_desc?: string;
  long_desc?: string;
  detailed_description?: string;
  description?: string;
  type?: string;
  name: string;
  value: string;
  search_score?: number;
  omim_id?: string[];
  mondo_id?: string;
  hpo_id?: string;
  numeric_mondo_id?: string;
  freq_enum?: string;
  category?: string;
  hpo_url?: string;
}

export interface GenePhenotypesResponse {
  associated_to_gene?: Phenotype[];
  all_conditions?: Phenotype[];
  error?: Phenotype;
}

export const ERROR_PHENOTYPE: Phenotype = {
  name: "We couldn't find any match\nCheck the spelling or try searching a synonym",
  value: 'error',
};
