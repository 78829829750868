import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[gnxFlex]',
  standalone: true,
})
export class FlexDirective {
  @HostBinding('style.gap') @Input() gap: string;
  @HostBinding('style.align-items') @Input() align: 'flex-start' | 'center' | 'flex-end' | 'stretch';
  @HostBinding('style.justify-content') @Input() justify:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-around'
    | 'space-between'
    | 'space-evenly';
  @Input() column: boolean;
  @Input() wrap: boolean;
  @Input() inline: boolean;

  @HostBinding('style.display')
  get displayStyle(): string {
    return this.inline ? 'inline-flex' : 'flex';
  }

  @HostBinding('style.flex-wrap')
  get flexWrap(): string {
    return this.wrap ? 'wrap' : undefined;
  }

  @HostBinding('style.flex-direction')
  get flexDirection(): string {
    return this.column ? 'column' : undefined;
  }
}
