import {
  AnalysisType,
  Assay,
  CaseCustomActionData,
  CaseCustomActionType,
  CaseCustomActionUserAccessData,
  DetailedStatus,
  ReportConfiguration,
} from '../../../../../../store/models';
import { RowsWidgetData } from '../../../../../../shared-lazy/components/rows-graph-widget/rows-graph-widget.component';
import { LinesGraphData } from '../../../../../../shared-lazy/components/lines-graph/lines-graph.component';
import { AnalysisWorkflowStatus } from './analysis-workflow-status.model';
import { ReportExportType } from '../../../reporting/store';
import { CaseProtocolStep } from '../../../workbench/store';

export interface Subject {
  internal_id: string;
}

export interface MainSample {
  status: number;
  sequence_type: string;
  id: number;
  subject: Subject;
  batch?: {
    created_at: string;
    name: string;
  };
  single_analysis_id: number;
}

export interface Panel {
  uuid: string;
  name: string;
}

export interface AnalysisConfigurableOptions {
  panels: Panel[];
  somatic?: any;
}

export interface EnrichmentKitBed {
  name?: string;
  targeted_coverage_bed?: string;
}

export interface CoverageConfig {
  second_user_covered_threshold?: any;
  first_user_covered_threshold?: any;
  low_covered_threshold: number;
  fifth_user_covered_threshold?: any;
  third_user_covered_threshold?: any;
  medium_covered_threshold: number;
  not_covered_at_all_threshold: number;
  fourth_user_covered_threshold?: any;
  fully_covered_threshold: number;
  enrichment_kit?: EnrichmentKitBed;
  calculate_targeted_genes_data?: boolean;
}

export const DEFAULT_COVERAGE_FULL_THRESHOLD = 20;

export const DEFAULT_COVERAGE_THRESHOLD_LIST = [0, 1, 5, 10, 20];

export interface QcConfig {
  coverage_config: CoverageConfig;
}

export interface Analysis {
  id: number;
  name: string;
  details: string;
  analysis_type: AnalysisType;
  num_samples: number;
  created_at: Date;
  last_visited?: Date;
  status: number;
  status_description: string;
  parent_status: AnalysisWorkflowStatus;
  phenotypes: string[];
  labels: { [key: string]: string[] };
  detailed_status: DetailedStatus;
  main_sample: MainSample;
  type_desc: string;
  availability_status: AnalysisAvailabilityStatus;
  disease?: any;
  assay: Assay;
  assigned_user: number;
  assigned_user_name: string;
  assigned_user_email: string;
  custom_data: { [key: string]: any };
  loading: { [prop: string]: boolean };
  primary_analyst_email: string;
  change_audits: {
    field: string;
    value: string;
    prev_value: string;
    changed_at: Date;
  }[];
  final_report_files?: {
    file_type: number;
    file_type_enum: ReportExportType;
    path: string;
  }[];
  snp_annotation_done: boolean;
  protocol_steps?: CaseProtocolStep[];
  access_list: string[];
  report_configuration: ReportConfiguration;
  last_report_uuid?: string;
}

export interface DataModel<T> {
  count: number;
  next: string;
  previous?: any;
  results: T[];
  show_creating: boolean;
}

export interface EnrichmentKit {
  id: number;
  name: string;
  description: string;
  s3_bed_file_path: string;
  provider: string;
  internal_note: string;
  organization: number;
}

export interface AnalysesStats {
  active: number;
  total: number;
  created_this_month: number;
  resolved_this_month: number;
  show_creating: boolean;
}

export interface ActiveStats {
  by_assigned: RowsWidgetData[];
  by_open_time: RowsWidgetData[];
  by_assay: RowsWidgetData[];
}

export interface BriefStats {
  unassigned: number;
  created_last_month: number;
  resolved_this_month: number;
  active: number;
  total: number;
  created_this_month: number;
  resolved_last_month: number;
}

export interface MonthlyStatsGraphData {
  resolved: LinesGraphData;
  created: LinesGraphData;
}

export interface AnalysesDetailedStats {
  active_stats: ActiveStats;
  brief_stats: BriefStats;
  monthly_stats_graph_data: MonthlyStatsGraphData;
}

export interface CustomLabel {
  values: string[];
  type: string;
}

export enum AnalysisAvailabilityStatus {
  Available = 0,
  InArchivingProcess = 1,
  Archived = 2,
  ArchivingFailed = 3,
  Deprecated = 4,
  InUnarchivingProcess = 5,
}

export interface AnalysisUpdateBulkPayload {
  action: CaseCustomActionType;
  analysis_ids: number[];
  value: CaseCustomActionData;
}
