import { createFeatureSelector, MemoizedSelector } from '@ngrx/store';

import * as fromFollowVariant from './follow-variant.reducer';
import * as fromRaffleVariant from './raffle.reducer';
import * as fromVariantPageConfig from './variant-page-config.reducer';

export interface VariantPageModuleState {
  followVariant: fromFollowVariant.FollowVariantState;
  raffle: fromRaffleVariant.RaffleVariantState;
  variantPageConfig: fromVariantPageConfig.VariantPageConfigState;
}

export const getAppModuleState: MemoizedSelector<VariantPageModuleState, VariantPageModuleState> =
  createFeatureSelector<VariantPageModuleState>('variantPageRoot');

export const variantPageReducers = {
  followVariant: fromFollowVariant.reducer,
  raffle: fromRaffleVariant.reducer,
  variantPageConfig: fromVariantPageConfig.reducer,
};
