import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromAction from '../actions';
import { Injectable } from '@angular/core';
import { WorkbenchService } from '../services/workbench.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

@Injectable()
export class ReferralNotesEffects {
  constructor(
    private actions$: Actions,
    private service: WorkbenchService,
  ) {}

  loadReferralNotes$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_REFERRAL_NOTES),
      switchMap((action: fromAction.LoadReferralNotes) =>
        this.service.getReferralNotes(action.analysisId).pipe(
          map((result) => new fromAction.LoadReferralNotesSuccess(action.analysisId, result)),
          catchError((err) => of(new fromAction.LoadReferralNotesFail(action.analysisId, err))),
        ),
      ),
    ),
  );
}
