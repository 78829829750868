import { InterpretationTag } from './snp-result.model';

export interface SomaticVariantInterpretation {
  include_in_report?: boolean;
  include_in_workbench?: boolean;
  mark_as_clinically_irrelevant?: boolean;
  cross_analysis?: boolean;
  interpretation?: string;
  history?: {
    classification: number;
    cross_analysis_value?: number;
    cross_analysis_sample_name?: string;
    date?: string;
    user?: string;
  }[];
}

export enum VariantInterpretationType {
  in_report = 'in_report',
  in_workbench = 'in_workbench',
  irrelevant = 'irrelevant',
  orthogonal = 'orthogonal',
  explainConfidence = 'explain_confidence',
}

export class VariantInterpretation {
  [VariantInterpretationType.in_report]?: boolean;
  [VariantInterpretationType.in_workbench]?: boolean;
  [VariantInterpretationType.irrelevant]?: boolean;
  [VariantInterpretationType.orthogonal]?: boolean;
  is_manual_variant?: boolean;
  loading?: {
    [VariantInterpretationType.in_report]?: boolean;
    [VariantInterpretationType.in_workbench]?: boolean;
    [VariantInterpretationType.irrelevant]?: boolean;
    [VariantInterpretationType.orthogonal]?: boolean;
  } = {};
  interpretation_tags?: InterpretationTag[];

  constructor(obj) {
    if (!obj) {
      return;
    }

    const { in_report, in_workbench, irrelevant, interpretation_tags } = obj;
    this.in_report = in_report;
    this.in_workbench = in_workbench;
    this.irrelevant = irrelevant;
    this.interpretation_tags = interpretation_tags;
  }
}

export interface SomaticVariantInterpretationApiResponse {
  variant_id: string;
  analysis_id: number;
  interpretation: SomaticVariantInterpretation;
}

export interface VariantInterpretations {
  [varId: string]: VariantInterpretation;
}
