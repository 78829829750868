import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[gnxGridItem]',
  standalone: true,
})
export class GridItemDirective {
  @HostBinding('style.grid-row') @Input() gridRow: string;
  @HostBinding('style.grid-column') @Input() gridColumn: string;
  @HostBinding('style.justify-self') @Input() justifySelf: 'start' | 'center' | 'end';
  @HostBinding('style.align-self') @Input() alignSelf: string;
}
