import * as fromAction from './../actions/download-analysis-file.action';
import { AnalysisFileForDownload } from '../models';

export interface DownloadAnalysisFilesState {
  analysisFiles: {
    [fileType: string]: AnalysisFileForDownload[];
  };
  loadingUrls: {
    [fileType: string]: boolean;
  };
  loadedUrls: {
    [fileType: string]: boolean;
  };
  downloadingAnalysisFiles: {
    [fileType: string]: boolean;
  };
  downloadingFamilyCarrier: boolean;
  error: any;
  exportControlSampleReportLoading: boolean;
  exportControlSampleReportReady?: boolean;
  exportControlSampleReportPath?: string;
  exportControlSampleReportError?: any;
  analysisSampleFileDownloading: {
    [key: string]: boolean;
  };
}

const initialState: DownloadAnalysisFilesState = {
  analysisFiles: {},
  loadingUrls: {},
  loadedUrls: {},
  downloadingAnalysisFiles: {},
  downloadingFamilyCarrier: false,
  exportControlSampleReportLoading: false,
  exportControlSampleReportError: undefined,
  error: undefined,
  analysisSampleFileDownloading: {}
};

export function reducer(
  state = initialState,
  action: fromAction.DownloadAnalysisFileAction,
): DownloadAnalysisFilesState {
  switch (action.type) {
    case fromAction.GET_DOWNLOAD_ANALYSIS_FILE_URL: {
      return {
        ...state,
        analysisFiles: {
          ...state.analysisFiles,
          [action.fileType]: [],
        },
        loadingUrls: {
          ...state.loadingUrls,
          [action.fileType]: true,
        },
        loadedUrls: {
          ...state.loadedUrls,
          [action.fileType]: false,
        },
        error: undefined,
      };
    }
    case fromAction.GET_DOWNLOAD_ANALYSIS_FILE_URL_SUCCESS: {
      return {
        ...state,
        analysisFiles: {
          ...state.analysisFiles,
          [action.fileType]: action.result,
        },
        loadingUrls: {
          ...state.loadingUrls,
          [action.fileType]: false,
        },
        loadedUrls: {
          ...state.loadedUrls,
          [action.fileType]: true,
        },
      };
    }
    case fromAction.GET_DOWNLOAD_ANALYSIS_FILE_URL_FAIL: {
      return {
        ...state,
        loadingUrls: {
          ...state.loadingUrls,
          [action.fileType]: false,
        },
        loadedUrls: {
          ...state.loadedUrls,
          [action.fileType]: false,
        },
        error: action.error,
      };
    }
    case fromAction.DOWNLOAD_ANALYSIS_FILE: {
      return {
        ...state,
        downloadingAnalysisFiles: {
          ...state.downloadingAnalysisFiles,
          [action.fileType]: true,
        },
      };
    }
    case fromAction.DOWNLOAD_ANALYSIS_FILE_SUCCESS: {
      return {
        ...state,
        downloadingAnalysisFiles: {
          ...state.downloadingAnalysisFiles,
          [action.fileType]: false,
        },
      };
    }
    case fromAction.DOWNLOAD_FAMILY_CARRIERS: {
      return {
        ...state,
        downloadingFamilyCarrier: true,
      };
    }
    case fromAction.DOWNLOAD_FAMILY_CARRIERS_SUCCESS:
    case fromAction.DOWNLOAD_FAMILY_CARRIERS_FAIL: {
      return {
        ...state,
        downloadingFamilyCarrier: false,
      };
    }
    case fromAction.CHECK_CONTROL_SAMPLE_REPORT_EXPORT:
      return {
        ...state,
        exportControlSampleReportLoading: true,
        exportControlSampleReportReady: false,
        exportControlSampleReportPath: undefined,
        exportControlSampleReportError: undefined,
      };
    case fromAction.CHECK_CONTROL_SAMPLE_REPORT_EXPORT_SUCCESS:
      return {
        ...state,
        exportControlSampleReportLoading: false,
        exportControlSampleReportReady: action.result.qc_ready,
        exportControlSampleReportPath: action.result.file_path,
      };
    case fromAction.CHECK_CONTROL_SAMPLE_REPORT_EXPORT_FAIL:
      return {
        ...state,
        exportControlSampleReportLoading: false,
        exportControlSampleReportError: action.error,
      };
    case fromAction.DOWNLOAD_ANALYSIS_SAMPLE_FILE:
      return {
        ...state,
        analysisSampleFileDownloading: {
          ...state.downloadingAnalysisFiles,
          [`${action.sampleId}_${action.fileId}`]: true
        }
      };
    case fromAction.DOWNLOAD_ANALYSIS_SAMPLE_FILE_FAIL:
    case fromAction.DOWNLOAD_ANALYSIS_SAMPLE_FILE_SUCCESS:
      return {
        ...state,
        analysisSampleFileDownloading: {
          ...state.downloadingAnalysisFiles,
          [`${action.sampleId}_${action.fileId}`]: false
        }
      };
  }

  return { ...state };
}
