import { Action } from '@ngrx/store';
import { BriefAssayListEntity, ExtendedAssayListEntity } from '../models/assay.model';

export const GET_ASSAY_BRIEF_LIST = '[Root] Get Assay Brief List';
export const GET_ASSAY_BRIEF_LIST_SUCCESS = '[Root] Get Assay Brief List Success';
export const GET_ASSAY_BRIEF_LIST_FAIL = '[Root] Get Assay Brief List Fail';

export class GetAssayBriefList implements Action {
  readonly type = GET_ASSAY_BRIEF_LIST;
}

export class GetAssayBriefListSuccess implements Action {
  readonly type = GET_ASSAY_BRIEF_LIST_SUCCESS;

  constructor(public list: BriefAssayListEntity[]) {}
}

export class GetAssayBriefListFail implements Action {
  readonly type = GET_ASSAY_BRIEF_LIST_FAIL;

  constructor(public err: any) {}
}

export const GET_ASSAYS_EXTENDED_LIST = '[Root] Get assays extended list';
export const GET_ASSAYS_EXTENDED_LIST_FAIL = '[Root] Get assays extended list fail';
export const GET_ASSAYS_EXTENDED_LIST_SUCCESS = '[Root] Get assays extended list success';
export const CLEAR_ASSAYS_EXTENDED_LIST = '[Root] Clear assays extended list';

export class GetAssaysExtendedList implements Action {
  readonly type = GET_ASSAYS_EXTENDED_LIST;

  constructor(
    public ordering: string,
    public search: string,
  ) {}
}

export class GetAssaysExtendedListFail implements Action {
  readonly type = GET_ASSAYS_EXTENDED_LIST_FAIL;

  constructor(public err: string) {}
}

export class GetAssaysExtendedListSuccess implements Action {
  readonly type = GET_ASSAYS_EXTENDED_LIST_SUCCESS;

  constructor(public assays: ExtendedAssayListEntity[]) {}
}

export class ClearAssaysExtendedList implements Action {
  readonly type = CLEAR_ASSAYS_EXTENDED_LIST;
}

export const DELETE_ASSAY = '[Root] Delete assay';
export const DELETE_ASSAY_FAIL = '[Root] Delete assay fail';
export const DELETE_ASSAY_SUCCESS = '[Root] Delete assay success';

export class DeleteAssay implements Action {
  readonly type = DELETE_ASSAY;

  constructor(public assayId: string) {}
}

export class DeleteAssayFail implements Action {
  readonly type = DELETE_ASSAY_FAIL;

  constructor(public err: string) {}
}

export class DeleteAssaySuccess implements Action {
  readonly type = DELETE_ASSAY_SUCCESS;

  constructor(public assayId: string) {}
}

export const GET_ASSAYS_IN_USE = '[Root] Get assays in use';
export const GET_ASSAYS_IN_USE_SUCCESS = '[Root] Get assays in use success';
export const GET_ASSAYS_IN_USE_FAIL = '[Root] Get assays in use fail';

export class GetAssaysInUse implements Action {
  readonly type = GET_ASSAYS_IN_USE;
}

export class GetAssaysInUseSuccess implements Action {
  readonly type = GET_ASSAYS_IN_USE_SUCCESS;

  constructor(public payload: string[]) {}
}

export class GetAssaysInUseFail implements Action {
  readonly type = GET_ASSAYS_IN_USE_FAIL;

  constructor(public error: any) {}
}

export type AssaysAction =
  | GetAssayBriefList
  | GetAssayBriefListSuccess
  | GetAssayBriefListFail
  | GetAssaysExtendedList
  | GetAssaysExtendedListFail
  | GetAssaysExtendedListSuccess
  | DeleteAssay
  | DeleteAssaySuccess
  | DeleteAssayFail
  | ClearAssaysExtendedList
  | GetAssaysInUse
  | GetAssaysInUseSuccess
  | GetAssaysInUseFail;
