<div class="close-btn" (click)="onClose()">
  <img src="/assets/svg/esc-blk.svg" />
</div>
<div class="top-section">
  <img src="/assets/png/ufo-copy.png" srcset="assets/png/ufo-copy@2x.png 2x, assets/png/ufo-copy@3x.png 3x" />
</div>
<div class="bottom-section">
  <div class="title">Something went wrong</div>
  <div class="subtitle">Please try again later</div>
  <button class="ok-btn" mat-dialog-close>OK</button>
</div>
