import { SigTabCounts } from '../../models';
import { createReducer, on } from '@ngrx/store';

import * as fromAction from './../actions/sig-variants.action';
import { AnalysisResult } from '../../../analysis/modules/analysis-variant/models';
import { SigClassifiedVariant } from '../../models/sig-variant.model';

export interface SigVariantsState {
  suggestedVariants: AnalysisResult[];
  loadingSuggested: boolean;
  loadedSuggested: boolean;
  suggestedVariantsCount: SigTabCounts;
  loadingSuggestedCount: boolean;
  loadedSuggestedCount: boolean;
  classifiedVariants: SigClassifiedVariant[];
  loadingClassified: boolean;
  loadedClassified: boolean;
  classifiedVariantsCount: SigTabCounts;
  categoriesFilters: { [category: string]: any };
  currentPage: {
    classified: number;
    suggested: number;
  };
}

const initialState: SigVariantsState = {
  suggestedVariants: [],
  loadedSuggested: false,
  loadingSuggested: false,
  suggestedVariantsCount: null,
  loadingSuggestedCount: false,
  loadedSuggestedCount: false,
  classifiedVariants: [],
  loadedClassified: false,
  loadingClassified: false,
  classifiedVariantsCount: null,
  categoriesFilters: {},
  currentPage: {
    classified: 1,
    suggested: 1,
  },
};

export const reducer = createReducer(
  initialState,
  on(fromAction.loadSuggestedVariantsCount, (state) => ({
    ...state,
    suggestedVariantsCount: {},
    loadedSuggestedCount: false,
    loadingSuggestedCount: true,
  })),
  on(fromAction.loadSuggestedVariantsCountSuccess, (state, { payload }) => ({
    ...state,
    suggestedVariantsCount: payload,
    loadingSuggestedCount: false,
    loadedSuggestedCount: true,
  })),
  on(fromAction.loadSuggestedVariantsCountFail, (state) => ({
    ...state,
    loadingSuggestedCount: false,
    loadedSuggestedCount: false,
  })),
  on(fromAction.loadSuggestedVariants, (state) => ({
    ...state,
    suggestedVariants: [],
    loadingSuggested: true,
    loadedSuggested: false,
    currentPage: { ...state.currentPage, suggested: 1 },
  })),
  on(fromAction.loadSuggestedVariantsSuccess, (state, { payload }) => ({
    ...state,
    suggestedVariants: [...state.suggestedVariants, ...payload],
    loadingSuggested: false,
    loadedSuggested: true,
  })),
  on(fromAction.loadSuggestedVariantsFail, (state, { error }) => ({
    ...state,
    loadingSuggested: false,
    loadedSuggested: false,
    currentPage: {
      ...state.currentPage,
      suggested: state.currentPage.suggested === 1 ? state.currentPage.suggested : state.currentPage.suggested - 1,
    },
  })),
  on(fromAction.loadMoreSuggestedVariants, (state) => ({
    ...state,
    loadingSuggested: true,
    currentPage: { ...state.currentPage, suggested: state.currentPage.suggested + 1 },
  })),
  on(fromAction.loadClassifiedVariants, (state) => ({
    ...state,
    classifiedVariants: [],
    loadingClassified: true,
    loadedClassified: false,
    currentPage: { ...state.currentPage, classified: 1 },
  })),
  on(fromAction.loadClassifiedVariantsSuccess, (state, payload) => ({
    ...state,
    classifiedVariants: [...state.classifiedVariants, ...payload.variants],
    classifiedVariantsCount: { num_variants: payload.total_count, num_genes: payload.genes_count },
    loadingClassified: false,
    loadedClassified: true,
  })),
  on(fromAction.loadClassifiedVariantsFail, (state, { error }) => ({
    ...state,
    loadingClassified: false,
    loadedClassified: false,
    currentPage: {
      ...state.currentPage,
      classified: state.currentPage.classified === 1 ? state.currentPage.classified : state.currentPage.classified - 1,
    },
  })),
  on(fromAction.loadMoreClassifiedVariants, (state) => ({
    ...state,
    loadingClassified: true,
    currentPage: {
      ...state.currentPage,
      classified: state.currentPage.classified + 1,
    },
  })),
  on(fromAction.SaveVariantsCategoryFilters, (state, payload) => ({
    ...state,
    categoriesFilters: {
      ...state.categoriesFilters,
      [payload.category]: payload.queryParams,
    },
  })),
);

// export function reducer(state: SigVariantsState | undefined, action: Action) {
//   return variantsReducer(state, action);
// }
