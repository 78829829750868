export const pngIcons = [
  '/assets/png/arrow-curved-long.png',
  '/assets/png/b-l.png',
  '/assets/png/b-l@2x.png',
  '/assets/png/b-l@3x.png',
  '/assets/png/classify-multiple-variants.png',
  '/assets/png/coming-soon.png',
  '/assets/png/coming-soon@2x.png',
  '/assets/png/coming-soon@3x.png',
  '/assets/png/drag-area.png',
  '/assets/png/email-bg-image.png',
  '/assets/png/email-logo.png',
  '/assets/png/fran-kli-n-combined-bright-bg.png',
  '/assets/png/fran-kli-n-combined-bright-bg@2x.png',
  '/assets/png/fran-kli-n-combined-bright-bg@3x.png',
  '/assets/png/franklin-dark-bg.png',
  '/assets/png/franklin-icon.png',
  '/assets/png/gdpr.png',
  '/assets/png/gdpr@2x.png',
  '/assets/png/gdpr@3x.png',
  '/assets/png/group-2.png',
  '/assets/png/group-2@2x.png',
  '/assets/png/group-2@3x.png',
  '/assets/png/group-3.png',
  '/assets/png/hipaa.png',
  '/assets/png/hipaa@2x.png',
  '/assets/png/hipaa@3x.png',
  '/assets/png/hipaa_large.png',
  '/assets/png/hipaa_large@2x.png',
  '/assets/png/hipaa_large@3x.png',
  '/assets/png/icon-img-1.png',
  '/assets/png/icon-img-2.png',
  '/assets/png/icon-img-3.png',
  '/assets/png/icon-img-4.png',
  '/assets/png/icon-img-5.png',
  '/assets/png/img-basespace.jpg',
  '/assets/png/img-right-bottom.png',
  '/assets/png/info.png',
  '/assets/png/ion-torrent.jpeg',
  '/assets/png/ion-torrent.png',
  '/assets/png/iso.png',
  '/assets/png/iso@2x.png',
  '/assets/png/iso@3x.png',
  '/assets/png/logo-2.png',
  '/assets/png/logo-nav.png',
  '/assets/png/logo.png',
  '/assets/png/panda-1.png',
  '/assets/png/panda-1@2x.png',
  '/assets/png/panda-1@3x.png',
  '/assets/png/panda-2.png',
  '/assets/png/panda-2@2x.png',
  '/assets/png/panda-2@3x.png',
  '/assets/png/panda-6.png',
  '/assets/png/panda-6@2x.png',
  '/assets/png/panda-6@3x.png',
  '/assets/png/panda-bottom-1.png',
  '/assets/png/panda-bottom-1@2x.png',
  '/assets/png/panda-bottom-1@3x.png',
  '/assets/png/panda-bottom-2.png',
  '/assets/png/panda-bottom-2@2x.png',
  '/assets/png/panda-bottom-2@3x.png',
  '/assets/png/panda-bottom.png',
  '/assets/png/panda-bottom@2x.png',
  '/assets/png/panda-bottom@3x.png',
  '/assets/png/panda-contact.png',
  '/assets/png/panda-contact@2x.png',
  '/assets/png/panda-contact@3x.png',
  '/assets/png/panda-icon-head-big.png',
  '/assets/png/panda-icon-head-big@2x.png',
  '/assets/png/panda-icon-head-big@3x.png',
  '/assets/png/panda-icon-head.png',
  '/assets/png/panda-icon-head@2x.png',
  '/assets/png/panda-icon-head@3x.png',
  '/assets/png/panda-icon.png',
  '/assets/png/panda-icon@2x.png',
  '/assets/png/panda-icon@3x.png',
  '/assets/png/panda-matchmaker-popup.png',
  '/assets/png/panda.png',
  '/assets/png/panda@2x.png',
  '/assets/png/panda@3x.png',
  '/assets/png/panda_icon-S.png',
  '/assets/png/raffle-strip-bg.png',
  '/assets/png/raffle_prize.png',
  '/assets/png/register-popup-bg.png',
  '/assets/png/round-copy@2x.png',
  '/assets/png/round-copy@3x.png',
  '/assets/png/round@2x.png',
  '/assets/png/round@3x.png',
  '/assets/png/search_icon.png',
  '/assets/png/seq-browser-btn.png',
  '/assets/png/seq-browser-btn@2x.png',
  '/assets/png/seq-browser-btn@3x.png',
  '/assets/png/shadow.png',
  '/assets/png/signature-1.png',
  '/assets/png/signature-2.png',
  '/assets/png/space-popup-image-d.png',
  '/assets/png/static-pages/about/chr.png',
  '/assets/png/static-pages/about/chr@2x.png',
  '/assets/png/static-pages/about/chr@3x.png',
  '/assets/png/static-pages/about/chr_edited@2x.png',
  '/assets/png/static-pages/about/franklin-community-1920.png',
  '/assets/png/static-pages/about/franklin-community.png',
  '/assets/png/static-pages/about/genoox@x2.png',
  '/assets/png/static-pages/about/group-12.png',
  '/assets/png/static-pages/about/group-12@2x.png',
  '/assets/png/static-pages/about/group-12@3x.png',
  '/assets/png/static-pages/about/group-4-modified@2x.png',
  '/assets/png/static-pages/about/group-4.png',
  '/assets/png/static-pages/about/group-4@2x.png',
  '/assets/png/static-pages/about/group-4@3x.png',
  '/assets/png/static-pages/about/group-6.png',
  '/assets/png/static-pages/about/group-6@2x.png',
  '/assets/png/static-pages/about/group-6@3x.png',
  '/assets/png/static-pages/about/group.png',
  '/assets/png/static-pages/about/group@3x.png',
  '/assets/png/static-pages/about/oval-copy-29-oval-copy-30-oval-copy-31-oval-mask@2x.png',
  '/assets/png/static-pages/about/oval-copy-29-oval-copy-30-oval-copy-31-oval-mask@3x.png',
  '/assets/png/static-pages/about/rosalind-franklin@2x.png',
  '/assets/png/t-r.png',
  '/assets/png/t-r@2x.png',
  '/assets/png/t-r@3x.png',
  '/assets/png/ufo-copy.png',
  '/assets/png/ufo-copy@2x.png',
  '/assets/png/ufo-copy@3x.png',
  '/assets/png/ufo.png',
  '/assets/png/upload-banner.png',
  '/assets/png/upload-banner@2x.png',
  '/assets/png/upload-banner@3x.png',
  '/assets/png/v.png',
  '/assets/png/viewer.png',
];
