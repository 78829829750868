export const IGNORED_FILTERS: string[] = [
  'curr_page',
  'page_size',
  'request_id',
  'vcount',
  'penetrance',
  'variantCallerOperation',
  'diffType',
  'diffZygosity',
  'panel',
  'phenotypes',
  'transcriptType',
  'predOp',
  'effectOp',
];

export const HIGHLIGHT_IGNORED_FILTERS: string[] = [
  'curr_page',
  'page_size',
  'request_id',
  'vcount',
  'diffType',
  'diffZygosity',
  'phenotypes',
];
