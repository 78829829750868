import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as fromAction from '../actions';
import { AppService } from '../services';

@Injectable()
export class VariantsCountEffect {
  constructor(
    private actions$: Actions,
    private service: AppService,
  ) {}

  getUserVariantsCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GET_USER_VARIANTS_COUNT),
      switchMap((action) =>
        this.service.getUserVariantsCount().pipe(
          map(({ count }) => new fromAction.GetUserVariantsCountSuccess(count)),
          catchError((err) => of(new fromAction.GetUserVariantsCountFail(err))),
        ),
      ),
    ),
  );
}
