import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'gnx-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class BlockComponent {
  @Input() width: string;
  @Input() height: string;
  @Input() margin: string;
  @Input() padding: string;
  @Input() background: string;
  @Input() position: string;
  @Input() border: string;
  @Input() borderRadius: string;
  @Input() inline: boolean;
  @Input() overflow: string;
  @Input() textAlign: 'left' | 'center' | 'right' | 'justify';

  @HostBinding('style')
  get styles(): Partial<CSSStyleDeclaration> {
    let borderStyle: string;
    let borderColor: string;
    let borderWidth: string;

    if (this.border) {
      const borderParts = this.border.split(' ');
      borderStyle = borderParts[borderParts.length - 2];
      borderColor = borderParts[borderParts.length - 1];
      borderWidth = borderParts.slice(0, -2).join(' ');
    }

    return {
      display: this.inline ? 'inline-block' : 'block',
      width: this.width,
      height: this.height,
      margin: this.margin,
      padding: this.padding,
      background: this.background,
      position: this.position,
      borderStyle,
      borderColor,
      borderWidth,
      borderRadius: this.borderRadius,
      textAlign: this.textAlign,
      overflow: this.overflow,
    };
  }
}
