import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { ClickCursorDirective } from '../../shared/directives/click-cursor.directive';

@Component({
  selector: 'gnx-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss'],
  standalone: true,
  imports: [ClickCursorDirective, MatDialogClose],
})
export class ErrorPopupComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ErrorPopupComponent>) {}

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
  }
}
