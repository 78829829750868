import { VariantType } from '../../analysis-variant/models';

export class ExportAsExcelModel {
  exportSucceeded: boolean;
}

export const MAX_VARIANTS_EXPORT_EXCEL = 8000;
export const MAX_VARIANTS_EXPORT_DISCOVERY = 1000000;
export const MAX_VARIANTS_EXPORT_EXCEL_EMAIL = 1000;
export const MAX_VARIANTS_EXPORT_EXCEL_EMAIL_TUMOR = 200;

export const EXPORT_VARIANT_TYPE = {
  [VariantType.SNP]: 'SHORT',
  [VariantType.SV]: 'STRUCTURAL',
  [VariantType.Fusion]: 'FUSION',
  [VariantType.RNA]: 'RNA',
};
