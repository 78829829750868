<div class="header">
  Search Multiple ROH
  <img src="assets/svg/x-white.svg" (click)="onClose()" />
</div>
<div class="content">
  <div #inputs class="inputs">
    <div *ngFor="let control of formArray.controls">
      <input
        placeholder="Enter ROH location"
        [formControl]="control"
        [ngClass]="{ error: control.invalid && control.touched }"
      />
      <div *ngIf="control.invalid && control.touched" class="error">
        {{
          control.errors.format
            ? 'This format is not supported, check examples'
            : 'End position must be bigger than start position'
        }}
      </div>
    </div>
  </div>
  <div class="add-btn" (click)="addInput()">
    <img src="assets/svg/add.svg" width="32" height="32" />
    Add location
  </div>
  <div class="divider">
    <span>OR</span>
  </div>
  <textarea
    [formControl]="formControl"
    [ngClass]="{ error: formControl.invalid && formControl.touched }"
    [placeholder]="
      'Paste multiple ROH locations seperated by a space.\nFor example: chr17:15,134,214-15,162,530 chr17:15,134,214-15,162,530'
    "
  ></textarea>
  <div *ngIf="formControl.invalid && formControl.touched" class="error">
    {{
      formControl.errors.format
        ? 'Format not supported. ROH should be separated by a space, check format example'
        : 'End position must be bigger than start position'
    }}
  </div>
  <div
    class="search-btn"
    (click)="onSearch()"
    [ngClass]="{ disabled: formArray.invalid || formControl.invalid || !formControl.value }"
  >
    SEARCH
  </div>
</div>
