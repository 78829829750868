import { SearchEffect } from './search.effect';
import { AnalyticsEffect } from './analytics.effect';
import { RouteEffect } from './route.effect';
import { AnalysisDetailsEffects } from './analysis-details-effects';
import { VariantsCountEffect } from './variants-count.effect';
import { SnackbarEffect } from './snackbar.effect';
import { PhenotypesEffect } from './phenotypes.effect';
import { EthnicitiesEffect } from './ethnicities.effect';
import { AssaysEffect } from './assays.effect';
import { EnrichmentKitsEffect } from './enrichment-kits.effect';
import { LazyLoaderEffect } from './lazy-loader.effect';
import { VariantsAutocompleteEffects } from './variants-autocomplete.effects';
import { PanelsEffect } from './panels.effect';
import { CancerTypesEffects } from './cancer-types.effects';
import { GenePagePopupEffects } from './gene-page-popup.effects';
import { GenePanelsEffect } from './gene-panels.effect';
import { GenesEffects } from './genes.effects';
import { PopupEffects } from './popup.effects';
import { VariantEvidencesSelectionEffect } from './variant-evidences-selection.effects';
import { WholeGenomePopupEffect } from './whole-genome-popup.effect';
import { CaseCustomFieldsEffect } from './case-custom-fields.effect';
import { OrganizationCustomSchemaEffect } from './organization-custom-schema.effect';
import { PredictionOptionsEffect } from './prediction-options.effect';
import { OrganizationConfigEffect } from './organization-config.effect';
import { SaveFileWindowEffect } from './save-file-window.effects';
import { RichTemplateEffects } from './rich-template.effects';

export const appEffects = [
  SearchEffect,
  AnalyticsEffect,
  RouteEffect,
  AnalysisDetailsEffects,
  VariantsCountEffect,
  SnackbarEffect,
  PhenotypesEffect,
  PanelsEffect,
  CancerTypesEffects,
  EthnicitiesEffect,
  AssaysEffect,
  EnrichmentKitsEffect,
  LazyLoaderEffect,
  VariantsAutocompleteEffects,
  GenePagePopupEffects,
  GenePanelsEffect,
  GenesEffects,
  VariantEvidencesSelectionEffect,
  PopupEffects,
  CaseCustomFieldsEffect,
  WholeGenomePopupEffect,
  OrganizationCustomSchemaEffect,
  PredictionOptionsEffect,
  OrganizationConfigEffect,
  SaveFileWindowEffect,
  RichTemplateEffects,
];
