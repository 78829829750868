import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { FiltersService } from '../services/filters.service';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';

import * as fromReducers from '../../../variants/store/reducers';
import * as fromActions from '../actions/filters-structure.action';
import { UpdateQueryParams } from '../../../../../../store/actions';
import { PanelsService } from '../../../../../../store/services';
import { AppModuleState, getDiscoveryAnalysisType } from '../../../../../../store';
import { DiscoveryAnalysisType } from '../../../../../discovery/store/models/discovery.model';

@Injectable()
export class FiltersStructureEffects {
  private activeRoute: string;

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducers.VariantsState>,
    private filterService: FiltersService,
    private panelsService: PanelsService,
    private rootStore$: Store<AppModuleState>,
  ) {}

  loadFiltersStructure$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LOAD_FILTERS_STRUCTURE),
      withLatestFrom(this.rootStore$.pipe(select(getDiscoveryAnalysisType))),
      switchMap(([action, discoveryAnalysisType]: [fromActions.LoadFiltersStructure, DiscoveryAnalysisType]) => {
        return this.filterService
          .getFiltersStructure(action.analysisId, action.analysisType, action.variantType, discoveryAnalysisType)
          .pipe(
            map((data: any) => new fromActions.LoadFiltersStructureSuccess(data)),
            catchError((error) => of(new fromActions.LoadFiltersStructureFail(error))),
          );
      }),
      catchError((error) => of(new fromActions.LoadFiltersStructureFail(error))),
    ),
  );

  updateFilterOptions$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.UPDATE_FILTER_OPTIONS),
      filter((action: fromActions.UpdateFilterOptions) => action.updateQueryParams),
      map(
        (action: fromActions.UpdateFilterOptions) =>
          new UpdateQueryParams(
            Object.keys(action.filters).reduce(
              (acc, crt) => ({
                ...acc,
                [crt]: action.filters[crt]
                  .filter((value) => (value.selected || value.selected_exclude) && !value.ignore_in_query_params)
                  .map((value) => (value.selected_exclude ? `-${value.value}` : value.value)),
              }),
              {},
            ),
          ),
      ),
      catchError((error) => of(new fromActions.UpdateFilterOptionsFail(error))),
    ),
  );
}
