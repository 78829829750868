export interface Ethnicity {
  id: number | string;
  name: string;
}

export interface EthnicityEntities {
  [key: number]: Ethnicity;
}

export const ERROR_ETHNICITY: Ethnicity = {
  name: "We couldn't find any match\nCheck the spelling or try a wider range",
  id: 'error',
};
