import { CommunityAppEnvironment } from './environment.interface';

const getUrl = window.location;

export const environment: CommunityAppEnvironment = {
  production: false,
  hmr: false,
  authCallback: `https://${getUrl.host}/auth-callback`,
  authClientId: 'mqq2tCK2dT5FNIWzKA66EOCbzpxAwIv6',
  authDomain: 'genoox-dev.eu.auth0.com',
  logoutCallbackUrl: `http://${getUrl.host}/`,
  beBaseURL: '',
  gTagManagerTrackingCode: 'GTM-MQ5ZSQ4',
  multipartChunkSize: 1024 * 1024 * 10,
  froalaActivationKey: 'lA5C4C4E2uF2C1F1H2A10B1C7A1A5C4hrsywvbA2C-9rs==',
};
