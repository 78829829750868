export enum PredefinedFilterType {
  LastStatus = 1,
  Custom = 2,
  Genoox = 3,
  Default = 4,
}

export enum GeneviewVariantType {
  SNP = 1,
  SV = 2,
  ALL = 3,
}

export enum GeneviewAnalysisType {
  Single = 1,
  Family = 2,
}

export type FilterData = Record<string, string | string[]>;

export interface PredefinedFilter {
  id: number;
  quick_filter?: boolean;
  name?: string;
  description?: string;
  created_at?: string;
  data_object?: FilterData;
  type?: PredefinedFilterType;
  sort_value?: number;
  count?: number;
  verified?: boolean;
  variant_type?: GeneviewVariantType;
  for_analysis_type?: GeneviewAnalysisType;
}

export const isPredefinedFilterMerged = (filter: PredefinedFilter): boolean =>
  !!filter.data_object.quick_filter?.length;

export const isPredefinedFilterTree = (filter: PredefinedFilter): boolean =>
  !!filter.data_object.selected_label_id?.length;
