import { createSelector, MemoizedSelector } from '@ngrx/store';
import { EditVariantState, getEditVariantState } from '../reducers';
import { EditVariantSubmitState } from '../reducers/edit-variant.reducer';

const getEditVariantSubmissionState: MemoizedSelector<EditVariantState, EditVariantSubmitState> = createSelector(
  getEditVariantState,
  (state) => state.submission,
);

export const getEditVariantInSubmission: MemoizedSelector<EditVariantState, boolean> = createSelector(
  getEditVariantSubmissionState,
  (state) => state.submitting,
);

export const getEditVariantSubmissionSuccess: MemoizedSelector<EditVariantState, boolean> = createSelector(
  getEditVariantSubmissionState,
  (state) => state.submitSuccess,
);

export const getEditVariantSubmissionFail: MemoizedSelector<EditVariantState, boolean> = createSelector(
  getEditVariantSubmissionState,
  (state) => state.submitFail,
);

export const getEditVariantPosition: MemoizedSelector<
  EditVariantState,
  {
    start: number;
    end: number;
  }
> = createSelector(getEditVariantSubmissionState, (state) => state.editPosition);
