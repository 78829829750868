import { environment } from '../../../../../../environments/environment';

export const GENE_PAGE_SERVICE_CONFIG = {
  publicationsService: {
    getPublications: `${environment.beBaseURL}/api/v2/publications/search_by_gene`,
  },
  conditionsService: {
    getConditions: `${environment.beBaseURL}/api/diseases/search_associated_by_gene`,
  },
  variantDataService: {
    contactCuratorUrl: `${environment.beBaseURL}/api/contact_curator`,
  },
};

export const VARIANT_PAGE_SERVICE_CONFIG = {
  variantDataService: {
    snp: {
      contactMembersUrl: `${environment.beBaseURL}/api/contact_case_owners`,
      contactPostOwnersUrl: `${environment.beBaseURL}/api/contact_post_publisher`,
      contactCuratorUrl: `${environment.beBaseURL}/api/contact_curator`,
    },
    sv: {
      contactMembersUrl: `${environment.beBaseURL}/api/contact_case_owners`,
      contactPostOwnersUrl: `${environment.beBaseURL}/api/contact_post_publisher`,
    },
    roh: {},
  },
  assessmentsService: {
    snp: {
      assessments: `${environment.beBaseURL}/api/fetch_interpretations`,
      submitClinvar: `${environment.beBaseURL}/api/v2/community/submit_clinvar/variants`,
      deleteAssessment: `${environment.beBaseURL}/api/delete_interpretations_json`,
    },
    sv: {
      assessments: `${environment.beBaseURL}/api/sv_fetch_interpretations`,
      submitClinvar: `${environment.beBaseURL}/api/v2/community/submit_clinvar/variants`,
      deleteAssessment: `${environment.beBaseURL}/api/delete_interpretations_json`,
    },
  },
  acmgService: {
    snp: {
      beUrl: `${environment.beBaseURL}/api`,
    },
    sv: {
      beUrl: `${environment.beBaseURL}/api`,
    },
  },
  acmgSvService: {
    sv: {
      beUrl: ``,
    },
  },
  feedService: {
    snp: {
      deletePost: `${environment.beBaseURL}/api/feed/:postType/delete`,
      findPublication: `${environment.beBaseURL}/api/feed/publications/find`,
      getPosts: `${environment.beBaseURL}/api/feed/get_posts`,
      getUsers: `${environment.beBaseURL}/api/v2/user/search`,
      postNote: `${environment.beBaseURL}/api/feed/note/post`,
      postPublication: `${environment.beBaseURL}/api/feed/publications/post`,
      shareClassification: `${environment.beBaseURL}/api/feed/classification/post`,
      shareRule: `${environment.beBaseURL}/api/feed/rule/post`,
    },
    sv: {
      deletePost: `${environment.beBaseURL}/api/feed/:postType/delete`,
      findPublication: `${environment.beBaseURL}/api/feed/publications/find`,
      getPosts: `${environment.beBaseURL}/api/feed/get_posts`,
      getUsers: `${environment.beBaseURL}/api/v2/user/search`,
      postNote: `${environment.beBaseURL}/api/feed/note/post`,
      postPublication: `${environment.beBaseURL}/api/feed/publications/post`,
      shareClassification: `${environment.beBaseURL}/api/feed/classification/post`,
    },
  },
  discussionService: {
    snp: {
      createTopic: `${environment.beBaseURL}/api/discussion/topic`,
      getTopics: `${environment.beBaseURL}/api/discussion/topics`,
      deleteTopic: `${environment.beBaseURL}/api/discussion/topic`,
      getUsers: `${environment.beBaseURL}/api/v2/user/search`,
      submitMessage: `${environment.beBaseURL}/api/discussion/message`,
      getMessages: `${environment.beBaseURL}/api/discussion/messages`,
      deleteMessage: `${environment.beBaseURL}/api/discussion/message`,
    },
    sv: {
      createTopic: `${environment.beBaseURL}/api/discussion/topic`,
      getTopics: `${environment.beBaseURL}/api/discussion/topics`,
      deleteTopic: `${environment.beBaseURL}/api/discussion/topic`,
      getUsers: `${environment.beBaseURL}/api/v2/user/search`,
      submitMessage: `${environment.beBaseURL}/api/discussion/message`,
      getMessages: `${environment.beBaseURL}/api/discussion/messages`,
      deleteMessage: `${environment.beBaseURL}/api/discussion/message`,
    },
  },
  assessmentToolsService: {
    snp: {
      getFrequencies: ``,
      getPredictions: ``,
      getCompoundVariants: ``,
      getInternalFrequency: ``,
      getCommunityFrequency: ``,
      getSomaticFrequency: ``,
      getTranscripts: ``,
      getRegionViewer: ``,
      getFamilyZygosity: ``,
      getSequenceBrowser: ``,
      getSomaticConfidence: ``,
      getCustomAnnotation: ``,
      getGeneCoverage: ``,
      getVariantPriority: ``,
      getFamilyVariantPriority: ``,
    },
    sv: {
      getSvRegionViewer: `${environment.beBaseURL}/api/region_data`,
      getSvRegionViewerCytobands: ``,
      getOccurrences: ``,
      getFamilyZygositySV: ``,
      getSequenceBrowser: ``,
      getSvConfidence: `${environment.beBaseURL}/api/api/analysis/:analysisId/sv-confidence`,
      getSvConfidenceSamples: `${environment.beBaseURL}/api/api/analysis/:analysisId/sv-confidence-samples`,
      getInternalOccurrence: ``,
      getInternalFrequency: ``,
    },
    roh: {
      getSvRegionViewer: `${environment.beBaseURL}/api/region_data`,
      getSvRegionViewerCytobands: ``,
      getRohSummary: `${environment.beBaseURL}/api/api/analysis/:analysisId/roh-report`,
      exportRohSummary: `${environment.beBaseURL}/api/api/analysis/:analysisId/roh-report/csv`,
    },
  },
  genesRegionsService: {
    sv: {
      getGenesRegions: `${environment.beBaseURL}/api/sv/fetch_genes_regions`,
    },
    roh: {
      getGenesRegions: `${environment.beBaseURL}/api/roh/fetch_genes_regions`,
    },
  },
  conditionsService: {
    snp: {
      getConditionDetails: ``,
      getConditions: `${environment.beBaseURL}/api/diseases/search_associated`,
    },
    sv: {
      getConditionDetails: ``,
      getConditions: `${environment.beBaseURL}/api/diseases/search_associated`,
    },
    roh: {
      getConditionDetails: ``,
      getConditions: `${environment.beBaseURL}/api/diseases/search_associated`,
    },
  },
  clinicalEvidenceService: {
    snp: {
      getClinicalEvidence: ``,
      getSomaticClassification: ``,
    },
    sv: {
      getClinicalEvidence: ``,
      getSomaticClassification: ``,
    },
  },
  publicationsService: {
    snp: {
      getPublications: `${environment.beBaseURL}/api/v2/publications/search_with_feed`,
      addPublication: `${environment.beBaseURL}/api/publications/add`,
    },
    sv: {
      getPublications: `${environment.beBaseURL}/api/v2/publications/search`,
      addPublication: `${environment.beBaseURL}/api/publications/add`,
    },
    roh: {
      getPublications: `${environment.beBaseURL}/api/v2/publications/search`,
      addPublication: `${environment.beBaseURL}/api/publications/add`,
    },
  },
  clinicalTrialsService: {
    snp: {
      getClinicalTrials: `${environment.beBaseURL}/api/somatic/variant/clinical_trials/search`,
      getLocationsUrl: `${environment.beBaseURL}/api/clinical_trials/locations`,
    },
    sv: {
      getClinicalTrials: `${environment.beBaseURL}/api/somatic/variant/clinical_trials/search`,
      getLocationsUrl: `${environment.beBaseURL}/api/clinical_trials/locations`,
    },
  },
  curationService: {
    snp: {
      createEvidenceUrl: `${environment.beBaseURL}/api/evidence/create`,
    },
  },
  preloadService: {
    snp: {
      preloadData: `${environment.beBaseURL}/api/warm_snp_data_json`,
    },
    sv: {
      preloadData: `${environment.beBaseURL}/api/warm_sv_data_json`,
    },
  },
  similarVariantsService: {
    sv: {
      getOrgSimilarVariants: ``,
      getCommSimilarVariants: ``,
      getOrgSimilarVariantsEthnicities: ``,
    },
  },
};
