export enum AnalysisGermlineBiomarkerType {
  UPD = 'UPD',
  SMN = 'SMN',
  GBA = 'GBA',
}

export interface AnalysisAdditionalInformationTypesResponse {
  supported_types: AnalysisGermlineBiomarkerType[];
}

export interface BioMarkerIrrelevantData {
  reasons: string[];
  details?: string;
}

export interface AnalysisGermlineBiomarkerUPD {
  chr: string;
  start: number;
  end: number;
  cytobands: string[];
  family_relation: string;
  upd_type: 'isodisomy' | 'heterodisomy' | 'mixed';
  length: number;
  conditions: {
    name: string;
    omim?: string;
  }[];
  classification: 'benign' | 'pathogenic';
  segments: {
    chr: string;
    start: number;
    end: number;
    cytobands: string[];
    upd_type: string;
    length: number;
  }[];
  in_report: boolean;
  biomarker_id: string;
  irrelevant_data?: BioMarkerIrrelevantData;
  inReportChangeInProgress?: boolean;
  irrelevantChangeInProgress?: boolean;
}

export enum AnalysisGermlineBiomarkerSMNStatus {
  NOT_TESTED = 'NOT_TESTED',
  NEGATIVE = 'NEGATIVE',
  AFFECTED = 'AFFECTED',
  CARRIER = 'CARRIER',
  POSSIBLE_SILENT_CARRIER = 'POSSIBLE_SILENT_CARRIER',
}

export interface AnalysisGermlineBiomarkerSMN {
  status: AnalysisGermlineBiomarkerSMNStatus;
  status_details: string;
  additional_info: {
    smn1_copy_number: number;
    smn2_copy_number: number;
    silent_carrier_predictor_copy_number: number;
    silent_carrier_predictor_variant_name: string;
  };
  in_report?: boolean;
  biomarker_id?: string;
  inReportChangeInProgress?: boolean;
}

export interface AnalysisGermlineBiomarkerGBA {
  biomarker_id: string;
  additional_info: {
    total_copy_number: number;
    deletion_breakpoint_in_gene?: boolean;
    recombinant_haplotypes: [string, string] | [];
    variants: string[];
  };
  in_report?: boolean;
  inReportChangeInProgress?: boolean;
  status: 'ABNORMAL' | 'NOT_TESTED' | 'NOT_DETECTED';
}

export interface AnalysisAdditionalInformationGermlineBiomarker {
  biomarker_type: AnalysisGermlineBiomarkerType;
  data: AnalysisGermlineBiomarkerUPD | AnalysisGermlineBiomarkerSMN | AnalysisGermlineBiomarkerGBA;
}
