import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as fromAction from './../actions/enrichment-kits.action';
import { EnrichmentKitsService } from '../services';

@Injectable()
export class EnrichmentKitsEffect {
  constructor(
    private actions$: Actions,
    private enrichmentKitsService: EnrichmentKitsService,
  ) {}

  $getAssayList$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GET_ENRICHMENT_KIT),
      switchMap(() =>
        this.enrichmentKitsService.getEnrichmentKit().pipe(
          map((result) => new fromAction.GetEnrichmentKitSuccess(result)),
          catchError((err) => of(new fromAction.GetEnrichmentKitFail(err))),
        ),
      ),
    ),
  );
}
