import { VariantInterpretation } from '../../models';

import * as fromAction from './../actions/interpretation-history.action';

export interface InterpretationHistoryState {
  data: VariantInterpretation[];
  loading: boolean;
  loaded: boolean;
  error?: any;
}

const initialState: InterpretationHistoryState = {
  data: [],
  loading: false,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: fromAction.InterpretationHistoryAction,
): InterpretationHistoryState {
  switch (action.type) {
    case fromAction.LOAD_INTERPRETATION_HISTORY: {
      return {
        ...state,
        data: [],
        loading: true,
        loaded: false,
        error: '',
      };
    }
    case fromAction.LOAD_INTERPRETATION_HISTORY_SUCCESS: {
      return {
        ...state,
        data: action.data,
        loading: false,
        loaded: true,
      };
    }
    case fromAction.LOAD_INTERPRETATION_HISTORY_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    }
  }

  return state;
}

export const getInterpretationHistoryEntities = (state: InterpretationHistoryState) => state.data;
export const getInterpretationHisotyLoadedEntity = (state: InterpretationHistoryState) => state.loaded;
export const getInterpretationHisotyLoadingEntity = (state: InterpretationHistoryState) => state.loading;
