import { AnalyticsAction, AnalyticsEvent, extractEventFromType } from '../../../../store/actions/analytics.action';

export const OPEN_REGISTRATION_POPUP = '[Variants] Open registration popup';

export class OpenRegistrationPopup implements AnalyticsAction {
  readonly type = OPEN_REGISTRATION_POPUP;
  analyticsEvent: AnalyticsEvent;

  constructor(
    public urlAfterAuth?: string,
    public style?: string,
  ) {
    this.analyticsEvent = extractEventFromType(this.type);
  }
}

export type PopupAction = OpenRegistrationPopup;
