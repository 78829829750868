import { Action } from '@ngrx/store';
import { ResultsFile } from '../models';
import { VariantLabel } from '../../../analysis-variant/models';

export const GET_EXPORT_RESULTS_URL = '[Analysis] Get export results url';
export const GET_EXPORT_RESULTS_URL_SUCCESS = '[Analysis] Get export results url success';
export const GET_EXPORT_RESULTS_URL_FAIL = '[Analysis] Get export results url fail';

export class GetExportResultsUrl implements Action {
  readonly type = GET_EXPORT_RESULTS_URL;

  constructor(public analysisId: number) {}
}

export class GetExportResultsUrlSuccess implements Action {
  readonly type = GET_EXPORT_RESULTS_URL_SUCCESS;

  constructor(public result: ResultsFile) {}
}

export class GetExportResultsUrlFail implements Action {
  readonly type = GET_EXPORT_RESULTS_URL_FAIL;

  constructor(public error: any) {}
}

export const GET_EXPORT_LABELED_VARIANTS_URL = '[Analysis] Get export labeled variants url';
export const GET_EXPORT_LABELED_VARIANTS_URL_SUCCESS = '[Analysis] Get export labeled variants url success';
export const GET_EXPORT_LABELED_VARIANTS_URL_FAIL = '[Analysis] Get export labeled variants url fail';

export class GetExportLabeledVariantsUrl implements Action {
  readonly type = GET_EXPORT_LABELED_VARIANTS_URL;

  constructor(
    public analysisId: number,
    public label: VariantLabel,
  ) {}
}

export class GetExportLabeledVariantsUrlSuccess implements Action {
  readonly type = GET_EXPORT_LABELED_VARIANTS_URL_SUCCESS;

  constructor(public result: ResultsFile) {}
}

export class GetExportLabeledVariantsUrlFail implements Action {
  readonly type = GET_EXPORT_LABELED_VARIANTS_URL_FAIL;

  constructor(public error: any) {}
}

export const GET_EXPORT_MACCABI_SUMMARY_URL = '[Analysis] Get export Maccabi summary url';

export class GetExportMaccabiSummaryUrl implements Action {
  readonly type = GET_EXPORT_MACCABI_SUMMARY_URL;

  constructor(public analysisId: number) {}
}

export type ExportResultsAction =
  | GetExportResultsUrl
  | GetExportResultsUrlSuccess
  | GetExportResultsUrlFail
  | GetExportLabeledVariantsUrl
  | GetExportLabeledVariantsUrlSuccess
  | GetExportLabeledVariantsUrlFail;
