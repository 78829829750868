import { Injectable } from '@angular/core';
import * as fromModels from '../models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class CancerTypesService {
  constructor(private http: HttpClient) {}

  getCancerTypes(prefixText: string): Observable<fromModels.CancerTypes> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<fromModels.CancerTypes>(
      `${environment.beBaseURL}/api/api/diseases/cancer-type-suggestions`,
      { prefixText },
      { headers },
    );
  }
}
