import { createSelector, MemoizedSelector } from '@ngrx/store';
import { FiltersState, getFiltersState } from '../reducers';
import {
  FilterTree,
  FilterTreeInfo,
  FilterTreeLabels,
  filterTreeLabelsToUIFilterModel,
  FilterTreeOptions,
  UIFilterModel
} from '../models/filter-tree.model';
import { FilterTreeState } from '../reducers/filter-tree.reducer';

const getFilterTreeState: MemoizedSelector<FiltersState, FilterTreeState> = createSelector(
  getFiltersState,
  (state) => state?.filterTree,
);

export const getFilterTreeDefinition: MemoizedSelector<FiltersState, FilterTree> = createSelector(
  getFilterTreeState,
  (state) => state.filterTreeDefinition,
);

export const getFilterTreeDefinitionLoading: MemoizedSelector<FiltersState, boolean> = createSelector(
  getFilterTreeState,
  (state) => state.filterTreeDefinitionLoading,
);

export const getFilterTreeDefinitionLoaded: MemoizedSelector<FiltersState, boolean> = createSelector(
  getFilterTreeState,
  (state) => state.filterTreeDefinitionLoaded,
);

export const getFilterTrees: MemoizedSelector<FiltersState, FilterTreeInfo[]> = createSelector(
  getFilterTreeState,
  (state) => state?.filterTrees,
);

export const getFilterTreesLoading: MemoizedSelector<FiltersState, boolean> = createSelector(
  getFilterTreeState,
  (state) => state?.filterTreesLoading,
);

export const getFilterTreesLoaded: MemoizedSelector<FiltersState, boolean> = createSelector(
  getFilterTreeState,
  (state) => state?.filterTreesLoaded,
);

export const getFilterTreeOptions: MemoizedSelector<FiltersState, FilterTreeOptions> = createSelector(
  getFilterTreeState,
  (state) => state.filterTreeOptions,
);

export const getFilterTreeOptionsLoading: MemoizedSelector<FiltersState, boolean> = createSelector(
  getFilterTreeState,
  (state) => state.filterTreeOptionsLoading,
);

export const getFilterTreeOptionsLoaded: MemoizedSelector<FiltersState, boolean> = createSelector(
  getFilterTreeState,
  (state) => state.filterTreeOptionsLoaded,
);

export const getFilterTreeLabels: MemoizedSelector<FiltersState, FilterTreeLabels> = createSelector(
  getFilterTreeState,
  (state) => state.filterTreeLabels,
);

export const getFilterTreeLabelsLoading: MemoizedSelector<FiltersState, boolean> = createSelector(
  getFilterTreeState,
  (state) => state.filterTreeLabelsLoading,
);

export const getFilterTreeLabelsLoaded: MemoizedSelector<FiltersState, boolean> = createSelector(
  getFilterTreeState,
  (state) => state.filterTreeLabelsLoaded,
);

export const getFilterTreeLabelsLoadingError: MemoizedSelector<FiltersState, any> = createSelector(
  getFilterTreeState,
  (state) => state.filterTreeLabelsLoadingError,
);

export const getUnifiedQuickFiltersAndTreeLabels: MemoizedSelector<FiltersState, UIFilterModel[]> = createSelector(
  getFilterTreeLabels,
  filterTreeLabelsToUIFilterModel,
);
