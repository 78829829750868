import { WorkbenchEffects } from './workbench.effects';
import { ConfigEffect } from './config.effect';
import { AnalysisResultsEffects } from './analysis-results.effects';
import { CaseDataEffects } from './case-data.effects';
import { QualityControlEffects } from './quality-control.effects';
import { ReferralNotesEffects } from './referral-notes.effects';
import { VariantInterpretationEffects } from './variant-interpretation.effects';
import { CancerTypeEffects } from './cancer-type.effects';
import { DownloadAnalysisFileEffects } from './download-analysis-file.effects';
import { SomaticBiomarkerEffect } from './somatic-biomarker-effect';
import { ExportResultsEffects } from './export-results.effects';
import { CaseFilterEffects } from './case-filter.effects';
import { EcsAnalysisResultsEffect } from './ecs-analysis-results.effect';
import { AnalysisAdditionalInformationEffects } from './analysis-additional-information.effects';
import { CaseSummaryEffect } from './case-summary.effect';
import { CaseProtocolsEffect } from './case-protocols.effect';
import { VariantListEffect } from './variant-list.effect';

export const workbenchEffects = [
  CaseSummaryEffect,
  WorkbenchEffects,
  ConfigEffect,
  AnalysisResultsEffects,
  CaseDataEffects,
  QualityControlEffects,
  ReferralNotesEffects,
  VariantInterpretationEffects,
  CancerTypeEffects,
  DownloadAnalysisFileEffects,
  SomaticBiomarkerEffect,
  ExportResultsEffects,
  CaseFilterEffects,
  EcsAnalysisResultsEffect,
  AnalysisAdditionalInformationEffects,
  CaseProtocolsEffect,
  VariantListEffect
];
