import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EnrichmentKit } from '../../modules/analysis/modules/analyses/store/models';
import { map } from 'rxjs/operators';

@Injectable()
export class EnrichmentKitsService {
  constructor(private http: HttpClient) {}

  getEnrichmentKit(): Observable<EnrichmentKit[]> {
    return this.http
      .get<{ results: EnrichmentKit[] }>(`${environment.beBaseURL}/api/api/enrichment-kit/`)
      .pipe(map(({ results }) => results));
  }
}
