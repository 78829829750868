import { Action } from '@ngrx/store';

export const LAZY_LOAD_MODULE = '[Lazy Loader] Lazy Load Module';

export class LazyLoadModule implements Action {
  readonly type = LAZY_LOAD_MODULE;

  constructor(
    public module: any,
    public onLoadAction: Action,
  ) {}
}

export type LazyLoaderAction = LazyLoadModule;
