import * as fromAction from './../actions/clinvar.action';

export interface ClinvarState {
  submitting: boolean;
  submitted: boolean;
}

const initialState: ClinvarState = {
  submitting: false,
  submitted: false,
};

export function reducer(state = initialState, action: fromAction.ClinvarAction): ClinvarState {
  switch (action.type) {
    case fromAction.SUBMIT_TO_CLINVAR: {
      return {
        ...state,
        submitting: true,
        submitted: false,
      };
    }
    case fromAction.SUBMIT_TO_CLINVAR_SUCCESS: {
      return {
        ...state,
        submitting: false,
        submitted: true,
      };
    }
    case fromAction.SUBMIT_TO_CLINVAR_FAIL: {
      return {
        ...state,
        submitting: false,
        submitted: false,
      };
    }
  }

  return state;
}

export const getSubmitting = (state: ClinvarState) => state.submitting;
export const getSubmitted = (state: ClinvarState) => state.submitted;
