import { Action } from '@ngrx/store';
import {
  ApplicationType,
  MaxiApplicationType,
  MiniApplicationConfig,
  MiniApplicationType,
  VariantPageConfig
} from '../../models';

export const SET_VARIANT_PAGE_CONFIG = '[Variant Page] Set variant page config';
export const CHANGE_VARIANT_PAGE_APP_CONFIG = '[Variant Page] Change variant page app config';
export const LOAD_DEFAULT_APPLICATION = '[Variant Page] Load default application';
export const TOGGLE_MINI_APP_EXPANDED_STATE = '[Variant Page] Toggle mini app expanded state';
export const SET_SELECTED_APP = '[Variant Page] Set selected app';
export const SET_SELECTED_APP_SUCCESS = '[Variant Page] Set selected app success';
export const SET_SELECTED_MINI_APP = '[Variant Page] Set selected mini-app';
export const SET_MINI_APPS = '[Variant Page] Set mini-apps';
export const RESET_MINI_APPS = '[Variant Page] Reset mini-apps';
export const SET_SELECTED_MAXI_APP = '[Variant Page] Set selected maxi-app';
export const THROW_MISSING_CONFIG_ERROR = '[Variant Page] Throw Missing Config Error';
export const CLEAR_VARIANT_PAGE_CONFIG = '[Variant Page] Clear variant page config';

export class SetVariantPageConfig implements Action {
  readonly type = SET_VARIANT_PAGE_CONFIG;

  constructor(public config: VariantPageConfig) {}
}

export class ChangeVariantPageAppConfig implements Action {
  readonly type = CHANGE_VARIANT_PAGE_APP_CONFIG;

  constructor(
    public app: ApplicationType | MiniApplicationType,
    public property: string,
    public value: any,
  ) {}
}

export class LoadDefaultApplication implements Action {
  readonly type = LOAD_DEFAULT_APPLICATION;

  constructor() {}
}

export class SetSelectedApp implements Action {
  readonly type = SET_SELECTED_APP;

  constructor(public app: ApplicationType) {}
}

export class SetSelectedAppSuccess implements Action {
  readonly type = SET_SELECTED_APP_SUCCESS;

  constructor(public app: ApplicationType) {}
}

export class SetSelectedMiniApp implements Action {
  readonly type = SET_SELECTED_MINI_APP;

  constructor(public miniApp: MiniApplicationType, public config?: MiniApplicationConfig) {}
}

export class SetMiniApps implements Action {
  readonly type = SET_MINI_APPS;

  constructor(public miniApps: MiniApplicationType[]) {}
}

export class ResetMiniApps implements Action {
  readonly type = RESET_MINI_APPS;
}

export class SetSelectedMaxiApp implements Action {
  readonly type = SET_SELECTED_MAXI_APP;

  constructor(public maxiApp: MaxiApplicationType) {}
}

export class ToggleMiniAppExpandedState implements Action {
  readonly type = TOGGLE_MINI_APP_EXPANDED_STATE;

  constructor(public width?: number) {}
}

export class ThrowMissingConfigError implements Action {
  readonly type = THROW_MISSING_CONFIG_ERROR;

  constructor() {}
}

export class ClearVariantPageConfig implements Action {
  readonly type = CLEAR_VARIANT_PAGE_CONFIG;
}

export const SET_VARIANT_POPUP_OPEN = '[Variant Page] Set variant popup open';

export class SetVariantPopupOpen implements Action {
  readonly type = SET_VARIANT_POPUP_OPEN;

  constructor(public open: boolean) {}
}

export type ConfigAction =
  | SetVariantPageConfig
  | ChangeVariantPageAppConfig
  | LoadDefaultApplication
  | ToggleMiniAppExpandedState
  | SetSelectedApp
  | SetSelectedAppSuccess
  | SetSelectedMiniApp
  | SetSelectedMaxiApp
  | ThrowMissingConfigError
  | ClearVariantPageConfig
  | SetMiniApps
  | ResetMiniApps
  | SetVariantPopupOpen;
