import { Action } from '@ngrx/store';
import { ClassificationResult, SearchErrorType } from '../../models';
import { AnalyticsAction, AnalyticsEvent, extractEventFromType } from '../../../shared/models/analytics.action';
import { VariantId } from '../../../variant-page/models';

export const GET_CLASSIFICATION = '[Classification] Get Classification';
export const GET_CLASSIFICATION_SUCCESS = '[Classification] Get Classification Success';
export const GET_CLASSIFICATION_FAILURE = '[Classification] Get Classification Failure';
export const SET_CLASSIFICATION = '[Classification] Set Classification';
export const RESET_CLASSIFICATION = '[Classification] Reset Classification';
export const CLEAR_CLASSIFICATION = '[Classification] Clear Classification';

export class GetClassification implements Action {
  readonly type = GET_CLASSIFICATION;

  constructor(public variantId: VariantId) {}
}

export class GetClassificationSuccess implements AnalyticsAction {
  analyticsEvent: AnalyticsEvent;
  readonly type = GET_CLASSIFICATION_SUCCESS;

  constructor(public classificationResult: ClassificationResult) {
    this.analyticsEvent = extractEventFromType(this.type);
  }
}

export class SetClassification implements Action {
  readonly type = SET_CLASSIFICATION;

  constructor(public classificationResult: ClassificationResult) {}
}

export class GetClassificationFailure implements AnalyticsAction {
  readonly type = GET_CLASSIFICATION_FAILURE;

  analyticsEvent: AnalyticsEvent;

  constructor(
    public error: SearchErrorType,
    public searchTerm?: string,
  ) {
    this.analyticsEvent = extractEventFromType(this.type);
  }
}

export class ResetClassification implements Action {
  readonly type = RESET_CLASSIFICATION;
}

export class ClearClassification implements Action {
  readonly type = CLEAR_CLASSIFICATION;
}

export type ClassificationActions =
  | SetClassification
  | GetClassification
  | GetClassificationSuccess
  | GetClassificationFailure
  | ResetClassification
  | ClearClassification;
