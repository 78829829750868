import { createSelector, MemoizedSelector } from '@ngrx/store';
import { getWorkbenchModuleState, WorkbenchModuleState } from '../reducers';
import { CaseProtocol, CaseProtocolStep } from '../models';
import { CaseProtocolsState } from '../reducers/case-protocols.reducer';

const getCaseProtocolsState: MemoizedSelector<WorkbenchModuleState, CaseProtocolsState> = createSelector(
  getWorkbenchModuleState,
  (state) => state.protocols,
);

export const getCaseProtocolsData: MemoizedSelector<WorkbenchModuleState, CaseProtocol[]> = createSelector(
  getCaseProtocolsState,
  (state) => state.data,
);

export const getCaseProtocolsLoading: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getCaseProtocolsState,
  (state) => state.loading,
);

export const getCaseProtocolsLoaded: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getCaseProtocolsState,
  (state) => state.loaded,
);

export const getProtocolStepOptionsList: MemoizedSelector<WorkbenchModuleState, CaseProtocolStep[]> = createSelector(
  getCaseProtocolsState,
  (state) => state.stepOptions,
);
