import { NgxZendeskWebwidgetConfig } from 'ngx-zendesk-webwidget';

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  lazyLoad = true;
  accountUrl = 'genoox.zendesk.com';

  callback(zE) {
    zE('webWidget', 'setLocale', 'en');
    zE('webWidget', 'hide');
    zE('webWidget:on', 'close', () => {
      zE('webWidget', 'hide');
    });
    zE('webWidget:on', 'open', () => {
      zE('webWidget', 'show');
    });
  }
}
