import {
  ClinicalSignificance,
  CustomAnnotation,
  InterpretationTag,
  LatestComment,
  PhenotypeRelevance,
  SubmissionsSummary,
} from './snp-result.model';
import { VariantHighlight } from './variant-highlights.model';
import { VariantLabelModel } from './variant-label.model';
import { BaseVariant } from './analysis-result.model';
import { Linkout } from '../../../../../store/models/variant-linkouts.model';

export interface SvGenomicData {
  region: string;
  genes_symbols: string[];
  exon_number: number;
  intron_number: number;
  transcript_exon_count: number;
  closest_exon: number;
  closest_distance_to_exon: number;
  transcript: string;
  num_transcripts: number;
}

export interface DatabaseOccurrenceData {
  explanation: string;
  db_name: string;
  occurrence_data: {
    frequency: number;
    occurrences: number;
    populations: string[];
  };
  db_id: string;
}

export interface ClingenDosageSensitivity {
  triplosensitivity_score: {
    readable_name: string;
    choice_value: number;
  };
  haploinsufficiency_score: {
    readable_name: string;
    choice_value: number;
  };
  name: string;
}

export interface VariantPosition {
  start: number;
  end: number;
  chromosome: string;
  chromosome_str?: string;
  position_str?: string;
}

export interface ExtraVCFAnnotation {
  display_text: string;
  value: string;
  link?: string;
}

export interface IscnData {
  array_cyto: string;
  seq_cyto: string;
  gdot: string;
}

export interface SvResult extends BaseVariant {
  overlapping_sv_data: {
    confidence: number;
    zygosity: string;
    sampleName: string;
    location: {
      start: number;
      end: number;
      chromosome: string;
    };
    relation: string;
  }[];
  annotation_data: {
    cytobands_display_description: string;
    genes_symbols: string[];
    genomic_data: {
      refseq: SvGenomicData;
      ensembl: SvGenomicData;
    };
    classification: {
      score: number;
      classification: string;
    };
    occurrence_data: {
      databases_occurrence_data: DatabaseOccurrenceData[];
      aggregated_occurrence_data: {
        frequency: number;
        occurrences: number;
        populations: string[];
      };
    };
    evidences: {
      clingen_dosage_sensitivity: {
        top_genes_regions_with_scores: ClingenDosageSensitivity[];
        top_haploinsufficiency_score: {
          readable_name: string;
          choice_value: number;
        };
        top_triplosensitivity_score: {
          readable_name: string;
          choice_value: number;
        };
      };
      decipher_classified_data: {
        decipherLink: string;
        position: {
          positionStr: string;
        };
        classification: string;
      };
      clinvar: {
        total: number;
        classifications: {
          classification: string;
          count: number;
        }[];
        variant_link: string;
      };
      decipher: {
        total: number;
        classifications: {
          classification: string;
          count: number;
        }[];
      };
      submissions_summary: SubmissionsSummary;
      does_evidence_exist: boolean;
    };
    exons: number[];
    extra_vcf_annotations: ExtraVCFAnnotation[];
    num_exons_in_gene: number;
    user_annotations: CustomAnnotation[];
    iscn_data: IscnData;
  };
  repeat_data: {
    confidence: {
      supporting_reads_type: string;
      in_repeat_reads_count: number;
      flanking_reads_count: number;
      locus_coverage: number;
      confidence_interval: string;
      spanning_reads_count: number;
    };
    variant: {
      repeat_nucleotides: string;
      repeats_copy_number: number;
    };
    ranges_annotation: {
      name: string;
      range: {
        to?: number;
        from?: number;
      };
    }[];
    allele_b_data: {
      supporting_reads_type: string;
      repeats_copy_number: number;
      confidence_interval: string;
      confidence_level: number;
    };
    related_samples_repeat_data: {
      copy_number_allele_a: number;
      copy_number_allele_b: number;
      relation: string;
    }[];
  };
  variant_data: {
    cipos: {
      first: number;
      second: number;
    };
    ciend: {
      first: number;
      second: number;
    };
    variation_type: {
      readable_name: string;
      readable_name_short: string;
      name: string;
    };
    position: VariantPosition;
    var_id: string;
    sv_length: number;
  };
  sample_data: {
    confidence_info: {
      confidence_level: {
        choice_value: number;
        readable_name: string;
      };
      fold_ratio: number | string;
      bins_count: number;
      clipped_reads: number;
      referenced_pairs: number | string;
      discordant_pairs: number;
      allele_balance: number;
      split_reads: number | string;
      paired_ends: number;
      split_reads_ref_alt: string;
      read_pairs_ref_alt: string;
      num_of_probes: number;
    };
    zygosity: {
      readable_name: string;
      name: string;
      value_in_old_sv_table: string;
    };
    variant_callers: {
      readable_name: string;
    }[];
    copy_number?: number;
    is_mosaic: boolean;
    coverage_ratio?: number;
    custom_variant_callers: string[];
    phasing_data?: {
      allele_type: 'ALLELE_A' | 'ALLELE_B';
      phase_set: number;
    };
    quality?: number;
  };
  internal_info?: {
    internal_occurrence: number;
    internal_occurrence_frequency: number;
  };
  users_interpretations_data?: {
    user_interpretation: {
      flag: number;
      text?: string;
      user_first_name: string;
      updated_on: string;
      in_workbench: boolean;
      interpretation_sub_level: string;
      draft_classification_flag: number;
      draft_sub_classification: string;
      interpretation_tags: InterpretationTag[];
      draft?: boolean;
    };
    all_interpretations_bitmap?: number;
  };
  phenotypes_search_data?: PhenotypeRelevance;
  interpretation_data: {
    in_report: boolean;
    irrelevant: boolean;
    in_workbench: boolean;
  };
  highlights: VariantHighlight[];
  clinical_significance_data: ClinicalSignificance;
  diseases: {
    inheritance_models: string[];
    count: number;
  };
  latest_comment: LatestComment;
  is_manually_called?: boolean;
  labels: Array<VariantLabelModel>;
  relation: string;
  publications_data: {
    genes_count: number;
    variants_count: number;
  };
  parentage: {
    is_from_father: boolean;
    is_from_mother: boolean;
    is_father_affected: boolean;
    is_mother_affected: boolean;
    father_zygosity: string;
    mother_zygosity: string;
  };
  linkouts?: Linkout[];
}
