import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { TeamMember, UserDetails } from '../../../auth0/models';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AuthModuleState } from '../../../auth0/store/reducers';
import { DeleteMember, GetTeamMembers, SetMemberRoles, UpdateOrgInfo } from '../../../auth0/store/actions';
import { InviteMemberPopupComponent } from '../invite-member-popup/invite-member-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, Observable, of, timer } from 'rxjs';
import { getTeamMembers, getUpdateOrgInProgress } from '../../../auth0/store/selectors';
import { UserUtils } from '../../../auth0/utils/user.utils';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { map, switchMap } from 'rxjs/operators';
import { AuthApiService } from '../../../auth0/services/auth-api.service';
import { MatOption } from '@angular/material/core';
import { AvatarModule } from 'ngx-avatar-ivy';
import { DotsLoaderComponent } from '../../../../shared/components/dots-loader/dots-loader.component';
import { ClickCursorDirective } from '../../../../shared/directives/click-cursor.directive';
import { NgClass, NgIf, NgFor, NgSwitch, NgSwitchCase, AsyncPipe, KeyValuePipe } from '@angular/common';

@Component({
  selector: 'gnx-organization-info',
  templateUrl: './organization-info.component.html',
  styleUrls: ['./organization-info.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    NgIf,
    ClickCursorDirective,
    DotsLoaderComponent,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    AvatarModule,
    MatSelect,
    MatOption,
    AsyncPipe,
    KeyValuePipe,
  ],
})
export class OrganizationInfoComponent implements OnChanges, OnInit {
  @Input() data: UserDetails;
  @Input() isAdmin: boolean;
  @ViewChild('searchInput') searchInput: ElementRef;
  orgForm: FormGroup;
  formSubmitted: boolean;
  formChanged = false;
  uniqueOrgViolationMsg: string;
  updateOrgInProgress$: Observable<boolean>;
  searchMembers$ = new BehaviorSubject('');
  teamMembers$: Observable<{ data: TeamMember[]; loaded: boolean; loading: boolean; membersLoading?: any }>;

  readonly USER_TYPE = {
    admin: 'Admin',
    member: 'Member',
  };

  constructor(
    private fb: FormBuilder,
    private authStore$: Store<AuthModuleState>,
    private dialog: MatDialog,
    private service: AuthApiService,
  ) {}

  ngOnChanges() {
    this.orgForm = this.fb.group({
      orgName: [
        { value: this.data.organization.name, disabled: !this.isAdmin },
        [Validators.required],
        this.orgNameAvailabilityValidator.bind(this),
      ],
    });

    this.orgForm.valueChanges.subscribe(() => (this.formChanged = true));
  }

  ngOnInit() {
    this.authStore$.dispatch(new GetTeamMembers(true));
    this.updateOrgInProgress$ = this.authStore$.pipe(select(getUpdateOrgInProgress));
    this.teamMembers$ = combineLatest([this.searchMembers$, this.authStore$.pipe(select(getTeamMembers))]).pipe(
      map(([searchText, membersState]) => {
        if (membersState.data) {
          if (!!searchText) {
            searchText = searchText.toLowerCase();
            membersState.data = membersState.data.filter(
              (user) => user.name?.toLowerCase().includes(searchText) || user.email.includes(searchText),
            );
          }
        }

        return membersState;
      }),
    );
  }

  onCancel() {
    this.orgForm.reset({
      orgName: this.data.organization.name,
    });
    this.formChanged = false;
  }

  onSubmit() {
    this.formSubmitted = true;
    this.authStore$.dispatch(new UpdateOrgInfo(this.orgForm.get('orgName').value));
  }

  get f() {
    return this.orgForm.controls;
  }

  deleteMember(user: TeamMember) {
    this.authStore$.dispatch(
      new DeleteMember(user.auth0_id, user.email, user.status.toLowerCase() === 'pending', user.name),
    );
  }

  openInviteMemberDialog() {
    this.dialog.open(InviteMemberPopupComponent, {
      data: this.data,
      width: '660px',
      height: '530px',
      backdropClass: 'error-popup-backdrop',
      panelClass: 'error-popup-panel',
    });
  }

  getOrgMemberRole(user: TeamMember): string {
    return UserUtils.isUserAnAdmin(user) ? this.USER_TYPE.admin : this.USER_TYPE.member;
  }

  onMemberRoleChange(user: TeamMember, event: MatSelectChange) {
    const isPending = user.status.toLowerCase() === 'pending';
    this.authStore$.dispatch(
      new SetMemberRoles(
        user.auth0_id,
        user.email,
        isPending,
        event.value === this.USER_TYPE.admin ? UserUtils.addAdminRole(user) : UserUtils.removeAdminRole(user),
      ),
    );
  }

  orgNameAvailabilityValidator(control: AbstractControl): Observable<ValidationErrors | null> {
    return control.value !== this.data.organization.name
      ? timer(500).pipe(
          switchMap(() => this.service.checkOrganizationAvailability(control.value)),
          map((result) => {
            if (!result.available) {
              this.uniqueOrgViolationMsg = result.error;
              return { uniqueOrgViolation: true };
            }
            return null;
          }),
        )
      : of(null);
  }
}
