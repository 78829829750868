import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[gnxBlock]',
  standalone: true,
})
export class BlockDirective {
  @Input() border: string;
  @Input() inline: boolean;
  @HostBinding('style.width') @Input() width: string;
  @HostBinding('style.height') @Input() height: string;
  @HostBinding('style.margin') @Input() margin: string;
  @HostBinding('style.padding') @Input() padding: string;
  @HostBinding('style.background') @Input() background: string;
  @HostBinding('style.position') @Input() position: 'absolute' | 'relative' | 'fixed' | 'sticky';
  @HostBinding('style.overflow') @Input() overflow: string;
  @HostBinding('style.inset') @Input() inset: string;
  @HostBinding('style.z-index') @Input() zIndex: number;
  @HostBinding('style.maxHeight') @Input() maxHeight: string;
  @HostBinding('style.minHeight') @Input() minHeight: string;
  @HostBinding('style.maxWidth') @Input() maxWidth: string;
  @HostBinding('style.minWidth') @Input() minWidth: string;
  @HostBinding('style.text-align') @Input() textAlign: 'left' | 'center' | 'right' | 'justify';
  @HostBinding('style.border-radius') @Input() borderRadius: string;
  @HostBinding('style.outline') @Input() outline: string;
  @HostBinding('style.transform') @Input() transform: string;
  @HostBinding('style.transition') @Input() transition: string;
  @HostBinding('style.cursor') @Input() cursor: string;
  @HostBinding('style.pointerEvents') @Input() pointerEvents: string;
  @HostBinding('style.opacity') @Input() opacity: number;
  @HostBinding('style.boxShadow') @Input() shadow: string;

  @HostBinding('style.border-style')
  get borderStyle(): string {
    if (!this.border || !this.border.includes(' ')) {
      return this.border;
    }
    const borderParts = this.border.split(' ');
    return borderParts[borderParts.length - 2];
  }

  @HostBinding('style.border-color')
  get borderColor(): string {
    if (!this.border || !this.border.includes(' ')) {
      return this.border;
    }
    const borderParts = this.border.split(' ');
    return borderParts[borderParts.length - 1];
  }

  @HostBinding('style.border-width')
  get borderWidth(): string {
    if (!this.border || !this.border.includes(' ')) {
      return this.border;
    }
    const borderParts = this.border.split(' ');
    return borderParts.slice(0, -2).join(' ');
  }

  @HostBinding('style.display')
  get display(): string {
    return this.inline ? 'inline-block' : undefined;
  }
}
