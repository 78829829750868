import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgFor, NgClass, NgIf } from '@angular/common';
import { ClickCursorDirective } from '../../shared/directives/click-cursor.directive';

@Component({
  selector: 'gnx-multiple-roh-popup',
  templateUrl: './multiple-roh-popup.component.html',
  styleUrls: ['./multiple-roh-popup.component.scss'],
  standalone: true,
  imports: [ClickCursorDirective, NgFor, ReactiveFormsModule, NgClass, NgIf],
})
export class MultipleRohPopupComponent implements OnInit {
  REGION_REGEX: RegExp = /^chr[\dXY]{1,2}:\d+-\d+$/i;

  formArray: FormArray;
  formControl: FormControl;

  @ViewChild('inputs') inputsRef: ElementRef;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<MultipleRohPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { search: (input: string) => {} },
  ) {}

  ngOnInit() {
    this.formControl = this.fb.control('', [this.multipleFormatValidator.bind(this)]);
    this.formArray = this.fb.array(
      new Array(3).fill(null).map(() => this.fb.control('', [this.singleFormatValidator.bind(this)])),
    );
    this.formArray.valueChanges.subscribe((value: string[]) => {
      const joined: string = value
        .map((value1) => value1.trim())
        .join(' ')
        .trim();
      if (this.formArray.enabled) {
        if (joined) {
          this.formControl.disable({ emitEvent: false });
        } else {
          this.formControl.enable({ emitEvent: false });
        }
        this.formControl.setValue(joined);
      }
    });
    this.formControl.valueChanges.subscribe((value: string) => {
      if (this.formControl.enabled) {
        if (value) {
          this.formArray.disable({ emitEvent: false });
        } else {
          this.formArray.enable({ emitEvent: false });
        }
      }
    });
  }

  addInput() {
    this.formArray.push(this.fb.control('', [this.singleFormatValidator.bind(this)]));
    setTimeout(() => {
      this.inputsRef.nativeElement.scrollBy({
        top: 70,
        behavior: 'smooth',
      });
    }, 10);
  }

  onClose() {
    this.dialogRef.close();
  }

  onSearch() {
    this.data.search(this.formControl.value);
    this.onClose();
  }

  private singleFormatValidator(fc: FormControl) {
    if (!fc.value) {
      return null;
    }
    return this.validateRegion(fc.value);
  }

  private multipleFormatValidator(fc: FormControl) {
    if (!fc.value) {
      return null;
    }
    const parts = fc.value.trim().split(' ');

    for (const part of parts) {
      const ret = this.validateRegion(part);
      if (ret) {
        return ret;
      }
    }
    return null;
  }

  private validateRegion(region: string) {
    region = region.trim().replace(/,/g, '');
    if (!this.REGION_REGEX.test(region)) {
      return {
        format: true,
      };
    }
    const positions: number[] = region
      .split(':')[1]
      .split('-')
      .map((posStr) => parseInt(posStr, 10));
    if (positions[0] >= positions[1]) {
      return {
        position: true,
      };
    }
    return null;
  }
}
