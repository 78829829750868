import { Action } from '@ngrx/store';
import { Phenotype } from '../models';

export const SEARCH_GENES = '[Root] Search Genes';
export const SEARCH_GENES_SUCCESS = '[Root] Search Genes Success';
export const SEARCH_GENES_FAIL = '[Root] Search Genes Fail';
export const CLEAR_GENES = '[Root] Clear Genes';

export class SearchGenes implements Action {
  readonly type = SEARCH_GENES;

  constructor(public str: string) {}
}

export class SearchGenesSuccess implements Action {
  readonly type = SEARCH_GENES_SUCCESS;

  constructor(public suggestions: Phenotype[]) {}
}

export class SearchGenesFail implements Action {
  readonly type = SEARCH_GENES_FAIL;

  constructor(public err: any) {}
}

export class ClearGenes implements Action {
  readonly type = CLEAR_GENES;
}

export type GenesAction = SearchGenes | SearchGenesSuccess | SearchGenesFail | ClearGenes;
