import { createSelector, MemoizedSelector } from '@ngrx/store';
import * as fromReducer from '../reducers/last-used-filter.reducer';
import { LastUsedFilterState } from '../reducers/last-used-filter.reducer';
import * as fromFeature from '../reducers';
import { FiltersState } from '../reducers';

export const getLastUsedFilterState: MemoizedSelector<FiltersState, LastUsedFilterState> = createSelector(
  fromFeature.getFiltersState,
  (state: fromFeature.FiltersState) => state.lastUsedFilter,
);

export const getLastUsedFilterLoaded: MemoizedSelector<FiltersState, boolean> = createSelector(
  getLastUsedFilterState,
  fromReducer.getLastUsedFilterLoaded,
);

export const getLastUsedFilterLoading: MemoizedSelector<FiltersState, boolean> = createSelector(
  getLastUsedFilterState,
  fromReducer.getLastUsedFilterLoading,
);
