export * from './variant-interpretation.model';
export * from './cosmic-frequency';
export * from './snp-result.model';
export * from './sv-result.model';
export * from './zygosity.model';
export * from './variant-popup-selection.model';
export * from './variant-type.model';
export * from './frequencies.model';
export * from './ecs-result.model';
export * from './somatic-snp-result.model';
export * from './analysis-result.model';
export * from './variant-user-actions.model';
export * from './variant-highlights.model';
export * from './somatic-sv-result.model';
export * from './overgrowth-snp.result.model';
export * from './variant-warnings.model';
export * from './variant-label.model';
