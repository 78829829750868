<!--<div class="right-column" *ngIf="isAdmin">-->
<!--  <button class="cancel-btn" [disabled]="!formChanged" (click)="onCancel()">Cancel</button>-->
<!--  <button class="save-btn" [disabled]="!formChanged || orgForm.invalid" (click)="onSubmit()">-->
<!--    <span *ngIf="!(updateOrgInProgress$ | async)">Save</span>-->
<!--    <gnx-dots-loader class="align-center" *ngIf="updateOrgInProgress$ | async"></gnx-dots-loader>-->
<!--  </button>-->
<!--</div>-->

<div class="org-info-section">
  <div>
    <div class="title">Organization Info</div>
    <form [formGroup]="orgForm">
      <div class="input-container">
        <div>
          <label for="orgName" [ngClass]="{ red: f.orgName.dirty && f.orgName.errors }">Organization Name</label>
          <span class="red" *ngIf="f.orgName.dirty && f.orgName.errors?.required"> is required</span>
        </div>
        <input
          id="orgName"
          formControlName="orgName"
          placeholder="Name"
          (keydown.enter)="onSubmit()"
          disabled
          [ngClass]="{ red: f.orgName.dirty && f.orgName.errors }"
        />
        <span class="red note-msg" *ngIf="f.orgName.dirty && f.orgName.errors?.uniqueOrgViolation">
          {{ uniqueOrgViolationMsg }}
        </span>
      </div>
    </form>
  </div>
</div>

<div class="team-members-section">
  <div class="header">
    <div class="title">Team Settings</div>
    <div class="team-control">
      <button class="invite-btn" *ngIf="isAdmin" (click)="openInviteMemberDialog()">Invite Team Member</button>
      <div class="divider" *ngIf="isAdmin"></div>
      <div class="search-bar">
        <input
          typ="text"
          placeholder="Search team members..."
          #searchInput
          (input)="searchMembers$.next(searchInput.value)"
        />
        <img width="25" height="25" src="/assets/svg/search-blue.svg" />
      </div>
    </div>
  </div>
  <div class="team-members">
    <div class="team-members-loader" *ngIf="(teamMembers$ | async).loading">
      <gnx-dots-loader></gnx-dots-loader>
      <div>Loading team members</div>
    </div>
    <ng-container *ngIf="(teamMembers$ | async).loaded">
      <div class="member" [class.disabled]="user.loading" *ngFor="let user of (teamMembers$ | async).data">
        <ng-container [ngSwitch]="!!user.name">
          <ngx-avatar
            *ngSwitchCase="true"
            class="col-a"
            [bgColor]="'#007da8'"
            [size]="50"
            [name]="user.name"
          ></ngx-avatar>
          <div *ngSwitchCase="false" class="empty-avatar"></div>
        </ng-container>

        <div class="col-b">
          <span class="name">{{ user.name }}</span>
          <span class="email">{{ user.email }}</span>
        </div>
        <div class="col-c">
          <mat-select
            [value]="getOrgMemberRole(user)"
            (selectionChange)="onMemberRoleChange(user, $event)"
            [disabled]="!isAdmin"
          >
            <mat-option *ngFor="let type of USER_TYPE | keyvalue" [value]="type.value">
              {{ type.value }}
            </mat-option>
          </mat-select>
        </div>
        <div class="col-d" [ngSwitch]="user.status.toLowerCase()">
          <div *ngSwitchCase="'active'" class="active"><img height="12" src="/assets/svg/targeted.svg" />Active</div>
          <div *ngSwitchCase="'pending'" class="pending mr-1">
            <img width="18" height="18" src="/assets/svg/pending.svg" />Pending
          </div>
          <img
            *ngIf="isAdmin"
            class="delete-btn"
            src="/assets/svg/trash-can.svg"
            width="18"
            height="17"
            (click)="deleteMember(user)"
          />
        </div>

        <div class="loader" *ngIf="user.loading">
          <gnx-dots-loader class="align-center"></gnx-dots-loader>
        </div>
      </div>

      <div class="team-empty" *ngIf="!(teamMembers$ | async).data?.length">No team members found</div>
    </ng-container>
    <div *ngIf="!(teamMembers$ | async).loading && !(teamMembers$ | async).loaded">Error loading team members</div>
  </div>
</div>
