import * as fromSearch from './search.reducer';
import * as fromRoute from './route.reducer';
import * as fromAnalysisDetails from './analysis-details.reducer';
import * as fromRouter from '@ngrx/router-store';
import * as fromVariantsCount from './variants-count.reducer';
import * as fromPhenotypes from './phenotypes.reducer';
import * as fromPanels from './panels.reducer';
import * as fromCancerTypes from './cancer-types.reducer';
import * as fromEthnicity from './ethnicities.reducer';
import * as fromAssays from './assays.reducer';
import * as fromEnrichmentKits from './enrichment-kits.reducer';
import * as fromVariantsAuto from './variants-autocomplete.reducer';
import * as fromGenePanels from './gene-panels.reducer';
import * as fromGenes from './genes.reducer';
import * as fromVariantEvidencesSelection from './variant-evidences-selection.reducer';
import * as fromCustomFields from './case-custom-fields.reducer';
import * as fromOrganizationCustomSchema from './organization-custom-schema.reducer';
import * as fromPredictionOptions from './prediction-options.reducer';
import * as fromOrganizationConfig from './organization-config.reducer';

import { ActivatedRouteSnapshot, Params, RouterStateSnapshot } from '@angular/router';
import { ActionReducerMap } from '@ngrx/store';
import * as fromRichTemplate from './rich-template.reducer';

export interface AppModuleState {
  search: fromSearch.SearchVariantState;
  route: fromRoute.RouteState;
  phenotypes: fromPhenotypes.PhenotypesState;
  panelList: fromPanels.PanelsState;
  cancerTypes: fromCancerTypes.CancerTypesState;
  ethnicity: fromEthnicity.EthnicitiesState;
  routerReducer: fromRouter.RouterReducerState<RouterStateUrl>;
  analysisDetails: fromAnalysisDetails.AnalysisDetailsState;
  variantsCount: fromVariantsCount.VariantsCountState;
  assays: fromAssays.AssaysState;
  enrichmentKits: fromEnrichmentKits.EnrichmentKitsState;
  variantsAutocomplete: fromVariantsAuto.State;
  genePanels: fromGenePanels.GenePanelsState;
  genes: fromGenes.GenesState;
  variantEvidencesSelection: fromVariantEvidencesSelection.VariantEvidencesSelectionState;
  caseCustomFields: fromCustomFields.CaseCustomFieldsState;
  organizationCustomSchema: fromOrganizationCustomSchema.OrganizationCustomSchemaState;
  predictionOptions: fromPredictionOptions.PredictionOptionsState;
  organizationConfig: fromOrganizationConfig.OrganizationConfigState;
  [fromRichTemplate.richTemplateFeatureKey]: fromRichTemplate.State;
}

export const getAppModuleState = (state: AppModuleState) => state;

export const appReducers: ActionReducerMap<AppModuleState> = {
  search: fromSearch.reducer,
  route: fromRoute.reducer,
  phenotypes: fromPhenotypes.phenotypesReducer,
  panelList: fromPanels.panelsReducer,
  cancerTypes: fromCancerTypes.reducer,
  ethnicity: fromEthnicity.reducer,
  routerReducer: fromRouter.routerReducer,
  analysisDetails: fromAnalysisDetails.reducer,
  variantsCount: fromVariantsCount.reducer,
  assays: fromAssays.reducer,
  enrichmentKits: fromEnrichmentKits.reducer,
  variantsAutocomplete: fromVariantsAuto.reducer,
  genePanels: fromGenePanels.reducer,
  genes: fromGenes.reducer,
  variantEvidencesSelection: fromVariantEvidencesSelection.reducer,
  caseCustomFields: fromCustomFields.reducer,
  organizationCustomSchema: fromOrganizationCustomSchema.reducer,
  predictionOptions: fromPredictionOptions.reducer,
  organizationConfig: fromOrganizationConfig.reducer,
  [fromRichTemplate.richTemplateFeatureKey]: fromRichTemplate.reducer,
};

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
  data: any;
  path: string;
  parentPath: string;
}

export class CustomSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;

    let { params } = state;

    while (state.firstChild) {
      state = state.firstChild;

      params = {
        ...params,
        ...state.params,
      };
    }
    const { data } = state;
    const { path } = state.url.length > 0 ? state.url[0] : { path: '' };
    const parentPath = state.parent?.url.length > 0 ? state.parent.url[0].path : '';

    return { url, queryParams, params, data, path, parentPath };
  }
}
