import { VariantDetails } from './variant-details.model';

export enum BannerType {
  ExpertOpinion = 'expert_opinion',
  PTC_ADDCD = 'ptc_addcd',
  IQVIA_EDA = 'IQVIA_EDA',
  IQVIA_FPLD = 'IQVIA_FPLD',
}

export interface BannerDataPGX {
  gene?: string;
  variant?: VariantDetails;
}

export type BannerData = BannerDataPGX; // later when there are more Banner types with data, this should be a union of all
