import { FilterTree, FilterTreeInfo, FilterTreeLabels, FilterTreeOptions } from '../models/filter-tree.model';
import { createReducer, on } from '@ngrx/store';

import * as fromAction from '../actions/filter-tree.actions';
import * as fromPredefinedFilterAction from './../actions/predefined-filters.action';
import * as fromLastUsedFilterAction from './../actions/last-used-filter.action';

export interface FilterTreeState {
  filterTreeDefinition: FilterTree;
  filterTreeDefinitionLoading: boolean;
  filterTreeDefinitionLoaded: boolean;
  filterTrees: FilterTreeInfo[];
  filterTreesLoading: boolean;
  filterTreesLoaded: boolean;
  filterTreeOptions: FilterTreeOptions;
  filterTreeOptionsLoading: boolean;
  filterTreeOptionsLoaded: boolean;
  filterTreeLabels: FilterTreeLabels;
  filterTreeLabelsLoading: boolean;
  filterTreeLabelsLoaded: boolean;
  filterTreeLabelsLoadingError: any;
  newFilterTree: FilterTree;
}

const initialState: FilterTreeState = {
  filterTreeDefinition: undefined,
  filterTreeDefinitionLoading: false,
  filterTreeDefinitionLoaded: false,
  filterTrees: undefined,
  filterTreesLoading: false,
  filterTreesLoaded: false,
  filterTreeOptions: undefined,
  filterTreeOptionsLoading: false,
  filterTreeOptionsLoaded: false,
  filterTreeLabels: undefined,
  filterTreeLabelsLoading: false,
  filterTreeLabelsLoaded: false,
  newFilterTree: undefined,
  filterTreeLabelsLoadingError: undefined,
};

const filterTreeReducer = createReducer(
  initialState,
  on(fromAction.loadFilterTreeDefinition, (state) => ({
    ...state,
    filterTreeDefinition: undefined,
    filterTreeDefinitionLoading: true,
    filterTreeDefinitionLoaded: false,
  })),
  on(fromAction.loadFilterTreeDefinitionSuccess, (state, { payload }) => ({
    ...state,
    filterTreeDefinition: payload,
    filterTreeDefinitionLoading: false,
    filterTreeDefinitionLoaded: true,
  })),
  on(fromAction.loadFilterDefinitionWithNewFilter, (state) => ({
    ...state,
    filterTreeDefinition: !!state.newFilterTree
      ? {
          ...state.newFilterTree,
          tree: {
            ...state.newFilterTree.tree,
            nodes: state.newFilterTree.tree.nodes || [],
            start_node_ids: state.newFilterTree.tree.start_node_ids || [],
          },
        }
      : { tree: { tree_id: '', name: 'New filter', nodes: [], start_node_ids: [], use_phenotypes_as_filter: false } },
  })),
  on(fromAction.loadFilterTreeDefinitionFail, (state, { error }) => ({
    ...state,
    filterTreeDefinitionLoaded: false,
    filterTreeDefinitionLoading: false,
  })),
  on(fromAction.loadFilterTrees, (state) => ({
    ...state,
    filterTrees: undefined,
    filterTreesLoading: true,
    filterTreesLoaded: false,
  })),
  on(fromAction.loadFilterTreesSuccess, (state, { payload }) => ({
    ...state,
    filterTrees: payload,
    filterTreesLoading: false,
    filterTreesLoaded: true,
  })),
  on(fromAction.loadFilterTreesFail, (state, { error }) => ({
    ...state,
    filterTreesLoaded: false,
    filterTreesLoading: false,
  })),
  on(fromAction.loadFilterTreeOptions, (state) => ({
    ...state,
    filterTreeOptions: undefined,
    filterTreeOptionsLoading: true,
    filterTreeOptionsLoaded: false,
  })),
  on(fromAction.loadFilterTreeOptionsSuccess, (state, { payload }) => ({
    ...state,
    filterTreeOptions: payload,
    filterTreeOptionsLoading: false,
    filterTreeOptionsLoaded: true,
  })),
  on(fromAction.loadFilterTreeOptionsFail, (state, { error }) => ({
    ...state,
    filterTreeOptionsLoaded: false,
    filterTreeOptionsLoading: false,
  })),
  on(fromAction.loadFilterTreeLabels, (state) => ({
    ...state,
    filterTreeLabels: undefined,
    filterTreeLabelsLoading: true,
    filterTreeLabelsLoaded: false,
    filterTreeLabelsLoadingError: undefined,
  })),
  on(fromAction.loadFilterTreeLabelsSuccess, (state, { payload }) => ({
    ...state,
    filterTreeLabels: payload,
    filterTreeLabelsLoading: false,
    filterTreeLabelsLoaded: true,
  })),
  on(fromAction.loadFilterTreeLabelsFail, (state, { error }) => ({
    ...state,
    filterTreeLabelsLoaded: false,
    filterTreeLabelsLoading: false,
    filterTreeLabelsLoadingError: error,
  })),
  on(fromAction.createFilterTreeSuccess, (state, { tree }) => ({
    ...state,
    newFilterTree: tree,
  })),
  on(fromAction.markFilterTreeVerifiedSuccess, (state, { filterTreeId, analysisId, value }) => {
    return {
      ...state,
      filterTreeLabels: {
        ...state.filterTreeLabels,
        labels: (state.filterTreeLabels?.labels || []).map((x) =>
          x.label_id === filterTreeId
            ? {
                ...x,
                is_checked: value,
              }
            : x,
        ),
        gv_labels: (state.filterTreeLabels?.gv_labels || []).map((x) =>
          x.label_id === filterTreeId
            ? {
                ...x,
                is_verified: value,
              }
            : x,
        ),
      },
    };
  }),
  on(fromAction.deleteFilterTreeSuccess, (state, { filterTreeId }) => {
    return {
      ...state,
      filterTrees: state.filterTrees.filter((x) => x.id !== filterTreeId),
    };
  }),
  on(fromAction.saveUpdatedFilterTree, (state, { tree }) => ({
    ...state,
    filterTrees: state.filterTrees.map((x) => (x.id === tree.tree.tree_id ? { ...x, inSave: true } : x)),
  })),
  on(fromAction.saveUpdatedFilterTreeSuccess, (state, { tree }) => ({
    ...state,
    filterTrees: state.filterTrees.map((x) => (x.id === tree.id ? tree : x)),
  })),
  on(fromAction.saveUpdatedFilterTreeFail, (state, { tree_id }) => ({
    ...state,
    filterTrees: state.filterTrees.map((x) => (x.id === tree_id ? { ...x, inSave: false } : x)),
  })),
);

export function reducer(
  state: FilterTreeState | undefined,
  action: fromPredefinedFilterAction.PredefinedFiltersAction | fromLastUsedFilterAction.LastUsedFilterAction
): FilterTreeState {
  if (action.type === fromPredefinedFilterAction.UPDATE_QUICK_FILTERS_METADATA_SUCCESS) {
    return {
      ...state,
      filterTreeLabels: {
        ...state.filterTreeLabels,
      labels: state.filterTreeLabels?.labels?.map((label) => ({
        ...label,
        sort_value:
          action.payload.tree_labels_metadata?.find((x) => x.label_id === label.label_id)?.sort_value ?? label.sort_value,
        quick_filter:
          action.payload.tree_labels_metadata?.find((x) => x.label_id === label.label_id)?.quick_filter ?? label.quick_filter,
      })),
      quick_filter_labels: state.filterTreeLabels?.quick_filter_labels?.map((label) => ({
        ...label,
        sort_value:
          action.payload.quick_filters_metadata?.find((x) => x.quick_filter_id.toString() === label.label_id)?.sort_value ?? label.sort_value,
        quick_filter:
          action.payload.quick_filters_metadata?.find((x) => x.quick_filter_id.toString() === label.label_id)?.quick_filter ?? label.quick_filter,
      })),
      gv_labels: state.filterTreeLabels?.gv_labels?.map((label) => ({
        ...label,
        sort_value:
          action.payload.quick_filters_metadata?.find((x) => x.quick_filter_id.toString() === label.label_id)?.sort_value ?? label.sort_value,
        is_visible:
          action.payload.quick_filters_metadata?.find((x) => x.quick_filter_id.toString() === label.label_id)?.quick_filter ?? label.is_visible,
      })),
      },
    };
  } else if (action.type === fromPredefinedFilterAction.UPDATE_QUICK_FILTERS_METADATA_FAIL) {
    return {
      ...state,
      filterTreeLabels: {
        ...state.filterTreeLabels,
        labels: [...state.filterTreeLabels?.labels || []],
      }
    };
  } else if (action.type === fromPredefinedFilterAction.DELETE_SAVED_FILTER_SUCCESS) {
    return {
      ...state,
      filterTreeLabels: {
        ...state.filterTreeLabels,
        gv_labels: state.filterTreeLabels?.gv_labels?.filter((x) => x.label_id !== action.filterId.toString()),
      },
    };
  } else if (action.type === fromLastUsedFilterAction.LOAD_LAST_USED_FILTER_AND_STRUCTURE) {
    return {
      ...state,
      filterTreeLabels: undefined,
      filterTreeLabelsLoading: true,
      filterTreeLabelsLoaded: false,
      filterTreeLabelsLoadingError: undefined,
    };
  }
  return filterTreeReducer(state, action);
}
