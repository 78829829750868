import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AppModuleState, getAppModuleState } from '../reducers';
import { CaseCustomFieldsState } from '../reducers/case-custom-fields.reducer';
import { DynamicInputField } from '../../shared/models';

const getCaseCustomFieldsState: MemoizedSelector<AppModuleState, CaseCustomFieldsState> = createSelector(
  getAppModuleState,
  (state: AppModuleState) => state.caseCustomFields,
);

export const getCaseCustomFieldsData = () =>
  createSelector(getCaseCustomFieldsState, (state): CaseCustomFieldsState => state);

export const getCaseCustomFields = (context = 'fields') =>
  createSelector(getCaseCustomFieldsState, (state): DynamicInputField[] => state[context]);
