import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import * as fromAction from '../actions/interpretation-custom-fields.action';
import { InterpretationService } from '../../services/interpretation.service';
import { select, Store } from '@ngrx/store';
import { AppModuleState, getAnalysisType } from '../../../../../../store';

@Injectable()
export class InterpretationCustomFieldsEffect {
  constructor(
    private actions$: Actions,
    private service: InterpretationService,
    private rootStore$: Store<AppModuleState>,
  ) {}

  getInterpretationCustomFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GET_INTERPRETATION_CUSTOM_FIELDS),
      switchMap((action: fromAction.GetInterpretationCustomFields) =>
        this.service.getInterpretationCustomFields(action.variantId, null).pipe(
          map((fields) => new fromAction.GetInterpretationCustomFieldsSuccess(fields)),
          catchError((error) => of(new fromAction.GetInterpretationCustomFieldsFail(error))),
        ),
      ),
    ),
  );
}
