import { createAction, props } from '@ngrx/store';
import { AnalysisBatch, AnalysisLeanListResponse, DataModel } from '../models';

export const loadAnalysisBatchList = createAction('[Analyses] Load analysis batch list');

export const loadAnalysisBatchListSuccess = createAction(
  '[Analyses] Load analysis batch list success',
  props<{ payload: DataModel<AnalysisBatch> }>(),
);

export const loadAnalysisBatchListFail = createAction(
  '[Analyses] Load analysis batch list fail',
  props<{ error: any }>(),
);

export const loadAnalysisBatchNextPage = createAction('[Analyses] Load analysis batch next page');

export const loadAnalysisBatchAnalysisList = createAction(
  '[Analyses] Load analysis batch analysis list',
  props<{ batchId?: number }>(),
);

export const loadAnalysisBatchAnalysisListSuccess = createAction(
  '[Analyses] load analysis batch analysis list success',
  props<{ batchId: number; payload: AnalysisLeanListResponse }>(),
);

export const loadAnalysisBatchAnalysisListFail = createAction(
  '[Analyses] load analysis batch analysis list fail',
  props<{ batchId: number; error: any }>(),
);

export const loadAnalysisBatchAnalysisListNextPage = createAction(
  '[Analyses] Load analysis batch analysis list next page',
  props<{ batchId?: number; nextPageUrl: string }>(),
);
