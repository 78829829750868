import { BannerType, SearchConfig, VariantId } from '../../models';
import { Action } from '@ngrx/store';

export const HIDE_BANNER = '[Variant Page] Hide Banner';

export class HideBanner implements Action {
  readonly type = HIDE_BANNER;

  constructor(public bannerType: BannerType) {}
}

export const REQUEST_EXPERT_OPINION = '[Variant Page] Request expert opinion';
export const REQUEST_EXPERT_OPINION_SUCCESS = '[Variant Page] Request expert opinion success';
export const REQUEST_EXPERT_OPINION_FAIL = '[Variant Page] Request expert opinion fail';

export class RequestExpertOpinion implements Action {
  readonly type = REQUEST_EXPERT_OPINION;

  constructor(
    public variantId: VariantId,
    public details: string,
    public caseContext: SearchConfig,
  ) {}
}

export class RequestExpertOpinionSuccess implements Action {
  readonly type = REQUEST_EXPERT_OPINION_SUCCESS;

  constructor(public variantId: VariantId) {}
}

export class RequestExpertOpinionFail implements Action {
  readonly type = REQUEST_EXPERT_OPINION_FAIL;

  constructor(
    public variantId: VariantId,
    error: any,
  ) {}
}

export type AppBannerAction =
  | HideBanner
  | RequestExpertOpinion
  | RequestExpertOpinionSuccess
  | RequestExpertOpinionFail;
