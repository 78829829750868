<svg
  width="13px"
  height="13px"
  viewBox="0 0 13 13"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
>
  <title>6A2EBB14-D686-424F-BCD9-FF716B7FC490</title>
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Filters-style-guide" transform="translate(-1557, -152)">
      <g id="filter/collapsed-copy-20" transform="translate(1542, 80)">
        <g id="CheckBox-pressed-Copy-2" transform="translate(15, 72)">
          <rect id="Rectangle-4" fill="#E03A39" x="0" y="0" width="13" height="13" rx="2"></rect>
          <rect
            id="Rectangle"
            fill="#FFFFFF"
            transform="translate(6, 6) rotate(-270) translate(-6, -6)"
            x="5.5"
            y="1.5"
            width="2"
            height="8"
            rx="1"
          ></rect>
        </g>
      </g>
    </g>
  </g>
</svg>
