import { Action } from '@ngrx/store';
import { GenePhenotypesResponse, Phenotype } from '../models';

export const GET_PHENOTYPES = '[Root] Get phenotypes';
export const GET_PHENOTYPES_FAIL = '[Root] Get phenotypes fail';
export const GET_PHENOTYPES_SUCCESS = '[Root] Get phenotypes success';
export const CLEAR_PHENOTYPES = '[Root] Clear phenotypes';
export const GET_GENE_PHENOTYPES = '[Root] Get gene phenotypes';
export const GET_GENE_PHENOTYPES_FAIL = '[Root] Get gene phenotypes fail';
export const GET_GENE_PHENOTYPES_SUCCESS = '[Root] Get gene phenotypes success';
export const CLEAR_GENE_PHENOTYPES = '[Root] Clear gene phenotypes';

export class GetPhenotypes implements Action {
  readonly type = GET_PHENOTYPES;

  constructor(
    public payload: string,
    public queryType: ('phenotypes' | 'diseases')[] = [],
  ) {}
}

export class GetPhenotypesFail implements Action {
  readonly type = GET_PHENOTYPES_FAIL;

  constructor(public payload: any) {}
}

export class GetPhenotypesSuccess implements Action {
  readonly type = GET_PHENOTYPES_SUCCESS;

  constructor(
    public payload: Phenotype[],
    public searchTerm: string,
  ) {}
}

export class GetGenePhenotypes implements Action {
  readonly type = GET_GENE_PHENOTYPES;

  constructor(
    public searchTerm: string,
    public gene: string,
    public queryType: ('phenotypes' | 'diseases')[] = [],
  ) {}
}

export class GetGenePhenotypesFail implements Action {
  readonly type = GET_GENE_PHENOTYPES_FAIL;

  constructor(public payload: any) {}
}

export class GetGenePhenotypesSuccess implements Action {
  readonly type = GET_GENE_PHENOTYPES_SUCCESS;

  constructor(public payload: GenePhenotypesResponse) {}
}

export class ClearPhenotypes implements Action {
  readonly type = CLEAR_PHENOTYPES;
}

export class ClearGenePhenotypes implements Action {
  readonly type = CLEAR_GENE_PHENOTYPES;
}

export type PhenotypesAction =
  | GetPhenotypes
  | GetPhenotypesSuccess
  | GetPhenotypesFail
  | ClearPhenotypes
  | GetGenePhenotypes
  | GetGenePhenotypesSuccess
  | GetGenePhenotypesFail
  | ClearGenePhenotypes;
