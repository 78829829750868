export interface SampleQC {
  sample: {
    name: string;
    id: string;  // this is actually single_analysis_id of the family member or same analysis id if single case
    assay: string;
    created_at: Date;
    annotation_version: number;
    roh: QCRohData;
    batch?: {
      id: number;
      name: string;
    };
    reference_genome: string;
  };
  metric_types: {
    type: string;
    display_name: string;
    metrics: QCMetric[];
  }[];
}

export interface QCRohData {
  intervals: {
    range: {
      chrom: string;
      start: number;
      end: number;
    };
    hom_variants_count: number;
    het_variants_count: number;
    total_variants_count: number;
  }[];
}

export interface QCMetric {
  name: string;
  value: string | number;
  type: QCMetricType;
  status: string;
  options: {
    min: number;
    max: number;
    value_type: QCMetricValueType;
    valid_ranges: {
      start: number;
      end: number;
    }[];
  };
  details: string;
  number_value_decimal_points?: number;
}

export enum QCMetricType {
  Text = 'text',
  Range = 'range',
}

export enum QCMetricValueType {
  Num = 'number',
  Percentage = 'percentage',
}

export interface ReferralNotes {
  notes: string;
}

export interface AnalysisFileForDownload {
  name: string;
  url: string;
}

export interface ResultsFile {
  s3_path: string;
}

export interface ExportFamilyCarrierTests {
  analysis_id: number;
  proband_sample_id: string;
  proband_annotation_version: number;
  proband_single_analysis_id: number;
  mother_sample_id: string;
  mother_annotation_version: number;
  father_sample_id: string;
  father_annotation_version: number;
  num_samples_in_repository: number;
  exported_file_name?: string;
}

export interface BatchQcExportResponse {
  qc_ready: boolean;
  file_path?: string;
}

export enum CaseFilterType {
  Default = 'default',
  HighDetection = 'high-detection',
}

export enum VariantType {
  Gain = 'gain',
  Loss = 'loss',
  LOH = 'loh',
}

export interface CaseFilterOption {
  label: string;
  value: CaseFilterType;
}

export interface VariantTypeOption {
  label: string;
  key: VariantType;
}

export type CaseFilterData = {
  [key in CaseFilterType]: {
    [key in VariantType]: {
      markerCount: number;
      size: number;
    };
  };
};

export const CaseFilterOptions: CaseFilterOption[] = [
  {
    label: 'Default',
    value: CaseFilterType.Default,
  },
  {
    label: 'High detection',
    value: CaseFilterType.HighDetection,
  },
];
export const VariantTypes: VariantTypeOption[] = [
  {
    label: 'Gain:',
    key: VariantType.Gain,
  },
  {
    label: 'Loss:',
    key: VariantType.Loss,
  },
  {
    label: 'LOH:',
    key: VariantType.LOH,
  },
];
