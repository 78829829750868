import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AZURE_LOGIN,
  AzureLogin,
  AzureLoginFailed,
  CHANGE_PASSWORD,
  ChangePassword,
  ChangePasswordFailure,
  DETERMINE_USER_REGION,
  DetermineUserRegion,
  GET_AZURE_CONNECTION_NAME,
  GET_AZURE_CONNECTION_NAME_SUCCESS,
  GET_INVITEE_DATA,
  GetAzureConnectionName,
  GetAzureConnectionNameSuccess,
  GetInviteeData,
  GetInviteeDataFailure,
  GetInviteeDataSuccess,
  INVITE_USERS,
  InviteUsers,
  InviteUsersFailure,
  InviteUsersSuccess,
  LOAD_EXISTING_TOKEN,
  LOAD_USER_DETAILS,
  LOAD_USER_DETAILS_SUCCESS,
  LoadExistingToken,
  LoadExistingTokenFail,
  LoadExistingTokenSuccess,
  LoadUserDetails,
  LoadUserDetailsFail,
  LoadUserDetailsSuccess,
  Login,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_WITH_MFA,
  LoginFailure,
  LoginMFARequired,
  LoginSuccess,
  LoginWithMFA,
  Logout,
  LOGOUT,
  LogoutFailure,
  LogoutSuccess,
  QUIT_SIGN_UP,
  QuitSignUp,
  REDIRECT_AFTER_AUTH,
  RENEW_TOKEN,
  RENEW_TOKEN_FAIL,
  RenewTokenFail,
  RenewTokenSuccess,
  RESEND_EMAIL,
  ResendEmail,
  ResendEmailFailure,
  ResendEmailSuccess,
  SEND_RESET_EMAIL,
  SendResetEmail,
  SendResetEmailFailure,
  SET_INFO,
  SET_INFO_SUCCESS,
  SET_REDIRECT_AFTER_AUTH,
  SetInfo,
  SetInfoFailure,
  SetInfoSuccess,
  SetRedirectAfterAuth,
  SetSignUpStep,
  SIGN_UP,
  SignUp,
  SignUpSuccess,
  UPDATE_USER_INFO,
  UpdateUserInfo,
  UpdateUserInfoFail,
  UpdateUserInfoSuccess,
  UserRegionUpdate,
} from '../actions';
import { Observable, of, timer } from 'rxjs';
import { catchError, delay, filter, map, mergeMap, switchMap, tap, throttleTime, withLatestFrom } from 'rxjs/operators';
import { AuthApiService } from '../../services/auth-api.service';
import { select, Store } from '@ngrx/store';
import { AuthModuleState } from '../reducers';
import { getTokenDetails, getUserDetails } from '../selectors';
import { MFALoginResponse, TokenDetails, UserDetails } from '../../models';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../../environments/environment';
import { AzureErrors } from '../../../../login-page/containers/login-page/login-page.data';
import { OpenSnackbar } from '../../../../store/actions';
import { SnackbarMode } from '../../../../store/models/snackbar.model';
import * as momentImported from 'moment';

const moment = momentImported;

@Injectable()
export class AuthEffect {
  constructor(
    private actions: Actions,
    private authApiService: AuthApiService,
    private authStore: Store<AuthModuleState>,
    private matDialog: MatDialog,
    private router: Router,
  ) {}

  setRedirectAfterAuth$: Observable<any> = createEffect(
    () =>
      this.actions.pipe(
        ofType(SET_REDIRECT_AFTER_AUTH),
        tap((action: SetRedirectAfterAuth) => this.authApiService.setRedirectAfterAuth(action.url)),
      ),
    { dispatch: false },
  );

  quitSignUp$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(QUIT_SIGN_UP),
        tap(() => this.authApiService.clearRedirectAfterAuth()),
      ),
    { dispatch: false },
  );

  login$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(LOGIN),
      switchMap((action: Login) => {
        return this.authApiService.login(action.email, action.password).pipe(
          map((tokenDetails) =>
            tokenDetails.hasOwnProperty('mfa_token')
              ? new LoginMFARequired(
                  (tokenDetails as MFALoginResponse).mfa_token,
                  (tokenDetails as MFALoginResponse).mfa_enrollment_uri,
                )
              : new LoginSuccess(tokenDetails as TokenDetails),
          ),
          catchError((err: HttpErrorResponse) =>
            of(new LoginFailure(err?.error?.error || 'The email or password is not correct.')),
          ),
        );
      }),
    ),
  );

  loginWithMFA$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(LOGIN_WITH_MFA),
      switchMap((action: LoginWithMFA) => {
        return this.authApiService.loginWithMFA(action.email, action.mfaToken, action.otpCode).pipe(
          map((tokenDetails) => new LoginSuccess(tokenDetails)),
          catchError((err: HttpErrorResponse) =>
            of(new LoginFailure(err.error.error || 'Login failed. Perhaps the otp code is no longer valid?')),
          ),
        );
      }),
    ),
  );

  logout$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(LOGOUT),
      switchMap(() =>
        this.authApiService.logoutRemote().pipe(
          map(() => new LogoutSuccess()),
          catchError((error) => of(new LogoutFailure(error))),
        ),
      ),
    ),
  );

  logoutSuccess$: Observable<any> = createEffect(
    () =>
      this.actions.pipe(
        ofType(LOGOUT),
        tap(() => this.authApiService.logout()),
      ),
    { dispatch: false },
  );

  // @Effect()
  // logoutFail$: Observable<any> = this.actions.pipe(
  //   ofType(LOGOUT_FAILURE),
  //   switchMap(() =>
  //     of(new OpenSnackbar('An internal error occurred while logging out, you might have to logout again later', SnackbarMode.Error))
  //   )
  // )

  loadExistingToken$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(LOAD_EXISTING_TOKEN),
      switchMap(() =>
        this.authApiService.getStoredTokenDetails().pipe(
          switchMap((tokenDetails) => [new LoadExistingTokenSuccess(tokenDetails), new LoadUserDetails()]),
          catchError((err: HttpErrorResponse) => of(new LoadExistingTokenFail(err.error.error))),
        ),
      ),
    ),
  );

  loadUserDetails$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(LOAD_USER_DETAILS),
      delay(0),
      switchMap((action) =>
        this.authApiService.fetchUserDetails().pipe(
          tap((userDetails) => {
            this.authApiService.setUserDetailsToLocalStorage(userDetails);
            if (!userDetails.organization && moment().diff(new Date(userDetails.created_at), 'seconds') > 60) {
              const noop = this.router.navigate(['/post-join'], { queryParams: { step: 1, midway: true } });
            }
          }),
          tap((userDetails: UserDetails) => {
            if (
              userDetails.organization?.permitted_domains?.length &&
              !userDetails.organization?.permitted_domains.includes(window.location.host)
            ) {
              const url = `${window.location.protocol}//${userDetails.organization?.permitted_domains[0]}${window.location.pathname}`;
              window.location.replace(url);
              // this.matDialog.open<EnvRedirectPopupComponent, EnvRedirectData>(
              //   EnvRedirectPopupComponent,
              //   {
              //     width: '630px',
              //     height: '510px',
              //     backdropClass: 'error-popup-backdrop',
              //     panelClass: 'error-popup-panel',
              //     hasBackdrop: true,
              //     disableClose: true,
              //     data: {
              //       redirectHost: userDetails.organization?.permitted_domains[0],
              //     },
              //   }
              // );
            }
          }),
          map((userDetails) => new LoadUserDetailsSuccess(userDetails)),
          catchError((err: HttpErrorResponse) => of(new LoadUserDetailsFail(err.error.error))),
        ),
      ),
    ),
  );

  showSessionTimeout$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(LOAD_USER_DETAILS_SUCCESS),
      filter((action: LoadUserDetailsSuccess) => !!action.userDetails.org_config?.userConfig?.sessionTimeoutSec),
      switchMap((action) =>
        timer((action.userDetails.org_config.userConfig.sessionTimeoutSec - 600) * 1000).pipe(
          map(() => new OpenSnackbar('Session timeout expected in 10 minutes', SnackbarMode.Error, null, 60000)),
        ),
      ),
    ),
  );

  renewToken$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(RENEW_TOKEN),
      throttleTime(1000),
      withLatestFrom(this.authStore.pipe(select(getTokenDetails))),
      switchMap(([, td]) =>
        this.authApiService.renewToken(td).pipe(
          map((tokenDetails) => new RenewTokenSuccess(tokenDetails)),
          catchError((err) => of(new RenewTokenFail(err))),
        ),
      ),
    ),
  );

  renewTokenFail$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(RENEW_TOKEN_FAIL),
      map(() => new Logout()),
    ),
  );

  redirectAfterLoginSuccess$: Observable<any> = createEffect(
    () =>
      this.actions.pipe(
        ofType(LOGIN_SUCCESS),
        filter((action: LoginSuccess) => !action.dontRedirect),
        tap(() => this.authApiService.redirectAfterAuth()),
      ),
    { dispatch: false },
  );

  redirectAfterAuth$: Observable<any> = createEffect(
    () =>
      this.actions.pipe(
        ofType(REDIRECT_AFTER_AUTH),
        tap(() => this.authApiService.redirectAfterAuth()),
      ),
    { dispatch: false },
  );

  signUp$ = createEffect(() =>
    this.actions.pipe(
      ofType(SIGN_UP),
      switchMap((action: SignUp) => {
        return this.authApiService.signUp(action.email, action.password, action.inviteeId).pipe(
          tap(() => {
            if (!action.fromPopup) {
              this.router.navigate(['/post-join'], { queryParams: { step: 1 } });
            } else {
              this.authStore.dispatch(new SetSignUpStep(1));
            }
          }),
          switchMap(() => [new LoadExistingToken(), new SignUpSuccess()]),
          catchError((err: HttpErrorResponse) => {
            this.matDialog.closeAll();
            this.router.navigate(['/login'], { queryParams: { fromSignUp: true } });
            return of(new QuitSignUp());
          }),
        );
      }),
    ),
  );

  determineUserRegion$ = createEffect(() =>
    this.actions.pipe(
      ofType(DETERMINE_USER_REGION),
      switchMap((action: DetermineUserRegion) => {
        return this.authApiService.determineUserRegion().pipe(
          map((region) => {
            return new UserRegionUpdate(region);
          }),
        );
      }),
    ),
  );

  setInfo$ = createEffect(() =>
    this.actions.pipe(
      ofType(SET_INFO),
      withLatestFrom(this.authStore.pipe(select(getUserDetails))),
      switchMap(([action, user]: [SetInfo, UserDetails]) => {
        return this.authApiService
          .setInfo(user.auth0_id, user.email, action.fullName, action.role, action.organization, action.organizationId)
          .pipe(
            map(() => new SetInfoSuccess()),
            tap(() => {
              if (!action.fromPopup) {
                this.router.navigate(['/post-join'], { queryParams: { step: action.organizationId ? 4 : 2 } });
              } else {
                this.authStore.dispatch(new SetSignUpStep(2));
              }
            }),
            catchError((err: HttpErrorResponse) => of(new SetInfoFailure(err.error.error || 'Already exists'))),
          );
      }),
    ),
  );

  setInfoSuccess$ = createEffect(() =>
    this.actions.pipe(
      ofType(SET_INFO_SUCCESS),
      switchMap(() => [new LoadUserDetails()]),
    ),
  );

  inviteUsers$ = createEffect(() =>
    this.actions.pipe(
      ofType(INVITE_USERS),
      withLatestFrom(this.authStore.pipe(select(getUserDetails))),
      switchMap(([action, user]: [InviteUsers, UserDetails]) => {
        return this.authApiService
          .inviteUsers(user.email, user.organization.id, user.organization.name, action.emails)
          .pipe(
            tap(() => {
              if (action.fromSettings) {
                return;
              }

              if (!action.fromPopup) {
                this.router.navigate(['/post-join'], { queryParams: { step: 3 } });
              } else {
                this.authStore.dispatch(new SetSignUpStep(3));
              }
            }),
            mergeMap(() => [new QuitSignUp(), new InviteUsersSuccess()]),
            catchError((err: HttpErrorResponse) => of(new InviteUsersFailure(err.error.error || 'Invite failed'))),
          );
      }),
    ),
  );

  resendEmail$ = createEffect(() =>
    this.actions.pipe(
      ofType(RESEND_EMAIL),
      withLatestFrom(this.authStore.pipe(select(getUserDetails))),
      switchMap(([action, user]: [ResendEmail, UserDetails]) => {
        return this.authApiService.resendEmail(action.email || user.email).pipe(
          map(() => new ResendEmailSuccess()),
          catchError((err: HttpErrorResponse) => of(new ResendEmailFailure(err.error.error || 'Could not resend'))),
        );
      }),
    ),
  );

  sendResetEmail$ = createEffect(() =>
    this.actions.pipe(
      ofType(SEND_RESET_EMAIL),
      switchMap((action: SendResetEmail) => {
        return this.authApiService.sendResetEmail(action.email).pipe(
          tap(() => this.router.navigate(['/reset'], { queryParams: { step: 1 } })),
          map(() => new QuitSignUp()),
          catchError((err: HttpErrorResponse) => of(new SendResetEmailFailure(err.error.error || 'Email not found'))),
        );
      }),
    ),
  );

  changePassword = createEffect(() =>
    this.actions.pipe(
      ofType(CHANGE_PASSWORD),
      switchMap((action: ChangePassword) => {
        return this.authApiService
          .changePassword(action.email, action.userId, action.password, action.oldPassword, action.token)
          .pipe(
            tap(() => this.router.navigate(['/reset'], { queryParams: { step: 4 } })),
            map(() => new QuitSignUp()),
            catchError((err: HttpErrorResponse) =>
              of(new ChangePasswordFailure(err.error.errors || 'Wrong old password')),
            ),
          );
      }),
    ),
  );

  getInviteeData$ = createEffect(() =>
    this.actions.pipe(
      ofType(GET_INVITEE_DATA),
      switchMap((action: GetInviteeData) => {
        return this.authApiService.getInviteeData(action.inviteId).pipe(
          map((data) => new GetInviteeDataSuccess(data)),
          catchError((err: HttpErrorResponse) => of(new GetInviteeDataFailure(err.error.error || 'Invitee not found'))),
        );
      }),
    ),
  );

  updateUserInfo$ = createEffect(() =>
    this.actions.pipe(
      ofType(UPDATE_USER_INFO),
      switchMap((action: UpdateUserInfo) =>
        this.authApiService.updateUserInfo(action.name, action.role).pipe(
          mergeMap((res) => [new UpdateUserInfoSuccess(), new LoadUserDetails()]),
          catchError((err) => of(new UpdateUserInfoFail(err))),
        ),
      ),
    ),
  );

  azureLogin$ = createEffect(() =>
    this.actions.pipe(
      ofType(AZURE_LOGIN),
      switchMap((action: AzureLogin) => [new GetAzureConnectionName(action.email, action.dontRedirect)]),
    ),
  );

  getAzureConnectionName$ = createEffect(() =>
    this.actions.pipe(
      ofType(GET_AZURE_CONNECTION_NAME),
      switchMap((action: GetAzureConnectionName) =>
        this.authApiService.getAzureConnectionName(action.email).pipe(
          mergeMap((res) => {
            if (res.connection_name) {
              return of(new GetAzureConnectionNameSuccess(res, action.dontRedirect));
            } else {
              return of(new AzureLoginFailed(AzureErrors.notAvailable));
            }
          }),
          catchError((err) => of(new AzureLoginFailed(AzureErrors.general))),
        ),
      ),
    ),
  );

  getAzureConnectionNameSuccess$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(GET_AZURE_CONNECTION_NAME_SUCCESS),
        tap((action: GetAzureConnectionNameSuccess) => {
          const promptLogin = action.payload?.force_login ? '&prompt=login' : '';
          const link = `https://${environment.authDomain}/authorize?response_type=token&client_id=${environment.authClientId}&connection=${action.payload.connection_name}&redirect_uri=${environment.authCallback}&scope=openid${promptLogin}`;
          const size = {
            width: 500,
            height: 500,
            left: screen.width / 2 - 500 / 2,
            top: screen.height / 2 - 500 / 2,
          };
          const authPopup = window.open(
            link,
            'newwindow',
            `width=${size.width},height=${size.height}, top=${size.top}, left=${size.left}`,
          );

          const timer = setInterval(() => {
            if (authPopup.closed) {
              clearInterval(timer);
              this.authStore.dispatch(new AzureLoginFailed(AzureErrors.general));
            }
          }, 1000);

          const bc = new BroadcastChannel('azure-auth-popup-channel');
          bc.onmessage = (ev: any) => {
            if (ev.data.accessToken) {
              this.authStore.dispatch(new LoginSuccess(ev.data, action.dontRedirect));
              authPopup.close();
              bc.close();
              if (timer) {
                clearInterval(timer);
              }
            }
          };
        }),
      ),
    { dispatch: false },
  );
}
