import { AnalysisType, AssayAnalysisType } from '../../../store/models';
import { ExtendedAssayListEntity } from '../../../store/models/assay.model';

export enum CaseType {
  SINGLE = 'Single Sample',
  FAMILY = 'Family',
  REPORT = 'Report',
  TUMOR = 'Tumor',
  CMA = 'CMA',
  SAMPLE_SHEET = 'Sample Sheet',
}

export const analysisTypeToCaseType = (analysisType: AnalysisType): CaseType => {
  switch (analysisType) {
    case AnalysisType.family:
      return CaseType.FAMILY;
    case AnalysisType.sample:
      return CaseType.SINGLE;
    case AnalysisType.tumor:
      return CaseType.TUMOR;
    default:
      return CaseType.SINGLE;
  }
};

export const caseTypeToAnalysisType = (caseType: CaseType): AnalysisType | undefined => {
  const mapper = {
    [CaseType.SINGLE]: AnalysisType.sample,
    [CaseType.CMA]: AnalysisType.sample,
    [CaseType.FAMILY]: AnalysisType.family,
    [CaseType.TUMOR]: AnalysisType.tumor,
  };

  return mapper?.[caseType] || undefined;
};

export const caseTypeToAssayAnalysisTypes = (caseType: CaseType): AssayAnalysisType[] => {
  const mapper: { [key in CaseType]?: AssayAnalysisType[]} = {
    [CaseType.SINGLE]: ['GERMLINE', 'PGX'],
    [CaseType.CMA]: ['CMA'],
    [CaseType.FAMILY]: ['GERMLINE'],
    [CaseType.TUMOR]: ['SOMATIC'],
  };

  return mapper?.[caseType];
};

export enum ReportType {
  TUMOR = 'Tumor',
  GERMLINE = 'Germline',
}

export enum CMAType {
  CNV = 'CNV',
  ROH = 'ROH',
}

export interface CaseTypeOption {
  caseType?: CaseType | ReportType | CMAType;
  name: string;
  title: string;
  img: string;
  disabled?: boolean;
  disabledTooltip?: string;
  imgClassList: string;
  customCaseType?: CustomCaseType;
  assay?: ExtendedAssayListEntity;
  routeAction?: string;
}

export enum CustomCaseType {
  DUO_FAMILY = 'Duo Family',
  SAMPLE_SHEET = 'Sample Sheet',
  BULK_UPLOAD = 'Sample Bulk Upload',
}

export const CaseTypeOptions: CaseTypeOption[] = [
  {
    caseType: CaseType.SINGLE,
    name: 'Inherited Disease<br>Single Case',
    title: 'Inherited Disease - Single Case',
    img: 'assets/svg/single-app.svg',
    imgClassList: 'bottom single',
  },
  {
    caseType: CaseType.FAMILY,
    name: 'Inherited Disease<br>Family Case',
    title: 'Inherited Disease - Family Case',
    img: 'assets/svg/family-app.svg',
    imgClassList: 'bottom',
  },
  {
    caseType: CaseType.REPORT,
    name: 'Shortlist of reported<br>variants',
    title: 'Manually Add Variants',
    img: 'assets/svg/reports.svg',
    imgClassList: 'center',
  },
  {
    caseType: CaseType.TUMOR,
    name: 'Tumor Case',
    title: 'Tumor Case',
    img: 'assets/svg/somatic-app.svg',
    imgClassList: 'center tumor',
  },
  {
    caseType: CaseType.CMA,
    name: 'Chromosomal Microarray',
    title: 'Case From {report_type}CMA',
    img: 'assets/svg/cma-reports.svg',
    imgClassList: 'center',
  },
];

export const CustomCaseTypeOptions: { [key: string]: CaseTypeOption } = {
  [CustomCaseType.DUO_FAMILY]: {
    caseType: CaseType.FAMILY,
    name: 'Duo Family Case',
    title: 'Duo Family',
    img: 'assets/svg/duo-app.svg',
    imgClassList: 'bottom',
    customCaseType: CustomCaseType.DUO_FAMILY,
  },
  [CustomCaseType.SAMPLE_SHEET]: {
    caseType: CaseType.SAMPLE_SHEET,
    name: 'Upload Sample Sheet',
    title: 'Sample Sheet',
    img: 'assets/svg/sample-sheets-case.svg',
    imgClassList: 'bottom',
    customCaseType: CustomCaseType.SAMPLE_SHEET,
  },
  [CustomCaseType.BULK_UPLOAD]: {
    name: 'Sample Bulk Upload<br/>(Premium Only)',
    title: 'Sample Bulk Upload',
    img: 'assets/svg/bulk-upload-wizard-option.svg',
    imgClassList: 'bottom',
    customCaseType: CustomCaseType.BULK_UPLOAD,
    routeAction: 'samples/upload',
    disabled: true,
    disabledTooltip: 'Available in Franklin Premium',
  },
};
