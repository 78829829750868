import { RouteAction, SET_CURRENT_ROUTE, SET_PREVIOUS_ROUTE } from '../actions';
import { NavigationEnd } from '@angular/router';

export interface RouteState {
  previousRoute: NavigationEnd;
  previousRouteUrl: string;
  previousAnalysesUrl: string;
}

const initialState: RouteState = {
  previousRoute: null,
  previousRouteUrl: null,
  previousAnalysesUrl: null,
};

export function reducer(state = initialState, action: RouteAction): RouteState {
  switch (action.type) {
    case SET_CURRENT_ROUTE: {
      let previousAnalysesUrl: string;
      if (!action.payload.url.includes('analysis-tool')) {
        previousAnalysesUrl = null;
      } else if (action.payload.url.includes('analyses')) {
        previousAnalysesUrl = action.payload.url;
      } else {
        previousAnalysesUrl = state.previousAnalysesUrl;
      }
      return {
        ...state,
        previousAnalysesUrl,
      };
    }
    case SET_PREVIOUS_ROUTE: {
      return {
        ...state,
        previousRoute:
          action.payload.url.includes('home') || action.payload.url.includes('my-variants')
            ? action.payload
            : state.previousRoute,
        previousRouteUrl: action.payload.url,
      };
    }
  }

  return state;
}

export const getPreviousRoute = (state: RouteState) => state.previousRoute;
export const getPreviousRouteUrl = (state: RouteState) => state.previousRouteUrl;
export const getPreviousAnalysesUrl = (state: RouteState) => state.previousAnalysesUrl;
