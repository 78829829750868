import { ActionReducerMap, createFeatureSelector, MemoizedSelector } from '@ngrx/store';

import * as fromAuth from './auth.reducer';
import * as fromOrg from './organization.reducer';

export interface AuthModuleState {
  auth: fromAuth.AuthState;
  organization: fromOrg.OrganizationState;
}

export const authReducers: ActionReducerMap<AuthModuleState> = {
  auth: fromAuth.authReducer,
  organization: fromOrg.reducer,
};

export const getAuthModuleState: MemoizedSelector<any, any> = createFeatureSelector('auth0');
