import { AnalysesDetailedStats, AnalysesStats } from '../models';
import { AnalysesSomaticDetailedStats } from '../models/analysis-somatic.model';
import { Action } from '@ngrx/store';
import { HttpParams } from '@angular/common/http';

export const GET_ANALYSES_STATS = '[Analyses] Get analyses stats';
export const GET_ANALYSES_STATS_FAIL = '[Analyses] Get analyses stats fail';
export const GET_ANALYSES_STATS_SUCCESS = '[Analyses] Get analyses stats success';

export class GetAnalysesStats implements Action {
  readonly type = GET_ANALYSES_STATS;

  constructor(public query?: HttpParams) {}
}

export class GetAnalysesStatsFail implements Action {
  readonly type = GET_ANALYSES_STATS_FAIL;

  constructor(public err: any) {}
}

export class GetAnalysesStatsSuccess implements Action {
  readonly type = GET_ANALYSES_STATS_SUCCESS;

  constructor(public payload: AnalysesStats | AnalysesDetailedStats) {}
}

export const GET_SOMATIC_ANALYSES_STATS = '[Analyses] Get Somatic analyses stats';
export const GET_SOMATIC_ANALYSES_STATS_FAIL = '[Analyses] Get Somatic analyses stats fail';
export const GET_SOMATIC_ANALYSES_STATS_SUCCESS = '[Analyses] Get Somatic analyses stats success';

export class GetSomaticAnalysesStats implements Action {
  readonly type = GET_SOMATIC_ANALYSES_STATS;

  constructor(public query?: HttpParams) {}
}

export class GetSomaticAnalysesStatsFail implements Action {
  readonly type = GET_SOMATIC_ANALYSES_STATS_FAIL;

  constructor(public err: any) {}
}

export class GetSomaticAnalysesStatsSuccess implements Action {
  readonly type = GET_SOMATIC_ANALYSES_STATS_SUCCESS;

  constructor(public payload: AnalysesSomaticDetailedStats) {}
}

export type AnalysisStatsAction =
  | GetAnalysesStats
  | GetAnalysesStatsSuccess
  | GetAnalysesStatsFail
  | GetSomaticAnalysesStats
  | GetSomaticAnalysesStatsSuccess
  | GetSomaticAnalysesStatsFail;
