export enum loginPageStates {
  loginWithFranklin,
  loginWithAzure,
  loginWithMFA,
}

export enum AzureErrors {
  general = 1,
  notAvailable,
}
