import { InjectionToken } from '@angular/core';
import { VariantId } from '../../../store/models';
import { VariantDetails } from '../../variant-page/modules/variant-page/models';
import { SelectOption } from './select-option.model';
import { PedigreeTree } from './pedigree-tree.model';
import { CaseType } from './case-type.model';
import { Subject } from 'rxjs';
import { AnalysisLean } from '../../analysis/modules/analyses/store';

export interface ETag {
  part_num: number;
  etag: string;
}

export interface UploadSampleStatus {
  caseName: string;
  file: File;
  sent: boolean;
  progress: number;
  start: number;
  observe: Subject<UpdateCaseStatusRequest>;
  err: boolean;
  completed: boolean;
  urls: string[];
  eTags: ETag[];
  uploadId: string;
}

export interface CreateCasePatientData {
  name?: string;
  dob?: string;
  ethnicities?: string[];
  sex?: string;
}

export interface CreateCaseData {
  name?: string;
  details?: string;
  phenotypes?: string[];
  disease?: string;
  patient_details?: CreateCasePatientData | CreateCasePatientData[];
  include_secondary_findings?: boolean;
  consanguinity?: string;
  assay?: string;
  virtual_panels?: string[];
  hard_panels?: string[];
  hard_panel_genes?: string[];
}

export interface NewCaseVariantEntry {
  variantId: VariantId;
  variantDetails: VariantDetails;
  zygosity?: string;
  vaf?: number;
  reported_classification?: string;
  family_inheritance_status?: string;
  copy_number?: number;
}

export interface CaseData {
  name: string;
  details: string;
  phenotypes: SelectOption[];
  include_secondary_findings?: boolean;
  consanguinity?: string;
  assay?: string;
  soft_panels?: string[];
  hard_panels?: string[];
  hard_panel_genes?: string[];
}

export interface TumorCaseData {
  name: string;
  details: string;
  disease: string;
}

export interface PatientData {
  name: string;
  dob: string;
  ethnicities: string[];
  sex: string;
}

export interface VcfInspectionResult {
  errors?: { description: string; error_type: string }[];
  warnings?: string[];
  samples?: string[];
}

export interface SampleFileForUpload {
  file?: File;
  name?: string;
  name_in_vcf?: string;
  vcfInspectionResult?: VcfInspectionResult;
  validating?: boolean;
  validated?: boolean;
  headerUrl?: string;
  fileAutoId?: number;
}

export interface SingleCaseAnalysisData {
  samples?: SampleFileForUpload[];
  variants?: NewCaseVariantEntry[];
  failedVariants?: string[];
  associatedSingleAnalysis?: AnalysisLean;
}

export interface TumorCaseAnalysisData {
  illuminaFiles?: SampleFileForUpload[];
  ionFiles?: SampleFileForUpload[];
}

export interface FamilyCaseAnalysisData {
  samples?: SampleFileForUpload[];
  ped_tree: PedigreeTree;
}

export interface CreateCaseSingleSampleForm {
  caseData?: CaseData;
  patientData?: PatientData;
  analysisData?: SingleCaseAnalysisData;
  customData?: { [key: string]: any };
  assayId?: string;
}

export interface CreateCaseFamilyForm {
  caseData?: CaseData;
  patientData?: PatientData;
  analysisData?: FamilyCaseAnalysisData;
  customData?: { [key: string]: any };
  assayId?: string;
}

export interface CreateCaseTumorForm {
  caseData?: TumorCaseData;
  patientData?: PatientData;
  analysisData?: TumorCaseAnalysisData;
  customData?: { [key: string]: any };
  assayId?: string;
}

export type CreateCaseForm = CreateCaseSingleSampleForm | CreateCaseFamilyForm | CreateCaseTumorForm;

export interface CreateCaseRequest {
  case_data: CreateCaseData;
  sample?: {
    files_creations_requests?: { path: string; vcf_header_location?: string; vcf_source?: string }[];
    sample_name?: string;
    existing_single_analysis_id?: number;
  };
  variants?: {
    variants: {
      variant: string;
      zygosity: string;
    }[];
  };
  custom_data?: { [key: string]: any };
  assay_id: string;
}

export interface CreateCaseFamilyRequest {
  family_case_data: CreateCaseData;
  new_samples: {
    files_creations_requests?: { path: string }[];
    sample_name?: string;
    sample_name_in_vcf?: string;
  }[];
  pedigree_tree: string;
  assay_id?: string;
  custom_data?: { [key: string]: any };
}

export interface UploadCaseSamplesData {
  caseName: string;
  file: File;
  urls: string[];
  uploadId: string;
}

export interface UpdateCaseStatusRequest {
  s3_path: string;
  progress: number;
  error?: boolean;
  originalFileName?: string;
}

export interface BriefSample {
  id: number;
  internal_id: string;
  subject_name: string;
  subject_sex: string;
  status: number;
  status_description: string;
  s3_id: string;
  annotation_version: number;
  assay_uuid: string;
  analysis_configuration_uuid: string;
  cnv_annotation_version: number;
  availability_status: number;
  created_at: Date;
  last_annotated?: Date;
}

export const CASE_PROGRESS_SNACKBAR_DATA = new InjectionToken<UploadSampleStatus[]>('CASE_PROGRESS_SNACKBAR_DATA');

export const ConvertCaseDataFormToRequest = (
  caseType: string,
  formData: CreateCaseForm,
  patientNames?: string[],
): CreateCaseData => {
  const patientDetails: CreateCasePatientData[] = patientNames.map((patient) => ({
    name: patient || formData.caseData.name,
    dob: formData.patientData.dob,
    ethnicities: formData.patientData.ethnicities,
    sex: formData.patientData.sex,
  }));

  switch (caseType) {
    case CaseType.SINGLE:
      const caseData = (formData as CreateCaseSingleSampleForm).caseData;
      return {
        name: caseData.name,
        details: caseData.details,
        phenotypes: caseData.phenotypes.map((x) => x.name),
        patient_details: patientDetails[0],
        include_secondary_findings: !!caseData.include_secondary_findings,
        consanguinity: caseData.consanguinity,
        virtual_panels: caseData.soft_panels,
        hard_panels: caseData.hard_panels,
        hard_panel_genes: caseData.hard_panel_genes,
      };
    case CaseType.FAMILY:
    case CaseType.REPORT:
    case CaseType.CMA:
      return {
        name: formData.caseData.name,
        details: formData.caseData.details,
        phenotypes: (formData.caseData as CaseData).phenotypes.map((x) => x.name),
        patient_details: caseType === CaseType.FAMILY ? patientDetails : patientDetails[0],
        include_secondary_findings: !!(formData as CreateCaseSingleSampleForm).caseData.include_secondary_findings,
        consanguinity: (formData as CreateCaseSingleSampleForm).caseData.consanguinity,
      };
    case CaseType.TUMOR:
      return {
        name: formData.caseData.name,
        details: formData.caseData.details,
        disease: (formData.caseData as TumorCaseData).disease,
        patient_details: patientDetails[0],
      };
    default:
      throw new Error('invalid case type in ConvertCaseDataFormToRequest');
  }
};
