<div class="dialog-header mat-dialog-content" mat-dialog-title>
  <div class="back-btn"></div>
  <div class="title-container">
    <div class="title">Variant Evidence</div>
    <div class="sub-title">New Evidence</div>
  </div>
  <img class="close-icon" src="/assets/svg/esc-with-txt.svg" (click)="onClose()" />
</div>
<div class="dialog-content">
  <div class="form">
    <div class="form-control">
      <label>Type</label>

      <mat-select
        [multiple]="true"
        [(ngModel)]="evidenceValues"
        [(value)]="evidenceValues"
        (selectionChange)="onEvidenceValuesChange($event)"
        disableRipple="true"
        panelClass="evidence-type-selector"
      >
        <ng-container *ngFor="let option of evidenceOptions">
          <mat-option *ngIf="!option.value_options?.length" [value]="option.evidence_category_name">
            {{ option.evidence_category_name }}
          </mat-option>
          <ng-container *ngIf="option.value_options?.length">
            <!--              <mat-option *ngFor="let optionValue of option.value_options"  hidden='true' [value]='{evidence_category_name: option.evidence_category_name, value: optionValue}'></mat-option>-->

            <div class="cont">
              <mat-checkbox
                (change)="onCheckboxClick($event, option)"
                [(ngModel)]="option.selected"
                disableRipple="true"
              >
                <img
                  class="arrow-icon"
                  *ngIf="!option.shown"
                  src="assets/svg/arrow-right-blue.svg"
                  (click)="toggleShown($event, option)"
                />
                <img
                  class="arrow-icon"
                  *ngIf="option.shown"
                  src="assets/svg/arrow-down-blue.svg"
                  (click)="toggleShown($event, option)"
                />

                <span id="example-radio-group-label">{{ option.evidence_category_name }}</span>
              </mat-checkbox>
              <mat-radio-group
                [hidden]="!option.shown"
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="option.selected"
                (change)="onRadioChange($event, option)"
              >
                <mat-radio-button
                  class="example-radio-button"
                  *ngFor="let optionValue of option.value_options"
                  [value]="optionValue"
                  disableRipple="true"
                >
                  {{ optionValue }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </ng-container>
        </ng-container>
      </mat-select>

      <mat-chip-listbox #chipList>
        <mat-chip-option
          *ngFor="let value of evidenceValuesB"
          removable="true"
          (removed)="remove($event, value)"
          [hidden]="!value?.evidence_category_name"
        >
          <mat-icon style="color: white" class="white-icon" matChipRemove>cancel</mat-icon>
          {{ value.value ? value.value : value.evidence_category_name }}
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
    <div class="form-control">
      <div class="question-container small-margin">
        <label for="detailsField">Details</label>
        <textarea
          id="detailsField"
          cdkTextareaAutosize
          [cdkAutosizeMinRows]="1"
          [cdkAutosizeMaxRows]="3"
          [(ngModel)]="details"
          (ngModelChange)="updateValueStore()"
        ></textarea>
      </div>
    </div>
  </div>
</div>

<mat-dialog-actions class="dialog-footer interpretation-actions">
  <button class="submit-btn" (click)="saveChanges()" [disabled]="saving">
    <span *ngIf="!saving">Add</span>
    <gnx-dots-loader *ngIf="saving"></gnx-dots-loader>
  </button>
</mat-dialog-actions>
