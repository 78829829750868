import { AnalysisType, AnnotationVersions, DetailedStatus } from '../../../../../../store';
import { ReportExportType } from '../../../reporting/store';
import { AnalysisWorkflowStatus } from './analysis-workflow-status.model';
import { DataModel } from './analysis.model';

export interface AnalysisBatchStats {
  active: number;
  resolved: number;
  processing: number;
  total?: number;
}

export interface AnalysisBatch {
  id: number;
  name: string;
  created_at: string;
  stats: AnalysisBatchStats;
}

export interface AnalysisLean {
  id: number;
  name: string;
  details: string;
  analysis_type: AnalysisType;
  num_samples: number;
  assay?: {
    uuid: string;
    name: string;
  };
  status: number;
  status_description: string;
  parent_status: AnalysisWorkflowStatus;
  assigned_user_email: string;
  detailed_status: DetailedStatus;
  final_report_files: {
    file_type_enum: ReportExportType;
  }[];
  access_list: string[];
  loading: { [prop: string]: boolean };
  labels: { [key: string]: string[] };
  last_annotated?: Date;
  versions?: AnnotationVersions;
}

export type AnalysisLeanListResponse = DataModel<AnalysisLean>;

export interface AnalysisBatchAnalysesResponse {
  results: AnalysisLean[];
  count: number;
  loading: boolean;
  loaded: boolean;
  nextPageUrl: string;
  previousPageUrl: string;
}

export interface AnalysisListByBatch {
  [batchId: number]: AnalysisBatchAnalysesResponse;
}
