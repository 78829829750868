import * as fromAction from './../actions/variant-interpretation.action';
import * as fromCurationAction from './../actions/variant-curation.actions';
import * as fromHeaderAction from '../actions/interpretation-header.action';
import * as fromWbActions from '../../../../../analysis/modules/workbench/store/actions';
import * as fromPublicationActions from '../../../publications/store/actions';

import { VariantInterpretation } from '../../models';
import { addPublication, removePublication } from '../../../shared/utils/references.utils';
import { VariantCurationActions } from './../actions/variant-curation.actions';

export interface VariantInterpretationState {
  data: VariantInterpretation;
  initialData: VariantInterpretation;
  loading: boolean;
  loaded: boolean;
  aiSuggestionLoaded: boolean;
  aiSuggestionLoading: boolean;
  error?: any;
  saved: boolean;
  saving: boolean;
}

const initialState: VariantInterpretationState = {
  data: {} as VariantInterpretation,
  initialData: {} as VariantInterpretation,
  loading: false,
  loaded: false,
  saved: false,
  aiSuggestionLoaded: false,
  aiSuggestionLoading: false,
  saving: false,
};

export function reducer(
  state = initialState,
  action:
    | fromAction.VariantInterpretationAction
    | fromHeaderAction.InterpretationHeaderAction
    | fromWbActions.VariantInterpretationAction
    | fromPublicationActions.PublicationsAction,
): VariantInterpretationState {
  switch (action.type) {
    case fromAction.LOAD_VARIANT_INTERPRETATION: {
      return {
        ...state,
        data: undefined,
        error: undefined,
        loading: true,
        loaded: false,
      };
    }
    case fromAction.SAVE_VARIANT_INTERPRETATION: {
      return {
        ...state,
        saving: true,
      };
    }
    case fromAction.CANCEL_SAVE_VARIANT_INTERPRETATION: {
      return {
        ...state,
        saving: false,
      };
    }
    case fromAction.SAVE_VARIANT_INTERPRETATION_SUCCESS: {
      return {
        ...state,
        data: action.data,
        initialData: action.data,
        saved: true,
        saving: false,
        aiSuggestionLoaded: false,
      };
    }
    case fromAction.SAVE_VARIANT_INTERPRETATION_RESET: {
      return {
        ...state,
        saved: false,
      };
    }
    case fromAction.LOAD_VARIANT_INTERPRETATION_SUCCESS: {
      return {
        ...state,
        data: action.data,
        initialData: action.data,
        loading: false,
        loaded: true,
      };
    }
    case fromAction.LOAD_AI_SUGGESTION: {
      return {
        ...state,
        aiSuggestionLoaded: false,
        aiSuggestionLoading: true,
      };
    }
    case fromAction.LOAD_AI_SUGGESTION_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          text:
            !action.interpretationType || !state.data[action.interpretationType] ? action.suggestion : state.data.text,
          [action.interpretationType]:
            action.interpretationType && state.data[action.interpretationType]
              ? {
                  ...state.data[action.interpretationType],
                  text: action.suggestion,
                }
              : state.data[action.interpretationType],
        },
        aiSuggestionLoaded: true,
        aiSuggestionLoading: false,
      };
    }
    case fromAction.LOAD_AI_SUGGESTION_FAIL: {
      return {
        ...state,
        aiSuggestionLoaded: false,
        aiSuggestionLoading: false,
        error: action.error,
      };
    }
    case fromAction.REVERT_VARIANT_INTERPRETATION: {
      return {
        ...state,
        data: {
          ...state.data,
          text: state.initialData.text,
          [action.interpretationType]:
            action.interpretationType && state.data[action.interpretationType]
              ? {
                  ...state.data[action.interpretationType],
                  text: state.initialData[action.interpretationType].text,
                }
              : state.data[action.interpretationType],
        },
      };
    }
    case fromAction.GET_INHERITANCE_MODEL_FAIL:
    case fromAction.SAVE_VARIANT_INTERPRETATION_FAIL:
    case fromAction.LOAD_VARIANT_INTERPRETATION_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        saving: false,
        error: action.error,
      };
    }
    case fromAction.UPDATE_VARIANT_INTERPRETATION:
    case fromAction.GET_INHERITANCE_MODEL_SUCCESS:
    case fromAction.REGENERATE_VARIANT_INTERPRETATION_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
        },
      };
    }
    case fromAction.CLEAR_VARIANT_INTERPRETATION: {
      return {
        ...initialState,
      };
    }
    case fromWbActions.MARK_IRRELEVANT_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          in_report: state.data ? state.data.in_report : false,
          follow: state.data ? state.data.follow : false,
          irrelevant: action.value,
        },
        initialData: {
          ...state.initialData,
          irrelevant: action.value,
        },
      };
    }
    case fromHeaderAction.FOLLOW_VARIANT_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          in_report: state.data ? state.data.in_report : false,
          irrelevant: state.data ? state.data.irrelevant : false,
          follow: action.value,
        },
        initialData: {
          ...state.initialData,
          follow: action.value,
        },
      };
    }
    case fromPublicationActions.MARK_PUBLICATION: {
      const value: string = state.data.custom_data?.references || '';
      const value1: string[] = state.data.custom_data?.reference_ids || [];
      let newValue: string;
      let newValue1: string[];
      if (action.value) {
        newValue = addPublication(
          action.publication.publicationDate,
          action.publication.publicationId.id,
          action.publication.publicationUrl,
          action.publication.journalName,
          action.publication.title,
          value,
        );
        newValue1 = [...value1, action.publication.publicationId.id];
      } else {
        newValue = removePublication(`PMID: ${action.publication.publicationId.id}`, value);
        newValue1 = value1.filter((temp) => temp !== action.publication.publicationId.id);
      }
      return {
        ...state,
        data: {
          ...state.data,
          custom_data: {
            ...(state.data.custom_data || {}),
            references: newValue,
            reference_ids: newValue1,
          },
        },
      };
    }
  }

  return state;
}

export const getVariantInterpretationEntity = (state: VariantInterpretationState) => state?.data;
export const getVariantInterpretationInitialEntity = (state: VariantInterpretationState) => state?.initialData;
export const getVariantInterpretationLoadingEntity = (state: VariantInterpretationState) => state?.loading;
export const getVariantInterpretationLoadedEntity = (state: VariantInterpretationState) => state?.loaded;
export const getVariantInterpretationSavedEntity = (state: VariantInterpretationState) => state?.saved;
export const getAiSuggestionsLoading = (state: VariantInterpretationState) => state?.aiSuggestionLoading;
export const getAiSuggestionsLoaded = (state: VariantInterpretationState) => state?.aiSuggestionLoaded;
export const getVariantInterpretationSavingEntity = (state: VariantInterpretationState) => state?.saving;

export const getVariantInterpretationTextChange = (state: VariantInterpretationState) => {
  const text = state.data.somatic_interpretation?.text || state.data.text;
  const initialText = state.initialData.somatic_interpretation?.text || state.initialData.text;
  return text?.replace(/<[^>]*>/g, '').replace(/ /g, '') !== initialText?.replace(/<[^>]*>/g, '').replace(/ /g, '');
};
