import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';

import { DEMO_APP_CLICK, DemoAppClick, OPEN_REGISTRATION_POPUP, OpenRegistrationPopup } from '../actions';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {
  FreemiumRegistrationFormData,
  FreemiumRegistrationFormPopupComponent,
} from '../../components/freemium-registration-form-popup/freemium-registration-form-popup.component';
import { select, Store } from '@ngrx/store';
import { AuthModuleState } from '../../../auth0/store/reducers';
import { getUserDetails } from '../../../auth0/store/selectors';
import { UserDetails } from '../../../auth0/models';
import { VariantPageModuleState } from '../reducers';
import { getIsRaffleVariant } from '../selectors';

@Injectable()
export class PopupEffect {
  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
    private authStore$: Store<AuthModuleState>,
    private store$: Store<VariantPageModuleState>,
  ) {}

  demoAppClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DEMO_APP_CLICK),
      withLatestFrom(this.authStore$.pipe(select(getUserDetails))),
      map(([action, userDetails]: [DemoAppClick, UserDetails]) => new OpenRegistrationPopup()),
    ),
  );

  openRegistrationPopup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OPEN_REGISTRATION_POPUP),
        withLatestFrom(this.store$.pipe(select(getIsRaffleVariant))),
        tap(([action, isRaffleVariant]: [OpenRegistrationPopup, boolean]) => {
          return this.dialog.open(FreemiumRegistrationFormPopupComponent, {
            width: '1100px',
            height: '610px',
            minWidth: '1100px',
            minHeight: '610px',
            backdropClass: 'error-popup-backdrop',
            panelClass: 'error-popup-panel',
            hasBackdrop: true,
            data: {
              urlAfterAuth: action.urlAfterAuth,
              style: action.style,
            } as FreemiumRegistrationFormData,
          });
        }),
      ),
    { dispatch: false },
  );
}
