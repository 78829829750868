import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as fromAction from './../actions/variant-page-config.action';
import { VariantPageService } from '../services/variant-page.service';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AppModuleState, getDiscoveryAnalysisType } from '../../../../store';
import { select, Store } from '@ngrx/store';
import { DiscoveryAnalysisType } from '../../../discovery/store/models/discovery.model';

@Injectable()
export class VariantPageConfigEffect {
  constructor(
    private actions$: Actions,
    private variantPageService: VariantPageService,
    private rootStore$: Store<AppModuleState>,
  ) {}

  getVariantPageConfig$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GET_VARIANT_PAGE_CONFIG),
      withLatestFrom(this.rootStore$.pipe(select(getDiscoveryAnalysisType))),
      switchMap(([action, discoveryAnalysisType]: [fromAction.GetVariantPageConfig, DiscoveryAnalysisType]) =>
        this.variantPageService
          .getVariantPageConfig(
            action.analysisId,
            action.variantType,
            action.popupAppType,
            action.svType,
            action.analysisType,
            discoveryAnalysisType,
          )
          .pipe(
            map(
              (config) =>
                new fromAction.GetVariantPageConfigSuccess(
                  action.analysisId,
                  action.variantType,
                  config,
                  action.popupAppType,
                ),
            ),
            catchError((error) =>
              of(new fromAction.GetVariantPageConfigFail(action.analysisId, action.variantType, error)),
            ),
          ),
      ),
    ),
  );
}
