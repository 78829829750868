import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: 'mat-select[gnxSelect]',
  standalone: true,
})
export class SelectDirective {
  @HostBinding('style.width') @Input() selectWidth: string;
  @HostBinding('style.flex') @Input() flex: string;
  @HostBinding('class.gnx-select') enabled = true;

  constructor() {}
}
