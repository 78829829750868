import { OrganizationConfig } from '../models';
import { createReducer, on } from '@ngrx/store';

import * as fromAction from './../actions/organization-config.action';

export interface OrganizationConfigState {
  data?: OrganizationConfig;
  loading: boolean;
  loaded: boolean;
}

const initialState: OrganizationConfigState = {
  loading: false,
  loaded: false,
};

export const reducer = createReducer(
  initialState,
  on(fromAction.loadOrganizationConfig, (state) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(fromAction.loadOrganizationConfigSuccess, (state, { data }) => ({
    ...state,
    data,
    loading: false,
    loaded: true,
  })),
  on(fromAction.loadOrganizationConfigFail, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: false,
  })),
);
