import { Action } from '@ngrx/store';

export const DEMO_APP_CLICK = '[Variant Page] Demo app click';

export class DemoAppClick implements Action {
  readonly type = DEMO_APP_CLICK;

  constructor(public payload: any) {}
}

export type DemoApplicationsAction = DemoAppClick;
