import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlexComponent } from '../flex/flex.component';

@Component({
  selector: 'gnx-flex-item',
  templateUrl: './flex-item.component.html',
  styleUrls: ['./flex-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class FlexItemComponent extends FlexComponent {
  @Input() flex: string;
  @Input() minWidth: string;
  @Input() minHeight: string;
  @Input() alignSelf: 'flex-start' | 'center' | 'flex-end' | 'stretch';
  @Input() display: 'inline-flex' | 'flex' | 'block';

  get styles() {
    const inheritedStyles = super.styles;
    let grow: string;
    let shrink: string;
    let basis: string;

    if (this.flex) {
      [grow, shrink, basis] = this.flex.split(' ');
      shrink = shrink ?? grow;
      basis = basis ?? '0%';
    }

    return {
      ...inheritedStyles,
      display: this.display,
      flexGrow: grow,
      flexShrink: shrink,
      flexBasis: basis,
      minWidth: this.minWidth,
      minHeight: this.minHeight,
      alignSelf: this.alignSelf,
    };
  }
}
