import {
  CaseDataAction,
  CLEAR_PATIENT_INFO,
  EDIT_PATIENT_INFO,
  LOAD_PATIENT_INFO,
  LOAD_PATIENT_INFO_FAIL,
  LOAD_PATIENT_INFO_SUCCESS,
  LOAD_PEDIGREE_URL,
  LOAD_PEDIGREE_URL_FAIL,
  LOAD_PEDIGREE_URL_SUCCESS,
  TOGGLE_PANEL,
  TOGGLE_PHENOTYPE,
} from '../actions';
import { CasePanel, SearchConfig } from '../../../../../variant-page/modules/variant-page/models';

export interface PatientInfoState {
  patientInfoResponse: SearchConfig;
  toggledPhenotypes: string[];
  pedigreeUrl: string;
  pedigreeUrlLoaded: boolean;
  pedigreeUrlLoading: boolean;
  toggledPanels: CasePanel[];
  patientInfoResponseLoaded: boolean;
  patientInfoResponseLoading: boolean;
}

export const initialState: PatientInfoState = {
  patientInfoResponse: undefined,
  toggledPhenotypes: undefined,
  pedigreeUrl: undefined,
  pedigreeUrlLoaded: false,
  pedigreeUrlLoading: false,
  toggledPanels: undefined,
  patientInfoResponseLoaded: false,
  patientInfoResponseLoading: false,
};

export function reducer(state = initialState, action: CaseDataAction): PatientInfoState {
  switch (action.type) {
    case EDIT_PATIENT_INFO:
      return {
        ...state,
        patientInfoResponseLoaded: false,
        patientInfoResponseLoading: true,
      };
    case LOAD_PATIENT_INFO:
      return {
        ...state,
        patientInfoResponse: null,
        patientInfoResponseLoaded: false,
        patientInfoResponseLoading: true,
      };
    case LOAD_PATIENT_INFO_SUCCESS:
      return {
        ...state,
        patientInfoResponse: action.data,
        patientInfoResponseLoaded: true,
        patientInfoResponseLoading: false,
      };
    case LOAD_PATIENT_INFO_FAIL:
      return { ...state, patientInfoResponseLoaded: false, patientInfoResponseLoading: false };
    case LOAD_PEDIGREE_URL:
      return {
        ...state,
        pedigreeUrl: null,
        pedigreeUrlLoaded: false,
        pedigreeUrlLoading: true,
      };
    case LOAD_PEDIGREE_URL_SUCCESS:
      return {
        ...state,
        pedigreeUrl: action.url,
        pedigreeUrlLoaded: true,
        pedigreeUrlLoading: false,
      };
    case LOAD_PEDIGREE_URL_FAIL:
      return { ...state, pedigreeUrlLoaded: false, pedigreeUrlLoading: false };
    case CLEAR_PATIENT_INFO:
      return { ...initialState };
    case TOGGLE_PHENOTYPE:
      return {
        ...state,
        toggledPhenotypes: state.toggledPhenotypes?.includes(action.phenotype)
          ? state.toggledPhenotypes.filter((pheno) => pheno !== action.phenotype)
          : [...(state.toggledPhenotypes || []), action.phenotype],
      };
    case TOGGLE_PANEL:
      let toggledPanels = [...(state.toggledPanels || [])];
      action.panels.forEach((toggledPanel) => {
        toggledPanels = toggledPanels?.some(
          (panel) => panel.id === toggledPanel.id && panel.location === toggledPanel.location,
        )
          ? toggledPanels.filter((panel) => panel.id !== toggledPanel.id || panel.location !== toggledPanel.location)
          : [...toggledPanels, toggledPanel];
      });
      return {
        ...state,
        toggledPanels,
      };
    default:
      return state;
  }
}

export const getPatientInfoResponse = (state: PatientInfoState) => state?.patientInfoResponse;
export const getPatientInfoResponseLoaded = (state: PatientInfoState) => state?.patientInfoResponseLoaded;
export const getPatientInfoResponseLoading = (state: PatientInfoState) => state?.patientInfoResponseLoading;
export const getPedigreeUrl = (state: PatientInfoState) => state?.pedigreeUrl;
export const getPedigreeUrlLoaded = (state: PatientInfoState) => state?.pedigreeUrlLoaded;
export const getPedigreeUrlLoading = (state: PatientInfoState) => state?.pedigreeUrlLoading;
export const getToggledPhenotypes = (state: PatientInfoState) => state?.toggledPhenotypes;
export const getToggledPanels = (state: PatientInfoState) => state?.toggledPanels;
