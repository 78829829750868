import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DiscussionService } from '../../services/discussion.service';
import {
  CREATE_TOPIC,
  CreateTopic,
  CreateTopicFailure,
  CreateTopicSuccess,
  DELETE_MESSAGE,
  DELETE_TOPIC,
  DeleteMessage,
  DeleteMessageFailure,
  DeleteMessageSuccess,
  DeleteTopic,
  DeleteTopicFailure,
  DeleteTopicSuccess,
  GET_MESSAGES,
  GET_TOPICS,
  GET_USERS,
  GetMessages,
  GetMessagesFailure,
  GetMessagesSuccess,
  GetTopics,
  GetTopicsFailure,
  GetTopicsSuccess,
  GetUsers,
  GetUsersFailure,
  GetUsersSuccess,
  SUBMIT_MESSAGE,
  SubmitMessage,
  SubmitMessageFailure,
  SubmitMessageSuccess,
} from '../actions/discussion.action';
import { catchError, debounceTime, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { DiscussionModuleState } from '../reducers';
import { Message, Topic } from '../../models/discussion.models';
import { AppModuleState, getAnalysisId } from '../../../../../../store';

@Injectable()
export class DiscussionEffect {
  constructor(
    private actions$: Actions,
    private store$: Store<DiscussionModuleState>,
    private rootStore$: Store<AppModuleState>,
    private discussionService: DiscussionService,
  ) {}

  getTopics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_TOPICS),
      switchMap((action: GetTopics) => {
        return this.discussionService.getTopics(action.variantId, action.fetch_from_index, action.fetch_to_index).pipe(
          map((topics) => new GetTopicsSuccess(topics)),
          catchError((err: string) => of(new GetTopicsFailure(err))),
        );
      }),
    ),
  );

  deleteTopic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DELETE_TOPIC),
      switchMap((action: DeleteTopic) => {
        return this.discussionService.deleteTopic(action.variantId, action.topic_id).pipe(
          map(() => new DeleteTopicSuccess(action.topic_id)),
          catchError((err: string) => of(new DeleteTopicFailure(err))),
        );
      }),
    ),
  );

  getUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_USERS),
      map((action: GetUsers) => action.searchString),
      debounceTime(250),
      switchMap((searchString: string) => {
        return this.discussionService.getUsers(searchString).pipe(
          map((users) => new GetUsersSuccess(users)),
          catchError((err: string) => of(new GetUsersFailure(err))),
        );
      }),
    ),
  );

  getMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_MESSAGES),
      switchMap((action: GetMessages) => {
        return this.discussionService
          .getMessages(action.variantId, action.topic_ids, action.fetch_from_index, action.fetch_to_index)
          .pipe(
            map((messages) => new GetMessagesSuccess(messages)),
            catchError((err: string) => of(new GetMessagesFailure(err))),
          );
      }),
    ),
  );

  deleteMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DELETE_MESSAGE),
      switchMap((action: DeleteMessage) => {
        return this.discussionService.deleteMessage(action.variantId, action.topic_id, action.message_id).pipe(
          map(() => new DeleteMessageSuccess(action.message_id)),
          catchError((err: string) => of(new DeleteMessageFailure(err))),
        );
      }),
    ),
  );

  submitMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SUBMIT_MESSAGE),
      switchMap((action: SubmitMessage) => {
        return this.discussionService
          .submitMessage(
            action.variantId,
            action.message_content,
            action.topic_id,
            action.users,
            action.message_id,
            action.replying_to,
          )
          .pipe(
            map((message) => new SubmitMessageSuccess(message, !!action.message_id)),
            catchError((err: string) => of(new SubmitMessageFailure(err))),
          );
      }),
    ),
  );

  createTopic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CREATE_TOPIC),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisId))),
      switchMap(([action, analysisId]: [CreateTopic, number]) => {
        return this.discussionService.createTopic(action.variantId, action.title, analysisId).pipe(
          switchMap((topic) =>
            this.discussionService
              .submitMessage(action.variantId, action.message, topic.topic_id, action.users)
              .pipe(map((message) => [topic, message])),
          ),
          switchMap(([topic, message]: [Topic, Message]) => [
            new SubmitMessageSuccess(message),
            new CreateTopicSuccess(topic),
          ]),
          catchError((err: string) => of(new CreateTopicFailure(err))),
        );
      }),
    ),
  );
}
