import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromReducer from '../reducers/search.reducer';

import { AppModuleState, getAppModuleState } from '../reducers';
import { SearchHistoryResponse, VariantId } from '../models';
import { Analysis } from '../../modules/analysis/modules/analyses/store';

export const getSearchState: MemoizedSelector<AppModuleState, fromReducer.SearchVariantState> = createSelector(
  getAppModuleState,
  (state: AppModuleState) => state.search,
);

export const getSearchTerm: MemoizedSelector<AppModuleState, string> = createSelector(
  getSearchState,
  fromReducer.getSearchTerm,
);

export const getSearching: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getSearchState,
  fromReducer.getSearching,
);

export const getSearchDone: MemoizedSelector<AppModuleState, boolean> = createSelector(
  getSearchState,
  fromReducer.getSearchDone,
);

export const getSearchError: MemoizedSelector<AppModuleState, any> = createSelector(
  getSearchState,
  fromReducer.getSearchError,
);

export const getSearchResult: MemoizedSelector<AppModuleState, VariantId> = createSelector(
  getSearchState,
  fromReducer.getSearchResult,
);

export const getAnalysisResults: MemoizedSelector<AppModuleState, Analysis[]> = createSelector(
  getSearchState,
  fromReducer.getAnalysisResults,
);

export const getAnalysisResultSuggestions: MemoizedSelector<AppModuleState, Analysis[]> = createSelector(
  getSearchState,
  (state) => (state.searchDone ? (state.analysisResults.length ? state.analysisResults : [{} as Analysis]) : null),
);

export const getSearchHistory: MemoizedSelector<AppModuleState, SearchHistoryResponse> = createSelector(
  getSearchState,
  fromReducer.getSearchHistory,
);
