import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromReducerSelectors from './../reducers/variant-interpretation.reducer';
import { getVariantInterpretationState, VariantInterpretationState } from '../reducers';
import { VariantInterpretation } from '../../models';
import { isVariantInterpretationEqual } from '../utils/variant-interpretation.utils';
import { MemoizedSelectorWithProps } from '@ngrx/store/src/selector';
import { InterpretationType } from '../../containers/interpretation/interpretation.data';

const getInterpretationDetailsState: MemoizedSelector<
  VariantInterpretationState,
  fromReducerSelectors.VariantInterpretationState
> = createSelector(getVariantInterpretationState, (state: VariantInterpretationState) => state?.interpretation);

export const getVariantInterpretation: MemoizedSelector<VariantInterpretationState, VariantInterpretation | undefined> =
  createSelector(getInterpretationDetailsState, fromReducerSelectors.getVariantInterpretationEntity);

export const getInitialVariantInterpretation: MemoizedSelector<
  VariantInterpretationState,
  VariantInterpretation | undefined
> = createSelector(getInterpretationDetailsState, fromReducerSelectors.getVariantInterpretationInitialEntity);

export const getVariantInterpretationLoading: MemoizedSelector<VariantInterpretationState, boolean> = createSelector(
  getInterpretationDetailsState,
  fromReducerSelectors.getVariantInterpretationLoadingEntity,
);

export const getVariantInterpretationLoaded: MemoizedSelector<VariantInterpretationState, boolean> = createSelector(
  getInterpretationDetailsState,
  fromReducerSelectors.getVariantInterpretationLoadedEntity,
);

export const getVariantInterpretationSaved: MemoizedSelector<VariantInterpretationState, boolean> = createSelector(
  getInterpretationDetailsState,
  fromReducerSelectors.getVariantInterpretationSavedEntity,
);

export const getVariantInterpretationSaving: MemoizedSelector<VariantInterpretationState, boolean> = createSelector(
  getInterpretationDetailsState,
  fromReducerSelectors.getVariantInterpretationSavingEntity,
);

export const getVariantInterpretationChanged: MemoizedSelector<VariantInterpretationState, boolean> = createSelector(
  getVariantInterpretation,
  getInitialVariantInterpretation,
  (data, initialData) => {
    if (!data) {
      return false;
    }
    return !isVariantInterpretationEqual(data, initialData);
  },
);

export const getAiSuggestionsLoading: MemoizedSelector<VariantInterpretationState, boolean> = createSelector(
  getInterpretationDetailsState,
  fromReducerSelectors.getAiSuggestionsLoading,
);

export const getAiSuggestionsLoaded: MemoizedSelectorWithProps<
  VariantInterpretationState,
  { type: InterpretationType },
  boolean
> = createSelector(getInterpretationDetailsState, (state, props) => {
  if (!state.data) {
    return false;
  }
  if (!props.type || !state.data[props.type]) {
    return state.aiSuggestionLoaded && state.data.text !== state.initialData.text;
  }
  return state.aiSuggestionLoaded && state.data[props.type].text !== state.initialData[props.type].text;
});

export const getVariantInterpretationTextChanged: MemoizedSelector<VariantInterpretationState, boolean> =
  createSelector(getInterpretationDetailsState, fromReducerSelectors.getVariantInterpretationTextChange);
