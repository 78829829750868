import * as fromAction from '../actions';

export interface FollowVariantState {
  followed: boolean;
  loading: boolean;
  loaded: boolean;
}

const initialState: FollowVariantState = {
  followed: false,
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action: fromAction.FollowVariantAction): FollowVariantState {
  switch (action.type) {
    case fromAction.GET_VARIANT_FOLLOWED_STATE:
    case fromAction.FOLLOW_VARIANT: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case fromAction.FOLLOW_VARIANT_SUCCESS:
    case fromAction.GET_VARIANT_FOLLOWED_STATE_SUCCESS: {
      return {
        ...state,
        followed: action.result,
        loading: false,
        loaded: true,
      };
    }
    case fromAction.FOLLOW_VARIANT_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case fromAction.CLEAR_VARIANT_FOLLOWED_STATE: {
      return {
        ...initialState,
      };
    }
  }

  return state;
}

export const getFollowed = (state: FollowVariantState) => state.followed;
export const getLoading = (state: FollowVariantState) => state.loading;
export const getLoaded = (state: FollowVariantState) => state.loaded;
