export enum SexType {
  MALE = 'male',
  FEMALE = 'female',
  UNKNOWN = 'unknown',
}

export const SEX_OPTIONS: { label: string; value: SexType }[] = [
  {
    label: 'Unknown',
    value: SexType.UNKNOWN,
  },
  {
    label: 'Female',
    value: SexType.FEMALE,
  },
  {
    label: 'Male',
    value: SexType.MALE,
  },
];
