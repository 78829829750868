import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[gnxGrid]',
  standalone: true,
})
export class GridDirective {
  @HostBinding('style.grid') @Input() grid: string;
  @HostBinding('style.gap') @Input() gap: string;
  @HostBinding('style.align-items') @Input() align: 'flex-start' | 'center' | 'flex-end' | 'stretch';

  @HostBinding('style.display')
  get displayStyle(): string {
    return 'grid';
  }
}
