import { AnalysisSingleVariant } from './analysis-result.model';

export interface ECSResult {
  variants: {
    female: AnalysisSingleVariant[];
    male?: AnalysisSingleVariant[];
  };
  disease: {
    name: string;
    transcripts: string[];
    risk: {
      factor: number;
      total: number;
    };
    gene: string;
    always_report: boolean;
    mother_carrier_status: CarrierStatus;
    father_carrier_status: CarrierStatus;
  };
}

export enum CarrierStatus {
  Unknown = 'UNKNOWN_CARRIER_STATUS',
  Carrier = 'CARRIER',
  ReducedRisk = 'REDUCED_RISK',
  Intermediate = 'INTERMEDIATE',
  NotTested = 'NOT_TESTED',
}
