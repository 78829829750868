import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Message, Topic, User } from '../models/discussion.models';
import { Injectable } from '@angular/core';
import { DiscussionServiceConfig, VariantPageLibServiceType } from '../../../store/models/library-config.model';
import { SnpVariantId, variantIdToBody, variantIdToBody2, variantIdToHttpParams } from '../../variant-page/models';
import { VariantPageLibConfigService } from '../../variant-page/services/variant-page-lib-config.service';
import { VariantId } from '../../../../../store';

@Injectable()
export class DiscussionService {
  constructor(private http: HttpClient) {}

  get serviceConfig(): DiscussionServiceConfig {
    return VariantPageLibConfigService.getServiceConfig(VariantPageLibServiceType.DiscussionService);
  }

  getTopics(varId: SnpVariantId, fetch_from_index?, fetch_to_index?): Observable<Topic[]> {
    const params = variantIdToHttpParams(varId)
      .set('fetch_from_index', fetch_from_index || '')
      .set('fetch_to_index', fetch_to_index || 1000);

    return this.http.get(this.serviceConfig.getTopics, { params }) as Observable<Topic[]>;
  }

  deleteTopic(varId: SnpVariantId, topic_id: string): Observable<any> {
    const params = variantIdToHttpParams(varId).set('topic_id', topic_id);

    return this.http.delete(this.serviceConfig.deleteTopic, { params });
  }

  getUsers(searchString: string): Observable<User[]> {
    const params = new HttpParams().set('query', searchString);

    return this.http.get<User[]>(this.serviceConfig.getUsers, { params });
  }

  getMessages(varId: SnpVariantId, topic_ids?, fetch_from_index?, fetch_to_index?): Observable<Message[]> {
    const params = variantIdToHttpParams(varId)
      .set('fetch_from_index', fetch_from_index || 0)
      .set('fetch_to_index', fetch_to_index || 1000)
      .set('topic_ids', topic_ids || '');

    return this.http.get(this.serviceConfig.getMessages, { params }) as Observable<Message[]>;
  }

  deleteMessage(varId: SnpVariantId, topic_id: string, message_id: string): Observable<any> {
    const params = variantIdToHttpParams(varId).set('topic_id', topic_id).set('message_id', message_id);

    return this.http.delete(this.serviceConfig.deleteMessage, { params });
  }

  submitMessage(
    varId: SnpVariantId,
    message_content,
    topic_id,
    tagged_users?: User[],
    message_id?,
    replying_to?,
  ): Observable<Message> {
    const body = {
      ...variantIdToBody2(varId),
      variant_id: varId?.hashId,
      topic_id,
      message_content,
      tagged_users,
      message_id,
      replying_to,
      analysis_id: varId.analysisId,
    };

    return this.http.post(this.serviceConfig.submitMessage, body) as Observable<Message>;
  }

  createTopic(varId: SnpVariantId, title, analysisId): Observable<Topic> {
    const body = {
      ...variantIdToBody2(varId),
      variant_id: varId?.hashId,
      title,
      analysis_id: analysisId.toString(),
    };

    return this.http.post(this.serviceConfig.createTopic, body) as Observable<Topic>;
  }
}
