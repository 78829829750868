import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import {
  AzureConnectionResponse,
  MFALoginResponse,
  SetInfoResponse,
  SignUpResponse,
  TeamMember,
  TokenDetails,
  UserDetails,
  UserLocationData,
} from '../models';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import * as jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  REGION_RESOLVER = 'https://ipinfo.io/json';

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  public login(email: string, password: string): Observable<TokenDetails | MFALoginResponse> {
    this.clearLoginFromLocalStorage();
    return this.http
      .post<SignUpResponse>(`${environment.beBaseURL}/api/v2/user/login`, {
        email,
        password,
      })
      .pipe(
        map((result) => {
          if (result.hasOwnProperty('mfa_token')) {
            return result as MFALoginResponse;
          } else {
            const response: TokenDetails = this.parseAuthResult({
              idToken: result.id_token,
              accessToken: result.access_token,
              expiresIn: result.expires_in,
            });
            this.setTokenDetailsToLocalStorage(response);
            return response;
          }
        }),
      );
  }

  public loginWithMFA(email: string, mfaToken: string, otpCode: string): Observable<TokenDetails> {
    return this.http
      .post<SignUpResponse>(`${environment.beBaseURL}/api/v2/user/challenge_mfa`, {
        email,
        mfa_token: mfaToken,
        otp_code: otpCode,
      })
      .pipe(
        map((result) => {
          const response: TokenDetails = this.parseAuthResult({
            idToken: result.id_token,
            accessToken: result.access_token,
            expiresIn: result.expires_in,
          });
          this.setTokenDetailsToLocalStorage(response);
          return response;
        }),
      );
  }

  public logoutRemote(): Observable<any> {
    return this.http.post<any>(`${environment.beBaseURL}/api/v2/user/logout`, {});
  }

  public logout(): void {
    this.clearLoginFromLocalStorage();
    this.clearRedirectFromLocalStorage();
    this.router.navigate(['clinical-db']);
  }

  signUp(email: string, password: string, inviteeId: string): Observable<TokenDetails> {
    return this.http
      .post<SignUpResponse>(`${environment.beBaseURL}/api/v2/user/create`, {
        email,
        password,
        is_invitee: !!inviteeId || undefined,
      })
      .pipe(
        map((result) => {
          const response: TokenDetails = this.parseAuthResult({
            idToken: result.id_token,
            accessToken: result.access_token,
            expiresIn: result.expires_in,
          });

          this.setTokenDetailsToLocalStorage(response);

          return response;
        }),
      );
  }

  setInfo(
    userId: string,
    email: string,
    fullName: string,
    role: string,
    organization: string,
    organizationId: string,
  ): Observable<SetInfoResponse> {
    return this.http.post<SetInfoResponse>(`${environment.beBaseURL}/api/v2/user/add_details`, {
      user_id: userId,
      email,
      full_name: fullName,
      role,
      organization_name: organization,
      organization_id: organizationId,
    });
  }

  inviteUsers(
    email: string,
    organizationId: string,
    organizationName: string,
    emails: { email: string; admin: boolean }[],
  ): Observable<SetInfoResponse> {
    return this.http.post<SetInfoResponse>(`${environment.beBaseURL}/api/v2/user/invite_members`, {
      inviter_email: email,
      organization_name: organizationName,
      organization_id: organizationId,
      invited_members: emails,
    });
  }

  resendEmail(userEmail: string): Observable<any> {
    return this.http.post<any>(`${environment.beBaseURL}/api/v2/user/prompt_email_verification`, {
      user_id: userEmail,
    });
  }

  sendResetEmail(userEmail: string): Observable<any> {
    return this.http.post<any>(`${environment.beBaseURL}/api/v2/user/prompt_reset_password`, { email: userEmail });
  }

  changePassword(
    email: string,
    userId: string,
    password: string,
    oldPassword: string,
    token?: string,
  ): Observable<any> {
    return this.http.post<any>(`${environment.beBaseURL}/api/v2/user/change_password`, {
      email,
      user_id: userId,
      new_password: password,
      old_password: oldPassword,
      token,
    });
  }

  getInviteeData(inviteeId: string): Observable<any> {
    return this.http.get<any>(`${environment.beBaseURL}/api/v2/user/invited_member?uuid=${inviteeId}`);
  }

  determineUserRegion(): Observable<UserLocationData> {
    return this.http
      .get<UserLocationData>(this.REGION_RESOLVER, {
        headers: {
          Authorization: 'Bearer f9b67ce31f7421',
        },
      })
      .pipe(
        map((result) => {
          console.log('resolved user city', result.city);
          return result;
        }),
      );
  }

  public getStoredTokenDetails(): Observable<TokenDetails> {
    return Observable.create((observer) => {
      const tokenDetails: TokenDetails = JSON.parse(localStorage.getItem('tokenDetails'));
      if (tokenDetails) {
        observer.next(tokenDetails);
      } else {
        observer.error(new HttpErrorResponse({ error: 'Token not found' }));
      }
    });
  }

  public fetchUserDetails(): Observable<UserDetails> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.beBaseURL}/api/v2/user/get_data`;

    return this.http.get<UserDetails>(url, { headers });
  }

  public renewToken(tokenDetails: TokenDetails): Observable<TokenDetails> {
    return this.http
      .get<{
        is_token_changed: boolean;
        failed_refreshing_token: boolean;
        new_token?: {
          access_token;
          id_token;
          scope;
          expires_in;
          token_type;
        };
        existing_token?: string;
      }>(`${environment.beBaseURL}/api/v2/user/check_session`, {
        headers: {
          Authorization: `Bearer ${tokenDetails.accessToken}`,
        },
      })
      .pipe(
        map((result) => {
          if (result.is_token_changed) {
            const response: TokenDetails = this.parseAuthResult({
              idToken: result.new_token.id_token,
              accessToken: result.new_token.access_token,
              expiresIn: result.new_token.expires_in,
            });

            this.setTokenDetailsToLocalStorage(response);
            return response;
          } else if (result.existing_token) {
            const token = jwtDecode(result.existing_token);
            const response: TokenDetails = {
              tokenId: result.existing_token,
              accessToken: result.existing_token,
              expiration: token.exp * 1000,
            };

            this.setTokenDetailsToLocalStorage(response);
            return response;
          } else if (result.failed_refreshing_token) {
            throw new Error('failed refreshing token');
          }
          return tokenDetails;
        }),
      );
  }

  public redirectAfterAuth() {
    const url = localStorage.getItem('redirectURL');
    localStorage.removeItem('redirectURL');

    if (!!url) {
      const redirectUrl = new URL(url);
      redirectUrl.hostname = window.location.hostname;

      window.location.replace(redirectUrl); // ToDo - fix with route and not url replace (stay in SPA)
    } else {
      window.location.replace('');
    }
  }

  public clearRedirectAfterAuth() {
    localStorage.removeItem('redirectURL');
  }

  public clearLoginFromLocalStorage() {
    localStorage.removeItem('tokenDetails');
    localStorage.removeItem('userDetails');
  }

  public clearRedirectFromLocalStorage() {
    localStorage.removeItem('redirectURL');
  }

  public setRedirectAfterAuth(url?: string) {
    url = url || window.location.href;
    if (!url.includes('-cta')) {
      localStorage.setItem('redirectURL', url);
    }
  }

  private setTokenDetailsToLocalStorage(tokenDetails: TokenDetails) {
    let userDetails: any = {
      ...tokenDetails,
    };
    if (tokenDetails.tokenId) {
      const decodedToken = jwtDecode(tokenDetails.tokenId);
      userDetails = {
        ...tokenDetails,
        email: decodedToken.email || undefined,
        firstName: decodedToken.given_name || undefined,
        lastName: decodedToken.family_name || undefined,
        sub: decodedToken.sub || undefined,
      };
    }
    localStorage.setItem('tokenDetails', JSON.stringify(userDetails));
  }

  public setUserDetailsToLocalStorage(userDetails: UserDetails) {
    localStorage.setItem('userDetails', JSON.stringify(userDetails));
  }

  private parseAuthResult(authResult): TokenDetails {
    return {
      tokenId: authResult.idToken,
      accessToken: authResult.accessToken,
      expiration: authResult.expiresIn * 1000 + new Date().getTime(),
    };
  }

  private makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  updateUserInfo(name: string, userRole: string) {
    return this.http.post(`${environment.beBaseURL}/api/v2/user/change_details`, { full_name: name, role: userRole });
  }

  updateOrganizationInfo(name: string) {
    return this.http.post(`${environment.beBaseURL}/api/v2/org/change_details`, { org_name: name });
  }

  checkOrganizationAvailability(name: string): Observable<{ available: boolean; error?: string }> {
    return this.http.post<{ available: boolean; error?: string }>(`${environment.beBaseURL}/api/v2/org/validate_name`, {
      org_name: name,
    });
  }

  getTeamMembers(): Observable<TeamMember[]> {
    return this.http.get<TeamMember[]>(`${environment.beBaseURL}/api/v2/org/fetch_members`);
  }

  getTeamMembersExtended(): Observable<TeamMember[]> {
    return this.http.get<TeamMember[]>(`${environment.beBaseURL}/api/v2/org/fetch_members_extended`);
  }

  setOrgMemberRole(userId: string, email: string, isPending: boolean, roles: string[]) {
    return this.http.post(`${environment.beBaseURL}/api/v2/user/set_org_roles${isPending ? '/pending' : ''}`, {
      user_id: userId,
      email,
      roles,
    });
  }

  deleteOrgMember(userId: string, email: string, isPending: boolean) {
    return this.http.request('DELETE', `${environment.beBaseURL}/api/v2/user/delete${isPending ? '/pending' : ''}`, {
      body: { user_id: userId, email },
    });
  }

  getAzureConnectionName(email): Observable<AzureConnectionResponse> {
    return this.http.get<AzureConnectionResponse>(
      `${environment.beBaseURL}/api/user/azure_enterprise_connection?email=${email}`,
    );
  }

  loginWithAzureToken(result) {
    const response: TokenDetails = this.parseAuthResult({
      accessToken: result.access_token,
      expiresIn: result.expires_in,
    });

    this.setTokenDetailsToLocalStorage(response);
    return response;
  }
}
