import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { delay, Observable, of } from 'rxjs';
import { Params } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { QueryParamsService } from '../../../../knowledge-base/services/query-params.service';
import { SigDetails, SigSuggestedGene, SigTabCounts, SigTotalCounts } from '../../models';
import { map } from 'rxjs/operators';
import { AnalysisResult } from '../../../analysis/modules/analysis-variant/models';
import { SigClassifiedVariant } from '../../models/sig-variant.model';
import {
  SearchConfig,
  variantIdToBody2,
  variantIdToHttpParams,
} from '../../../variant-page/modules/variant-page/models';
import { VariantInterpretation } from '../../../variant-page/modules/interpretation/models';
import { VariantId } from '../../../../store';
import { GeneCurations } from '../../../variant-page/modules/gene-curation/models';

@Injectable()
export class SigService {
  constructor(
    private http: HttpClient,
    private paramsService: QueryParamsService,
  ) {}

  getSigDetails(sigId: string): Observable<SigDetails> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.beBaseURL}/api/sig`;
    const params = new HttpParams().set('sig_id', sigId);

    return this.http.get<SigDetails>(url, { headers, params }).pipe(map((sig) => ({ ...sig, sig_id: sigId })));
  }

  getSigCounts(sigId: string): Observable<SigTotalCounts> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.beBaseURL}/api/sig/counts`;
    const params = new HttpParams().set('sig_id', sigId);

    return this.http.get<SigTotalCounts>(url, { headers, params });
  }

  getSuggestedVariants(
    sigId: string,
    annotationVersion: number,
    currentPage: number,
    pageSize: number,
    queryParams: Params,
  ): Observable<any> {
    const url = `${environment.beBaseURL}/api/sig/suggested_variants/search`;
    const body = this.paramsService.convertToBackendFormat('snp', queryParams, sigId, pageSize, currentPage, true);

    return this.http
      .post<{ results: AnalysisResult[] }>(url, { sig_id: sigId, ...body })
      .pipe(map(({ results }) => results));
  }

  getSuggestedVariantsCount(
    sigId: string,
    annotationVersion: number,
    currentPage: number,
    pageSize: number,
    queryParams: Params,
  ): Observable<SigTabCounts> {
    const url = `${environment.beBaseURL}/api/sig/suggested_variants/count`;
    const body = this.paramsService.convertToBackendFormat('snp', queryParams, sigId, pageSize, currentPage, true);

    return this.http.post<SigTabCounts>(url, { ...body, sig_id: sigId });
  }

  getClassifiedVariants(
    sigId: string,
    annotationVersion: number,
    currentPage: number,
    pageSize: number,
    queryParams: Params,
  ): Observable<any> {
    const url = `${environment.beBaseURL}/api/sig/classified_variants/search`;
    const body = this.paramsService.convertToBackendFormat('snp', queryParams, sigId, pageSize, currentPage, true);

    return this.http.post<{ variants: SigClassifiedVariant[]; total_count: number; genes_count: number }>(url, {
      ...body,
      sig_id: sigId,
    });
  }

  classifyVariant(
    variantId: VariantId,
    interpretation: VariantInterpretation,
    caseContext: SearchConfig,
    sigId: string,
  ) {
    const url = `${environment.beBaseURL}/api/sig/variant/classification`;
    const body = { ...interpretation, ...variantIdToBody2(variantId) };

    return this.http.put<VariantInterpretation>(url, { ...body, sig_id: sigId });
  }

  getVariantClassification(variantId: VariantId, sigId: string): Observable<VariantInterpretation> {
    const params = variantIdToHttpParams(variantId).append('sig_id', sigId);
    const url = `${environment.beBaseURL}/api/sig/variant/classification`;

    return this.http.get<VariantInterpretation>(url, { params });
  }

  getCuratedGenes(
    sigId: string,
    annotationVersion: number,
    currentPage: number,
    pageSize: number,
    queryParams: Params,
  ): Observable<any> {
    const url = `${environment.beBaseURL}/api/sig/genes/search_curated`;
    const body = this.paramsService.convertToBackendFormat('snp', queryParams, sigId, pageSize, currentPage, true);

    return this.http.post<{ genes: GeneCurations[]; count: number }>(url, { ...body, sig_id: sigId });
  }

  getSuggestedGenes(
    sigId: string,
    annotationVersion: number,
    currentPage: number,
    pageSize: number,
    queryParams: Params,
  ): Observable<any> {
    const url = `${environment.beBaseURL}/api/sig/genes/search_suggested`;
    const body = this.paramsService.convertToBackendFormat('snp', queryParams, sigId, pageSize, currentPage, true);

    return this.http.post<{ genes: SigSuggestedGene[]; count: number }>(url, { ...body, sig_id: sigId });
  }

  setSuggestedGeneIrrelevant(sigId: string, geneSymbol: string) {
    return of(true).pipe(delay(1000));
  }
}
