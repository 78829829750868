import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import * as fromReducer from '../reducers/last-used-filter.reducer';
import * as fromActions from '../actions/last-used-filter.action';
import * as fromFilterStructureAction from './../actions/filters-structure.action';

import { FiltersService } from '../services/filters.service';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { UpdateQueryParams } from '../../../../../../store/actions';
import { AnalysisDetails, AppModuleState, getAnalysisDetails, getDiscoveryAnalysisType, getVariantType } from '../../../../../../store';
import { VariantType } from '../../../analysis-variant/models';
import { filtersArrayToDict } from '../selectors';
import { DiscoveryAnalysisType } from '../../../../../discovery/store/models/discovery.model';
import { FilterTreeLabels, filterTreeLabelsToUIFilterModel } from '../models/filter-tree.model';
import { loadFilterTreeLabelsSuccess } from '../actions';
import { SearchConfig } from '../../../../../variant-page/modules/variant-page/models';
import { getPatientInfoResponse, WorkbenchModuleState } from '../../../workbench/store';

@Injectable()
export class LastUsedFilterEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.LastUsedFilterState>,
    private rootStore$: Store<AppModuleState>,
    private wbStore$: Store<WorkbenchModuleState>,
    private filterService: FiltersService,
  ) {}

  loadLastUsedFilterAndStructure: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LOAD_LAST_USED_FILTER_AND_STRUCTURE),
      withLatestFrom(
        this.rootStore$.pipe(select(getDiscoveryAnalysisType)),
        this.wbStore$.pipe(select(getPatientInfoResponse)),
      ),
      switchMap(
        ([action, discoveryAnalysisType, caseDetails]: [
          fromActions.LoadLastUsedFilterAndStructure,
          DiscoveryAnalysisType,
          SearchConfig,
        ]) =>
          combineLatest([
            this.filterService.getFilterTreeLabels(action.analysisId, action.analysisType, action.variantType),
            this.filterService.getFiltersStructure(
              action.analysisId,
              action.analysisType,
              action.variantType,
              discoveryAnalysisType,
            ),
          ]).pipe(
            switchMap(([filters, structure]) => {
              return [
                loadFilterTreeLabelsSuccess({ payload: filters  }),
                new fromActions.LoadLastUsedFilterSuccess(),
                new fromFilterStructureAction.LoadFiltersStructureSuccess(structure),
                new UpdateQueryParams(this.getLastUsedFromLabels(filters, caseDetails), true),
              ];
            }),
            catchError((error) => [
              new fromActions.LoadLastUsedFilterFail(error),
              new fromFilterStructureAction.LoadFiltersStructureFail(error),
            ]),
          ),
      ),
    ),
  );

  getLastUsedFromLabels(labels: FilterTreeLabels, caseDetails: SearchConfig): { [key: string]: string[]} {
    if (!!labels.last_search) {
      return JSON.parse(labels.last_search);
    } else {
      const quickFilters = filterTreeLabelsToUIFilterModel(labels).filter((f) => f.quick_filter);
      let initialFilter = quickFilters.length ? quickFilters[0].filter_content : {};
      const initialOtherPanels = !!initialFilter?.other_panels
        ? typeof initialFilter.other_panels === 'string'
          ? [initialFilter.other_panels]
          : initialFilter.other_panels
        : [];
      const initialKbPanels = !!initialFilter?.kb_panels
        ? typeof initialFilter.kb_panels === 'string'
          ? [initialFilter.kb_panels]
          : initialFilter.kb_panels
        : [];
      initialFilter = {
        ...initialFilter,
        other_panels: [...new Set([...initialOtherPanels, ...initialKbPanels])],
        kb_panels: (caseDetails.kb_panels || []).map(x => x.id),
        phenotypes: caseDetails.phenotypes
      };
      return initialFilter;
    }
  }
}
