import { Action } from '@ngrx/store';
import { Publication, PublicationsQuery, PublicationsResponse } from '../models';
import { AddPublicationRequest } from '../models/add-publication-request.model';
import { VariantId } from '../../../variant-page/models';

export const GET_PUBLICATIONS = '[Publications] Get Publications';
export const GET_PUBLICATIONS_SUCCESS = '[Publications] Get Publications Success';
export const GET_PUBLICATIONS_FAIL = '[Publications] Get Publications Fail';
export const CLEAR_PUBLICATIONS_DATA = '[Publications] Clear Publications Data';

export class GetPublications implements Action {
  readonly type = GET_PUBLICATIONS;

  constructor(public query: PublicationsQuery) {}
}

export class GetPublicationsSuccess implements Action {
  readonly type = GET_PUBLICATIONS_SUCCESS;

  constructor(public payload: PublicationsResponse) {}
}

export class GetPublicationsFail implements Action {
  readonly type = GET_PUBLICATIONS_FAIL;

  constructor(public error: any) {}
}

export const GET_MASTERMIND_LINK = '[Publications] Get Mastermind Link';
export const GET_MASTERMIND_LINK_SUCCESS = '[Publications] Get Mastermind Link Success';

export class GetMastermindLink implements Action {
  readonly type = GET_MASTERMIND_LINK;

  constructor(public variantId: VariantId) {}
}

export class GetMastermindLinkSuccess implements Action {
  readonly type = GET_MASTERMIND_LINK_SUCCESS;

  constructor(public payload: string) {}
}

export const GET_MORE_PUBLICATIONS = '[Publications] Get More Publications';
export const GET_MORE_PUBLICATIONS_SUCCESS = '[Publications] Get More Publications Success';
export const GET_MORE_PUBLICATIONS_FAIL = '[Publications] Get More Publications Fail';

export class GetMorePublications implements Action {
  readonly type = GET_MORE_PUBLICATIONS;

  constructor(public query: PublicationsQuery) {}
}

export class GetMorePublicationsSuccess implements Action {
  readonly type = GET_MORE_PUBLICATIONS_SUCCESS;

  constructor(public payload: PublicationsResponse) {}
}

export class GetMorePublicationsFail implements Action {
  readonly type = GET_MORE_PUBLICATIONS_FAIL;

  constructor(public error: any) {}
}

export class ClearPublicationsData implements Action {
  readonly type = CLEAR_PUBLICATIONS_DATA;
}

export const HIGHLIGHT_TEXT = '[Publications] Highlight text';
export const UNHIGHLIGHT_TEXT = '[Publications] Unhighlight text';

export class HighlightText implements Action {
  readonly type = HIGHLIGHT_TEXT;

  constructor(
    public publication: Publication,
    public text: string,
  ) {}
}

export class UnhighlightText implements Action {
  readonly type = UNHIGHLIGHT_TEXT;

  constructor(
    public publication: Publication,
    public text: string,
  ) {}
}

export const MARK_PUBLICATION = '[Publications] Mark publication';

export class MarkPublication implements Action {
  readonly type = MARK_PUBLICATION;

  constructor(
    public publication: Publication,
    public value: boolean,
  ) {}
}

export const MANUALLY_ACTIVATE_TAG = '[Publications] Manually activate tag';

export class ManuallyActivateTag implements Action {
  readonly type = MANUALLY_ACTIVATE_TAG;

  constructor(
    public publication: Publication,
    public tag: string,
    public activate: boolean,
  ) {}
}

export const SELECT_TAB_INDEX = '[Publications] Select Tab Index';

export class SelectTabIndex implements Action {
  readonly type = SELECT_TAB_INDEX;

  constructor(public index: number) {}
}

export const SELECT_PUBLICATION = '[Publications] Select Publication';

export class SelectPublication implements Action {
  readonly type = SELECT_PUBLICATION;

  constructor(public id: string) {}
}

export const ADD_PUBLICATION = '[Publication] Add publication request';
export const ADD_PUBLICATION_SUCCESS = '[Publication] Add publication submit success';
export const ADD_PUBLICATION_FAIL = '[Publication] Add publication submit fail';
export const RESET_ADD_PUBLICATION = '[Publication] Reset Add publication form';

export class AddPublication implements Action {
  readonly type = ADD_PUBLICATION;

  constructor(public input: AddPublicationRequest) {}
}

export class AddPublicationSuccess implements Action {
  readonly type = ADD_PUBLICATION_SUCCESS;

  constructor() {}
}

export class AddPublicationFailure implements Action {
  readonly type = ADD_PUBLICATION_FAIL;

  constructor() {}
}

export class ResetAddPublication implements Action {
  readonly type = RESET_ADD_PUBLICATION;

  constructor() {}
}

export const GET_PUBLICATIONS_COUNT_BY_SCOPE = '[Publications] Get publications count by scope';
export const GET_PUBLICATIONS_COUNT_BY_SCOPE_SUCCESS = '[Publications] Get publications count by scope success';
export const GET_PUBLICATIONS_COUNT_BY_SCOPE_FAIL = '[Publications] Get publications count by scope fail';

export class GetPublicationsCountByScope implements Action {
  readonly type = GET_PUBLICATIONS_COUNT_BY_SCOPE;

  constructor(public query: PublicationsQuery) {}
}

export class GetPublicationsCountByScopeSuccess implements Action {
  readonly type = GET_PUBLICATIONS_COUNT_BY_SCOPE_SUCCESS;

  constructor(public payload: { [key: string]: number }) {}
}

export class GetPublicationsCountByScopeFail implements Action {
  readonly type = GET_PUBLICATIONS_COUNT_BY_SCOPE_FAIL;

  constructor(public error: any) {}
}

export type PublicationsAction =
  | GetPublications
  | GetPublicationsSuccess
  | GetPublicationsFail
  | GetMastermindLink
  | GetMastermindLinkSuccess
  | GetMorePublications
  | GetMorePublicationsSuccess
  | GetMorePublicationsFail
  | HighlightText
  | UnhighlightText
  | MarkPublication
  | ManuallyActivateTag
  | ClearPublicationsData
  | SelectTabIndex
  | SelectPublication
  | AddPublication
  | AddPublicationSuccess
  | AddPublicationFailure
  | ResetAddPublication
  | GetPublicationsCountByScope
  | GetPublicationsCountByScopeSuccess
  | GetPublicationsCountByScopeFail;
