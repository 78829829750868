export enum CaseCustomActionType {
  UserAccess = 'access_list',
  Status = 'status',
  Assignee = 'assignee',
  Labels = 'labels',
}

export interface CaseCustomActionUserAccessData {
  access_list: {
    add: string[];
    remove: string[];
  };
}

export interface CaseCustomActionStatusData {
  status: string;
}

export interface CaseCustomActionAssigneeData {
  assignee: string;
}

export interface CaseCustomActionLabelsData {
  labels: {
    [key: string]: {
      add: string[];
      remove: string[];
    };
  };
}

export type CaseCustomActionData =
  | CaseCustomActionUserAccessData
  | CaseCustomActionStatusData
  | CaseCustomActionAssigneeData
  | CaseCustomActionLabelsData;

export interface CaseCustomAction {
  type: CaseCustomActionType;
  data: CaseCustomActionData;
}
