import { CaseSummary } from '../models';

import * as fromAction from '../actions';

export interface CaseSummaryState {
  caseSummary: CaseSummary;
  treatmentInReportSaving: { [treatmentId: string]: boolean };
  loading: boolean;
  loaded: boolean;
  error: boolean;
}

const initialState: CaseSummaryState = {
  caseSummary: null,
  loading: false,
  loaded: false,
  error: null,
  treatmentInReportSaving: {},
};

export function reducer(state = initialState, action: fromAction.CaseSummaryAction): CaseSummaryState {
  switch (action.type) {
    case fromAction.LOAD_CASE_SUMMARY: {
      return {
        ...state,
        caseSummary: null,
        loading: true,
        loaded: false,
      };
    }
    case fromAction.LOAD_CASE_SUMMARY_SUCCESS: {
      return {
        ...state,
        caseSummary: action.caseSummary,
        loading: false,
        loaded: true,
      };
    }
    case fromAction.LOAD_CASE_SUMMARY_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case fromAction.SET_TREATMENT_INCLUDE_IN_REPORT: {
      return {
        ...state,
        treatmentInReportSaving: {
          ...state.treatmentInReportSaving,
          [`${action.analysisId}__${action.treatmentId}`]: true,
        },
      };
    }
    case fromAction.SET_TREATMENT_INCLUDE_IN_REPORT_SUCCESS: {
      return {
        ...state,
        treatmentInReportSaving: {
          ...state.treatmentInReportSaving,
          [`${action.analysisId}__${action.treatmentId}`]: false,
        },
        caseSummary: {
          treatments_data: {
            ...state.caseSummary.treatments_data,
            treatments: state.caseSummary.treatments_data.treatments.map((x) =>
              x.id === action.treatmentId ? { ...x, in_report: action.inReport } : x,
            ),
          },
        },
      };
    }
    case fromAction.SET_TREATMENT_INCLUDE_IN_REPORT_FAIL: {
      return {
        ...state,
        treatmentInReportSaving: {
          ...state.treatmentInReportSaving,
          [`${action.analysisId}__${action.treatmentId}`]: false,
        }
      };
    }
  }

  return state;
}

export const getCaseSummary = (state: CaseSummaryState) => state.caseSummary;
export const getCaseSummaryLoading = (state: CaseSummaryState) => state.loading;
export const getCaseSummaryLoaded = (state: CaseSummaryState) => state.loaded;
export const getCaseSummaryError = (state: CaseSummaryState) => state.error;
