<gnx-top-nav [activeMiniApp]="MiniApps.ClinicalDB"></gnx-top-nav>
<div class="header-container">
  <gnx-breadcrumb [crumbs]="crumbs"></gnx-breadcrumb>
  <div class="page-header">
    <strong>Scientific Publications</strong>
    <div class="citation-info">
      <span class="info-title">Citing Franklin</span>
      <strong>We highly appreciate your wish to cite us in your publication.</strong>
      We are in the process of completing publishing a peer-reviewed paper on Franklin and the methodology used. For
      now, please cite us using our website address:
      <strong>https://franklin.genoox.com - Franklin by Genoox</strong>
    </div>
  </div>
</div>
<div class="citations-container">
  <div *ngIf="citationsResponse$ | async as response" class="citations">
    <a *ngFor="let citation of response?.citations" [href]="citation.link" target="_blank">
      <gnx-citations-tile [citation]="citation"></gnx-citations-tile>
    </a>
  </div>
</div>
