import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { ClickCursorDirective } from '../../../../../../shared/directives/click-cursor.directive';

@Component({
  selector: 'gnx-add-comment-to-variant-dialog',
  templateUrl: './add-comment-to-variant-dialog.component.html',
  styleUrls: [
    '../add-evidence-to-variant-dialog/add-evidence-to-variant-dialog.component.scss',
    './add-comment-to-variant-dialog.component.scss',
  ],
  standalone: true,
  imports: [
    MatDialogTitle,
    ClickCursorDirective,
    CdkTextareaAutosize,
    ReactiveFormsModule,
    FormsModule,
    MatCheckbox,
    MatDialogActions,
  ],
})
export class AddCommentToVariantDialogComponent implements OnInit, OnDestroy {
  private destroyed$: Subject<void> = new Subject();
  public commentText = '';
  public isPrivate = false;

  constructor(
    private dialogRef: MatDialogRef<AddCommentToVariantDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { varId: string },
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.destroyed$.next();
  }

  onClose() {
    this.dialogRef.close();
  }

  saveChanges() {
    this.dialogRef.close({ comment: this.commentText, isPrivate: this.isPrivate });
  }
}
