import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { KbPanelSuggestionResponse, PanelSuggestionResponse } from '../models';
import { environment } from '../../../environments/environment';
import { PanelsResponse } from '../../knowledge-base/modules/panels/store';
import { PanelLocation } from '../../modules/variant-page/modules/variant-page/models';

@Injectable()
export class PanelsService {
  constructor(private http: HttpClient) {}

  getPanels(input: string, analysisId?: number): Observable<PanelSuggestionResponse> {
    let params = new HttpParams()
      .append('search', input)
      .append('page_size', Number.MAX_SAFE_INTEGER.toString())
      .append('ordering', 'name');

    if (analysisId) {
      params = params.append('analysis_id', analysisId.toString());
    }
    return this.http.get<PanelSuggestionResponse>(`${environment.beBaseURL}/api/api/panels`, { params });
  }

  getKbPanels(search: string, order: string, analysisId: number, assayId?: string): Observable<PanelsResponse> {
    let params = new HttpParams();
    if (analysisId) {
      params = params.append('analysis_id', analysisId.toString());
    }
    if (!!assayId) {
      params = params.append('assay', assayId.toString());
    }
    return this.http.get<PanelsResponse>(`${environment.beBaseURL}/api/kb/panels/fetch_all`, { params });
  }

  getCasePanels(panelIds: string[]): Observable<PanelsResponse> {
    const body = {
      panel_ids: panelIds,
    };
    return this.http.post<PanelsResponse>(`${environment.beBaseURL}/api/kb/panels/fetch`, body);
  }

  getAllPanelsWithVersions(): Observable<PanelsResponse> {
    return this.http.get<PanelsResponse>(`${environment.beBaseURL}/api/kb/panels/fetch_all_versions`, {});
  }

  getPanelGenes(
    locationString: string,
    panelId: string,
    limit: number,
    analysisId?: number,
  ): Observable<PanelSuggestionResponse> {
    let params = new HttpParams().append('page_size', limit?.toString() || Number.MAX_SAFE_INTEGER.toString());
    if (analysisId) {
      params = params.append('analysis_id', analysisId.toString());
    }

    return this.http.get<PanelSuggestionResponse>(
      `${environment.beBaseURL}/api/api/panels/${locationString}/${panelId}/genes`,
      { params },
    );
  }

  getKbPanelGenes(panelId: string, location: PanelLocation): Observable<KbPanelSuggestionResponse> {
    if (location === PanelLocation.Assay) {
      return this.http.post<KbPanelSuggestionResponse>(
        `${environment.beBaseURL}/api/kb/genes/search?assay_panel=${panelId}`,
        {
          from_index: 0,
          to_index: 20,
        },
      );
    } else {
      return this.http.post<KbPanelSuggestionResponse>(`${environment.beBaseURL}/api/kb/genes/search`, {
        panel_ids_query: {
          panel_ids: [panelId],
        },
        from_index: 0,
        to_index: 20,
      });
    }
  }

  getKbGenePanels(gene: string): Observable<PanelsResponse> {
    return this.http.post<PanelsResponse>(`${environment.beBaseURL}/api/kb/panels/fetch_by_gene`, {
      gene_symbol: gene,
    });
  }
}
