import { AnalysisAdditionalInformationGermlineBiomarker, AnalysisGermlineBiomarkerType } from '../models';
import * as fromAction from './../actions/analysis-additional-information.action';

interface DataWithLoading<T> {
  data?: T;
  loading: boolean;
  loaded: boolean;
}

export interface AnalysisAdditionalInformationState {
  supportedTypes?: DataWithLoading<AnalysisGermlineBiomarkerType[]>;
  germlineBiomarkers: DataWithLoading<AnalysisAdditionalInformationGermlineBiomarker[]>;
}

const initialState: AnalysisAdditionalInformationState = {
  supportedTypes: {
    loading: false,
    loaded: false,
  },
  germlineBiomarkers: {
    loading: false,
    loaded: false,
  },
};

export function reducer(
  state = initialState,
  action: fromAction.AnalysisAdditionalInformationAction,
): AnalysisAdditionalInformationState {
  switch (action.type) {
    case fromAction.LOAD_ANALYSIS_ADDITIONAL_INFORMATION_TYPES: {
      return {
        ...initialState,
        supportedTypes: {
          data: undefined,
          loading: true,
          loaded: false,
        },
      };
    }
    case fromAction.LOAD_ANALYSIS_ADDITIONAL_INFORMATION_TYPES_SUCCESS: {
      return {
        ...state,
        supportedTypes: {
          data: action.payload.supported_types,
          loading: false,
          loaded: true,
        },
      };
    }
    case fromAction.LOAD_ANALYSIS_ADDITIONAL_INFORMATION_TYPES_FAIL: {
      return {
        ...state,
        supportedTypes: {
          data: [...(state.supportedTypes?.data || [])],
          loading: false,
          loaded: false,
        },
      };
    }
    case fromAction.LOAD_ANALYSIS_GERMLINE_BIOMARKERS: {
      return {
        ...state,
        germlineBiomarkers: {
          data: [],
          loading: true,
          loaded: false,
        },
      };
    }
    case fromAction.LOAD_ANALYSIS_GERMLINE_BIOMARKERS_SUCCESS: {
      return {
        ...state,
        germlineBiomarkers: {
          data: action.data,
          loading: false,
          loaded: true,
        },
      };
    }
    case fromAction.LOAD_ANALYSIS_GERMLINE_BIOMARKERS_FAIL: {
      return {
        ...state,
        germlineBiomarkers: {
          ...state.germlineBiomarkers,
          loading: false,
          loaded: false,
        },
      };
    }
    case fromAction.CHANGE_GERMLINE_BIOMARKER_IN_REPORT: {
      const data = state.germlineBiomarkers.data.map((marker) =>
        marker.data.biomarker_id === action.biomarkerId
          ? {
              ...marker,
              data: {
                ...marker.data,
                inReportChangeInProgress: true,
              },
            }
          : { ...marker },
      );

      return {
        ...state,
        germlineBiomarkers: {
          ...state.germlineBiomarkers,
          data,
        },
      };
    }
    case fromAction.CHANGE_GERMLINE_BIOMARKER_IN_REPORT_SUCCESS: {
      const data = state.germlineBiomarkers.data.map((marker) =>
        marker.data.biomarker_id === action.biomarkerId
          ? {
              ...marker,
              data: {
                ...marker.data,
                inReportChangeInProgress: false,
                in_report: action.value,
              },
            }
          : { ...marker },
      );

      return {
        ...state,
        germlineBiomarkers: {
          ...state.germlineBiomarkers,
          data,
        },
      };
    }
    case fromAction.CHANGE_GERMLINE_BIOMARKER_IN_REPORT_FAIL: {
      const data = state.germlineBiomarkers.data.map((marker) =>
        marker.data.biomarker_id === action.biomarkerId
          ? {
              ...marker,
              data: {
                ...marker.data,
                inReportChangeInProgress: false,
              },
            }
          : { ...marker },
      );

      return {
        ...state,
        germlineBiomarkers: {
          ...state.germlineBiomarkers,
          data,
        },
      };
    }

    case fromAction.CHANGE_GERMLINE_BIOMARKER_NOT_RELEVANT: {
      const data = state.germlineBiomarkers.data.map((marker) =>
        marker.data.biomarker_id === action.biomarkerId ? {
           ...marker,
              data: {
              ...marker.data,
                irrelevantChangeInProgress: true,
              },
            } : { ...marker },
        );

      return {
        ...state,
        germlineBiomarkers: {
          ...state.germlineBiomarkers,
          data,
        },
      };
    }

    case fromAction.CHANGE_GERMLINE_BIOMARKER_NOT_RELEVANT_SUCCESS: {
      const data = state.germlineBiomarkers.data.map((marker) =>
        marker.data.biomarker_id === action.biomarkerId
         ? {
             ...marker,
              data: {
               ...marker.data,
                irrelevantChangeInProgress: false,
                irrelevant_data: action.irrelevantData,
              },
            }
          : { ...marker },
      );

      return {
        ...state,
        germlineBiomarkers: {
         ...state.germlineBiomarkers,
          data,
        },
      };
    }

    case fromAction.CHANGE_GERMLINE_BIOMARKER_NOT_RELEVANT_FAIL: {
      const data = state.germlineBiomarkers.data.map((marker) =>
        marker.data.biomarker_id === action.biomarkerId
         ? {
             ...marker,
              data: {
               ...marker.data,
                irrelevantChangeInProgress: false,
              },
            }
          : { ...marker },
      );

      return {
       ...state,
        germlineBiomarkers: {
         ...state.germlineBiomarkers,
          data,
        },
      };
    }
  }

  return state;
}
