import { AnalysisType } from '../../../store/models';

export enum CreateCaseDialogSection {
  phenotypes = 'phenotypes',
  sampleInfo = 'sample-info',
  panels = 'panels',
  variantInfo = 'variant-info',
  patientInfo = 'patient-info',
  familyMembers = 'family-members',
  comments = 'comments',
  caseResolution = 'case-resolution',
  sampleDetails = 'sample-details',
}

export const getDefaultCaseSectionsForAnalysisType: (analysisType: AnalysisType) => CreateCaseDialogSection[] = (
  analysisType,
) => {
  switch (analysisType) {
    case AnalysisType.sample:
    case AnalysisType.overgrowth: {
      return [
        CreateCaseDialogSection.patientInfo,
        CreateCaseDialogSection.sampleInfo,
        CreateCaseDialogSection.phenotypes,
        CreateCaseDialogSection.panels,
        CreateCaseDialogSection.comments,
      ];
    }
    case AnalysisType.family: {
      return [
        CreateCaseDialogSection.patientInfo,
        CreateCaseDialogSection.sampleInfo,
        CreateCaseDialogSection.phenotypes,
        CreateCaseDialogSection.familyMembers,
        CreateCaseDialogSection.panels,
        CreateCaseDialogSection.comments,
      ];
    }
    case AnalysisType.tumor: {
      return [
        CreateCaseDialogSection.patientInfo,
        CreateCaseDialogSection.sampleInfo,
        CreateCaseDialogSection.panels,
        CreateCaseDialogSection.comments,
      ];
    }
    default: {
      return [];
    }
  }
};

export interface CreateCaseDialogSectionMenuConfig {
  name: string;
  progressValue?: () => number;
  progressMax?: () => number;
}
