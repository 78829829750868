import { Params } from '@angular/router';

export interface QueryParams extends Params {
  search: string;
  shared_by_me: boolean;
  followed: boolean;
  organization?: string[];
  organization_rule?: string;
  classification?: string[];
  inheritance?: string[];
  variant_type?: string[];
  region?: string[];
  frequency?: string[];
  sorting?: string;
  hide_suggested?: any;
  somatic_classification: string[];
}

export interface BackendQueryParams {
  communityId?: string;
  organizationQuery?: BEQueryParamsOrganization;
  interpretationQuery?: BEQueryParamsInterpretation;
  variantDataQuery?: BEQueryParamsVariantData;
  svVariantDataQuery?: BEQueryParamsSvVariantData;
  followedVariantsQuery?: BEQueryParamsFollowedVariants;
  variantTypeQuery: BEQueryParamsVariantType;
  sorting?: BEQueryParamsSorting;
  fromIndex?: number;
  toIndex?: number;
  searchText?: string[];
  panel_ids_query?: { [key: string]: any };
}

export interface GenesBackendQueryParams {
  sorting?: BEQueryParamsSorting;
  fromIndex?: number;
  toIndex?: number;
  searchText?: string[];
  panel_ids_query?: { [key: string]: any };
  geneSymbolQuery?: { names: string[] };
  curationQuery?: {
    characterizationStatus: string[];
  };
}

export interface BackendQueryParamsSnakeCase {
  community_id?: string;
  organization_query?: BEQueryParamsOrganization;
  interpretation_query?: BEQueryParamsInterpretation;
  variant_data_query?: BEQueryParamsVariantData;
  sv_variant_data_query?: BEQueryParamsSvVariantData;
  followed_variants_query?: BEQueryParamsFollowedVariants;
  variant_type_query: BEQueryParamsVariantType;
  sorting?: BEQueryParamsSorting;
  from_index?: number;
  to_index?: number;
  search_text?: string[];
  panel_ids_query?: { [key: string]: any };
}

export interface GenesBackendQueryParamsSnakeCase {
  sorting?: BEQueryParamsSorting;
  from_index?: number;
  to_index?: number;
  search_text?: string[];
  panel_ids_query?: { [key: string]: any };
  gene_symbol_query: { names?: string[] };
  search_terms?: string[];
  curation_query?: {
    characterization_status: string[];
  };
}

export interface BEQueryParamsSorting {
  sortBy?: string;
  sort_by?: string;
  direction?: BEQueryParamsSortDirection;
}

export enum BEQueryParamsSortDirection {
  INCR = 1,
  DECR = 2,
}

export interface BEQueryParamsOrganization {
  organizationIds?: string[];
  organization_ids?: string[];
  rule?: string;
}

export interface BEQueryParamsInterpretation {
  assessment?: string[];
  somaticAssessments?: string[];
  inheritanceModel?: string[];
  inheritance_model?: string[];
  userId?: string;
  user_id?: string;
  suggested?: boolean;
  assignee?: string[];
}

export interface BEQueryRangeFilter {
  intervals?: {
    from?: number;
    to?: number;
    intervalType?: string;
    interval_type?: string;
  }[];
  allowNotAvailable?: boolean;
  allow_not_available?: boolean;
}

export interface BEQueryParamsVariantData {
  variantType?: string[];
  variant_type?: string[];
  region?: string[];
  frequency?: BEQueryRangeFilter;
}

export interface BEQueryParamsSvVariantData {
  svType?: string[];
  sv_type?: string[];
  region?: string[];
}

export interface BEQueryParamsFollowedVariants {
  userId?: string;
  user_id?: string;
}

export interface BEQueryParamsVariantType {
  generalVariantType?: string[];
  general_variant_type?: string[];
}
