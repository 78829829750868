export enum AnalysisWorkflowStatus {
  Active = 1,
  Suspended,
  Resolved,
  Creating,
  Review,
  NoFiles,
}

export interface AnalysisWorkflowOptionConfig {
  label: string;
  class: string;
}

export const AnalysisWorkflowConfig: { [key in AnalysisWorkflowStatus]: AnalysisWorkflowOptionConfig } = {
  [AnalysisWorkflowStatus.Active]: {
    label: 'Active',
    class: 'active',
  },
  [AnalysisWorkflowStatus.Suspended]: {
    label: 'Suspended',
    class: 'suspended',
  },
  [AnalysisWorkflowStatus.Resolved]: {
    label: 'Resolved',
    class: 'resolved',
  },
  [AnalysisWorkflowStatus.Creating]: {
    label: 'Creating',
    class: 'creating',
  },
  [AnalysisWorkflowStatus.Review]: {
    label: 'Pending review',
    class: 'review',
  },
  [AnalysisWorkflowStatus.NoFiles]: {
    label: 'Pending samples',
    class: 'no-files',
  },
};

export const analysisStatusDefaultUserOptions = [
  AnalysisWorkflowStatus.Active,
  AnalysisWorkflowStatus.Review,
  AnalysisWorkflowStatus.Suspended,
  AnalysisWorkflowStatus.Resolved,
];
