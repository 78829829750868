import { Injectable } from '@angular/core';
import {
  ClassificationMetadata,
  ClassificationMetadataMap,
  ClassificationType,
  InterpretationCustomSubTiersConfig,
} from './classification-metadata.model';
import {
  PlatformGermlineClassificationBitmap,
  ProtoGermlineClassificationEnum,
  ProtoInterpretationAssessmentEnum,
} from '../../../shared/utils/classification.utils';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { take } from 'rxjs/operators';

const GERMLINE_CLASSIFICATIONS: ClassificationMetadata[] = [
  {
    value: 0,
    platformValue: PlatformGermlineClassificationBitmap.NONE,
    protoValue: ProtoGermlineClassificationEnum.UNDEFINED_CLASSIFICATION,
    protoInterpretationValue: ProtoInterpretationAssessmentEnum.NONE,
    key: 'NONE',
    label: 'Choose Classification',
    name: 'No classification',
    shortName: 'NONE',
    colorClass: 'none',
    class: 'cls-na',
    color: '#f6f8f8',
    textColor: '#222',
    alternateTextColor: 'white',
    borderColor: '#4eadf2',
    sourceTypes: [ClassificationType.User, ClassificationType.Community, ClassificationType.Genoox],
  },
  {
    value: 1,
    platformValue: PlatformGermlineClassificationBitmap.PATHOGENIC,
    protoValue: ProtoGermlineClassificationEnum.PATHOGENIC,
    protoInterpretationValue: ProtoInterpretationAssessmentEnum.PATHOGENIC,
    key: 'PATHOGENIC',
    label: 'Confirmed as Pathogenic',
    name: 'Pathogenic',
    shortName: 'P',
    colorClass: 'pathogenic',
    class: 'cls-pathogenic',
    color: '#e03a39',
    sourceTypes: [
      ClassificationType.User,
      ClassificationType.Community,
      ClassificationType.Genoox,
      ClassificationType.VariantFromReport,
    ],
  },
  {
    value: 2,
    platformValue: PlatformGermlineClassificationBitmap.SUSPECTED_PATHOGENIC,
    protoValue: ProtoGermlineClassificationEnum.LIKELY_PATHOGENIC,
    protoInterpretationValue: ProtoInterpretationAssessmentEnum.SUSPECTED_PATHOGENIC,
    key: 'SUSPECTED_PATHOGENIC',
    label: 'Suspected as Pathogenic',
    name: 'Likely Pathogenic',
    shortName: 'LP',
    colorClass: 'likely-pathogenic',
    class: 'cls-likely-pathogenic',
    color: '#f19a29',
    sourceTypes: [
      ClassificationType.User,
      ClassificationType.Community,
      ClassificationType.Genoox,
      ClassificationType.VariantFromReport,
    ],
  },
  {
    value: 6,
    platformValue: PlatformGermlineClassificationBitmap.VUS,
    protoValue: ProtoGermlineClassificationEnum.UNCERTAIN_SIGNIFICANCE,
    protoInterpretationValue: ProtoInterpretationAssessmentEnum.VUS,
    key: 'VUS',
    label: 'VUS (Uncertain)',
    name: 'VUS',
    shortName: 'VUS',
    colorClass: 'uncertain',
    class: 'cls-uncertain',
    color: '#919fa5',
    sourceTypes: [
      ClassificationType.User,
      ClassificationType.Community,
      ClassificationType.Genoox,
      ClassificationType.VariantFromReport,
    ],
  },
  {
    value: 7,
    platformValue: PlatformGermlineClassificationBitmap.VUS_PATHOGENIC_MODERATE,
    protoValue: ProtoGermlineClassificationEnum.POSSIBLY_PATHOGENIC_MODERATE,
    key: 'VUS_PATHOGENIC_MODERATE',
    label: 'VUS (Uncertain) Leaning to pathogenic',
    name: 'VUS Pathogenic',
    shortName: 'VUS',
    colorClass: 'uncertain_pathogenic',
    class: 'cls-uncertain-pathogenic',
    color: '#FACF34',
    sourceTypes: [ClassificationType.Genoox],
    parentClassificationKey: 'VUS',
  },
  {
    value: 8,
    key: 'VUS_PATHOGENIC_LOW',
    platformValue: PlatformGermlineClassificationBitmap.VUS_PATHOGENIC_LOW,
    protoValue: ProtoGermlineClassificationEnum.POSSIBLY_PATHOGENIC_LOW,
    label: 'VUS (Uncertain) Leaning to pathogenic (low)',
    name: 'VUS Pathogenic',
    shortName: 'VUS',
    colorClass: 'uncertain_pathogenic',
    color: '#FACF34',
    class: 'cls-uncertain-pathogenic',
    sourceTypes: [ClassificationType.Genoox],
    parentClassificationKey: 'VUS',
  },
  {
    value: 9,
    key: 'VUS_BENIGN',
    platformValue: PlatformGermlineClassificationBitmap.VUS_BENIGN,
    protoValue: ProtoGermlineClassificationEnum.POSSIBLY_BENIGN,
    label: 'VUS (Uncertain) Leaning to benign',
    name: 'VUS Benign',
    shortName: 'VUS',
    colorClass: 'uncertain_benign',
    color: '#9ADAF0',
    class: 'cls-uncertain-benign',
    sourceTypes: [ClassificationType.Genoox],
    parentClassificationKey: 'VUS',
  },
  {
    value: 4,
    platformValue: PlatformGermlineClassificationBitmap.SUSPECTED_BENIGN,
    protoValue: ProtoGermlineClassificationEnum.LIKELY_BENIGN,
    protoInterpretationValue: ProtoInterpretationAssessmentEnum.SUSPECTED_BENIGN,
    key: 'SUSPECTED_BENIGN',
    label: 'Suspected as Benign',
    name: 'Likely Benign',
    shortName: 'LB',
    colorClass: 'likely-benign',
    class: 'cls-likely-benign',
    color: '#217EC6',
    sourceTypes: [
      ClassificationType.User,
      ClassificationType.Community,
      ClassificationType.Genoox,
      ClassificationType.VariantFromReport,
    ],
  },
  {
    value: 3,
    platformValue: PlatformGermlineClassificationBitmap.BENIGN,
    protoInterpretationValue: ProtoInterpretationAssessmentEnum.BENIGN,
    protoValue: ProtoGermlineClassificationEnum.BENIGN,
    key: 'BENIGN',
    label: 'Confirmed as Benign',
    name: 'Benign',
    shortName: 'B',
    colorClass: 'benign',
    class: 'cls-benign',
    color: '#1AB29C',
    sourceTypes: [
      ClassificationType.User,
      ClassificationType.Community,
      ClassificationType.Genoox,
      ClassificationType.VariantFromReport,
    ],
  },
  {
    value: 5,
    protoInterpretationValue: ProtoInterpretationAssessmentEnum.FALSE,
    key: 'FALSE',
    label: 'False Variant',
    name: 'False',
    shortName: 'F',
    colorClass: 'false',
    color: '#484950',
    class: 'cls-false',
    sourceTypes: [ClassificationType.User, ClassificationType.Community],
  },
  {
    value: 1000,
    protoInterpretationValue: ProtoInterpretationAssessmentEnum.CONFLICT,
    key: 'CONFLICT',
    label: 'Conflict',
    name: 'Conflict',
    shortName: 'C',
    colorClass: 'conflict',
    class: 'cls-conflict',
    sourceTypes: [ClassificationType.Community],
  },
];

const SOMATIC_CLASSIFICATIONS: ClassificationMetadata[] = [
  {
    value: 0,
    key: 'UNKNOWN',
    label: 'No classification',
    name: 'No classification',
    shortName: 'N/A',
    colorClass: 'none',
    class: 'cls-na',
    color: '#f6f8f8',
    textColor: '#222',
    alternateTextColor: 'white',
    borderColor: '#4eadf2',
    severity: 1,
    sourceTypes: [ClassificationType.Somatic, ClassificationType.User, ClassificationType.Community],
  },
  {
    value: 1,
    key: 'TIER_1',
    label: 'Tier 1 - Strong clinical significance',
    name: 'Tier 1',
    shortName: 'Tier 1',
    colorClass: 'pathogenic',
    class: 'cls-somatic-tier1',
    color: '#e03a39',
    severity: 2,
    sourceTypes: [ClassificationType.Somatic, ClassificationType.User, ClassificationType.Community],
  },
  {
    value: 2,
    key: 'TIER_2',
    label: 'Tier 2 - Potential clinical significance',
    name: 'Tier 2',
    shortName: 'Tier 2',
    colorClass: 'likely-pathogenic',
    class: 'cls-somatic-tier2',
    color: '#f19a29',
    severity: 3,
    sourceTypes: [ClassificationType.Somatic, ClassificationType.User, ClassificationType.Community],
  },
  {
    value: 3,
    key: 'TIER_3',
    label: 'Tier 3 - Unknown clinical significance',
    name: 'Tier 3',
    shortName: 'Tier 3',
    colorClass: 'uncertain',
    class: 'cls-somatic-tier3',
    color: '#919fa5',
    severity: 4,
    sourceTypes: [ClassificationType.Somatic, ClassificationType.User, ClassificationType.Community],
  },
  {
    value: 4,
    key: 'TIER_4',
    label: 'Tier 4 - Benign or likely benign',
    name: 'Tier 4',
    shortName: 'Tier 4',
    colorClass: 'benign',
    class: 'cls-somatic-tier4',
    color: '#1AB29C',
    severity: 5,
    sourceTypes: [ClassificationType.Somatic, ClassificationType.User, ClassificationType.Community],
  },
  {
    value: 5,
    key: 'FALSE',
    label: 'False variant',
    name: 'False',
    shortName: 'False',
    color: '#484950',
    colorClass: 'somatic-false',
    class: 'cls-false',
    severity: 6,
    sourceTypes: [ClassificationType.Somatic, ClassificationType.User],
  },
  {
    value: -1,
    key: 'SUSPECTED_GERMLINE',
    label: 'Suspected as germline',
    name: 'Suspected as germline',
    shortName: 'GR',
    colorClass: 'suspected-germline',
    class: 'cls-suspected-germline',
    severity: 7,
    color: '#FACF34',
    sourceTypes: [ClassificationType.Somatic, ClassificationType.User],
  },
];

const ONCOGENIC_CLASSIFICATIONS: ClassificationMetadata[] = [
  {
    value: 0,
    platformValue: PlatformGermlineClassificationBitmap.NONE,
    protoValue: ProtoGermlineClassificationEnum.UNDEFINED_CLASSIFICATION,
    protoInterpretationValue: ProtoInterpretationAssessmentEnum.NONE,
    key: 'NONE',
    label: 'Choose Classification',
    name: 'No classification',
    shortName: 'NONE',
    colorClass: 'none',
    class: 'cls-na',
    color: '#f6f8f8',
    textColor: '#222',
    alternateTextColor: 'white',
    borderColor: '#4eadf2',
    sourceTypes: [ClassificationType.User, ClassificationType.Community, ClassificationType.Genoox],
  },
  {
    value: 1,
    platformValue: PlatformGermlineClassificationBitmap.PATHOGENIC,
    protoValue: ProtoGermlineClassificationEnum.PATHOGENIC,
    protoInterpretationValue: ProtoInterpretationAssessmentEnum.ONCOGENIC,
    key: 'ONCOGENIC',
    label: 'Confirmed as Oncogenic',
    name: 'Oncogenic',
    shortName: 'O',
    colorClass: 'pathogenic',
    class: 'cls-pathogenic',
    color: '#e03a39',
    sourceTypes: [
      ClassificationType.User,
      ClassificationType.Community,
      ClassificationType.Genoox,
      ClassificationType.VariantFromReport,
    ],
  },
  {
    value: 2,
    platformValue: PlatformGermlineClassificationBitmap.SUSPECTED_PATHOGENIC,
    protoValue: ProtoGermlineClassificationEnum.LIKELY_PATHOGENIC,
    protoInterpretationValue: ProtoInterpretationAssessmentEnum.LIKELY_ONCOGENIC,
    key: 'SUSPECTED_ONCOGENIC',
    label: 'Suspected as Oncogenic',
    name: 'Likely Oncogenic',
    shortName: 'LO',
    colorClass: 'likely-pathogenic',
    class: 'cls-likely-pathogenic',
    color: '#f19a29',
    sourceTypes: [
      ClassificationType.User,
      ClassificationType.Community,
      ClassificationType.Genoox,
      ClassificationType.VariantFromReport,
    ],
  },
  {
    value: 6,
    platformValue: PlatformGermlineClassificationBitmap.VUS,
    protoValue: ProtoGermlineClassificationEnum.UNCERTAIN_SIGNIFICANCE,
    protoInterpretationValue: ProtoInterpretationAssessmentEnum.VUS,
    key: 'VUS',
    label: 'VUS (Uncertain)',
    name: 'VUS',
    shortName: 'VUS',
    colorClass: 'uncertain',
    class: 'cls-uncertain',
    color: '#919fa5',
    sourceTypes: [
      ClassificationType.User,
      ClassificationType.Community,
      ClassificationType.Genoox,
      ClassificationType.VariantFromReport,
    ],
  },
  {
    value: 4,
    platformValue: PlatformGermlineClassificationBitmap.SUSPECTED_BENIGN,
    protoValue: ProtoGermlineClassificationEnum.LIKELY_BENIGN,
    protoInterpretationValue: ProtoInterpretationAssessmentEnum.SUSPECTED_BENIGN,
    key: 'SUSPECTED_BENIGN',
    label: 'Suspected as Benign',
    name: 'Likely Benign',
    shortName: 'LB',
    colorClass: 'likely-benign',
    class: 'cls-likely-benign',
    color: '#217EC6',
    sourceTypes: [
      ClassificationType.User,
      ClassificationType.Community,
      ClassificationType.Genoox,
      ClassificationType.VariantFromReport,
    ],
  },
  {
    value: 3,
    platformValue: PlatformGermlineClassificationBitmap.BENIGN,
    protoInterpretationValue: ProtoInterpretationAssessmentEnum.BENIGN,
    protoValue: ProtoGermlineClassificationEnum.BENIGN,
    key: 'BENIGN',
    label: 'Confirmed as Benign',
    name: 'Benign',
    shortName: 'B',
    colorClass: 'benign',
    class: 'cls-benign',
    color: '#1AB29C',
    sourceTypes: [
      ClassificationType.User,
      ClassificationType.Community,
      ClassificationType.Genoox,
      ClassificationType.VariantFromReport,
    ],
  },
  {
    value: 5,
    protoInterpretationValue: ProtoInterpretationAssessmentEnum.FALSE,
    key: 'FALSE',
    label: 'False Variant',
    name: 'False',
    shortName: 'F',
    colorClass: 'false',
    color: '#484950',
    class: 'cls-false',
    sourceTypes: [ClassificationType.User, ClassificationType.Community],
  },
];

const EXTERNAL_CLASSIFICATIONS: ClassificationMetadata[] = [
  {
    value: 3333,
    key: 'OTHER',
    label: 'Other',
    name: 'Other',
    shortName: 'other',
    colorClass: 'uncertain',
    color: '#919fa5',
    sourceTypes: [ClassificationType.External],
  },
  {
    value: 4444,
    key: 'RISK_FACTOR',
    label: 'Risk factor',
    name: 'Risk factor',
    shortName: 'RF',
    colorClass: 'pathogenic',
    color: '#e03a39',
    sourceTypes: [ClassificationType.External],
  },
];

@Injectable({
  providedIn: 'root',
})
export class ClassificationMetadataService {
  private germlineClassifications: ClassificationMetadata[] = GERMLINE_CLASSIFICATIONS;
  private somaticClassifications: ClassificationMetadata[] = SOMATIC_CLASSIFICATIONS;
  private oncogenicClassifications: ClassificationMetadata[] = ONCOGENIC_CLASSIFICATIONS;

  constructor(private http: HttpClient) {}

  init() {
    this.getInterpretationCustomSubTiersConfig()
      .pipe(take(1))
      .subscribe((config) => this.updateClassificationMetadataWithSubTier(config));
  }

  getInterpretationCustomSubTiersConfig(): Observable<InterpretationCustomSubTiersConfig> {
    const url = `${environment.beBaseURL}/api/interpretation/sub_tiers/configuration`;
    return this.http.get<InterpretationCustomSubTiersConfig>(url);
  }
  updateClassificationMetadataWithSubTier(subTiersConfig: InterpretationCustomSubTiersConfig) {
    this.germlineClassifications = GERMLINE_CLASSIFICATIONS.map((classification: ClassificationMetadata) => {
      const subTiers = subTiersConfig.germline?.[classification.key];
      return subTiers
        ? {
            ...classification,
            subTiers: subTiers.map((subTierKey) => ({
              ...classification,
              key: subTierKey.id,
              // Using string as value for sub tiers since sub tiers don't have value
              value: subTierKey.id as unknown as number,
              name: classification.name + ' ' + subTierKey.display,
              shortName: classification.shortName + ' ' + subTierKey.abbreviation,
            })),
          }
        : classification;
    });

    this.somaticClassifications = SOMATIC_CLASSIFICATIONS.map((classification: ClassificationMetadata) => {
      const subTiers = subTiersConfig.somatic?.[classification.key];
      return subTiers
        ? {
            ...classification,
            subTiers: subTiers.map((subTierKey) => ({
              ...classification,
              key: subTierKey.id,
              // Using string as value for sub tiers since sub tiers don't have value
              value: subTierKey.id as unknown as number,
              name: subTierKey.display,
              shortName: subTierKey.abbreviation || subTierKey.display?.replace(/\s/g, ''),
            })),
          }
        : classification;
    });

    this.oncogenicClassifications = ONCOGENIC_CLASSIFICATIONS.map((classification: ClassificationMetadata) => {
      const subTiers = subTiersConfig.oncogenic?.[classification.key];
      return subTiers
        ? {
            ...classification,
            subTiers: subTiers.map((subTierKey) => ({
              ...classification,
              key: subTierKey.id,
              // Using string as value for sub tiers since sub tiers don't have value
              value: subTierKey.id as unknown as number,
              name: subTierKey.display,
              shortName: subTierKey.abbreviation || subTierKey.display?.replace(/\s/g, ''),
            })),
          }
        : classification;
    });
  }

  getGermlineClassifications(sourceType: ClassificationType): ClassificationMetadata[] {
    return this.germlineClassifications.filter((classification: ClassificationMetadata) =>
      classification.sourceTypes.includes(sourceType),
    );
  }

  getSomaticClassifications(sourceType: ClassificationType): ClassificationMetadata[] {
    return this.somaticClassifications.filter((classification: ClassificationMetadata) =>
      classification.sourceTypes.includes(sourceType),
    );
  }

  getOncogenicClassifications(sourceType: ClassificationType): ClassificationMetadata[] {
    return this.oncogenicClassifications.filter((classification: ClassificationMetadata) =>
      classification.sourceTypes.includes(sourceType),
    );
  }

  getGermlineClassificationsMap(key: keyof ClassificationMetadata): ClassificationMetadataMap {
    const result: ClassificationMetadataMap = {};

    // EXTERNAL_CLASSIFICATIONS are useful in <gnx-result-graph-parameter/> where we have classification keys as OTHER
    // So far, it looks that they are only needed in maps, not in arrays
    for (const classification of EXTERNAL_CLASSIFICATIONS) {
      // Add the main classification
      result[classification[key] as string | number] = classification;
    }

    for (const classification of this.germlineClassifications) {
      // Add subTiers if available
      if (classification.subTiers) {
        for (const subTier of classification.subTiers) {
          result[subTier[key] as string | number] = subTier;
        }
      }
      // Add the main classification
      result[classification[key] as string | number] = classification;
    }

    return result;
  }

  getSomaticClassificationsMap(key: keyof ClassificationMetadata): ClassificationMetadataMap {
    const result: ClassificationMetadataMap = {};

    for (const classification of this.somaticClassifications) {
      // Add subTiers if available
      if (classification.subTiers) {
        for (const subTier of classification.subTiers) {
          result[subTier[key] as string | number] = subTier;
        }
      }

      // Add the main classification
      result[classification[key] as string | number] = classification;
    }

    return result;
  }

  getOncogenicClassificationsMap(key: keyof ClassificationMetadata): ClassificationMetadataMap {
    const result: ClassificationMetadataMap = {};

    for (const classification of this.oncogenicClassifications) {
      // Add subTiers if available
      if (classification.subTiers) {
        for (const subTier of classification.subTiers) {
          result[subTier[key] as string | number] = subTier;
        }
      }

      // Add the main classification
      result[classification[key] as string | number] = classification;
    }

    return result;
  }

  getAllClassificationsMap(key: keyof ClassificationMetadata): ClassificationMetadataMap {
    return {
      ...this.getGermlineClassificationsMap(key),
      ...this.getSomaticClassificationsMap(key),
      ...this.getOncogenicClassificationsMap(key),
    };
  }
}
