import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import * as fromActions from '../actions';
import { VariantDataService } from '../../services/variant-data.service';
import { ComponentPortal } from '@angular/cdk/portal';
import { ContactPopupComponent } from '../../../shared/components/contact-popup/contact-popup.component';
import { Overlay } from '@angular/cdk/overlay';

@Injectable()
export class ContactEffect {
  constructor(
    private actions$: Actions,
    private dataService: VariantDataService,
    private overlay: Overlay,
  ) {}

  openContactMembersPopup$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.OPEN_CONTACT_MEMBERS_POPUP),
        map((action: fromActions.OpenContactMembersPopup) => {
          const overlayRef = this.overlay.create({
            hasBackdrop: true,
            backdropClass: 'save-menu-backdrop',
            positionStrategy: this.overlay
              .position()
              .global()
              .top(action.top - 218 + 'px')
              .left(action.left - 310 + 'px'),
          });
          const portal = new ComponentPortal(ContactPopupComponent);
          const componentRef = overlayRef.attach(portal);
          componentRef.instance.mainMessage = action.mainMessage;
          componentRef.instance.contactCallback = () => {
            overlayRef.dispose();
            action.contactCallback();
          };
          overlayRef
            .backdropClick()
            .pipe(take(1))
            .subscribe(() => {
              action.cancelCallback();
              overlayRef.dispose();
            });
        }),
      ),
    { dispatch: false },
  );

  contactMembers$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CONTACT_MEMBERS),
      switchMap((action: fromActions.ContactMembers) =>
        this.dataService
          .contactMembers(
            action.contextVariant,
            action.subjectVariant,
            action.postId,
            action.sampleId,
            action.sigId,
            action.gene,
            action.samplesAffected,
          )
          .pipe(
            map(
              () =>
                new fromActions.ContactMembersSuccess(
                  action.contextVariant,
                  action.subjectVariant,
                  action.postId,
                  action.sampleId,
                  action.sigId,
                  action.gene,
                ),
            ),
            catchError((error) => of(new fromActions.ContactMembersFail(error))),
          ),
      ),
    ),
  );

  getContactNetworkData$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.GET_CONTACT_NETWORK_DATA),
      switchMap((action: fromActions.GetContactNetworkData) =>
        this.dataService.getContactNetworkData(action.variant).pipe(
          map((data) => new fromActions.GetContactNetworkDataSuccess(data)),
          catchError((error) => of(new fromActions.GetContactNetworkDataFail(error))),
        ),
      ),
    ),
  );

  contactNetwork$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CONTACT_NETWORK),
      switchMap((action: fromActions.ContactNetwork) =>
        this.dataService.contactNetwork(action.title, action.body, action.variant, action.context).pipe(
          map(() => new fromActions.ContactNetworkSuccess()),
          catchError((error) => of(new fromActions.ContactNetworkFail(error))),
        ),
      ),
    ),
  );
}
