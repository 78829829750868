<img src="assets/svg/bubble.svg" class="privacy-bg" />
<img
  src="/assets/png/hipaa_large.png"
  srcset="assets/png/hipaa_large@2x.png, assets/png/hipaa_large@3x.png"
  class="hipaa-logo"
  width="141"
  height="100"
/>
<div class="privacy-text">
  <strong>Maintain patient privacy.</strong><br />
  Whether you’re sharing a case or posting a comment, you must never include identifying information.<br />
  <br />
  View our
  <a href="https://www.genoox.com/privacy-policy/" target="_blank">Privacy Policy</a>
</div>
