<div class="close-btn" (click)="onClose()">
  <img src="/assets/svg/esc-blk.svg" />
</div>
<div class="head">
  <img src="/assets/svg/campass.svg" />
</div>
<div class="popup-body">
  <div class="header">Please Select the Variant You’re Referring to</div>
  <div class="subheader">
    The information you entered can be associated with <b>different variants</b>, please choose the one you need:
  </div>

  <div class="option-container">
    <div *ngFor="let option of data.variants | sort: 'canonical_tanscript'" class="option" (click)="onChoose(option)">
      <div class="variant">
        {{ getVariantString(option) }}
      </div>
      <div class="transcripts">
        <div class="transcript" *ngFor="let transcript of option.transcripts">
          {{ transcript }}
        </div>
      </div>
      <div *ngIf="option.canonical_tanscript" class="canonical">
        <img src="assets/svg/star-full.svg" width="15" height="14" />
        <strong>Includes Canonical transcript</strong>
        -
        {{ option.canonical_tanscript }}
      </div>
    </div>
  </div>

  <a class="cancel-btn" (click)="onClose()"> Cancel </a>
</div>
