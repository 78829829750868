import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AuthModuleState } from '../store/reducers';
import { getTokenDetails } from '../store/selectors';
import { filter, map, take, tap } from 'rxjs/operators';
import { LoadExistingToken, RenewToken } from '../store/actions';
import { FeatureBit, TokenDetails, UserPermission } from '../models';
import * as fromStore from '../store';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private store$: Store<AuthModuleState>) {}

  init() {
    this.store$.dispatch(new LoadExistingToken());
  }

  getToken(): Observable<TokenDetails> {
    return this.store$.pipe(
      select(getTokenDetails),
      tap((tokenDetails) => {
        if (tokenDetails && this.isExpired(tokenDetails)) {
          this.store$.dispatch(new RenewToken());
        }
      }),
      filter((tokenDetails) => !tokenDetails || !this.isExpired(tokenDetails)),
      take(1),
    );
  }

  private isExpired(tokenDetails: TokenDetails) {
    const time: number = new Date().getTime();
    return tokenDetails.expiration < time;
  }

  isPermissionBlocked(permission: UserPermission): Observable<boolean> {
    return combineLatest([
      this.store$.pipe(select(fromStore.getFeatureBits)),
      this.store$.pipe(select(fromStore.getUserAllowedPermissions)),
    ]).pipe(map(([fbs, permissions]) => fbs?.[FeatureBit.EnablePermissions] && !permissions.includes(permission)));
  }
}
