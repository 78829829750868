import { Action } from '@ngrx/store';

export const OPEN_EXPORT_EXCEL_DIALOG = '[Variants] Open Export Excel Dialog';
export const OPEN_EXPORT_EXCEL_DIALOG_SUCCESS = '[Variants] Open Export Excel Dialog Success';
export const OPEN_EXPORT_EXCEL_DIALOG_FAIL = '[Variants] Open Export Excel Dialog Fail';

export class OpenExportExcelDialog implements Action {
  readonly type = OPEN_EXPORT_EXCEL_DIALOG;
}

export class OpenExportExcelDialogSuccess implements Action {
  readonly type = OPEN_EXPORT_EXCEL_DIALOG_SUCCESS;
}

export class OpenExportExcelDialogFail implements Action {
  readonly type = OPEN_EXPORT_EXCEL_DIALOG_FAIL;
}

export const GET_VARIANTS_EXCEL = '[Variants] Get Variants Excel';
export const GET_VARIANTS_EXCEL_SUCCESS = '[Variants] Get Variants Excel Success';
export const GET_VARIANTS_EXCEL_FAIL = '[Variants] Get Variants Excel Fail';

export class GetVariantsExcel implements Action {
  readonly type = GET_VARIANTS_EXCEL;
}

export class GetVariantsExcelSuccess implements Action {
  readonly type = GET_VARIANTS_EXCEL_SUCCESS;

  constructor(public payload: any) {}
}

export class GetVariantsExcelFail implements Action {
  readonly type = GET_VARIANTS_EXCEL_FAIL;

  constructor(public error: any) {}
}

export type OpenExportExcelDialogAction =
  | OpenExportExcelDialog
  | OpenExportExcelDialogSuccess
  | OpenExportExcelDialogFail
  | GetVariantsExcel
  | GetVariantsExcelSuccess
  | GetVariantsExcelFail;
