import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatSelect } from '@angular/material/select';
import { ClickCursorDirective } from '../../../../../../shared/directives/click-cursor.directive';

export interface ClinicalSignificanceReasonDialogData {
  reasoningOptions: string[];
}

export interface ClinicalSignificanceReasonDialogResult {
  reasons: string[];
  details: string;
}

@Component({
  selector: 'gnx-clinical-significance-reason-dialog',
  templateUrl: './clinical-significance-reason-dialog.component.html',
  styleUrls: ['./clinical-significance-reason-dialog.component.scss'],
  standalone: true,
  imports: [
    ClickCursorDirective,
    MatDialogContent,
    ReactiveFormsModule,
    MatSelect,
    NgFor,
    MatOption,
    MatDialogActions,
    MatDialogClose,
  ],
})
export class ClinicalSignificanceReasonDialogComponent implements OnInit {
  form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<ClinicalSignificanceReasonDialogComponent, ClinicalSignificanceReasonDialogResult>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { reasoningOptions: string[] },
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      reasons: [[], Validators.required],
      details: [],
    });
  }

  onClose() {
    this.dialogRef.close();
  }
}
