<form *ngIf="step === 0" [formGroup]="joinFormGroup" (ngSubmit)="onJoinSubmit()">
  <div>
    <div class="header">
      {{ inPopup ? 'Join Franklin by Genoox' : 'Create Your\nFranklin Account' }}
    </div>
    <div *ngIf="!inPopup" class="subheader"></div>
  </div>
  <div *ngIf="inviteeData" class="header1">Join the “{{ inviteeData.org_name }}” workspace</div>
  <div class="input-container">
    <div>
      <span *ngIf="!showErrors || j.email.valid">Company Email Address</span>
      <span class="red" *ngIf="showErrors && j.email.invalid">
        {{ j.email.errors?.required ? 'Email is required' : '' }}
        {{
          j.email.errors?.email || j.email.errors?.pattern
            ? 'Something doesn’t look right, please check the email and try again'
            : ''
        }}
        {{
          j.email.errors?.emailDomain
            ? 'Franklin does not accept addresses from ' + j.email.errors?.emailDomain + '. Please use your work email.'
            : ''
        }}
      </span>
    </div>
    <input
      type="email"
      id="email"
      placeholder="YourName@Company.com"
      formControlName="email"
      [ngClass]="{ red: showErrors && j.email.invalid && j.email.touched }"
    />
    <div *ngIf="!inPopup">Please use your work email to sign up</div>
  </div>
  <div class="input-container">
    <div>
      <span *ngIf="!showErrors || j.password.valid">Password</span>
      <span class="red" *ngIf="showErrors && j.password.invalid">
        {{ j.password.errors?.minlength ? 'Password needs to be at least 8 characters long' : '' }}
        {{ j.password.errors?.required ? 'Password is required' : '' }}
      </span>
    </div>
    <input
      [type]="visiblePassword ? 'text' : 'password'"
      id="password"
      placeholder="Enter password"
      formControlName="password"
      [ngClass]="{ red: showErrors && j.password.invalid && j.password.touched }"
    />
    <img
      src="assets/svg/eye-{{ visiblePassword ? 'open' : 'close' }}.svg"
      width="34"
      height="20"
      (click)="visiblePassword = !visiblePassword"
    />
    <div *ngIf="!inPopup">Min. eight characters</div>
  </div>
  <div class="terms-container">
    <div class="terms">
      <gnx-checkbox formControlName="acceptTerms"></gnx-checkbox>
      I agree to the
      <a (click)="$event.stopImmediatePropagation()" href="https://www.genoox.com/terms/" target="_blank">
        Terms of Use
      </a>
      and
      <a (click)="$event.stopImmediatePropagation()" href="https://www.genoox.com/privacy-policy/" target="_blank">
        Privacy Policy
      </a>
    </div>
    <div class="red terms-error" *ngIf="showErrors && j.acceptTerms.invalid">
      You must agree to the Terms of Service
    </div>
  </div>
  <div *ngIf="(errorDescription$ | async)?.signUp" class="red submit-error">
    {{ (errorDescription$ | async)?.signUp }}
  </div>
  <div class="submit-container">
    <button type="submit" [disabled]="signUpInProgress$ | async" [ngClass]="{ disabled: signUpInProgress$ | async }">
      <span *ngIf="!(signUpInProgress$ | async)">CONTINUE</span>
      <gnx-dots-loader *ngIf="signUpInProgress$ | async"></gnx-dots-loader>
    </button>
  </div>
</form>
<form *ngIf="step === 1" [formGroup]="infoFormGroup" (ngSubmit)="onInfoSubmit()">
  <div>
    <div class="header">
      {{ inPopup ? 'Tell Us a Bit About Yourself' : 'Getting to Know You' }}
    </div>
    <div class="subheader warning" *ngIf="midway">You were in the middle of sign-up, please complete the process</div>
    <div *ngIf="!inPopup" class="subheader"></div>
  </div>
  <div class="input-container">
    <div>
      <span *ngIf="i.fullName.valid || i.fullName.untouched">Full Name</span>
      <span class="red" *ngIf="i.fullName.invalid && i.fullName.touched">
        {{ i.fullName.errors?.required ? 'Please enter in your name' : '' }}
        {{
          i.fullName.errors?.fullName && !i.fullName.errors?.required && !i.fullName.errors?.email
            ? 'Please enter your full name'
            : ''
        }}
        {{ i.fullName.errors?.email ? 'This looks like an email address, please enter your name' : '' }}
      </span>
    </div>
    <input id="fullName" formControlName="fullName" [ngClass]="{ red: i.fullName.invalid && i.fullName.touched }" />
  </div>
  <div class="input-container">
    <div>
      <span *ngIf="i.organization.valid || i.organization.untouched">Organization Name</span>
      <span class="red" *ngIf="i.organization.invalid && i.organization.touched">
        {{ i.organization.errors?.required ? 'Please enter an organization name' : '' }}
        {{ i.organization.errors?.maxlength ? 'Please enter maximum 45 characters' : '' }}
        {{ i.organization.errors?.nonAlphaStart ? 'Organization name must start with a letter' : '' }}
        {{ i.organization.errors?.taken }}
      </span>
    </div>
    <input
      id="organization"
      formControlName="organization"
      [ngClass]="{ red: i.organization.invalid && i.organization.touched }"
    />
  </div>
  <div class="input-container">
    <div>
      <span *ngIf="(i.role.valid || i.role.untouched) && i.other.valid"> Professional Role </span>
      <span class="red" *ngIf="(i.role.invalid && i.role.touched) || i.other.invalid">
        {{ i.role.errors?.required ? 'Please enter in your role' : '' }}
        {{ i.other.errors?.required ? 'Please enter in your role' : '' }}
      </span>
    </div>
    <div class="input" [ngClass]="{ red: (i.role.invalid && i.role.touched) || i.other.invalid }">
      <mat-select
        id="role"
        formControlName="role"
        panelClass="role-select-panel"
        placeholder="Please select your role in the organization"
        [ngClass]="{ red: (i.role.invalid && i.role.touched) || i.other.invalid }"
      >
        <mat-option *ngFor="let role of ROLES" [value]="role">{{ role }}</mat-option>
      </mat-select>
      <input
        [@grow]="'inOut'"
        *ngIf="i.role.value === 'Other'"
        placeholder="Please specify role"
        formControlName="other"
      />
    </div>
  </div>
  <div class="submit-container">
    <button
      type="submit"
      [disabled]="infoFormGroup.invalid"
      [ngClass]="{ disabled: infoFormGroup.invalid || (signUpInProgress$ | async) }"
    >
      <span *ngIf="!(signUpInProgress$ | async)">CONTINUE</span>
      <gnx-dots-loader *ngIf="signUpInProgress$ | async"></gnx-dots-loader>
    </button>
  </div>
</form>
<form class="team-form" *ngIf="step === 2" [formGroup]="teamFormGroup" (ngSubmit)="onTeamSubmit()">
  <div>
    <div class="header">
      <span *ngIf="!manualInvite">(Optional)</span><br />
      Finally Create Your Team
    </div>
    <div class="subheader">
      {{ inPopup ? 'Collaborate with your colleagues - share and discuss variants' : '' }}
    </div>
  </div>
  <strong> Invite using email </strong>
  <div #inputs class="inputs" formArrayName="team">
    <div class="input-container" *ngFor="let pair of team.controls; index as i" [formGroupName]="i">
      <div *ngIf="getMemberEmailControl(i).invalid && getMemberEmailControl(i).touched" [@appear]="'inOut'">
        <span></span>
        <span class="red">
          {{
            getMemberEmailControl(i).errors?.email ||
            getMemberEmailControl(i).errors?.required ||
            getMemberEmailControl(i).errors?.pattern
              ? 'Something doesn’t look right, please check the email and try again.'
              : ''
          }}
          {{ getMemberEmailControl(i).errors?.self ? 'There is no need to invite yourself.' : '' }}
          {{
            getMemberEmailControl(i).errors?.emailDomain
              ? 'Franklin does not accept addresses from ' +
                getMemberEmailControl(i).errors?.emailDomain +
                '. Please use your work email.'
              : ''
          }}
        </span>
      </div>
      <div class="input">
        <input
          type="email"
          placeholder="Teammate email address"
          formControlName="email"
          [ngClass]="{ red: getMemberEmailControl(i).invalid && getMemberEmailControl(i).touched }"
          autocomplete="new-email"
        />
        <div>
          <gnx-checkbox formControlName="admin">
            Admin&nbsp;
            <img
              src="assets/svg/info.svg"
              width="15"
              height="15"
              matTooltip="Admins can invite new team members, and manage your organization account"
              matTooltipClass="blue-md-tooltip"
            />
          </gnx-checkbox>
        </div>
      </div>
    </div>
  </div>
  <div class="add-btn" (click)="addInput()">
    <strong>+</strong>
    <span>Add another mail</span>
  </div>
  <div class="submit-container">
    <button type="submit" [disabled]="teamFormGroup.invalid" [ngClass]="{ disabled: teamFormGroup.invalid }">
      {{ manualInvite ? 'SEND INVITE' : 'FINISH' }}
    </button>
    <a *ngIf="!manualInvite && inPopup" (click)="onSkipInvite()">I’ll Do This Later</a>
    <a *ngIf="!manualInvite && !inPopup" [routerLink]="['/post-join']" [queryParams]="{ step: 3 }"
      >I’ll Do This Later</a
    >
    <a *ngIf="manualInvite" (click)="goBack()">Cancel</a>
  </div>
</form>
<div *ngIf="step === 3" class="step-container">
  <img *ngIf="!inPopup" src="assets/svg/v-green.svg" width="82" height="82" />
  <div>
    <div *ngIf="!manualInvite" class="header">Welcome to Franklin!</div>
    <div *ngIf="manualInvite" class="header">Invitation sent</div>
    <div *ngIf="!manualInvite" class="subheader">
      You are all set up and ready to start working.<br /><br />
      We’ve sent you an email to the address you provided to verify it’s you.<br /><br />
      Didn’t receive an email?
      <a (click)="onResendEmail()">Click here</a><br /><br />
      <span *ngIf="resendClicked">
        We have sent you another email. If you still didn’t get our message please
        <a (click)="onContactUs()">contact us</a>
      </span>
    </div>
    <div *ngIf="manualInvite" class="subheader">We have sent an invitation email to {{ teamEmails }}</div>
  </div>
  <div class="submit-container">
    <button *ngIf="!manualInvite" type="submit" (click)="signUpComplete.emit()">LET’S START</button>
    <button *ngIf="manualInvite" type="submit" routerLink="/home">BACK TO HOME PAGE</button>
  </div>
</div>
<div *ngIf="step === 4" class="step-container">
  <img src="assets/svg/v-green.svg" width="82" height="82" />
  <div>
    <div class="header">You’re Good to Go!</div>
    <div class="subheader">Your account is now activated and you can continue exploring Franklin</div>
  </div>
  <div class="submit-container">
    <button type="submit" routerLink="/home">{{ manualInvite ? 'BACK TO HOME PAGE' : 'LET’S START' }}</button>
  </div>
</div>
