import { Action } from '@ngrx/store';
import { BatchQcExportResponse, SampleQC } from '../models';

export const LOAD_QUALITY_CONTROL = '[Analysis] Load quality control';
export const LOAD_QUALITY_CONTROL_SUCCESS = '[Analysis] Load quality control success';
export const LOAD_QUALITY_CONTROL_FAIL = '[Analysis] Load quality control fail';

export class LoadQualityControl implements Action {
  readonly type = LOAD_QUALITY_CONTROL;

  constructor(public analysisId: number) {}
}

export class LoadQualityControlSuccess implements Action {
  readonly type = LOAD_QUALITY_CONTROL_SUCCESS;

  constructor(
    public analysisId: number,
    public data: SampleQC[],
  ) {}
}

export class LoadQualityControlFail implements Action {
  readonly type = LOAD_QUALITY_CONTROL_FAIL;

  constructor(
    public analysisId: number,
    public error: any,
  ) {}
}

export const EXPORT_QUALITY_CONTROL = '[Analysis] Export quality control';
export const EXPORT_QUALITY_CONTROL_SUCCESS = '[Analysis] Export quality control success';
export const EXPORT_QUALITY_CONTROL_FAIL = '[Analysis] Export quality control fail';

export class ExportQualityControl implements Action {
  readonly type = EXPORT_QUALITY_CONTROL;

  constructor(public analysisId: number) {}
}

export class ExportQualityControlSuccess implements Action {
  readonly type = EXPORT_QUALITY_CONTROL_SUCCESS;

  constructor(public payload: any) {}
}

export class ExportQualityControlFail implements Action {
  readonly type = EXPORT_QUALITY_CONTROL_FAIL;

  constructor(public error: any) {}
}

export const CLEAR_QUALITY_CONTROL = '[Analysis] Clear Quality Control';

export class ClearQualityControl implements Action {
  readonly type = CLEAR_QUALITY_CONTROL;

  constructor() {}
}

export const CHECK_QC_BATCH_EXPORT = '[Analysis] Check qc batch export';
export const CHECK_QC_BATCH_EXPORT_SUCCESS = '[Analysis] Check qc batch export success';
export const CHECK_QC_BATCH_EXPORT_FAIL = '[Analysis] Check qc batch export fail';

export class CheckQCBatchExport implements Action {
  readonly type = CHECK_QC_BATCH_EXPORT;

  constructor(
    public analysisId: number,
    public batchId: number,
  ) {}
}

export class CheckQCBatchExportSuccess implements Action {
  readonly type = CHECK_QC_BATCH_EXPORT_SUCCESS;

  constructor(public result: BatchQcExportResponse) {}
}

export class CheckQCBatchExportFail implements Action {
  readonly type = CHECK_QC_BATCH_EXPORT_FAIL;

  constructor(public error: any) {}
}

export const EXPORT_QC_BATCH_REPORT = '[Analysis] Export QC Batch Report';

export class ExportQCBatchReport implements Action {
  readonly type = EXPORT_QC_BATCH_REPORT;

  constructor(public path: string) {}
}

export const CHECK_QC_SNP_PROFILE_RESULTS_EXPORT = '[Analysis] Check qc SNP profile results export';
export const CHECK_QC_SNP_PROFILE_RESULTS_EXPORT_SUCCESS = '[Analysis] Check qc SNP profile results export success';
export const CHECK_QC_SNP_PROFILE_RESULTS_EXPORT_FAIL = '[Analysis] Check qc SNP profile results export fail';

export class CheckQCSnpProfileResultsExport implements Action {
  readonly type = CHECK_QC_SNP_PROFILE_RESULTS_EXPORT;

  constructor(public analysisId: number) {}
}

export class CheckQCSnpProfileResultsExportSuccess implements Action {
  readonly type = CHECK_QC_SNP_PROFILE_RESULTS_EXPORT_SUCCESS;

  constructor(public result: BatchQcExportResponse) {}
}

export class CheckQCSnpProfileResultsExportFail implements Action {
  readonly type = CHECK_QC_SNP_PROFILE_RESULTS_EXPORT_FAIL;

  constructor(public error: any) {}
}

export const EXPORT_QC_SNP_PROFILE_RESULTS = '[Analysis] Export QC SNP profile Results';

export class ExportQCSnpProfileResults implements Action {
  readonly type = EXPORT_QC_SNP_PROFILE_RESULTS;

  constructor(public path: string) {}
}

export type QualityControlAction =
  | LoadQualityControl
  | LoadQualityControlSuccess
  | LoadQualityControlFail
  | ExportQualityControl
  | ExportQualityControlSuccess
  | ExportQualityControlFail
  | ClearQualityControl
  | CheckQCBatchExport
  | CheckQCBatchExportSuccess
  | CheckQCBatchExportFail
  | CheckQCSnpProfileResultsExport
  | CheckQCSnpProfileResultsExportSuccess
  | CheckQCSnpProfileResultsExportFail;
