import { SigDetails, SigTotalCounts } from '../../models';
import { createReducer, on } from '@ngrx/store';

import * as fromAction from './../actions/sig-details.action';

export interface SigDetailsState {
  sigDetails: SigDetails;
  loadingDetails: boolean;
  loadedDetails: boolean;
  totalCounts: SigTotalCounts;
  totalCountsLoading: boolean;
  totalCountsLoaded: boolean;
}

const initialState: SigDetailsState = {
  sigDetails: undefined,
  loadingDetails: false,
  loadedDetails: false,
  totalCounts: undefined,
  totalCountsLoading: false,
  totalCountsLoaded: false,
};

export const reducer = createReducer(
  initialState,
  on(fromAction.loadSigDetails, (state) => ({
    ...state,
    sigDetails: undefined,
    loadingDetails: true,
    loadedDetails: false,
  })),
  on(fromAction.loadSigDetailsSuccess, (state, { payload }) => ({
    ...state,
    sigDetails: payload,
    loadingDetails: false,
    loadedDetails: true,
  })),
  on(fromAction.loadSigDetailsFail, (state, { error }) => ({
    ...state,
    loadedDetails: false,
    loadingDetails: false,
  })),
  on(fromAction.loadSigCounts, (state) => ({
    ...state,
    totalCounts: {},
    totalCountsLoading: true,
    totalCountsLoaded: false,
  })),
  on(fromAction.loadSigCountsSuccess, (state, { payload }) => ({
    ...state,
    totalCounts: payload,
    totalCountsLoading: false,
    totalCountsLoaded: true,
  })),
  on(fromAction.loadSigCountsFail, (state, { error }) => ({
    ...state,
    totalCountsLoading: false,
    totalCountsLoaded: false,
  })),
);
