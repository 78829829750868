import { Action } from '@ngrx/store';
import { EnrichmentKit } from '../../modules/analysis/modules/analyses/store/models';

export const GET_ENRICHMENT_KIT = '[Analyses] Get Enrichment Kit';
export const GET_ENRICHMENT_KIT_SUCCESS = '[Analyses] Get Enrichment Kit Success';
export const GET_ENRICHMENT_KIT_FAIL = '[Analyses] Get Enrichment Kit Fail';

export class GetEnrichmentKit implements Action {
  readonly type = GET_ENRICHMENT_KIT;
}

export class GetEnrichmentKitSuccess implements Action {
  readonly type = GET_ENRICHMENT_KIT_SUCCESS;

  constructor(public payload: EnrichmentKit[]) {}
}

export class GetEnrichmentKitFail implements Action {
  readonly type = GET_ENRICHMENT_KIT_FAIL;

  constructor(public err: any) {}
}

export type EnrichmentKitsAction = GetEnrichmentKit | GetEnrichmentKitSuccess | GetEnrichmentKitFail;
