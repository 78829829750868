import { ReferralNotes } from '../models';
import {
  LOAD_REFERRAL_NOTES,
  LOAD_REFERRAL_NOTES_FAIL,
  LOAD_REFERRAL_NOTES_SUCCESS,
  ReferralNotesAction,
} from '../actions';

export interface ReferralNotesState {
  referralNotes: ReferralNotes;
  referralNotesLoaded: boolean;
  referralNotesLoading: boolean;
}

export const initialState: ReferralNotesState = {
  referralNotes: undefined,
  referralNotesLoaded: false,
  referralNotesLoading: false,
};

export function reducer(state = initialState, action: ReferralNotesAction): ReferralNotesState {
  switch (action.type) {
    case LOAD_REFERRAL_NOTES:
      return {
        ...state,
        referralNotes: null,
        referralNotesLoaded: false,
        referralNotesLoading: true,
      };
    case LOAD_REFERRAL_NOTES_SUCCESS:
      return {
        ...state,
        referralNotes: action.data,
        referralNotesLoaded: true,
        referralNotesLoading: false,
      };
    case LOAD_REFERRAL_NOTES_FAIL:
      return { ...state, referralNotesLoaded: false, referralNotesLoading: false };
    default:
      return state;
  }
}

export const getReferralNotes = (state: ReferralNotesState) => state.referralNotes;
export const getReferralNotesLoaded = (state: ReferralNotesState) => state.referralNotesLoaded;
export const getReferralNotesLoading = (state: ReferralNotesState) => state.referralNotesLoading;
