import { ConnectedPosition } from '@angular/cdk/overlay';

export enum InterpretationType {
  germline = 'germline_interpretation',
  somatic = 'somatic_interpretation',
  oncogenic = 'oncogenic_interpretation',
}

export const menuPosition: ConnectedPosition[] = [
  {
    offsetX: 0,
    offsetY: 5,
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
  },
];

export enum SaveClassificationOptions {
  SaveAsDraft = 'draft',
  SaveAsFinal = 'final',
}
