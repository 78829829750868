// main.ts
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { MatTabsModule } from '@angular/material/tabs';
import { ReactiveFormsModule } from '@angular/forms';
import { ZendeskConfig } from './app/zendesk-config';
import { NgxZendeskWebwidgetModule } from 'ngx-zendesk-webwidget';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { storeFreeze } from 'ngrx-store-freeze';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { AppRoutingModule } from './app/app-routing.module';
import { SettingsPageModule } from './app/modules/settings-page/settings-page.module';
import { HomePageModule } from './app/home-page/home-page.module';
import { SharedModule } from './app/shared/shared.module';
import { Auth0Module } from './app/modules/auth0/auth0.module';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { appTooltipDefaults } from './app/shared/utils/tooltip-defaults.utils';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { ClassificationMetadataService } from './app/store/services/classification-metadata/classification-metadata.service';
import {
  appEffects,
  appReducers,
  AppService,
  AssayService,
  CancerTypesService,
  CustomSerializer,
  EnrichmentKitsService,
  EthnicitiesService,
  PanelsService,
  PhenotypesService,
  PredictionOptionsService,
  VariantEvidencesSelectionService,
} from './app/store';

const metaReducers: MetaReducer<any>[] = !environment.production ? [storeFreeze] : [];

if (environment.production) {
  enableProdMode();
}

const bootstrap = () =>
  bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(
        MatMenuModule,
        MatDialogModule,
        MatButtonModule,
        CommonModule,
        BrowserModule,
        Auth0Module,
        SharedModule,
        HomePageModule,
        SettingsPageModule,
        AppRoutingModule,
        StoreModule.forRoot(appReducers, { metaReducers }),
        EffectsModule.forRoot(appEffects),
        StoreRouterConnectingModule.forRoot({
          serializer: CustomSerializer,
        }),
        !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
        NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
        ReactiveFormsModule,
        MatTabsModule,
        InlineSVGModule.forRoot({ baseUrl: '/assets/svg/' }),
      ),
      AppService,
      PhenotypesService,
      PanelsService,
      CancerTypesService,
      EthnicitiesService,
      AssayService,
      EnrichmentKitsService,
      VariantEvidencesSelectionService,
      ClassificationMetadataService,
      PredictionOptionsService,
      { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: appTooltipDefaults },
      {
        provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
        useValue: {
          subscriptSizing: 'dynamic',
        },
      },
      {
        provide: MAT_SELECT_CONFIG,
        useValue: {
          disableOptionCentering: true,
          hideSingleSelectionIndicator: true,
          panelWidth: null,
        },
      },
      provideAnimations(),
      provideHttpClient(withInterceptorsFromDi()),
    ],
  });

if (environment.hmr) {
  // tslint:disable-next-line:no-string-literal
  if (module['hot']) {
    // hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  bootstrap().catch((err) => console.log(err));
}
