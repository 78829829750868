import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GET_GENE_PANELS, GetGeneKbPanelsSuccess, GetGenePanels, GetGenePanelsFail } from '../actions';
import { PanelsService } from '../services';
import { PanelsResponse } from '../../knowledge-base/modules/panels/store';
import { PANEL_LOCATION } from '../models';

@Injectable()
export class GenePanelsEffect {
  constructor(
    private actions: Actions,
    private service: PanelsService,
  ) {}

  getGenePanels$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(GET_GENE_PANELS),
      switchMap((action: GetGenePanels) =>
        this.service.getKbGenePanels(action.gene).pipe(
          map((data: PanelsResponse) => {
            return new GetGeneKbPanelsSuccess(
              action.gene,
              data.panels.map((item) => {
                return {
                  ...item,
                  location: item.public ? PANEL_LOCATION.Public : PANEL_LOCATION.Private,
                };
              }),
            );
          }),
          catchError((err) => of(new GetGenePanelsFail(action.gene, err))),
        ),
      ),
    ),
  );
}
