export interface VariantLabelsResponse {
  labels: string[];
  labels_data: VariantLabelData[];
}

export interface VariantLabelData {
  label: VariantLabel;
  tag_name: string;
  display_name: string;
  should_present_comment?: boolean;
  label_fields?: {
    field_name: string;
    field_type: string;
    field_display_name: string;
    field_default_value: boolean;
    tag_text_if_true?: string;
    tag_text_if_false?: string;
  }[];
}

export interface VariantLabelModel {
  label: VariantLabel;
  comment: string;
  label_fields: VariantLabelFields[];
}

export interface VariantLabelFields {
  field_name: string;
  field_type: string;
  field_value: boolean;
  additional_tag_text?: string;
}

export enum VariantLabel {
  orthogonal = 'Mark for Orthogonal Testing',
}
