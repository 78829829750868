import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import { InterpretationService } from '../../services/interpretation.service';

import * as fromAction from './../actions/variant-interpretation.action';
import { GetPosts } from '../../../feed/store/actions';
import { select, Store } from '@ngrx/store';
import { getSearchConfig } from '../../../variant-page/store/selectors';
import { VariantPageState } from '../../../variant-page/store/reducers';
import { SearchConfig } from '../../../variant-page/models';
import { VariantPopupAppType } from '../../../../../analysis/modules/analysis-variant/models';
import { classifySigVariant, loadSigVariantClassification } from '../../../../../sig/store';
import * as fromStore from '../index';
import {
  WarningDialogComponent,
  WarningDialogData,
} from '../../../../../../components/warning-dialog/warning-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CancelSaveVariantInterpretation } from './../actions/variant-interpretation.action';
import { VariantCurationService } from '../../services/variant-curation.service';

@Injectable()
export class VariantInterpretationEffect {
  constructor(
    private actions$: Actions,
    private interpretationService: InterpretationService,
    private variantStore$: Store<VariantPageState>,
    private matDialog: MatDialog,
  ) {}

  loadInterpretation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_VARIANT_INTERPRETATION),
      withLatestFrom(this.variantStore$.pipe(select(getSearchConfig))),
      switchMap(([action, context]: [fromAction.LoadVariantInterpretation, SearchConfig]) => {
        return action.popupAppType === VariantPopupAppType.SIG
          ? of(loadSigVariantClassification({ variantId: action.variantId }))
          : this.interpretationService
              .getInterpretation(
                action.variantId,
                context,
                action.compoundVariantId,
                action.conditionId,
                action.allowedPoints,
                action.vciEnabled,
              )
              .pipe(
                switchMap((result) => [
                  new fromAction.LoadVariantInterpretationSuccess(action.variantId, result),
                ]),
                catchError((error) => of(new fromAction.LoadVariantInterpretationFail(error))),
              );
      }),
    ),
  );

  loadAISuggestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_AI_SUGGESTION),
      withLatestFrom(this.variantStore$.pipe(select(getSearchConfig))),
      mergeMap(([action, context]: [fromAction.LoadAISuggestion, SearchConfig]) => {
        return this.interpretationService.getAIInterpretation(action.payload).pipe(
          map((result) => new fromAction.LoadAISuggestionSuccess(result, action.interpretationType)),
          catchError((error) => of(new fromAction.LoadAISuggestionFail(error))),
        );
      }),
    ),
  );

  saveInterpretation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.SAVE_VARIANT_INTERPRETATION),
      withLatestFrom(this.variantStore$.pipe(select(getSearchConfig))),
      switchMap(([action, context]: [fromAction.SaveVariantInterpretation, SearchConfig]) => {
        if (
          !action.avoidWarning &&
          !action.interpretation.somatic_interpretation?.flag &&
          !action.interpretation.germline_interpretation?.flag &&
          !action.interpretation.oncogenic_interpretation?.flag &&
          !action.interpretation.flag
        ) {
          const dialog = this.matDialog.open(WarningDialogComponent, {
            width: '700px',
            height: '264px',
            backdropClass: 'popup-backdrop',
            panelClass: 'popup-panel',
            hasBackdrop: true,
            data: {
              title: 'A classification wasn’t selected',
              description: 'Are you sure you want to save your assessment without a classification?',
              yesButtonLabel: 'Save assessment',
              cancelButtonLabel: 'Continue editing',
              hideTopSection: true,
            } as WarningDialogData,
          });
          return dialog
            .afterClosed()
            .pipe(switchMap((answer) => (answer ? of([action, context, true]) : of([action, context, false]))));
        }
        return of([action, context, true]);
      }),
      switchMap(
        ([action, context, continueSaving = true]: [fromAction.SaveVariantInterpretation, SearchConfig, boolean]) => {
          if (continueSaving) {
            return action.popupAppType === VariantPopupAppType.SIG
              ? of(
                  classifySigVariant({
                    variantId: action.variantId,
                    interpretation: action.interpretation,
                    caseContext: context,
                  }),
                )
              : this.interpretationService
                  .saveInterpretation(
                    action.variantId,
                    action.interpretation,
                    context,
                    action.compoundVariantId,
                    action.asDraft,
                    action.vciEnabled,
                  )
                  .pipe(
                    switchMap((result) => [
                      new fromAction.SaveVariantInterpretationSuccess(
                        action.variantId,
                        !!action.compoundVariantId?.hashId ? action.interpretation : result,
                        action.avoidReloadResults,
                      ),
                      new GetPosts(action.variantId),
                    ]),
                    catchError((error) => of(new fromAction.SaveVariantInterpretationFail(error))),
                  );
          } else {
            return [new CancelSaveVariantInterpretation()];
          }
        },
      ),
    ),
  );

  regenerateInterpretation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.REGENERATE_VARIANT_INTERPRETATION),
      switchMap((action: fromAction.RegenerateVariantInterpretation) =>
        this.interpretationService
          .regenerateInterpretation(action.variantId.hashId, action.variantId.analysisId, action.acmgRules)
          .pipe(
            switchMap((result) => [new fromAction.RegenerateVariantInterpretationSuccess(result)]),
            catchError((error) => of(new fromAction.RegenerateVariantInterpretationFail(error))),
          ),
      ),
    ),
  );

  getInheritanceModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GET_INHERITANCE_MODEL),
      switchMap((action: fromAction.GetInheritanceModel) =>
        this.interpretationService.getInheritanceModel(action.variantId.analysisId, action.variantId.hashId).pipe(
          switchMap((result) => [new fromAction.GetInheritanceModelSuccess(result)]),
          catchError((error) => of(new fromAction.GetInheritanceModelFail(error))),
        ),
      ),
    ),
  );
}
