import {
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  SecurityContext,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as $ from 'jquery';
import { DomSanitizer } from '@angular/platform-browser';
import sanitizeHtml from 'sanitize-html';
import { SANITIZATION_OPTIONS } from '../pipes/sanitize-html.pipe';

const DEFAULT_FROALA_BUTTONS: string[] = [
  'bold',
  'italic',
  'underline',
  'strikeThrough',
  'fontSize',
  'fontFamily',
  'color',
  'background',
  'superscript',
  'subscript',
  'specialCharacters',
  'clearFormatting',
  '|',
  'paragraphFormat',
  'align',
  'formatOL',
  'formatUL',
  'outdent',
  'indent',
  '|',
  'insertLink',
  'insertTable',
  '|',
  'undo',
  'redo',
];

@Directive({
  selector: '[gnxFroalaEditor]',
  exportAs: 'gnxFroalaEditor',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FroalaEditorDirective),
      multi: true,
    },
  ],
  standalone: true,
})
export class FroalaEditorDirective implements ControlValueAccessor, OnInit, OnDestroy {
  // gnxFroalaEditor directive as input: store the editor options
  @Input() set gnxFroalaEditor(opts: any) {
    this.opts = {
      ...(opts || this.opts),
      toolbarButtons: opts.toolbarButtons || DEFAULT_FROALA_BUTTONS,
    };
  }

  // froalaModel directive as input: store initial editor content
  @Input() set froalaModel(content: any) {
    this.updateEditor(content);
  }

  // froalaModel directive as output: update model if editor contentChanged
  @Output() froalaModelChange: EventEmitter<any> = new EventEmitter<any>();

  // froalaInit directive as output: send manual editor initialization
  @Output() froalaInit: EventEmitter<any> = new EventEmitter<any>();

  // editor options
  private opts: any = {
    immediateAngularModelUpdate: false,
    angularIgnoreAttrs: null,
  };

  // jquery wrapped element
  private element: any;

  private SPECIAL_TAGS: string[] = ['img', 'button', 'input', 'a'];
  private INNER_HTML_ATTR = 'innerHTML';
  private hasSpecialTag = false;

  // editor element
  private editor: any;

  // initial editor content
  private model: string;

  private listeningEvents: string[] = [];

  private editorInitialized = false;

  private oldModel: string = null;

  constructor(
    el: ElementRef,
    private zone: NgZone,
  ) {
    const element: any = el.nativeElement;

    // check if the element is a special tag
    if (this.SPECIAL_TAGS.indexOf(element.tagName.toLowerCase()) !== -1) {
      this.hasSpecialTag = true;
    }

    // jquery wrap and store element
    this.element = $(element);

    this.zone = zone;
  }

  // TODO not sure if ngOnInit is executed after @inputs
  ngOnInit() {
    // check if output froalaInit is present. Maybe observers is private and should not be used??
    // TODO how to better test that an output directive is present.
    if (!this.froalaInit.observers.length) {
      this.createEditor();
    } else {
      this.generateManualController();
    }
  }

  ngOnDestroy() {
    this.destroyEditor();
  }

  // Begin ControlValueAccesor methods.
  onChange = (_) => {};

  onTouched = () => {};

  // Form model content changed.
  writeValue(content: any): void {
    this.updateEditor(content);
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // End ControlValueAccesor methods.

  // Update editor with model contents.
  private updateEditor(content: any) {
    if (JSON.stringify(this.oldModel) === JSON.stringify(content)) {
      return;
    }
    content = sanitizeHtml(content, SANITIZATION_OPTIONS);

    if (!this.hasSpecialTag) {
      this.oldModel = content;
    } else {
      this.model = content;
    }

    if (this.editorInitialized) {
      if (!this.hasSpecialTag) {
        this.element.froalaEditor('html.set', content);
      } else {
        this.setContent();
      }
    } else {
      if (!this.hasSpecialTag) {
        this.element.html(content);
      } else {
        this.setContent();
      }
    }
  }

  // update model if editor contentChanged
  private updateModel() {
    this.zone.run(() => {
      let modelContent: any = null;

      if (this.hasSpecialTag) {
        const attributeNodes = this.element[0].attributes;
        const attrs = {};

        for (const node of attributeNodes) {
          const attrName = node.name;
          if (this.opts.angularIgnoreAttrs && this.opts.angularIgnoreAttrs.indexOf(attrName) !== -1) {
            continue;
          }

          attrs[attrName] = node.value;
        }

        if (this.element[0].innerHTML) {
          attrs[this.INNER_HTML_ATTR] = this.element[0].innerHTML;
        }

        modelContent = attrs;
      } else {
        const returnedHtml: any = this.element.froalaEditor('html.get');
        if (typeof returnedHtml === 'string') {
          modelContent = returnedHtml;
        }
      }

      this.oldModel = modelContent;

      // Update froalaModel.
      this.froalaModelChange.emit(modelContent);

      // Update form model.
      this.onChange(modelContent);
    });
  }

  // register event on jquery element
  private registerEvent(element, eventName, callback) {
    if (!element || !eventName || !callback) {
      return;
    }

    this.listeningEvents.push(eventName);
    element.on(eventName, callback);
  }

  private initListeners() {
    const self = this;

    // bind contentChange and keyup event to froalaModel
    this.registerEvent(this.element, 'froalaEditor.contentChanged', () => {
      setTimeout(() => {
        self.updateModel();
      }, 0);
    });
    this.registerEvent(this.element, 'froalaEditor.mousedown', () => {
      setTimeout(() => {
        self.onTouched();
      }, 0);
    });

    if (this.opts.immediateAngularModelUpdate) {
      this.registerEvent(this.element, 'keyup', () => {
        setTimeout(() => {
          self.updateModel();
        }, 0);
      });
    }
  }

  // register events from editor options
  private registerFroalaEvents() {
    if (!this.opts.events) {
      return;
    }

    for (const eventName in this.opts.events) {
      if (this.opts.events.hasOwnProperty(eventName)) {
        this.registerEvent(this.element, eventName, this.opts.events[eventName]);
      }
    }
  }

  private createEditor() {
    if (this.editorInitialized) {
      return;
    }

    this.setContent(true);

    // Registering events before initializing the editor will bind the initialized event correctly.
    this.registerFroalaEvents();

    this.initListeners();

    // init editor
    this.zone.runOutsideAngular(() => {
      this.element.on('froalaEditor.initialized', () => {
        this.editorInitialized = true;
      });

      this.editor = this.element.froalaEditor(this.opts).data('froala.editor').$el;
    });
  }

  private setHtml() {
    this.element.froalaEditor('html.set', this.model || '', true);

    // This will reset the undo stack everytime the model changes externally. Can we fix this?
    this.element.froalaEditor('undo.reset');
    this.element.froalaEditor('undo.saveStep');
  }

  private setContent(firstTime = false) {
    const self = this;

    // Set initial content
    if (this.model || this.model === '') {
      this.oldModel = this.model;
      if (this.hasSpecialTag) {
        const tags: any = this.model;

        // add tags on element
        if (tags) {
          for (const attr in tags) {
            if (tags.hasOwnProperty(attr) && attr !== this.INNER_HTML_ATTR) {
              this.element.attr(attr, tags[attr]);
            }
          }

          if (tags.hasOwnProperty(this.INNER_HTML_ATTR)) {
            this.element[0].innerHTML = tags[this.INNER_HTML_ATTR];
          }
        }
      } else {
        if (firstTime) {
          this.registerEvent(this.element, 'froalaEditor.initialized', () => {
            self.setHtml();
          });
        } else {
          self.setHtml();
        }
      }
    }
  }

  private destroyEditor() {
    if (this.editorInitialized) {
      this.element.off(this.listeningEvents.join(' '));
      this.editor.off('keyup');
      this.element.froalaEditor('destroy');
      this.listeningEvents.length = 0;
      this.editorInitialized = false;
    }
  }

  private getEditor() {
    if (this.element) {
      return this.element.froalaEditor.bind(this.element);
    }

    return null;
  }

  // send manual editor initialization
  private generateManualController() {
    const self = this;
    const controls = {
      initialize: this.createEditor.bind(this),
      destroy: this.destroyEditor.bind(this),
      getEditor: this.getEditor.bind(this),
    };
    this.froalaInit.emit(controls);
  }
}
