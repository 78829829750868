import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { VariantId, variantIdToBody } from '../../variant-page/models';
import { AcmgData, AcmgSectionResult, AcmgSectionSavePayload, ConditionSuggestions } from '../models';

@Injectable({ providedIn: 'root' })
export class VariantCurationService {
  constructor(private http: HttpClient) {}

  getVariantCurationConditions(variantId: VariantId, searchText: string): Observable<ConditionSuggestions> {
    const url = `${environment.beBaseURL}/api/organization/variants/acmg/diseases`;

    return this.http.post<ConditionSuggestions>(url, { ...variantIdToBody(variantId), search_text: searchText });
  }

  getVariantCurationAcmgData(variantId: VariantId, conditionId: string, allowedPoints: number): Observable<AcmgData> {
    const url = `${environment.beBaseURL}/api/organization/variants/acmg/interpretation/fetch`;

    return this.http.post<AcmgData>(url, {
      ...variantIdToBody(variantId),
      associated_condition_id: conditionId,
      allowed_points: allowedPoints,
    });
  }

  getVariantCurationSectionAcmgData(
    variantId: VariantId,
    conditionId: string,
    section: string,
    allowedPoints?: number,
  ): Observable<AcmgSectionResult> {
    const url = `${environment.beBaseURL}/api/organization/variants/acmg/${section}/interpretation/fetch`;

    return this.http.post<AcmgSectionResult>(url, {
      ...variantIdToBody(variantId),
      associated_condition_id: conditionId,
      allowed_points: allowedPoints,
    });
  }

  setVariantCurationSectionAcmgData(
    variantId: VariantId,
    conditionId: string,
    section: string,
    data: AcmgSectionSavePayload,
    allowedPoints: number,
  ): Observable<AcmgData> {
    const url = `${environment.beBaseURL}/api/organization/variants/acmg/${section}/interpretation`;

    return this.http.put<AcmgData>(url, {
      ...variantIdToBody(variantId),
      associated_condition_id: conditionId,
      allowed_points: allowedPoints,
      ...data,
    });
  }

  refreshCuratorNotes(variantId: VariantId, conditionId: string): Observable<{ notes: string }> {
    const url = `${environment.beBaseURL}/api/organization/variants/acmg/interpretation/final/refresh`;

    return this.http.post<{ notes: string }>(url, {
      ...variantIdToBody(variantId),
      associated_condition_id: conditionId,
    });
  }

  applyToOtherConditions(
    variantId: VariantId,
    conditionIds: string[],
    section: string,
    data: Partial<AcmgSectionSavePayload>,
  ): Observable<{ notes: string }> {
    const url = `${environment.beBaseURL}/api/organization/variants/acmg/${section}/interpretation/apply_partial`;

    return this.http.post<{ notes: string }>(url, {
      ...variantIdToBody(variantId),
      associated_condition_ids: conditionIds,
      ...data,
    });
  }
}
