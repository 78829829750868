import { createAction, props } from '@ngrx/store';
import { OrganizationConfig } from '../models';

export const loadOrganizationConfig = createAction('[Root] Load organization config');

export const loadOrganizationConfigSuccess = createAction(
  '[Root] Load organization config success',
  props<{ data: OrganizationConfig }>(),
);

export const loadOrganizationConfigFail = createAction('[Root] Load organization config fail', props<{ error: any }>());
