import { GeneRegion } from '../../variant-page/modules/genes-regions-app/store';

export enum SigGenesCategoryTab {
  curated,
  suggested,
}

export interface SigCuratedGene {
  gene_symbol: string;
  associated_conditions?: string[];
  inheritance_models?: string[];
  imprinted_status?: string[];
  penetrance?: string[];
  evidence_level?: string[];
  comment?: string;
  references?: {
    text: string;
    link: string;
  }[];
  submitting_user?: string;
  submission_date?: string;
}

export interface SigSuggestedGene {
  gene_symbol: string;
  gene_data: GeneRegion;
}

export enum InheritanceModel {
  AUTOSOMAL_DOMINANT = 'AD',
  SEMI_DOMINANT = 'SD',
  ISOLATED_CASES = 'IC',
  AUTOSOMAL_RECESSIVE = 'AR',
  DIGENIC = 'DI',
  DIGENIC_RECESSIVE = 'DR',
  SOMATIC_MUTATION = 'SMu',
  MULTIFACTORIAL = 'Mu',
  SOMATIC_MOSAICISM = 'SMo',
  INHERITED_CHROMOSOMAL_IMBALANCE = 'ICB',
  MITOCHONDRIAL = 'Mi',
  X_LINKED = 'XL',
  X_LINKED_DOMINANT = 'XLD',
  X_LINKED_RECESSIVE = 'XLR',
  Y_LINKED = 'YL',
  TRIALLELIC = 'TRI',
  UNKNOWN = 'UNK',
}

export enum ImprintedStatus {
  MATERNALLY = 'Maternally',
  PATERNALLY = 'Paternally',
  BOTH = 'Partial/Both',
}

export enum Penetrance {
  HIGH_PENETRANCE = 'High',
  MODERATE_PENETRANCE = 'Moderate',
  LOW_PENETRANCE = 'Low',
}

export const EVIDENCE_LEVELS = {
  NO_EVIDENCE: { label: '', color: '' },
  LIMITED: { label: 'Limited', color: '#e5e5e5' },
  MODERATE: { label: 'Moderate', color: '#556372' },
  STRONG: { label: 'Strong', color: '#ec7e5e' },
  DEFINITIVE: { label: 'Definitive', color: '#f44618' },
};
