import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import * as fromAction from '../actions/case-filter.action';
import { catchError, map, switchMap } from 'rxjs/operators';
import { WorkbenchService } from '../services/workbench.service';

@Injectable()
export class CaseFilterEffects {
  constructor(
    private actions$: Actions,
    private service: WorkbenchService,
  ) {}

  loadCaseFilter$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_CASE_FILTER_DATA),
      switchMap((action: fromAction.LoadCaseFilterData) =>
        this.service.getCaseFilterData().pipe(
          map((result) => new fromAction.LoadCaseFilterDataSuccess(result)),
          catchError((err) => of(new fromAction.LoadCaseFilterDataFail(err))),
        ),
      ),
    ),
  );

  saveCaseFilter$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.SAVE_CASE_FILTER_DATA),
      switchMap((action: fromAction.SaveCaseFilterData) =>
        this.service.saveCaseFilterData(action.customData).pipe(
          map((result) => new fromAction.LoadCaseFilterDataSuccess(result)),
          catchError((err) => of(new fromAction.LoadCaseFilterDataFail(err))),
        ),
      ),
    ),
  );
}
