import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { LoginGuard } from './login.guard';
import { filter, map, take } from 'rxjs/operators';
import { AuthModuleState } from '../store/reducers';
import { select, Store } from '@ngrx/store';
import { getUserSigs } from '../store/selectors';
import { AppModuleState } from '../../../store/reducers';
import { getParams } from '../../../store/selectors';

@Injectable({
  providedIn: 'root',
})
export class SIGGuard {
  constructor(
    private loginGuard: LoginGuard,
    private authStore$: Store<AuthModuleState>,
    private router: Router,
    private rootStore$: Store<AppModuleState>,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return combineLatest([
      this.authStore$.pipe(select(getUserSigs)),
      this.rootStore$.pipe(select(getParams)),
      this.loginGuard.canActivate(route, state) as Observable<any>,
    ]).pipe(
      filter(([userSigs]) => userSigs !== undefined),
      map(([userSigs, params, loggedIn]) => {
        const value = loggedIn ? userSigs.includes(params.sigId) : false;
        if (!value) {
          this.router.navigate(['/clinical-db']);
        }

        return value;
      }),
      take(1),
    );
  }
}
