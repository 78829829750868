import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ClickCursorDirective } from '../../../../../../shared/directives/click-cursor.directive';

@Component({
  selector: 'gnx-contact-popup',
  templateUrl: './contact-popup.component.html',
  styleUrls: ['./contact-popup.component.scss'],
  standalone: true,
  imports: [ClickCursorDirective],
})
export class ContactPopupComponent implements OnInit {
  @Input() @HostBinding('style.top.px') top: number;
  @Input() @HostBinding('style.left.px') left: number;
  @Input() contactCallback: () => void;
  @Input() mainMessage = 'Contact community members that already seen this variant in one of their cases';

  constructor() {}

  ngOnInit(): void {}
}
