import { Action } from '@ngrx/store';
import { VariantInterpretation } from '../../models';
import { SnpVariantId, SvVariantId } from '../../../variant-page/models';

export const LOAD_INTERPRETATION_HISTORY = '[Interpretation] Load Interpretation History';
export const LOAD_INTERPRETATION_HISTORY_SUCCESS = '[Interpretation] Load Interpretation History Success';
export const LOAD_INTERPRETATION_HISTORY_FAIL = '[Interpretation] Load Interpretation History Fail';

export class LoadInterpretationHistory implements Action {
  readonly type = LOAD_INTERPRETATION_HISTORY;

  constructor(public variant: SnpVariantId | SvVariantId) {}
}

export class LoadInterpretationHistorySuccess implements Action {
  readonly type = LOAD_INTERPRETATION_HISTORY_SUCCESS;

  constructor(public data: VariantInterpretation[]) {}
}

export class LoadInterpretationHistoryFail implements Action {
  readonly type = LOAD_INTERPRETATION_HISTORY_FAIL;

  constructor(public error: any) {}
}

export type InterpretationHistoryAction =
  | LoadInterpretationHistory
  | LoadInterpretationHistorySuccess
  | LoadInterpretationHistoryFail;
