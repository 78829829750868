import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

import * as fromAction from './../actions/ecs-analysis-results.action';
import { WorkbenchService } from '../services/workbench.service';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ECSResult } from '../../../analysis-variant/models';
import { SAVE_VARIANT_INTERPRETATION_SUCCESS } from '../../../../../variant-page/modules/interpretation/store';
import { CREATE_TOPIC_SUCCESS } from '../../../../../variant-page/modules/discussion/store';
import { editVariantSubmitSuccess } from '../../../../../variant-page/modules/edit-variant-mini-app/store';
import { AppModuleState, getAnalysisDetails, getAnalysisId } from '../../../../../../store';
import { select, Store } from '@ngrx/store';

@Injectable()
export class EcsAnalysisResultsEffect {
  constructor(
    private actions$: Actions,
    private dataService: WorkbenchService,
    private rootStore$: Store<AppModuleState>,
  ) {}

  loadEcsAnalysisResults$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_ECS_ANALYSIS_RESULTS),
      switchMap((action: fromAction.LoadECSAnalysisResults) =>
        this.dataService.getAnalysisResults(action.analysisId, []).pipe(
          map((results) => new fromAction.LoadECSAnalysisResultsSuccess(action.analysisId, results as ECSResult[])),
          catchError((error) => of(new fromAction.LoadECSAnalysisResultsFail(action.analysisId, error))),
        ),
      ),
    ),
  );

  reloadResults$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(SAVE_VARIANT_INTERPRETATION_SUCCESS, CREATE_TOPIC_SUCCESS, editVariantSubmitSuccess.type),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisDetails))),
      filter(([, analysisDetails]) => analysisDetails?.assay?.analysis_type === 'ECS'),
      switchMap(([, analysisDetails]) => [new fromAction.LoadECSAnalysisResults(analysisDetails.id)]),
    ),
  );
}
