import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromReducer from '../reducers/cancer_type.reducer';
import { getWorkbenchModuleState, WorkbenchModuleState } from '../reducers';

const getCancerTypeState: MemoizedSelector<WorkbenchModuleState, fromReducer.CancerTypeState> = createSelector(
  getWorkbenchModuleState,
  (state: WorkbenchModuleState) => state.cancerType,
);

export const getCancerType: MemoizedSelector<WorkbenchModuleState, string> = createSelector(
  getCancerTypeState,
  fromReducer.getCancerType,
);

export const getCancerTypeLoaded: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getCancerTypeState,
  fromReducer.getCancerTypeLoaded,
);

export const getCancerTypeLoading: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getCancerTypeState,
  fromReducer.getCancerTypeLoading,
);
