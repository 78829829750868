import { Action } from '@ngrx/store';
import { AnalysisDetails } from '../../../../../../store';

export const OPEN_SAVE_FILTER_DIALOG = '[Filters] Open Save Filter Dialog';
export const OPEN_LOAD_FILTER_DIALOG = '[Filters] Open Load Filter Dialog';

export class OpenSaveFilterDialog implements Action {
  readonly type = OPEN_SAVE_FILTER_DIALOG;

  constructor(
    public newFilterType?: 'merged',
    public newFilterData?: any,
  ) {}
}

export class OpenLoadFilterDialog implements Action {
  readonly type = OPEN_LOAD_FILTER_DIALOG;

  constructor(
    public analysisDetails: AnalysisDetails,
    public inMergeMode?: boolean,
  ) {}
}

export type OpenFilterDialogActions = OpenLoadFilterDialog | OpenSaveFilterDialog;
