import { createSelector, MemoizedSelector } from '@ngrx/store';
import { getVariantInterpretationState, VariantInterpretationState } from '../reducers';
import { InterpretationHeaderState } from '../reducers/interpretation-header.reducer';

import * as fromReducerSelectors from './../reducers/interpretation-header.reducer';

const getHeaderState: MemoizedSelector<VariantInterpretationState, InterpretationHeaderState> = createSelector(
  getVariantInterpretationState,
  (state: VariantInterpretationState) => state.header,
);

export const getFollowVariantInProgress: MemoizedSelector<VariantInterpretationState, boolean> = createSelector(
  getHeaderState,
  fromReducerSelectors.getFollowVariantInProgressEntity,
);
