import { VariantType } from '../../modules/analysis/modules/analysis-variant/models';
import { SearchConfig } from '../../modules/variant-page/modules/variant-page/models';

export enum VariantIdTypes {
  SNP = 'snp',
  SNP_TUMOR = 'snpTumor',
  SV = 'sv',
  SV_TUMOR = 'svTumor',
  ROH = 'roh',
  RNA = 'rna',
}

export type VariantId = SnpVariantId | SvVariantId | RohVariantId;

export interface SnpVariantId {
  chr: string;
  pos: number;
  ref: string;
  alt: string;
  hashId?: string;
  annotationVersion?: number;
  analysisId?: number;
}

export interface SvVariantId {
  chr: string;
  start_pos: number;
  end_pos: number;
  sv_type: string;
  ci_pos?: string;
  ci_end?: string;
  mate?: {
    chr: string;
    start_pos: number;
    end_pos: number;
  };
  hashId?: string;
  annotationVersion?: number;
  analysisId?: number;
  copy_number?: number;
}

export interface RohVariantId {
  chr: string;
  start_pos: number;
  end_pos: number;
  roh_regions: RohRegion[];
  hashId?: string;
  annotationVersion?: number;
  analysisId?: number;
}

export interface RohRegion {
  chrom: string;
  start_pos: number;
  end_pos: number;
}

export function getVariantIdType(variantId: VariantId, context?: SearchConfig): VariantIdTypes {
  let type =
    'sv_type' in variantId
      ? VariantIdTypes.SV
      : 'start_pos' in variantId
        ? VariantIdTypes.ROH
        : 'is_loh' in variantId
          ? VariantIdTypes.ROH
          : VariantIdTypes.SNP;

  if (context?.zygosity === 'TUMOR') {
    type = type === VariantIdTypes.SNP ? VariantIdTypes.SNP_TUMOR : VariantIdTypes.SV_TUMOR;
  }
  return type;
}

export function getVariantIdTypeEnum(variantId: VariantId): VariantType {
  const varType = getVariantIdType(variantId);

  switch (varType) {
    case VariantIdTypes.SNP:
    case VariantIdTypes.SNP_TUMOR:
      return VariantType.SNP;
    case VariantIdTypes.SV:
    case VariantIdTypes.SV_TUMOR:
      return VariantType.SV;
    case VariantIdTypes.ROH:
      return VariantType.LOH;
    default:
      return VariantType.SNP;
  }
}

export function variantIdToString(variantId: VariantId, expandROHRegion: boolean = false): string {
  switch (getVariantIdType(variantId)) {
    case VariantIdTypes.SV:
      const svVariantID = variantId as SvVariantId;
      return `${svVariantID.chr}:${svVariantID.start_pos}-${svVariantID.end_pos}?${svVariantID.sv_type}`;
    case VariantIdTypes.SNP:
      const snpVariantID = variantId as SnpVariantId;
      return `${snpVariantID.chr}:${snpVariantID.pos} ${snpVariantID.ref}>${snpVariantID.alt}`;
    case VariantIdTypes.ROH:
      const rohVariantID = variantId as RohVariantId;
      if (rohVariantID.roh_regions.length > 0 && expandROHRegion) {
        return rohVariantID.roh_regions
          .map((region) => `${region.chrom}:${region.start_pos}-${region.end_pos}`)
          .join(` ;`);
      } else {
        return `${rohVariantID.chr}:${rohVariantID.start_pos}-${rohVariantID.end_pos}`;
      }
  }
}
