export enum CaseProtocolStepStatus {
  completed = 'COMPLETED',
  pending = 'PENDING',
  active = 'ACTIVE',
  irrelevant = 'IRRELEVANT',
}

export interface CaseProtocolStep {
  id: string;
  name: string;
  status: CaseProtocolStepStatus;
  completed_time: string;
  assignee: string;
}

export interface CaseProtocol {
  id: string;
  name: string;
  steps: CaseProtocolStep[];
}
