import { createAction, props } from '@ngrx/store';

export const editVariantSubmit = createAction(
  '[Edit Variant] Submit variant',
  props<{ analysisId: number; varId: string; startPos: number; endPos: number }>(),
);
export const editVariantSubmitSuccess = createAction('[Edit Variant] Submit variant success');
export const editVariantSubmitFail = createAction('[Edit Variant] Submit variant fail');

export const editVariantUpdatePositions = createAction(
  '[Edit Variant] Edit Variant Update Positions',
  props<{ start?: number; end?: number }>(),
);

export const editVariantClearPositions = createAction('[Edit Variant] Edit Variant Clear Positions');
