import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IsFollowedVariantResponse, IsRaffleVariantResponse } from '../models';
import { AnalysisType } from '../../../../store/models';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import {
  VariantId,
  variantIdToBody,
  variantIdToHttpParams,
  VariantPageConfig,
} from '../../modules/variant-page/models';
import { VariantPopupAppType } from '../../../analysis/modules/analysis-variant/models';
import { DiscoveryAnalysisType } from '../../../discovery/store/models/discovery.model';

@Injectable()
export class VariantPageService {
  constructor(private http: HttpClient) {}

  getVariantPageConfig(
    analysisId: number,
    variantType: string,
    popupAppType: VariantPopupAppType,
    svType?: string,
    analysisType?: AnalysisType,
    discoveryAnalysisType?: DiscoveryAnalysisType,
  ): Observable<VariantPageConfig> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const url = analysisId
      ? `${environment.beBaseURL}/api/api/analysis/${analysisId}/${variantType}/variant-page-config`
      : `${environment.beBaseURL}/api/api/variant/${variantType}/variant-page-config`;
    let params = new HttpParams().set('app_type', popupAppType);
    if (svType) {
      params = params.append('sv_type', svType);
    }
    if (analysisType) {
      params = params.append('analysis_type', analysisType);
    }
    if (discoveryAnalysisType) {
      params = params.append('discovery_analysis_type', discoveryAnalysisType);
    }

    return this.http.get<VariantPageConfig>(url, { headers, params });
  }

  getIsRaffleVariantState(varId: VariantId): Observable<IsRaffleVariantResponse> {
    const params = variantIdToHttpParams(varId);
    const url = `${environment.beBaseURL}/api/raffle/is_raffle_variant`;

    return this.http.get<IsRaffleVariantResponse>(url, { params });
  }

  followVariant(varId: VariantId, state: boolean) {
    const body = variantIdToBody(varId);
    const url = `${environment.beBaseURL}/api/follow_variant/${state ? 'follow' : 'unfollow'}`;

    return this.http.post(url, body);
  }

  getFollowVariantState(varId: VariantId): Observable<IsFollowedVariantResponse> {
    const params = variantIdToHttpParams(varId);
    const url = `${environment.beBaseURL}/api/follow_variant/is_followed`;

    return this.http.get<IsFollowedVariantResponse>(url, { params });
  }

  getCasesCount(): Observable<number> {
    const url = `${environment.beBaseURL}/api/api/analysis/count`;

    return this.http.get<{ count: number }>(url).pipe(map((value) => value.count));
  }
}
