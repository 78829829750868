import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import { Location } from '@angular/common';
import { SELECT_PUBLICATION, SelectPublication } from '../../../publications/store/actions';
import { SET_SELECTED_APP, SET_SELECTED_APP_SUCCESS, SetSelectedApp } from '../actions';
import { select, Store } from '@ngrx/store';
import { GET_CONDITION_DETAILS, GetConditionDetails } from '../../../conditions/store/actions';
import { VariantPageState } from '../reducers';
import { getApplyUrlNavigation } from '../selectors';

@Injectable()
export class RouteUpdateEffect {
  constructor(
    private actions$: Actions,
    private location: Location,
    private rootStore$: Store<VariantPageState>,
  ) {}

  routeUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SET_SELECTED_APP_SUCCESS),
        withLatestFrom(this.rootStore$.pipe(select(getApplyUrlNavigation))),
        filter(([, applyNavigation]) => !!applyNavigation),
        tap(([action]: [SetSelectedApp, any]) =>
          this.location.replaceState(this.location.path().split('?')[0], `app=${action.app}`),
        ),
      ),
    { dispatch: false },
  );

  conditionDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GET_CONDITION_DETAILS),
        withLatestFrom(this.rootStore$.pipe(select(getApplyUrlNavigation))),
        filter(([, applyNavigation]) => !!applyNavigation),
        tap(([action]: [GetConditionDetails, any]) =>
          this.location.replaceState(
            this.location.path().replace(/&selected=.+?(?=&|$)/, '') + `&selected=${action.conditionId}`,
          ),
        ),
      ),
    { dispatch: false },
  );

  publicationDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SELECT_PUBLICATION),
        withLatestFrom(this.rootStore$.pipe(select(getApplyUrlNavigation))),
        filter(([, applyNavigation]) => !!applyNavigation),
        tap(([action]: [SelectPublication, any]) =>
          this.location.replaceState(
            this.location.path().replace(/&selected=.+?(?=&|$)/, '') + `&selected=${action.id}`,
          ),
        ),
      ),
    { dispatch: false },
  );

  publicationDetailsReset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Publications] Select Tab Index'),
        filter((action: { index: number }) => action.index === 0),
        withLatestFrom(this.rootStore$.pipe(select(getApplyUrlNavigation))),
        filter(([, applyNavigation]) => !!applyNavigation),
        tap(() => this.location.replaceState(this.location.path().replace(/&selected=.+?(?=&|$)/, ''))),
      ),
    { dispatch: false },
  );

  conditionDetailsReset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Conditions] Select Tab Index'),
        filter((action: { index: number }) => action.index === 0),
        withLatestFrom(this.rootStore$.pipe(select(getApplyUrlNavigation))),
        filter(([, applyNavigation]) => !!applyNavigation),
        tap(() => this.location.replaceState(this.location.path().replace(/&selected=.+?(?=&|$)/, ''))),
      ),
    { dispatch: false },
  );
}
