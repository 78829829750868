import { createActionGroup, props } from '@ngrx/store';
import { RichTemplateResponse } from '../models';

export const RichTemplateActions = createActionGroup({
  source: 'RichTemplate',
  events: {
    'Load RichTemplates': props<{ section: string }>(),
    'Load RichTemplates Success': props<{ data: RichTemplateResponse }>(),
    'Load RichTemplates Failure': props<{ error: unknown }>(),
  },
});
