import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import { FiltersState } from '../reducers';
import * as fromFiltersStructure from '../reducers/filters-structure.reducer';
import { FiltersStructureState } from '../reducers/filters-structure.reducer';
import { MemoizedSelectorWithProps } from '@ngrx/store/src/selector';
import { FilterGroup, FilterGroupType } from '../../../shared/models/filter.model';
import { FilterStructure } from '../../../shared/models/filter-structure.model';

export const getFiltersStructureState: MemoizedSelector<FiltersState, FiltersStructureState> = createSelector(
  fromFeature.getFiltersState,
  (state: fromFeature.FiltersState) => state.filtersStructure,
);

export const getFilterStructureEntities: MemoizedSelector<FiltersState, FilterGroup[]> = createSelector(
  getFiltersStructureState,
  fromFiltersStructure.getFilterStructureEntities,
);

export const getFilterGroupsByType: MemoizedSelectorWithProps<FiltersState, { type: FilterGroupType }, FilterGroup[]> =
  createSelector(getFilterStructureEntities, (entities, props: { type: FilterGroupType }) =>
    entities.filter((entity) => entity.type === props.type),
  );

export const getFlattenedFiltersStructureEntities: MemoizedSelector<FiltersState, { [id: string]: FilterStructure }> =
  createSelector(getFilterStructureEntities, filtersArrayToDict);

export const getFiltersStructureLoaded: MemoizedSelector<FiltersState, boolean> = createSelector(
  getFiltersStructureState,
  fromFiltersStructure.getFiltersStructureLoaded,
);

export const getFiltersStructureLoading: MemoizedSelector<FiltersState, boolean> = createSelector(
  getFiltersStructureState,
  fromFiltersStructure.getFiltersStructureLoading,
);

export function filtersArrayToDict(filters: FilterGroup[]): { [id: string]: FilterStructure } {
  return filters.reduce((pre: { [id: string]: FilterStructure }, curr: FilterGroup) => {
    return {
      ...pre,
      ...curr.filters.reduce(
        (preFilters: { [id: string]: FilterStructure }, currFilters: FilterStructure) => ({
          ...preFilters,
          [currFilters.id]: currFilters,
        }),
        {},
      ),
    };
  }, {});
}
