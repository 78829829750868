import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { VariantActionType } from '../../modules/analysis/modules/analysis-variant/models';
import { FeatureBit, UserPermission } from '../../modules/auth0/models';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import * as fromAuthStore from '../../modules/auth0/store';
import { AuthModuleState } from '../../modules/auth0/store';

@Directive({
  selector: '[gnxElementPermissionResolver]',
  exportAs: 'permissionresolver',
  standalone: true,
})
export class ElementPermissionResolverDirective implements OnInit, OnDestroy {
  private destroyed$: Subject<void> = new Subject();
  @Input() requiredActionPermission: VariantActionType | UserPermission;
  @Input() requiredBlockPermission: VariantActionType | UserPermission;
  public allowedPermissions: UserPermission[] = [];
  public isPermitted;

  blockDisableOverlay: Element;

  @HostBinding('class.not-permitted') notPermittedClass = false;

  constructor(
    private authStore$: Store<AuthModuleState>,
    private _elRef: ElementRef,
    private _renderer: Renderer2,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    combineLatest([
      this.authStore$.pipe(select(fromAuthStore.getFeatureBits)),
      this.authStore$.pipe(select(fromAuthStore.getUserAllowedPermissions)),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([fbs, permissions]) => {
        if (!fbs?.[FeatureBit.EnablePermissions]) {
          this.isPermitted = true;
        } else {
          this.isPermitted = false;
          this.allowedPermissions = permissions;
          if (this.requiredActionPermission) {
            this.toggleDisableElement(this.isActionPermitted);
          }
          if (this.requiredBlockPermission) {
            this.toggleDisabledBlock(this.isActionPermitted);
          }
        }
        this.cd.detectChanges();
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  isPermissionAllowed(permission: UserPermission): boolean {
    return this.isPermitted || this.allowedPermissions.includes(permission);
  }

  toggleDisableElement(state) {
    this.notPermittedClass = !state;
  }

  toggleDisabledBlock(state) {
    // if (state) {
    //   if (this.blockDisableOverlay) {
    //     this._renderer.removeChild(this._elRef.nativeElement, this.blockDisableOverlay);
    //     this.blockDisableOverlay = null;
    //   }
    // } else {
    //   this._elRef.nativeElement.children.forEach(child => {
    //     this._renderer.setStyle(child, 'opacity', '.25');
    //   }
    // )
    //   this.blockDisableOverlay = this._renderer.createElement('div');
    //   const text = this._renderer.createText('Permission required for this action')
    //   this._renderer.addClass(this.blockDisableOverlay, 'not-permitted-block')
    //   if (!window.getComputedStyle(this._elRef.nativeElement).display) {
    //     this._renderer.setStyle(this._elRef.nativeElement, 'display', 'block');
    //     this._renderer.setStyle(this._elRef.nativeElement, 'position', 'relative');
    //   }
    //
    //   this._renderer.appendChild(this.blockDisableOverlay, text);
    //   this._renderer.appendChild(this._elRef.nativeElement, this.blockDisableOverlay)
    //
    // }
  }

  get isActionPermitted() {
    if (this.requiredActionPermission) {
      switch (this.requiredActionPermission) {
        case UserPermission.RemoveFromReport:
        case UserPermission.AddToReport:
        case UserPermission.AddToWorkbench:
        case UserPermission.RemoveFromWorkbench:
        case UserPermission.AssignClinicalSignificance:
        case UserPermission.AddEvidence:
        case UserPermission.Classify:
        case UserPermission.CurateGene:
        case UserPermission.DeleteOrgAssessment:
        case UserPermission.ClassifyAsFinal:
        case UserPermission.ManageFilters:
        case UserPermission.EditCaseDetailsMyCases:
        case UserPermission.CreateCase:
        case UserPermission.EditKB:
        case UserPermission.AccessAllCases:
        case UserPermission.ProvideCaseAccess:
        case UserPermission.GeneCurationSaveDraft:
        case UserPermission.GeneCurationSaveFinal:
        case UserPermission.GeneCurationSaveApproval:
        case UserPermission.EditVariantLists:
          return this.isPermissionAllowed(this.requiredActionPermission);
        default:
          return true;
      }
    } else {
      return true;
    }
  }
}
