import { CancerTypes, ERROR_CANCER_TYPE } from '../models';
import * as fromAction from '../actions/cancer-types.actions';

export interface CancerTypesState {
  data: CancerTypes;
  loaded: boolean;
  loading: boolean;
}

export const initialState: CancerTypesState = {
  data: undefined,
  loaded: false,
  loading: false,
};

export function reducer(state = initialState, action: fromAction.CancerTypeAction): CancerTypesState {
  switch (action.type) {
    case fromAction.GET_CANCER_TYPES:
      return {
        ...state,
        data: null,
        loaded: false,
        loading: true,
      };
    case fromAction.GET_CANCER_TYPES_SUCCESS:
      return {
        ...state,
        data:
          action.suggestions?.exactMatch?.length || !action.prefixText
            ? action.suggestions
            : {
                exactMatch: [ERROR_CANCER_TYPE],
                relatedSuggestion: null,
              },
        loaded: true,
        loading: false,
      };
    case fromAction.GET_CANCER_TYPES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}

export const getCancerTypes = (state: CancerTypesState) => state.data;
export const getCancerTypesLoaded = (state: CancerTypesState) => state.loaded;
export const getCancerTypesLoading = (state: CancerTypesState) => state.loading;
