export enum AnalysisStatus {
  NEW = 'NEW',
  UPLOADING = 'UPLOADING FILES',
  UPLOAD_DONE = 'UPLOAD COMPLETE',
  CREATING = 'CREATING',
  PROCESSING_PIPELINE = 'PROCESSING - PIPLINE',
  PROCESSING_ANNOTATION = 'PROCESSING - ANNOTATION',
  READY = 'READY',
  ERROR = 'ERROR',
  ERROR_ANNOTATION = 'ERROR - ANNOTATION',
  ERROR_PIPELINE = 'ERROR - PIPELINE',
  PARTIAL_ANNOTATION = 'PARTIAL ANNOTATION',
  ERROR_UPLOAD = 'UPLOAD FAILED',
  ARCHIVED = 'ARCHIVED',
  IN_ARCHIVING_PROCESS = 'IN ARCHIVING PROCESS',
  ARCHIVING_FAILED = 'ARCHIVING FAILED',
  DEPRECATED = 'DEPRECATED',
  PENDING_FILES = 'PENDING FILES',
  PENDING = 'PENDING',
  PENDING_ANNOTATION = 'PENDING ANNOTATION',
}

export const PROCESS_ANALYSIS_STATUSES: AnalysisStatus[] = [
  AnalysisStatus.PROCESSING_ANNOTATION,
  AnalysisStatus.UPLOADING,
  AnalysisStatus.IN_ARCHIVING_PROCESS,
  AnalysisStatus.PROCESSING_PIPELINE,
  AnalysisStatus.CREATING,
];

export const ERROR_ANALYSIS_STATUSES: AnalysisStatus[] = [
  AnalysisStatus.ERROR,
  AnalysisStatus.ERROR_ANNOTATION,
  AnalysisStatus.ERROR_PIPELINE,
  AnalysisStatus.ERROR_UPLOAD,
  AnalysisStatus.ARCHIVING_FAILED,
];

export const INFO_ANALYSIS_STATUSES: AnalysisStatus[] = [
  AnalysisStatus.ARCHIVED,
  AnalysisStatus.DEPRECATED,
  AnalysisStatus.NEW,
  AnalysisStatus.PARTIAL_ANNOTATION,
  AnalysisStatus.UPLOAD_DONE,
];
