import { AllSomaticBiomarkerResponse, SomaticBioMarkerType, SomaticBiomarkerUserData } from '../models';

import * as fromAction from './../actions/somatic-biomarkers.action';
import { TmbByVafResponse } from '../../components/tmb-by-vaf/tmb-by-vaf.model';

export interface SomaticBiomarkersState {
  data: AllSomaticBiomarkerResponse;
  loading: boolean;
  loaded: boolean;
  userData: SomaticBiomarkerUserData[];
  userDataLoading: boolean;
  userDataLoaded: boolean;
  userDataInUpdate?: SomaticBioMarkerType;
  tmbByVaf?: TmbByVafResponse;
}

const initialState: SomaticBiomarkersState = {
  data: undefined,
  loading: false,
  loaded: false,
  userData: [],
  userDataLoaded: false,
  userDataLoading: false,
  tmbByVaf: null,
};

export function reducer(state = initialState, action: fromAction.SomaticBiomarkersAction): SomaticBiomarkersState {
  switch (action.type) {
    case fromAction.LOAD_SOMATIC_BIOMARKERS: {
      return {
        ...state,
        data: undefined,
        loading: true,
        loaded: false,
      };
    }
    case fromAction.LOAD_SOMATIC_BIOMARKERS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
      };
    }
    case fromAction.LOAD_SOMATIC_BIOMARKERS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case fromAction.LOAD_SOMATIC_MARKER_USER_DATA: {
      return {
        ...state,
        userData: [],
        userDataLoading: true,
        userDataLoaded: false,
      };
    }
    case fromAction.LOAD_SOMATIC_MARKER_USER_DATA_SUCCESS: {
      return {
        ...state,
        userData: action.data,
        userDataLoading: false,
        userDataLoaded: true,
      };
    }
    case fromAction.LOAD_SOMATIC_MARKER_USER_DATA_FAIL: {
      return {
        ...state,
        userDataLoading: false,
        userDataLoaded: false,
      };
    }
    case fromAction.UPDATE_SOMATIC_MARKER_USER_DATA: {
      return {
        ...state,
        userDataInUpdate: action.payload.biomarker_type,
      };
    }
    case fromAction.UPDATE_SOMATIC_MARKER_USER_DATA_SUCCESS: {
      const userDataExists = state.userData.some((x) => x.biomarker_type === action.payload.biomarker_type);
      return {
        ...state,
        userDataInUpdate: undefined,
        userData: userDataExists
          ? state.userData.map((x) =>
              x.biomarker_type === action.payload.biomarker_type ? { ...x, ...action.payload } : { ...x },
            )
          : [...state.userData, action.payload],
      };
    }
    case fromAction.UPDATE_SOMATIC_MARKER_USER_DATA_FAIL: {
      return {
        ...state,
        userDataInUpdate: undefined,
      };
    }
  }

  return { ...state };
}
