import { createActionGroup, props } from '@ngrx/store';
import {
  AcmgData,
  AcmgSection,
  AcmgSectionResult,
  AcmgSectionSavePayload,
  VariantAIInterpretationPayload,
  VariantInterpretation,
} from '../../models';
import { VariantId } from '../../../variant-page/models';

export const VariantCurationActions = createActionGroup({
  source: 'VariantCuration',
  events: {
    'Load VariantCurations': props<{ variantId: VariantId; conditionId: string; allowedPoints?: number }>(),
    'Load VariantCurations Success': props<{ data: AcmgData }>(),
    'Load VariantCurations Failure': props<{ error: string }>(),

    'Load VariantCurationsSection': props<{
      variantId: VariantId;
      conditionId: string;
      section: string;
      allowedPoints?: number;
    }>(),
    'Load VariantCurationsSection Success': props<{ data: AcmgSectionResult }>(),
    'Load VariantCurationsSection Failure': props<{ section: string; error: string }>(),

    'Save VariantCurationsSection': props<{
      variantId: VariantId;
      conditionId: string;
      section: string;
      data: AcmgSectionSavePayload;
      allowedPoints?: number;
    }>(),
    'Save VariantCurationsSection Success': props<{ data: AcmgData; section: string }>(),
    'Save VariantCurationsSection Failure': props<{ section: string; error: string }>(),
  },
});
