import { Action } from '@ngrx/store';
import { Message, Topic, User } from '../../models/discussion.models';
import { SnpVariantId } from '../../../variant-page/models';

export const GET_TOPICS = '[Discussion] Get topics';
export const GET_TOPICS_SUCCESS = '[Discussion] Get topics success';
export const GET_TOPICS_FAILURE = '[Discussion] Get topics failure';
export const DELETE_TOPIC = '[Discussion] Delete topic';
export const DELETE_TOPIC_SUCCESS = '[Discussion] Delete topic success';
export const DELETE_TOPIC_FAILURE = '[Discussion] Delete topic failure';
export const GET_USERS = '[Discussion] Get users';
export const GET_USERS_SUCCESS = '[Discussion] Get users success';
export const GET_USERS_FAILURE = '[Discussion] Get users failure';
export const GET_MESSAGES = '[Discussion] Get messages';
export const GET_MESSAGES_SUCCESS = '[Discussion] Get messages success';
export const GET_MESSAGES_FAILURE = '[Discussion] Get messages failure';
export const DELETE_MESSAGE = '[Discussion] Delete message';
export const DELETE_MESSAGE_SUCCESS = '[Discussion] Delete message success';
export const DELETE_MESSAGE_FAILURE = '[Discussion] Delete message failure';
export const CREATE_TOPIC = '[Discussion] Create topic';
export const CREATE_TOPIC_SUCCESS = '[Discussion] Create topic success';
export const CREATE_TOPIC_FAILURE = '[Discussion] Create topic failure';
export const SUBMIT_MESSAGE = '[Discussion] Submit message';
export const SUBMIT_MESSAGE_SUCCESS = '[Discussion] Submit message success';
export const SUBMIT_MESSAGE_FAILURE = '[Discussion] Submit message failure';
export const RESET_DISCUSSION_STATE = '[Discussion] Reset discussion state';

export class GetTopics implements Action {
  readonly type = GET_TOPICS;

  constructor(
    public variantId: SnpVariantId,
    public fetch_from_index?,
    public fetch_to_index?,
  ) {}
}

export class GetTopicsSuccess implements Action {
  readonly type = GET_TOPICS_SUCCESS;

  constructor(public topics: Topic[]) {}
}

export class GetTopicsFailure implements Action {
  readonly type = GET_TOPICS_FAILURE;

  constructor(public err: string) {}
}

export class DeleteTopic implements Action {
  readonly type = DELETE_TOPIC;

  constructor(
    public variantId: SnpVariantId,
    public topic_id,
  ) {}
}

export class DeleteTopicSuccess implements Action {
  readonly type = DELETE_TOPIC_SUCCESS;

  constructor(public topicId: string) {}
}

export class DeleteTopicFailure implements Action {
  readonly type = DELETE_TOPIC_FAILURE;

  constructor(public err: string) {}
}

export class GetUsers implements Action {
  readonly type = GET_USERS;

  constructor(public searchString: string) {}
}

export class GetUsersSuccess implements Action {
  readonly type = GET_USERS_SUCCESS;

  constructor(public users: User[]) {}
}

export class GetUsersFailure implements Action {
  readonly type = GET_USERS_FAILURE;

  constructor(public err: string) {}
}

export class GetMessages implements Action {
  readonly type = GET_MESSAGES;

  constructor(
    public variantId: SnpVariantId,
    public topic_ids?,
    public fetch_from_index?,
    public fetch_to_index?,
  ) {}
}

export class GetMessagesSuccess implements Action {
  readonly type = GET_MESSAGES_SUCCESS;

  constructor(public messages: Message[]) {}
}

export class GetMessagesFailure implements Action {
  readonly type = GET_MESSAGES_FAILURE;

  constructor(public err: string) {}
}

export class DeleteMessage implements Action {
  readonly type = DELETE_MESSAGE;

  constructor(
    public variantId: SnpVariantId,
    public message_id,
    public topic_id,
  ) {}
}

export class DeleteMessageSuccess implements Action {
  readonly type = DELETE_MESSAGE_SUCCESS;

  constructor(public messageId: string) {}
}

export class DeleteMessageFailure implements Action {
  readonly type = DELETE_MESSAGE_FAILURE;

  constructor(public err: string) {}
}

export class CreateTopic implements Action {
  readonly type = CREATE_TOPIC;

  constructor(
    public variantId: SnpVariantId,
    public title,
    public message,
    public users?: User[],
  ) {}
}

export class CreateTopicSuccess implements Action {
  readonly type = CREATE_TOPIC_SUCCESS;

  constructor(public topic: Topic) {}
}

export class CreateTopicFailure implements Action {
  readonly type = CREATE_TOPIC_FAILURE;

  constructor(public err: string) {}
}

export class SubmitMessage implements Action {
  readonly type = SUBMIT_MESSAGE;

  constructor(
    public variantId: SnpVariantId,
    public message_content,
    public topic_id,
    public users?: User[],
    public message_id?,
    public replying_to?,
  ) {}
}

export class SubmitMessageSuccess implements Action {
  readonly type = SUBMIT_MESSAGE_SUCCESS;

  constructor(
    public message: Message,
    public update?: boolean,
  ) {}
}

export class SubmitMessageFailure implements Action {
  readonly type = SUBMIT_MESSAGE_FAILURE;

  constructor(public err: string) {}
}

export class ResetDiscussionState implements Action {
  readonly type = RESET_DISCUSSION_STATE;

  constructor() {}
}

export type DiscussionAction =
  | GetTopics
  | GetTopicsSuccess
  | GetTopicsFailure
  | DeleteTopic
  | DeleteTopicSuccess
  | DeleteTopicFailure
  | GetUsers
  | GetUsersSuccess
  | GetUsersFailure
  | GetMessages
  | GetMessagesSuccess
  | GetMessagesFailure
  | DeleteMessage
  | DeleteMessageSuccess
  | DeleteMessageFailure
  | CreateTopic
  | CreateTopicSuccess
  | CreateTopicFailure
  | SubmitMessage
  | SubmitMessageSuccess
  | SubmitMessageFailure
  | ResetDiscussionState;
