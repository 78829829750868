import { createSelector, MemoizedSelector } from '@ngrx/store';
import { getWorkbenchModuleState, WorkbenchModuleState } from '../reducers';
import { WorkbenchConfigState } from '../reducers/config.reducer';
import { WorkbenchConfig } from '../models/config.model';

import * as fromReducer from './../reducers/config.reducer';

const getWorkbenchConfigState: MemoizedSelector<WorkbenchModuleState, WorkbenchConfigState> = createSelector(
  getWorkbenchModuleState,
  (state: WorkbenchModuleState) => state.config,
);

export const getWorkbenchConfig: MemoizedSelector<WorkbenchModuleState, WorkbenchConfig> = createSelector(
  getWorkbenchConfigState,
  fromReducer.getConfig,
);

export const getWorkbenchConfigLoaded: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getWorkbenchConfigState,
  fromReducer.getConfigLoaded,
);

export const getWorkbenchConfigLoading: MemoizedSelector<WorkbenchModuleState, boolean> = createSelector(
  getWorkbenchConfigState,
  fromReducer.getConfigLoading,
);
