import { Action } from '@ngrx/store';
import { CancerTypes } from '../models';

export const GET_CANCER_TYPES = '[Root] Get Cancer Types';
export const GET_CANCER_TYPES_SUCCESS = '[Root] Get Cancer Types Success';
export const GET_CANCER_TYPES_FAIL = '[Root] Get Cancer Types Fail';

export class GetCancerTypes implements Action {
  readonly type = GET_CANCER_TYPES;

  constructor(public prefixText: string) {}
}

export class GetCancerTypesSuccess implements Action {
  readonly type = GET_CANCER_TYPES_SUCCESS;

  constructor(
    public suggestions: CancerTypes,
    public prefixText: string,
  ) {}
}

export class GetCancerTypesFail implements Action {
  readonly type = GET_CANCER_TYPES_FAIL;

  constructor(public err: any) {}
}

export type CancerTypeAction = GetCancerTypes | GetCancerTypesSuccess | GetCancerTypesFail;
