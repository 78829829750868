import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  AnalysesDetailedStats,
  AnalysesStats,
  Analysis,
  AnalysisBatch,
  AnalysisLeanListResponse,
  AnalysisUpdateBulkPayload,
  CustomLabel,
  DataModel,
} from '../store';
import { CustomHttpParamEncoder } from '../../../../../shared/utils/http-param-encoder';
import { map, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AuthModuleState, getTeamMembersObject } from '../../../../auth0/store';
import { TeamMember } from '../../../../auth0/models';
import { environment } from '../../../../../../environments/environment';
import { AnalysisCustomWorkflowStatus } from '../../../models';
import { AnalysesSomaticDetailedStats } from '../store/models/analysis-somatic.model';

@Injectable({ providedIn: 'root' })
export class AnalysisService {
  constructor(
    private http: HttpClient,
    private authStore$: Store<AuthModuleState>,
  ) {}

  getAnalyses(
    search: string,
    ordering: string = '-created_at',
    assignedTo: string[] = [],
    status: string[] = [],
    assay: string[] = [],
    customLabel: string[] = [],
    customData: { [key: string]: any } = null,
    activeStep: string[] = [],
  ): Observable<DataModel<Analysis>> {
    const params = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
      fromObject: {
        search,
        ordering,
        assignedTo,
        status,
        assay,
        ...this.getCustomLabelsParams(customLabel),
        page: '1',
        page_size: 60,
        ...(customData || {}),
        active_step: activeStep,
      },
    });
    return this.http.get(`${environment.beBaseURL}/api/api/analysis/`, { params }) as Observable<DataModel<Analysis>>;
  }

  getCustomLabels(): Observable<CustomLabel[]> {
    return this.http.get<CustomLabel[]>(`${environment.beBaseURL}/api/api/analysis/custom-labels/options`);
  }

  getAnalysesNextPage(nextUrl: string): Observable<DataModel<Analysis>> {
    return this.http.get(`${environment.beBaseURL}/api/api/analysis/?${nextUrl.split('?')[1]}`) as Observable<
      DataModel<Analysis>
    >;
  }

  saveAnalysis(analysis: Analysis) {
    return this.http.put<Analysis>(`${environment.beBaseURL}/api/api/analysis/${analysis.id}`, analysis);
  }

  updateAnalysisBulk(payload: AnalysisUpdateBulkPayload): Observable<Analysis[]> {
    return this.http.post<Analysis[]>(`${environment.beBaseURL}/api/api/analysis/bulk_update`, payload);
  }

  deleteAnalysis(analysisId: number) {
    return this.http.delete(`${environment.beBaseURL}/api/api/analysis/${analysisId}`);
  }

  getAnalysisProgress(analysisId: any): Observable<Analysis> {
    return this.http.get(`${environment.beBaseURL}/api/api/analysis/${analysisId}`) as Observable<Analysis>;
  }

  analysisStatusPolling(analysisIds: number[]): Observable<Analysis[]> {
    let params = new HttpParams();
    analysisIds.forEach((id) => {
      params = params.append('id', id.toString());
    });
    return this.http.get<Analysis[]>(`${environment.beBaseURL}/api/api/analysis/status_polling`, { params });
  }

  getCustomStatusOptions(): Observable<AnalysisCustomWorkflowStatus[]> {
    return this.http.get(`${environment.beBaseURL}/api/api/analysis/custom-status/options`) as Observable<
      AnalysisCustomWorkflowStatus[]
    >;
  }

  reanalyzeAnalysis(analysisId: number): Observable<any> {
    // TBD
    return of('TBD no api for this yet');
  }

  getAnalysesStats(params: HttpParams): Observable<AnalysesStats | AnalysesDetailedStats> {
    return this.http
      .get(`${environment.beBaseURL}/api/api/analysis/stats/${params ? 'full' : 'brief'}`, { params })
      .pipe(
        withLatestFrom(this.authStore$.pipe(select(getTeamMembersObject))),
        map(([response, members]: [AnalysesStats | any, { [key: number]: TeamMember }]) => {
          return 'total' in response
            ? response
            : {
                brief_stats: response.brief_stats,
                monthly_stats_graph_data: response.monthly_stats_graph_data,
                active_stats: {
                  by_assigned: response.active_stats.by_assigned.map((value) => ({
                    label: (members && members[value.user]?.name) || value.user || 'Unassigned',
                    value: value.count,
                  })),
                  by_open_time: response.active_stats.by_open_time.map((value) => ({
                    label: value.open_time || 'N/A',
                    value: value.count,
                  })),
                  by_assay: response.active_stats.by_assay.map((value) => ({
                    label: value.assay_name || 'No Assay',
                    value: value.count,
                  })),
                },
              };
        }),
      );
  }

  getSomaticAnalysesStats(params: HttpParams): Observable<AnalysesSomaticDetailedStats> {
    // return of(sampleSomaticStats);
    return this.http.get(
      `${environment.beBaseURL}/api/somatic_dashboard?from_time=1635728000000&to_time=1645565931673`,
      { params },
    ) as Observable<AnalysesSomaticDetailedStats>;
  }

  getAnalysisBatchList(): Observable<DataModel<AnalysisBatch>> {
    const url = `${environment.beBaseURL}/api/api/analysis/batch/list`;
    return this.http.get<DataModel<AnalysisBatch>>(url);
  }

  getAnalysesBatchNextPage(nextUrl: string): Observable<DataModel<AnalysisBatch>> {
    const url = `${environment.beBaseURL}/api/api/analysis/batch/list?${nextUrl.split('?')[1]}`;
    return this.http.get<DataModel<AnalysisBatch>>(url);
  }

  getAnalysisBatchAnalysisList(batchId?: number, pageSize = 25): Observable<AnalysisLeanListResponse> {
    const url = `${environment.beBaseURL}/api/api/analysis/batch/analyses`;
    let params = new HttpParams().set('page_size', pageSize).set('page', 1);
    if (!!batchId) {
      params = params.set('batch_id', batchId);
    }
    return this.http.get<AnalysisLeanListResponse>(url, { params });
  }

  getAnalysesBatchAnalysisListNextPage(nextUrl: string): Observable<AnalysisLeanListResponse> {
    const url = `${environment.beBaseURL}/api/api/analysis/batch/analyses?${nextUrl.split('?')[1]}`;
    return this.http.get<AnalysisLeanListResponse>(url);
  }

  rerunAnalysisTrees(analysisId: number): Observable<any> {
    const url = `${environment.beBaseURL}/api/filter_tree/sample/snp/run_trees`;
    const body = {
      analysis_id: analysisId,
    };
    return this.http.post<any>(url, body);
  }

  rerunCaseAnnotations(analysisId: number): Observable<any> {
    const url = `${environment.beBaseURL}/api/analysis/${analysisId.toString()}/rerun_annotations`;
    const body = {
      analysis_id: analysisId,
    };
    return this.http.post<any>(url, body);
  }

  private getCustomLabelsParams(customLabels: string[]) {
    if (!customLabels?.length) {
      return {};
    }
    return customLabels.reduce((acc, crt) => {
      const [key, value] = crt.split(/:(.*)/);
      return {
        ...acc,
        [key]: acc[key] ? [...acc[key], value] : [value],
      };
    }, {});
  }
}
