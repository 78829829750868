import { AnalyticsAction, AnalyticsEvent, extractEventFromType } from '../../../../store/actions/analytics.action';
import { Action } from '@ngrx/store';
import { VariantCaseRequest } from '../../../variant-page/modules/variant-page/models';

export const OPEN_CASE_FROM_REPORT_DIALOG = '[Create Case] Open case from report dialog';
export const SUBMIT_CASE_FROM_REPORT = '[Create Case] Submit case from report';

export class OpenCaseFromReportDialog implements AnalyticsAction {
  readonly type = OPEN_CASE_FROM_REPORT_DIALOG;
  analyticsEvent: AnalyticsEvent;

  constructor() {
    const label = 'Open Case From Report Dialog';

    this.analyticsEvent = extractEventFromType(this.type);
    this.analyticsEvent.eventLabel = label;
  }
}

export class SubmitCaseFromReport implements Action {
  readonly type = SUBMIT_CASE_FROM_REPORT;

  constructor(public payload: VariantCaseRequest) {}
}

export type CaseFromReportAction = OpenCaseFromReportDialog | SubmitCaseFromReport;
