<div class="search-container">
  <div
    class="search-icon-container"
    *ngIf="!(searching$ | async)"
    [ngClass]="{ disabled: !searchControl.value }"
    gnxRtl
  >
    <img src="/assets/svg/search.svg" class="search-icon" (click)="onSearch()" />
  </div>
  <gnx-dots-loader *ngIf="searching$ | async"></gnx-dots-loader>
  <input
    class="search-input"
    placeholder="{{ 'SEARCH_ANY_VARIANT' | i18n }}"
    [formControl]="searchControl"
    (paste)="onPaste()"
    (keyup.esc)="onEscape()"
    (keyup.enter)="onSearch()"
  />
  <div class="search-error" *ngIf="((searchDone$ | async) && !(searchResult$ | async)) || (searchError$ | async)">
    {{ searchError$ | async }}
  </div>
  <div class="select-wrapper" [style.flex]="'0 0 86px'">
    <mat-select [(value)]="hgValue">
      <mat-option value="hg19">hg19</mat-option>
      <mat-option value="hg38">hg38</mat-option>
    </mat-select>
  </div>
  <div class="select-wrapper" [style.flex]="'0 0 102px'">
    <mat-select [(value)]="isTumor">
      <mat-option [value]="false">Germline</mat-option>
      <mat-option [value]="true">Somatic</mat-option>
    </mat-select>
  </div>
</div>
<gnx-questionnaire
  #questionnaire
  [closeable]="true"
  [searchControl]="searchControl"
  (contextChange)="onContextChange($event)"
  (search)="onSearch($event)"
>
</gnx-questionnaire>
