import { AfterViewInit, Component, forwardRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MultipleRohPopupComponent } from '../../../components/multiple-roh-popup/multiple-roh-popup.component';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppModuleState } from '../../../store/reducers';
import { SearchVariant } from '../../../store/actions';
import * as fromAppStore from '../../../store';
import { SearchConfig } from '../../../modules/variant-page/modules/variant-page/models';
import { QuestionnaireComponent } from '../../../shared/containers/questionnaire/questionnaire.component';
import { MAT_SELECT_CONFIG, MatSelect } from '@angular/material/select';
import { DotsLoaderComponent } from '../../../shared/components/dots-loader/dots-loader.component';
import { MatOption } from '@angular/material/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ClickCursorDirective } from '../../../shared/directives/click-cursor.directive';
import { NgFor, NgClass, NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'gnx-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {
        disableOptionCentering: true,
        hideSingleSelectionIndicator: true,
      },
    },
  ],
  standalone: true,
  imports: [
    NgFor,
    NgClass,
    ClickCursorDirective,
    MatExpansionPanel,
    ReactiveFormsModule,
    MatSelect,
    MatOption,
    NgIf,
    DotsLoaderComponent,
    QuestionnaireComponent,
    AsyncPipe,
  ],
})
export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {
  static EXAMPLES: { [key: string]: { format: string; context: SearchConfig }[] } = {
    SNP: [
      {
        format: 'TSC1:c.359T>C',
        context: {} || {
          search_term: 'TSC1:c.359T>C',
          sex: 'MALE',
          phenotypes: ['Cafe-au-lait spot', 'Renal angiomyolipoma', 'Failure to thrive'],
          zygosity: 'HET',
          ethnicity: ['Ashkenzi jewish'],
          reported_classification: 'VUS',
        },
      },
      {
        format: 'MSH6:c.4082delA',
        context: {
          search_term: 'MSH6:c.4082delA',
          sex: 'FEMALE',
          phenotypes: ['Colon cancer', 'Pancreatic adenocarcinoma'],
          zygosity: 'HET',
          ethnicity: ['Ashkenzi jewish'],
          reported_classification: 'VUS',
        },
      },
      {
        format: 'USH1C:c.238dup',
        context: {
          search_term: 'USH1C:c.238dup',
          sex: 'FEMALE',
          phenotypes: ['Retinitis pigmentosa', 'Vestibular hypofunction'],
          zygosity: 'HOM',
          ethnicity: ['Spanish'],
          reported_classification: 'SUSPECTED_PATHOGENIC',
        },
      },
      {
        format: 'chr9:135800978-A-G',
        context: {} || {
          search_term: 'chr9:135800978-A-G',
          sex: 'MALE',
          phenotypes: ['Cafe-au-lait spot', 'Renal angiomyolipoma', 'Failure to thrive'],
          zygosity: 'HET',
          ethnicity: ['Ashkenzi jewish'],
          reported_classification: 'VUS',
        },
      },
      {
        format: 'CRTAP:c.320_321del',
        context: {
          search_term: 'CRTAP:c.320_321del',
          sex: 'MALE',
          phenotypes: ['Round face', 'Bluish sclerae', 'Osteopenia'],
          zygosity: 'HOM',
          ethnicity: ['Canadian'],
          reported_classification: 'SUSPECTED_PATHOGENIC',
        },
      },
      {
        format: 'chr11-17552955 C CG',
        context: {
          search_term: 'chr11-17552955 C CG',
          sex: 'FEMALE',
          phenotypes: ['Retinitis pigmentosa', 'Vestibular hypofunction'],
          zygosity: 'HOM',
          ethnicity: ['Spanish'],
          reported_classification: 'SUSPECTED_PATHOGENIC',
        },
      },
      {
        format: 'NM_000368.5:c.359T>C',
        context: {} || {
          search_term: 'NM_000368.5:c.359T>C',
          sex: 'MALE',
          phenotypes: ['Cafe-au-lait spot', 'Renal angiomyolipoma', 'Failure to thrive'],
          zygosity: 'HET',
          ethnicity: ['Ashkenzi jewish'],
          reported_classification: 'VUS',
        },
      },
      {
        format: 'rs397515359',
        context: {
          search_term: 'rs397515359',
          sex: 'FEMALE',
          phenotypes: ['Retinitis pigmentosa', 'Vestibular hypofunction'],
          zygosity: 'HOM',
          ethnicity: ['Spanish'],
          reported_classification: 'SUSPECTED_PATHOGENIC',
        },
      },
      {
        format: 'PDE6B:c.1921-9C>G',
        context: {
          search_term: 'PDE6B:c.1921-9C>G',
          phenotypes: ['Retinitis pigmentosa', 'Absent night vision'],
          zygosity: 'HOM',
          ethnicity: ['French'],
          reported_classification: 'SUSPECTED_PATHOGENIC',
        },
      },
      {
        format: 'VHL:c.293A>G',
        context: {
          search_term: 'VHL:c.293A>G',
          sex: 'MALE',
          phenotypes: ['Adrenal pheochromocytoma', 'Abdominal pain'],
          zygosity: 'HET',
          ethnicity: ['Spanish'],
          reported_classification: 'PATHOGENIC',
          family_inheritance_status: 'DE_NOVO',
        },
      },
    ],
    CNV: [
      { format: 'arr[GRCh37] 7q11(72,650,120_74,154,209 )x1', context: {} },
      { format: 'arr[GRCh37] 5q23.2-31.1(126,103,200_131,243,320)x3', context: {} },
      { format: 'DEL:chr1-216138600-216270555', context: {} },
      { format: 'DUP:chrX-73565114-73956354', context: {} },
    ],
    ROH: [
      { format: 'chr13:32946119-62698218', context: {} },
      { format: 'chr12:103248894-103249130', context: {} },
      { format: 'chr13:32946119-62698218 chr12:103248894-103249130', context: {} },
    ],
  };

  static EXAMPLES_HG_38: { [key: string]: { format: string; context: SearchConfig }[] } = {
    SNP: [
      {
        format: 'VHL:c.293A>G',
        context: {},
      },
      {
        format: 'USH1C:c.238dup ',
        context: {},
      },
      {
        format: 'chr3:10142140 A-G',
        context: {},
      },
      {
        format: 'CRTAP:c.320_321del',
        context: {},
      },
      {
        format: 'NM_000551.4:c.293A>G',
        context: {},
      },
      {
        format: 'rs397515359 ',
        context: {},
      },
      {
        format: 'PDE6B:c.1921-9C>G',
        context: {},
      },
    ],
    CNV: [
      {
        format: 'DEL:Chr7:72846244-74187855',
        context: {},
      },
      {
        format: 'arr[GRCh38] 7q11.23(72846244_74187855)X1',
        context: {},
      },
      {
        format: 'DUP:Chr5:125423848-128107072',
        context: {},
      },
      {
        format: 'arr[GRCh38] 5q21.1(125423848_128107072)x3',
        context: {},
      },
    ],
  };

  static EXAMPLES_SOMATIC: { [key: string]: { format: string; context: SearchConfig }[] } = {
    SNP: [
      {
        format: 'EGFR:c.2573T>G',
        context: { zygosity: 'TUMOR', search_term: 'EGFR:c.2573T>G' },
      },
      {
        format: 'chr7:55259514-CT-CG',
        context: { zygosity: 'TUMOR', search_term: 'chr7:55259514-CT-CG' },
      },
      {
        format: 'EGFR:p.Leu858Arg',
        context: { zygosity: 'TUMOR', search_term: 'EGFR:p.Leu858Arg' },
      },
      // {
      //   format: 'BRAF:c.1405_1407delGGA',
      //   context: {zygosity: 'TUMOR', search_term: 'BRAF:c.1405_1407delGGA'}
      // },
      {
        format: 'NM_000546.6:c.434T>A',
        context: { zygosity: 'TUMOR', search_term: 'NM_000546.6:c.434T>A' },
      },
      {
        format: 'BRCA2:c.432insA',
        context: { zygosity: 'TUMOR', search_term: 'BRCA2:c.432insA' },
      },
      {
        format: 'chr17-7578496 A T',
        context: { zygosity: 'TUMOR', search_term: 'chr17-7578496 A T' },
      },
    ],
    CNV: [
      {
        format: 'arr[GRCh37]12p12.1(25,364,760_25,400,274)X3',
        context: { zygosity: 'TUMOR', search_term: 'arr[GRCh37]12p12.1(25,364,760_25,400,274)X3' },
      },
      {
        format: 'dup:chr12:25,364,760-25,400,274',
        context: { zygosity: 'TUMOR', search_term: 'dup:chr12:25,364,760-25,400,274' },
      },
      {
        format: 'arr[GRCh37]10q23.31(chr10:89,624,217-89,725,240)X1',
        context: { zygosity: 'TUMOR', search_term: 'arr[GRCh37]10q23.31(chr10:89,624,217-89,725,240)X1' },
      },
      {
        format: 'del:chr10:89,624,217-89,725,240',
        context: { zygosity: 'TUMOR', search_term: 'del:chr10:89,624,217-89,725,240' },
      },
    ],
  };

  static EXAMPLES_SOMATIC_HG_38: { [key: string]: { format: string; context: SearchConfig }[] } = {
    SNP: [
      {
        format: 'EGFR:c.2573T>G',
        context: { zygosity: 'TUMOR', search_term: 'EGFR:c.2573T>G' },
      },
      {
        format: 'chr7:55191821-CT-CG',
        context: { zygosity: 'TUMOR', search_term: 'chr7:55191821-CT-CG' },
      },
      {
        format: 'EGFR:p.Leu858Arg',
        context: { zygosity: 'TUMOR', search_term: 'EGFR:p.Leu858Arg' },
      },
      {
        format: 'NM_000546.6:c.434T>A',
        context: { zygosity: 'TUMOR', search_term: 'NM_000546.6:c.434T>A' },
      },
      {
        format: 'BRCA2:c.432insA',
        context: { zygosity: 'TUMOR', search_term: 'BRCA2:c.432insA' },
      },
      {
        format: 'chr17-7675178 A T',
        context: { zygosity: 'TUMOR', search_term: 'chr17-7675178 A T' },
      },
    ],
    CNV: [
      {
        format: 'arr[GRCh38]12p12.1(25,364,760_25,400,274)X3',
        context: { zygosity: 'TUMOR', search_term: 'arr[GRCh38]12p12.1(25,364,760_25,400,274)X3' },
      },
      {
        format: 'dup:chr12:25,364,760-25,400,274',
        context: { zygosity: 'TUMOR', search_term: 'dup:chr12:25,364,760-25,400,274' },
      },
      {
        format: 'arr[GRCh38]10q23.31(chr10:89,624,217-89,725,240)X1',
        context: { zygosity: 'TUMOR', search_term: 'arr[GRCh38]10q23.31(chr10:89,624,217-89,725,240)X1' },
      },
      {
        format: 'del:chr10:89,624,217-89,725,240',
        context: { zygosity: 'TUMOR', search_term: 'del:chr10:89,624,217-89,725,240' },
      },
    ],
  };

  SearchComponent = SearchComponent;

  TYPES: string[] = ['SNP', 'CNV', 'ROH'];

  @ViewChild('questionnaire', { static: true }) questionnaireRef: QuestionnaireComponent;

  public searching$: Observable<boolean>;
  public searchError$: Observable<string>;

  destroy$: Subject<void> = new Subject<void>();

  searchControl: FormControl;

  searchContext: SearchConfig;
  expandedExamples: string;

  constructor(
    private store$: Store<AppModuleState>,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.searchControl = this.fb.control('');

    this.searching$ = this.store$.pipe(select(fromAppStore.getSearching));
    this.searchError$ = this.store$.pipe(select(fromAppStore.getSearchError));
  }

  ngAfterViewInit() {
    this.questionnaireRef.setIsTumor(this.isTumor);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  get hgValue(): string {
    return localStorage.getItem('lastHGValue') || 'hg19';
  }

  set hgValue(value: string) {
    localStorage.setItem('lastHGValue', value);
    if (this.expandedExamples && !this.examples[this.expandedExamples]) {
      this.expandedExamples = 'SNP';
    }
  }

  get isTumor(): boolean {
    return localStorage.getItem('isTumorSearch') === 'true';
  }

  set isTumor(value: boolean) {
    localStorage.setItem('isTumorSearch', `${value}`);
    this.questionnaireRef.setIsTumor(value);
  }

  get examples() {
    return this.searchContext?.zygosity === 'TUMOR'
      ? this.hgValue === 'hg38'
        ? SearchComponent.EXAMPLES_SOMATIC_HG_38
        : SearchComponent.EXAMPLES_SOMATIC
      : this.hgValue === 'hg38'
        ? SearchComponent.EXAMPLES_HG_38
        : SearchComponent.EXAMPLES;
  }

  selectExample(example: { format: string; context: SearchConfig }): void {
    if (this.searchControl) {
      this.searchControl.setValue(example.format, { emitEvent: false });
      this.searchContext = example.context;
    }
    this.onClassify();
  }

  onSearchStringSubmit(searchString, redirect: boolean, config?: any) {
    setTimeout(() => {
      this.store$.dispatch(new SearchVariant(searchString, redirect, this.hgValue, config));
    });
  }

  onClassify(redirect = true) {
    if (this.searchControl.value.includes('SHULE:c.88')) {
      window.open('http://minesweeperonline.com/#intermediate', '_blank');
    } else {
      this.onSearchStringSubmit(this.searchControl.value, redirect, {
        ...this.searchContext,
        search_term: this.searchControl.value,
      });
    }
  }

  onMultipleRoh() {
    this.dialog.open(MultipleRohPopupComponent, {
      width: '895px',
      height: '690px',
      backdropClass: 'error-popup-backdrop',
      panelClass: 'error-popup-panel',
      hasBackdrop: true,
      data: {
        search: (input: string) => {
          this.searchControl.setValue(input);
          this.onClassify();
        },
      },
    });
  }

  setContext(context: SearchConfig) {
    this.searchContext = context;
    if (this.expandedExamples && !this.examples[this.expandedExamples]) {
      this.expandedExamples = 'SNP';
    }
  }
}
