import { cloneDeep as _cloneDeep } from 'lodash';
import * as fromAction from '../actions';
import { TeamMember } from '../../models';

export interface OrganizationState {
  updateOrgInProgress: boolean;
  members: {
    data: TeamMember[];
    loaded: boolean;
    loading: boolean;
  };
}

export const initialState: OrganizationState = {
  updateOrgInProgress: false,
  members: {
    data: undefined,
    loaded: false,
    loading: false,
  },
};

export function reducer(state = initialState, action: fromAction.OrganizationAction): OrganizationState {
  switch (action.type) {
    case fromAction.UPDATE_ORGANIZATION_INFO: {
      return {
        ...state,
        updateOrgInProgress: true,
      };
    }
    case fromAction.UPDATE_ORGANIZATION_INFO_FAIL:
    case fromAction.UPDATE_ORGANIZATION_INFO_SUCCESS: {
      return {
        ...state,
        updateOrgInProgress: false,
      };
    }
    case fromAction.GET_TEAM_MEMBERS: {
      return {
        ...state,
        members: { ...state.members, loading: true, loaded: false },
      };
    }
    case fromAction.GET_TEAM_MEMBERS_SUCCESS: {
      return {
        ...state,
        members: { data: action.members, loading: false, loaded: true },
      };
    }
    case fromAction.GET_TEAM_MEMBERS_FAIL: {
      return {
        ...state,
        members: { ...state.members, loading: false, loaded: false },
      };
    }
    case fromAction.DELETE_MEMBER:
    case fromAction.SET_MEMBER_ROLES: {
      const data = _cloneDeep(state.members.data);
      data[data.findIndex((value) => value.email === action.email)].loading = true;
      return {
        ...state,
        members: {
          ...state.members,
          data,
        },
      };
    }
    case fromAction.DELETE_MEMBER_SUCCESS: {
      const data = state.members.data.filter((member) => member.email !== action.email);
      return {
        ...state,
        members: {
          ...state.members,
          data,
        },
      };
    }
    case fromAction.SET_MEMBER_ROLES_SUCCESS: {
      const data = _cloneDeep(state.members.data);
      data.forEach((member) => {
        if (member.email === action.email) {
          member.org_roles = action.roles;
          member.loading = false;
        }
      });

      return {
        ...state,
        members: {
          ...state.members,
          data,
        },
      };
    }
    case fromAction.DELETE_MEMBER_FAIL:
    case fromAction.SET_MEMBER_ROLES_FAIL:
    case fromAction.DELETE_MEMBER_CANCEL: {
      const data = _cloneDeep(state.members.data);
      data[data.findIndex((value) => value.email === action.email)].loading = false;
      return {
        ...state,
        members: {
          ...state.members,
          data,
        },
      };
    }
    default:
      return state;
  }
}

export const getUpdateOrgInProgress = (state: OrganizationState) => state.updateOrgInProgress;
export const getTeamMembers = (state: OrganizationState) => state.members;
export const getTeamMembersLoading = (state: OrganizationState) => state.members.loading;
